// Components
import Footer from "../components/Footer/Footer";

const LayoutAssessment = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <main className="assessment">{children}</main>
      <Footer noLogo={true} />
    </>
  );
};

export default LayoutAssessment;
