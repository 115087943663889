import { UserAccessPermissions } from "../interfaces/permissions";
import { useAuth } from "../providers/auth-context";

/**
 *
 * Utility function that reads the list of the logged-in user's permissions
 * and checks if the permission(s) received as parameter exists in that list
 *
 * @param expectedPermissions An array of strings representing a set of pre-defined
 * permissions that the user needs to have in order to be granted access.
 *
 * @param requireAllExpectedPermissions A boolean indicating if the user must have all of
 * the expected permissions that were passed trough the `expectedPermissions` argument.
 *
 * The `expectedPermissions` defaults to `*` which is used as a wildcard for components
 * and pages that do not have any permission-based restrictions.
 *
 * If the user is a "Super Admin" then access is granted by default.
 *
 * @returns A boolean indicating if the user has the necessary permission(s) to
 * view or access the component or the page.
 *
 */
export default function handlePermissionCheck(
  expectedPermissions: UserAccessPermissions[] = ["*"],
  requireAllExpectedPermissions: boolean = false,
): boolean {
  const { user } = useAuth();

  // If the user has a "Super Admin" role, then everything is permitted
  if (user.role === "Super Admin") return true;

  // If an "*" was provided as the "specificPermission" parameter,
  // then that means that the component / route does not have any
  // permission-based access associated with it
  if (expectedPermissions.includes("*")) return true;

  // Make sure the user has all of the expected permissions if all are required.
  // Otherwise, make sure that the user has at least one of the expected permissions before granting access.
  const arrayMethodToBeCalled: "every" | "some" = requireAllExpectedPermissions ? "every" : "some";

  // If the user does not have a "Super Admin" role and the wildcard (*) permission is not part of the expected permissions,
  // then check if the expected permissions passed as argument exist in the list of received permissions of the user from the API
  const isAccessGranted: boolean = expectedPermissions[arrayMethodToBeCalled](
    expectedPermission => {
      return user.permissions.includes(expectedPermission);
    },
  );

  return isAccessGranted;
}
