import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT, SchemasFileValueTestValidation } from "./constants";

export const ARTICLES_EDIT_SCHEMA = Yup.object().shape({
  title: Yup.string()
    .required("Please enter the article title")
    .max(255, "Maximum of 255 characters allowed.")
    .test("article-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  summary: Yup.string()
    .nullable()
    .notRequired()
    .max(255, "Maximum of 255 characters allowed.")
    .test("article-summary", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  status_id: Yup.mixed()
    // 1 -> Draft
    // 2 -> Published
    // 3 -> Archived
    .oneOf([1, 2, 3])
    .required("Please select the article status"),
  content: Yup.string()
    .nullable()
    .notRequired()
    .max(65000, "Maximum of 65000 characters allowed in the text editor!"),
  feature_image: Yup.mixed()
    .test("feature_image-edit", "Thumbnail Image is required", value => {
      // "null" value for this field represents the default value
      // from the auto-creation of the draft article.
      // If the value is "null" the field is REQUIRED
      // If there's a string value for this field (e.g. already uploaded preview image) then its OPTIONAL
      if (value === null) return false;

      return true;
    })
    .test("feature_image-edit", "The file is too big.", value => {
      const typecastedValue = value as SchemasFileValueTestValidation;
      if (!typecastedValue || !typecastedValue.name) return true;

      return typecastedValue.size <= 5242880; // Allowed file size is 5mb
    }),
});
