import Loader from "../Loader/Loader";
import { LoaderRefetchProps } from "./interfaces";

const LoaderRefetch = ({ title, modifierClass = "" }: LoaderRefetchProps) => {
  return (
    <div className={`loader-refetch ${modifierClass}`}>
      <h6 className="d-flex align-items-center mb--20">{title}</h6>
      <Loader size="sm" modifierWrapper="ml--10" />
    </div>
  );
};

export default LoaderRefetch;
