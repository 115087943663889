// Utilities & Hooks
import { useChatBlockActions } from "../../api/Chat/Chat";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import DropdownActions from "../Dropdown/DropdownActions";

// Assets
import { BiBlock as BlockIcon, BiDotsHorizontalRounded as ChatActionsIcon } from "react-icons/bi";

// Interfaces
import { ChatActionsProps } from "./interfaces";
import { DropdownItem } from "../Dropdown/interfaces";

const ChatActions = ({ id, is_blocked = 0, orientation = "bottom" }: ChatActionsProps) => {
  const errorReporting = useErrorReporting();
  const chatBlockActions = useChatBlockActions();

  const handleChatBlockActions = async (chatAction: DropdownItem) => {
    // If there's no valid chat ID available, exit function, preventing any mutations
    if (!id) return;

    // Type of action to be taken for the targeted chat
    const action: "block" | "unblock" = chatAction.value === 1 ? "block" : "unblock";

    try {
      await chatBlockActions.mutateAsync({
        chatID: id,
        action,
      });
    } catch (error) {
      errorReporting(`Failed chat ${action} action.`, error, { chat_id: id, chat_action: action });
    }
  };

  return (
    <div className="chat-actions">
      <DropdownActions
        items={[
          {
            text: is_blocked === 0 ? "Block" : "Unblock",
            value: is_blocked === 0 ? 1 : 0,
            icon: <BlockIcon />,
          },
        ]}
        orientation={orientation}
        size="sm"
        title="Actions"
        customToggleElement={<ChatActionsIcon />}
        handleDropdownItem={handleChatBlockActions}
      />
    </div>
  );
};

export default ChatActions;
