// Utilities & Hooks
import { Link, useParams } from "react-router-dom";
import { useVideoConferencingJoin } from "../../api/VideoConferencing/VideoConferencing";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";

// Components
import Loader from "../../components/Loader/Loader";

const VideoConferencingPublic = () => {
  const { id } = useParams();

  /*==========================
    JOIN THE VIDEO MEETING
  ===========================*/
  const { data, isLoading, error } = useVideoConferencingJoin(id);

  return (
    <div className="video-conferencing video-conferencing--public">
      <div className="video-conferencing__header">
        <Link to="/login/">
          <img src={FCHLogo} title="FirstChoice Hiring logo" />
        </Link>
      </div>
      <div className="video-conferencing__line"></div>

      {isLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <div className="video-conferencing__container">
          {!data || !Object.entries(data).length || error ? (
            <h2>
              <strong> Couldn't start video. Please reload the page and try again.</strong>{" "}
            </h2>
          ) : (
            <div className="video">
              <iframe
                title="Video Conferencing Iframe"
                src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${data.embed_id}&iframe=true&room=${data.video_link}`}
                width="1024px"
                height="576px"
                scrolling="no"
                allow="microphone; camera"
              ></iframe>
            </div>
          )}
        </div>
      )}

      <footer className="video-conferencing__footer">
        <p>Copyright © FirstChoice Hiring 2014 - {new Date().getFullYear()} </p>
      </footer>
    </div>
  );
};

export default VideoConferencingPublic;
