// Components
import ContentHeader from "../../components/Content/ContentHeader";
import Card from "../../components/Card/Card";
import Skeleton from "react-loading-skeleton";

// Utilities & Hooks
import { useLogsEmailsGetSpecific } from "../../api/Logs/Logs";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

const EmailLogDetails = () => {
  const { id } = useParams();

  // Fetch the details for the specific SMS log
  const { data, isLoading, isError } = useLogsEmailsGetSpecific(id);

  return (
    <div className="container py--25">
      <ContentHeader
        modifierClass="mb--20"
        title={
          <div className="d-flex align-items-center">
            <Link
              to="/notification-logs/?tab=emails"
              className="d-flex align-items-center fw--medium txt--lg mr--20"
            >
              <BackIcon className="mr--5" />
              Back
            </Link>
            Email Log - {id}
          </div>
        }
      />

      <Card modifierClass="card--padding--xl">
        {isLoading ? (
          <>
            <p className="mb--10 txt--black" data-testid="component:skeleton-logs-email-details">
              <strong>Sent:</strong> <Skeleton width="60px" height="15px" />
            </p>
            <p className="mb--10 txt--black">
              <strong>Status:</strong> <Skeleton width="130px" height="15px" />
            </p>
            <p className="mb--10 txt--black">
              <strong>To:</strong> <Skeleton width="130px" height="15px" />
            </p>
            <p className="mb--10 txt--black">
              <strong>From:</strong> <Skeleton width="130px" height="15px" />
            </p>
            <p className="mb--10 txt--black">
              <strong>Subject:</strong> <Skeleton width="200px" height="15px" />
            </p>

            <Skeleton width="100%" height="380px" />
          </>
        ) : isError ? (
          <p>Failed obtaining details data for the targeted Email Log.</p>
        ) : !data || !Object.entries(data).length ? (
          <h5>No Email Log Details data available.</h5>
        ) : (
          <>
            <p className="mb--10 txt--black">
              <strong>Sent:</strong>{" "}
              {data.sent_timestamp
                ? format(handleDateAsTimestamp(data.sent_timestamp), "MM/dd/yyyy H:mm:ss")
                : "N/A"}
            </p>

            <p className="mb--10 txt--black">
              <strong>Status:</strong> {data.sent_timestamp !== null ? "Successful" : "Failed"}
            </p>

            <p className="mb--10 txt--black">
              <strong>To:</strong> {data.to}
            </p>

            <p className="mb--10 txt--black">
              <strong>From:</strong> {data.from}
            </p>

            <p className="mb--10 txt--black">
              <strong>Subject:</strong> {data.subject}
            </p>

            <iframe
              title="Email Log Details Iframe"
              className="iframe-height"
              srcDoc={data.body}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default EmailLogDetails;
