import * as Yup from "yup";
import { UserRoleIDsEnum } from "../interfaces/global";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT, SCHEMAS_PASSWORD_MESSAGE } from "./constants";

// SCHEMA REGEX
import { EMAIL_REGEX_PATTERN, PASSWORD_REGEX_PATTERN } from "./regexes";

/*========================
  ACCOUNT - USERS (ADMIN)
=========================*/
export const ACCOUNT_ADMIN_USERS_NEW = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter the first name of the user")
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-fname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  last_name: Yup.string()
    .required("Please enter the last name of the user")
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-lname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  title: Yup.string()
    .notRequired()
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address of the user")
    .max(50, "Maximum of 50 characters allowed!")
    .test("admin-user-email", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  password: Yup.string()
    .matches(PASSWORD_REGEX_PATTERN, {
      message: SCHEMAS_PASSWORD_MESSAGE,
    })
    .min(8, "The password needs to be at least 8 characters long.")
    .max(50, "Maximum of 50 characters allowed!")
    .required("Please enter a password"),
  password_confirmation: Yup.string()
    .required("Passwords must be matching")
    .when("password", {
      is: (password: string) => (password && password.length > 0 ? true : false),
      then: schema =>
        schema
          .oneOf([Yup.ref("password")], "Passwords do not match.")
          .required("Please confirm the password"),
    }),
  role_id: Yup.number().nullable().required("Please select the user's role."),
  company_ids: Yup.array()
    .nullable()
    .test("companies-selection", "Please select at least 1 client.", (value, context) => {
      const { role_id } = context.parent;
      const { SUPER_ADMIN, ADMIN } = UserRoleIDsEnum;

      // If the selected role is 'super admin' (ID 1) or 'account manager' (ID 2)
      // then the field is not required. Otherwise if any other role is selected, and there are no
      // selected companies, throw a validation error
      if ([SUPER_ADMIN, ADMIN].includes(role_id)) {
        return true;
      } else if (role_id !== SUPER_ADMIN && !value?.length) {
        return false;
      }

      return true;
    }),
  group_id: Yup.number()
    .nullable()
    .test("group-selection", "Please select the user's group.", (value, context) => {
      const { role_id } = context.parent;
      const { SUPER_ADMIN } = UserRoleIDsEnum;

      // Only make the field required if the selected role is not "super admin"
      // and there is no selected user group at the moment
      if (role_id === SUPER_ADMIN) {
        return true;
      } else if (role_id !== SUPER_ADMIN && !value) {
        return false;
      }

      return true;
    }),
});

export const ACCOUNT_ADMIN_USERS_EDIT_ACCOUNT_DETAILS = Yup.object().shape({
  first_name: Yup.string()
    .required("User's first name must be included")
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-fname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  last_name: Yup.string()
    .required("User's last name must be included")
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-lname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  title: Yup.string()
    .notRequired()
    .nullable()
    .max(30, "Maximum of 30 characters allowed!")
    .test("admin-user-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("User's email address must be included")
    .max(50, "Maximum of 50 characters allowed!")
    .test("admin-user-email", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  role_id: Yup.number().nullable().required("Please select the user's role"),
  company_ids: Yup.array().nullable(),
  password: Yup.string()
    .matches(PASSWORD_REGEX_PATTERN, {
      message: SCHEMAS_PASSWORD_MESSAGE,
    })
    .min(8, "The password needs to be at least 8 characters long.")
    .max(50, "Maximum of 50 characters allowed!")
    .notRequired(),
  password_confirmation: Yup.string()
    .notRequired()
    .when("password", {
      is: (password: string) => {
        return password && password.length > 0 ? true : false;
      },
      then: schema =>
        schema
          .oneOf([Yup.ref("password")], "Passwords do not match.")
          .required("Please confirm the password"),
    }),
});

/*========================
  ACCOUNT - USERS
=========================*/
export const ACCOUNT_USERS_NEW = Yup.object().shape({
  email: Yup.array()
    .required("Please enter at least one email address")
    // Transform the received string into an array & run validation tests for each entity
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s;]+/) : [];
    })
    .test("email-valid-check", function (value) {
      if (value) {
        // Make array of strings that fail the email regex test
        const invalidMails = value.filter(stringValue => {
          return !stringValue.match(EMAIL_REGEX_PATTERN);
        });

        // If there are invalid mails, map the invalid emails inside the error message
        return invalidMails.length
          ? this.createError({
              message: `${invalidMails.map(email => `${email} is not a valid email address`)}`,
            })
          : true;
      } else {
        return false;
      }
    })
    .test("unique-email", function (value) {
      if (value) {
        // Make array of duplicate emails
        const findDuplicates = (arr: string[]) =>
          arr.filter((item, index) => arr.indexOf(item) !== index);
        const duplicateElements = findDuplicates(value);

        // If there are duplicate emails, map the duplicates inside the error message
        return duplicateElements.length
          ? this.createError({
              message: `${duplicateElements.map(email => `${email} is a duplicate email`)}`,
            })
          : true;
      } else {
        return false;
      }
    })
    .test("check-already-existing-user", function (value, context) {
      if (value && context.parent.company_users) {
        // Cross-check each input mail with the already existing users
        const filterUsers = value.filter(inputMail =>
          context.parent.company_users.find(
            (companyUser: { email: string; status: string }) =>
              companyUser.email === inputMail && companyUser.status === "active",
          ),
        );

        // If there are already users in the input, map the emails inside the error message
        if (filterUsers.length) {
          return this.createError({
            message: `${filterUsers.map(email => `${email} is already a user in the company`)}`,
          });
        } else {
          return true;
        }
      } else {
        return false;
      }
    })
    .test("check-pending-invite", function (value, context) {
      if (value && context.parent.company_users) {
        // Cross-check each input mail with the already pending invites
        const filterPending = value.filter(inputMail =>
          context.parent.company_users.find(
            (companyUser: { email: string; status: string }) =>
              companyUser.email === inputMail && companyUser.status === "pending",
          ),
        );

        // If there are pending invites in the input, map the emails inside the error message
        if (filterPending.length) {
          return this.createError({
            message: `${filterPending.map(email => `${email} is already invited to the company`)}`,
          });
        } else {
          return true;
        }
      } else {
        return false;
      }
    }),
  group_id: Yup.number().nullable().required("Please select the group this user will belong to"),
});

export const ACCOUNT_USERS_EDIT = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter the first name of the user")
    .max(30, "Maximum of 30 characters allowed!")
    .test("user-fname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  last_name: Yup.string()
    .required("Please enter the last name of the user")
    .max(30, "Maximum of 30 characters allowed!")
    .test("user-lname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  title: Yup.string()
    .nullable()
    .notRequired()
    .max(30, "Maximum of 30 characters allowed!")
    .test("user-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address of the user")
    .max(50, "Maximum of 50 characters allowed!")
    .test("user-email", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  group_id: Yup.number().nullable().required("Please select the group this user will belong to"),
});

/*========================
  ACCOUNT - MANAGE ALERTS
=========================*/
export const ACCOUNT_MANAGE_ALERTS = Yup.object().shape({
  inhouse_alerts: Yup.array()
    .notRequired()
    // Transform the received string into an array & run validation tests for each entity
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s;]+/) : [];
    })
    .test("email-valid-check", function (value) {
      if (!value) return true;

      // Make array of strings that fail the email regex test
      // or will fail the test that checks if any HTML tags are present
      const invalidMails = value.filter(stringValue => {
        return !stringValue.match(EMAIL_REGEX_PATTERN) || handleCheckStringForHTML(stringValue);
      });

      // If there are invalid mails, map the invalid emails inside the error message
      return invalidMails.length
        ? this.createError({
            message: `${invalidMails.map(email => `${email} is not a valid email address`)}`,
          })
        : true;
    }),
  ads: Yup.array().of(
    Yup.object().shape({
      emails: Yup.array()
        .notRequired()
        .nullable()
        // Transform the received string into an array & run validation tests for each entity
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s;]+/) : [];
        })
        .test("ads-email", function (value) {
          if (!value) return true;

          // Make array of strings that fail the email regex test
          // or will fail the test that checks if any HTML tags are present
          const invalidMails = value.filter(stringValue => {
            return !stringValue.match(EMAIL_REGEX_PATTERN) || handleCheckStringForHTML(stringValue);
          });

          // If there are invalid mails, map the invalid emails inside the error message
          return invalidMails.length
            ? this.createError({
                message: `${invalidMails.map(email => `${email} is not a valid email address`)}`,
              })
            : true;
        }),
    }),
  ),
});
