import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import useErrorReporting from "../../hooks/useErrorReporting";
import handlePhoneStringSanitization from "../../utilities/strings/handlePhoneStringSanitization";

/*===========================================
  LOGS: EMAILS
============================================*/
export interface EmailLogsResponseFields {
  messages: EmailLogFields[];
  total: number;
}
export interface EmailLogFields {
  id: number;
  sent_timestamp: number | null;
  status: number | null;
  from: string;
  to: string;
  subject: string;
}

interface EmailLogSpecificResponseFields {
  id: number;
  sent_timestamp: number;
  from: string;
  to: string;
  subject: string;
  body: string;
}

/**
 *
 * Get a list of all of the email logs
 *
 */
export function useLogsEmailsGetAll(searchedValue: string | undefined) {
  const errorReporting = useErrorReporting();

  return useQuery(
    ["admin-logs-emails", searchedValue],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/messages${searchedValue ? `?search=${searchedValue}` : ""}`,
      )) as EmailLogsResponseFields;
    },
    {
      onError: error => {
        errorReporting("Failed fetching Emails Logs", error);
      },
    },
  );
}

/**
 *
 * Get the details of the targeted email log
 *
 * @param id The ID of the targeted email log
 *
 */
export function useLogsEmailsGetSpecific(id: string | undefined) {
  const errorReporting = useErrorReporting();

  return useQuery(
    ["admin-logs-email-details", id],
    async () => {
      return (await fetchHandler("GET", `admin/messages/${id}`)) as EmailLogSpecificResponseFields;
    },
    {
      enabled: !!id,
      onError: error => {
        errorReporting("Failed fetching Email Log Details", error);
      },
    },
  );
}

/*===========================================
  LOGS: SMS
============================================*/
export interface SMSLogsResponseFields {
  smsMessages: SMSLogFields[];
  total: number;
}
interface SMSLogFields {
  id: number;
  message_id: string;
  status: string;
  from: string;
  to: string;
  action: string;
  content: string;
  sent: number;
}

/**
 *
 * Get a list of all existing SMS logs
 *
 */
export function useLogsSMSGetAll(searchedValue: string | undefined) {
  const errorReporting = useErrorReporting();

  // Sanitize the searched value for SMS
  const sanitizedSearchedValue: string = handlePhoneStringSanitization(searchedValue);

  return useQuery(
    ["admin-logs-sms", searchedValue],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/sms/messages${sanitizedSearchedValue ? `?search=${sanitizedSearchedValue}` : ""}`,
      )) as SMSLogsResponseFields;
    },
    {
      onError: error => {
        errorReporting("Failed fetching SMS Logs", error);
      },
    },
  );
}

/**
 *
 * Get the details for a specific SMS Log
 *
 * @param id The id of the targeted SMS log
 *
 */
export function useLogsSMSGetSpecific(id: string | undefined) {
  const errorReporting = useErrorReporting();

  return useQuery(
    ["admin-logs-sms-details", id],
    async () => {
      return (await fetchHandler("GET", `admin/sms/messages/${id}`)) as SMSLogFields;
    },
    {
      enabled: !!id,
      onError: error => {
        errorReporting("Failed fetching SMS Log Details", error, {
          sms_log_id: id,
        });
      },
    },
  );
}
