// Components
import DropdownActions from "../Dropdown/DropdownActions";

// Interfaces
import { DropdownItem } from "../Dropdown/interfaces";
import { ChatsSortBy, ChatsSortProps } from "./interfaces";

const ChatMessagesSort = ({ sortBy, handleChatMessagesSorting }: ChatsSortProps) => {
  return (
    <div className="chat-sort">
      <h6>Sort by:</h6>
      <DropdownActions
        title={sortBy.split("_").join(" ")}
        items={[
          { text: "Most Recent", value: "most_recent" },
          { text: "Unread", value: "unread" },
          { text: "Blocked", value: "blocked" },
        ]}
        size="sm"
        selectedItem={sortBy}
        handleDropdownItem={(sortOption: DropdownItem) => {
          handleChatMessagesSorting(sortOption.value as ChatsSortBy);
        }}
      />
    </div>
  );
};

export default ChatMessagesSort;
