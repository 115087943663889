import { handleUserInitials } from "../../utilities/strings/handleUserInitials";
import { ChatAvatarProps } from "./interfaces";

// Assets
import { BiBlock as ChatAvatarBlockedIcon } from "react-icons/bi";

const ChatAvatar = ({
  name,
  image_url = null,
  size = "md",
  status = "active",
  modifierClass = "",
}: ChatAvatarProps) => {
  return (
    <div className={`chat-avatar chat-avatar--${status} chat-avatar--${size} ${modifierClass}`}>
      {image_url ? (
        <img src={image_url} alt="Applicant's Avatar" />
      ) : (
        <p className="chat-avatar__initials">{handleUserInitials(name)}</p>
      )}

      {status === "blocked" && (
        <div className="chat-avatar__blocked-indicator">
          <ChatAvatarBlockedIcon />
        </div>
      )}
    </div>
  );
};

export default ChatAvatar;
