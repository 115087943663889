/**
 *
 * Custom utility function for client-side blob download
 *
 * The function initiates a file download for the received
 * blob parameter, using the provided file name.
 *
 */
const handleDownloadBlob = (dataBlob: Blob, fileName: string): void => {
  // Create file URL from received blob & create a link element
  const URL = window.URL.createObjectURL(dataBlob);
  const LINK = document.createElement("a");

  // Append the created file URL as the href attribute to the created link & set file name + extension
  LINK.href = URL;
  LINK.download = `${fileName}`;

  // Append the created link element to the document body & initiate the click event which will open the download prompt
  document.body.appendChild(LINK);
  LINK.click();

  // Invalidate the file URL & remove the created link from the DOM
  window.URL.revokeObjectURL(URL);
  document.body.removeChild(LINK);
};

export default handleDownloadBlob;
