import { getIn } from "formik";
import { FormInputSideLabelProps } from "./interfaces";

const FormInputSideLabel = ({
  form,
  field,
  label,
  isRequired = false,
  modifierClass = "",
  description = "",
  size = "full",
  nestedChildrenElements = null,
  ...props
}: FormInputSideLabelProps) => {
  /*===============================
    HANDLE FORMIK ERRORS
  ================================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    TRIM FORM FIELD VALUE
  ================================*/
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue: string = event.target.value.trim();

    // Update the form value to which this field corresponds to
    form.setFieldValue(field.name, trimmedValue);

    // Trigger internal Formik 'onBlur' events for the field
    field.onBlur(event);
  };

  return (
    <div className={`input input-side-label input--${size} ${modifierClass}`}>
      {label && (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div className="input__wrapper">
        <input
          {...field}
          {...props}
          onBlur={handleOnBlur}
          className={`input__field ${errors && touched ? "input__field--error" : ""}`}
        />

        {/* ADDITIONAL NESTED ELEMENTS IF NEEDED (e.g. ICONS) */}
        {nestedChildrenElements}

        {/* DISPLAY ERROR MESSAGES */}
        {errors && touched && <p className="input__error">{errors}</p>}

        {description && <p className="input__description">{description}</p>}
      </div>
    </div>
  );
};

export default FormInputSideLabel;
