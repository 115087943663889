// Utilities & Hooks
import { useSortable } from "@dnd-kit/sortable";

// Components
import Button from "../Button/Button";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { BucketsDraggableItemProps } from "./interfaces";

// Assets
import { MdDragIndicator as DragHandlerIcon } from "react-icons/md";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

const BucketsDraggableItem = ({
  bucket,
  activeBucketID,
  bucketsRefetching,
  handleBucketEditingNestedModals,
}: BucketsDraggableItemProps) => {
  /*==========================
    DND SORTING
  ===========================*/
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: bucket.id,
  });

  /*==========================
    ADDITIONAL STYLING
    WHILE ONGOING DRAG EVENTS
  ===========================*/
  const style = {
    ...(transform && {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }),
    ...(activeBucketID !== null && activeBucketID !== bucket.id ? { opacity: 0.5 } : undefined),
    transition,
  };

  return (
    <li
      className={`buckets__edit__item ${
        activeBucketID && activeBucketID === bucket.id ? "buckets__edit__item--dragged" : ""
      }`}
      key={bucket.id}
      ref={setNodeRef}
      style={style}
    >
      <div className="row align-items-center">
        <div className="col-2 pl--sm--0">
          <div className="buckets__edit__drag">
            <DragHandlerIcon
              className="buckets__edit__drag__handler"
              {...listeners}
              {...attributes}
            />

            <span>{bucket.bucket_index}</span>
          </div>
        </div>

        <div className="col-3 txt--left">{bucket.name}</div>

        {!bucket.default ? (
          <div className="d-flex justify-content-center align-items-center col-4">
            <div className="row">
              <div
                className={`col-12 ${
                  handlePermissionCheck(["buckets_delete"]) ? "col-md-6 " : ""
                }px--0i`}
              >
                <Button
                  modifierClass="btn--fluid btn--primary mb--md--10 w--100"
                  onClick={() => handleBucketEditingNestedModals(bucket.id, "edit")}
                  isDisabled={bucketsRefetching}
                >
                  Edit
                </Button>
              </div>
              <div className="col-12 col-md-6 px--0i">
                <PermissionCheckComponentWrapper permissions={["buckets_delete"]}>
                  <Button
                    modifierClass="btn--fluid btn--danger ml--10 ml--md--0 w--100"
                    onClick={() => handleBucketEditingNestedModals(bucket.id, "delete")}
                    isDisabled={bucketsRefetching}
                  >
                    Delete
                  </Button>
                </PermissionCheckComponentWrapper>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-4">/</div>
        )}

        <div className="col-3">{bucket.last_updated}</div>
      </div>
    </li>
  );
};

export default BucketsDraggableItem;
