import Skeleton from "react-loading-skeleton";
import TableSkeletonPlaceholder from "../../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";

const ArticlesOverviewSkeleton = () => {
  return (
    <div data-testid="component:articles-overview-skeleton">
      {/* TABLE TAB SECTION */}
      <div className="d-flex align-items-center justify-content-between mb--10">
        <div className="d-flex align-items-center justify-content-between">
          <Skeleton width={225} height={47} className="mr--10" />
          <Skeleton width={120} height={47} className="mr--10" />
          <Skeleton width={155} height={47} />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <Skeleton width={120} height={35} className="mr--10" />
          <Skeleton width={110} height={35} />
        </div>
      </div>

      <TableSkeletonPlaceholder modifierClass="table-wrapper" />
    </div>
  );
};

export default ArticlesOverviewSkeleton;
