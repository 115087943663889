// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseJSON } from "date-fns";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminUsersDelete, useAdminUsersGet } from "../../../../api/Users/AdminUsers";
import { toast } from "react-toastify";
import { useAuth } from "../../../../providers/auth-context";
import useErrorReporting from "../../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../../utilities/strings/handleFullnameCombination";

// Interfaces
import { CompaniesResponseFields } from "../../../../api/Company/interfaces";
import { AdminUsersResponseFields } from "../../../../api/Users/interfaces";
import { AdminUsersListMappedFields, AdminUsersTableProps } from "../interfaces";
import { UserRoleNames } from "../../../../interfaces/global";

// Components
import { AnimatePresence } from "framer-motion";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal/Modal";
import TableSkeletonPlaceholder from "../../../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../../../components/Table/Table";
import PermissionCheckComponentWrapper from "../../../../components/Wrappers/PermissionCheckComponentWrapper";
import Tooltip from "../../../../components/Tooltip/Tooltip";

// Assets
import { BsPatchQuestion as AutologinInfo } from "react-icons/bs";

const AdminUsersTable = ({ searchedUsers, companiesFilter }: AdminUsersTableProps) => {
  const errorReporting = useErrorReporting();
  const { user } = useAuth();

  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<AdminUsersListMappedFields>();
  const USERS_ADMIN_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Name</span>,
      size: 150,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("email", {
      header: () => <span>E-mail</span>,
      size: 200,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 150,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("role", {
      header: () => <span>Role</span>,
      cell: data => <span title={data.getValue()}>{data.getValue() || "N/A"}</span>,
      size: 130,
    }),
    COLUMN_HELPER.accessor("companies", {
      header: () => <span>Companies</span>,
      size: 150,
      cell: data => {
        // Join the list of received companies into a single string
        const companies: string =
          data.getValue().length > 0
            ? data
                .getValue()
                .map((company: CompaniesResponseFields) => company.name)
                .join(", ")
            : "N/A";

        return (
          <span className="txt--ellipsis-vertical" title={companies}>
            {companies}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("last_login_ip", {
      header: () => <span>IP Address</span>,
      size: 130,
      enableSorting: false,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("last_login_time", {
      header: () => <span>Last Login</span>,
      size: 130,
      cell: data => (
        <span>
          {data.getValue() === "N/A"
            ? "N/A"
            : format(parseJSON(data.getValue()), "dd MMM yyyy HH:mm")}
        </span>
      ),
    }),
    ...(user.role === UserRoleNames.SUPER_ADMIN
      ? [
          COLUMN_HELPER.accessor("autologin_token", {
            header: () => (
              <div className="table__header__info">
                <span>Autologin Link</span>
                <Tooltip
                  text={`Link that can be used to login with the selected user's account.\n\nBe sure to use the link in a browser where you're not already logged in into the application`}
                  wrapperModifierClass="table__header__info__tooltip"
                  positioning="bottom"
                  size="xl"
                >
                  <AutologinInfo className="table__header__info__icon" />
                </Tooltip>
              </div>
            ),
            enableSorting: false,
            enableHiding: true,
            size: 140,
            cell: data => (
              <div className="txt--center">
                {data.getValue() ? (
                  <Button
                    modifierClass="btn--text btn--text--primary"
                    onClick={() =>
                      handleAutologinLink(data.cell.getValue(), data.row.original.email)
                    }
                  >
                    Copy Link
                  </Button>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          }),
        ]
      : []),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Action</span>,
      enableSorting: false,
      size: 170,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <div className="table__buttons">
          {user.role === UserRoleNames.ACCOUNT_MANAGER &&
          data.row.original.role === UserRoleNames.SUPER_ADMIN ? (
            <span className="w--100 txt--center">No actions for super admins</span>
          ) : (
            <>
              <PermissionCheckComponentWrapper permissions={["users_admin_edit"]}>
                <Link
                  to={`/account/admin/users/${data.getValue()}/edit/`}
                  className="btn btn--fluid btn--primary--light mr--20 flex-shrink-0"
                >
                  Edit
                </Link>
              </PermissionCheckComponentWrapper>
              <PermissionCheckComponentWrapper permissions={["users_admin_delete"]}>
                <Button
                  modifierClass="btn--fluid btn--danger flex-shrink-0"
                  onClick={() => {
                    setIsDeleteUserModalOpen(true);
                    setDeletedUserID(data.getValue());
                  }}
                >
                  Delete
                </Button>
              </PermissionCheckComponentWrapper>
            </>
          )}
        </div>
      ),
    }),
  ];

  /*=========================
    USERS TABLE DATA
  ==========================*/
  const { data, isLoading, isFetching } = useAdminUsersGet();

  const adminUsers = useMemo(() => {
    // Exit & return a default value if there's no data
    if (!data || !data.length || isLoading) return [];

    // Map the received fields from the API to what we need for the table
    let mappedUsers: AdminUsersListMappedFields[] = data.map((user: AdminUsersResponseFields) => {
      return {
        id: user.id,
        name: handleFullnameCombination(user),
        email: user.email,
        title: user.title || "",
        companies: user.companies.length > 0 ? user.companies : [],
        last_login_ip: user.user_data?.last_login_ip ?? "N/A",
        last_login_time: user.user_data?.last_login_time ?? "N/A",
        role: user.role,
        autologin_token: !["account manager", "super admin"].includes(user.role.toLowerCase())
          ? user.autologin_token
          : null,
      };
    });

    // Filter out the users based on the selected companies
    if (companiesFilter.length) {
      mappedUsers = mappedUsers.filter(user => {
        return user.companies.some(company => {
          return companiesFilter.some(filteredCompany => {
            return company.name.toLowerCase() === filteredCompany.toLowerCase();
          });
        });
      });
    }

    // Filter out the users based on the searched value
    if (searchedUsers) {
      mappedUsers = matchSorter(mappedUsers, searchedUsers, {
        keys: ["name", "email"],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    return mappedUsers;
  }, [data, searchedUsers, companiesFilter]);

  /*=========================
    DELETE A USER
  ==========================*/
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState<boolean>(false);
  const [deletedUserID, setDeletedUserID] = useState<number | null>(null);
  const userDelete = useAdminUsersDelete();

  // Trigger an API call to delete the targeted user
  const handleDeleteUser = async () => {
    try {
      if (!deletedUserID) throw new Error("No user ID found");

      await userDelete.mutateAsync(deletedUserID);

      handleCloseDeleteUserModal();
    } catch (error) {
      errorReporting("Failed deleting user's account from 'Admin' page", error, {
        user_id: deletedUserID,
      });
    }
  };

  // Close the modal and reset to default values
  const handleCloseDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
    setDeletedUserID(null);
  };

  /*=========================
    AUTOLOGIN LINK

    Generate autologin link URL, constructed from
    the clicked user's `email` and `autologin_token` values
  ==========================*/
  const handleAutologinLink = async (token: string | null, email: string) => {
    // Exit function if there's no token for autologin to work with
    if (!token) return;

    const { host, protocol } = window.location;
    const autologinLink: string = `${protocol}//${host}/autologin?email=${email}&token=${token}`;

    try {
      await navigator.clipboard.writeText(autologinLink);
      toast.info("Autologin link copied!");
    } catch (error) {
      toast.error("Failed copying autologin link!");
    }
  };

  return (
    <>
      {isLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper" />
      ) : (
        <Table
          data={adminUsers}
          columns={USERS_ADMIN_TABLE_COLUMNS}
          isRefetching={isFetching}
          paginationPageSize={10}
        />
      )}

      <AnimatePresence>
        {isDeleteUserModalOpen && (
          <Modal
            title="Delete User?"
            text={
              <>
                Are you sure you want to delete{" "}
                <strong className="txt--black">
                  {adminUsers.find(user => user.id === deletedUserID)?.name ?? "this user"}?
                </strong>{" "}
                This action is <strong className="txt--black">irreversible.</strong>
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleCloseDeleteUserModal}
          >
            <div className="modal__actions">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary"
                onClick={handleCloseDeleteUserModal}
              >
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--secondary"
                onClick={handleDeleteUser}
                isDisabled={userDelete.isLoading}
                isLoading={userDelete.isLoading}
              >
                Yes, Delete
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default AdminUsersTable;
