import { useEffect, useRef, useState } from "react";
import { TooltipProps } from "./interfaces";
import useWindowResize from "../../hooks/useWindowResize";

const Tooltip = ({
  text,
  children,
  toggle = "hover",
  positioning = "top",
  size = "sm",
  wrapperModifierClass = "",
  modifierClass = "",
}: TooltipProps) => {
  const [tootlipVisible, setTooltipVisible] = useState<boolean>(false);

  // Toggle the tooltip ONLY on click event
  const handleTooltipOnClick = () => {
    if (toggle !== "click" || !text) return;
    setTooltipVisible(!tootlipVisible);
  };

  // Toggle the tooltip state ONLY when hovering over the item
  const handleTooltipOnHover = (action: "enter" | "leave") => {
    if (toggle !== "hover" || !text) return;

    if (action === "enter") {
      setTooltipVisible(true);
    } else {
      setTooltipVisible(false);
    }
  };

  // Responsive Positioning in case tooltip overflows viewport
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<"left" | "right" | "top" | "bottom" | null>(null);

  // Get the window width
  const [windowWidth] = useWindowResize(200);

  // Repositioning handler
  const handleResponsivePositioning = () => {
    if (!tooltipRef || !tooltipRef.current) return;

    const tooltipSpecs = tooltipRef.current.getBoundingClientRect();

    const isOverflowingRight = tooltipSpecs.left + tooltipSpecs.width + 30 >= windowWidth;

    const isOverflowingLeft = tooltipSpecs.left < 0;

    if (isOverflowingRight) {
      return setPosition("left");
    }
    if (isOverflowingLeft) {
      return setPosition("right");
    }
  };

  useEffect(() => {
    // If the viewport is at least 1200, reset to the received prop positioning
    if (windowWidth > 1200 && position !== positioning) {
      return setPosition(null);
    }

    // If the above condition fails, call the reposition handler
    handleResponsivePositioning();
  }, [tooltipRef, windowWidth]);

  return (
    <div
      className={`p--relative ${wrapperModifierClass}`}
      onClick={handleTooltipOnClick}
      onMouseEnter={() => handleTooltipOnHover("enter")}
      onMouseLeave={() => handleTooltipOnHover("leave")}
    >
      {text && (
        <div
          className={`tooltip tooltip--${!position ? positioning : position} tooltip--${size} ${
            tootlipVisible ? "tooltip--visible" : ""
          } ${modifierClass}`}
          ref={tooltipRef}
        >
          <span className="tooltip__body">{text}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
