// Utilities & Hooks
import { useMemo, useState } from "react";
import { useArticlesGet } from "../../api/Articles/Articles";
import { useDefaultViewSelection } from "../../hooks/useDefaultViewSelection";
import handleDataPagination from "../../utilities/data/handleDataPagination";

// Components
import ArticleCardGrid from "../../components/Articles/ArticleCardGrid";
import ArticleCardList from "../../components/Articles/ArticleCardList";
import Pagination from "../../components/Pagination/Pagination";
import ContentHeader from "../../components/Content/ContentHeader";
import ArticlesSkeleton from "./Skeleton/Articles";
import ViewActions from "../../components/ViewActions/ViewActions";

// Interfaces
import { ArticlesResponseFields } from "../../api/Articles/interfaces";

const Articles = () => {
  /*============================
    VIEW TYPE (GRID vs CLASSIC)
  ==============================*/
  const [activeView, handleViewSelection] = useDefaultViewSelection();

  /*=============================
    ARTICLES PAGINATION
  ==============================*/
  const COUNT_PER_PAGE: number = 10;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);

  const handleArticlesPagination = (selectedPage: number) => setCurrentPage(selectedPage);

  /*=============================
    ARTICLES DATA
  ==============================*/
  const articles = useArticlesGet();

  const articlesData = useMemo(() => {
    // Exit function if there's no data or request is ongoing
    if (!articles.data || !articles.data.length) return [];

    // Total number of pages
    const pageCount: number = Math.ceil(articles.data.length / COUNT_PER_PAGE);
    setTotalPageCount(pageCount);

    // Paginate the mapped data
    const PAGINATED_DATA = handleDataPagination(articles.data, currentPage, COUNT_PER_PAGE);

    return PAGINATED_DATA;
  }, [articles.data, currentPage]);

  return (
    <div className="container py--25">
      <ContentHeader title="Articles">
        <ViewActions activeView={activeView} handleViewSelection={handleViewSelection} />
      </ContentHeader>

      {articles.isLoading ? (
        <ArticlesSkeleton mode={activeView} />
      ) : articlesData.length ? (
        <div className={`container my--30 ${activeView === "grid" ? "container--lg" : ""}`}>
          <div className="row">
            {articlesData.map((article: ArticlesResponseFields) =>
              activeView === "grid" ? (
                <ArticleCardGrid key={article.id} {...article} />
              ) : (
                <ArticleCardList key={article.id} {...article} />
              ),
            )}
          </div>
        </div>
      ) : (
        <h6 className="txt--center py--50">No Articles available.</h6>
      )}

      {totalPageCount > 1 && (
        <div className="d-flex justify-content-end my--20">
          <Pagination
            pageCount={totalPageCount}
            currentPage={currentPage}
            handlePageChange={({ selected }) => handleArticlesPagination(selected)}
          />
        </div>
      )}
    </div>
  );
};

export default Articles;
