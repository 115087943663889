// Utilities & Hooks
import { useAssessmentGetSpecific } from "../../api/Assessment/Assessment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../providers/auth-context";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import { useEffect, useRef } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";

// Components
import AssessmentScoreSummary from "../../components/Assessment/AssessmentData/AssessmentScoreSummary";
import AssessmentWarning from "../../components/Assessment/AssessmentData/AssessmentWarning";
import AssessmentCommentary from "../../components/Assessment/AssessmentData/AssessmentCommentary";
import AssessmentSection from "../../components/Assessment/AssessmentSection";
import AssessmentProgressBarCard from "../../components/Assessment/AssessmentData/AssessmentProgressBarCard";
import AssessmentProgressBarCircleCard from "../../components/Assessment/AssessmentData/AssessmentProgressBarCircleCard";
import AssessmentCard from "../../components/Assessment/AssessmentCard";
import AssessmentProgressBarSummaryCard from "../../components/Assessment/AssessmentData/AssessmentProgressBarSummaryCard";
import AssessmentHeader from "../../components/Assessment/AssessmentHeader";
import AssessmentResultsSkeleton from "./Skeleton/AssessmentResultsSkeleton";
import Button from "../../components/Button/Button";
import AssessmentAISummary from "../../components/Assessment/AssessmentData/AssessmentAISummary";

// Interfaces
import { AssessmentCategoriesFields } from "../../api/Assessment/interfaces";
import { UserRoleNames } from "../../interfaces/global";

const AssessmentResults = () => {
  /*===============================
   ACCESS CONTROL

   If "SBCA" is disabled on company level,
   and user visits this page trough a URL,
   redirect the user to the home (applications) page
  =================================*/
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user.active_company.disable_sbca) {
    toast.info("Access to this page is forbidden because SBCA is disabled.", {
      toastId: "sbca-disabled",
    });
    navigate("/");
  }

  /*=====================
  SBCA REPORT DETAILS
  ======================*/
  const { sbcaReportIdentifier } = useParams();

  const {
    data: sbcaReport,
    isLoading: sbcaReportIsLoading,
    refetch,
    error,
  } = useAssessmentGetSpecific(sbcaReportIdentifier);

  useEffect(() => {
    // Exit if report is still loading or the 'ai_summary' field is null
    if (!sbcaReport || sbcaReportIsLoading) return;

    // Exit if the user is not 'Super Admin', "Account Manager" OR the 'is_regenerated' flag is false
    if (
      (user.role !== UserRoleNames.SUPER_ADMIN && user.role !== UserRoleNames.ACCOUNT_MANAGER) ||
      !sbcaReport.ai_summary.is_regenerated
    ) {
      return;
    }

    // Refetch the data every 15 seconds until the 'is_regenerated' flag is set to false
    const interval = setInterval(() => refetch(), 15000);
    return () => clearInterval(interval);
  }, [sbcaReport, sbcaReportIsLoading, user]);

  /*==============================================
  NAVIGATE & SCROLL TO AI SECTION FEATURE

  ->We set a ref to the 'AI Section' and upon 
  rendering if the 'scrollTo' param is present with
  the "AIInsights" value, we scroll to the section ref.
  ===============================================*/
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  const aiSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (searchParametersObject.scrollTo === "AIInsights" && aiSectionRef.current) {
      aiSectionRef.current.scrollIntoView();

      delete searchParametersObject.scrollTo;
      setSearchParametersObject({ ...searchParametersObject });
    }
  }, [aiSectionRef, searchParametersObject]);

  return (
    <>
      {sbcaReportIsLoading ? (
        <AssessmentResultsSkeleton />
      ) : (
        <>
          {sbcaReport ? (
            <>
              <AssessmentHeader sbcaReport={sbcaReport} />

              <div className="assessment__content">
                <div className="assessment__section">
                  <div className="container container--lg">
                    {/*Overall Observations*/}
                    <AssessmentSection
                      title="Overall Observations"
                      modifierClass="assessment-title--lg"
                    >
                      <AssessmentCard
                        title="Summary Index"
                        score={(+sbcaReport.summary.score).toFixed(2)}
                        scoreColor={sbcaReport.summary.progress_bar.color}
                      >
                        <AssessmentProgressBarSummaryCard data={sbcaReport.summary} />
                      </AssessmentCard>
                    </AssessmentSection>

                    {/*Baseline Characteristics*/}
                    <AssessmentSection title="Baseline Characteristics">
                      <AssessmentProgressBarCircleCard
                        title="Caregiver"
                        type="caregiver"
                        data={sbcaReport.caregiver}
                      />
                      <AssessmentProgressBarCircleCard
                        title="Thinker"
                        type="thinker"
                        data={sbcaReport.thinker}
                      />
                      <AssessmentProgressBarCircleCard
                        title="Processor"
                        type="processor"
                        data={sbcaReport.processor}
                      />
                    </AssessmentSection>

                    {/*Evaluation - Baseline:*/}
                    <AssessmentSection
                      title={`Evaluation - Baseline: ${sbcaReport.evaluation.base_type}`}
                    >
                      {sbcaReport.evaluation.categories.map(
                        (category: AssessmentCategoriesFields, index: number) => (
                          <AssessmentCard key={`categories-${index}`} title={category.label}>
                            <AssessmentProgressBarCard data={category.attribute_rows} />
                          </AssessmentCard>
                        ),
                      )}
                    </AssessmentSection>
                  </div>
                </div>

                <div className="assessment__section bg--white">
                  <div className="container container--lg">
                    {/*Final Thoughts*/}
                    <AssessmentSection title="Final Thoughts">
                      <AssessmentScoreSummary data={sbcaReport.final_thoughts} />
                    </AssessmentSection>

                    {/*Areas of Concern*/}
                    <AssessmentSection title="Areas of Concern">
                      <AssessmentWarning data={sbcaReport.areas_of_concern} />
                    </AssessmentSection>

                    {/*Commentary*/}
                    <AssessmentSection title="Commentary">
                      <AssessmentCommentary
                        name={sbcaReport.prepared_for}
                        data={sbcaReport.commentary}
                      />
                    </AssessmentSection>

                    {/* AI Summary */}
                    <div ref={aiSectionRef}>
                      <AssessmentSection
                        title="AI Insights"
                        modifierClass="m--0"
                        sectionId="AIInsights"
                      >
                        <AssessmentAISummary
                          isRegenerated={sbcaReport.ai_summary.is_regenerated}
                          lastRegeneratedDate={sbcaReport.ai_summary.last_regenerated_date}
                          text={sbcaReport.ai_summary.text}
                          sbcaReportIdentifier={sbcaReportIdentifier!}
                        />
                      </AssessmentSection>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="container py--25">
              <div className="d-flex flex-column align-items-center">
                <h1 className="mb--10">There was an issue displaying the SBCA Results:</h1>
                <h3 className="mb--20">{handleErrorMessage(error)}</h3>
                <p>
                  Please check the URL that you're trying to access and be sure that you have the
                  permissions to see this page.
                </p>
                <div className="d-flex flex-column align-content-center">
                  <Button modifierClass="btn--fluid btn--primary mb--10">Reload</Button>
                  <Link className="txt--sm" to="/applications/">
                    Go back to Applications
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AssessmentResults;
