// Form Components
import { Field, Form, Formik, FormikProps } from "formik";
import FormInput from "../../components/Form/FormInput";
import FormPhoneInput from "../../components/Form/FormPhoneInput";
import FormDropdownSearchable from "../../components/Form/FormDropdownSearchable";

// Components
import Button from "../../components/Button/Button";
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../components/Table/Table";

// Hooks & Utilities
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { useGetSBCATypes } from "../../api/SBCA/SbcaTypes";
import { toast } from "react-toastify";
import {
  useAssessmentGetSbcaDirectLogs,
  useAssessmentResendSbcaDirectRequest,
  useAssessmentSendDirectRequest,
} from "../../api/Assessment/Assessment";
import { useCompaniesGetJobPositionsForCurrentCompany } from "../../api/Company/Company";
import useErrorReporting from "../../hooks/useErrorReporting";
import { useAuth } from "../../providers/auth-context";
import { useNavigate } from "react-router-dom";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";

export interface AssessmentDirectRequestFormFields {
  first_name: string;
  last_name: string;
  email: string;
  position: string | null;
  sbca_type_id: number | null;
  phone: string | null;
}

interface AssessmentDirectRequestTableFields {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  position: string;
  sbca_type: string;
  date: string;
}

const ASSESSMENT_REQUEST_SCHEMA = Yup.object().shape({
  first_name: Yup.string().required("Please enter the first name of the applicant"),
  last_name: Yup.string().required("Please enter the last name of the applicant"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email of the applicant"),
  phone: Yup.string()
    .nullable()
    .test("phone-number", "Please enter a valid phone number", phone => {
      // This field is optional, so if no value is entered, do not throw any errors
      if (!phone) return true;

      // Throw an error if there are not enough characters for valid phone number
      // This phone value is already sanitized from potential brackets, empty spaces and dashes
      // and has an appended +1 prefix to it, that's why the check is set for less than 12 characters
      if (phone.length < 12) return false;

      return true;
    }),
  position: Yup.string().nullable().required("Please select a job position"),
  sbca_type_id: Yup.number().nullable().required("Please select the SBCA type"),
});

export default function AssessmentRequest() {
  const errorReporting = useErrorReporting();
  const formRef = useRef<FormikProps<AssessmentDirectRequestFormFields>>(null);

  /*====================================
    CURRENT COMPANY AVAILABLE POSITIONS
  ======================================*/
  const { data: positionsData, isLoading: positionsLoading } =
    useCompaniesGetJobPositionsForCurrentCompany();
  const [positions, setPositions] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!positionsData || !positionsData.length || positionsLoading) return;

    const mappedPositions = positionsData.map(position => {
      return {
        text: position,
        value: position,
      };
    });
    setPositions(mappedPositions);
  }, [positionsData]);

  /*===============================
    SBCA TYPES
  ================================*/
  const { data: sbcaTypesData, isLoading: sbcaTypesLoading } = useGetSBCATypes();
  const [sbcaTypes, setSbcaTypes] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!sbcaTypesData || !sbcaTypesData.length || sbcaTypesLoading) return;

    const mappedSbcaTypes = sbcaTypesData.map(sbca => {
      return { text: `SBCA - ${sbca.name}`, value: sbca.id };
    });

    setSbcaTypes(mappedSbcaTypes);
  }, [sbcaTypesData]);

  /*===============================
    FORM SBCA REQUEST
  ================================*/
  const directRequest = useAssessmentSendDirectRequest();
  const [successfulDirectRequestCount, setSuccessfulDirectRequestCount] = useState<number>(0);

  const handleRequestSbca = async (values: AssessmentDirectRequestFormFields) => {
    if (!formRef.current) {
      toast.error("Form is not initialized correctly.");
      return;
    }

    try {
      await directRequest.mutateAsync(values);

      // Reset the form to the default values once its successfully submitted
      formRef.current.resetForm();
      setSuccessfulDirectRequestCount(prevState => (prevState += 1));
    } catch (error) {
      errorReporting("Failed sending direct SBCA request!", error);
    }
  };

  /*===============================
    TABLE SECTION
  ================================*/
  const COLUMN_HELPER = createColumnHelper<AssessmentDirectRequestTableFields>();
  const ASSESSMENT_REQUESTS_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("first_name", {
      header: () => <span>First Name</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 100,
    }),
    COLUMN_HELPER.accessor("last_name", {
      header: () => <span>Last Name</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 100,
    }),
    COLUMN_HELPER.accessor("email", {
      header: () => <span>Email</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 120,
    }),
    COLUMN_HELPER.accessor("position", {
      header: () => <span>Job Position</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 120,
    }),
    COLUMN_HELPER.accessor("sbca_type", {
      header: () => <span>SBCA Type</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 100,
    }),
    COLUMN_HELPER.accessor("date", {
      header: () => <span>Date Submitted</span>,
      cell: data => (
        <span>
          {data.getValue() ? format(new Date(data.getValue()), "dd MMM yyyy hh:mm:ss aa") : "N/A"}
        </span>
      ),
      size: 150,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Action</span>,
      enableSorting: false,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <div className="d-flex justify-content-end">
          <Button
            isLoading={resendRequestID === data.getValue() && resendDirectRequest.isLoading}
            isDisabled={resendRequestID === data.getValue() && resendDirectRequest.isLoading}
            modifierClass="btn--fluid btn--primary"
            onClick={() => handleResendRequest(data.getValue())}
          >
            Resend
          </Button>
        </div>
      ),
      size: 100,
    }),
  ];

  /*===================================
    TABLE DATA
  ====================================*/
  const {
    data: logsData,
    isLoading: logsLoading,
    isRefetching: logsRefetching,
  } = useAssessmentGetSbcaDirectLogs();

  const TABLE_LOGS_DATA = useMemo(() => {
    if (!logsData || !logsData.length || logsLoading) return [];

    // Remap the received logs data into fields usable by the table columns
    const remappedData: AssessmentDirectRequestTableFields[] = logsData.map(log => {
      return {
        id: log.id,
        first_name: log.user.first_name || "N/A",
        last_name: log.user.last_name || "N/A",
        email: log.user.email || "N/A",
        position: log.position || "N/A",
        sbca_type: log.sbca_type.name || "N/A",
        date: log.created_at || "",
      };
    });

    return remappedData;
  }, [logsData]);

  /*===================================
    RESEND INDIVIDUAL DIRECT REQUESTS
  ====================================*/
  const resendDirectRequest = useAssessmentResendSbcaDirectRequest();
  const [resendRequestID, setResendRequestID] = useState<number | null>(null);

  const handleResendRequest = async (requestID: number) => {
    setResendRequestID(requestID);

    try {
      await resendDirectRequest.mutateAsync(requestID);
    } catch (error) {
      errorReporting("Failed resending SBCA Direct Request!", error, { requestID });
    } finally {
      setResendRequestID(null);
    }
  };

  //  If the currently active company has SBCA disabled, redirect to the main dashboard
  const { user } = useAuth();
  const navigate = useNavigate();
  if (user.active_company.disable_sbca) {
    navigate("/applications/");
  }

  return (
    <div className="container py--25">
      <h3 className="txt--blue fw--semibold mb--5">Request Form</h3>
      <p>Send an email request for SBCA form completion</p>

      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          position: null,
          sbca_type_id: null,
          phone: null,
        }}
        validationSchema={ASSESSMENT_REQUEST_SCHEMA}
        onSubmit={handleRequestSbca}
        innerRef={formRef}
      >
        {({ setFieldValue }) => (
          <Form key={successfulDirectRequestCount}>
            <div className="row">
              {/* FIRST NAME */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormInput}
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  label="First Name"
                  modifierClass="mb--10"
                />
              </div>

              {/* LAST NAME */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormInput}
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                  modifierClass="mb--10"
                />
              </div>

              {/* EMAIL */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormInput}
                  name="email"
                  id="email"
                  placeholder="Email"
                  label="Email"
                  modifierClass="mb--10"
                />
              </div>

              {/* PHONE */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormPhoneInput}
                  name="phone"
                  id="phone"
                  placeholder="(xxx) xxx-xxxx"
                  label="Phone"
                  modifierClass="mb--10"
                />
              </div>

              {/* JOB POSITION */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormDropdownSearchable}
                  id="position"
                  name="position"
                  size="full"
                  items={positions}
                  isLoading={positionsLoading}
                  disabled={!positions.length || positionsLoading}
                  placeholder="Select Job Position"
                  handleFieldUpdate={(position: DropdownItem) =>
                    setFieldValue("position", position.value)
                  }
                  label="Job Position"
                  modifierClass="dropdown--default-label mb--10"
                />
              </div>

              {/* SBCA TYPE */}
              <div className="col-12 col-lg-6">
                <Field
                  component={FormDropdownSearchable}
                  id="sbca_type_id"
                  name="sbca_type_id"
                  size="full"
                  items={sbcaTypes}
                  isLoading={sbcaTypesLoading}
                  disabled={!sbcaTypes.length || sbcaTypesLoading}
                  placeholder="Select SBCA Type"
                  handleFieldUpdate={(sbca_type_id: DropdownItem) =>
                    setFieldValue("sbca_type_id", sbca_type_id.value)
                  }
                  label="SBCA Type"
                  modifierClass="dropdown--default-label mb--40"
                />
              </div>
            </div>

            <Button
              isLoading={directRequest.isLoading}
              isDisabled={directRequest.isLoading}
              modifierClass="btn--fluid btn--primary mb--30"
            >
              Send SBCA Request
            </Button>
          </Form>
        )}
      </Formik>

      <h3 className="txt--blue fw--semibold mb--5">SBCA Requests</h3>
      <p>All of the SBCA requests that have been sent out but haven't been filled out yet</p>

      {logsLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper" />
      ) : (
        <Table
          data={TABLE_LOGS_DATA}
          columns={ASSESSMENT_REQUESTS_TABLE_COLUMNS}
          isRefetching={logsRefetching}
          paginationPageSize={10}
          noDataMessage="No SBCA Direct Requests at the moment"
        />
      )}
    </div>
  );
}
