import { Link, useNavigate, useParams } from "react-router-dom";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Components
import { Field, Form, Formik } from "formik";
import ContentHeader from "../../../components/Content/ContentHeader";
import Button from "../../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import Loader from "../../../components/Loader/Loader";
import FormInputSideLabel from "../../../components/Form/FormInputSideLabel";
import FormDropdown from "../../../components/Form/FormDropdown";

// Interfaces
import { UsersEditFormikFields } from "../../../api/Users/interfaces";
import { DropdownItem } from "../../../components/Dropdown/interfaces";
import { GroupFields } from "../../../api/Groups/interfaces";

// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { useUsersEditSpecific, useUsersGetSpecific } from "../../../api/Users/Users";
import { useAuth } from "../../../providers/auth-context";
import { useGroupsGetAll } from "../../../api/Groups/Groups";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

// Schemas
import { ACCOUNT_USERS_EDIT } from "../../../schemas/AccountSchemas";

const UsersEdit = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const errorReporting = useErrorReporting();
  const navigate = useNavigate();

  /*==============================
    FORM FIELDS VALUES
  ===============================*/
  const [userFullname, setUserFullname] = useState<string>("");
  const [userFormDetails, setUserFormDetails] = useState<UsersEditFormikFields>({
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    group_id: null,
  });

  /*==============================
    OBTAIN THE LIST OF ROLE GROUPS
  ===============================*/
  const { data: groups, isLoading: groupsIsLoading } = useGroupsGetAll();

  const ROLE_GROUPS: DropdownItem[] = useMemo(() => {
    if (!groups || !groups.length || groupsIsLoading) return [];

    const mappedGroups: DropdownItem[] = groups.map((group: GroupFields) => {
      return { text: group.name, value: group.id, description: group.description };
    });

    return mappedGroups;
  }, [groups]);

  /*==============================
    GET TARGETED USER'S DETAILS
  ===============================*/
  const { data: userData, isLoading: userIsLoading } = useUsersGetSpecific(
    user.active_company.slug,
    id,
  );

  useEffect(() => {
    if (!userData || !Object.entries(userData).length || userIsLoading) return;

    // Redirect if user doesnt belong to any group
    if (!userData.group_id) navigate("/403/");

    // Construct the user's fullname
    const fullname: string = handleFullnameCombination(userData);

    setUserFullname(fullname);
    setUserFormDetails({
      first_name: userData.first_name,
      title: userData.title ?? "",
      last_name: userData.last_name,
      email: userData.email,
      group_id: userData.group_id,
    });
  }, [userData]);

  /*==============================
    EDIT SPECIFIED USER'S DETAILS
  ===============================*/
  const editUser = useUsersEditSpecific();

  const handleUserEdit = async (userDetails: UsersEditFormikFields) => {
    // Exit function if there's no valid "active_company" or user "id" value
    if (!user.active_company.slug || !id) return;

    try {
      // Construct the payload that will be sent in the request
      const editRequestPayload: UsersEditFormikFields = {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        title: userDetails.title,
        email: userDetails.email,
        group_id: userDetails.group_id,
      };

      await editUser.mutateAsync({
        userDetails: editRequestPayload,
        userId: id,
      });
    } catch (error) {
      errorReporting("Failed editing user details", error, { user_id: id, ...userDetails });
    }
  };

  return (
    <div className="container py--25">
      <ContentHeader
        title={
          <div className="d-flex align-items-center">
            Edit User -{" "}
            {userIsLoading ? <Skeleton width="120px" height="20px" /> : userFullname || "N/A"}
          </div>
        }
      ></ContentHeader>

      <Link to="/account/users/" className="d-inline-flex align-items-center txt--lg my--20">
        <BackIcon className="txt--grey" />
        <span className="txt--blue fw--regular">Back</span>
      </Link>

      {userIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <Formik
          enableReinitialize
          initialValues={userFormDetails}
          validationSchema={ACCOUNT_USERS_EDIT}
          onSubmit={handleUserEdit}
        >
          {({ values }) => (
            <Form
              style={{
                maxWidth: 700,
                width: "100%",
                margin: "0 auto",
              }}
            >
              <Field
                component={FormInputSideLabel}
                label="First Name"
                id="first_name"
                name="first_name"
                modifierClass="input-side-label mb--15"
                size="full"
              />

              <Field
                component={FormInputSideLabel}
                label="Last Name"
                id="last_name"
                name="last_name"
                modifierClass="input-side-label mb--15"
                size="full"
              />

              <Field
                component={FormInputSideLabel}
                label="Title"
                id="title"
                name="title"
                modifierClass="input-side-label mb--15"
                size="full"
              />

              <Field
                component={FormInputSideLabel}
                label="Email"
                id="email"
                name="email"
                description="The email address will be used to deliver instructions to login to the system and choose a password."
                modifierClass="input-side-label mb--15"
                size="full"
              />

              <Field
                component={FormDropdown}
                id="group_id"
                name="group_id"
                title="Select a group"
                items={ROLE_GROUPS}
                size="full"
                label="User Group"
                preselectedItemValue={values.group_id}
                isLoading={groupsIsLoading}
                disabled={groupsIsLoading || !ROLE_GROUPS.length}
                modifierClass="dropdown--side-label mb--15"
                framerAnimationCustomProps={{ hasSideLabel: true }}
              />

              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary mt--20 ml--135"
                isLoading={editUser.isLoading}
                isDisabled={editUser.isLoading}
              >
                Edit User
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UsersEdit;
