import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

// Assests
import { IoCheckmarkCircle as SuccessIcon } from "react-icons/io5";
import { AssessmentModalSuccessfulRequestProps } from "./interfaces";

const AssessmentModalSuccessfulRequest = ({
  title,
  text,
  subtitle = "Success!",
  handleCloseModal,
}: AssessmentModalSuccessfulRequestProps) => {
  return (
    <Modal
      title={title}
      text=""
      modifierClass="modal--fixated application-resume-modal-success"
      handleCloseModal={handleCloseModal}
    >
      <div className="modal__icon-container">
        <SuccessIcon />
        <h2>{subtitle}</h2>
      </div>

      <p>{text}</p>

      <div className="d-flex justify-content-end">
        <Button
          type="button"
          modifierClass="btn--fluid btn--fluid--md btn--primary--light"
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default AssessmentModalSuccessfulRequest;
