// Hooks & Utilities
import { useAuth } from "../../providers/auth-context";
import { useEffect, useState } from "react";

// Assets
import Logo from "../../assets/images/fch-logo.png";
import ApplicationsIcon from "../../assets/images/icons/applications-icon.svg?react";
import AppointmentIcon from "../../assets/images/icons/appointments-icon.svg?react";

// Interfaces
import { HeaderProps } from "./interfaces";

// Components
import { Link, useLocation } from "react-router-dom";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import HeaderDropdownAdminMenu from "./HeaderDropdownAdminMenu";
import HeaderDropdownWebsite from "./HeaderDropdownWebsite";
import ArticlesNotificationsMenu from "../Articles/ArticlesNotificationsMenu";
import HeaderCompaniesMenu from "./HeaderCompaniesMenu";
import Tooltip from "../Tooltip/Tooltip";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import ChatMessagesDropdown from "../Chat/ChatMessagesDropdown";
import HeaderDropdownNeedHelp from "./HeaderDropdownHelp";
import HeaderCareerPages from "./HeaderCareerPages";

const Header = ({ isFullHeader = true }: HeaderProps) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  /*================================
    CONTROL COMPANIES MENU DISPLAY
  =================================*/
  const [shouldShowCompaniesMenu, setShouldShowCompaniesMenu] = useState<boolean>(false);

  useEffect(() => {
    // If user doesn't have access to any company, do not display the menu
    // Super Admins and Account Manager (admins) have access to all companies
    if (!["super admin", "admin"].includes(user?.role?.toLowerCase()) && !user.companies.length) {
      setShouldShowCompaniesMenu(false);
      return;
    }

    // Do not display the menu if the page is not supposed to do that
    if (!isFullHeader) {
      setShouldShowCompaniesMenu(false);
      return;
    }

    setShouldShowCompaniesMenu(true);
  }, [user, pathname]);

  return (
    <header className="header">
      {/*LOGO*/}
      {!["super admin", "admin"].includes(user.role?.toLowerCase()) && !user.active_company.slug ? (
        <div className="header__logo">
          <img src={Logo} alt="FirstChoice Hiring logo" width={55} />
        </div>
      ) : (
        <Link title="Homepage" to="/applications/" className="header__logo">
          <img src={Logo} alt="FirstChoice Hiring logo" width={55} />
        </Link>
      )}

      {/* COMPANIES */}
      {shouldShowCompaniesMenu ? <HeaderCompaniesMenu /> : null}

      <span className="flex-grow-1" />

      <nav className="header__nav">
        {/*ACCOUNT DROPDOWN*/}
        <HeaderDropdownAccount />

        <div className="d-flex justify-content-between w--100">
          {/*MESSAGES*/}
          <PermissionCheckComponentWrapper permissions={["sms_read"]}>
            <ChatMessagesDropdown />
          </PermissionCheckComponentWrapper>

          {/*APPLICATIONS*/}
          <PermissionCheckComponentWrapper permissions={["applicant_dashboard_view"]}>
            <Tooltip text="Applicants" positioning="bottom">
              <Link title="Applicants" to="/applications/" className="header__icon">
                <ApplicationsIcon />
              </Link>
            </Tooltip>
          </PermissionCheckComponentWrapper>

          {/*APPOINTMENTS*/}
          <PermissionCheckComponentWrapper permissions={["applicant_appointments_view"]}>
            <Tooltip text="Appointments" positioning="bottom">
              <Link title="Appointments" to="/appointments/" className="header__icon">
                <AppointmentIcon />
              </Link>
            </Tooltip>
          </PermissionCheckComponentWrapper>
        </div>

        <div className="d-flex justify-content-between w--100">
          {/* Career Pages */}
          {user.role ? <HeaderCareerPages /> : null}

          {/*Standard & Custom application dropdown*/}
          {user.active_company?.name ? (
            <HeaderDropdownWebsite modifierClass="header__icon" />
          ) : null}

          {/* NEED HELP */}
          {user.role ? <HeaderDropdownNeedHelp /> : null}

          {/*NOTIFICATIONS/ARTICLES*/}
          <PermissionCheckComponentWrapper permissions={["articles_user_view"]}>
            <Tooltip text="Notifications" positioning="bottom">
              <ArticlesNotificationsMenu />
            </Tooltip>
          </PermissionCheckComponentWrapper>
        </div>

        {/*SETTINGS DROPDOWN*/}
        {user.role?.toLowerCase() === "super admin" || user.permissions.length > 0 ? (
          <HeaderDropdownAdminMenu modifierClass="header__icon" />
        ) : null}
      </nav>
    </header>
  );
};
export default Header;
