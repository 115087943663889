// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";

// Interfaces
import { JobBoardsFormsProps } from "../interfaces";

const CraigslistForm = ({ values, validateField }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.craigslist.enabled) validateField("craigslist");
  }, [values.craigslist]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="craigslist.enabled"
        id="craigslist.enabled"
        label="Enable Craigslist"
        checked={values.craigslist.enabled}
        labelModifierClass="input__label--sm mb--0i"
      />

      {values.craigslist.enabled ? (
        <p className="fw--medium">
          Manual posting required. Please save changes with this enabled to help track status.
        </p>
      ) : null}
    </>
  );
};

export default CraigslistForm;
