import { ContentHeaderProps } from "./interfaces";

const ContentHeader = ({ title, children, modifierClass = "" }: ContentHeaderProps) => {
  return (
    <div className={`content__header ${modifierClass}`}>
      <h5 className="content__title">{title}</h5>
      {children && <div className="content__children">{children}</div>}
    </div>
  );
};

export default ContentHeader;
