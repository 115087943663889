// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import fetchHandler from "../fetchHandler";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";

// Interfaces
import { DashboardViewMode } from "../../interfaces/global";
import { UsersResponseFields } from "../Users/interfaces";
import { UserUpdateProfileSettingsPayload } from "./interfaces";

/**
 *
 * Updates the profile settings of the
 * currently logged-in user
 *
 */
export function useUserUpdateProfileSettings() {
  return useMutation(
    async (profileSettings: UserUpdateProfileSettingsPayload) => {
      return await fetchHandler("POST", "user/profile-settings", profileSettings);
    },
    {
      onSuccess: () => toast.success("Successfully updated profile settings."),
      onError: error => error,
    },
  );
}

/**
 *
 * Update the default dashboard view that will be presented to the user
 *
 */
export function useUserUpdateDashboardView() {
  return useMutation(
    async (dashboardView: DashboardViewMode) => {
      return await fetchHandler("POST", `user/default-dashboard`, {
        default_dashboard_view: dashboardView,
      });
    },
    {
      onMutate: dashboardView => {
        toast.success(
          `Default Dashboard View changed to: ${handleStringCapitalization(dashboardView, [" "])}`,
          { toastId: "user-default-dashboard-view" },
        );
      },
      onError: error => {
        // Dismiss the success notification from the UI first
        toast.dismiss("user-default-dashboard-view");

        return error;
      },
    },
  );
}

/**
 *
 * Get the currently logged in user's profile data
 *
 * @param isAuthenticated Indicates if the user is logged in into the
 * application, enabling the request to be sent. If not, then no request will be sent
 *
 */
export function useUserProfileData(isAuthenticated: boolean = false) {
  return useQuery(
    ["user-profile"],
    async () => {
      return (await fetchHandler("GET", "user/profile")) as UsersResponseFields;
    },
    { enabled: isAuthenticated, onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Update the logged in user's active company
 *
 * The mutation hook takes a "companyID" parameter, representing
 * the selected company from the company selection dropdown menu
 */
export function useUserSetActiveCompany() {
  const queryClient = useQueryClient();

  return useMutation(
    (companyID: number) => {
      return fetchHandler("POST", "user/company/active", { company_id: companyID });
    },
    {
      onError: error => error,
      onSuccess: () => {
        queryClient.invalidateQueries(["user-profile"]);
      },
    },
  );
}
