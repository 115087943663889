// Hooks & Utilities
import { useMemo, useState } from "react";
import { handleStripProtocolFromLinks } from "../../utilities/strings/handleStripProtocolFromLinks";
import { createColumnHelper } from "@tanstack/react-table";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";
import {
  useMarketingBannersCreate,
  useMarketingBannersDelete,
  useMarketingBannersEdit,
  useMarketingBannersGetAll,
  useMarketingBannersReorder,
  useMarketingBannersToggleStatus,
} from "../../api/Marketing/Banners";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import * as Yup from "yup";
import useErrorReporting from "../../hooks/useErrorReporting";
import parse from "html-react-parser";

// Schemas
import { WEBSITE_URL_REGEX_PATTERN } from "../../schemas/regexes";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import ContentHeader from "../../components/Content/ContentHeader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import FormInput from "../../components/Form/FormInput";
import Toggle from "../../components/Toggle/Toggle";
import Tooltip from "../../components/Tooltip/Tooltip";
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import TextEditorConfigurable from "../../components/WYSIWYG/TextEditorConfigurable";

// Interfaces
import { BannerCreatePayload, BannerStatus } from "../../api/Marketing/interfaces";
import TableDnD, { TableDragHandleCell } from "../../components/Table/TableDnD";

const BANNERS_CREATE_SCHEMA = Yup.object().shape({
  banner_text: Yup.string()
    .transform((value: string) => {
      // Remove any HTML tags and HTML entities (e.g. '&nbsp;')
      // from the received text value before running the validation schema
      const sanitizedBannerText = value.replace(/(<.*?>)|(&.*;)/gi, "");
      return sanitizedBannerText;
    })
    .max(150, "Banner text cannot be longer than 150 characters!")
    .required("Please enter banner text!"),
  banner_link: Yup.string().matches(WEBSITE_URL_REGEX_PATTERN, {
    message: "Please enter a valid website URL.",
  }),
});

interface BannersListMappedFields {
  id: number;
  text: string;
  hyperlink: string;
  is_active: boolean;
}

function BannersPage() {
  const errorReporting = useErrorReporting();
  const [showBannerCreateModal, setShowBannerCreateModal] = useState<boolean>(false);

  const BANNERS_TABLE_COLUMNS = useMemo(() => {
    const COLUMN_HELPER = createColumnHelper<BannersListMappedFields>();
    const columns = [
      COLUMN_HELPER.display({
        id: "reorder",
        size: 10,
        cell: data => <TableDragHandleCell rowId={data.row.original.id} />,
      }),
      COLUMN_HELPER.accessor("text", {
        header: () => <span>Banner Text</span>,
        size: 400,
        enableSorting: false,
        cell: data => <span>{parse(data.getValue())}</span>,
      }),
      COLUMN_HELPER.accessor("hyperlink", {
        header: () => <span>Banner Link</span>,
        size: 300,
        enableSorting: false,
        cell: data => {
          const link: string = data.getValue();
          return link ? (
            <a href={link} target="_blank">
              {link}
            </a>
          ) : (
            <span>N/A</span>
          );
        },
      }),
      COLUMN_HELPER.accessor("is_active", {
        header: () => <span>Status</span>,
        size: 100,
        enableSorting: false,
        cell: data => {
          const bannerID = data.row.original.id;
          const bannerStatus = data.getValue() ? "active" : "inactive";

          return (
            <div className="d-flex justify-content-start" key={bannerID}>
              <Tooltip text={handleStringCapitalization(bannerStatus, [" "])}>
                <Toggle
                  id={`${bannerID}-banner_status`}
                  name={`${bannerID}-banner_status`}
                  textLeft=""
                  textRight=""
                  isToggled={data.getValue()}
                  isDisabled={bannerID === 0 || !handlePermissionCheck(["marketing_banners_edit"])}
                  handleOnChange={event => handleToggleBannerStatus(bannerID, event)}
                />
              </Tooltip>
            </div>
          );
        },
      }),
      COLUMN_HELPER.accessor("id", {
        header: () => <span>Actions</span>,
        size: 150,
        enableSorting: false,
        meta: {
          headerModifierClass: "justify-content-end",
        },
        cell: data => {
          const { id, text, hyperlink, is_active } = data.row.original;

          return (
            <div className="table__buttons">
              <PermissionCheckComponentWrapper permissions={["marketing_banners_edit"]}>
                <Button
                  modifierClass="btn--text btn--text--secondary mr--5"
                  isDisabled={id === 0}
                  onClick={() => {
                    setTargetedBanner({ id, text, hyperlink, is_active });
                    setShowBannerEditModal(true);
                  }}
                  aria-label="button:edit-banner"
                >
                  Edit
                </Button>
              </PermissionCheckComponentWrapper>

              <PermissionCheckComponentWrapper permissions={["marketing_banners_delete"]}>
                <Button
                  modifierClass="btn--text btn--text--danger"
                  isDisabled={id === 0}
                  onClick={() => {
                    setTargetedBanner({ id, text, hyperlink, is_active });
                    setShowBannerDeleteModal(true);
                  }}
                  aria-label="button:delete-banner"
                >
                  Delete
                </Button>
              </PermissionCheckComponentWrapper>
            </div>
          );
        },
      }),
    ];

    return columns;
  }, []);

  /*================================
    GET ALL EXISTING BANNERS
  =================================*/
  const { data, isLoading } = useMarketingBannersGetAll();
  const [targetedBanner, setTargetedBanner] = useState<Record<string, unknown> | null>(null);

  const BANNERS_DATA = useMemo(() => {
    if (!data || !data.length || isLoading) return [];

    // Sort the banners shown in the table based on their order
    const sortedBanners = [...data].sort((bannerA, bannerB) => {
      return bannerA.order > bannerB.order ? 1 : -1;
    });

    return sortedBanners;
  }, [data]);

  /*================================
    CREATE NEW BANNERS
  =================================*/
  const createNewBanner = useMarketingBannersCreate();
  const handleCreateNewBanner = async (values: FormikValues) => {
    try {
      // Close the modal
      setShowBannerCreateModal(false);

      const { banner_text, banner_link } = values;

      // Clean up the provided banner link and make sure its always using HTTPS
      const sanitizedBannerLink = handleStripProtocolFromLinks(banner_link);

      // Send request to the API to create a new banner
      const bannerPayload: BannerCreatePayload = {
        text: banner_text,
        hyperlink: sanitizedBannerLink,
      };
      await createNewBanner.mutateAsync(bannerPayload);
    } catch (error) {
      errorReporting("Failed creating new marketing banner!", error);
    }
  };

  /*================================
    EDIT TARGETED BANNER
  =================================*/
  const [showBannerEditModal, setShowBannerEditModal] = useState<boolean>(false);
  const editBanner = useMarketingBannersEdit();

  const handleEditModal = async (values: FormikValues) => {
    if (!targetedBanner) return;

    try {
      // Close the modal
      setShowBannerEditModal(false);

      // Clean up the provided banner link and make sure its always using HTTPS
      const sanitizedBannerLink = handleStripProtocolFromLinks(values.banner_link);

      // Send request to the API
      await editBanner.mutateAsync({
        id: targetedBanner.id as number,
        text: values.banner_text,
        hyperlink: sanitizedBannerLink,
      });
    } catch (error) {
      errorReporting("Failed editing the targeted banner!", error, { ...targetedBanner });
    } finally {
      // Clear out the state for the targeted banner
      setTargetedBanner(null);
    }
  };

  /*================================
    DELETE TARGETED BANNER
  =================================*/
  const [showBannerDeleteModal, setShowBannerDeleteModal] = useState<boolean>(false);
  const deleteBanner = useMarketingBannersDelete();

  const handleDeleteBanner = async () => {
    if (!targetedBanner) return;

    try {
      // Close the modal
      setShowBannerDeleteModal(false);

      // Send request to the API
      await deleteBanner.mutateAsync(targetedBanner.id as number);
    } catch (error) {
      errorReporting("Failed deleting targeted banner!", error, { targetedBanner });
    } finally {
      // Clear out the state for the targeted banner
      setTargetedBanner(null);
    }
  };

  /*================================
    TOGGLE BANNER STATUS
  =================================*/
  const toggleStatus = useMarketingBannersToggleStatus();

  const handleToggleBannerStatus = async (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const status: BannerStatus = event.target.checked ? "active" : "inactive";

    try {
      await toggleStatus.mutateAsync(id);
    } catch (error) {
      errorReporting("Failed updating banner status!", error, { bannerID: id, status });
    }
  };

  /*================================
    REORDER BANNERS
  =================================*/
  const reorderBanners = useMarketingBannersReorder();
  const handleUpdateData = async (reorderedBanners: BannersListMappedFields[]) => {
    try {
      // Extract only the IDs of the banners that were reordered
      const reorderedBannerIDs: number[] = reorderedBanners.map(({ id }) => id);

      // Send a request to the API
      await reorderBanners.mutateAsync(reorderedBannerIDs);
    } catch (error) {
      errorReporting("Failed reordering marketing banners!", error, { reorderBanners });
    }
  };

  return (
    <div className="container py--25">
      <ContentHeader
        title={
          <PermissionCheckComponentWrapper permissions={["marketing_banners_create"]}>
            <Button
              onClick={() => setShowBannerCreateModal(true)}
              aria-label="button:add-banner"
              modifierClass="btn btn--fluid btn--fluid--md btn--secondary"
            >
              Add New Banner
            </Button>
          </PermissionCheckComponentWrapper>
        }
        modifierClass="content__header--no-underline"
      />

      {isLoading ? (
        <TableSkeletonPlaceholder />
      ) : (
        <TableDnD
          data={BANNERS_DATA}
          columns={BANNERS_TABLE_COLUMNS}
          isRefetching={false}
          modifierClass="table--parsed-html"
          handleUpdateData={handleUpdateData}
        />
      )}

      {/* MODAL FOR CREATING NEW BANNERS */}
      {showBannerCreateModal ? (
        <Modal
          title="Create New Banner"
          text=""
          modifierClass="modal--lg"
          handleCloseModal={() => setShowBannerCreateModal(false)}
          testID="modal-banner-create"
        >
          <Formik
            initialValues={{ banner_text: "", banner_link: "" }}
            enableReinitialize
            validationSchema={BANNERS_CREATE_SCHEMA}
            onSubmit={handleCreateNewBanner}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <div className="tinymce-configurable--modal">
                  <TextEditorConfigurable
                    initialValue={values.banner_text}
                    handleEditorContent={content => setFieldValue("banner_text", content)}
                    customConfig={{
                      height: 200,
                      statusbar: false,
                    }}
                  />
                  {errors.banner_text ? <p className="input__error">{errors.banner_text}</p> : null}
                </div>

                <Field
                  component={FormInput}
                  id="banner_link"
                  name="banner_link"
                  label="Banner Link"
                  placeholder="Banner Link"
                  modifierClass="mb--50 txt--left"
                />

                <div className="modal__actions">
                  <Button
                    type="button"
                    modifierClass="btn--fluid btn--primary--light"
                    onClick={() => setShowBannerCreateModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary"
                    isLoading={createNewBanner.isLoading}
                    isDisabled={createNewBanner.isLoading}
                  >
                    Create Banner
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}

      {/* MODAL FOR EDITING THE TARGETED BANNER */}
      {showBannerEditModal ? (
        <Modal
          title="Edit Banner"
          text=""
          modifierClass="modal--lg"
          handleCloseModal={() => setShowBannerEditModal(false)}
        >
          <Formik
            initialValues={{
              banner_text: targetedBanner?.text || "",
              banner_link: targetedBanner?.hyperlink || "",
            }}
            enableReinitialize
            validationSchema={BANNERS_CREATE_SCHEMA}
            onSubmit={handleEditModal}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <div className="tinymce-configurable--modal">
                  <TextEditorConfigurable
                    initialValue={values.banner_text}
                    handleEditorContent={content => setFieldValue("banner_text", content)}
                    customConfig={{
                      height: 200,
                      statusbar: false,
                    }}
                  />
                  {errors.banner_text ? (
                    // @ts-expect-error For some reason it shows an
                    // React I18Next error here while its not being used.
                    <p className="input__error">{errors.banner_text}</p>
                  ) : null}
                </div>

                <Field
                  component={FormInput}
                  id="banner_link"
                  name="banner_link"
                  label="Banner Link"
                  placeholder="Banner Link"
                  modifierClass="mb--50 txt--left"
                />

                <div className="modal__actions">
                  <Button
                    type="button"
                    modifierClass="btn--fluid btn--primary--light"
                    onClick={() => setShowBannerEditModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary"
                    isLoading={editBanner.isLoading}
                    isDisabled={editBanner.isLoading}
                  >
                    Edit Banner
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}

      {/* MODAL FOR CREATING NEW BANNERS */}
      {showBannerDeleteModal ? (
        <Modal
          title="Delete Banner?"
          text="Are you sure you want to delete the targeted banner? This action is irreversible."
          modifierClass="modal--md"
          handleCloseModal={() => setShowBannerDeleteModal(false)}
        >
          <div className="modal__actions">
            <Button
              type="button"
              modifierClass="btn--fluid btn--primary--light"
              onClick={() => setShowBannerDeleteModal(false)}
            >
              Cancel
            </Button>

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary"
              isLoading={deleteBanner.isLoading}
              isDisabled={deleteBanner.isLoading}
              type="button"
              onClick={handleDeleteBanner}
              aria-label="button:confirm-delete-banner"
            >
              Yes, Delete Banner
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default BannersPage;
