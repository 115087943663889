// Utilities & Hooks
import { useEffect, useRef, useState } from "react";
import { handleCalculateChatMessageProgress } from "../utils/handleChatMessageProgress";
import handlePermissionCheck from "../../../utilities/handlePermissionCheck";

// Assets
import ChatSendMessageIcon from "../../../assets/images/icons/chat-send-icon.svg?react";
import { BsInfoCircle as InfoIcon } from "react-icons/bs";

// Constants
import {
  CHAT_CIRCLE_PROGRES_DASH_ARRAY,
  CHAT_CIRCLE_PROGRES_RADIUS,
  CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT,
} from "../constants";

// Interfaces
import { ChatConversationFormProps } from "./interfaces";

// Contexts
import { useChatsContext } from "../ChatWrapper/ChatContextWrapper";
import { useAuth } from "../../../providers/auth-context";

const ChatConversationForm = ({
  id,
  chatLocation = "page",
  selectedUserID = 0,
  handleSendChatMessage,
}: ChatConversationFormProps) => {
  const { user } = useAuth();

  /*============================
    TRACK TYPED MESSAGE
  =============================*/
  const [typedMessageCharactersCount, setTypedMessageCharactersCount] = useState<number>(0);
  const [typedMessage, setTypedMessage] = useState<string>("");
  const [typedMessageProgressPercentage, setTypedMessageProgressPercentage] = useState<number>(0);

  const handleMessageTyping = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Dynamic textarea height as the user types
    if (textareaRef && textareaRef.current) {
      if (event.target.value) {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      } else {
        textareaRef.current.style.height = "24px";
      }
    }

    // Prevent typing more than the maximum allowed message characters count
    // This is an extra layer of protection used for mobile devices where
    // the "maxLength" property applied to the textarea field is not working correctly
    if (event.target.value.length > CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT) return;

    setTypedMessage(event.target.value);
  };

  const handleMessageTypingKeydown = (event: React.KeyboardEvent) => {
    // If user presses only on the "Enter" key, then trigger sending the message
    // Otherwise, if user presses "Enter" and "Shift" key in combination, insert new line
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleTriggerSendChatMessage();
    }
  };

  // Count the message characters anytime the typed message is updated
  useEffect(() => {
    setTypedMessageCharactersCount(typedMessage.length);

    // Calculate the progress percentage based on how
    // many characters were typed within the message
    const progressPercentage: number = handleCalculateChatMessageProgress(
      typedMessage.length,
      CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT,
      chatLocation === "page" ? "circular" : "line",
    );

    setTypedMessageProgressPercentage(progressPercentage);
  }, [typedMessage]);

  /*=============================
    TRIGGER SENDING THE MESSAGE
  ================================*/
  const handleTriggerSendChatMessage = () => {
    // Prevent form submission when the form is in "readonly" mode
    if (isReadOnly) return;

    // Prevent trying to send the message if there's an empty message,
    // or if user entered more than 180 characters
    if (
      typedMessageCharactersCount === 0 ||
      typedMessageCharactersCount > CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT
    )
      return;

    // Trigger function for sending the message
    handleSendChatMessage(typedMessage);

    // Clear out the message after sending it
    setTypedMessage("");
  };

  /*=====================================
    CHECK IF FORM IS IN "READ-ONLY" MODE
  ======================================*/
  const [isReadOnly, setIsReadOnly] = useState<boolean>(() => {
    // When we open the chat conversation in a panel, by default the form is not in "readonly" mode.
    // When we open the chat conversation in the dedicated page, by default the form is in "readonly" mode.
    return chatLocation === "page" ? true : false;
  });
  const permissionCheckFormWrite = handlePermissionCheck(["sms_write"]);

  useEffect(() => {
    // If no user data is available, exit the function
    if (!user.id) return;

    // If the logged in user does not have the "write" permission,
    // then the form is in "readonly" mode
    if (!permissionCheckFormWrite) {
      setIsReadOnly(true);
      return;
    }

    // If there's no valid "selectedUserID" value received as a prop,
    // then the form is not in "read only" mode, meaning that we're not trying
    // to see the messages of someone else, but instead we're viewing our own messages
    if (!selectedUserID) {
      setIsReadOnly(false);
      return;
    }

    // If the logged in user's ID matches the ID of the selected user then
    // form is in "readonly" mode to prevent sending messages impersonating another user.
    // Otherwise, logged in users are viewing their own chats & messages and can send a message.
    if (user.id !== selectedUserID) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [user.id, selectedUserID, permissionCheckFormWrite]);

  /*===========================
    FOCUS ON THE TEXTAREA
  ============================*/
  const chatsContext = useChatsContext();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    // If its mobile device do not auto-focus
    if (window.innerWidth <= 575) return;

    // Exit function if the ref for the textarea cannot be found
    if (!textareaRef.current) return;

    // If user is viewing the dedicated Chats page, automatically focus on the textarea
    // Otherwise, if the user is using Chat Panels, check if the ID of the panel
    // is matching the one of the currently active (focused) chat panel, before focusing to the textarea
    if (chatLocation === "page") {
      textareaRef.current.focus();
    } else {
      if (id && id === chatsContext.activeChatID) textareaRef.current.focus();
    }
  }, [id, chatsContext.activeChatID]);

  return (
    <>
      <div
        className={`chat-conversation__form ${
          isReadOnly ? "chat-conversation__form--readonly" : ""
        }`}
      >
        <textarea
          className="chat-conversation__form__textarea"
          rows={1}
          value={typedMessage}
          maxLength={CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT}
          placeholder="Type a message"
          onChange={handleMessageTyping}
          onKeyDown={handleMessageTypingKeydown}
          ref={textareaRef}
          readOnly={isReadOnly}
        ></textarea>

        <button
          disabled={isReadOnly}
          className="chat-conversation__form__button"
          onClick={handleTriggerSendChatMessage}
        >
          {/* iOS: Has to be wrapped in some element otherwise wont render the SVG */}
          <span style={{ paddingTop: 2 }}>
            <ChatSendMessageIcon />
          </span>
        </button>

        {chatLocation === "panel" ? (
          <div
            style={{ width: `${typedMessageProgressPercentage}%` }}
            className={`chat-conversation__form__progress__indicator--line ${
              typedMessageCharactersCount >= CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT
                ? "chat-conversation__form__progress__indicator--limit"
                : ""
            }`}
          ></div>
        ) : null}
      </div>

      <div className="chat-conversation__form__progress">
        {chatLocation === "page" ? (
          <svg
            className={`chat-conversation__form__progress__indicator--circle ${
              typedMessageCharactersCount >= CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT
                ? "chat-conversation__form__progress__indicator--limit"
                : ""
            }`}
            height="16"
            width="16"
          >
            <circle
              stroke="#d1d1cf"
              stroke-width="1"
              fill="transparent"
              r={CHAT_CIRCLE_PROGRES_RADIUS}
              cx="8"
              cy="8"
              strokeWidth={1.5}
            />
            <circle
              fill="transparent"
              r={CHAT_CIRCLE_PROGRES_RADIUS}
              cx="8"
              cy="8"
              transform="rotate(-90deg)"
              strokeDasharray={CHAT_CIRCLE_PROGRES_DASH_ARRAY}
              strokeDashoffset={typedMessageProgressPercentage}
              strokeWidth={1.5}
            />
          </svg>
        ) : null}
        <span>
          <span
            className={`chat-conversation__form__progress__typed ${
              typedMessageCharactersCount >= CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT
                ? "chat-conversation__form__progress__typed--limit"
                : ""
            }`}
          >
            {typedMessageCharactersCount}
          </span>
          / {CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT}
        </span>
      </div>

      {isReadOnly && chatLocation === "page" ? (
        <div className="chat-conversation__form__limitation">
          <p className="chat-conversation__form__readonly">
            <InfoIcon />
            You can only view these messages. You can not engage in conversation from this account.
          </p>
        </div>
      ) : null}
    </>
  );
};

export default ChatConversationForm;
