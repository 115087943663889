import { RefObject, useEffect } from "react";

/**
 *
 * Trigger component-related functionality when "Escape" key is pressed by the user.
 *
 * @param ref The referenced HTML element
 * @param callback The function to be triggered
 *
 */
export function useOnEscapeKey(ref: RefObject<HTMLDivElement>, callback: () => void) {
  // Add event listener when component mounts and remove it when it unmounts
  useEffect(() => {
    // Checks if the referenced element exists in the DOM,
    // and then checks if the referenced element is the currently focused one, or a parent of the focused element
    if (
      ref &&
      ref.current &&
      document.activeElement &&
      (document.activeElement === ref.current ||
        document.activeElement.contains(ref.current) ||
        ref.current.contains(document.activeElement))
    ) {
      // Add event listener to ref since it's focused
      ref.current.addEventListener("keyup", event => handleEscapeKey(event), false);
    }

    return () => {
      ref.current?.removeEventListener("keyup", handleEscapeKey, false);
    };
  }, [document.activeElement]);

  const handleEscapeKey = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === "Escape") callback();
  };
}
