// Utilities & Hooks
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import fetchHandler from "../fetchHandler";

// Interfaces
import { RolesListResponseFields } from "./interfaces";

/**
 *
 * Get a list of ***ALL*** existing roles.
 *
 */
export function useAdminRolesGetAll() {
  return useQuery(
    ["roles-admin"],
    async () => {
      return (await fetchHandler("GET", "admin/roles")) as RolesListResponseFields;
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}
