import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

export const BUCKETS_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required("Please enter a name for the bucket")
    .max(30, "Maximum of 30 characters allowed!")
    .test("bucket-name", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});
