import { Link } from "react-router-dom";

// Interfaces
import { ApplicationDetailsProps } from "./interfaces";

const ApplicationDetails = ({
  id,
  name,
  position,
  modifierClass = "",
}: ApplicationDetailsProps) => {
  return (
    <div className={`applications__details ${modifierClass}`}>
      <Link to={`/applications/${id}/`} className="applications__details__name" title={name}>
        {name}{" "}
      </Link>

      <p className="applications__details__position" title={position}>
        {position || "N/A"}
      </p>
    </div>
  );
};

export default ApplicationDetails;
