import { UserAccessPermissions } from "../../interfaces/permissions";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

interface PermissionCheckComponenteWrapperProps {
  children: React.ReactNode;
  permissions: UserAccessPermissions[];

  /** Requires that the user has `all` of the expected permissions before granting access */
  requireAllPermissions?: boolean;
}

/**
 *
 * Component that checks if the user has access and is allowed to see and interact
 * with a specific component that is supposed to be rendered on the page.
 *
 * This is being used in each individual page and/or component, to control user interactions
 * based on the permissions that the user has.
 *
 */
const PermissionCheckComponentWrapper = ({
  children,
  permissions,
  requireAllPermissions = false,
}: PermissionCheckComponenteWrapperProps) => {
  return <>{handlePermissionCheck(permissions, requireAllPermissions) ? children : null}</>;
};

export default PermissionCheckComponentWrapper;
