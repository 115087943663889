import { DocumentExportColumnDetails } from "../../pages/Reports/interfaces";
import { DefaultReportCSVTemplate } from "../../templates/spreadsheet/DefaultReportCSVTemplate";
import { DefaultReportExcelTemplate } from "../../templates/spreadsheet/DefaultReportExcelTemplate";
import { SummaryReportExcelTemplate } from "../../templates/spreadsheet/SummaryReportExcelTemplate";

type AvailableSpreadsheetTemplates = "default-csv" | "default-excel" | "summary-excel";

/**
 * Custom hook for initiating spreadsheet document generation using the provided template
 *
 * @param data - Table data
 * @param fileName - String value for the file name
 * @param columns - Predefined table columns values
 * @param template - Specifies which template to use for the generated document
 * @param dataDate - Optional first field showing the document data date
 * @returns - Starts the download for the generated file
 */
const handleInitiateSpreadsheetGenerator = (
  data: unknown[],
  fileName: string,
  columns: DocumentExportColumnDetails[],
  template: AvailableSpreadsheetTemplates,
  dataDate?: string,
) => {
  // Send the received document data to the appropriate template for generation
  switch (template) {
    case "default-csv":
      DefaultReportCSVTemplate(data, fileName, columns, dataDate);
      break;

    case "default-excel":
      DefaultReportExcelTemplate(data, fileName, columns, dataDate);
      break;

    case "summary-excel":
      SummaryReportExcelTemplate(data, fileName, columns, dataDate);
      break;

    default:
      DefaultReportCSVTemplate(data, fileName, columns, dataDate);
  }
};

export default handleInitiateSpreadsheetGenerator;
