// Hooks & Utilities
import { useEffect, useMemo, useState } from "react";
import {
  useJobAdsGetCompanyFilters,
  useJobAdsGetJobBoards,
  useJobAdsGetJobTitleFilters,
  useJobAdsGetStatuses,
  useJobsAdsGetAll,
} from "../../api/JobAds/JobAds";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";
import { JobAdStatus } from "../../api/JobAds/interfaces";

// Components
import Card from "../../components/Card/Card";
import JobAdHeader from "../../components/JobAds/JobAdHeader";
import JobAdsTable from "../../components/JobAds/JobAdsTable";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Skeleton from "react-loading-skeleton";

// Constants
import { JOB_ADS_DATE_FIELD_MAPPINGS, JOB_AD_TYPES } from "./constants";

// Assets
import { GrPowerReset as ResetFiltersIcon } from "react-icons/gr";
import LoaderRefetch from "../../components/Loader/LoaderRefetch";

const JobAdsListAds = () => {
  /*==============================
    SEARCH PARAMETERS
  ===============================*/
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*==============================
    STATUSES FILTERS
  ===============================*/
  const { data: jobAdStatuses, isLoading: jobAdStatusesLoading } = useJobAdsGetStatuses();
  const [statuses, setStatuses] = useState<DropdownItem[]>([]);

  // Map the received statuses into dropdown items
  useEffect(() => {
    if (!jobAdStatuses || !jobAdStatuses.length || jobAdStatusesLoading) return;

    const MAPPED_STATUSES: DropdownItem[] = jobAdStatuses
      .map(status => {
        return {
          text: handleStringCapitalization(status.name.split("_").join(" "), [" "]),
          value: status.name.toLowerCase(),
        };
      })
      .sort((statusA, statusB) => {
        return statusA.text.toLowerCase() > statusB.text.toLowerCase() ? 1 : -1;
      });

    setStatuses([{ text: "All Statuses", value: "all" }, ...MAPPED_STATUSES]);
  }, [jobAdStatuses]);

  /*==============================
    CLIENTS (COMPANIES) FILTERS
  ===============================*/
  const { data: jobAdClients, isLoading: jobAdClientsLoading } = useJobAdsGetCompanyFilters();
  const [clients, setClients] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!jobAdClients || !jobAdClients.length || jobAdClientsLoading) return;

    // Alphabetically sort the clients dropdown items
    const sortedClients = jobAdClients.sort((clientA, clientB) => {
      return clientA.text.toLowerCase() > clientB.text.toLowerCase() ? 1 : -1;
    });

    setClients([{ text: "All Clients", value: "all" }, ...sortedClients]);
  }, [jobAdClients]);

  /*==============================
    JOB TITLE FILTERS
  ===============================*/
  const { data: jobAdTitles, isLoading: jobAdTitlesLoading } = useJobAdsGetJobTitleFilters();
  const [jobTitles, setJobTitles] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!jobAdTitles || !jobAdTitles.length || jobAdTitlesLoading) return;

    // Alphabetically sort the "jobs" dropdown items
    const sortedJobs = jobAdTitles
      .filter(job => job.text)
      .sort((jobA, jobB) => {
        return jobA.text.toLowerCase() > jobB.text.toLowerCase() ? 1 : -1;
      });

    setJobTitles([{ text: "All Jobs", value: "all" }, ...sortedJobs]);
  }, [jobAdTitles]);

  /*==============================
    JOB BOARD FILTERS
  ===============================*/
  const { data: jobBoardsData, isLoading: jobBoardsLoading } = useJobAdsGetJobBoards();
  const [jobBoards, setJobBoards] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (jobBoardsLoading || !jobBoardsData) return;

    const mappedBoards = jobBoardsData.map((board: Record<string, string | number>) => {
      return { text: board.label, value: board.id };
    });

    setJobBoards([{ text: "All Boards", value: "all" }, ...mappedBoards]);
  }, [jobBoardsData]);

  /*==============================
    SEARCHING
  ===============================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  /*==============================
    FILTERING
  ===============================*/
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [typeFilter, setTypeFilter] = useState<string>("all");
  const [clientNameFilter, setClientNameFilter] = useState<string>("all");
  const [jobTitleFilter, setJobTitleFilter] = useState<string>("all");
  const [boardFilter, setBoardFilter] = useState<string>("all");

  const handleJobAdsStatusFilter = (status: string) => {
    setStatusFilter(status);

    // Append the "status" filter route parameter
    setSearchParameters({ ...searchParametersObject, page: 1, status });
  };

  const handleJobAdsTypeFilter = (type: string) => {
    setTypeFilter(type);

    // Append the "type" filter route parameter
    setSearchParameters({ ...searchParametersObject, page: 1, type });
  };

  const handleJobAdsClientFilter = (client: string) => {
    setClientNameFilter(client);

    // Append the "client" filter route parameter
    setSearchParameters({ ...searchParametersObject, page: 1, client });
  };

  const handleJobAdsTitleFilter = (title: string) => {
    setJobTitleFilter(title);

    // Append the "title" filter route parameter
    setSearchParameters({ ...searchParametersObject, page: 1, job: title });
  };

  const handleJobBoardFitler = (board: string) => {
    setBoardFilter(board);

    // Append the "board" filter route parameter
    setSearchParameters({ ...searchParametersObject, page: 1, board: board });
  };

  const handleFiltersReset = () => {
    setStatusFilter("all");
    setTypeFilter("all");
    setClientNameFilter("all");
    setJobTitleFilter("all");
    setBoardFilter("all");

    // This is internal state, based on the value received from the search field
    // which is just a copy of the searched value state used in the corresponding component
    setSearchedValue("");

    // Clear out all of the applied search parameters
    setSearchParameters({});
  };

  // Read the filters received as route parameters,
  // and update the corresponding filters states
  useEffect(() => {
    const { status, type, client, job, board } = searchParametersObject as Record<
      "status" | "type" | "client" | "job" | "board",
      string | undefined
    >;

    if (status) setStatusFilter(status);
    if (type) setTypeFilter(type);
    if (client) setClientNameFilter(client);
    if (job) setJobTitleFilter(job);
    if (board) setBoardFilter(board);
  }, []);

  /*==============================
    ADS TABLE DATA
  ===============================*/
  const {
    data: jobAdsAll,
    isLoading: jobAdsAllLoading,
    isRefetching: jobAdsAllRefetching,
  } = useJobsAdsGetAll({
    search: searchParametersObject?.search || searchedValue,
    company: searchParametersObject?.client || clientNameFilter,
    title: searchParametersObject?.job || jobTitleFilter,
    status: searchParametersObject?.status || statusFilter,
    type: searchParametersObject?.type || typeFilter,
    job_board: searchParametersObject?.board || boardFilter,
  });

  const JOB_ADS_TABLE_DATA = useMemo(() => {
    if (!jobAdsAll || !jobAdsAll.ads.length || jobAdsAllLoading)
      return {
        data: [],
        total_count: 0,
      };

    // Sort the job ad data based on the date value, in descending order
    const sortedData = jobAdsAll.ads.sort((a: any, b: any) => {
      const targetedDateField: string = JOB_ADS_DATE_FIELD_MAPPINGS[statusFilter as JobAdStatus];
      const dateA = new Date(a[targetedDateField]);
      const dateB = new Date(b[targetedDateField]);

      return dateA > dateB ? -1 : 1;
    });

    return { data: sortedData, total_count: jobAdsAll.ads_total_count };
  }, [jobAdsAll]);

  /*============================
    DYNAMIC CARD TITLE BASED
    ON SELECTED STATUS FILTER
  =============================*/
  const [cardTitle, setCardTitle] = useState<string>("Latest Ads");

  useEffect(() => {
    if (statusFilter === "all") {
      setCardTitle("Latest Ads");
    } else {
      const selectedFilter: string = statusFilter.split("_").join(" ");
      const capitalizedString: string = handleStringCapitalization(selectedFilter, [" "]);
      setCardTitle(capitalizedString);
    }
  }, [statusFilter]);

  return (
    <div className="job-ads">
      <JobAdHeader handleSearch={search => setSearchedValue(search)} />

      <div className="container py--25">
        <Card>
          <h2 className="fw--semibold ml--20 my--20">
            {cardTitle}

            {jobAdsAllLoading ? (
              <Skeleton width="60px" height="22px" className="ml--10" />
            ) : (
              <span className="ml--10">({JOB_ADS_TABLE_DATA.total_count})</span>
            )}
          </h2>

          <div className="d-flex justify-content-between align-items-center flex-wrap px--20">
            <DropdownSearchable
              items={clients}
              placeholder={clientNameFilter === "all" ? "All Clients" : clientNameFilter}
              preselectedItemValue={clientNameFilter}
              size="xl"
              handleItemSelected={client => handleJobAdsClientFilter(client.value as string)}
              isLoading={jobAdClientsLoading}
              disabled={jobAdClientsLoading || !clients.length || jobAdsAllLoading}
            />

            <DropdownSearchable
              items={jobTitles}
              placeholder={jobTitleFilter === "all" ? "All Jobs" : jobTitleFilter}
              preselectedItemValue={jobTitleFilter}
              size="md"
              handleItemSelected={job => handleJobAdsTitleFilter(job.value as string)}
              modifierClass="ml--15"
              isLoading={jobAdTitlesLoading}
              disabled={jobAdTitlesLoading || !jobTitles.length || jobAdsAllLoading}
            />

            <DropdownSearchable
              items={statuses}
              placeholder={statusFilter === "all" ? "All Statuses" : statusFilter}
              preselectedItemValue={statusFilter}
              size="xs"
              handleItemSelected={status => handleJobAdsStatusFilter(status.value as string)}
              modifierClass="ml--15"
              isLoading={jobAdStatusesLoading}
              disabled={jobAdStatusesLoading || !statuses.length || jobAdsAllLoading}
            />

            <DropdownSearchable
              items={JOB_AD_TYPES}
              placeholder={typeFilter === "all" ? "All Types" : typeFilter}
              preselectedItemValue={typeFilter}
              size="xs"
              handleItemSelected={type => handleJobAdsTypeFilter(type.value as string)}
              modifierClass="ml--15"
              disabled={jobAdsAllLoading}
            />
            <DropdownSearchable
              items={jobBoards}
              placeholder={boardFilter === "all" ? "All Boards" : boardFilter}
              preselectedItemValue={
                jobBoards.find(board => board.value == boardFilter)?.value || ""
              }
              size="xs"
              handleItemSelected={board => handleJobBoardFitler(board.value as string)}
              modifierClass="ml--15"
              isLoading={jobBoardsLoading}
              disabled={jobAdsAllLoading || jobBoardsLoading}
            />

            <div
              className="btn--action ml--10"
              onClick={handleFiltersReset}
              data-testid="component:job-ads-list-ads-reset-filters"
            >
              <ResetFiltersIcon />
            </div>
          </div>

          {jobAdsAllLoading ? (
            <div className="p--20">
              <TableSkeletonPlaceholder modifierClass="card--no-shadow mb--0i" />
            </div>
          ) : (
            <JobAdsTable
              ads={JOB_ADS_TABLE_DATA.data}
              paginationSize={25}
              isRefetching={jobAdsAllRefetching}
              adGroupStatus={statusFilter as JobAdStatus}
              shouldHandleStatusesFetching={false}
            />
          )}
        </Card>
      </div>

      {jobAdsAllRefetching && <LoaderRefetch title="Refetching Job Ads..." />}
    </div>
  );
};

export default JobAdsListAds;
