import { OnlineApplicationFormSubtypes } from "../../api/OnlineApplication/interfaces";

export type OnlineApplicationFormInitialValues = Record<string, string | number | boolean | null>;
export type OnlineApplicationDynamicFieldsWidths = 25 | 33 | 50 | 100;
export type OnlineApplicationDynamicFieldsColumns = "col-12" | "col-lg-6" | "col-lg-4" | "col-lg-3";

export enum OnlineApplicationDynamicFieldsValuesEnum {
  // TEXT-BASED FIELDS
  text = "string",
  textarea = "string",
  select = "string",
  multiselect = "string",
  linkedin = "string",

  // DATE TIME PICKERS
  datetime = "datetime",

  // NUMBER BASED FIELDS
  number = "number",

  // BOOLEAN BASED FIELDS (Checkbox, Radio)
  checkbox = "boolean",

  // FILE UPLOAD BASED FIELDS
  file = "file",
  image = "file",
  resume = "file",
  profile_image = "file",
}

// prettier-ignore
export type OnlineApplicationSupportedDynamicFields = keyof typeof OnlineApplicationDynamicFieldsValuesEnum;
export type OnlineApplicationSupportedDynamicFieldsValues =
  | "string"
  | "number"
  | "file"
  | "datetime"
  | "boolean";

export interface OnlineApplicationFieldsForDynamicValidationSchema {
  name: string;
  is_required: boolean;
  type: OnlineApplicationSupportedDynamicFields;
  sub_type: OnlineApplicationFormSubtypes;
  value_type: OnlineApplicationSupportedDynamicFieldsValues;
}

// Online Application - Assessment form
export interface AssessmentStaticQuestionsList {
  id: "a" | "b" | "c";
  text: string;
}

export interface AssessmentStaticQuestionGroups {
  id: "group";
  title: string;
  questions: AssessmentStaticQuestionsList[];
  i18nKey?: string;
}
