// Interfaces
import { ApplicationRouteProps } from "./interfaces";

// Pages & Layout
import JobAdsOverview from "../pages/JobAds/JobAdsOverview";
import JobAdsListAds from "../pages/JobAds/JobAdsListAds";
import JobAdsClients from "../pages/JobAds/Clients/JobAdsClients";
import JobAdsClientDetails from "../pages/JobAds/Clients/JobAdsClientDetails";
import JobAdDetails from "../pages/JobAds/JobAdDetails";
import JobAdsEdit from "../pages/JobAds/JobAdsEdit";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import JobAdPreview from "../pages/JobAds/JobAdPreview";

export const ROUTES_JOB_ADS: ApplicationRouteProps[] = [
  {
    path: "/job-ads/overview",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdsOverview />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_view"],
    type: "protected",
  },
  {
    path: "/job-ads/ads",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdsListAds />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_view"],
    type: "protected",
  },
  {
    path: "/job-ads/ads/:id",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_view"],
    type: "protected",
  },
  {
    path: "/job-ads/ads/:id/edit",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdsEdit />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_edit"],
    type: "protected",
  },
  {
    path: "/job-ads/clients",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdsClients />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_view"],
    type: "protected",
  },
  {
    path: "/job-ads/clients/:slug",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobAdsClientDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_manager_view"],
    type: "protected",
  },

  {
    path: "/preview/job-ads/:id",
    element: <JobAdPreview />,
    permissions: ["*"],
    type: "public",
  },

  // Note: Same as the already existing Job Ads preview route,
  // used for specific legacy scenarios
  {
    path: "/talent/v/:id",
    element: <JobAdPreview />,
    permissions: ["*"],
    type: "public",
  },
];
