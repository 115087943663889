// Utilities & Hooks
import { useRef, useState } from "react";

// Packages
import { Editor } from "@tinymce/tinymce-react";

// Configuration
import { TINY_MCE_WYSIWYG_EDITOR_KEY } from "../../config/config";

// Statics & Interfaces
import { TextEditorConfigurableProps } from "./interfaces";

// Components
import Loader from "../Loader/Loader";

// Interfaces
import { InitOptions } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";

const EDITOR_DEFAULT_CONFIG: InitOptions = {
  height: 300,
  menubar: false,
  plugins: ["emoticons"],
  relative_urls: false,
  remove_script_host: false,
  automatic_uploads: false,
  placeholder: "Enter Content...",
  auto_focus: undefined,
  toolbar: ["bold italic underline emoticons"],
  toolbar_mode: "sliding",
  font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt",
  link_context_toolbar: false,
  link_default_target: "_blank",
  paste_data_images: false,
  image_title: false,
  image_caption: false,
};

const TextEditorConfigurable = ({
  initialValue = "",
  handleEditorContent,
  isDisabled = false,
  customConfig = null,
}: TextEditorConfigurableProps) => {
  const [isEditorInitializing, setIsEditorInitializing] = useState<boolean>(true);
  const editorRef = useRef<any>(null);

  return (
    <>
      {isEditorInitializing && (
        <h6 className="d-flex align-items-center my--20">
          Initializing Editor...
          <Loader size="sm" modifierWrapper="ml--10" />
        </h6>
      )}

      <Editor
        value={initialValue}
        apiKey={TINY_MCE_WYSIWYG_EDITOR_KEY}
        id="wysiwyg-editor"
        onInit={(_event, editor) => {
          editorRef.current = editor;
          setIsEditorInitializing(false);
        }}
        ref={editorRef}
        onEditorChange={handleEditorContent}
        disabled={isDisabled}
        init={{
          ...EDITOR_DEFAULT_CONFIG,
          ...customConfig,
        }}
      />
    </>
  );
};

export default TextEditorConfigurable;
