// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { useCompaniesGetAvailable } from "../../api/Company/Company";
import { useAuth } from "../../providers/auth-context";
import { useUserSetActiveCompany } from "../../api/User/User";
import useErrorReporting from "../../hooks/useErrorReporting";
import handleRedirectOnActiveCompanyChange from "./utils/handleRedirectOnActiveCompanyChange";

// Components
import DropdownSearchable from "../Dropdown/DropdownSearchable";
import Loader from "../Loader/Loader";

// Interfaces
import { DropdownItem } from "../Dropdown/interfaces";
import { createPortal } from "react-dom";

const HeaderCompaniesMenu = () => {
  const errorReporting = useErrorReporting();

  /*================================
    FETCH THE LIST OF COMPANIES THAT
    THE LOGGED IN USER HAS ACCESS TO
  =================================*/
  const { data, isLoading } = useCompaniesGetAvailable();

  // Map the received companies into dropdown items
  const availableCompanies: DropdownItem[] = useMemo(() => {
    // Exit function and return default dataset if there's no available data
    if (!data || !data.length || isLoading) return [];

    // Sort the list of received companies in alphabetical order, and map them into dropdown items
    return data
      .map(company => {
        return { text: company.name, value: company.id };
      })
      .sort((companyA, companyB) => {
        return companyA.text.toLowerCase() > companyB.text.toLowerCase() ? 1 : -1;
      });
  }, [data]);

  /*================================
    PRE-SELECT THE USER'S 
    ACTIVE COMPANY IN THE LIST
  =================================*/
  const [activeCompanyID, setActiveCompanyID] = useState<number>(0);
  const { user } = useAuth();

  useEffect(() => {
    if (!user || !user.active_company || !availableCompanies.length) return;

    // Find the company with the matching name
    // and pre-select it's "ID" as a value
    const matchingCompany = availableCompanies.find(company => {
      return company.text.toLowerCase() === user.active_company.name.toLowerCase();
    });

    // If the company exists, extract it's ID value
    // and use it as the state for the preselected item's value
    if (matchingCompany) {
      const matchingCompanyID: number = matchingCompany.value as number;
      setActiveCompanyID(matchingCompanyID);
    }
  }, [user, availableCompanies]);

  /*================================
    UPDATE THE CURRENT USER'S
    ACTIVE COMPANY 
  =================================*/
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const setActiveCompany = useUserSetActiveCompany();
  const { handleLocationReplacement } = handleRedirectOnActiveCompanyChange();

  const handleUsersActiveCompany = async (company: DropdownItem) => {
    // Display the page overlay and the company name
    setShowOverlay(true);
    setSelectedCompanyName(company.text);

    try {
      const companyID = company.value as number;
      await setActiveCompany.mutateAsync(companyID);

      // Reload the page (and possibly redirect the user) after a successful request to the API
      handleLocationReplacement();
    } catch (error) {
      setShowOverlay(false);
      setSelectedCompanyName("");
      errorReporting("Failed to update user's active company.", error, {
        user_id: user.id,
        company_id: company.value,
      });
    }
  };

  return (
    <>
      <DropdownSearchable
        preselectedItemValue={activeCompanyID}
        items={availableCompanies}
        placeholder="Select Client"
        modifierClass="ml--20 dropdown--transparent header-dropdown--companies"
        isLoading={isLoading}
        disabled={isLoading || !availableCompanies.length}
        handleItemSelected={handleUsersActiveCompany}
        size="xl"
        maxScrollableHeight="450px"
      />

      {showOverlay
        ? createPortal(
            <div className="page-reload-overlay">
              <div className="page-reload-overlay__content">
                <Loader size="lg" modifierWrapper="mb--40" />
                <div className="txt--center">
                  <h1>Loading data for {selectedCompanyName}...</h1>
                  <p>Page will reload shortly</p>
                </div>
              </div>
            </div>,
            document.body,
          )
        : null}
    </>
  );
};

export default HeaderCompaniesMenu;
