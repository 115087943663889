import UserInvitationRegister from "../pages/UserInvitation/UserInvitationRegister";
import UserInvitationStatusCheck from "../pages/UserInvitation/UserInvitationStatusCheck";
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_INVITATIONS: ApplicationRouteProps[] = [
  {
    path: "/invitation/check/:invitationID",
    element: <UserInvitationStatusCheck />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/invitation/register/:invitationID",
    element: <UserInvitationRegister />,
    permissions: ["*"],
    type: "public",
  },
];
