// Hooks & Utilities
import { useEffect, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useAlertsGetDetails, useAlertsUpdateDetails } from "../../api/Alerts/Alerts";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../../components/Button/Button";
import ContentHeader from "../../components/Content/ContentHeader";
import Skeleton from "react-loading-skeleton";
import FormInputBubbles from "../../components/Form/FormInputBubbles";

// Schemas
import { ACCOUNT_MANAGE_ALERTS } from "../../schemas/AccountSchemas";

// Interfaces
import { AlertsFormFields } from "./interfaces";
import { AlertsUpdateRequestFields } from "../../api/Alerts/interfaces";
import { FormInputBubblesModel } from "../../components/Form/interfaces";

const ManageAlerts = () => {
  const errorReporting = useErrorReporting();

  /*======================================
    ALERTS MANAGEMENT DETAILS
  =======================================*/
  const [formDetails, setFormDetails] = useState<AlertsFormFields>({
    inhouse_alerts: "",
    ads: [],
  });

  /*==============================
    BUBBLED EMAIL ALERTS
  ===============================*/
  const [inHouseEmailValue, setInHouseEmailValue] = useState<string>("");
  const [inHouseFinalEmails, setInHouseFinalEmails] = useState<FormInputBubblesModel[]>([]);

  const [onlineAdsAlertsEmailValues, setOnlineAdsAlertsEmailValues] = useState<string[]>([]);
  const [onlineAdsAlertsFinalEmails, setOnlineAdsAlertsFinalEmails] = useState<
    FormInputBubblesModel[][]
  >([]);

  // Fetch the alert details from the API
  const { data, isLoading } = useAlertsGetDetails();

  // Update the form details state with the fetched details from the API
  useEffect(() => {
    // Exit function if data is not available yet
    if (!data || !Object.entries(data).length || isLoading) return;

    setFormDetails({
      inhouse_alerts: data.inhouse_alerts,
      ads: data.ads,
    });

    /*=======================================
      BUBBLED EMAILS - IN-HOUSE & ONLINE ADS
    =========================================*/
    const inhouseAlertsEmailBubbles: FormInputBubblesModel[] = data.inhouse_alerts
      ? data.inhouse_alerts.split(";").map((email, index) => {
          return { id: index, value: email };
        })
      : [];

    // We have an array of arrays here as we can target the individual input fields
    // corresponding to each indivdiual online job ad
    const onlineAdsAlertsEmailBubbles: FormInputBubblesModel[][] = data.ads.map(ad => {
      return ad.emails
        ? ad.emails.split(";").map((email, index) => {
            return { id: index, value: email };
          })
        : [];
    });

    setInHouseFinalEmails(inhouseAlertsEmailBubbles);
    setOnlineAdsAlertsFinalEmails(onlineAdsAlertsEmailBubbles);
  }, [data, isLoading]);

  /*======================================
    ALERTS DETAILS UPDATES
  =======================================*/
  const updateAlertDetails = useAlertsUpdateDetails();

  const handleManagingAlerts = async (details: AlertsFormFields) => {
    try {
      const REQUEST_PAYLOAD: AlertsUpdateRequestFields = {
        inhouse_alerts: details.inhouse_alerts,
        ads: details.ads.map(ad => {
          return { id: ad.id, emails: ad.emails };
        }),
      };

      await updateAlertDetails.mutateAsync(REQUEST_PAYLOAD);
    } catch (error) {
      errorReporting("Failed updating alert details", error, { ...details });
    }
  };

  /*======================================
    PREVIEW PAGE URL
  =======================================*/
  const [previewPageURL, setPreviewPageURL] = useState<string>("");

  useEffect(() => {
    const { protocol, host } = window.location;
    const url: string = `${protocol}//${host}/preview/job-ads`;
    setPreviewPageURL(url);
  }, []);

  return (
    <div className="container py--25">
      <ContentHeader title="Manage Alerts" />

      {isLoading ? (
        <div className="my--20" data-testid="component:manage-alerts-skeleton">
          <Skeleton width="130px" height="20px" className="mb--5" />
          <Skeleton width="80%" height="17px" className="mb--30" />

          <div className="row mb--50">
            <div className="col-2 pt--5">
              <Skeleton width="150px" height="20px" />
            </div>
            <div className="col-10">
              <Skeleton width="80%" height="40px" />
            </div>
          </div>

          <Skeleton width="120px" height="20px" className="mb--5" />
          <Skeleton width="80%" height="15px" className="mb--30" />

          <div className="row mb--30">
            <div className="col-2 pt--5">
              <Skeleton width="150px" height="20px" />
            </div>
            <div className="col-10">
              <Skeleton width="80%" height="40px" />
            </div>
          </div>
          <div className="row mb--20">
            <div className="col-2 pt--5">
              <Skeleton width="150px" height="20px" />
            </div>
            <div className="col-10">
              <Skeleton width="80%" height="40px" />
            </div>
          </div>
          <div className="row">
            <div className="col-2 pt--5">
              <Skeleton width="150px" height="20px" />
            </div>
            <div className="col-10">
              <Skeleton width="80%" height="40px" />
            </div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={formDetails}
          enableReinitialize
          validationSchema={ACCOUNT_MANAGE_ALERTS}
          onSubmit={handleManagingAlerts}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              {/* IN-HOUSE ALERTS */}
              <div className="my--20">
                <h4 className="txt--blue fw--semibold mb--5">In-House Alerts</h4>
                <p className="txt--md fw--medium">
                  Add emails to which in-house alerts will be sent.
                </p>
              </div>

              <div className="row">
                <div className="col-12 col-md-2">
                  <label
                    htmlFor="inhouse_alerts"
                    className="input__label"
                    style={{ color: "#585858" }}
                  >
                    Email Addresses:
                  </label>
                </div>

                <div className="col-12 col-md-10">
                  <Field
                    component={FormInputBubbles}
                    id="inhouse_alerts"
                    name="inhouse_alerts"
                    description="To add multiple emails separate them using empty space, Enter, semicolon or comma characters."
                    size="full"
                    bubbledValues={inHouseFinalEmails}
                    handleBubbledValues={(emails: FormInputBubblesModel[]) => {
                      setInHouseFinalEmails(emails);
                      setFieldValue("inhouse_alerts", emails.map(email => email.value).join(";"));
                    }}
                    value={inHouseEmailValue}
                    modifierClass="input-side-label mb--15"
                    textInput={inHouseEmailValue}
                    handleTextInput={setInHouseEmailValue}
                    delimiters={[";", ",", " ", "Space", "Enter"]}
                    internalDelimiter=";"
                  />
                </div>
              </div>

              {/* ONLINE ALERTS */}
              {values.ads.length > 0 ? (
                <div className="my--20">
                  <h4 className="txt--blue fw--semibold mb--5">Online Alerts</h4>
                  <p className="txt--md fw--medium">
                    Separate multiple email addresses with a semicolon. Example:
                    email@email.com;email2@email.com
                  </p>
                </div>
              ) : null}

              <FieldArray
                name="ads"
                render={() => (
                  <>
                    {values.ads.map((ad, index) => (
                      <div key={ad.id} className="row mb--30">
                        <div className="col-12 col-md-2 pt--5">
                          <p className="txt--gray--darkest fw--semibold txt--lg mb--0">{ad.name}</p>
                          <a
                            href={`${previewPageURL}/${ad.ad_identifier}`}
                            target="_blank"
                            rel="noopener"
                            className="txt--md fw--medium txt--link mb--10"
                          >
                            (Click to preview job ad)
                          </a>
                        </div>

                        <div className="col-12 col-md-10">
                          <Field
                            component={FormInputBubbles}
                            id={`ads.${index}.emails`}
                            name={`ads.${index}.emails`}
                            description="To add multiple emails separate them using empty space, Enter, semicolon or comma characters."
                            size="full"
                            bubbledValues={onlineAdsAlertsFinalEmails[index]}
                            handleBubbledValues={(emails: FormInputBubblesModel[]) => {
                              // Update the form value for the Ad at the specific index
                              setFieldValue(
                                `ads.${index}.emails`,
                                emails.map(email => email.value).join(";"),
                              );

                              // Update the value for the bubbled emails in the input corresponding to the current index
                              const updatedState = [...onlineAdsAlertsFinalEmails];
                              updatedState.splice(index, 1, emails);
                              setOnlineAdsAlertsFinalEmails(updatedState);
                            }}
                            modifierClass="input-side-label mb--15"
                            value={onlineAdsAlertsEmailValues[index]}
                            textInput={onlineAdsAlertsEmailValues[index]}
                            handleTextInput={(text: string) => {
                              // Update the value for the input corresponding to the current index
                              const updatedState = [...onlineAdsAlertsEmailValues];
                              updatedState.splice(index, 1, text);
                              setOnlineAdsAlertsEmailValues(updatedState);
                            }}
                            delimiters={[";", ",", " ", "Space", "Enter"]}
                            internalDelimiter=";"
                          />

                          <p className="txt--md fw--medium mb--0">
                            Running {format(handleDateAsTimestamp(ad.date_from), "MM/dd/yyyy")} -{" "}
                            {format(handleDateAsTimestamp(ad.date_to), "MM/dd/yyyy")}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />

              <div className="row">
                <div className="col-12 col-md-10 offset-md-2">
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary"
                    isLoading={updateAlertDetails.isLoading}
                    isDisabled={!!Object.entries(errors).length || updateAlertDetails.isLoading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ManageAlerts;
