import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

const AppointmentsCardSkeleton = ({ index }: { index: number }) => {
  return (
    <motion.div
      className="col-12 col-sm-6 col-lg-4 col-xl-3 mb--10"
      key={`appointments-card-skeleton-${index}`}
      initial={{ opacity: 0, translateY: "30px" }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: "30px" }}
      transition={{
        delay: 0.25 * index,
        type: "spring",
        repeatType: "mirror",
        repeat: Infinity,
        repeatDelay: 0.5,
      }}
      data-testid="component:appointments-card-skeleton"
    >
      <div className="appointments__card appointments__card--skeleton">
        <div className="appointments__card__header">
          <div className="appointments__card__date">
            <Skeleton width="25px" height="9px" className="mb--5" />
            <Skeleton width="50px" height="20px" className="mb--5" />
            <Skeleton width="30px" height="9px" />
          </div>

          <div className="appointments__card__time_and_creation">
            {/* APPOINTMENT HOUR */}
            <Skeleton width="100px" height="25px" className="mb--5" />

            {/* APPOINTMENT CREATION DATE */}
            <Skeleton width="80px" height="9px" />
          </div>
        </div>

        <div className="appointments__card__content">
          <Skeleton width="80px" height="80px" circle />

          <div>
            <div className="appointments__card__applicant-details">
              {/* NAME */}
              <Skeleton width="150px" height="15px" />

              {/* POSITION */}
              <Skeleton width="100px" height="10px" className="mb--15" />

              {/* PHONE */}
              <Skeleton width="120px" height="10px" />

              {/* ADDRESS */}
              <Skeleton width="120px" height="10px" />
            </div>

            {/* APPLICANT'S SOCIALS (Resume, Linkedin, Email, Meeting) */}
            <div className="appointments__card__socials">
              <Skeleton width="25px" height="20px" />
              <Skeleton width="25px" height="20px" />
              <Skeleton width="25px" height="20px" />
              <Skeleton width="25px" height="20px" />
            </div>
          </div>
        </div>

        <div className="appointments__card__footer">
          {/* APPOINTMENT TYPE & INTERVIEWER */}
          <div className="appointments__card__type">
            <Skeleton width="20px" height="15px" />
            <p className="m--0">
              <Skeleton width="80px" height="10px" />
            </p>
            <p className="m--0">
              <Skeleton width="100px" height="10px" />
            </p>
          </div>

          {/* APPOINTMENT ACTIONS */}
          <div className="d-flex justify-content-end align-items-center">
            <Skeleton width={21} height={21} circle className="mr--10" />
            <Skeleton width={21} height={21} circle className="mr--10" />
            <Skeleton width={21} height={21} circle />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AppointmentsCardSkeleton;
