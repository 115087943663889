/**
 *
 * Utility function for moving an array item from it's starting position
 * to the desired position in the same array
 *
 * @param array The array to be rearranged
 * @param startingPosition The starting position of the targeted element
 * @param desiredPosition The desired position of the targeted element
 * @returns A rearranged array, with the targeted element moved from
 * its starting to its desired position
 *
 */
export default function handleMoveArrayItem<T>(
  array: T[],
  startingPosition: number,
  desiredPosition: number,
): T[] {
  // Early exit if no valid 'array' value was received
  if (!array || !array.length) return [];

  const arrayCopy: T[] = [...array];
  const targetedElement: T = arrayCopy[startingPosition];

  // Remove the element from the starting position
  arrayCopy.splice(startingPosition, 1);

  // Place the element to the desired index position
  arrayCopy.splice(desiredPosition, 0, targetedElement);

  return arrayCopy;
}
