// Hooks & Utilities
import { useEffect, useState } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

// Interfaces
import { TabItemProps } from "../../components/Tabs/interfaces";

// Components
import { AnimatePresence } from "framer-motion";
import Card from "../../components/Card/Card";
import Tabs from "../../components/Tabs/Tabs";
import JobCompanyPositions from "../../features/JobPositions/CompanyPositions";
import JobDefaultPositions from "../../features/JobPositions/DefaultPositions";

const JobPositions = () => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  /*===========================
    ARTICLES DATA TAB 
  ============================*/
  const [activeTab, setActiveTab] = useState<"company" | "default">(() => {
    // If there's a present search parameter use its value, otherwise use default value
    return searchParametersObject.tab ?? "company";
  });

  const handleTabSelection = (tab: TabItemProps) => {
    const tabId = tab.id as "company" | "default";
    setActiveTab(tabId);
  };

  // Reset the table pagination (if present) to first page on tab change
  useEffect(() => {
    if (searchParametersObject.page) {
      setSearchParametersObject({ ...searchParametersObject, page: 1 });
    }
  }, [activeTab]);

  return (
    <div className="container py--25">
      <h2 className="ml--30 mb--20">
        <strong>Job Positions</strong>
      </h2>

      <Tabs
        tabs={[
          {
            id: "company",
            text: "Company Positions",
          },
          ...(handlePermissionCheck(["job_position_view"])
            ? [
                {
                  id: "default",
                  text: "Default Positions",
                },
              ]
            : []),
        ]}
        activeTabId={activeTab}
        handleTabSelection={handleTabSelection}
      />

      <Card modifierClass="card--padding--xl">
        <AnimatePresence initial={false} mode="popLayout">
          {activeTab === "company" ? <JobCompanyPositions /> : null}
        </AnimatePresence>

        <AnimatePresence mode="popLayout">
          {activeTab === "default" ? <JobDefaultPositions /> : null}
        </AnimatePresence>
      </Card>
    </div>
  );
};

export default JobPositions;
