// Hooks & Utilities
import { useState } from "react";
import { useApplicationsBulkHide } from "../../api/Applications/Applications";
import useErrorReporting from "../../hooks/useErrorReporting";
import { useAuth } from "../../providers/auth-context";

// Components
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { AnimatePresence } from "framer-motion";

const ApplicationsHideSelection = ({
  selectedApplicationIDs,
  handleResetSelection,
}: {
  selectedApplicationIDs: number[];
  handleResetSelection: () => void;
}) => {
  const { user } = useAuth();

  const errorReporting = useErrorReporting();

  const [showBulkHideModal, setShowBulkHideModal] = useState(false);

  const handleCloseBulkHideModal = () => {
    setShowBulkHideModal(false);
  };

  const applicationBulkHide = useApplicationsBulkHide();

  const handleBulkHide = async () => {
    if (!selectedApplicationIDs.length) return;

    try {
      await applicationBulkHide.mutateAsync(selectedApplicationIDs);
      handleCloseBulkHideModal();
      handleResetSelection();
    } catch (error) {
      errorReporting("Failed bulk application hide request", error, {
        applicationsIds: selectedApplicationIDs,
        company_slug: user.active_company.slug,
      });
    }
  };

  return (
    <>
      <AnimatePresence>
        {showBulkHideModal ? (
          <Modal
            title={`Hide selected ${
              selectedApplicationIDs.length > 1 ? "applications?" : "application?"
            }`}
            text={
              <>
                This action will hide {selectedApplicationIDs.length > 1 ? "all" : "the"}{" "}
                <strong className="txt--black">{selectedApplicationIDs.length}</strong> selected{" "}
                {selectedApplicationIDs.length > 1 ? "applications" : "application"}. Do you want to
                continue?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleCloseBulkHideModal}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleCloseBulkHideModal}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--fluid btn--danger"
                onClick={handleBulkHide}
                isDisabled={applicationBulkHide.isLoading}
                isLoading={applicationBulkHide.isLoading}
              >
                {`Yes, Hide ${selectedApplicationIDs.length > 1 ? "Applications" : "Application"}`}
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>

      <Button
        modifierClass="btn--fluid btn--danger mb--md--10"
        onClick={() => setShowBulkHideModal(true)}
        isDisabled={!selectedApplicationIDs.length}
      >
        Hide Selected
      </Button>
    </>
  );
};

export default ApplicationsHideSelection;
