import { CheckboxProps } from "./interfaces";

const Checkbox = ({
  id,
  name,
  label,
  value,
  handleCheckboxAction,

  isChecked = false,
  isDisabled = false,
  modifierClass = "",
  isIndeterminate,
}: CheckboxProps) => {
  return (
    <div className={`input-checkbox ${modifierClass}`}>
      <input
        id={id}
        name={name}
        value={value}
        hidden
        type="checkbox"
        onChange={handleCheckboxAction}
        checked={isChecked}
        disabled={isDisabled}
        aria-controls={isChecked ? "checked" : isIndeterminate ? "indeterminate" : "unchecked"}
      />
      <label className="input__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
