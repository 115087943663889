import { useEffect, useRef, useState } from "react";

// Hooks & Utils
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Interfaces
import { DropdownItem, DropdownProps } from "./interfaces";

// Assets
import { FaChevronDown as ChevronIcon } from "react-icons/fa";

// Components
import Loader from "../Loader/Loader";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import { motion, AnimatePresence } from "framer-motion";

// Constants
import {
  FRAMER_DROPDOWN_ANIMATION,
  FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION,
} from "../../constants/framer";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";

const Dropdown: React.FC<DropdownProps> = ({
  title,
  items,
  handleItemSelected,

  preselectedItemValue = "",
  isLoading = false,
  label = "",
  size = "md",
  disabled = false,
  modifierClass = "",
  orientation = "bottom",
  maxScrollableHeight = "300px",
  framerAnimationCustomProps = null,
}) => {
  const [selectedItemText, setSelectedItemText] = useState<string>("");

  // Update the selected item based on the pre-selected title value
  useEffect(() => {
    setSelectedItemText(title);
  }, [title]);

  /*============================
   PRE-SELECT ITEM IF THERE'S
   A RECEIVED TITLE PROP THAT MATCHES IT
  ============================*/
  useEffect(() => {
    // Do not preselect anything if there's no such prop received
    if (!preselectedItemValue) return;

    // Find the item whose value matches the preselected item value received as a prop
    const preselectedItem: DropdownItem | undefined = items.find((item: DropdownItem) => {
      return item.value === preselectedItemValue;
    });

    // Exit if there's no match
    if (!preselectedItem) return;

    // Update the selected item's text state, which
    // will also mark the dropdown item as selected
    setSelectedItemText(preselectedItem.text);
  }, [preselectedItemValue, items]);

  /*===========================
   DROPDOWN MENU STATE
  ============================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdownMenu = () => {
    // If the component is marked as 'disabled', prevent it from being opened
    if (disabled || isLoading) return;

    setIsDropdownOpen(!isDropdownOpen);
  };

  /*================================
   HANDLE DROPDOWN ITEM SELECTION
  ================================*/
  const handleDropdownItem = (item: DropdownItem) => {
    // Prevent functionality if the item is marked as "disabled"
    if (item.disabled) return;

    // Call the callback passed as prop
    handleItemSelected(item);

    // Display the selected item's text in the dropdown body
    setSelectedItemText(item.text);

    // Close the menu
    setIsDropdownOpen(false);
  };

  /*============================
   CLOSE WHEN CLICKED OUTSIDE
  ============================*/
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*============================
   DROPDOWN CLASS NAMES
  ============================*/
  let DROPDOWN_CLASSNAME = `dropdown dropdown--${size}`;
  if (disabled) DROPDOWN_CLASSNAME += " dropdown--disabled";
  if (modifierClass) DROPDOWN_CLASSNAME += ` ${modifierClass}`;
  if (isDropdownOpen) DROPDOWN_CLASSNAME += " dropdown--active";

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div ref={dropdownRef} className={DROPDOWN_CLASSNAME} tabIndex={0}>
      {label && (
        <label htmlFor="dropdown" className="dropdown__label">
          {label}
        </label>
      )}

      <div className="d-flex flex-column w--100">
        <div className="p--relative">
          <div
            className={`dropdown__body ${isDropdownOpen ? "dropdown__body--active" : ""}`}
            onClick={handleToggleDropdownMenu}
          >
            <span title={selectedItemText || title} className="dropdown__body__text">
              {selectedItemText || title}
            </span>

            <div className="d-flex">
              {isLoading ? (
                <Loader size="sm" modifierWrapper="mr--10" />
              ) : (
                <ChevronIcon className="dropdown__chevron" />
              )}
            </div>
          </div>

          <AnimatePresence>
            {isDropdownOpen && (
              <motion.ul
                className={`dropdown__content dropdown__content--orientation-${orientation}`}
                key="framer-dropdown"
                variants={
                  orientation === "bottom"
                    ? FRAMER_DROPDOWN_ANIMATION
                    : FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION
                }
                initial="initial"
                animate="animate"
                exit="exit"
                custom={framerAnimationCustomProps}
                transition={{ duration: 0.5, type: "spring" }}
              >
                <CustomScrollbars maxHeight={maxScrollableHeight}>
                  {items.map(item => {
                    let DROPDOWN_ITEM_CLASSNAME: string = "dropdown__item";

                    if (item.disabled) DROPDOWN_ITEM_CLASSNAME += " dropdown__item--disabled";
                    if (item.text.toLowerCase() === selectedItemText.toLowerCase()) {
                      DROPDOWN_ITEM_CLASSNAME += " dropdown__item--selected";
                    }

                    return (
                      <li
                        key={item.value}
                        className={DROPDOWN_ITEM_CLASSNAME}
                        onClick={() => handleDropdownItem(item)}
                        title={item.text}
                      >
                        <div className="d-flex flex-column">
                          {item.icon && <div className="dropdown__item__icon">{item.icon}</div>}
                          {item.text}

                          {item.description && (
                            <p className="dropdown__item__description">{item.description}</p>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </CustomScrollbars>
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
export default Dropdown;
