export const ROLLBAR_CONFIG = {
  enabled: import.meta.env.VITE_ROLLBAR_ENVIRONMENT === "local" ? false : true,
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,

  /**
   * Small set of specific error messages that are to be completely ignored by Rollbar
   * to avoid unnecessarily spamming Rollbar with things that are not really errors
   *
   **/
  ignoredMessages: [
    "Your username or password are incorrect",
    "The email has already been taken",
    "Wrong password",
  ],

  /** Sourcemaps related configuration */
  payload: {
    environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.VITE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
};
