// Assets
import LinkedinIcon from "../../../assets/images/icons/applications-linkedin-icon.svg?react";

// Components
import Tooltip from "../../Tooltip/Tooltip";

const ApplicationLinkedIn = ({ linkedin }: { linkedin: string | null }) => {
  const constructValidLink = (linkedin: string | null): string => {
    if (!linkedin) return "";

    return linkedin.startsWith("http://") || linkedin.startsWith("https://")
      ? linkedin
      : `http://${linkedin}`;
  };

  return (
    <Tooltip text="Open LinkedIn" size="md" modifierClass={!linkedin ? "d-none" : ""}>
      <a
        href={constructValidLink(linkedin)}
        target="_blank"
        className={`applications__socials__icon ${
          linkedin ? "applications__socials__icon--active" : ""
        }`}
        style={{ pointerEvents: linkedin ? "all" : "none" }}
        data-testid="link:linkedin-action"
        aria-disabled={linkedin ? false : true}
      >
        <LinkedinIcon />
      </a>
    </Tooltip>
  );
};

export default ApplicationLinkedIn;
