import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./internationalization/i18n";
import "./scss/app.scss";
import ScrollRestoration from "./components/ScrollRestoration/ScrollRestoration";

// Providers
import { BrowserRouter } from "react-router-dom";
import { AuthenticationContext } from "./providers/auth-context";
import { Provider } from "@rollbar/react";
import QueryClient from "./providers/query-client";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";

// Configuration
import { ROLLBAR_CONFIG } from "./config/rollbar";
import { TourContextWrapper } from "./providers/tour-context";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClient>
        <Provider config={ROLLBAR_CONFIG}>
          <ErrorBoundary>
            <AuthenticationContext>
              <TourContextWrapper>
                <ScrollRestoration />
                <App />
              </TourContextWrapper>
            </AuthenticationContext>
          </ErrorBoundary>
        </Provider>
      </QueryClient>
    </BrowserRouter>
  </React.StrictMode>,
);
