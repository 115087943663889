import { format } from "date-fns";
import {
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";

export const REJECTION_EMAIL_POST_INTERVIEW: EmailTemplateModel = {
  name: "rejection-email-post-interview",
  label: "Rejection Email (Post Interview)",
  subject: (details: EmailTemplateDetailsModel) =>
    `Re: Your Job Application dated ${format(new Date(details.applicationDate), "MM/dd/yyyy")}`,
  html: (
    details: EmailTemplateDetailsModel,
  ) => `<p>Dear <span style="color:orangered;">${details.applicantName}</span>,</p>  
    <br />      
<p>Thank you for your interest in the position of <span style="color:orangered;">${details.applicationPosition}</span> with our company. It was a pleasure meeting you on <span style="color:orangered;">${details.applicationDate}</span>.</p>
        <br />
<p>After a thorough review, we have decided to pursue another candidate more closely suited for our current needs. Thank you for your continued interest in <span style="color:orangered;">${details.senderCompany}</span>. Check back for other openings that may fit with your skills and experience.</p>
    <br />
<p>Good luck in your continued job search.</p>
    <br />
<p>Sincerely,</p>
<p style="color:orangered;">${details.senderName}</p>
<p style="color:orangered;">[TITLE]</p>`,
};
