// Utilities & Hooks
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import fetchHandler from "../fetchHandler";
import fetchHandlerUpload from "../fetchHandlerUpload";

// Interfaces
import {
  CareerPageDetailsResponseFields,
  CareerPageDetailsUpdateRequestFields,
  CareerPageGraphicsResponseFields,
  CareerPageGraphicsUpdateRequestFields,
} from "./interfaces";

/**
 *
 * Get the details for the current company's career page
 *
 */
export function useCareerPagesGetDetails() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["career-pages", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/career-pages`,
      )) as CareerPageDetailsResponseFields;
    },
    { enabled: !!companySlug, cacheTime: 0 },
  );
}

/**
 *
 * Get the graphics for the current company's career page
 *
 */
export function useCareerPagesGetGraphics() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["career-pages-graphics", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/career-pages/graphics`,
      )) as CareerPageGraphicsResponseFields;
    },
    { enabled: !!companySlug, cacheTime: 0 },
  );
}

/**
 *
 * Update the details of the current company's career page
 *
 */
export function useCareerPagesUpdateDetails() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (details: CareerPageDetailsUpdateRequestFields) => {
      // Construct the FormData payload
      const formData = new FormData();

      // If there are somehow values for both "team_image" and "embed_video"
      // prevent the request from being sent and show notification
      if (details.team_image && details.embed_video) {
        toast.error('Only one of "Embed Video" or "Team Photo" can be uploaded.');
        return;
      }

      /*==============================================================
        GENERAL Section

        The images fields here will be appended to the FormData
        ONLY if there are valid values for them (e.g. user has selected a file).

        Otherwise, these won't be included. 
      ==============================================================*/
      if (details.about_us_feature_image) {
        formData.append("about_us_feature_image", details.about_us_feature_image as File);
      }

      if (details.careers_feature_image) {
        formData.append("careers_feature_image", details.careers_feature_image as File);
      }

      formData.append("embed_video", details.embed_video);
      formData.append("sub_header", details.sub_header);
      formData.append("about_us", details.about_us);
      formData.append("team_image", details.team_image as any); // Can either be a File or null

      /*==============================================================
        HIGHLIGHTS Section

        If there are highlights items present in the array, 
        append them to the FormData and send them in the request.

        Otherwise, send a single empty value for the highlights array,
        which represents that the highlights array is now  empty
        and the request will delete all existing ones.
      ==============================================================*/
      if (details.highlights.length) {
        details.highlights.forEach((highlight, index) => {
          formData.append(`highlights[${index}][title]`, highlight.title);
          formData.append(`highlights[${index}][data]`, highlight.data);
        });
      } else {
        formData.append("highlights[]", "");
      }

      /*==============================================================
        FEATURED HIGHLIGHTS Section

        If there are featured highlights items present in the array,
        append them to the FormData and send them in the request.

        Otherwise (e.g. there was one featured highlight present and we cleared it), then 
        send a single empty string value for the featured array, which represents that the
        array has no items in it, and all the currently existing featured highlights will be deleted.
      ==============================================================*/
      if (details.featured.length) {
        details.featured.forEach((featured, index) => {
          // Include the image in the request only if there's a value for it
          if (featured.image) formData.append(`featured[${index}][image]`, featured.image as File);

          formData.append(`featured[${index}][headline_1]`, featured.headline_1);
          formData.append(`featured[${index}][headline_2]`, featured.headline_2);
          formData.append(`featured[${index}][body]`, featured.body);
          formData.append(`featured[${index}][id]`, featured.id as any);
        });
      } else {
        formData.append("featured[]", "");
      }

      /*==============================================================
        BENEFITS Section

        If there are benefits items present in the array, 
        append them to the FormData and send them in the request.

        Otherwise, send a single empty value for the benefits array,
        which represents that the benefits array is now  empty
        and the request will delete all existing ones.
      ===============================================================*/
      if (details.benefits.length) {
        details.benefits.forEach(benefit => {
          formData.append("benefits[]", benefit.data);
        });
      } else {
        formData.append("benefits[]", "");
      }

      return await fetchHandlerUpload("POST", `company/${companySlug}/career-pages`, formData);
    },
    {
      onSuccess: () => toast.success("Career Page details were successfully updated."),
      onError: error => error,
    },
  );
}

/**
 *
 * Update the graphics of the current company's career page
 *
 */
export function useCareerPagesUpdateGraphics() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (details: CareerPageGraphicsUpdateRequestFields) => {
      // Construct the FormData payload
      const formData = new FormData();

      if (details.company_logo) {
        formData.append("company_logo", details.company_logo as File);
      }

      if (details.apply_image) {
        formData.append("apply_image", details.apply_image as File);
      }

      if (details.banner_image) {
        formData.append("banner_image", details.banner_image as File);
      }

      if (details.full_graphic_image) {
        formData.append("full_graphic_image", details.full_graphic_image as File);
      }

      return await fetchHandlerUpload(
        "POST",
        `company/${companySlug}/career-pages/graphics`,
        formData,
      );
    },
    {
      onSuccess: () => toast.success("Career Page Graphics were successfully updated."),
      onError: error => error,
    },
  );
}
