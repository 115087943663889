// Utilities & Hooks
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAdminArticlesEdit,
  useAdminArticlesGetSpecific,
  useAdminArticlesStatuses,
} from "../../api/Articles/AdminArticles";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";
import useErrorReporting from "../../hooks/useErrorReporting";
import { useBackNavigation } from "../../hooks/useBackNavigation";

// Interfaces
import {
  ArticlesEditFormState,
  ArticlesStatusesResponseFields,
} from "../../api/Articles/interfaces";
import { DropdownItem } from "../../components/Dropdown/interfaces";

// Components
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import FormDropdown from "../../components/Form/FormDropdown";
import FormInputSideLabel from "../../components/Form/FormInputSideLabel";
import FormTextarea from "../../components/Form/FormTextarea";
import FormUpload from "../../components/Form/FormUpload";
import Loader from "../../components/Loader/Loader";
import TextEditor from "../../components/WYSIWYG/TextEditor";
import ArticlesManagementSkeleton from "./Skeleton/ArticlesManagement";
import ContentHeader from "../../components/Content/ContentHeader";

// Constants
import { ARTICLES_STATUSES } from "./constants";

// Schemas
import { ARTICLES_EDIT_SCHEMA } from "../../schemas/ArticlesSchemas";

const ArticlesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*=============================
    ARTICLE INITIAL FORM STATE
  ==============================*/
  const [articleFormState, setArticleFormState] = useState<ArticlesEditFormState>({
    title: "",
    summary: "",
    status_id: 1, // Defaults to "Draft"
    feature_image: "",
    content: "",
    preview: "no",
  });

  /*=============================
    OBTAIN ARTICLE STATUSES
  ==============================*/
  const { data: statuses, isLoading: statusesLoading } = useAdminArticlesStatuses();

  const articlesStatuses = useMemo(() => {
    // Return default value if there's no statuses available
    if (!statuses || !statuses.length) return [];

    // Map the received statuses to a dropdown item format
    const mappedArticleStatuses: DropdownItem[] = statuses.map(
      (status: ArticlesStatusesResponseFields) => {
        return {
          text: handleStringCapitalization(status.status, [" "]),
          value: status.id,
        } as DropdownItem;
      },
    );

    return mappedArticleStatuses;
  }, [statuses]);

  /*=============================
    OBTAIN SPECIFIC ARTICLE'S 
    DETAILS BASED ON ACCESSED ID
  ==============================*/
  const {
    data: articleById,
    isLoading: articleByIdLoading,
    isRefetching: articleByIdRefetching,
  } = useAdminArticlesGetSpecific(id);

  useEffect(() => {
    // Exit function if there's no data yet
    if (!articleById || articleByIdLoading) return;

    // Update the article's form state and pre-populate the fields
    const { title, content, summary, status, feature_image } = articleById;

    setArticleFormState({
      title,
      summary,
      feature_image: feature_image == null ? null : "",
      content,
      preview: "no",
      status_id: ARTICLES_STATUSES[status],
    });
  }, [articleById]);

  /*=============================
    ARTICLE UPDATE

    Edits the article by sending an API request
    while remaining on the same page when the 
    response from the API comes back 
  ==============================*/
  const [articleUpdateOngoingAction, setArticleUpdateOngoingAction] = useState<boolean>(false);
  const handleUpdateArticle = async (articleValues: ArticlesEditFormState) => {
    setArticleUpdateOngoingAction(true);
    await handleArticle(articleValues, "no");
    setArticleUpdateOngoingAction(false);
  };

  /*=============================
    ARTICLE SAVE & GO BACK

    Edits the article by sending an API request,
    and then redirects the user back to the Articles Overview page 
  ==============================*/
  const [saveAndGoBackOngoingAction, setSaveAndGoBackOngoingAction] = useState<boolean>(false);
  const handleSaveArticleAndGoBack = async (articleValues: ArticlesEditFormState) => {
    setSaveAndGoBackOngoingAction(true);
    await handleArticle(articleValues, "no", true);
    setSaveAndGoBackOngoingAction(false);
  };

  /*=============================
    ARTICLE PREVIEW

    Sends a PUT request to the API, which
    generates a new (temporary) preview article,
    and after the response comes back, it redirects
    the user to the article details page for the preview article
  ==============================*/
  const [previewActionOngoing, setPreviewActionOngoing] = useState<boolean>(false);

  const handleArticlePreview = async (event: React.MouseEvent, values: ArticlesEditFormState) => {
    // Prevent redirecting the user straight away
    event.preventDefault();
    setPreviewActionOngoing(true);

    try {
      await handleArticle(values, "yes");
    } catch (error) {
      errorReporting("Failed creating article preview", error, { ...values });
    } finally {
      setPreviewActionOngoing(false);
    }
  };

  /*=============================
    ARTICLE MANAGEMENT
  ==============================*/
  const formikReference = useRef<FormikProps<ArticlesEditFormState>>(null);
  const articleEdit = useAdminArticlesEdit(id);

  // Send a request to the API to create or edit an article
  const handleArticle = async (
    articleValues: ArticlesEditFormState,
    preview: "yes" | "no",
    shouldRedirect: boolean = false,
  ) => {
    // Exit function if there's no available formik reference
    if (!formikReference || !formikReference.current) return;

    // Validate the form fields and prevent
    // the API request from being sent if there's an error
    const formValidation = await formikReference.current.validateForm();
    if (Object.entries(formValidation).length > 0) {
      throw new Error("Invalid form values! Please double check all fields.");
    }

    try {
      const updatedArticlesValues: Partial<ArticlesEditFormState> = {
        title: articleValues.title,
        status_id: articleValues.status_id,
        preview,
        ...(articleValues.summary && { summary: articleValues.summary }),
        ...(articleValues.content && { content: articleValues.content }),
        ...(articleValues.feature_image && { feature_image: articleValues.feature_image }),
      };

      const articleResponse = await articleEdit.mutateAsync({ ...updatedArticlesValues });

      // If "preview" of the article was requested, open that articles' details in a new tab
      if (preview === "yes") window.open(`/articles/${articleResponse.id}?mode=preview`);

      // Control whether the user should be redirected to the overview page based on the action that was taken.
      // The "Update" button action is not supposed to redirect the user
      // The "Save & Go Back" button action does redirects the user
      if (preview === "no" && shouldRedirect) navigate("/articles/overview/");
    } catch (error) {
      errorReporting("Failed editing article", error, {
        ...articleValues,
        is_preview_mode: preview,
      });
    }
  };

  /*==============================
    CUSTOM BACK NAVIGATION
  ===============================*/
  const handleNavigateBack = useBackNavigation();

  return id != null && (articleByIdLoading || articleByIdRefetching) ? (
    <ArticlesManagementSkeleton />
  ) : (
    <div className="container py--25">
      <ContentHeader title="Article Editor" modifierClass="content__header--no-underline">
        <Link to="/articles/overview/" className="txt--md txt--link">
          Back to Overview
        </Link>
      </ContentHeader>

      <Formik
        initialValues={articleFormState}
        enableReinitialize
        validationSchema={ARTICLES_EDIT_SCHEMA}
        onSubmit={(values: ArticlesEditFormState) => handleArticle(values, "no")}
        innerRef={formikReference}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Card modifierClass="card--padding--xl">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Field
                    component={FormInputSideLabel}
                    label="Article Title:"
                    id="title"
                    name="title"
                    modifierClass="input-side-label mb--15"
                  />

                  <Field
                    component={FormTextarea}
                    label="Article Summary:"
                    id="summary"
                    name="summary"
                    modifierClass="input-side-label mb--15"
                    rows="6"
                  />

                  <Field
                    component={FormDropdown}
                    id="status_id"
                    name="status_id"
                    title={
                      statusesLoading
                        ? "Fetching Statuses..."
                        : articleById
                        ? handleStringCapitalization(articleById?.status, [" "])
                        : "Draft"
                    }
                    items={articlesStatuses}
                    isLoading={statusesLoading}
                    disabled={statusesLoading || !articlesStatuses.length}
                    size="full"
                    label="Status:"
                    modifierClass="dropdown--side-label"
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <Field
                    component={FormUpload}
                    id="feature_image"
                    name="feature_image"
                    buttonText="Choose Image"
                    placeholder="Select file"
                    modifierClass="input-side-label"
                    label="Thumbnail Image:"
                    accept=".png, .jpg, .jpeg, .gif"
                    thumbnailPreview={articleById?.feature_image ?? ""}
                    info={
                      <>
                        <span className="input-upload__info--info">
                          Accepted file types: .png, .jpg, .jpeg, .gif.{" "}
                        </span>
                        <span className="input-upload__info--danger"> Max File Size: 5mb.</span>
                      </>
                    }
                  />
                </div>
              </div>
            </Card>

            {errors.content ? <p className="input__error">{errors.content}</p> : null}

            <Card modifierClass="card--padding--xl">
              <div className="row">
                <div className="col-12 col-lg-8 d-flex flex-column flex-lg-row align-items-center">
                  <div className="input input-side-label input-side-label--url">
                    <label htmlFor="url" className="input__label">
                      URL:
                    </label>
                    <input
                      className="input__field input__field--disabled--blue-tint mb--md--10"
                      readOnly
                      placeholder="URL"
                      value={`/articles/${id}`}
                    />
                  </div>

                  <div className="d-flex align-items-center flex-shrink-0 ml--10">
                    <Link
                      to="/"
                      onClick={(event: React.MouseEvent) => handleArticlePreview(event, values)}
                      className="txt--lg txt--link mb--md--10"
                    >
                      (Click here to view)
                    </Link>
                    {previewActionOngoing && <Loader size="sm" modifierWrapper="ml--10" />}
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
                    <Button
                      onClick={() => handleNavigateBack("/articles/")}
                      modifierClass="btn--fluid btn--danger mr--10 mr--md--0 mb--md--10"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleSaveArticleAndGoBack(values)}
                      modifierClass="btn--fluid btn--primary mr--10 mr--md--0 mb--md--10"
                      type="button"
                      isDisabled={saveAndGoBackOngoingAction || Object.entries(errors).length > 0}
                      isLoading={saveAndGoBackOngoingAction}
                    >
                      Save & Go Back
                    </Button>
                    <Button
                      onClick={() => handleUpdateArticle(values)}
                      modifierClass="btn--fluid btn--secondary mb--md--10"
                      type="button"
                      isDisabled={articleUpdateOngoingAction || Object.entries(errors).length > 0}
                      isLoading={articleUpdateOngoingAction}
                    >
                      Update
                    </Button>
                  </div>
                </div>

                <div className="col-12 my--20">
                  <TextEditor
                    initialValue={values.content}
                    handleEditorContent={(content: string) => setFieldValue("content", content)}
                    placeholder="Article Content..."
                    image_upload_url="admin/articles/images"
                  />
                </div>
              </div>
            </Card>
            {errors.content ? <p className="input__error">{errors.content}</p> : null}

            <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
              <Button
                onClick={() => handleNavigateBack("/articles/")}
                modifierClass="btn--fluid btn--danger mr--10 mb--md--10"
                type="button"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSaveArticleAndGoBack(values)}
                modifierClass="btn--fluid btn--primary mr--10 mr--md--0 mb--md--10"
                type="button"
                isDisabled={saveAndGoBackOngoingAction || Object.entries(errors).length > 0}
                isLoading={saveAndGoBackOngoingAction}
              >
                Save & Go Back
              </Button>
              <Button
                onClick={() => handleUpdateArticle(values)}
                modifierClass="btn--fluid btn--secondary mb--md--10"
                type="button"
                isDisabled={articleUpdateOngoingAction || Object.entries(errors).length > 0}
                isLoading={articleUpdateOngoingAction}
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ArticlesEdit;
