// Utilities & Hooks
import { useAuth } from "../providers/auth-context";
import { useEffect, useState } from "react";
import { useUserUpdateDashboardView } from "../api/User/User";
import { LocalStorageActions } from "../utilities/handleLocalStorage";
import useErrorReporting from "../hooks/useErrorReporting";

// Interfaces
import { DashboardViewMode } from "../interfaces/global";

/**
 *
 * Custom hook for handling the applications' default view,
 * allowing the users to choose between:
 * - Classic view (list items)
 * - Grid view (box items)
 *
 * Saves the preferred value to local storage.
 * If there's no value saved in local storage, then it sends a
 * request to the API to obtain the default dashboard view
 * from the server
 *
 * @returns The currently selected `activeView` state and
 * a function that handles the selection of the active view.
 */
export function useDefaultViewSelection(): [
  DashboardViewMode,
  (viewMode: DashboardViewMode) => void,
] {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  const [activeView, setActiveView] = useState<DashboardViewMode>(() => {
    // If there's a dashboard view saved in local storage,
    // use that value to avoid content jumps
    const dashboardViewInLocalStorage = LocalStorageActions.getItem("dashboard_view_mode");

    if (dashboardViewInLocalStorage) return dashboardViewInLocalStorage;

    // If there's no dashboard view value in
    // the profile's user data, then default to "grid" view
    return "grid";
  });

  /*======================================
    UPDATE THE DEFAULT DASHBOARD VIEW
    BASED ON THE RECEIVED DATA FROM THE RESPONSE
  =======================================*/
  useEffect(() => {
    if (!user || !user.user_data) return;
    const defaultDashboardView: DashboardViewMode = user.user_data.default_dashboard_view;

    // If there's no dashboard view saved in local storage,
    // use the default dashboard view returned in the response from the API
    const dashboardViewInLocalStorage = LocalStorageActions.getItem("dashboard_view_mode");

    if (!dashboardViewInLocalStorage && defaultDashboardView) {
      setActiveView(defaultDashboardView);

      // Save the default dashboard view, returned from the API response,
      // so any consecutive page loads/reloads will display the correct view instantly
      LocalStorageActions.saveItem("dashboard_view_mode", defaultDashboardView);
    }
  }, [user]);

  /*======================================
    UPDATE THE DEFAULT DASHBOARD VIEW
  =======================================*/
  const updateDashboardView = useUserUpdateDashboardView();

  const handleViewSelection = async (viewMode: DashboardViewMode) => {
    // Prevent sending API requests if the clicked view mode is the one that is already active
    if (viewMode === activeView) return;

    try {
      // Update the dashboard view's state
      setActiveView(viewMode);

      // Save the value in local storage
      LocalStorageActions.saveItem("dashboard_view_mode", viewMode);

      // Send a request to the API to update the default dashboard view
      await updateDashboardView.mutateAsync(viewMode);
    } catch (error) {
      errorReporting("Failed updating default dashboard view", error, { dashboard_mode: viewMode });
    }
  };

  return [activeView, handleViewSelection];
}
