import FCHLogoFull from "../../assets/images/fch-full-logo-white.png";

const Footer = ({ noLogo = false }) => {
  // Get the current year
  const copyrightYear = new Date().getFullYear();

  return (
    <footer
      className={`footer d-flex flex-column flex-md-row align-items-center ${
        noLogo ? "justify-content-center" : "justify-content-between"
      }`}
    >
      {!noLogo && (
        <img
          src={FCHLogoFull}
          alt="FirstChoice Hiring logo"
          data-testid="svg:fch-logo-full"
          height={63}
        />
      )}
      <p className="txt--white m--0">
        ® FirstChoice Hiring 2014-{copyrightYear}. All Rights Reserved.
      </p>
    </footer>
  );
};
export default Footer;
