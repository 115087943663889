import { ProductTourDetails } from "./interfaces";

export const PRODUCT_TOURS_DATA: ProductTourDetails[] = [
  {
    tourId: "buckets-improved-workflow",
    type: "manual",
    steps: [
      {
        title: "New, Improved Workflow",
        target: ".buckets__container",
        content: (
          <div>
            We've improved the layout of your folders to better organize the path to Happy Hiring!
            <span className="joyride-tooltip__content__note">
              Note: You can also use "Left" and "Right" arrow keys on the keyboard for navigation.
            </span>
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets container showcase",
      },
      {
        title: "Current Folder",
        target: ".js-bucket-current",
        content: (
          <div>
            All your new applications appear in the Current folder as soon as they are received!
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets current showcase",
      },
      {
        title: "Favorites Folder",
        target: ".js-bucket-favorites",
        content: (
          <div>
            Found the perfect application? Move them to the Favorites and get that interview
            scheduled!
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets favorites showcase",
      },
      {
        title: "Interviewed Folder",
        target: ".js-bucket-interviewed",
        content: (
          <div>
            Interview complete? Here you can make notes, give a rating, even share with a colleague.
            It's decision time!
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets interviewed showcase",
      },
      {
        title: "Offer Pending",
        target: ".js-bucket-offer_pending",
        content: (
          <div>
            Made an Offer? Excellent! You're on the path to Happy Hiring! Move those applicants here
            and keep the ball rolling!
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets offer pending showcase",
      },
      {
        title: "Hired Folder",
        target: ".js-bucket-hired",
        content: (
          <div>
            Just made a great hire? Awesome! Move them here and get that process started. You're
            building a winning team!
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets hired showcase",
      },
      {
        title: "Archived Folder",
        target: ".js-bucket-archived",
        content: (
          <div>
            Not a perfect fit? No worries! You can move these applications to the Archived folder.
          </div>
        ),
        disableBeacon: true,
        stepName: "buckets archived showcase",
      },
    ],
  },
];
