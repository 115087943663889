import { LoaderProps } from "./interfaces";

const Loader = ({
  size = "sm",
  speed = "medium",
  modifierWrapper = "",
  modifier = "",
  style = {},
}: LoaderProps) => {
  return (
    <div className={`loader ${modifierWrapper}`} data-testid="component-loader">
      <div
        className={`loader__spinner loader__spinner--${size} loader__spinner--${speed} ${modifier}`}
        style={style}
      />
    </div>
  );
};

export default Loader;
