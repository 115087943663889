// Assets
import FCHLogo from "../assets/images/fch-full-logo.png";

const LayoutUnauthenticated = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="unauthenticated row no-gutters">
      <div className="col-12 col-md-6 d-flex align-items-center justify-content-center unauthenticated__cta">
        <div className="container">
          <h1 className="fw--semibold">We do all the work so you can focus on yours.</h1>
        </div>
      </div>
      <div
        className="col-12 col-md-6 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="unauthenticated__form">
          <img src={FCHLogo} alt="FirstChoice Hiring logo" />

          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutUnauthenticated;
