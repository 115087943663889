// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  useApplicationsMoveToBucket,
  useApplicationsUndoBucketMovement,
} from "../../../api/Applications/Applications";
import { useBucketsGetAll } from "../../../api/Buckets/Buckets";
import { orderBuckets } from "./orderBuckets";
import useErrorReporting from "../../../hooks/useErrorReporting";
import filterGroupedApplications from "./filterGroupedApplications";

// Components
import DropdownSearchable from "../../Dropdown/DropdownSearchable";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { ApplicationBulkBucketMovementProps, ApplicationsGroupedByBucket } from "../interfaces";

/**
 *
 * This component will be used to:
 * - fetch the list of existing buckets,
 * - populate the dropdown menu with bucket items
 * - handle moving applications from one bucket to another
 * - and also can be used to handle moving applications to
 *   specific bucket in `bulk`
 *
 */
const ApplicationBulkBucketMovement = ({
  ids,
  currentBucketFilter,
  title,
  preselectedBucket = "",
  isDisabled = false,
  dropdownSizeModifier = "sm",
  groupedApplicationBuckets = {},
  handleApplicationsResetSelection,
}: ApplicationBulkBucketMovementProps) => {
  const errorReporting = useErrorReporting();

  /*=================================
    FETCH & MAP THE LIST OF BUCKETS
  =================================*/
  const { data: buckets, isLoading: bucketsLoading } = useBucketsGetAll();

  const BUCKETS_DROPDOWN_ITEMS = useMemo(() => {
    // Exit function if there are no buckets available
    if (!buckets || !buckets.length || bucketsLoading) return [];

    const MAPPED_BUCKETS: DropdownItem[] = orderBuckets(buckets).filter(
      bucket => bucket.text.toLowerCase() !== currentBucketFilter.toLowerCase(),
    );
    return MAPPED_BUCKETS;
  }, [buckets, currentBucketFilter]);

  /*=================================
    PRE-SELECT THE BUCKET

    Based on the received "preselectedBucket" value,
    which represents the bucket's name, find the matching bucket
    from the list of fetched and mapped buckets dropdown items,
    and extract the ID of the matched bucket, which will then be used
    to pre-select the dropdown item corresponding to that bucket
  =================================*/
  const [preselectedBucketValue, setPreselectedBucketValue] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!preselectedBucket) return;

    // Find the bucket with the matching name from the
    // list of mapped bucket dropdown items
    const matchingBucket = BUCKETS_DROPDOWN_ITEMS.find(bucket => {
      return bucket.text.toLowerCase() === preselectedBucket.toLowerCase();
    });

    if (matchingBucket) setPreselectedBucketValue(matchingBucket.value as number);
  }, [preselectedBucket]);

  /*=================================
    MOVE THE SELECTED APPLICATION(S)
    TO THE SPECIFIED BUCKET
  =================================*/
  const [bucketDropdownKey, setBucketDropdownKey] = useState<string>(title);
  const moveApplication = useApplicationsMoveToBucket();

  const handleApplicationMoveToBucket = async (bucket: DropdownItem) => {
    const bucket_name: string = bucket.text as string;
    const bucket_id: number = bucket.value as number;

    // Filter out the applications that are already part of the targeted bucket,
    // so they won't be included in the request to the server to move them (again) to the same bucket
    const { groupedApplications, extractedIDs } = filterGroupedApplications(
      groupedApplicationBuckets,
      bucket_id,
    );

    // Prevent sending any requests to the server
    // if none of the selected applications are supposed to be moved
    if (!extractedIDs.length) {
      toast.info("Selected applications are already part of the targeted bucket");
      handleResetTargetedBucket();
      return;
    }

    try {
      toast.success(
        <div className="Toastify__undo-notification">
          <strong>
            {
              extractedIDs.length > 1
                ? `${extractedIDs.length} applications `
                : `${Object.values(groupedApplications)[0][0].application_name} ` // Grab the name of the only applicant that was selected
            }
          </strong>
          moved to:
          <br />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <strong>{bucket_name}</strong>
            </span>

            <span
              className="Toastify__undo-notification__btn"
              onClick={() => handleApplicationBulkBucketUndo(groupedApplications)}
            >
              Undo
            </span>
          </div>
        </div>,
        {
          toastId: "buckets-movement-bulk",
        },
      );

      await moveApplication.mutateAsync({
        bucket_name,
        bucket_id,
        application_ids: extractedIDs,
      });

      handleResetTargetedBucket();
    } catch (error) {
      // Dismiss the matching success notification from the UI
      toast.dismiss("buckets-movement-bulk");

      errorReporting("Failed moving application to specific bucket", error, {
        bucket_id,
        bucket_name,
      });
    }
  };

  const handleResetTargetedBucket = () => {
    // We clear out the "key" state when the request has finished successfully
    setBucketDropdownKey("");

    // We reset the state back to the initially received "title" prop value,
    // so we can reset the "key" and trigger a rerender of the component,
    // which in itself will reset the component's internal state
    setTimeout(() => setBucketDropdownKey(title), 100);

    // Clear out the selected applications
    handleApplicationsResetSelection();
  };

  /*=================================
    UNDO APPLICATION BUCKETS ACTION
  =================================*/
  const undoBucketMovement = useApplicationsUndoBucketMovement();

  const handleApplicationBulkBucketUndo = async (
    filteredGroupedApplications: ApplicationsGroupedByBucket,
  ) => {
    try {
      // Go trough each of the different bucket groups for which we have
      // selected applications, and trigger API updates for each group
      Object.entries(filteredGroupedApplications).forEach(async entry => {
        const [key, value] = entry;

        // Convert the object properties
        const parsedKey = parseInt(key);
        const typecastedValue = value as Record<string, string | number>[];
        const mappedValues = typecastedValue.map(value => value.id as number);

        // Revert application to its previous bucket
        await undoBucketMovement.mutateAsync({
          bucket_id: parsedKey,
          application_ids: mappedValues,
        });
      });
    } catch (error) {
      errorReporting("Failed reverting application bucket changes", error);
    }
  };

  return (
    <DropdownSearchable
      key={`application-bucket-movement-${bucketDropdownKey}`}
      placeholder={title}
      size={dropdownSizeModifier}
      items={BUCKETS_DROPDOWN_ITEMS}
      preselectedItemValue={preselectedBucketValue}
      handleItemSelected={handleApplicationMoveToBucket}
      disabled={isDisabled || !ids.length || moveApplication.isLoading}
      isLoading={bucketsLoading || moveApplication.isLoading}
    />
  );
};

export default ApplicationBulkBucketMovement;
