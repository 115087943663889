/**
 *
 * Utility function for extracting the initials of the user's
 * fullname, taking only the initials of the first and last name.
 *
 * @param fullname A string represntation of the concatenation of the users name (first, middle, and last).
 * @param initialsJoin A string representation of how to join the extracted name initials. Defaults to `" "`.
 *
 * @returns The user initials from the first and last name.
 */
export function handleUserInitials(fullname: string, initialsJoin: string = " "): string {
  // If there's no valid value received, exit function with default value
  if (!fullname) return "N/A";

  // Split the fullname into the parts that make it up
  const partsOfName: string[] = fullname.split(" ");

  // Get the initial of the first name, in case the
  // user only provided their first name
  const initials: string[] = [partsOfName[0].substring(0, 1).toUpperCase()];

  // If there are multiple parts of the received fullname,
  // such as middle name(s) and last name, then take into
  // consideration only the first letter of the user's last name
  if (partsOfName.length > 1) {
    initials.push(partsOfName[partsOfName.length - 1].substring(0, 1).toUpperCase());
  }

  // Join the extracted name initials and join them
  // using the provided join value
  return initials.join(initialsJoin);
}
