import FCHLogoFull from "../../assets/images/fch-logo-disabled-client.svg";
import ContactPhoneIcon from "../../assets/images/icons/client-disabled-phone-icon.svg";
import ContactEmailIcon from "../../assets/images/icons/client-disabled-email-icon.svg";
import ContactMacbooksCover from "../../assets/images/fch-graphic.png";

const OnlineApplicationDisabledClient = () => {
  return (
    <div className="online-application--disabled">
      <div className="container txt--center">
        <img
          src={FCHLogoFull}
          alt="FirstChoice Hiring logo"
          className="disabled__logo disabled__img mb--20"
        />

        <h1>
          Your Career site isn't <span className="d-sm-inline-block">currently active.</span>
        </h1>
        <p>Please contact your FirstChoice representative!</p>

        <div className="disabled__box">
          <div className="disabled__box__info">
            <h2 className="disabled__box__title">Contact Us:</h2>

            <div className="contact">
              <div className="row">
                <div className="col-12 col-sm-6 mb--10">
                  <h3 className="contact__title">Sales</h3>
                  <div className="contact__item">
                    <span className="contact__icon">
                      <img src={ContactPhoneIcon} alt="Phone" />
                    </span>
                    <a href="tel:+17705510045" className="contact__link">
                      770 551 0045
                    </a>
                  </div>
                  <div className="contact__item">
                    <span className="contact__icon">
                      <img src={ContactEmailIcon} alt="Email" />
                    </span>
                    <a href="mailto:sales@proexel.com" className="contact__link">
                      sales@proexel.com
                    </a>
                  </div>
                </div>

                <div className="col-12 col-sm-6">
                  <h3 className="contact__title">Support</h3>
                  <div className="contact__item">
                    <span className="contact__icon">
                      <img src={ContactPhoneIcon} alt="Phone" />
                    </span>
                    <a href="tel:+18774497595" className="contact__link">
                      877 449 7595
                    </a>
                  </div>
                  <div className="contact__item">
                    <span className="contact__icon">
                      <img src={ContactEmailIcon} alt="Email" />
                    </span>
                    <a href="mailto:support@proexel.com" className="contact__link">
                      support@proexel.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={ContactMacbooksCover}
            className="disabled__img"
            width="901"
            height="497"
            alt="Two macbooks showcasing FirstChoice hiring."
          />
        </div>
      </div>
    </div>
  );
};

export default OnlineApplicationDisabledClient;
