import { useState } from "react";
import ContentHeader from "../../components/Content/ContentHeader";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import { AnimatePresence } from "framer-motion";
import {
  AvailableEmailTemplates,
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";
import { EMAIL_TEMPLATES } from "../../templates/emails";

const EmailTemplates = () => {
  const [previewTemplateData, setPreviewTemplateData] = useState<EmailTemplateModel | null>(null);

  const handleImportTemplateData = (templateName: AvailableEmailTemplates) => {
    const foundTemplate = EMAIL_TEMPLATES.find(template => template.name === templateName);

    if (foundTemplate) setPreviewTemplateData(foundTemplate);
  };

  const PLACEHOLDER_DETAILS_DATA = {
    applicantName: "[APPLICANT NAME]",
    applicationDate: "[APPLICATION DATE]",
    applicationPosition: "[APPLICATION POSITION]",
    applicantLocation: "[APPLICANT LOCATION]",
    senderName: "[SENDER NAME]",
    senderCompany: "[SENDER COMPANY]",
  };

  return (
    <>
      <div className="container py--25">
        <ContentHeader title="Email Templates" modifierClass="mb--25" />

        <ul className="mb--30">
          {EMAIL_TEMPLATES.filter(template => template.name !== "blank-email").map(template => {
            return (
              <li
                className="btn btn--text--secondary txt--left btn--font-md mb--10"
                onClick={() => handleImportTemplateData(template.name)}
                key={template.name}
              >
                {template.label}
              </li>
            );
          })}
        </ul>
      </div>

      <AnimatePresence>
        {previewTemplateData ? (
          <Modal
            title=""
            text=""
            handleCloseModal={() => setPreviewTemplateData(null)}
            modifierClass="application-email-modal modal--fixated txt--left"
            testID="modal-send-email-template"
          >
            <h2 className="mb--30 txt--center">
              <strong>Preview Email Template</strong>
            </h2>
            <div className="d-flex flex-column mb--10 txt--lg">
              <span>
                <strong>Template: </strong>
                {previewTemplateData ? previewTemplateData.label : "N/A"}
              </span>
            </div>
            <h6 className="mb--10">
              <strong>To:</strong> [APPLICANT EMAIL]
            </h6>

            <div
              className="application-email-modal__preview"
              dangerouslySetInnerHTML={{
                __html: previewTemplateData
                  ? previewTemplateData.html(PLACEHOLDER_DETAILS_DATA as EmailTemplateDetailsModel)
                  : "",
              }}
            ></div>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--fluid--md btn--primary--light"
                onClick={() => setPreviewTemplateData(null)}
              >
                Close
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default EmailTemplates;
