// Components
import Card from "../Card/Card";

// Interfaces
import { AssessmentCardProps } from "./interfaces";

const AssessmentCard = ({
  title,
  children,
  modifierClass = "",
  score = null,
  scoreColor = "",
  scoreType = "number",
}: AssessmentCardProps) => {
  return (
    <Card modifierClass={`p--0 ${modifierClass}`}>
      {/*HEADER*/}
      <div className="card__header">
        <h3 className="txt--xxl">
          <strong>{title}</strong>
        </h3>
        {score && (
          <div className={`badge badge--${scoreColor} `}>
            {score}
            {scoreType === "percentage" ? "%" : ""}
          </div>
        )}
      </div>

      {/*CONTENT*/}
      <div className="card__content">{children}</div>
    </Card>
  );
};

export default AssessmentCard;
