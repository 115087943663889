import { EmailTemplateModel } from "../../components/Applications/interfaces";
import { INITIAL_INTERVIEW_REQUEST } from "./initial-interview-request";
import { JOB_OFFER_EMAIL_COVER_NOTE } from "./job-offer-email-cover-note";
import { REJECTION_EMAIL_NO_INTERVIEW } from "./rejection-email-no-interview";
import { REJECTION_EMAIL_POST_INTERVIEW } from "./rejection-email-post-interview";
import { SECOND_INTERVIEW_REQUEST } from "./second-interview-request";

export const EMAIL_TEMPLATES: EmailTemplateModel[] = [
  // Append the blank email option to the list of available templates
  {
    name: "blank-email",
    label: "Blank Email",
    subject: () => "",
    html: () => "",
  },
  INITIAL_INTERVIEW_REQUEST,
  SECOND_INTERVIEW_REQUEST,
  REJECTION_EMAIL_POST_INTERVIEW,
  REJECTION_EMAIL_NO_INTERVIEW,
  JOB_OFFER_EMAIL_COVER_NOTE,
];

export const DROPDOWN_TEMPLATE_OPTIONS = EMAIL_TEMPLATES.map(template => {
  return { text: template.label, value: template.name };
});
