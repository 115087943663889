import { formatDistanceToNow } from "date-fns";

/**
 * A utility function that gives how long ago in the past or in the future
 * a given date is from the current date.
 * @param date A string or Date object representation of a date.
 * @returns The distance (in the past or the future) between the current date and the
 * date passed as an argument to the function.
 */
export function handleDistanceToNow(date: number | string | Date): string {
  const formattedDate: Date = new Date(date);
  const distanceToNow: string = formatDistanceToNow(formattedDate, { addSuffix: true });
  return distanceToNow;
}
