// Components
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Table from "../../components/Table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import Loader from "../../components/Loader/Loader";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Tabs from "../../components/Tabs/Tabs";

// Hooks & Utilities
import { useEffect, useMemo, useState } from "react";
import { useReportGetJobBoardStatus } from "../../api/Reports/Reports";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import { format } from "date-fns";
import { matchSorter } from "match-sorter";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";

// Interfaces & Constants
import { ReportJobBoardStatusAd } from "../../api/Reports/interfaces";
import { TabItemProps } from "../../components/Tabs/interfaces";
import {
  REPORTS_JOB_BOARD_STATUS_DOCUMENT_COLUMNS,
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
} from "./constants";
import { ReportJobBoardStatusTableData } from "./interfaces";

const JobBoardStatus = () => {
  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportJobBoardStatusTableData>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.display({
      id: "row_id",
      header: () => <span>#</span>,
      size: 30,
      enableSorting: true,
      cell: props => <span>{+props.row.id + 1}</span>,
    }),
    COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job Code</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("client_name", {
      header: () => <span>Client</span>,
      size: 250,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/job-ads/clients/${data.row.original.client_slug}/`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 300,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/job-ads/ads/${data.row.original.ad_id}/`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("start_date", {
      header: () => <span>Start Date</span>,
      size: 90,
      enableSorting: true,
      cell: data => <span>{format(data.getValue(), "MM/dd/yyyy")}</span>,
    }),
    COLUMN_HELPER.accessor("end_date", {
      header: () => <span>End Date</span>,
      size: 90,
      enableSorting: true,
      cell: data => <span>{format(data.getValue(), "MM/dd/yyyy")}</span>,
    }),
  ];

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    isLoading: jobBoardStatusLoading,
    isRefetching: jobBoardStatusRefetching,
    isFetching: jobBoardStatusFetching,
    data: jobBoardStatusData,
  } = useReportGetJobBoardStatus();

  /*=================================
    TRACK ACTIVE TAB
  ==================================*/
  const [activeTab, setActiveTab] = useState<string>("");

  const handleTabChange = (tab: TabItemProps) => setActiveTab(tab.id);

  // Pre-select the first job board from the data fetched from the API
  useEffect(() => {
    if (!jobBoardStatusData) return;
    setActiveTab(jobBoardStatusData[0].label);
  }, [jobBoardStatusData]);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<ReportJobBoardStatusAd[]>([]);

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "job_code", "client.name" & "title"
    data fields. 
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    if (!jobBoardStatusData) return [];

    let data = jobBoardStatusData.find(board => board.label === activeTab)?.ads ?? [];

    if (data.length && searchedValue) {
      data = matchSorter(data, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "client.name" },
          { threshold: matchSorter.rankings.CONTAINS, key: "title" },
        ],
      });
    }

    return data.map((entity, index) => {
      return {
        id: index + 1,
        job_code: entity.job_code,
        client_id: entity.client.id,
        client_name: entity.client.name,
        client_slug: entity.client.slug,
        ad_id: entity.id,
        title: entity.title,
        start_date: new Date(entity.start_date),
        end_date: new Date(entity.end_date),
      };
    });
  }, [searchedValue, jobBoardStatusData, activeTab]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  /*====================================================
    DOCUMENT EXPORT DATA
  =====================================================*/
  const EXPORT_DATA = useMemo(() => {
    if (!exportedTableData.length) return [];

    const formattedDates = exportedTableData.map(item => {
      return {
        ...item,
        start_date: item.start_date ? format(new Date(item.start_date), "MM/dd/yyyy") : "N/A",
        end_date: item.end_date ? format(new Date(item.end_date), "MM/dd/yyyy") : "N/A",
      };
    });

    return formattedDates;
  }, [exportedTableData]);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex justify-content-between">
          <div>
            <h2 className="fw--semibold mb--5">Job Board Status Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>
        </div>
      </Card>
      <div className="px--30">
        {!jobBoardStatusLoading && jobBoardStatusData ? (
          <>
            <Tabs
              tabs={jobBoardStatusData.map(entity => {
                return {
                  id: entity.label,
                  text: `${entity.label} (${
                    jobBoardStatusData.find(board => board.label === entity.label)?.ads.length
                  })`,
                } as TabItemProps;
              })}
              activeTabId={activeTab}
              handleTabSelection={tab => handleTabChange(tab)}
            />
            <Card>
              <h3 className="fw--semibold mb--5 px--20 pt--10 mb--20">Export Data</h3>
              <div className="d-flex flex-column flex-md-row  align-items-md-start justify-content-center justify-content-md-between px--20">
                <div className="d-flex flex-column flex-md-row">
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold mb--md--15"
                    onClick={() =>
                      handleInitiateSpreadsheetGenerator(
                        EXPORT_DATA,
                        "job_board_status_report",
                        REPORTS_JOB_BOARD_STATUS_DOCUMENT_COLUMNS,
                        "default-csv",
                        `${format(Date.now(), "MM/dd/yyy")}`,
                      )
                    }
                    isDisabled={!REPORT_TABLE_DATA.length || jobBoardStatusFetching}
                  >
                    CSV
                  </Button>
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--md--0 mb--md--15 txt--uppercase"
                    onClick={() =>
                      handleInitiatePDFWorker({
                        columns: REPORTS_JOB_BOARD_STATUS_DOCUMENT_COLUMNS,
                        data: EXPORT_DATA,
                        documentTitle: `FirstChoice Hiring - ${activeTab} Job Board Status Report`,
                        documentSubTitle: `Data Date: ${format(Date.now(), "MM/dd/yyy")}`,
                        template: "default-report",
                        fileName: "job_board_status_report",
                        setPDFLoading: setPDFLoading,
                      })
                    }
                    isDisabled={!REPORT_TABLE_DATA.length || jobBoardStatusFetching || PDFLoading}
                    isLoading={PDFLoading}
                  >
                    PDF
                  </Button>
                </div>
                <div
                  className="d-flex flex--wrap--md justify-content-start justify-content-md-end mb--10"
                  style={{ gridGap: "10px" }}
                >
                  <InputFieldSearch
                    handleOnSearch={search => setSearchedValue(search)}
                    placeholder="Search Reports"
                    size="md"
                  />
                  <Dropdown
                    title="Select number of rows"
                    preselectedItemValue={rowsPerPage}
                    size="md"
                    items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                    handleItemSelected={item => setRowsPerPage(item.value as number)}
                  />
                </div>
              </div>
              <Table
                data={REPORT_TABLE_DATA}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={jobBoardStatusRefetching}
                paginationPageSize={rowsPerPage}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default JobBoardStatus;
