// Assets
import { MdDragIndicator as DragHandlerIcon } from "react-icons/md";

// Components
import Button from "../Button/Button";

// Interfaces
import { BucketsEditModalTableFields } from "./interfaces";

const BucketsDragOverlayItem = ({ bucket }: { bucket: BucketsEditModalTableFields }) => {
  return (
    <ul>
      <li className="buckets__edit__item--drag-overlay">
        <div className="row align-items-center">
          <div className="col-2 pl--sm--0">
            <div className="buckets__edit__drag">
              <DragHandlerIcon className="buckets__edit__drag__handler" />

              <span>{bucket.bucket_index}</span>
            </div>
          </div>

          <div className="col-3 txt--left">{bucket.name}</div>

          {!bucket.default ? (
            <div className="d-flex justify-content-center align-items-center col-4">
              <div className="row">
                <div className={`col-12 col-md-6`}>
                  <Button modifierClass="btn--fluid btn--primary mb--md--10 w--100">Edit</Button>
                </div>
                <div className="col-12 col-md-6">
                  <Button modifierClass="btn--fluid btn--danger ml--10 ml--md--0 w--100">
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-4">/</div>
          )}

          <div className="col-3">{bucket.last_updated}</div>
        </div>
      </li>
    </ul>
  );
};

export default BucketsDragOverlayItem;
