// TanStack Table utilities
import { createColumnHelper } from "@tanstack/react-table";

// Utilities & Hooks
import { format } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAdminArticleArchiving } from "../../api/Articles/AdminArticles";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import Table from "../Table/Table";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { ArticlesOverviewPickedTableFields, ArticlesOverviewTableProps } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const ArticlesOverviewTable = ({
  articlesData,
  activeTab,
  isFetching,
}: ArticlesOverviewTableProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<ArticlesOverviewPickedTableFields>();
  const ARTICLES_OVERVIEW_COLUMNS = [
    COLUMN_HELPER.accessor("create_date", {
      header: () => <span>Created</span>,
      cell: data => (
        <span>{format(handleDateAsTimestamp(data.getValue()), "MM-dd-yy hh:mm aaa")}</span>
      ),
    }),
    COLUMN_HELPER.accessor("author", {
      header: () => <span>Author</span>,
      cell: data =>
        `${data.getValue().first_name} ${data.getValue().middle_name ?? ""} ${
          data.getValue().last_name
        }`,
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 500,
      minSize: 500,
      cell: data => data.getValue(),
    }),
    COLUMN_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      size: 100,
      enableSorting: false,
      cell: data => <span style={{ textTransform: "capitalize" }}>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Options</span>,
      enableSorting: false,
      minSize: 210,
      cell: data => (
        <div className="d-flex">
          {activeTab !== "archived" && (
            <Link to={`/articles/${data.getValue()}/`}>
              <Button modifierClass="btn--text btn--text--primary mr--20">View</Button>
            </Link>
          )}

          <PermissionCheckComponentWrapper permissions={["articles_edit"]}>
            <Link to={`/articles/${data.getValue()}/edit/`}>
              <Button modifierClass="btn--text btn--text--secondary mr--20">Edit</Button>
            </Link>
          </PermissionCheckComponentWrapper>

          <PermissionCheckComponentWrapper permissions={["articles_delete"]}>
            <Button
              modifierClass="btn--text btn--text--danger"
              onClick={() => handleOpenArchiveModal(data.getValue())}
            >
              {activeTab === "archived" ? "Unarchive" : "Archive"}
            </Button>
          </PermissionCheckComponentWrapper>
        </div>
      ),
    }),
  ];

  /*=========================
    ARCHIVING MODAL
  ==========================*/
  const [selectedArticleId, setSelectedArticleId] = useState<number | null>(null);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [archiveAction, setArchiveAction] = useState<"archive" | "unarchive" | null>(null);

  const handleOpenArchiveModal = (articleId: number) => {
    // If there's an invalid article ID value, exit function
    if (articleId == null) return;

    setSelectedArticleId(articleId);
    setShowArchiveModal(true);

    // Set the selected archiving action type
    // based on currently selected tab
    if (activeTab === "archived") {
      setArchiveAction("unarchive");
    } else {
      setArchiveAction("archive");
    }
  };

  const handleCloseArchiveModal = () => {
    setSelectedArticleId(null);
    setShowArchiveModal(false);
    setArchiveAction(null);
  };

  /*=========================
    ARCHIVING AN ARTICLE
  ==========================*/
  const articleArchiving = useAdminArticleArchiving();

  const handleArticleArchiving = async () => {
    // Prevent function execution if the article ID is null
    if (selectedArticleId == null) return;

    // Status to which the article will be updated
    const status: "archived" | "draft" = archiveAction === "archive" ? "archived" : "draft";

    try {
      // Close the modal after the request has finished
      handleCloseArchiveModal();

      await articleArchiving.mutateAsync({
        id: selectedArticleId,
        status,
      });
    } catch (error) {
      errorReporting("Failed updating article status", error, { id: selectedArticleId, status });
    }
  };

  return (
    <>
      <Table
        data={articlesData}
        columns={ARTICLES_OVERVIEW_COLUMNS}
        isRefetching={isFetching}
        paginationPageSize={100}
      />

      <AnimatePresence>
        {showArchiveModal && (
          <Modal
            title={archiveAction === "archive" ? `Archive Article?` : "Unarchive Article?"}
            text={`This action will ${archiveAction} the selected article. Do you want to continue?`}
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleCloseArchiveModal}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleCloseArchiveModal}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--fluid--md btn--secondary"
                onClick={handleArticleArchiving}
                isDisabled={articleArchiving.isLoading}
                isLoading={articleArchiving.isLoading}
              >
                {archiveAction}
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default ArticlesOverviewTable;
