import { useLocation } from "react-router-dom";

export const DETAILS_SPECIFIC_PAGES: string[] = [
  "/applications/", // Represents the Application by ID page
];

/**
 *
 * Utility function for handling page reload, and possible redirect,
 * based on the page from which the user triggered a changed in the
 * currently active company.
 *
 * If the user was on a "details-specific" page,
 * e.g. page for specific application (/applications/:id),
 * then on successful active company change, the user will be redirected
 * to the default route defined in the router (in this case: the home Applications page).
 *
 * Otherwise only a page reload will be triggered, if the active company change
 * was not triggered from some "details-specific" page, e.g. Appointments page.
 *
 * @returns Function to be triggered on successful Active Company change API request
 *
 */
export default function handleRedirectOnActiveCompanyChange() {
  const { pathname } = useLocation();

  // Check if the page on which the user triggered a change
  // in the currently active company, is a "details-specific" page.
  const detailsSpecificPageCheck: boolean = DETAILS_SPECIFIC_PAGES.some(pageURL => {
    const pathnameLowercased: string = pathname.toLowerCase();
    const pageURLLowercased: string = pageURL.toLowerCase();

    return pathnameLowercased.includes(pageURLLowercased);
  });

  function handleLocationReplacement() {
    if (detailsSpecificPageCheck) {
      window.location.replace("/");
    } else {
      window.location.reload();
    }
  }

  return { handleLocationReplacement };
}
