// Components
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import Datepicker from "../../components/Datepicker/Datepicker";
import Button from "../../components/Button/Button";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Dropdown from "../../components/Dropdown/Dropdown";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";

// Hooks & Utilities
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";
import { format, subDays } from "date-fns";
import { useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { matchSorter } from "match-sorter";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import { useReportGetZipRecruiterSponsored } from "../../api/Reports/Reports";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";

// Interfaces & Constants
import {
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
  REPORTS_ZIPRECRUITER_SPONSORED_DOCUMENT_COLUMNS,
} from "./constants";
import { ReportZipRecruiterSponsoredTableData } from "./interfaces";
import { ReportZipRecruiterSponsoredAdsResponseFields } from "../../api/Reports/interfaces";
// import { DropdownItem } from "../../components/Dropdown/interfaces";

const ZipRecruiterSponsored = () => {
  /*===============================================
    DATE RANGE SELECTION

    The date range is handled using the custom hook
    which handles the 'preview dates' and the 
    'active dates'.
  ================================================*/

  const { pickerDates, filterDates, setFilterDates, handleStartDate, handleEndDate } =
    useReportDateRangeHandling({
      initial_start_date: format(subDays(Date.now(), 7), "MM/dd/yyyy"),
      initial_end_date: format(Date.now(), "MM/dd/yyyy"),
    });

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportZipRecruiterSponsoredTableData>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.display({
      id: "row_id",
      header: () => <span>#</span>,
      size: 50,
      enableSorting: true,
      cell: props => <span>{+props.row.id + 1}</span>,
    }),
    COLUMN_HELPER.accessor("ad_id", {
      header: () => <span>Ad Id</span>,
      size: 80,
      enableSorting: true,
      cell: data => (
        <span>
          <Link to={`/job-ads/ads/${data.getValue()}/`} className="d-flex align-items-center">
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job Code</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 250,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/job-ads/ads/${data.row.original.ad_id}/`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("company", {
      header: () => <span>Company</span>,
      size: 150,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    // Temporarily Disabled
    // COLUMN_HELPER.accessor("campaign", {
    //   header: () => <span>Campaign</span>,
    //   size: 140,
    //   enableSorting: true,
    //   cell: data => <span>{data.getValue()}</span>,
    // }),
    COLUMN_HELPER.accessor("post_date", {
      header: () => <span>Post Date</span>,
      size: 100,
      enableSorting: true,
      sortingFn: "datetime",
      cell: data => (
        <span>{data.getValue() ? format(data.getValue(), "dd MMM yyyy hh:mm:ss aa") : "N/A"}</span>
      ),
    }),
    COLUMN_HELPER.accessor("take_down_date", {
      header: () => <span>Take Down Date</span>,
      size: 100,
      enableSorting: true,
      sortingFn: "datetime",
      cell: data => (
        <span>{data.getValue() ? format(data.getValue(), "dd MMM yyyy hh:mm:ss aa") : "N/A"}</span>
      ),
    }),
    COLUMN_HELPER.accessor("refreshed", {
      header: () => <span>Refreshed?</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("refresh_date", {
      header: () => <span>Refresh Date</span>,
      size: 100,
      enableSorting: true,
      sortingFn: "datetime",
      cell: data => (
        <span>
          {data.getValue() ? format(new Date(data.getValue()), "dd MMM yyyy hh:mm:ss aa") : "N/A"}
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("applications", {
      header: () => <span>Applications</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("resumes", {
      header: () => <span>Resumes</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("sbcas", {
      header: () => <span>SBCAs</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
  ];

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    isLoading: zipRecruiterLoading,
    isRefetching: zipRecruiterRefetching,
    isFetching: zipRecruiterFetching,
    data: zipRecruiterData,
  } = useReportGetZipRecruiterSponsored(filterDates);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<
    ReportZipRecruiterSponsoredAdsResponseFields[]
  >([]);

  /*============================================
    CAMPAIGN FILTER
  =============================================*/
  // Temporarily Disabled
  // const [campaignFilter, setCampaignFilter] = useState<string>("All Campaigns");
  // useEffect(() => {
  //   const { campaign } = searchParametersObject;

  //   if (campaign) setCampaignFilter(campaign);
  // }, []);

  // const handleCampaignFilter = (campaign: string) => {
  //   setCampaignFilter(campaign);

  //   // Append the "campaign" filter route parameter
  //   if (campaign !== "All Campaigns") {
  //     setSearchParameters({ ...searchParametersObject, campaign: campaign });
  //   } else {
  //     delete searchParametersObject.campaign;
  //     setSearchParameters({ ...searchParametersObject });
  //   }
  // };

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "ad_id", "job_code", "title" & "company"
    data fields. 
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    if (!zipRecruiterData || !zipRecruiterData.ads.length) return [];

    let REPORT_DATA = zipRecruiterData.ads.map((entity, index) => {
      return {
        id: index + 1,
        ad_id: entity.ad_id,
        job_code: entity.job_code,
        title: entity.title,
        company: entity.company,
        // campaign:
        //   ZIP_RECRUITER_DROPDOWN_SPONSORED_CAMPAIGNS.find(
        //     (campaign: DropdownItem) => campaign.value === entity.campaign,
        //   )?.text || "N/A",
        post_date: entity.post_date ? new Date(entity.post_date) : null,
        take_down_date: entity.take_down_date ? new Date(entity.take_down_date) : null,
        refreshed: entity.refreshed ? "Yes" : "No",
        refresh_date: entity.refresh_date ? new Date(entity.refresh_date) : null,
        applications: entity.applications,
        resumes: entity.resumes,
        sbcas: entity.sbcas,
      };
    });

    // if (campaignFilter !== "All Campaigns") {
    //   REPORT_DATA = REPORT_DATA.filter(entity => entity.campaign === campaignFilter);
    // }

    if (searchedValue) {
      REPORT_DATA = matchSorter(REPORT_DATA, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "ad_id" },
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "title" },
          { threshold: matchSorter.rankings.CONTAINS, key: "company" },
        ],
      });
    }

    return REPORT_DATA;
  }, [searchedValue, zipRecruiterData]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  /*====================================================
    DOCUMENT EXPORT DATA
  =====================================================*/
  const EXPORT_DATA = useMemo(() => {
    if (!exportedTableData.length) return [];

    const formattedDates = exportedTableData.map(item => {
      return {
        ...item,
        post_date: item.post_date
          ? format(new Date(item.post_date), "dd MMM yyyy hh:mm:ss a")
          : "N/A",
        take_down_date: item.take_down_date
          ? format(new Date(item.take_down_date), "dd MMM yyyy hh:mm:ss a")
          : "N/A",
        refresh_date: item.refresh_date
          ? format(new Date(item.refresh_date), "dd MMM yyyy hh:mm:ss a")
          : "N/A",
      };
    });

    return formattedDates;
  }, [exportedTableData]);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">ZipRecruiter Sponsored Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || "Select start date"}
                    dateFormat="m/d/Y"
                    isDisabled={zipRecruiterLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">End Date:</div>
                </div>
                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={zipRecruiterLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-2 col-md-4 d-flex flex-column align-items-xl-start justify-content-md-end align-items-end pr--0 pr--md--15 pb--15 pb--sm--20 ">
              <Button
                modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                onClick={() => setFilterDates(pickerDates)}
                isLoading={zipRecruiterLoading || zipRecruiterFetching}
                isDisabled={zipRecruiterFetching || zipRecruiterLoading}
              >
                Run Report
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <div className="px--30">
        {!zipRecruiterLoading ? (
          <>
            <Card modifierClass="card--padding--xl">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column align-items-start align-items-md-start">
                    <h5 className="fw--semibold mb--xl--10">
                      Currently Viewing: {filterDates.start_date} through {filterDates.end_date}
                    </h5>
                    <h5 className="mb--xl--10">Total Ads: {REPORT_TABLE_DATA.length}</h5>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="d-flex flex--wrap--md justify-content-start justify-content-lg-end mb--10"
                    style={{ gridGap: "10px" }}
                  >
                    <InputFieldSearch
                      handleOnSearch={search => setSearchedValue(search)}
                      placeholder="Search Reports"
                      size="md"
                    />
                    {/* CAMPAIGN FILTER */}
                    {/* Temporarily Disabled */}
                    {/* <Dropdown
                      title="All Campaigns"
                      size="md"
                      items={[
                        { text: "All Campaigns", value: "All Campaigns" },
                        ...ZIP_RECRUITER_DROPDOWN_SPONSORED_CAMPAIGNS.map(item => {
                          return { text: item.text, value: item.text };
                        }),
                      ]}
                      handleItemSelected={(campaign: DropdownItem) =>
                        handleCampaignFilter(campaign.value as string)
                      }
                      preselectedItemValue={campaignFilter}
                      disabled={zipRecruiterLoading}
                    /> */}

                    <Dropdown
                      title="Select number of rows"
                      preselectedItemValue={rowsPerPage}
                      size="md"
                      items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                      handleItemSelected={item => setRowsPerPage(item.value as number)}
                    />
                  </div>
                </div>
              </div>
            </Card>

            <Card>
              <h3 className="fw--semibold mb--5 px--20 pt--10">Export Data</h3>
              <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                  onClick={() =>
                    handleInitiateSpreadsheetGenerator(
                      EXPORT_DATA,
                      "ziprecruiter_sponsored_report",
                      REPORTS_ZIPRECRUITER_SPONSORED_DOCUMENT_COLUMNS,
                      "default-csv",
                      `${filterDates.start_date} to ${filterDates.end_date}`,
                    )
                  }
                  isDisabled={!REPORT_TABLE_DATA.length || zipRecruiterFetching}
                >
                  CSV
                </Button>
                <Button
                  modifierClass="btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0"
                  onClick={() =>
                    handleInitiatePDFWorker({
                      columns: REPORTS_ZIPRECRUITER_SPONSORED_DOCUMENT_COLUMNS,
                      data: EXPORT_DATA,
                      documentTitle: "FirstChoice Hiring - ZipRecruiter Sponsored Report",
                      documentSubTitle: `Data Date: ${filterDates.start_date} to ${filterDates.end_date}`,
                      template: "default-report",
                      fileName: "ziprecruiter_sponsored_report",
                      setPDFLoading: setPDFLoading,
                    })
                  }
                  isDisabled={!REPORT_TABLE_DATA.length || zipRecruiterFetching || PDFLoading}
                  isLoading={PDFLoading}
                >
                  PDF
                </Button>
              </div>
              <Table
                data={REPORT_TABLE_DATA}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={zipRecruiterRefetching}
                paginationPageSize={rowsPerPage}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default ZipRecruiterSponsored;
