// Utilities
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

// Components
import Card from "../../Card/Card";
import Table from "../../Table/Table";

// Assets
import { IoMdCheckmark as ResultCheckmarkIcon, IoMdClose as ResultTimesIcon } from "react-icons/io";

// Interfaces
import { JobBoardPostingHistoryProps, JobBoardPostingHistoryTableFields } from "./interfaces";

const JobBoardPostingHistory = ({
  job_ad_status,
  job_board_posting_history,
}: JobBoardPostingHistoryProps) => {
  /*======================================
    TABLE DATA MAPPING
  =======================================*/
  const TABLE_DATA = useMemo(() => {
    const dataset: JobBoardPostingHistoryTableFields[] = [...job_board_posting_history];

    // Manually insert a table row with default data if the job ad is in one of "Active" or "Expired" statuses
    if (["active", "expired"].includes(job_ad_status)) {
      dataset.unshift({
        job_board: "Career Page",
        action_taken: "Posted",
        action_time: null,
        status: 1,
      });
    }

    return dataset;
  }, [job_ad_status, job_board_posting_history]);

  /*======================================
    TABLE COLUMNS
  =======================================*/
  const COLUMNS_HELPER = createColumnHelper<JobBoardPostingHistoryTableFields>();

  const JOB_BOARD_POSTING_HISTORY_TABLE_COLUMNS = [
    COLUMNS_HELPER.accessor("job_board", {
      header: () => <span>Job Board</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("action_taken", {
      header: () => <span>Action Taken</span>,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("action_time", {
      header: () => <span>Action Time</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("status", {
      header: () => <span>Result</span>,
      cell: data => (
        <span>
          {[200, 1].includes(data.getValue()) ? (
            <ResultCheckmarkIcon
              style={{ color: "orange", margin: "0 10px", fontSize: "28px" }}
              data-testid="component:job-board-posting-history-success"
            />
          ) : (
            <ResultTimesIcon
              style={{ color: "orange", margin: "0 10px", fontSize: "28px" }}
              data-testid="component:job-board-posting-history-failed"
            />
          )}
        </span>
      ),
      enableSorting: false,
      size: 70,
    }),
  ];

  return (
    <Card modifierClass="card--padding--sm">
      <h3 className="fw--semibold txt--blue">Job Board Posting History</h3>

      {TABLE_DATA.length > 0 ? (
        <Table
          data={TABLE_DATA}
          columns={JOB_BOARD_POSTING_HISTORY_TABLE_COLUMNS}
          isRefetching={false}
          modifierClass="table-wrapper--no-shadow mb--0i px--0i"
          shouldHandlePageRouteParameter={false}
        />
      ) : (
        <p className="txt--black mt--20 mb--0">No job board submission history.</p>
      )}
    </Card>
  );
};

export default JobBoardPostingHistory;
