// Utilities & Hooks
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAdminArticlesCreate, useAdminArticlesGet } from "../../api/Articles/AdminArticles";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../../components/Button/Button";
import Tabs from "../../components/Tabs/Tabs";
import ArticlesOverviewTable from "../../components/Articles/ArticlesOverviewTable";
import ArticlesOverviewSkeleton from "./Skeleton/ArticlesOverview";
import ContentHeader from "../../components/Content/ContentHeader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { TabItemProps } from "../../components/Tabs/interfaces";
import { ArticlesResponseFields, ArticleStatus } from "../../api/Articles/interfaces";

const ArticlesOverview = () => {
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*===========================
    ARTICLES DATA TAB 
  ============================*/
  const [activeTab, setActiveTab] = useState<ArticleStatus>("published");

  const handleTabSelection = (tab: TabItemProps) => {
    const tabId = tab.id as ArticleStatus;
    setActiveTab(tabId);
  };

  /*===========================
    ARTICLES TABLE DATA

    - Fetch data from the API
    - Process the data before sending it to the table
  ============================*/
  const { data, isLoading, isFetching } = useAdminArticlesGet();

  const articlesData: ArticlesResponseFields[] = useMemo(() => {
    if (!data || !Object.entries(data).length) return [];

    // Filter out the data based on the selected active tab
    const articles = [...data].filter(
      (article: ArticlesResponseFields) => article.status === activeTab,
    );

    return articles;
  }, [data, activeTab]);

  /*===========================
    ARTICLE CREATION

    Creates a draft article and
    redirects the user to the "edit"
    page for that newly created article
  ============================*/
  const [articleCreationOngoing, setArticleCreationOngoing] = useState<boolean>(false);
  const articleCreation = useAdminArticlesCreate();

  const handleArticleCreation = async () => {
    setArticleCreationOngoing(true);

    try {
      const articleCreationResponse = await articleCreation.mutateAsync();

      // Redirect the user to the ID appointed to the newly created (draft) article
      if (articleCreationResponse.id != null) {
        navigate(`/articles/${articleCreationResponse.id}/edit/`);
      } else {
        throw new Error("Article was not created successfully.");
      }
    } catch (error) {
      errorReporting("Failed creating new 'Draft' article.", error);
    } finally {
      setArticleCreationOngoing(false);
    }
  };

  return (
    <div className="container py--25">
      <ContentHeader title="Articles Overview" modifierClass="content__header--no-underline" />

      {isLoading ? (
        <ArticlesOverviewSkeleton />
      ) : (
        <>
          <div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-lg-center">
            <Tabs
              tabs={[
                {
                  id: "published",
                  text: `Published Articles (${
                    data?.filter(article => article.status === "published").length || "0"
                  })`,
                },
                {
                  id: "draft",
                  text: `Draft (${
                    data?.filter(article => article.status === "draft").length || "0"
                  })`,
                },
                {
                  id: "archived",
                  text: `Archived (${
                    data?.filter(article => article.status === "archived").length || "0"
                  })`,
                },
              ]}
              activeTabId={activeTab}
              handleTabSelection={handleTabSelection}
            />
            <div className="mb--10">
              <Link to="/articles/">
                <Button modifierClass="btn--fluid btn--primary mr--10 mb--md--10">
                  View Articles
                </Button>
              </Link>

              <PermissionCheckComponentWrapper permissions={["articles_create"]}>
                <Button
                  modifierClass="btn--fluid btn--secondary"
                  onClick={handleArticleCreation}
                  isDisabled={articleCreationOngoing}
                  isLoading={articleCreationOngoing}
                >
                  Create New
                </Button>
              </PermissionCheckComponentWrapper>
            </div>
          </div>

          <ArticlesOverviewTable
            articlesData={articlesData}
            activeTab={activeTab}
            isFetching={isFetching}
          />
        </>
      )}
    </div>
  );
};

export default ArticlesOverview;
