// Hooks
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useInvitationGetStatus } from "../../api/Invitation/Invitation";

// Components
import Loader from "../../components/Loader/Loader";

const UserInvitationStatusCheck = () => {
  const { invitationID } = useParams();
  const navigate = useNavigate();

  /*==============================
    INVITATION INFO
  ===============================*/
  const { data, isLoading, error } = useInvitationGetStatus(invitationID);

  // Redirect the user to the correct page to finalize the invitaiton flow
  useEffect(() => {
    if (!data || !Object.entries(data) || isLoading) return;

    const { user_status } = data;

    if (user_status === "new") {
      navigate(`/invitation/register/${invitationID}/`);
    } else {
      window.location.href = `/account/invitation/accept/${invitationID}/`;
    }
  }, [data]);

  // If there's an error with the API response, redirect to error page
  useEffect(() => {
    if (error) navigate("/404/");
  }, [error]);

  return <div>{isLoading ? <Loader size="page" modifierWrapper="loader--page" /> : null}</div>;
};

export default UserInvitationStatusCheck;
