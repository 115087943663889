// Hooks
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

// Socket & Context
import socket from "../../config/chat-socket";
import { useChatsContext } from "./ChatWrapper/ChatContextWrapper";

// Assets
import { IoCloseSharp as ChatBubbleCloseIcon } from "react-icons/io5";

// Interfaces
import { ChatDetails } from "./ChatWrapper/interfaces";
import { ChatMessagesResponseFields, ChatResponseFields } from "../../api/Chat/interfaces";
import { ChatBubbleExtrasItemProps } from "./interfaces";

const ChatBubbleExtrasItem = ({
  chatDetails,
  handleCloseBubbleExtrasMenu,
}: ChatBubbleExtrasItemProps) => {
  const queryClient = useQueryClient();
  const chatsContext = useChatsContext();

  /*============================
    COUNT THE UNREAD MESSAGES
  =============================*/
  const [chatMessages, setChatMessages] = useState<ChatResponseFields[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  useEffect(() => {
    if (!chatMessages.length) return;

    // Count the unread messages
    const unreadMessages: number = chatMessages.filter(message => !message.is_read).length;
    setUnreadMessagesCount(unreadMessages);
  }, [chatMessages]);

  // Read all the messages for this specific chat from the cached data
  // and update the state, which will trigger re-count of the unread messages
  const messagesForChat = queryClient.getQueryData([
    "sms-chat-messages",
    chatDetails.chat_id,
  ]) as ChatMessagesResponseFields;

  useEffect(() => {
    if (!messagesForChat || !messagesForChat.messages.length) return;
    setChatMessages(messagesForChat.messages);
  }, [messagesForChat]);

  /*============================
    INCOMING MESSAGES
  =============================*/
  socket.on("inbound message", (incomingMessage: ChatResponseFields) => {
    // Do not show any messages and notifications if the
    // message that was received is not meant for the chat bubble
    if (incomingMessage.chat_id !== chatDetails.chat_id) return;

    const updatedChatMessages = [...chatMessages];
    updatedChatMessages.push(incomingMessage);
    setChatMessages(updatedChatMessages);
  });

  /*==========================
    HANDLE BUBBLE CHATS
  ===========================*/
  const handleOpenBubbleChat = (event: React.MouseEvent, chatDetails: ChatDetails) => {
    event.stopPropagation();
    chatsContext.handleOpenBubbleChat(chatDetails);
    handleCloseBubbleExtrasMenu();
  };

  const handleCloseBubbleChat = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();
    chatsContext.handleCloseChat(id);
  };

  return (
    <li
      className="chat-bubble-extras__list__item"
      onClick={(event: React.MouseEvent) => handleOpenBubbleChat(event, chatDetails)}
    >
      {chatDetails.applicant.name}

      <div
        className="chat-bubble-extras__list__item__close"
        onClick={(event: React.MouseEvent) => handleCloseBubbleChat(event, chatDetails.id)}
      >
        <ChatBubbleCloseIcon />
      </div>

      {unreadMessagesCount > 0 ? (
        <span className="notifications-counter notifications-counter--chat-extras-items">
          {unreadMessagesCount}
        </span>
      ) : null}
    </li>
  );
};

export default ChatBubbleExtrasItem;
