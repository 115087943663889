import { ResourcesItem, ResourcesGroups } from "./interfaces";

const RESOURCES_FAQ: ResourcesItem[] = [
  { link: "Quick_Contact_Card.pdf", text: "Quick Contact Card" },
  { link: "FAQS_General_Questions.pdf", text: "General" },
  { link: "FAQS_Personality_v_Behavior.pdf", text: "Personality Versus Behavior" },
  { link: "FAQS_InterpretingSBCA.pdf", text: "Interpreting the SBCA" },
  { link: "FAQS_SBCAProfileDescriptions.pdf", text: "SBCA Profile Descriptions" },
  { link: "FAQS_SBCAInnateDrive.pdf", text: "SBCA Measuring Innate Drive" },
  { link: "Resources_Dashboard_Tutorial.pdf", text: "Dashboard Tutorial" },
];

const RESOURCES_BEHAVIOUR_PROFILE: ResourcesItem[] = [
  { link: "InterviewQuestions_CAREGIVER.pdf", text: "Interview Questions - CAREGIVER" },
  { link: "InterviewQuestions_PROCESSOR.pdf", text: "Interview Questions - PROCESSOR" },
  { link: "InterviewQuestions_THINKER.pdf", text: "Interview Questions - THINKER" },
  { link: "InterviewQuestions_INNATEDRIVE.pdf", text: "Interview Questions – INNATE DRIVE" },
];

const RESOURCES_POSITION_TYPE: ResourcesItem[] = [
  {
    link: "InterviewQuestions_AdminClerical.pdf",
    text: "Interview Questions – Administrative / Clerical",
  },
  {
    link: "InterviewQuestions_CustomerService.pdf",
    text: "Interview Questions – Customer Service",
  },
  { link: "InterviewQuestions_Management.pdf", text: "Interview Questions - Management" },
  { link: "InterviewQuestions_Sales.pdf", text: "Interview Questions - Sales" },
  { link: "InterviewQuestions_Technical.pdf", text: "Interview Questions - Technical" },
];

const RESOURCES_TEMPLATE_DOCUMENTS: ResourcesItem[] = [
  { link: "Simple_Job_Offer_Letter+V2.docx", text: "Simple Job Offer Letter" },
  { link: "Expanded_Job_Offer_Letter+V2.docx", text: "Expanded Job Offer Letter" },
  {
    link: "Employment_Reference_Check_Authorization_Form+V2.docx",
    text: "Employment Reference Check Authorization Form",
  },
];

export const RESOURCES_GROUPS: ResourcesGroups[] = [
  { title: "Frequently Asked Questions", pdfs: RESOURCES_FAQ },
  { title: "Interview Questions - Behavior Profile", pdfs: RESOURCES_BEHAVIOUR_PROFILE },
  { title: "Interview Questions - Position Type", pdfs: RESOURCES_POSITION_TYPE },
  { title: "Template Documents", pdfs: RESOURCES_TEMPLATE_DOCUMENTS },
];
