// Utilities & Hooks
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useAdminGetAllCompanyForms } from "../../../api/Company/AdminCompany";
import { useJobAdsUpdateAdSettings } from "../../../api/JobAds/JobAds";
import { format, addDays, isPast } from "date-fns";
import { useAdminGetSBCATypes } from "../../../api/SBCA/SbcaTypes";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Interfaces
import { JobAdDetailsAdSettingsUpdateRequestFields } from "../../../api/JobAds/interfaces";
import { DropdownItem } from "../../Dropdown/interfaces";
import { JobAdDetailsFormFields, JobAdDetailsSettingsProps } from "./interfaces";

// Schemas
import { JOB_ADS_DETAILS_AD_SETTINGS_SCHEMA } from "../../../schemas/JobAdSchemas";

// Components
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import Datepicker from "../../Datepicker/Datepicker";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";

const JobAdSettings = ({ id, status, settings }: JobAdDetailsSettingsProps) => {
  const errorReporting = useErrorReporting();

  /*===============================
    FETCH THE LIST OF FORMS
  ================================*/
  const { data: companyForms, isLoading: companyFormsLoading } = useAdminGetAllCompanyForms();

  // Map the received company forms data into dropdown items
  const JOB_APPLICATION_TYPES: DropdownItem[] = useMemo(() => {
    if (!companyForms || !companyForms.length || companyFormsLoading) return [];

    return companyForms.map(form => {
      return { text: form.name, value: form.id };
    });
  }, [companyForms]);

  /*===============================
    FETCH THE LIST OF SBCA TYPES
  ================================*/
  const { data: sbcaTypes, isLoading: sbcaTypesLoading } = useAdminGetSBCATypes();

  // Map the received SBCA types into dropdown items
  const SBCA_TYPES: DropdownItem[] = useMemo(() => {
    if (!sbcaTypes || !sbcaTypes.length || sbcaTypesLoading) return [];

    return sbcaTypes.map(sbca => {
      return { text: `SBCA - ${sbca.name}`, value: sbca.id };
    });
  }, [sbcaTypes]);

  /*===============================
    FORM INITIAL VALUES
  ================================*/
  const [formValues, setFormValues] = useState<JobAdDetailsFormFields>({
    form_id: null,
    sbca_type_id: null,
    live_date: new Date(),
    expire_date: addDays(new Date(), 30),
  });

  // Reinitialize the form values with the
  // values received from the "ad_settings" response field
  useEffect(() => {
    // If a valid value is received from the API use that, otherwise default to "todays" date
    let liveDate: Date = settings.live_date ? new Date(settings.live_date) : new Date();

    // Default to "todays" date if the ad is new but the received
    // "live_date" value from the API response is in the past
    if (["new", "pending", "ready_to_post"].includes(status) && isPast(liveDate)) {
      liveDate = new Date();
    }

    setFormValues({
      // We use ID 2 to preselect "Short Application Form"
      form_id: settings.form_id || 2,
      sbca_type_id: settings.sbca_id,
      live_date: liveDate,
      expire_date: settings.expire_date ? new Date(settings.expire_date) : addDays(liveDate, 30),
    });
  }, [settings]);

  /*===============================
    UPDATE JOB AD SETTINGS
  =================================*/
  const updateSettings = useJobAdsUpdateAdSettings(id);

  const handleAdSettingsUpdate = async (values: JobAdDetailsFormFields) => {
    // If no "Job Application Type" or "SBCA Type" was selected, prevent sending the request
    if (!values.form_id || !values.sbca_type_id) return;

    try {
      // Constuct the request payload and convert the "live" and "expire"
      // dates values to timestamps before sending the request
      const REQUEST_PAYLOAD: JobAdDetailsAdSettingsUpdateRequestFields = {
        form_id: values.form_id,
        sbca_type_id: values.sbca_type_id,
        live_date: format(new Date(values.live_date), "MM/dd/yyyy"),
        expire_date: format(new Date(values.expire_date), "MM/dd/yyyy"),
      };

      await updateSettings.mutateAsync(REQUEST_PAYLOAD);
    } catch (error) {
      errorReporting("Failed updating ad settings", error, {
        ad_id: id,
        ad_status: status,
        ...values,
      });
    }
  };

  return (
    <div className="col-12 col-lg-6 mb--20">
      <Card modifierClass="card--padding--xl">
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={handleAdSettingsUpdate}
          validationSchema={JOB_ADS_DETAILS_AD_SETTINGS_SCHEMA}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className="job-ads-details__ad-settings">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb--40">
                <h3 className="fw--semibold txt--blue">Ad Settings</h3>

                <PermissionCheckComponentWrapper permissions={["ad_manager_edit"]}>
                  <Button
                    modifierClass="btn--fluid btn--primary"
                    isLoading={updateSettings.isLoading}
                    isDisabled={updateSettings.isLoading}
                  >
                    Update Ad Settings
                  </Button>
                </PermissionCheckComponentWrapper>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mb--40">
                  <Field
                    component={FormDropdownSearchable}
                    id="form_id"
                    name="form_id"
                    label="Job Application Type"
                    size="full"
                    items={JOB_APPLICATION_TYPES}
                    isLoading={companyFormsLoading}
                    disabled={!JOB_APPLICATION_TYPES.length}
                    placeholder="Select Job Application"
                    preselectedItemValue={formValues.form_id}
                    handleFieldUpdate={(form_id: DropdownItem) =>
                      setFieldValue("form_id", form_id.value)
                    }
                  />
                </div>

                <div className="col-12 col-lg-6 mb--40">
                  <Field
                    component={FormDropdownSearchable}
                    id="sbca_type_id"
                    name="sbca_type_id"
                    label="SBCA Type"
                    size="full"
                    items={SBCA_TYPES}
                    isLoading={sbcaTypesLoading}
                    disabled={!SBCA_TYPES.length || status === "active"}
                    placeholder="Select SBCA Type"
                    preselectedItemValue={formValues.sbca_type_id}
                    handleFieldUpdate={(sbca_type_id: DropdownItem) =>
                      setFieldValue("sbca_type_id", sbca_type_id.value)
                    }
                  />
                </div>
              </div>

              <div className="row">
                {/* LIVE DATE */}
                <div className="col-12 col-lg-6 mb-20">
                  <div className="p--relative">
                    <label className="dropdown__label">Live Date</label>
                    <Datepicker
                      dateFormat="m/d/Y"
                      enableTime={false}
                      modifierClass="datepicker--full mb--20"
                      preselectedDate={values.live_date}
                      handleSelectedDate={dates => {
                        // Prevent date updates functionality based on status restrictions
                        if (["active", "takedown", "expired"].includes(status)) return;

                        setFieldValue("live_date", dates[0]);

                        // Update the `expire_date` field value to always be 30 days after the selected live date
                        setFieldValue("expire_date", addDays(dates[0], 30));
                      }}
                      isDisabled={["active", "takedown", "expired"].includes(status)}
                      placeholder={format(values.live_date, "MM/dd/yyyy")}
                    />
                    {errors.live_date && touched.live_date ? (
                      <span
                        className="input__error"
                        style={{ position: "relative", bottom: "10px" }}
                      >
                        <ErrorMessage name="live_date" />
                      </span>
                    ) : null}
                  </div>
                </div>

                {/* EXPIRE DATE */}
                <div className="col-12 col-lg-6 mb-20">
                  <div className="p--relative">
                    <label className="dropdown__label">Expire Date</label>
                    <Datepicker
                      dateFormat="m/d/Y"
                      enableTime={false}
                      modifierClass="datepicker--full mb--20"
                      preselectedDate={values.expire_date}
                      handleSelectedDate={() => {
                        // Even if someone 'enables' the expire date field
                        // we will still fallback to a date that is 30 days after the selected live date
                        setFieldValue("expire_date", addDays(values.live_date, 30));
                      }}
                      placeholder={format(values.expire_date, "MM/dd/yyyy")}
                      minDate={values.live_date}
                      isDisabled
                    />
                    {errors.expire_date && touched.expire_date ? (
                      <span
                        className="input__error"
                        style={{ position: "relative", bottom: "10px" }}
                      >
                        <ErrorMessage name="expire_date" />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default JobAdSettings;
