// Hooks & Utiities
import { useState } from "react";
import { useApplicationRequestResume } from "../../../api/Applications/Applications";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../Button/Button";
import FormTextarea from "../../Form/FormTextarea";
import Modal from "../../Modal/Modal";
import ApplicationModalSuccessfulRequest from "./ApplicationModalSuccessfulRequest";

// Interfaces
import { ApplicationModalResumeRequestProps } from "../interfaces";

// Schemas
import { APPLICATIONS_RESUME_REQUEST } from "../../../schemas/ApplicationsSchemas";
import { AnimatePresence } from "framer-motion";

const ApplicationModalResumeRequest = ({
  id,
  name,
  email,
  handleCloseModal,
}: ApplicationModalResumeRequestProps) => {
  const errorReporting = useErrorReporting();

  /*===========================
    REQUEST RESUME
  ============================*/
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const requestResume = useApplicationRequestResume();

  const handleRequestResume = async (values: FormikValues) => {
    try {
      await requestResume.mutateAsync({ id: values.id, message: values.message });

      // Display the "Success" modal after the request has completed
      setShowSuccessModal(true);
    } catch (error) {
      errorReporting("Failed requesting application resume", error, {
        application_id: id,
        application_name: name,
      });
    }
  };

  return (
    <>
      {!showSuccessModal ? (
        <Modal
          title="Request Resume"
          text=""
          modifierClass="modal--fixated application-action-modal"
          handleCloseModal={handleCloseModal}
          testID="modal-resume-request"
        >
          <h6>
            <strong>Applicant:</strong> {name}
          </h6>
          <h6>
            <strong>Email:</strong> {email}
          </h6>

          <Formik
            initialValues={{
              id,
              message:
                "Thanks for responding! Your application is almost complete! Please click below to upload your resume and you’ll be good to go.",
            }}
            enableReinitialize
            validationSchema={APPLICATIONS_RESUME_REQUEST}
            onSubmit={handleRequestResume}
          >
            <Form>
              <Field
                id="message"
                name="message"
                placeholder="Your message to the applicant"
                modifierClass="mb--20"
                rows={7}
                component={FormTextarea}
              />

              <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary--light"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
                  isLoading={requestResume.isLoading}
                  isDisabled={requestResume.isLoading}
                >
                  Request Resume
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal>
      ) : null}

      <AnimatePresence>
        {showSuccessModal ? (
          <ApplicationModalSuccessfulRequest
            title="Request Resume"
            text="An email has been sent containing a link for the applicant to upload their resume."
            handleCloseModal={handleCloseModal}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default ApplicationModalResumeRequest;
