// Utilities & Hooks
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useOnlineApplicationGetInfo,
  useOnlineApplicationUploadPhoto,
} from "../../api/OnlineApplication/OnlineApplication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import FormUpload from "../../components/Form/FormUpload";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";
import Loader from "../../components/Loader/Loader";

// Schemas
import { ONLINE_APPLICATION_UPLOAD_PHOTO_SCHEMA } from "../../schemas/OnlineApplicationSchemas";

const OnlineApplicationUploadPhoto = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*===========================
    INFO DETAILS
  ============================*/
  const { data: infoData, isLoading: infoIsLoading, error } = useOnlineApplicationGetInfo(id);

  // Redirect the user to the 404 page if invalid application ID was used
  useEffect(() => {
    if (!error) return;
    navigate("/404/");
  }, [error]);

  /*===========================
    TRIGGER PHOTO UPLOAD
  ============================*/
  const uploadPhoto = useOnlineApplicationUploadPhoto();

  const handleUploadPhoto = async (values: FormikValues) => {
    // If there's no valid identifier received, prevent form submission
    if (!id) return;

    try {
      await uploadPhoto.mutateAsync({ id: id, photo: values.photo });

      // Redirect the user to the Thank You page
      navigate("/online-application/upload/photo/thank-you/");
    } catch (error) {
      errorReporting("Failed uploading application photo", error, { ...values });
    }
  };

  return (
    <div className="online-application-upload">
      {infoIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <div className="container">
          <h1 className="online-application-upload__title">{infoData?.company || "N/A"}</h1>

          <h3 className="online-application-upload__subtitle">Upload your Photo</h3>
          <p className="online-application-upload__text">
            Thank you for completing the job application. We appreciate your interest in this career
            opportunity. To improve the visibility of your application and to assist our hiring
            managers, please upload your photo here.
          </p>

          <Formik
            initialValues={{ photo: null }}
            validationSchema={ONLINE_APPLICATION_UPLOAD_PHOTO_SCHEMA}
            onSubmit={handleUploadPhoto}
          >
            {({ values, errors }) => (
              <Form>
                <Field
                  component={FormUpload}
                  id="photo"
                  name="photo"
                  buttonText="Choose File"
                  label="Your Photo"
                  placeholder="No file chosen"
                  modifierClass="input--box-error"
                  accept=".jpeg, .jpg, .png, .webp, .gif, .svg, .tiff"
                  uploadType="image"
                  fieldHasDedicatedFileTypeValidation
                  info={
                    <>
                      <span className="input-upload__info--info">
                        Supported file types: .jpeg, .jpg, .png, .webp, .gif, .svg, .tiff{" "}
                      </span>
                    </>
                  }
                />

                <p className="online-application-upload__text">
                  Please add no-reply@firstchoicehiring.com to your address book to ensure you
                  receive valuable communication from us in the future.
                </p>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--secondary"
                  isDisabled={uploadPhoto.isLoading || !values.photo || errors.photo}
                  isLoading={uploadPhoto.isLoading}
                >
                  Upload Photo
                </Button>
              </Form>
            )}
          </Formik>

          <OnlineApplicationFooter />
        </div>
      )}
    </div>
  );
};

export default OnlineApplicationUploadPhoto;
