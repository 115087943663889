import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import JobPositions from "../pages/JobPositions/JobPositions";
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_JOB_POSITIONS: ApplicationRouteProps[] = [
  {
    path: "/job-positions",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobPositions />
      </LayoutAuthenticated>
    ),
    permissions: ["company_job_position_view"],
    type: "protected",
  },
];
