// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  usePrivilegesGroupDelete,
  usePrivilegesGroupsGetAll,
} from "../../api/Privileges/Privileges";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../components/Table/Table";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";
import { AnimatePresence } from "framer-motion";

interface PrivilegesGroupTableFields {
  id: number;
  name: string;
  description: string;
  is_admin_only: string;
}

const PrivilegesGroup = () => {
  const errorReporting = useErrorReporting();

  /*====================================
    PRIVILEGES GROUPS TABLE
  =====================================*/
  const COLUMN_HELPER = createColumnHelper<PrivilegesGroupTableFields>();
  const PRIVILEGE_GROUPS_COLUMNS = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Group Name</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
    }),
    COLUMN_HELPER.accessor("description", {
      header: () => <span>Group Description</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
      minSize: 250,
    }),
    COLUMN_HELPER.accessor("is_admin_only", {
      header: () => <span>Group Type</span>,
      cell: data => <span>{data.getValue() ? "Admin" : "Public"}</span>,
      enableSorting: false,
      size: 50,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span className="d-block txt--right">Actions</span>,
      enableSorting: false,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <div className="table__buttons">
          <PermissionCheckComponentWrapper permissions={["privileges_group_edit"]}>
            <Link
              to={`/privileges/groups/edit/${data.getValue()}/`}
              className="btn btn--fluid btn--primary"
            >
              Edit
            </Link>
          </PermissionCheckComponentWrapper>

          <PermissionCheckComponentWrapper permissions={["privileges_group_delete"]}>
            <Button
              modifierClass="btn--fluid btn--danger ml--10"
              onClick={() => setPriviligeGroupDeleteID(data.getValue())}
            >
              Delete
            </Button>
          </PermissionCheckComponentWrapper>
        </div>
      ),
    }),
  ];

  /*====================================
    PRIVILEGES GROUPS DATA
  =====================================*/
  const { data, isLoading, isRefetching } = usePrivilegesGroupsGetAll();

  const PRIVILEGES_GROUPS_TABLE_DATA = useMemo(() => {
    if (!data || !Object.entries(data).length || isLoading) return [];

    return data;
  }, [data]);

  /*====================================
    PRIVILEGES GROUPS DELETE
  =====================================*/
  const [privilegeGroupDeleteID, setPriviligeGroupDeleteID] = useState<number | null>(null);
  const privilegeGroupDelete = usePrivilegesGroupDelete();

  const handlePrivilegeGroupDelete = async () => {
    try {
      await privilegeGroupDelete.mutateAsync(privilegeGroupDeleteID);
      setPriviligeGroupDeleteID(null);
    } catch (error) {
      errorReporting("Failed deleting privileges group", error, {
        group_id: privilegeGroupDeleteID,
      });
    }
  };

  return (
    <div className="container py--25">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="ml--30 mb--20">
          <strong>Groups</strong>
        </h2>

        <PermissionCheckComponentWrapper permissions={["privileges_group_create"]}>
          <Link to="/privileges/groups/new/" className="btn btn--fluid btn--primary">
            Add Group
          </Link>
        </PermissionCheckComponentWrapper>
      </div>

      <div className="tabs">
        <Link to="/privileges/" className="tabs__item">
          <span className="tabs__text">User Privileges</span>
        </Link>

        <Link to="/privileges/groups/" className="tabs__item tabs__item--active">
          <span className="tabs__text">Groups</span>
        </Link>
      </div>

      <Card modifierClass="card--padding--sm">
        {isLoading ? (
          <TableSkeletonPlaceholder modifierClass="table-wrapper--no-shadow mb--0i px--0i" />
        ) : (
          <Table
            data={PRIVILEGES_GROUPS_TABLE_DATA}
            columns={PRIVILEGE_GROUPS_COLUMNS}
            isRefetching={isRefetching}
            modifierClass="table-wrapper--no-shadow mb--0i px--0i"
            paginationPageSize={10}
          />
        )}
      </Card>

      <AnimatePresence>
        {privilegeGroupDeleteID ? (
          <Modal
            title="Delete Privileges Group?"
            text="This action will delete the selected privileges group. Are you sure you want to continue?"
            modifierClass="modal--md modal--fixated"
            handleCloseModal={() => setPriviligeGroupDeleteID(null)}
          >
            <div className="modal__actions justify-content-center">
              <Button
                modifierClass="btn--fluid btn--primary"
                onClick={() => setPriviligeGroupDeleteID(null)}
              >
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--fluid--md btn--danger"
                onClick={handlePrivilegeGroupDelete}
                isDisabled={privilegeGroupDelete.isLoading}
                isLoading={privilegeGroupDelete.isLoading}
              >
                Delete
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default PrivilegesGroup;
