// Interfaces
import { ApplicationRouteProps } from "./interfaces";

// Pages & Layout
import AdminClients from "../pages/Admin/Clients/AdminClients";
import AdminClientsNew from "../pages/Admin/Clients/AdminClientsNew";
import AdminClientsEdit from "../pages/Admin/Clients/AdminClientsEdit";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";

export const ROUTES_CLIENTS: ApplicationRouteProps[] = [
  {
    path: "/admin/clients",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdminClients />
      </LayoutAuthenticated>
    ),
    permissions: ["client_admin_view"],
    type: "protected",
  },
  {
    path: "/admin/clients/new",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdminClientsNew />
      </LayoutAuthenticated>
    ),
    permissions: ["client_admin_create"],
    type: "protected",
  },
  {
    path: "/admin/clients/:slug/edit",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdminClientsEdit />
      </LayoutAuthenticated>
    ),
    permissions: ["client_admin_edit"],
    type: "protected",
  },
];
