// Utilities & Hooks
import { useMemo, useState } from "react";
import { useUsersGetList } from "../../../api/Users/Users";
import { useApplicationShareByEmail } from "../../../api/Applications/Applications";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import { AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import Button from "../../Button/Button";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormTextarea from "../../Form/FormTextarea";
import Modal from "../../Modal/Modal";
import ApplicationModalSuccessfulRequest from "./ApplicationModalSuccessfulRequest";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { ApplicationEmailModalProps } from "../interfaces";

const ApplicationEmailModal = ({
  application_id,
  applicant_name,
  handleCloseModal,
}: ApplicationEmailModalProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    LIST OF USERS
  ========================*/
  const { data: usersData, isLoading: usersDataIsLoading } = useUsersGetList();

  const USERS: DropdownItem[] = useMemo(() => {
    if (!usersData || !usersData.length || usersDataIsLoading) return [];

    // Map the list of company users with active status into dropdown items
    const mappedUsers: DropdownItem[] = usersData
      .filter(user => user.status === "active")
      .map(user => {
        return {
          text: handleFullnameCombination(user),
          value: user.id,
        };
      });

    return mappedUsers;
  }, [usersData]);

  /*=========================
    SHARE THE APPLICATION
    BY SENDING EMAIL
  ========================*/
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const shareApplication = useApplicationShareByEmail();

  const handleShareEmailApplication = async (values: FormikValues) => {
    // Prevent sending request to the API if there's no valid recipient selected from the dropdown menu
    if (!values.recipient_id) {
      toast.info("Please select a recipient from the dropdown menu.");
      return;
    }

    try {
      await shareApplication.mutateAsync({
        applicationID: values.id,
        message: values.message,
        recipient_id: values.recipient_id,
      });

      // Display the "Success" modal after the request has completed
      setShowSuccessModal(true);
    } catch (error) {
      errorReporting("Failed sharing application by email", error, values);
    }
  };

  return (
    <>
      {!showSuccessModal ? (
        <Modal
          title=""
          text=""
          handleCloseModal={handleCloseModal}
          modifierClass="modal--lg modal--fixated"
          testID="modal-share-email"
        >
          <Formik
            initialValues={{ id: application_id, recipient_id: null, message: "" }}
            enableReinitialize
            onSubmit={handleShareEmailApplication}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <h2 className="mb--50">
                  <strong>Send Application</strong>{" "}
                </h2>

                <h6 className="txt--black txt--left mb--10">
                  <strong>Applicant:</strong> {applicant_name}
                </h6>

                <div className="d-flex align-items-center mb--20">
                  <h6 className="txt--black txt--left mr--10">
                    <strong>To:</strong>
                  </h6>

                  <Field
                    component={FormDropdownSearchable}
                    id="recipient_id"
                    name="recipient_id"
                    items={USERS}
                    placeholder="Select User"
                    isLoading={usersDataIsLoading}
                    disabled={usersDataIsLoading || USERS.length === 0}
                    size="lg"
                    handleFieldUpdate={(meetingWith: DropdownItem) => {
                      setFieldValue("recipient_id", meetingWith.value);
                    }}
                    modifierClass="min--w--unset"
                  />
                </div>

                <Field
                  component={FormTextarea}
                  id="message"
                  name="message"
                  placeholder="Enter your message here"
                  rows={7}
                  modifierClass="mb--20"
                />

                <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
                  <Button
                    type="button"
                    modifierClass="btn--fluid btn--primary--light"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                  <Button
                    modifierClass="btn--fluid btn--primary ml--10 ml--md--0 mb--md--10"
                    isLoading={shareApplication.isLoading}
                    isDisabled={!values.recipient_id || shareApplication.isLoading}
                  >
                    Send Application
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}

      <AnimatePresence>
        {showSuccessModal ? (
          <ApplicationModalSuccessfulRequest
            title="Send Application"
            text="An email has been sent containing a link for selected application."
            handleCloseModal={handleCloseModal}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default ApplicationEmailModal;
