// Pages & Layout
import SMSLogDetails from "../pages/Logs/SMSLogDetails";
import EmailLogDetails from "../pages/Logs/EmailLogDetails";
import Logs from "../pages/Logs/Logs";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_LOGS: ApplicationRouteProps[] = [
  {
    path: "/notification-logs",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <Logs />
      </LayoutAuthenticated>
    ),
    permissions: ["messages_view"],
    type: "protected",
  },
  {
    path: "/notification-logs/emails/details/:id",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <EmailLogDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["messages_view"],
    type: "protected",
  },
  {
    path: "/notification-logs/sms/details/:id",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <SMSLogDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["messages_view"],
    type: "protected",
  },
];
