// Assets
import WarningIcon from "../../../assets/images/icons/assessment-warning.svg?react";

// Interfaces
import { AssessmentWarningProps } from "./interfaces";
import { AssessmentDetailsAreasOfConcernFields } from "../../../api/Assessment/interfaces";

const AssessmentWarning = ({ data }: AssessmentWarningProps) => {
  return (
    <div className="box box--padding bg--gray">
      {data && data.length > 0 ? (
        <>
          {data.map((item: AssessmentDetailsAreasOfConcernFields, index: number) => (
            <div
              key={`assessment-warning-${index}`}
              className={`assessment-warning ${data.length - 1 !== index ? "mb--10" : ""}`}
            >
              <WarningIcon />
              <p className="assessment-txt assessment-txt--md">
                <strong>{item.label}:</strong>{" "}
                <span className="assessment-warning__range">{item.range}</span> {item.description}
              </p>
            </div>
          ))}
        </>
      ) : (
        <p className="m--0">
          <strong>None</strong>
        </p>
      )}
    </div>
  );
};
export default AssessmentWarning;
