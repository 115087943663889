// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  useAppointmentsGet,
  useAppointmentsGetAvailableMonths,
} from "../../api/Appointments/Appointments";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";
import { useBucketsGetAll } from "../../api/Buckets/Buckets";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import handleDataPagination from "../../utilities/data/handleDataPagination";

// Components
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import AppointmentsCard from "../../components/Appointments/AppointmentsCard";
import Pagination from "../../components/Pagination/Pagination";
import AppointmentsCardSkeleton from "../../components/Appointments/skeletons/AppointmentsCardSkeleton";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";
import { AppointmentsListResponseFields } from "../../api/Appointments/interfaces";

const Appointments = () => {
  /*=========================
    GET ALL BUCKETS

    Fetch the list of all existing buckets on page
    load to allow working with them providing option for optimistic updates
  ==========================*/
  const { isLoading: bucketsLoading } = useBucketsGetAll();

  /*===============================
    SELECT MONTH FOR WHICH WE 
    WANT TO SEE THE APPOINTMENTS
  ================================*/
  const [searchParameterObject, setSearchParameterObject] = useExtractSearchParameters();
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  // Pre-select the month/year combination based on current date
  // on initial page load
  useEffect(() => {
    const month = searchParameterObject.month || format(new Date(), "MMMM").toLowerCase();
    const year = searchParameterObject.year || format(new Date(), "yyyy").toLowerCase();

    // We first must capitalize the month string that is being used as query string
    const capitalizedMonthString: string = handleStringCapitalization(month, [" "]);

    setSelectedMonth(capitalizedMonthString);
    setSelectedYear(year);
  }, []);

  const handleAppointmentsMonthSelection = (selection: DropdownItem) => {
    const selectionValue: string = selection.value as string;

    // Extract only the `month` and `year` values from the selection
    const valueForMonth: string = selectionValue.split(" ")[0];
    const valueForYear: string = selectionValue.split(" ")[1];

    setSelectedMonth(valueForMonth);
    setSelectedYear(valueForYear);

    // Update the search parameters
    setSearchParameterObject({
      ...searchParameterObject,
      month: valueForMonth,
      year: valueForYear,
    });
  };

  /*===============================
    APPOINTMENTS MONTHS & YEARS
  ================================*/
  const { data: appointmentsMonths, isLoading: appointmentsMonthsLoading } =
    useAppointmentsGetAvailableMonths();

  const APPOINTMENTS_MONTHS: DropdownItem[] = useMemo(() => {
    // Exit function if there's no available data
    if (!appointmentsMonths || !appointmentsMonths.length) return [];

    const mappedAppointmentsMonths: DropdownItem[] = appointmentsMonths.map(
      (appointment: string) => {
        return { text: appointment, value: appointment };
      },
    );

    return mappedAppointmentsMonths;
  }, [appointmentsMonths]);

  /*============================
    PAGINATION
  ==============================*/
  const COUNT_PER_PAGE: number = 12;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);

  /*==================================
    GET THE LIST OF APPOINTMENTS
  ===================================*/
  const { data: appointments, isLoading: appointmentsLoading } = useAppointmentsGet(
    selectedMonth,
    selectedYear,
  );

  const APPOINTMENTS: AppointmentsListResponseFields[] = useMemo(() => {
    // Exit function and return default value if there's no data available (yet)
    if (!appointments || !appointments.length || appointmentsLoading) return [];

    const listOfAppointments: AppointmentsListResponseFields[] = [...appointments];

    // Paginate the results before returning the data to be rendered in the UI
    const TOTAL_PAGES: number = Math.ceil(listOfAppointments.length / COUNT_PER_PAGE);
    setTotalPagesCount(TOTAL_PAGES);

    const PAGINATED_APPOINTMENTS: AppointmentsListResponseFields[] =
      handleDataPagination<AppointmentsListResponseFields>(
        listOfAppointments,
        currentPage,
        COUNT_PER_PAGE,
      );

    return PAGINATED_APPOINTMENTS;
  }, [appointments, currentPage]);

  return (
    <>
      <div className="appointments__month-selection">
        <div className="appointments__month-selection__content">
          <DropdownSearchable
            placeholder="Select a Month"
            items={APPOINTMENTS_MONTHS}
            isLoading={appointmentsMonthsLoading}
            disabled={!APPOINTMENTS_MONTHS.length || appointmentsMonthsLoading}
            maxScrollableHeight="300px"
            preselectedItemValue={`${selectedMonth} ${selectedYear}`}
            handleItemSelected={handleAppointmentsMonthSelection}
          />

          <p className="txt--capitalize">
            Currently Viewing: {selectedMonth} {selectedYear}
          </p>
        </div>
      </div>

      <div className="container py--25">
        <div className="row gutters-xxs">
          {appointmentsLoading || bucketsLoading ? (
            <>
              {[...Array(4).keys()].map((_, index) => (
                <AppointmentsCardSkeleton key={index} index={index} />
              ))}
            </>
          ) : APPOINTMENTS.length > 0 ? (
            APPOINTMENTS.map((appointment: AppointmentsListResponseFields, index: number) => (
              <AppointmentsCard key={index} appointment={appointment} />
            ))
          ) : (
            <h1 className="w--100 txt--center">
              No Appointments available for the selected month.
            </h1>
          )}
        </div>

        {totalPagesCount > 1 && (
          <div className="d-flex justify-content-end my--20">
            <Pagination
              pageCount={totalPagesCount}
              currentPage={currentPage}
              handlePageChange={({ selected }) => setCurrentPage(selected)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Appointments;
