import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

export const APPLICATIONS_COMMENT = Yup.object().shape({
  content: Yup.string()
    .nullable()
    .test("applications-comment-content", "Comment or Rating is required!", (value, context) => {
      const { rating } = context.parent;
      if (!value && !rating) return false;
      return true;
    })
    .max(1000, "Maximum of 1000 characters allowed!")
    .test("application-comment-content", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  rating: Yup.number()
    .nullable()
    .test("application-comment-rating", "Comment or Rating is required!", (value, context) => {
      const { content } = context.parent;
      if (!value && !content) return false;
      return true;
    }),
});

export const APPLICATIONS_RESUME_REQUEST = Yup.object().shape({
  message: Yup.string()
    .required("Please include a message for the applicant.")
    .test("application-resume-request", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});

export const APPLICATIONS_SBCA_MODAL = Yup.object().shape({
  message: Yup.string()
    .required("Please include a message for the applicant.")
    .test("application-sbca-request", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});

export const APPLICATIONS_SCHEDULE_MEETING_VIDEO_CONFERENCE = Yup.object().shape({
  interviewer_id: Yup.number().nullable().required("Please select an interviewer"),
  meeting_name: Yup.string()
    .required("Please enter the name of the meeting event")
    .max(50, "Maximum of 50 characters allowed!")
    .test("application-meeting-name", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  meeting_duration: Yup.number().nullable().required("Please select the duration of the meeting"),
  appointment_time: Yup.date()
    .min(new Date(), "Selected Date/Time cannot be in the past.")
    .required("Please select the date and time for the meeting"),
  timezone: Yup.string().required("Please select the timezone for the meeting"),
});

export const APPLICATIONS_SCHEDULE_MEETING_IN_PERSON = Yup.object().shape({
  interviewer_id: Yup.number().nullable().required("Please select an interviewer"),
  appointment_time: Yup.date()
    .min(new Date(), "Selected Date/Time cannot be in the past.")
    .required("Please select the date and time for the meeting"),
  notes: Yup.string()
    .nullable()
    .notRequired()
    .max(255, "Maximum of 255 characters allowed!")
    .test("application-meeting-notes", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});
