// Hooks & Utilities
import { Link, useParams } from "react-router-dom";
import { useJobAdsGetAdDetails } from "../../api/JobAds/JobAds";
import { useState } from "react";
import { useBackNavigation } from "../../hooks/useBackNavigation";

// Components
import JobAdStatusUpdateActions from "../../components/JobAds/Details/JobAdStatusUpdateActions";
import JobAdHeader from "../../components/JobAds/JobAdHeader";
import JobAdDetailsSkeleton from "./Skeleton/JobAdDetailsSkeleton";
import JobAdInformation from "../../components/JobAds/Details/JobAdInformation";
import Card from "../../components/Card/Card";
import Tabs from "../../components/Tabs/Tabs";
import JobAdComments from "../../components/JobAds/Details/JobAdComments";
import JobAdLivePreview from "../../components/JobAds/Details/JobAdLivePreview";
import JobAdLinks from "../../components/JobAds/Details/JobAdLinks";
import JobAdHistory from "../../components/JobAds/Details/JobAdHistory";
import JobAdSettings from "../../components/JobAds/Details/JobAdSettings";
import JobBoardActions from "../../components/JobAds/Details/JobBoardActions";
import JobBoardPostingHistory from "../../components/JobAds/Details/JobBoardPostingHistory";
import JobBoards from "../../components/JobAds/Details/JobBoards";
import Alert from "../../components/Alert/Alert";
import JobAdCraigslistEmbed from "../../components/JobAds/Details/JobAdCraigslistEmbed";
import Button from "../../components/Button/Button";

// Types & Interfaces
type CommentsSectionTabs = "comments" | "live-ad-preview" | "craigslist-embed";
type DetailsSectionTabs = "ad-info" | "ad-history" | "ad-poster";

const JobAdDetails = () => {
  const { id } = useParams();

  /*=========================
    FETCH JOB AD DETAILS
  ===========================*/
  const { data, isLoading, isRefetching, error } = useJobAdsGetAdDetails(id);

  /*=========================
    JOB AD DETAILS TABS
  ===========================*/
  const [detailsActiveTab, setDetailsActiveTab] = useState<DetailsSectionTabs>("ad-info");

  /*=========================
    JOB AD COMMENTS TABS
  ===========================*/
  const [commentsActiveTab, setCommentsActiveTab] = useState<CommentsSectionTabs>("comments");

  const handleNavigateBack = useBackNavigation();

  return (
    <div className="job-ads-details">
      <JobAdHeader />

      {isLoading || isRefetching ? (
        <JobAdDetailsSkeleton />
      ) : !data || error ? (
        <div className="d-flex flex-column align-items-center justify-content-center wrapper--height">
          <h2 className="txt--center fw--semibold mb--30">
            No data available for the targeted job ad.
          </h2>

          <div>
            <Button
              modifierClass="btn--fluid btn--primary--light"
              onClick={() => handleNavigateBack("/job-ads/overview/")}
            >
              Go Back
            </Button>

            <Button
              modifierClass="btn--fluid btn--primary ml--10"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="job-ads-details__header">
            <div className="container">
              <h3 className="fw--semibold">
                {data?.title || "N/A"} at{" "}
                <Link to={`/job-ads/clients/${data?.company.slug}/`}>{data?.company.name}</Link>
              </h3>

              <h3 className="txt--capitalize fw--medium">
                Status: {data?.status.split("_").join(" ")}
              </h3>
            </div>
          </div>

          <div className="container py--25">
            {data.company.is_active === false ? (
              <Alert
                text={
                  <>
                    <strong>Warning!</strong> This ad belongs to account that is not active!
                  </>
                }
                type="error"
                modifierClass="alert--txt-black"
              />
            ) : null}

            {data.tracking.status && data.tracking.tracker ? (
              <Alert
                text={
                  <>
                    <strong>Warning!</strong> {data.tracking.tracker} is currently editing this ad!
                  </>
                }
                type="error"
                modifierClass="alert--txt-black"
              />
            ) : null}

            <div className="d-flex justify-content-between align-items-center mb--10">
              <div className="job-ads-details__codes">
                <p>Job Code: {data?.job_code || "N/A"}</p>
                <p>
                  Salesforce Order:{" "}
                  {data?.salesforce.link ? (
                    <a href={data?.salesforce.link} target="_blank">
                      {data?.salesforce.number || "N/A"}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>

              {/* JOB AD STATUS UPDATE ACTIONS */}
              <JobAdStatusUpdateActions
                id={id}
                status={data.status}
                tracking={data.tracking}
                company_is_active={data.company.is_active}
              />
            </div>

            {/* JOB AD INFO & AD SETTINGS  */}
            <div className="row">
              <div className="col-12">
                <div>
                  <Tabs
                    tabs={[
                      { id: "ad-info", text: "Ad Info" },
                      { id: "ad-history", text: "Ad History" },
                      { id: "ad-poster", text: "Ad Poster" },
                    ]}
                    activeTabId={detailsActiveTab}
                    handleTabSelection={tab => setDetailsActiveTab(tab.id as DetailsSectionTabs)}
                    searchParamKey="tabsInfo"
                  />
                </div>
              </div>

              {/* AD INFO TAB */}
              {detailsActiveTab === "ad-info" ? (
                <>
                  <JobAdLinks
                    form_identifier={data.form_identifier}
                    live_ad_preview_identifier={data.live_ad_preview_identifier}
                  />

                  <JobAdSettings id={id} status={data.status} settings={data.ad_settings} />
                </>
              ) : null}

              {/* AD HISTORY TAB */}
              {detailsActiveTab === "ad-history" ? (
                <>
                  <JobAdHistory histories={data.histories} />
                  <div className="col-12 col-lg-6 mb--20">
                    <Card modifierClass="card--padding--xl">
                      <JobAdLivePreview
                        id={id}
                        status={data.status}
                        live_ad_preview={data.live_ad_preview}
                        live_ad_preview_identifier={data.live_ad_preview_identifier}
                        tracking={data.tracking}
                        company_is_active={data.company.is_active}
                      />
                    </Card>
                  </div>
                </>
              ) : null}

              {/* AD POSTER TAB */}
              {detailsActiveTab === "ad-poster" ? (
                <>
                  <JobBoards
                    state={data.job_information.address.state}
                    job_boards={data.job_boards}
                    job_boards_data={data.job_board_data}
                    job_board_data_career_builder={data.job_board_data_career_builder}
                    screening_questions={data.screening_questions}
                  />

                  <div className="col-12 col-lg-6 mb--20">
                    <JobBoardActions job_board_actions_pending={data.job_board_actions_pending} />
                    <JobBoardPostingHistory
                      job_ad_status={data.status}
                      job_board_posting_history={data.job_board_posting_history}
                    />
                  </div>
                </>
              ) : null}
            </div>

            {/* JOB AD INFORMATION AND COMMENTS */}
            {detailsActiveTab === "ad-info" ? (
              <div className="row">
                {/* JOB INFORMATION */}
                <div className="col-12 col-lg-6 mb--20">
                  <JobAdInformation details={data.job_information} />
                </div>

                {/* JOB AD COMMENTS */}
                <div className="col-12 col-lg-6 mb--20">
                  <div>
                    <Tabs
                      tabs={[
                        { id: "comments", text: "Comments" },
                        { id: "live-ad-preview", text: "Live Ad Preview" },
                        { id: "craigslist-embed", text: "Craigslist Embed" },
                      ]}
                      activeTabId={commentsActiveTab}
                      handleTabSelection={tab =>
                        setCommentsActiveTab(tab.id as CommentsSectionTabs)
                      }
                      searchParamKey="tabsPreview"
                    />
                  </div>

                  <Card modifierClass="card--padding--xl">
                    {commentsActiveTab === "comments" ? (
                      <JobAdComments id={id} comments={data.comments} />
                    ) : commentsActiveTab === "craigslist-embed" ? (
                      <JobAdCraigslistEmbed live_ad_preview={data.live_ad_preview} />
                    ) : (
                      <>
                        <JobAdLivePreview
                          id={id}
                          status={data.status}
                          live_ad_preview={data.live_ad_preview}
                          live_ad_preview_identifier={data.live_ad_preview_identifier}
                          tracking={data.tracking}
                          company_is_active={data.company.is_active}
                        />
                      </>
                    )}
                  </Card>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default JobAdDetails;
