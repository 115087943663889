// Components
import AssessmentProgressBox from "../AssessmentProgressBox";

// Interfaces
import { AssessmentProgressBarDrivenCardProps } from "./interfaces";

const AssessmentProgressBarDrivenCard = ({ data }: AssessmentProgressBarDrivenCardProps) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="sbca-data__row">
          <span className="sbca-data__title">Driven:</span>
          <span className="sbca-data__content">
            <strong>{data.score}</strong>
          </span>
        </div>
        <div className="sbca-data__row">
          <span className="sbca-data__title">Marginal:</span>
          <span className="sbca-data__content">3.00-5.00</span>
        </div>
        <p className="assessment-txt mt--20">
          Striving to achieve personal goals because of a strong need or inner compulsion.
        </p>
      </div>
      <div className="col-md-6">
        <AssessmentProgressBox data={data.progress_bar} modifierClass={"bg--white"} />
      </div>
    </div>
  );
};
export default AssessmentProgressBarDrivenCard;
