import { ApplicationRouteProps } from "./interfaces";

// Routes
import ForbiddenAccess from "../pages/ErrorPages/ForbiddenAccess";
import NotFound from "../pages/ErrorPages/NotFound";

export const ROUTES_ERROR_PAGES: ApplicationRouteProps[] = [
  {
    path: "/403",
    element: <ForbiddenAccess />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/404",
    element: <NotFound />,
    permissions: ["*"],
    type: "public",
  },
];
