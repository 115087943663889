import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const ApplicationDetailsSkeleton = () => {
  return (
    <div className="" data-testid="component:skeleton-loader">
      <div className="row mb--20">
        <div className="col-12 col-md-8">
          <div className="d-flex justify-content-between align-items-center">
            <Skeleton width={150} height={20} />
            <Skeleton width={150} height={40} />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="applications-details__header__icons">
            <Skeleton width={20} height={20} className="mr--5" />
            <Skeleton width={20} height={20} className="mr--5" />
            <Skeleton width={20} height={20} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8">
          <Card modifierClass="card--rounded">
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Skeleton width={30} height={20} className="mb--5" />
                  <Skeleton width={125} height={125} circle />
                </div>
              </div>

              <div className="col-12 col-md-9">
                <div className="d-flex justify-content-between align-items-start mb--20">
                  <div>
                    <Skeleton width={150} height={20} />
                    <Skeleton width={200} height={15} />
                  </div>

                  <div className="d-flex align-items-center">
                    <Skeleton width={23} height={23} circle className="mr--15" />
                    <Skeleton width={23} height={23} circle />
                  </div>
                </div>

                <div className="row no-gutters mb--35">
                  <div className="col-6">
                    <div className="applications__contact__location">
                      <Skeleton width={150} height={15} />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="d-flex flex-column justify-content-center">
                      <Skeleton width={150} height={15} />
                      <Skeleton width={150} height={15} />
                    </div>
                  </div>
                </div>

                <Skeleton width="100%" height="1px" />

                <div className="d-flex align-items-center">
                  <Skeleton width={30} height={30} className="mr--10" />
                  <Skeleton width={30} height={30} className="mr--10" />
                  <Skeleton width={30} height={30} className="mr--10" />
                  <Skeleton width={30} height={30} className="mr--10" />
                  <Skeleton width={30} height={30} />
                </div>
              </div>
            </div>
          </Card>

          {/* ACCORDION (APPLICANT SUMMARY) */}
          <Card>
            <div className="d-flex justify-content-between">
              <Skeleton width={220} height={20} />
              <Skeleton width={20} height={20} />
            </div>
          </Card>

          {/* SBCA Results */}
          <Card>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb--25">
              <Skeleton width={120} height={30} />
              <Skeleton width={150} height={30} />
            </div>

            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="d-flex align-items-start mb--20 w--100">
                <Skeleton width={84} height={84} circle className="mr--10" />

                <div className="w--100">
                  <Skeleton width="100%" style={{ maxWidth: "100px" }} />
                  <Skeleton width={60} height={10} />

                  <Skeleton width={80} height={25} className="my--10" />

                  <Skeleton width="100%" style={{ maxWidth: "100px" }} height={10} />
                  <Skeleton width="100%" style={{ maxWidth: "350px" }} height={10} />
                </div>
              </div>

              <div className="d-flex align-items-start mb--20 w--100">
                <Skeleton width={84} height={84} circle className="mr--10" />

                <div className="w--100">
                  <Skeleton width="100%" style={{ maxWidth: "100px" }} />
                  <Skeleton width={60} height={10} />

                  <Skeleton width={80} height={25} className="my--10" />

                  <Skeleton width="100%" style={{ maxWidth: "100px" }} height={10} />
                  <Skeleton width="100%" style={{ maxWidth: "350px" }} height={10} />
                </div>
              </div>

              <div className="d-flex align-items-start w--100">
                <Skeleton width={84} height={84} circle className="mr--10" />

                <div className="w--100">
                  <Skeleton width="100%" style={{ maxWidth: "100px" }} height={15} />
                  <Skeleton width={60} height={10} />

                  <Skeleton width={80} height={25} className="my--10" />

                  <Skeleton width="100%" style={{ maxWidth: "100px" }} height={10} />
                  <Skeleton width="100%" style={{ maxWidth: "350px" }} height={10} />
                </div>
              </div>
            </div>
          </Card>

          {/* ACCORDION (INTERVIEW QUESTIONS) */}
          <Card>
            <div className="d-flex justify-content-between">
              <Skeleton width={220} height={20} />
              <Skeleton width={20} height={20} />
            </div>
          </Card>

          {/* EMPLOYMENT HISTORY */}
          <Card>
            <Skeleton width={250} height={25} className="mb--25" />

            <Skeleton width="100%" style={{ maxWidth: "350px" }} height={10} />
            <Skeleton width="100%" height={1} className="my--20" />
            <Skeleton width="100%" style={{ maxWidth: "350px" }} height={10} />
          </Card>
        </div>

        <div className="col-12 col-lg-4">
          {/* SCHEDULE APPOINTMENTS */}
          <Card>
            <Skeleton width="100%" style={{ maxWidth: "325px" }} height={20} className="mb--20" />

            <div className="d-flex justify-content-center">
              <Skeleton width={240} height={35} className="btn--pill" />
            </div>
          </Card>

          {/* APPOINTMENTS */}
          <Card>
            <Skeleton width="100%" style={{ maxWidth: "280px" }} height={20} className="mb--20" />

            <div>
              <Skeleton width={200} height={15} />
              <Skeleton width={100} height={10} className="mb--20" />

              <div className="d-flex justify-content-start justify-content-lg-center align-items-center">
                <Skeleton width={120} height={25} className="btn--pill mb--20 mr--10" />
                <Skeleton width={120} height={25} className="btn--pill mb--20" />
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Skeleton width={21} height={21} circle className="mr--10" />
                <Skeleton width={21} height={21} circle className="mr--10" />
                <Skeleton width={21} height={21} circle />
              </div>
            </div>
          </Card>

          {/* COMMENTS */}
          <Card>
            <Skeleton width="100%" style={{ maxWidth: "250px" }} height={20} className="mb--20" />

            <div className="d-flex justify-content-between align-items-center mb--20">
              <div>
                <Skeleton width={160} height={20} className="mb--5" />
                <Skeleton width={150} height={15} />
              </div>

              <div className="d-flex align-items-center">
                <Skeleton width={21} height={21} circle className="mr--10" />
                <Skeleton width={21} height={21} circle />
              </div>
            </div>

            <Skeleton width={220} height={12} />
            <Skeleton width={180} height={12} />
            <Skeleton width={200} height={12} />
            <Skeleton width={160} height={12} />

            <Skeleton width="100%" height={1} className="my--20" />

            <div className="d-flex justify-content-end">
              <Skeleton width={140} height={40} className="btn--pill" />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetailsSkeleton;
