// Components
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip";

// Interfaces
import { ApplicationAppointmentsAndCommentsProps } from "./interfaces";

// Assets
import AppointmentsIcon from "../../assets/images/icons/applications-calendar-icon.svg?react";
import CommentsIcon from "../../assets/images/icons/applications-comment-icon.svg?react";

const ApplicationAppointmentsAndComments = ({
  applicationID,
  appointments_count,
  comments_count,
}: ApplicationAppointmentsAndCommentsProps) => {
  return (
    <div className="applications__appointments_and_comments">
      {appointments_count ? (
        <Tooltip
          size="md"
          text={`${appointments_count} ${
            appointments_count === 1 ? "Appointment" : "Appointments"
          }`}
        >
          <Link to={`/applications/${applicationID}/`}>
            <div className="applications__appointments_and_comments__wrapper mr--5">
              <AppointmentsIcon className="applications__appointments_and_comments__icon" />
              {appointments_count}
            </div>
          </Link>
        </Tooltip>
      ) : null}

      {comments_count ? (
        <Tooltip
          size="md"
          text={`${comments_count} ${comments_count === 1 ? "Comment" : "Comments"}`}
        >
          <Link to={`/applications/${applicationID}/`}>
            <div className="applications__appointments_and_comments__wrapper">
              <CommentsIcon className="applications__appointments_and_comments__icon" />
              {comments_count}
            </div>
          </Link>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ApplicationAppointmentsAndComments;
