// Assets
import VideoMeetingIcon from "../../assets/images/icons/applications-camera-icon.svg?react";
import InPersonMeetingIcon from "../../assets/images/icons/appointment-in-person-icon.svg?react";

// Hooks & Utilities
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleAppointmentDisplayedTime } from "./utils/handleAppointmentDisplayedTime";

// Components
import Button from "../Button/Button";
import AppointmentsActions from "./AppointmentsActions";

// Interfaces
import { AppointmentsListItemProps } from "./interfaces";

const AppointmentsListItem = ({ details }: AppointmentsListItemProps) => {
  const [accessLink, setAccessLink] = useState<string>("");

  useEffect(() => {
    const { host, protocol } = window.location;
    const invitationLink: string = `${protocol}//${host}/vidconf/join/${details.video_link}`;

    setAccessLink(invitationLink);
  }, [details.video_link]);

  /*================================
    MEETING (COPY LINK, START)
  ================================*/
  const handleCopyInvitationLink = async (link: string | null) => {
    if (!link) return;

    const { host, protocol } = window.location;

    const meetingLink: string = `${protocol}//${host}/vidconf/join/${link}`;
    try {
      await navigator.clipboard.writeText(meetingLink);
      toast.info("Invite link copied!", { toastId: "invite-link" });
    } catch (error) {
      toast.error("Failed copying invatation link!");
    }
  };

  return (
    <div className="appointments__item appointments__item--list appointments__item--video">
      {details.type === "video" ? (
        <VideoMeetingIcon className="appointments__type-icon" />
      ) : (
        <InPersonMeetingIcon className="appointments__type-icon" />
      )}

      <div className="appointments__item__details">
        <p className="appointments__item__date">
          {handleAppointmentDisplayedTime(
            details.type === "video" ? details.appointment_timestamp : details.appointment_time,
            details.type,
          )}
          {details.type === "video" && (
            <span className="appointments__item__date--local">Local Time</span>
          )}
        </p>
        <p className="mb--10">
          <i>with {details.interviewer}</i>
        </p>

        {details.type === "video" && (
          <div className="appointments__item__details__buttons">
            <Button
              modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary"
              onClick={() => handleCopyInvitationLink(details.video_link)}
            >
              Copy Invite Link
            </Button>

            <a
              href={accessLink}
              target="_blank"
              className="d-inline-block btn btn--fluid btn--fluid--md btn--pill btn--primary btn--border btn--border--primary ml--10"
            >
              Start Meeting
            </a>
          </div>
        )}

        {details.notes ? (
          <p className="appointments__item__details__notes">{details.notes}</p>
        ) : null}

        <AppointmentsActions details={details} />
      </div>
    </div>
  );
};

export default AppointmentsListItem;
