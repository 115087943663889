import Skeleton from "react-loading-skeleton";
import Card from "../Card/Card";

const TableSkeletonPlaceholder = ({ modifierClass = "" }: { modifierClass?: string }) => {
  return (
    <Card modifierClass={modifierClass}>
      <table data-testid="component:skeleton-table-placeholder">
        <thead>
          <tr>
            {[...Array(4).keys()].map((index: number) => {
              return (
                <th scope="col" key={`table-header-${index}`}>
                  <span className="d-flex justify-content-start pr--10 pb--20">
                    <Skeleton width={100} height={20} />
                  </span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {[...Array(4).keys()].map((_, index: number) => {
            return (
              <tr key={`table-body-row-${index}`}>
                <>
                  {[...Array(4).keys()].map(index => {
                    return (
                      <td
                        key={`table-body-data-${index}`}
                        className="py--10"
                        style={{ paddingRight: 30 }}
                      >
                        <Skeleton width={250} height={20} />
                      </td>
                    );
                  })}
                </>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default TableSkeletonPlaceholder;
