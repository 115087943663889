import { Editor } from "tinymce";

/**
 * Utility function for appending 'target="_blank" to pasted links
 *
 * The function uses the editor "paste_postprocess" functionality to
 * apply custom paste rules, it acts depending whether we're trying
 * to autolink a selection OR we're just pasting one or more links.
 * The manual textContent addition is a workaround to overwrite native
 * plugin paste behaviour and use the appropriate (selection) textContent
 * on links.
 *
 * @param editor - Editor instance
 * @param fragment - Editor paste event that includes the html node
 */
const handleLinkTargetOnLinkPaste = (editor: Editor, fragment: any) => {
  // IF paste content contains link elements
  if (fragment.node.getElementsByTagName("a").length) {
    // IF editor has a selection append target attribute & re-add link textContent
    if (editor.selection.getContent()) {
      const allLinks: HTMLAnchorElement[] = Array.from(fragment.node.getElementsByTagName("a"));
      allLinks.forEach(link => {
        if (link.hasAttribute("target") === false) {
          link.setAttribute("target", "_blank");

          // Strip all html tags before applying the link text content
          link.textContent = editor.selection.getContent().replaceAll(/<\/?[^>]+(>|$)/gi, "");
        }
      });
      // IF editor doesnt have a selection and links are pasted, append target to all links
    } else if (!editor.selection.getContent()) {
      const allLinks: HTMLAnchorElement[] = Array.from(fragment.node.querySelectorAll("a"));
      allLinks.forEach(link => {
        link.setAttribute("target", "_blank");
      });
    }
  }
};

export default handleLinkTargetOnLinkPaste;
