import { useEffect, useState } from "react";
import { useAuth } from "../../providers/auth-context";
import { ProductTourIds } from "./interfaces";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";

/**
 *
 * Control if the specific product tour should be shown to the user,
 * based on if the user has it in his list of unwatched product tours,
 * or by reading the tour ID from local storage if the user started it manually.
 *
 * @param tourID The ID of the tour that we want to show to the user.
 *
 * @returns `true` or `false` based on if the tour should be shown to the user
 *
 */
export function useShouldShowTour(tourID: ProductTourIds): boolean {
  const { user } = useAuth();
  const [showTour, setShowTour] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    if (
      LocalStorageActions.getItem("activeProductTour") &&
      LocalStorageActions.getItem("activeProductTour") === tourID
    ) {
      setShowTour(true);
      return;
    }

    if (user.unwatched_product_tours.length) {
      const matchingTour = user.unwatched_product_tours.find(tour => {
        return tour.name.toLowerCase() === tourID.toLowerCase();
      });

      if (matchingTour) setShowTour(true);
    }
  }, [user]);

  return showTour;
}
