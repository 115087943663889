// Utilities & Hooks
import { Field, Form, Formik } from "formik";
import { useAuthenticationForgotPassword } from "../../api/Authentication/Authentication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import FormInput from "../../components/Form/FormInput";

// Schemas
import { AUTHENTICATION_FORGOT_PASSWORD_SCHEMA } from "../../schemas/AuthenticationSchemas";

// Interfaces
import { ForgotPasswordState } from "./interfaces";

const ForgotPassword = () => {
  const errorReporting = useErrorReporting();
  const forgotPassword = useAuthenticationForgotPassword();

  // Send a request to the API so the user can reset the password
  const handleForgotPassword = async ({ email }: ForgotPasswordState) => {
    try {
      await forgotPassword.mutateAsync(email);
    } catch (error) {
      errorReporting("Failed 'Forgot Password' action.", error, { email }, "critical");
    }
  };

  return (
    <>
      <h1 className="h4 my--30 txt--center fw--semibold">Reset Password</h1>

      <Formik
        initialValues={{ email: "" }}
        validationSchema={AUTHENTICATION_FORGOT_PASSWORD_SCHEMA}
        onSubmit={handleForgotPassword}
      >
        <Form autoComplete="new-password">
          <Field
            name="email"
            id="email"
            isRequired={true}
            placeholder="Enter your email address"
            type="email"
            component={FormInput}
            modifierClass="mb--15"
            autoComplete="new-password"
          />

          <Button
            type="submit"
            modifierClass="btn--xl btn--primary"
            isLoading={forgotPassword.isLoading}
            isDisabled={forgotPassword.isLoading}
          >
            {forgotPassword.isLoading ? "Sending Email..." : "Request Password Reset"}
          </Button>

          <div className="d-flex justify-content-center">
            <Link className="txt--md my--20 mb--20 txt--underline-on-hover" to="/login/">
              Go Back
            </Link>
          </div>
        </Form>
      </Formik>

      <div className="mt-5">
        <p className="txt--gray-darker txt--center mb--30">
          <span className="d-block fw--semibold mb--5">
            Having trouble resetting your password?
          </span>
          Call us at (877) 449-7595
        </p>

        <p className="txt--gray-darker txt--center">
          <span className="fw--semibold">Note: </span>Please contact your local IT person or
          department to verify that the domain name firstchoicehiring.com is white-listed in your
          company's email server.
        </p>
      </div>
    </>
  );
};

export default ForgotPassword;
