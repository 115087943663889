// Utilities & Hooks
import { format } from "date-fns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useAppointmentsEditInPersonAppointment } from "../../../api/Appointments/Appointments";
import { useUsersGetList } from "../../../api/Users/Users";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

// Schemas
import { APPLICATIONS_SCHEDULE_MEETING_IN_PERSON } from "../../../schemas/ApplicationsSchemas";

// Components
import Button from "../../Button/Button";
import Datepicker from "../../Datepicker/Datepicker";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormTextarea from "../../Form/FormTextarea";
import Modal from "../../Modal/Modal";

// Interfaces
import { InPersonAppointmentFormFields } from "../../Applications/ScheduleMeeting/interfaces";
import { DropdownItem } from "../../Dropdown/interfaces";
import {
  AppointmentsEditInPersonAppointmentProps,
  AppointmentsEditInPersonFormDetails,
} from "../interfaces";

const EditInPersonAppointment = ({
  details,
  handleCloseModal,
}: AppointmentsEditInPersonAppointmentProps) => {
  const errorReporting = useErrorReporting();

  const [formDetails, setFormDetails] = useState<AppointmentsEditInPersonFormDetails>({
    interviewer_id: null,
    appointment_time: new Date(),
    notes: undefined,
  });

  /*=========================
    LIST OF INTERVIEWERS
  ========================*/
  const { data: interviewersData, isLoading: interviewersDataIsLoading } = useUsersGetList();

  const INTERVIEWERS: DropdownItem[] = useMemo(() => {
    if (!interviewersData || !interviewersData.length || interviewersDataIsLoading) return [];

    // Map the list of company users (interviewers) into dropdown items
    const mappedInterviewers: DropdownItem[] = interviewersData
      .filter(user => user.status === "active")
      .map(interviewer => {
        return {
          text: handleFullnameCombination(interviewer),
          value: interviewer.id,
        };
      });

    return mappedInterviewers;
  }, [interviewersData]);

  // Update the state of the form once the list of interviewers is fetched from the API
  useEffect(() => {
    // Find the matching interviewer and extract the ID
    const matchingInterviewer = INTERVIEWERS.find(interviewer => {
      return interviewer.text.toLowerCase() === details.interviewer.toLowerCase();
    });

    setFormDetails({
      interviewer_id: (matchingInterviewer?.value as number) ?? null,
      appointment_time: new Date(details.appointment_time),
      notes: details.notes ?? "",
    });
  }, [INTERVIEWERS]);

  /*==================================
    SCHEDULE "IN-PERSON" APPOINTMENT
  ===================================*/
  const editInPersonAppointment = useAppointmentsEditInPersonAppointment();

  const handleEditInPersonAppointment = async (values: InPersonAppointmentFormFields) => {
    // Exit function if there's no valid interviewer selection
    if (values.interviewer_id === null) return;

    try {
      const { interviewer_id, appointment_time, notes } = values;

      // Convert the selected date & time value so it also shows the hours in 12h mode with AM/PM
      const CONVERTED_APPOINTMENT_TIMESTAMP: string = format(
        appointment_time,
        "MM/dd/yyyy hh:mm a",
      );

      // Send the API Request
      await editInPersonAppointment.mutateAsync({
        application_id: details.applicationID,
        appointment_id: details.id,
        type: "in-person",
        appointment_time: CONVERTED_APPOINTMENT_TIMESTAMP,
        notes: notes,
        interviewer_id,
      });

      // Close the menu after the appointment was made
      handleCloseModal();
    } catch (error) {
      errorReporting("Failed editing 'InPerson' appointment.", error, {
        application_id: details.applicationID,
        appointment_id: details.id,
      });
    }
  };

  return (
    <Modal
      title=""
      text=""
      modifierClass="modal--fixated applications-schedule-meeting applications-schedule-meeting-modal"
      handleCloseModal={handleCloseModal}
    >
      <Formik
        initialValues={formDetails}
        enableReinitialize
        validationSchema={APPLICATIONS_SCHEDULE_MEETING_IN_PERSON}
        onSubmit={handleEditInPersonAppointment}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <h2 className="txt--center mb--20">
              <strong>Edit Appointment</strong>
            </h2>

            <Field
              component={FormDropdownSearchable}
              id="interviewer_id"
              name="interviewer_id"
              label="Meeting With"
              placeholder="Select Interviewer"
              items={INTERVIEWERS}
              isLoading={interviewersDataIsLoading}
              disabled={interviewersDataIsLoading || INTERVIEWERS.length === 0}
              size="full"
              handleFieldUpdate={(meetingWith: DropdownItem) =>
                setFieldValue("interviewer_id", meetingWith.value)
              }
              preselectedItemValue={formDetails.interviewer_id}
              modifierClass="mb--20"
            />

            <div className="p--relative">
              <label className="dropdown__label">Date and Time</label>
              <Datepicker
                modifierClass="datepicker--full mb--20"
                preselectedDate={formDetails.appointment_time}
                handleSelectedDate={dates => setFieldValue("appointment_time", dates[0])}
                placeholder={
                  formDetails.appointment_time
                    ? format(formDetails.appointment_time, "MM/dd/yyyy hh:mm a")
                    : undefined
                }
              />
              {errors.appointment_time && touched.appointment_time ? (
                <span className="input__error" style={{ position: "absolute", bottom: "-22px" }}>
                  <ErrorMessage name="appointment_time" />
                </span>
              ) : null}
            </div>

            <Field
              component={FormTextarea}
              id="notes"
              name="notes"
              placeholder="Type your notes here"
              label="Appointment Notes"
              rows={6}
              modifierClass="mb--30"
            />

            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="submit"
                modifierClass="btn--fluid btn--fluid--md btn--pill btn--primary"
                isLoading={editInPersonAppointment.isLoading}
                isDisabled={
                  editInPersonAppointment.isLoading ||
                  (errors && Object.keys(errors).length ? true : false)
                }
              >
                Save New Meeting Details
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditInPersonAppointment;
