// Utilities & Hooks
import { useEffect, useRef } from "react";

// Package
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

// Interfaces
import { CustomScrollbarsProps } from "./interfaces";

const CustomScrollbars: React.FC<CustomScrollbarsProps> = ({
  children,
  maxHeight,
  autoHide = true,
  forceVisible = "y",
  autoScrollToBottom = null,
  hideAxis = null,
}) => {
  const scrollbarsRef = useRef<any>(null);
  const scrollbarsScrollToBottomElement = useRef<HTMLDivElement | null>(null);

  // Scroll down to the latest element in the list,
  // anytime the update is triggered (e.g. state has been updated)
  useEffect(() => {
    if (!autoScrollToBottom?.enabled || !scrollbarsScrollToBottomElement.current) return;
    scrollbarsScrollToBottomElement.current.scrollIntoView();
  }, [autoScrollToBottom?.updateTrigger]);

  return (
    <SimpleBar
      forceVisible={forceVisible}
      autoHide={autoHide}
      style={{
        maxHeight: maxHeight,
        overscrollBehavior: "contain",
        ...(hideAxis === "x" && { overflowX: "hidden" }),
        ...(hideAxis === "y" && { overflowY: "hidden" }),
      }}
      ref={scrollbarsRef}
    >
      {children}
      {autoScrollToBottom && <div ref={scrollbarsScrollToBottomElement}></div>}
    </SimpleBar>
  );
};

export default CustomScrollbars;
