import { AlertProps } from "./interfaces";

const Alert = ({ text, type = "success", modifierClass = "" }: AlertProps) => {
  return (
    <div
      className={`alert alert--${type} ${modifierClass}`}
      data-testid={`component:alert-${type}`}
    >
      <p className="alert__text">{text}</p>
    </div>
  );
};

export default Alert;
