// Hooks
import { useRef, useState } from "react";
import { useAuth } from "../../providers/auth-context";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Context
import { useChatsContext } from "./ChatWrapper/ChatContextWrapper";

// Components
import ChatBubbleExtrasItem from "./ChatBubbleExtrasItem";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";

// Interfaces
import { ChatBubbleExtrasProps } from "./interfaces";

const ChatBubbleExtras = ({ chats }: ChatBubbleExtrasProps) => {
  const { user } = useAuth();
  const chatsContext = useChatsContext();

  /*==========================
    MENU STATE
  ===========================*/
  const [isExtrasMenuOpen, setIsExtrasMenuOpen] = useState<boolean>(false);
  const bubblesExtrasMenuRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(bubblesExtrasMenuRef, () => setIsExtrasMenuOpen(false));

  // Do not render anything in the UI
  // until we have the neccessary data
  if (!user.id || !chatsContext.existingUserChats) return null;

  return (
    <div
      ref={bubblesExtrasMenuRef}
      className="chat-bubble-extras"
      onClick={() => setIsExtrasMenuOpen(!isExtrasMenuOpen)}
    >
      <h6 className="chat-bubble-extras__counter">+ {chats.length}</h6>

      {isExtrasMenuOpen ? (
        <ul className="chat-bubble-extras__list">
          <CustomScrollbars maxHeight="250px" autoHide={false}>
            {chats.map(chat => (
              <ChatBubbleExtrasItem
                key={chat.applicant.id}
                chatDetails={chat}
                handleCloseBubbleExtrasMenu={() => setIsExtrasMenuOpen(false)}
              />
            ))}
          </CustomScrollbars>
        </ul>
      ) : null}
    </div>
  );
};

export default ChatBubbleExtras;
