// Utilities & Hooks
import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import { Field, Form, Formik, FormikValues } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { JobPositionFields } from "../../api/JobPositions/interfaces";
import useErrorReporting from "../../hooks/useErrorReporting";
import {
  useJobPositionsCompanyBulkDelete,
  useJobPositionsCompanyCreate,
  useJobPositionsCompanyDelete,
  useJobPositionsCompanyGet,
  useJobPositionsCompanyUpdate,
} from "../../api/JobPositions/JobPositions";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import { uniq } from "lodash-es";

// Components
import Button from "../../components/Button/Button";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import FormInput from "../../components/Form/FormInput";
import Modal from "../../components/Modal/Modal";
import Table from "../../components/Table/Table";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";
import Checkbox from "../../components/Inputs/Checkbox";
import PositionsSelectionIndicator from "./components/PositionsSelectionIndicator";

// Schemas
import { JOB_POSITIONS_WRITE_SCHEMA } from "../../schemas/JobPositionSchemas";

// Interfaces & Globals
import { DropdownItem } from "../../components/Dropdown/interfaces";
import { FRAMER_SELECTION_INDICATOR } from "../../constants/framer";

const JobCompanyPositions = () => {
  const errorReporting = useErrorReporting();
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  /*===============================
    GET THE LIST OF 
    COMPANIES AND JOB POSITIONS
  ================================*/
  const { data: positionsData, isLoading: positionsLoading } = useJobPositionsCompanyGet();
  const [selectedCompanyID, setSelectedCompanyID] = useState<number | null>(null);
  const [selectedCompanySlug, setSelectedCompanySlug] = useState<string>("");

  // Pre-select the company from the dropdown menu on page load if
  // corresponding search parameter exists in the URL
  useEffect(() => {
    if (searchParametersObject.company_id) {
      setSelectedCompanyID(parseInt(searchParametersObject.company_id));
    }
  }, []);

  // Map the received companies data into dropdown items
  const COMPANIES_DROPDOWN: DropdownItem[] = useMemo(() => {
    if (!positionsData || !positionsData.length || positionsLoading) return [];

    // Map the received companies into dropdown items
    const companies: DropdownItem[] = positionsData
      .map(companies => {
        return { text: companies.company.name, value: companies.company.id };
      })
      .sort((companyA, companyB) => {
        return companyA.text.toLowerCase() > companyB.text.toLowerCase() ? 1 : -1;
      });

    return companies;
  }, [positionsData, positionsLoading]);

  const handleCompanySelection = (company: DropdownItem) => {
    setSelectedCompanyID(company.value as number);
    setSearchParametersObject({ ...searchParametersObject, company_id: company.value });
  };

  // Extract the slug value from the currently selected company
  useEffect(() => {
    if (!selectedCompanyID || !positionsData || !positionsData.length) return;

    // Find the slug of the company that has the same ID as the currently selected one
    const matchingCompany = positionsData.find(company => company.company.id === selectedCompanyID);

    // Exit function if no matching company was found
    if (!matchingCompany) return;

    // Update the details of the currently selected comopany slug
    setSelectedCompanySlug(matchingCompany.company.slug);
  }, [selectedCompanyID, positionsData]);

  /*===============================
    TABLE OF COMPANY POSITIONS
  ================================*/
  const COLUMN_HELPER = createColumnHelper<JobPositionFields>();
  const JOB_POSITIONS_COMPANY_COLUMNS = [
    ...(handlePermissionCheck(["company_job_position_delete"])
      ? [
          COLUMN_HELPER.accessor("id", {
            id: "selection_id",
            header: () => (
              <Checkbox
                id="all"
                name="all"
                value="all"
                label=""
                handleCheckboxAction={() => handlePositionsAllSelection()}
                modifierClass={`my--0i ${
                  selectedPositionsIDs.length &&
                  selectedPositionsIDs.length !== paginatedPositions.length
                    ? "input-checkbox--indeterminate"
                    : ""
                }`}
                isChecked={
                  !!selectedPositionsIDs.length &&
                  paginatedPositions.every((position: number) => {
                    return selectedPositionsIDs.includes(position);
                  })
                }
                isDisabled={positionsLoading || !JOB_POSITIONS_TABLE_DATA.length}
                isIndeterminate={
                  positionsData &&
                  !paginatedPositions.every((position: number) => {
                    return selectedPositionsIDs.includes(position);
                  })
                }
              />
            ),
            enableSorting: false,
            size: 20,
            cell: data => (
              <span>
                <Checkbox
                  id={String(data.getValue())}
                  name={String(data.getValue())}
                  value={data.getValue()}
                  label=""
                  handleCheckboxAction={event =>
                    handleIndividualPositionSelection(Number(event.target.value))
                  }
                  isChecked={selectedPositionsIDs.includes(data.getValue())}
                  isDisabled={!data.getValue()}
                />
              </span>
            ),
          }),
        ]
      : []),

    COLUMN_HELPER.accessor("name", {
      header: () => <span>Name</span>,
      size: 530,
      cell: data => <span className="break-word">{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Actions</span>,
      enableSorting: false,
      size: 50,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => {
        const positionID: number = data.getValue();
        const positionName: string = data.row.original.name;
        return (
          <div className="table__buttons">
            <PermissionCheckComponentWrapper permissions={["company_job_position_edit"]}>
              <Button
                modifierClass="btn--fluid btn--primary--light"
                isDisabled={!positionID}
                onClick={() => {
                  setSelectedPositionDetails({ id: positionID, name: positionName });
                  setShowEditModal(true);
                }}
              >
                Edit
              </Button>
            </PermissionCheckComponentWrapper>

            <PermissionCheckComponentWrapper permissions={["company_job_position_delete"]}>
              <Button
                modifierClass="btn--fluid btn--danger ml--10"
                isDisabled={!positionID}
                onClick={() => {
                  setSelectedPositionDetails({ id: positionID, name: positionName });
                  setShowDeleteModal(true);
                }}
              >
                Delete
              </Button>
            </PermissionCheckComponentWrapper>
          </div>
        );
      },
    }),
  ];

  // Map the received data into data suitable for displaying in the table
  const JOB_POSITIONS_TABLE_DATA = useMemo(() => {
    if (!selectedCompanyID || !positionsData || !positionsData.length) return [];

    // Filter out the job positions data to be displayed in the company
    // based on the currently selected company
    const companySpecificData = positionsData.find(companies => {
      return companies.company.id === selectedCompanyID;
    });

    // Remap the job positions to use correct naming for the fields
    // prettier-ignore
    let remappedJobPositions = companySpecificData?.job_positions
      .map(position => {
        return { id: position.key, name: position.value };
      }) ?? [];

    // Order the job positions alphabetically
    remappedJobPositions = remappedJobPositions.sort((positionA, positionB) => {
      return positionA.name.toLowerCase() > positionB.name.toLowerCase() ? 1 : -1;
    });

    return remappedJobPositions;
  }, [positionsData, selectedCompanyID]);

  /*============================
    JOB POSITION SELECTION 
  =============================*/
  const [selectedPositionDetails, setSelectedPositionDetails] = useState({
    id: null as number | null,
    name: null as string | null,
  });

  /*============================
    COMPANY POSITION CREATE
  =============================*/
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const createCompanyPosition = useJobPositionsCompanyCreate();

  const handleCompanyPositionCreate = async ({ name }: FormikValues) => {
    // Prevent any actions if there's no selected details
    if (!selectedCompanyID || !selectedCompanySlug) return;

    try {
      handleCloseModal();
      await createCompanyPosition.mutateAsync({
        name,
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
      });
    } catch (error) {
      errorReporting("Failed creating 'Company' job position", error, {
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
        job_position_name: name,
      });
    }
  };

  /*============================
    COMPANY POSITION EDIT
  =============================*/
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const editCompanyPosition = useJobPositionsCompanyUpdate();

  const handleCompanyPositionEdit = async ({ name }: FormikValues) => {
    // Prevent any actions if there's no selected details
    if (!selectedPositionDetails.id || !selectedCompanyID || !selectedCompanySlug) return;

    try {
      handleCloseModal();
      await editCompanyPosition.mutateAsync({
        name,
        key: selectedPositionDetails.id,
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
      });
    } catch (error) {
      errorReporting("Failed editing 'Company' job position", error, {
        job_position_id: selectedPositionDetails.id,
        key: selectedPositionDetails.id,
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
      });
    }
  };

  /*============================
    COMPANY POSITION DELETE
  =============================*/
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteCompanyPosition = useJobPositionsCompanyDelete();

  const handleCompanyPositionDelete = async () => {
    // Prevent any actions if there's no selected details
    if (!selectedPositionDetails.id || !selectedCompanyID || !selectedCompanySlug) return;

    try {
      handleCloseModal();
      await deleteCompanyPosition.mutateAsync({
        key: selectedPositionDetails.id,
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
      });
    } catch (error) {
      errorReporting("Failed deleting 'Company' job position", error, {
        job_position_id: selectedPositionDetails.id,
        key: selectedPositionDetails.id,
        company_id: selectedCompanyID,
        slug: selectedCompanySlug,
      });
    }
  };

  /*================================
    RESET SELECTED POSITION DETAILS
  =================================*/
  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setShowBulkDeleteModal(false);
    setSelectedPositionDetails({ id: null, name: null });
  };

  /*=======================================
    BULK POSITIONS SELECTION & DELETE
  =========================================*/
  const [selectedPositionsIDs, setSelectedPositionsIDs] = useState<number[]>([]);

  // On company change, empty the selection list
  useEffect(() => {
    setSelectedPositionsIDs([]);
  }, [selectedCompanyID]);

  // Selects OR deselects individual positions
  const handleIndividualPositionSelection = (positionID: number) => {
    const positionsIDs: number[] = [...selectedPositionsIDs];
    const isPositionSelected: number = positionsIDs.findIndex(id => id === positionID);

    if (isPositionSelected < 0) {
      positionsIDs.push(positionID);
    } else {
      // Find the received position in the original array and remove it
      const foundIndex = positionsIDs.findIndex(originalID => originalID === positionID);
      positionsIDs.splice(foundIndex, 1);
    }

    setSelectedPositionsIDs(positionsIDs);
  };

  // Selects/Deselects all job positions on the current pagination page
  const handlePositionsAllSelection = () => {
    if (!JOB_POSITIONS_TABLE_DATA.length) return;

    const SELECTED_SHOWN_OVERLAPPING = paginatedPositions.every(element => {
      return selectedPositionsIDs.includes(element);
    });

    // If all currently shown positions are selected, remove only those
    // that overlap between the current page selection and the selection from the entire dataset
    if (SELECTED_SHOWN_OVERLAPPING) {
      setSelectedPositionsIDs(
        selectedPositionsIDs.filter(selectedID => !paginatedPositions.includes(selectedID)),
      );
    } else {
      // Else spread the existing old selections and add the new ones to the array
      setSelectedPositionsIDs(uniq([...selectedPositionsIDs, ...paginatedPositions]));
    }
  };

  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<boolean>(false);

  const deleteBulkCompanyPositions = useJobPositionsCompanyBulkDelete();

  const handleBulkCompanyPositionsDelete = async () => {
    // Prevent any actions if there's no selected details
    if (!selectedPositionsIDs || !selectedCompanyID || !selectedCompanySlug) return;

    try {
      handleCloseModal();
      await deleteBulkCompanyPositions.mutateAsync({
        job_position_ids: selectedPositionsIDs,
        slug: selectedCompanySlug,
      });

      // Empty selection array after successful mutation
      setSelectedPositionsIDs([]);
    } catch (error) {
      errorReporting("Failed deleting multiple 'Company' job positions", error, {
        job_position_ids: selectedPositionsIDs,
        slug: selectedCompanySlug,
      });
    }
  };

  /*===================================================
    TABLE EXPORTED DATA & PAGINATION STATE

    -> Used for the selection indicator calculations
    -> TODO: Refactor into separate hook OR transfer 
    selection functionality inside table component
  ====================================================*/
  const [paginatedState, setPaginatedState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 0,
  });
  const [exportedTableData, setExportedTableData] = useState<number[]>([]);

  const paginatedPositions = useMemo(() => {
    if (!paginatedState.pageSize || !exportedTableData) return [];

    // Extract the current page & page size from the exported table paginations tate
    const { pageIndex, pageSize } = paginatedState;

    // Calculate the start/end cursors
    const startIndex = pageIndex === 0 ? 0 : pageIndex * pageSize;
    const endIndex = pageIndex === 0 ? pageSize : pageIndex * pageSize + pageSize;

    // Slice the exported table data using the cursors to get the same pagination data as the table
    const currentPagePositions = exportedTableData.slice(startIndex, endIndex);

    return currentPagePositions;
  }, [exportedTableData, paginatedState]);

  return (
    <motion.div
      initial={{ opacity: 0, display: "none" }}
      animate={{ opacity: 1, display: "block" }}
      exit={{ opacity: 0, display: "none" }}
      transition={{ duration: 1, type: "spring" }}
    >
      <p className="txt--black">Select a company to edit the job positions.</p>

      <div className="d-flex justify-content-between align-items-center mb--20">
        <DropdownSearchable
          placeholder="Select a company..."
          size="xl"
          items={COMPANIES_DROPDOWN}
          disabled={!COMPANIES_DROPDOWN.length || positionsLoading}
          isLoading={positionsLoading}
          preselectedItemValue={selectedCompanyID || undefined}
          handleItemSelected={handleCompanySelection}
        />
        <div>
          <PermissionCheckComponentWrapper permissions={["company_job_position_delete"]}>
            {selectedCompanyID ? (
              <Button
                type="button"
                modifierClass="btn--danger btn--fluid mr--20"
                onClick={() => setShowBulkDeleteModal(true)}
                isDisabled={
                  !selectedPositionsIDs.length ||
                  positionsLoading ||
                  selectedPositionsIDs.includes(0)
                }
              >
                Delete Selected
              </Button>
            ) : null}
          </PermissionCheckComponentWrapper>
          <PermissionCheckComponentWrapper permissions={["company_job_position_create"]}>
            {selectedCompanyID ? (
              <Button
                type="button"
                modifierClass="btn--secondary btn--fluid"
                onClick={() => setShowCreateModal(true)}
              >
                Create
              </Button>
            ) : null}
          </PermissionCheckComponentWrapper>
        </div>
      </div>

      <AnimatePresence>
        {selectedPositionsIDs.length ? (
          <div style={{ overflow: "hidden" }}>
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={FRAMER_SELECTION_INDICATOR}
              transition={{ type: "spring", duration: 0.5, damping: 25 }}
            >
              <PositionsSelectionIndicator
                allPositions={JOB_POSITIONS_TABLE_DATA.map(position => position.id)}
                paginatedPositions={paginatedPositions}
                selectedPositionsIDs={selectedPositionsIDs}
                setSelectedPositionIDs={setSelectedPositionsIDs}
              />
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>

      {selectedCompanyID ? (
        <Table
          data={JOB_POSITIONS_TABLE_DATA}
          columns={JOB_POSITIONS_COMPANY_COLUMNS}
          isRefetching={false}
          paginationPageSize={10}
          modifierClass="table-wrapper--no-shadow table-wrapper--centered-th mb--0i px--0i"
          noDataMessage={
            positionsLoading && selectedCompanyID
              ? "Fetching Job Positions..."
              : "No Job Positions available"
          }
          handleExportPaginationState={(paginationState: PaginationState) =>
            setPaginatedState(paginationState)
          }
          handleExportData={(data: JobPositionFields[]) =>
            setExportedTableData(data.map((position: JobPositionFields) => position.id))
          }
        />
      ) : null}

      {/* BULK DELETE MODAL */}
      <AnimatePresence>
        {selectedPositionsIDs.length > 0 && showBulkDeleteModal ? (
          <Modal
            title="Delete selected positions?"
            text={
              <div className="d-flex flex-column">
                <span>
                  Are you sure you want to delete the{" "}
                  <strong>
                    {selectedPositionsIDs.length}{" "}
                    selected {selectedPositionsIDs.length > 1 ? "positions" : "position"}
                  </strong>? This action is irreversible.
                </span>
              </div>
            }
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                type="button"
                modifierClass="btn--fluid btn--fluid--md btn--danger ml--10"
                onClick={() => handleBulkCompanyPositionsDelete()}
                isLoading={deleteCompanyPosition.isLoading}
                isDisabled={deleteCompanyPosition.isLoading}
              >
                Delete
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>

      {/* CREATE COMPANY POSITION */}
      <AnimatePresence>
        {showCreateModal ? (
          <Modal
            title="Create Company Position"
            text=""
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <Formik
              initialValues={{ name: "" }}
              validationSchema={JOB_POSITIONS_WRITE_SCHEMA}
              onSubmit={handleCompanyPositionCreate}
            >
              <Form>
                <Field
                  component={FormInput}
                  id="name"
                  name="name"
                  placeholder="Company Job Position"
                  modifierClass="mb--15"
                />

                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary--light ml--10"
                  isLoading={createCompanyPosition.isLoading}
                  isDisabled={createCompanyPosition.isLoading}
                >
                  Create
                </Button>
              </Form>
            </Formik>
          </Modal>
        ) : null}
      </AnimatePresence>

      {/* EDIT COMPANY POSITION */}
      <AnimatePresence>
        {showEditModal ? (
          <Modal
            title={`Edit position: ${selectedPositionDetails.name}`}
            text=""
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <Formik
              initialValues={{ name: selectedPositionDetails.name ?? "" }}
              enableReinitialize
              validationSchema={JOB_POSITIONS_WRITE_SCHEMA}
              onSubmit={handleCompanyPositionEdit}
            >
              <Form>
                <Field
                  component={FormInput}
                  id="name"
                  name="name"
                  placeholder="Company Job Position"
                  modifierClass="mb--15"
                />

                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary--light ml--10"
                  isLoading={editCompanyPosition.isLoading}
                  isDisabled={editCompanyPosition.isLoading}
                >
                  Edit
                </Button>
              </Form>
            </Formik>
          </Modal>
        ) : null}
      </AnimatePresence>

      {/* DELETE COMPANY POSITION */}
      <AnimatePresence>
        {showDeleteModal ? (
          <Modal
            title="Delete position?"
            text={
              <>
                Are you sure you want to delete{" "}
                <strong className="txt--black">{selectedPositionDetails.name}</strong> ? This action
                is irreversible.
              </>
            }
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                type="button"
                modifierClass="btn--fluid btn--fluid--md btn--danger ml--10"
                onClick={handleCompanyPositionDelete}
                isLoading={deleteCompanyPosition.isLoading}
                isDisabled={deleteCompanyPosition.isLoading}
              >
                Delete
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

export default JobCompanyPositions;
