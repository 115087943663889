// Utilities
import { handleStringCapitalization } from "../../../utilities/strings/handleStringCapitalization";
import { handleFormFieldsMaximumCharactersLimit } from "./utilities";
import { handleCheckStringForHTML } from "../../../utilities/strings/handleCheckStringForHTML";
import * as Yup from "yup";

// Interfaces
import { OnlineApplicationFormComponentsFields } from "../../../api/OnlineApplication/interfaces";
import {
  OnlineApplicationFieldsForDynamicValidationSchema,
  OnlineApplicationDynamicFieldsValuesEnum,
  OnlineApplicationSupportedDynamicFieldsValues,
} from "../interfaces";

// Constants
import { FILE_MAX_BYTES_SIZE, FORM_FIELD_SUBTYPE_FIELDS_VALIDATIONS } from "./constants";
import {
  SCHEMAS_NO_HTML_MESSAGE_TEXT,
  SchemasFileValueTestValidation,
} from "../../../schemas/constants";

/**
 *
 * Utility function for generating the validation schema that is to be used
 * by fields from the form that are represented as "arrays", and can be of dynamic size
 *
 * @param components The form section components to work with, for which we'll be generating the validation schema
 * @returns Validation schema object for the arrays of dynamic size that are part of the form
 *
 */
export function handleFormArraySectionsFieldValidationSchemas(
  components: OnlineApplicationFormComponentsFields[],
) {
  const validationObject: any = {};

  components.forEach(component => {
    // Go to next itteration if the component is not marked as "dynamic"
    if (!component.dynamic) return;

    // Extract the name of the section to be used as the property to which
    // the individual field validation schemas will be added
    const sectionFieldName: string = component.title.split(" ").join("_").toLowerCase();

    // Loop trough each of the fields that belong to the dynamic section
    const validationFields: OnlineApplicationFieldsForDynamicValidationSchema[] = [];
    component.section_fields.forEach(field => {
      validationFields.push({
        name: field.name,
        is_required: field.is_required,
        type: field.type,
        sub_type: field.sub_type,
        value_type: OnlineApplicationDynamicFieldsValuesEnum[field.type],
      });
    });

    // Generate Yup.array() type of schema for each of the dynamic sections
    let arrayObjectShape: any = {};
    validationFields.forEach(field => {
      const { name, is_required, value_type, sub_type, type } = field;
      const fieldRequired = is_required ? "required" : "notRequired";
      const valueType: OnlineApplicationSupportedDynamicFieldsValues = value_type;

      /*======================================
        SUB-TYPE SPECIFIC FIELDS

        (e.g. linked in, photo, resume)
      =======================================*/
      if (sub_type) {
        arrayObjectShape = {
          ...arrayObjectShape,
          [name]: arrayFieldValidator(
            name,
            component.repetitions,
            fieldRequired === "required",
            valueType === "file" ? "mixed" : valueType,
            FORM_FIELD_SUBTYPE_FIELDS_VALIDATIONS[sub_type][fieldRequired],
            type,
          ),
        };
      }

      /*======================================
        EMAIL-SPECIFIC FIELDS
      =======================================*/
      if (name === "email") {
        arrayObjectShape = {
          ...arrayObjectShape,
          [name]: arrayFieldValidator(
            name,
            component.repetitions,
            fieldRequired === "required",
            "string",
            Yup.string()
              .email(`${handleStringCapitalization(name, [" "])} must be a valid email.`)
              .max(50, "Maximum of 50 characters is allowed!"),
            type,
          ),
        };
        return;
      }

      /*======================================
        PHONE-SPECIFIC FIELDS
      =======================================*/
      if (name === "phone") {
        arrayObjectShape = {
          ...arrayObjectShape,
          [name]: arrayFieldValidator(
            name,
            component.repetitions,
            fieldRequired === "required",
            "string",
            Yup.string().test(
              "phone-number",
              "Please enter a valid phone number in the following format!",
              phone => {
                // If the field is not required, do not run any validation
                if (fieldRequired === "notRequired" && !phone) return true;

                // Throw an error if there are not enough characters for valid phone number
                // This phone value is already sanitized from potential brackets, empty spaces and dashes
                // and has an appended +1 prefix to it, that's why the check is set for less than 12 characters
                if (phone && phone.length < 12) return false;

                return true;
              },
            ),
            type,
          ),
        };
        return;
      }

      /*======================================
        DATE FIELD TYPES
      =======================================*/
      if (valueType === "datetime") {
        arrayObjectShape = {
          ...arrayObjectShape,
          [name]: arrayFieldValidator(
            name,
            component.repetitions,
            fieldRequired === "required",
            "date",
            Yup.date().nullable().max(new Date()),
            type,
          ),
        };
        return;
      }

      /*======================================
        FILE FIELD TYPES
      =======================================*/
      if (valueType === "file") {
        arrayObjectShape = {
          ...arrayObjectShape,
          [name]: arrayFieldValidator(
            name,
            component.repetitions,
            fieldRequired === "required",
            "mixed",
            Yup.mixed()
              .nullable()
              .test(
                `array-section-field-${name}-file-size`,
                `${handleStringCapitalization(name.split("_").join(" "), [
                  " ",
                ])} file must be up to a maximum of 8 MB in size`,
                value => {
                  // If the value is null (default value) then no validation is applied
                  if (value == null) return true;

                  // If the file is bigger than 8mb, throw error, otherwise test passes
                  return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
                },
              ),
            type,
          ),
        };
        return;
      }

      /*======================================
        GENERAL FIELD TYPES

        (e.g. string, number, boolean based)
      =======================================*/
      arrayObjectShape = {
        ...arrayObjectShape,

        [name]: arrayFieldValidator(
          name,
          component.repetitions,
          fieldRequired === "required",
          valueType,
          Yup[valueType](),
          type,
        ),
      };
    });

    // Add the array section validation schema to the main validation object that will be exposed for usage
    validationObject[sectionFieldName] = Yup.array().of(Yup.object().shape(arrayObjectShape));
  });

  return validationObject;
}

/**
 *
 * Validator function for fields contained in an array, representing the sections that are dynamic.
 *
 * @param name The name of the targeted field
 * @param repetitions The number of default repetitions for the form section in which the field is located
 * @param isRequired Is the field marked as required or not in the response from the API
 * @param valueType The type of the field that we're working with
 * @param validationSchema Validation schema to be used to check if the provided value is correct
 *
 * @returns Validation schema for the targeted field
 *
 */
function arrayFieldValidator(
  name: string,
  repetitions: number,
  isRequired: boolean = true,
  valueType: any,
  validationSchema: Yup.AnySchema,
  fieldType: any,
) {
  // Message to be shown in the UI in case there's a validation schema
  const message = isRequired
    ? `${handleStringCapitalization(name.split("_").join(" "), [" "])} field is required`
    : "";

  // Character limitations for text-based fields
  const charachterLimit = handleFormFieldsMaximumCharactersLimit(fieldType);

  // eslint-disable-next-line
  // @ts-ignore: Yup throws type issues
  return Yup[valueType]()
    .test(`array-section-field-${name}-test`, message, (value: any, context: any) => {
      // Check if the field that is to be checked is within the default repetitions
      // meaning that the field was not added manually later on by the user
      const isWithinRange: boolean = validationFieldWithinRepetitionsRange(
        context.options.index,
        repetitions,
      );

      // If the field is marked as required, and there's no value for it, throw an error
      if (isRequired && !value && isWithinRange) return false;

      // If the field's index is within the default repetitions range (before user manually added new sections)
      // and if the validation for the field fails, then throw the corresponding error
      if (isWithinRange && !validationSchema.isValidSync(value)) return false;

      return true;
    })
    .test(`${name}`, SCHEMAS_NO_HTML_MESSAGE_TEXT, (value: any) => {
      // Do not run this test if the value is not a string
      if (typeof value !== "string") return true;

      // Throw a validation error if there's HTML code in the string's value
      return !handleCheckStringForHTML(value as string);
    })
    .test(
      `array-section-field-${name}-character-limit`,
      `Maximum of ${charachterLimit} is allowed!`,
      (value: any) => {
        // Do not run this test if the value is not a string
        if (typeof value !== "string") return true;

        // If the string has more than the specified characters limit, throw a validation error
        if (value.length > charachterLimit) return false;

        return true;
      },
    );
}

/**
 * Utility function to check if the targeted array field is within the
 * default repetitions of the form section, or it was manually added by the user as
 * an extra, in which case no validation is applied.
 *
 * @param fieldIndex The array index position of the field
 * @param repetitions The number of default repetitions of the form section
 *
 * @returns Boolean indicating if the field is within the allowed range or not
 */
function validationFieldWithinRepetitionsRange(fieldIndex: number, repetitions: number): boolean {
  return fieldIndex <= repetitions;
}
