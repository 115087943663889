// Utilities & Hooks
import React, { useState } from "react";
import { useAuth } from "../../../providers/auth-context";
import { Field, Form, Formik } from "formik";
import {
  useJobAdsDeleteComment,
  useJobAdsEditComment,
  useJobAdsPostNewComment,
} from "../../../api/JobAds/JobAdsComments";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Interfaces
import { JobAdDetailsCommentsProps } from "./interfaces";

// Components
import { AnimatePresence } from "framer-motion";
import Button from "../../Button/Button";
import FormTextarea from "../../Form/FormTextarea";
import Tooltip from "../../Tooltip/Tooltip";
import Modal from "../../Modal/Modal";

// Assets
import { FaPencilAlt as CommentEditIcon } from "react-icons/fa";
import { IoMdCloseCircle as CommentDeleteIcon } from "react-icons/io";

// Schemas
import { JOB_ADS_DETAILS_COMMENTS_SCHEMA } from "../../../schemas/JobAdSchemas";

const JobAdComments = ({ id, comments }: JobAdDetailsCommentsProps) => {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  const [selectedCommentID, setSelectedCommentID] = useState<number | null>(null);

  /*======================
    POST NEW COMMENTS
  ========================*/
  const postNewComment = useJobAdsPostNewComment();

  const handlePostNewComment = async ({ data }: { data: string }) => {
    // Prevent sending requests for empty textarea or
    // if there's no valid Job Ad ID received
    if (!data.length || !id) return;

    try {
      await postNewComment.mutateAsync({ ad_id: id, data });
    } catch (error) {
      errorReporting("Failed posting new ad comment", error, { comment: data });
    }
  };

  /*======================
    EDIT COMMENTS
  ========================*/
  const [showEditCommentForm, setShowEditCommentForm] = useState<boolean>(false);
  const editComment = useJobAdsEditComment();

  const handleEditComment = async ({ data }: { data: string }) => {
    // Prevent sending any requests if no comment is selected
    // or if there's no valid Job Ad ID value received
    if (selectedCommentID == null || !id) return;

    try {
      // Close the modal
      setShowEditCommentForm(false);

      // Wait for the response from the API
      await editComment.mutateAsync({
        ad_id: id,
        comment_id: selectedCommentID,
        data,
      });

      // If everything is fine, reset the selected comment ID
      setSelectedCommentID(null);
    } catch (error) {
      // If an error occurs, show the modal back up again
      setShowEditCommentForm(true);
      errorReporting("Failed editing existing ad comment", error, {
        comment_id: selectedCommentID,
      });
    }
  };

  /*======================
    DELETE COMMENTS
  ========================*/
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState<boolean>(false);
  const deleteComment = useJobAdsDeleteComment();

  const handleDeleteComment = async () => {
    // Prevent sending any requests if no comment is selected
    // or if there's no valid Job Ad ID value received
    if (selectedCommentID == null || !id) return;

    try {
      // Close the modal
      setShowDeleteCommentModal(false);

      // Wait for the response from the API
      await deleteComment.mutateAsync({ ad_id: id, comment_id: selectedCommentID });

      // If everything is fine, reset the selected comment ID
      setSelectedCommentID(null);
    } catch (error) {
      // If an error occurs, show the modal back up again
      setShowDeleteCommentModal(true);
      errorReporting("Failed deleting existing ad comment", error, {
        comment_id: selectedCommentID,
      });
    }
  };

  return (
    <div className="job-ads-details__comments">
      {comments && comments.length > 0
        ? comments.map(comment => (
            <React.Fragment key={comment.id}>
              <div className="job-ads-details__comments__item">
                <div className="job-ads-details__comments__item__header">
                  <h5>{comment.data}</h5>

                  {user.id === comment.commented_by.id ? (
                    <>
                      <div
                        className="job-ads-details__comments__item__actions"
                        onClick={() => setSelectedCommentID(comment.id)}
                      >
                        <Tooltip text="Edit Comment" size="md">
                          <CommentEditIcon
                            onClick={() => setShowEditCommentForm(true)}
                            data-testid="component:job-ad-comments-edit-btn"
                          />
                        </Tooltip>

                        <Tooltip text="Delete Comment" size="md" wrapperModifierClass="ml--10">
                          <CommentDeleteIcon
                            onClick={() => setShowDeleteCommentModal(true)}
                            data-testid="component:job-ad-comments-delete-btn"
                          />
                        </Tooltip>
                      </div>
                    </>
                  ) : null}
                </div>

                <p className="job-ads-details__comments__item__info">
                  Posted by {comment.commented_by.full_name} at {comment.timestamp || "N/A"}
                </p>
              </div>

              {/* EDIT COMMENT FORM */}
              {showEditCommentForm && comment.id === selectedCommentID ? (
                <Formik
                  initialValues={{ data: comment.data }}
                  onSubmit={handleEditComment}
                  validationSchema={JOB_ADS_DETAILS_COMMENTS_SCHEMA}
                >
                  {({ values }) => (
                    <Form>
                      <Field
                        id="data"
                        name="data"
                        component={FormTextarea}
                        placeholder="Type your message"
                        rows={4}
                        modifierClass="mb--10"
                      />
                      <div className="d-flex justify-content-end mb--20">
                        <Button
                          modifierClass="btn--fluid btn--primary"
                          isDisabled={!values.data || editComment.isLoading}
                          isLoading={editComment.isLoading}
                        >
                          Update
                        </Button>
                        <Button
                          type="button"
                          onClick={() => setShowEditCommentForm(false)}
                          modifierClass="btn--fluid btn--danger ml--10"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : null}
            </React.Fragment>
          ))
        : null}

      {/* NEW COMMENT FORM */}
      <Formik
        initialValues={{ data: "" }}
        onSubmit={handlePostNewComment}
        validationSchema={JOB_ADS_DETAILS_COMMENTS_SCHEMA}
      >
        {({ values }) => (
          <Form>
            <Field
              id="data"
              name="data"
              component={FormTextarea}
              placeholder="Type your message"
              rows={4}
              modifierClass="mb--20"
            />

            <div className="d-flex justify-content-end">
              <Button
                modifierClass="btn--fluid btn--primary"
                isDisabled={!values.data || postNewComment.isLoading}
                isLoading={postNewComment.isLoading}
              >
                Post Comment
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {/* DELETE CONFIRMATION MODAL */}
      <AnimatePresence>
        {showDeleteCommentModal ? (
          <Modal
            title="Delete Comment?"
            text="Are you sure you want to delete this comment? This action is irreversible."
            handleCloseModal={() => setShowDeleteCommentModal(false)}
            modifierClass="modal--md modal--fixated p--30i"
          >
            <div className="d-flex justify-content-center align-items-center mt--20">
              <Button
                modifierClass="btn--fluid btn--primary--light"
                onClick={() => setShowDeleteCommentModal(false)}
              >
                Cancel
              </Button>

              <Button
                modifierClass="btn--fluid--md btn--primary ml--20"
                onClick={handleDeleteComment}
                isLoading={deleteComment.isLoading}
                isDisabled={deleteComment.isLoading}
              >
                Yes
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default JobAdComments;
