// Utilities & Hooks
import { useEffect, useState, ChangeEvent } from "react";
import { Field, Form, Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { cloneDeep } from "lodash-es";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";
import { useAuthenticationLogin } from "../../api/Authentication/Authentication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Button from "../../components/Button/Button";
import FormInput from "../../components/Form/FormInput";
import FormCheckbox from "../../components/Form/FormCheckbox";
import ModalInactiveUser from "../../components/Modal/ModalInactiveUser";
import FormPasswordInput from "../../components/Form/FormPasswordInput";

// Schemas
import { AUTHENTICATION_LOGIN_SCHEMA } from "../../schemas/AuthenticationSchemas";

// Interfaces
import { LoginFormState } from "./interfaces";
import { RouterLocationStateRedirectTo } from "../../interfaces/global";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();
  const [searchParametersObject] = useExtractSearchParameters();

  const [loginState, setLoginState] = useState<LoginFormState>({
    email: "",
    password: "",
  });

  /*==============================
    LOGIN USER
  ===============================*/
  const [showInactiveUserModal, setShowInactiveUserModal] = useState<boolean>(false);
  const loginUser = useAuthenticationLogin();

  const handleLogin = async (credentials: LoginFormState) => {
    try {
      const { is_enabled } = await loginUser.mutateAsync(credentials);

      // If the user is not enabled, show info modal
      if (is_enabled === false) {
        setShowInactiveUserModal(true);
        return;
      }

      // Redirect the user to the page that they tried accessing
      // but couldn't because they weren't logged in at the moment
      const locationState = location.state as RouterLocationStateRedirectTo;

      if (locationState && locationState.redirectTo) navigate(locationState.redirectTo);

      // If the redirection is coming from an expired token, read the redirection
      // URL & any filters if present from the search params
      const copiedParams = cloneDeep(searchParametersObject);
      delete copiedParams["message"];
      const parametersEntries = Object.entries(copiedParams);

      const finalparams = parametersEntries
        .join("&")
        .replaceAll(",", "=")
        .replace("redirectTo=", "");

      if (searchParametersObject["redirectTo"]) navigate(finalparams);
    } catch (error) {
      errorReporting("User login failed", error, { email: credentials.email }, "critical");
    }
  };

  /*==============================
    REMEMBER ME

    Saves the email in local storage
    and populates the input field with its value
  ===============================*/
  const [isRememberMeChecked, setIsRememberMeChecked] = useState<boolean>(false);

  const handleRememberMe = (email: string, checked: boolean) => {
    // Either update or remove the 'fch-email' value in local storage
    if (checked) {
      LocalStorageActions.saveItem("fch-email", email);
    } else {
      LocalStorageActions.removeItem("fch-email");
    }

    setIsRememberMeChecked(checked);
  };

  // Pre-populate the email field with if a saved value exists
  useEffect(() => {
    const loginStateCopy = { ...loginState };
    const rememberedEmail: string = LocalStorageActions.getItem("fch-email");

    if (rememberedEmail) {
      setLoginState({ ...loginStateCopy, email: rememberedEmail });
      setIsRememberMeChecked(true);
    }
  }, []);

  /*==============================
    RESET ALL CACHED DATA
    WHEN THIS PAGE COMPONENT MOUNTS
  ===============================*/
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.clear();
  }, []);

  /*============================
    HANDLE THE DISPLAYED
    LOGIN MESSAGE
  =============================*/
  const [loginMessage, setLoginMessage] = useState<string | React.ReactNode>("Please Login");

  useEffect(() => {
    // If there's no valid location's state, exit function
    if (!location.state) return;

    // Extract the "redirectTo" state property, that contains
    // the URL to which the user will be redirected after successful login
    const { redirectTo } = location.state as Record<"redirectTo", string>;

    // If the redirect URL is the page for accepting an invitation,
    // show a specific message to the user informing them what they need to do.
    if (redirectTo.startsWith("/account/invitation/")) {
      setLoginMessage(
        "You need to be logged in to \n accept this invitation. \n\n Please log in below.",
      );
    }
  }, [location.state]);

  // Check for existing URL parameters that include the redirect reason
  // so we can display the correct message to the user in the UI
  // NOTE: In case the number of scenarios where we need to show a message increases, we can refactor this.
  useEffect(() => {
    if (searchParametersObject?.message === "expired_token") {
      setLoginMessage(
        <>
          <span className="txt--xxl txt--error">Your session has timed out</span>
          <span className="d-block txt--lg">Please login again to access the application</span>
        </>,
      );
    }
  }, []);

  return (
    <>
      <h1 className="h4 my--30 txt--center fw--semibold">{loginMessage}</h1>

      <Formik
        initialValues={loginState}
        validationSchema={AUTHENTICATION_LOGIN_SCHEMA}
        enableReinitialize
        onSubmit={handleLogin}
      >
        {({ values }) => (
          <Form>
            <Field
              name="email"
              id="email"
              placeholder="Email Address"
              component={FormInput}
              modifierClass="mb--15"
            />

            <Field
              component={FormPasswordInput}
              id="password"
              name="password"
              placeholder="Password"
              modifierClass="mb--15"
            />

            <Field
              name="remember_me"
              id="remember_me"
              label="Remember Me"
              modifierClass="mb--10"
              checked={isRememberMeChecked}
              handleCheckboxNonFormAction={(event: ChangeEvent<HTMLInputElement>) => {
                handleRememberMe(values.email, event.target.checked);
              }}
              component={FormCheckbox}
            />

            <Button
              type="submit"
              modifierClass="btn--xl btn--primary"
              isLoading={loginUser.isLoading}
              isDisabled={loginUser.isLoading}
            >
              {loginUser.isLoading ? "Signing In..." : "Sign In"}
            </Button>
          </Form>
        )}
      </Formik>

      <div className="d-flex justify-content-end">
        <Link className="txt--md mt--10 mb--20 txt--underline-on-hover" to="/forgot-password/">
          Forgot Password?
        </Link>
      </div>

      <div className="unauthenticated__form__contact">
        <p className="fw--semibold">Need Technical Support?</p>
        <a href="tel:+18774497595">Call us at (877) 449-7595</a>
      </div>

      <div className="unauthenticated__form__contact">
        <p className="fw--semibold">Need Sales Assistance?</p>
        <a href="tel:+18889906451">Call us at (888) 990-6451</a>
      </div>

      <AnimatePresence>
        {showInactiveUserModal ? (
          <ModalInactiveUser handleModalState={() => setShowInactiveUserModal(false)} />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default Login;
