// Hooks & Utils
import { useEffect, useMemo, useRef, useState } from "react";
import { matchSorter } from "match-sorter";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import useScrollToActiveElement from "../../hooks/useScrollToActiveElement";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Interfaces
import { DropdownItem, DropdownSearchableProps } from "./interfaces";

// Assets
import { FaChevronDown as ChevronIcon } from "react-icons/fa";

// Components
import Loader from "../Loader/Loader";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import { motion, AnimatePresence } from "framer-motion";

// Constants
import {
  FRAMER_DROPDOWN_ANIMATION,
  FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION,
} from "../../constants/framer";

const DropdownSearchable: React.FC<DropdownSearchableProps> = ({
  items,
  handleItemSelected,

  preselectedItemValue = "",
  placeholder = "",
  label = "",
  size = "md",
  isLoading = false,
  disabled = false,
  modifierClass = "",
  allowDeselection = false,
  orientation = "bottom",
  maxScrollableHeight = "300px",
  deselectionHandler,
  isRequired = false,
  framerAnimationCustomProps = null,
  sortOnSearch = { enabled: false, direction: "desc" },
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownContentRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // The value of the item that was selected from the dropdown menu
  const [selectedItemValue, setSelectedItemValue] = useState<string | number>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [placeholderValue, setPlaceholderValue] = useState<string>(placeholder);

  /*===========================
   DROPDOWN MENU STATE
  ============================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdownMenu = () => {
    // If the component is marked as 'disabled', prevent it from being opened
    if (disabled || isLoading) return;

    // Focus on the input field
    if (inputRef.current) inputRef.current.focus();

    setIsDropdownOpen(!isDropdownOpen);
  };

  /*===========================
   PRE-SELECT ITEM IF THERE'S
   A RECEIVED TITLE PROP THAT MATCHES IT
  ============================*/
  useEffect(() => {
    // Do not preselect anything if there's no such prop received
    if (!preselectedItemValue) return;

    // Find the item whose value matches the preselected item value received as a prop
    const preselectedItem: DropdownItem | undefined = items.find((item: DropdownItem) => {
      return item.value === preselectedItemValue;
    });

    // Exit if there's no match
    if (!preselectedItem) return;

    // Populate the input's placeholder text
    // and the state representing the currently selected item,
    // if there was a matching between the list of dropdown items
    // and the item value that was passed as preselected trough the prop
    setPlaceholderValue(preselectedItem.text);
    setSelectedItemValue(preselectedItem.value);
  }, [preselectedItemValue, items]);

  /*===========================
   HANDLE SEARCHING TROUGH
   THE RECEIVED DROPDOWN ITEMS
  ============================*/

  const handleOnSearch = (value: string) => {
    // Open the menu if it was closed
    if (!isDropdownOpen) setIsDropdownOpen(true);

    setSearchValue(value);
  };

  /*===========================
   FILTERED ITEMS
  ============================*/
  const FILTERED_DROPDOWN_ITEMS: DropdownItem[] = useMemo(() => {
    // If there's no value in the input field, return originally received list
    if (!searchValue) return items;

    // Filter the list of dropdown items based on user's input
    let FILTERED_ITEMS: DropdownItem[] = matchSorter(items, searchValue.trim(), {
      keys: ["text"],
      threshold: matchSorter.rankings.CONTAINS,
    });

    // Sort the dropdown items after searching (disabled by default)
    if (sortOnSearch.enabled) {
      FILTERED_ITEMS = FILTERED_ITEMS.sort((a, b) => {
        if (sortOnSearch.direction === "asc") {
          // If the received items contain a 'sortValue' property
          // use that value for sorting after searching
          // else just use the text field value for sorting
          if (a.sortValue && b.sortValue) {
            return a.sortValue > b.sortValue ? 1 : -1;
          } else {
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          }
        } else {
          if (a.sortValue && b.sortValue) {
            return a.sortValue > b.sortValue ? -1 : 1;
          } else {
            return a.text.toLowerCase() > b.text.toLowerCase() ? -1 : 1;
          }
        }
      });
    }

    return FILTERED_ITEMS;
  }, [items, searchValue]);

  /*===========================
   HANDLE DROPDOWN SELECTION
  ============================*/
  const handleDropdownItem = (item: DropdownItem) => {
    // Prevent functionality if the item is marked as "disabled"
    if (item.disabled) return;

    // If the clicked item is the same as the already selected one,
    // then just de-select the item and do not do anything extra
    if (allowDeselection && deselectionHandler && item.value === selectedItemValue) {
      setSelectedItemValue("");
      setPlaceholderValue(placeholder);
      setSearchValue("");

      // Callback function to be triggered on deselection
      deselectionHandler();
    } else {
      // Highlights (marks) the dropdown item that was selected
      setSelectedItemValue(item.value);

      // Update the state for the search and placeholder values
      // that are displayed in the input, based on the item that was selected
      setSearchValue("");
      setPlaceholderValue(item.text);

      // Call the callback passed as prop
      handleItemSelected(item);
    }

    // Close the dropdown menu
    setIsDropdownOpen(false);
  };

  /*===========================
    CLOSE WHEN CLICKED OUTSIDE
  ============================*/
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  // Close when "Escape" key is pressed
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  /*============================
   DROPDOWN CLASS NAMES
  ============================*/
  let DROPDOWN_CLASSNAME = `dropdown dropdown--searchable dropdown--${size}`;
  if (disabled) DROPDOWN_CLASSNAME += " dropdown--disabled";
  if (modifierClass) DROPDOWN_CLASSNAME += ` ${modifierClass}`;
  if (isDropdownOpen) DROPDOWN_CLASSNAME += " dropdown--active";

  /*===============================
   SCROLL TO THE SELECTED ELEMENT
  ================================*/
  useScrollToActiveElement(
    isDropdownOpen,
    dropdownContentRef,
    ".simplebar-content",
    ".dropdown__item--selected",
  );

  return (
    <div ref={dropdownRef} className={DROPDOWN_CLASSNAME} tabIndex={0}>
      {label && (
        <label
          htmlFor="dropdown"
          className={`dropdown__label ${isRequired ? "dropdown__label--required" : ""}`}
        >
          {label}
        </label>
      )}

      <div className="d-flex flex-column w--100">
        <div className="p--relative">
          <div
            className={`dropdown__body ${isDropdownOpen ? "dropdown__body--active" : ""}`}
            onClick={handleToggleDropdownMenu}
            data-testid="components-dropdown-searchable:body"
          >
            <input
              ref={inputRef}
              type="text"
              value={searchValue}
              placeholder={placeholderValue}
              disabled={disabled || isLoading}
              className="dropdown__input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnSearch(e.target.value)}
              title={searchValue || placeholderValue}
            />
            <div className="d-flex">
              {isLoading ? (
                <Loader size="sm" modifierWrapper="mr--10" />
              ) : (
                <ChevronIcon className="dropdown__chevron" />
              )}
            </div>
          </div>

          <AnimatePresence>
            {isDropdownOpen && (
              <motion.ul
                className={`dropdown__content dropdown__content--orientation-${orientation}`}
                key="framer-dropdown-searchable"
                variants={
                  orientation === "bottom"
                    ? FRAMER_DROPDOWN_ANIMATION
                    : FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION
                }
                initial="initial"
                animate="animate"
                exit="exit"
                custom={framerAnimationCustomProps}
                transition={{ duration: 0.5, type: "spring" }}
                ref={dropdownContentRef}
              >
                <CustomScrollbars maxHeight={maxScrollableHeight}>
                  {FILTERED_DROPDOWN_ITEMS.length > 0 ? (
                    <>
                      {FILTERED_DROPDOWN_ITEMS.map((item: DropdownItem) => {
                        let DROPDOWN_ITEM_CLASSNAME: string = "dropdown__item";

                        if (item.disabled) DROPDOWN_ITEM_CLASSNAME += " dropdown__item--disabled";
                        if (item.value === selectedItemValue) {
                          DROPDOWN_ITEM_CLASSNAME += " dropdown__item--selected";
                        }

                        return (
                          <li
                            key={item.value}
                            className={DROPDOWN_ITEM_CLASSNAME}
                            onClick={() => handleDropdownItem(item)}
                            title={item.text}
                          >
                            <div className="d-flex flex-column">
                              {item.icon && <div className="dropdown__item__icon">{item.icon}</div>}
                              {item.text}

                              {item.description && (
                                <p className="dropdown__item__description">{item.description}</p>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <li className="dropdown__item">No Data Found</li>
                  )}
                </CustomScrollbars>
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
export default DropdownSearchable;
