import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
import { BannerCreatePayload, BannerEditPayload, BannersListResponse } from "./interfaces";
import fetchHandler from "../fetchHandler";

/** Get the list of all existing banners */
export const useMarketingBannersGetAll = () => {
  return useQuery(["marketing-banners-all"], async () => {
    return (await fetchHandler("GET", "admin/banners")) as BannersListResponse[];
  });
};

/** Create a new marketing announcement banner */
export const useMarketingBannersCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (createPayload: BannerCreatePayload) => {
      return await fetchHandler("POST", "admin/banners", createPayload);
    },
    {
      onMutate: (createPayload: BannerCreatePayload) => {
        // Get currently cached banners
        const cachedBanners = queryClient.getQueryData([
          "marketing-banners-all",
        ]) as BannersListResponse[];

        // Add the new banner to the list of existing banners
        const updatedBannersList = cloneDeep(cachedBanners);
        updatedBannersList.push({
          id: 0,
          text: createPayload.text,
          hyperlink: createPayload.hyperlink,
          is_active: false,
          order: 0,
        });

        // Update the cached data
        queryClient.setQueryData(["marketing-banners-all"], updatedBannersList);

        // Show a success notification in the UI
        toast.success("Successfully created new banner!", {
          toastId: "marketing-banners-new",
        });

        return { cachedBanners };
      },
      onError: (error, _banner, context) => {
        toast.dismiss("marketing-banners-new");
        queryClient.setQueryData(["marketing-banners-all"], context?.cachedBanners);
        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["marketing-banners-all"]);
      },
    },
  );
};

/** Update the targeted marketing announcement banner */
export const useMarketingBannersEdit = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (editPayload: BannerEditPayload) => {
      return await fetchHandler("PUT", `admin/banners/${editPayload.id}`, editPayload);
    },
    {
      onMutate: editPayload => {
        // Get currently cached banners
        const cachedBanners = queryClient.getQueryData([
          "marketing-banners-all",
        ]) as BannersListResponse[];

        // Early exit in case there are no banners to be found in the cache
        if (!cachedBanners || !cachedBanners.length) return { cachedBanners };

        // Find the targeted banner
        const targetedBannerIndex = cachedBanners.findIndex(banner => {
          return banner.id === editPayload.id;
        });

        // Early exit in case the targeted banner cannot be found
        if (targetedBannerIndex < 0) return { cachedBanners };

        // Update the targeted banner
        const updatedBannersList = cloneDeep(cachedBanners);
        updatedBannersList[targetedBannerIndex] = {
          ...updatedBannersList[targetedBannerIndex],
          text: editPayload.text,
          hyperlink: editPayload.hyperlink,
        };

        // Update the cached data
        queryClient.setQueryData(["marketing-banners-all"], updatedBannersList);

        // Show a success notification in the UI
        toast.success("Successfully edited banner!", {
          toastId: "marketing-banners-edit",
        });

        return { cachedBanners };
      },
      onError: (error, _banner, context) => {
        toast.dismiss("marketing-banners-edit");
        queryClient.setQueryData(["marketing-banners-all"], context?.cachedBanners);
        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["marketing-banners-all"]);
      },
    },
  );
};

/** Delete the targeted marketing announcement banner */
export const useMarketingBannersDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (bannerID: number) => {
      return await fetchHandler("DELETE", `admin/banners/${bannerID}`);
    },
    {
      onMutate: (bannerID: number) => {
        // Get currently cached banners
        const cachedBanners = queryClient.getQueryData([
          "marketing-banners-all",
        ]) as BannersListResponse[];

        // Remove the targeted banner from the list
        let updatedBannersList = cloneDeep(cachedBanners);
        updatedBannersList = updatedBannersList.filter(banner => banner.id !== bannerID);

        // Update the cached data
        queryClient.setQueryData(["marketing-banners-all"], updatedBannersList);

        // Show a success notification in the UI
        toast.success("Successfully deleted banner!", {
          toastId: "marketing-banners-delete",
        });

        return { cachedBanners };
      },
      onError: (error, _banner, context) => {
        toast.dismiss("marketing-banners-delete");
        queryClient.setQueryData(["marketing-banners-all"], context?.cachedBanners);
        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["marketing-banners-all"]);
      },
    },
  );
};

/** Update the status of the targeted marketing announcement banner */
export const useMarketingBannersToggleStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (bannerID: number) => {
      return await fetchHandler("POST", `admin/banners/${bannerID}/change-status`);
    },
    {
      onMutate: (bannerID: number) => {
        // Get currently cached banners
        const cachedBanners = queryClient.getQueryData([
          "marketing-banners-all",
        ]) as BannersListResponse[];

        // Early exit in case there are no banners to be found in the cache
        if (!cachedBanners || !cachedBanners.length) return { cachedBanners };

        // Find the targeted banner
        const targetedBannerIndex = cachedBanners.findIndex(banner => {
          return banner.id === bannerID;
        });

        // Early exit in case the targeted banner cannot be found
        if (targetedBannerIndex < 0) return { cachedBanners };

        // Update the targeted banner
        const updatedBannersList = cloneDeep(cachedBanners);
        const targetedBannerStatus = updatedBannersList[targetedBannerIndex].is_active;
        updatedBannersList[targetedBannerIndex].is_active = !targetedBannerStatus;

        // Update the cached data
        queryClient.setQueryData(["marketing-banners-all"], updatedBannersList);

        // Show a success notification in the UI
        toast.success(
          `Successfully updated banner status to ${!targetedBannerStatus ? "active" : "inactive"}!`,
          {
            toastId: "marketing-banners-status-toggle",
          },
        );

        return { cachedBanners };
      },
      onError: (error, _banner, context) => {
        toast.dismiss("marketing-banners-status-toggle");
        queryClient.setQueryData(["marketing-banners-all"], context?.cachedBanners);
        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["marketing-banners-all"]);
      },
    },
  );
};

/** Marks the marketing announcement banners as closed for the logged in user  */
export const useMarketingBannersClose = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      return fetchHandler("POST", "banners/close");
    },
    {
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["user-profile"]);
      },
    },
  );
};

/** Update the order in which the marketing announcement banners are shown to the user */
export const useMarketingBannersReorder = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (banner_ids: number[]) => {
      return fetchHandler("POST", `admin/banners/order`, { banner_ids });
    },
    {
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["marketing-banners-all"]);
        queryClient.invalidateQueries(["user-profile"]);
      },
    },
  );
};
