import { DropdownItem } from "../../../components/Dropdown/interfaces";
import i18n from "../../../internationalization/i18n";
import { AssessmentStaticQuestionGroups } from "../interfaces";

export const ONLINE_APPLICATION_ASSESSMENT_WORK_ENVIRONMENT: DropdownItem[] = [
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.leadership"),
    value: 1,
    i18nKey: "leadership",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.performance_oriented"),
    value: 2,
    i18nKey: "performance_oriented",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.mentor"),
    value: 3,
    i18nKey: "mentor",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.nurturing"),
    value: 4,
    i18nKey: "nurturing",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.organized"),
    value: 5,
    i18nKey: "organized",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.self_starter"),
    value: 6,
    i18nKey: "self_starter",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.relaxed"),
    value: 7,
    i18nKey: "relaxed",
  },
  {
    text: i18n.t("sbca_assessment:form.questions.work_environment_options.structured"),
    value: 8,
    i18nKey: "structured",
  },
];

export const ONLINE_APPLICATION_ASSESSMENT_STATIC_QUESTIONS: AssessmentStaticQuestionGroups[] = [
  {
    id: "group",
    title: "The personal qualities I am most aware of in myself are...",
    questions: [
      {
        id: "a",
        text: "Gentleness & warmth",
      },
      {
        id: "b",
        text: "Strength & capability",
      },
      {
        id: "c",
        text: "Consistency & logic",
      },
    ],
    i18nKey: "personal_qualities",
  },
  {
    id: "group",
    title: "Sometimes I am afraid others see me as...",
    questions: [
      {
        id: "a",
        text: "Weak or wishy-washy",
      },
      {
        id: "b",
        text: "Dictatorial or pushy",
      },
      {
        id: "c",
        text: "Cold or indifferent",
      },
    ],
    i18nKey: "afraid_others",
  },
  {
    id: "group",
    title: "When someone expresses tender emotions to me I quite often",
    questions: [
      {
        id: "a",
        text: "Accept & delight in it",
      },
      {
        id: "b",
        text: "Feel awkward & anxious",
      },
      {
        id: "c",
        text: "I am unsure of how to respond & wish it was over",
      },
    ],
    i18nKey: "express_tender_emotions",
  },
  {
    id: "group",
    title: "When someone criticizes me it's likely that I will...",
    questions: [
      {
        id: "a",
        text: "Not want to deal with it & try calm them down",
      },
      {
        id: "b",
        text: "Defend myself & tell them they have no right to do so",
      },
      {
        id: "c",
        text: "Try to analyze what they are saying",
      },
    ],
    i18nKey: "criticizes",
  },
  {
    id: "group",
    title: "When I am among new people I generally",
    questions: [
      {
        id: "a",
        text: "Am polite but wait for them to make the first move",
      },
      {
        id: "b",
        text: "Am outgoing & take the first steps to get acquainted",
      },
      {
        id: "c",
        text: "Wait for a suitable time to introduce myself or be introduced",
      },
    ],
    i18nKey: "among_new_people",
  },
  {
    id: "group",
    title: "I am most uneasy when I think people are judging me to be...",
    questions: [
      {
        id: "a",
        text: "Harsh & uncaring",
      },
      {
        id: "b",
        text: "A phony or showoff",
      },
      {
        id: "c",
        text: "Distant or standoffish",
      },
    ],
    i18nKey: "judging",
  },
  {
    id: "group",
    title: "When I am really at odds with someone I am likely to...",
    questions: [
      {
        id: "a",
        text: "Avoid talking about the issues that separate us",
      },
      {
        id: "b",
        text: "Speak my mind & tell them exactly where I stand",
      },
      {
        id: "c",
        text: "Stand back & try to figure the situation out",
      },
    ],
    i18nKey: "odds",
  },
  {
    id: "group",
    title: "When people compliment me I tend to...",
    questions: [
      {
        id: "a",
        text: "Feel uncomfortable & hope the moment passes quickly",
      },
      {
        id: "b",
        text: "Accept the compliment in a joking way",
      },
      {
        id: "c",
        text: "Figure out whether I deserve the compliment",
      },
    ],
    i18nKey: "compliment",
  },
  {
    id: "group",
    title: "When someone is angry with me, or becomes aggressive...",
    questions: [
      {
        id: "a",
        text: "I cringe inside because I hate turmoil or getting upset",
      },
      {
        id: "b",
        text: "I am willing to react to them & see it through",
      },
      {
        id: "c",
        text: "I try not to overreact or get caught up in their anger",
      },
    ],
    i18nKey: "angry",
  },
  {
    id: "group",
    title: "When I am really down on myself I see myself as...",
    questions: [
      {
        id: "a",
        text: "Not very smart with very little to offer",
      },
      {
        id: "b",
        text: "Inadequate & incapable of doing what's needed",
      },
      {
        id: "c",
        text: "Disorganized, confused & out of control",
      },
    ],
    i18nKey: "down",
  },
  {
    id: "group",
    title: "When some issue comes between me and others I...",
    questions: [
      {
        id: "a",
        text: "Would rather forget it & get back to loving one another",
      },
      {
        id: "b",
        text: "Do not think it is good to back down",
      },
      {
        id: "c",
        text: "Reconsider the facts & try to figure out who is right",
      },
    ],
    i18nKey: "issues_between",
  },
  {
    id: "group",
    title: "I am attracted to people who...",
    questions: [
      {
        id: "a",
        text: "Are easy to get along with",
      },
      {
        id: "b",
        text: "Stand on their own two feet",
      },
      {
        id: "c",
        text: "Take time to think things through",
      },
    ],
    i18nKey: "attracted_to",
  },
  {
    id: "group",
    title: "I would describe myself as...",
    questions: [
      {
        id: "a",
        text: "Quietly involved in the background",
      },
      {
        id: "b",
        text: "Dynamically involved in the forefront",
      },
      {
        id: "c",
        text: "Rationally involved evaluating the work to be done",
      },
    ],
    i18nKey: "describe",
  },
  {
    id: "group",
    title:
      "When I have to talk to my superior about something that makes me very uncomfortable I am apt to...",
    questions: [
      {
        id: "a",
        text: "Avoid the conversation, or end it quickly",
      },
      {
        id: "b",
        text: "Defend myself and justify my actions",
      },
      {
        id: "c",
        text: "Proceed only if realistic talk is assured",
      },
    ],
    i18nKey: "talk_to_superior",
  },
  {
    id: "group",
    title: "In an important conversation I tend to...",
    questions: [
      {
        id: "a",
        text: "Do more listening than talking",
      },
      {
        id: "b",
        text: "Try to get involved in the conversation",
      },
      {
        id: "c",
        text: "Listen and talk until there is clarification & understanding",
      },
    ],
    i18nKey: "important_conversation",
  },
  {
    id: "group",
    title: "I usually settle arguments by...",
    questions: [
      {
        id: "a",
        text: "Conforming for the sake of others",
      },
      {
        id: "b",
        text: "Dominating others until my viewpoint is accepted",
      },
      {
        id: "c",
        text: "Using order & analysis to reveal the correct viewpoint",
      },
    ],
    i18nKey: "settle_arguments",
  },
  {
    id: "group",
    title: "The thing I fear losing most is...",
    questions: [
      {
        id: "a",
        text: "Respect",
      },
      {
        id: "b",
        text: "Power & influence",
      },
      {
        id: "c",
        text: "Self control & independence",
      },
    ],
    i18nKey: "fear_loosing_most",
  },
  {
    id: "group",
    title: "One of my weak points is my...",
    questions: [
      {
        id: "a",
        text: "Unwillingness to take a stand",
      },
      {
        id: "b",
        text: "Temper & arrogance",
      },
      {
        id: "c",
        text: "Lack of wanting to take a risk",
      },
    ],
    i18nKey: "weak_points",
  },
  {
    id: "group",
    title: "I feel better about myself when I am...",
    questions: [
      {
        id: "a",
        text: "Helping others",
      },
      {
        id: "b",
        text: "Leading others",
      },
      {
        id: "c",
        text: "Teaching others",
      },
    ],
    i18nKey: "about_myself",
  },
  {
    id: "group",
    title: "When others want to help me I...",
    questions: [
      {
        id: "a",
        text: "I accept the help",
      },
      {
        id: "b",
        text: "Know I would be more comfortable helping them",
      },
      {
        id: "c",
        text: "Prefer being self-sufficient",
      },
    ],
    i18nKey: "others_helping",
  },
  {
    id: "group",
    title: "I make most of my decisions...",
    questions: [
      {
        id: "a",
        text: "By trusting my intuition",
      },
      {
        id: "b",
        text: "By my gut reaction",
      },
      {
        id: "c",
        text: "By careful consideration",
      },
    ],
    i18nKey: "decisions",
  },
  {
    id: "group",
    title: "I am probably best known for...",
    questions: [
      {
        id: "a",
        text: "My regard for others",
      },
      {
        id: "b",
        text: "My strength of purpose",
      },
      {
        id: "c",
        text: "My Logical thinking",
      },
    ],
    i18nKey: "best_known",
  },
  {
    id: "group",
    title: "When I face more stress than I can handle I generally...",
    questions: [
      {
        id: "a",
        text: "Pray or seek advice",
      },
      {
        id: "b",
        text: "Get things under control myself",
      },
      {
        id: "c",
        text: "Analyze all my options",
      },
    ],
    i18nKey: "facing_stress",
  },
  {
    id: "group",
    title: "I get people to cooperate by...",
    questions: [
      {
        id: "a",
        text: "Creating an atmosphere of harmony",
      },
      {
        id: "b",
        text: "Persuasion & a willingness to direct them",
      },
      {
        id: "c",
        text: "Showing the logical steps of my plan",
      },
    ],
    i18nKey: "cooperate",
  },
  {
    id: "group",
    title: "People that know me see me as...",
    questions: [
      {
        id: "a",
        text: "Flexible, Adaptable",
      },
      {
        id: "b",
        text: "Confident, Forceful",
      },
      {
        id: "c",
        text: "Independent, Balanced",
      },
    ],
    i18nKey: "see_me_as",
  },
  {
    id: "group",
    title: "The secret of being successful with people is being seen as...",
    questions: [
      {
        id: "a",
        text: "Compassionate",
      },
      {
        id: "b",
        text: "Confident",
      },
      {
        id: "c",
        text: "Resourceful",
      },
    ],
    i18nKey: "success_secret",
  },
  {
    id: "group",
    title: "I would like to be described as...",
    questions: [
      {
        id: "a",
        text: "Sociable, Loyal",
      },
      {
        id: "b",
        text: "Direct, Competitive",
      },
      {
        id: "c",
        text: "Intelligent, Analytical",
      },
    ],
    i18nKey: "described_as",
  },
  {
    id: "group",
    title: "My weakness is to...",
    questions: [
      {
        id: "a",
        text: "Believe what people say",
      },
      {
        id: "b",
        text: "Dominate a conversation",
      },
      {
        id: "c",
        text: "Over analyze a situation",
      },
    ],
    i18nKey: "weakness",
  },
  {
    id: "group",
    title: "When I meet someone for the first time, I formulate an opinion by...",
    questions: [
      {
        id: "a",
        text: "How I feel",
      },
      {
        id: "b",
        text: "By what I see & hear",
      },
      {
        id: "c",
        text: "Analysis",
      },
    ],
    i18nKey: "first_time_meet",
  },
  {
    id: "group",
    title: "I hate being seen as being...",
    questions: [
      {
        id: "a",
        text: "A push over, Weak",
      },
      {
        id: "b",
        text: "Stubborn, Arrogant",
      },
      {
        id: "c",
        text: "Perfectionist",
      },
    ],
    i18nKey: "hate_being_seen_as",
  },
  {
    id: "group",
    title: "In a relationship I want to be...",
    questions: [
      {
        id: "a",
        text: "Needed",
      },
      {
        id: "b",
        text: "Admired",
      },
      {
        id: "c",
        text: "Respected",
      },
    ],
    i18nKey: "relationship",
  },
];

// When language is changed, update the displayed text in the UI
i18n.on("languageChanged", () => {
  // Handling of the "Work Environment" dropdown options
  ONLINE_APPLICATION_ASSESSMENT_WORK_ENVIRONMENT.forEach(workEnvironment => {
    workEnvironment.text = i18n.t(
      `sbca_assessment:form.questions.work_environment_options.${workEnvironment.i18nKey}`,
    );
  });

  // Handling of the 31 statically defeined questions
  ONLINE_APPLICATION_ASSESSMENT_STATIC_QUESTIONS.forEach(questionsGroup => {
    questionsGroup.title = i18n.t(
      `sbca_assessment:form.questions.assessment_questions.${questionsGroup.i18nKey}.title`,
    );

    questionsGroup.questions.forEach(question => {
      question.text = i18n.t(
        `sbca_assessment:form.questions.assessment_questions.${questionsGroup.i18nKey}.${question.id}`,
      );
    });
  });
});
