// Utilities & Hooks
import { useMemo } from "react";
import { useJobAdsOverviewSearch } from "../../api/JobAds/JobAds";

// Components
import Skeleton from "react-loading-skeleton";
import Card from "../Card/Card";
import JobAdsClientsTable from "./JobAdsClientsTable";
import JobAdsTable from "./JobAdsTable";

const JobAdsOverviewSearch = ({ searchedValue }: { searchedValue: string }) => {
  /*===========================
   OVERVIEW SEARCH TABLE DATA
  ============================*/
  const { data, isLoading } = useJobAdsOverviewSearch(searchedValue);

  const OVERVIEW_SEARCHED_DATA = useMemo(() => {
    if (!data || !Object.entries(data).length || isLoading)
      return {
        ads: [],
        clients: [],
      };

    return { clients: data.clients, ads: data.ads };
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div data-testid="component:job-ads-overview-search-skeleton">
          <Skeleton width="150px" height="22px" className="mb--20" />
          <Skeleton width="100%" height="350px" className="mb--20" />

          <Skeleton width="120px" height="22px" className="mb--20" />
          <Skeleton width="100%" height="350px" className="mb--20" />
        </div>
      ) : (
        <>
          <h2 className="fw--semibold ml--30 mb--20">Clients</h2>
          <Card>
            <JobAdsClientsTable
              data={OVERVIEW_SEARCHED_DATA.clients}
              shouldHandlePageRouteParameter={false}
              paginationPageSize={25}
            />
          </Card>

          <h2 className="fw--semibold ml--30 mb--20">Ads</h2>
          <Card>
            <JobAdsTable
              ads={OVERVIEW_SEARCHED_DATA.ads}
              paginationSize={25}
              isRefetching={false}
              shouldHandlePageRouteParameter={false}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default JobAdsOverviewSearch;
