import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const JobAdDetailsSkeleton = () => {
  return (
    <div className="job-ads" data-testid="component:job-ad-details-skeleton">
      <div className="job-ads-details__header">
        <div className="container">
          <Skeleton width="250px" height="25px" />
          <Skeleton width="140px" height="25px" />
        </div>
      </div>

      <div className="container py--25">
        <div className="d-flex justify-content-between align-items-center mb--30">
          <div className="job-ads-details__codes">
            <Skeleton width="105px" height="16px" />
            <Skeleton width="140px" height="16px" />
          </div>

          <div className="job-ads-details__actions">
            <Skeleton width="105px" height="35px" />
            <Skeleton width="105px" height="35px" className="ml--10" />
          </div>
        </div>

        <div className="row">
          {/* JOB AD INFO CARD */}
          <div className="col-12 col-lg-6 mb--20">
            <Card modifierClass="card--padding--xl h--100">
              <Skeleton width="130px" height="20px" className="mb--30" />

              <Skeleton width="205px" height="18px" className="mb--10" />
              <Skeleton width="100%" height="70px" className="mb--20" />

              <Skeleton width="350px" height="18px" className="mb--10" />
              <Skeleton width="100%" height="70px" />
            </Card>
          </div>

          {/* JOB AD SETTINGS CARD */}
          <div className="col-12 col-lg-6 mb--20">
            <Card modifierClass="card--padding--xl h--100">
              <div className="d-flex justify-content-between align-items-center mb--50">
                <Skeleton width="100px" height="22px" />
                <Skeleton width="170px" height="35px" />
              </div>

              {/* APPLICATION AND SBCA TYPES */}
              <div className="row mb--30">
                <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mb--20">
                  <Skeleton width="150px" height="18px" className="mb--10" />
                  <Skeleton width="240px" height="40px" />
                </div>

                <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mb--20">
                  <Skeleton width="80px" height="18px" className="mb--10" />
                  <Skeleton width="240px" height="40px" />
                </div>
              </div>

              {/* DATEPICKERS */}
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mb--20">
                  <Skeleton width="100px" height="18px" className="mb--10" />
                  <Skeleton width="240px" height="40px" />
                </div>

                <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mb--20">
                  <Skeleton width="120px" height="18px" className="mb--10" />
                  <Skeleton width="240px" height="40px" />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          {/* JOB AD INFORMATION */}
          <div className="col-12 col-lg-6">
            <Card modifierClass="card--padding--xl">
              <Skeleton width="150px" height="22px" className="mb--30" />

              <div className="d-flex align-items-center mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="120px" height="16px" className="ml--20" />
              </div>

              <div className="d-flex align-items-center mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="200px" height="16px" className="ml--20" />
              </div>

              <div className="d-flex align-items-center mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="220px" height="16px" className="ml--20" />
              </div>

              <div className="d-flex align-items-center mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="80px" height="16px" className="ml--20" />
              </div>

              <div className="d-flex align-items-start mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="200px" height="80px" className="ml--20" />
              </div>

              <div className="d-flex align-items-center mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="50px" height="16px" className="ml--20" />
              </div>

              <div className="d-flex align-items-start mb--20">
                <Skeleton width="150px" height="16px" />
                <Skeleton width="200px" height="80px" className="ml--20" />
              </div>
            </Card>
          </div>

          {/* JOB AD COMMENTS & LIVE PREVIEW */}
          <div className="col-12 col-lg-6">
            <Card modifierClass="card--padding--xl">
              <Skeleton width="100%" height="85px" className="mb--20" />

              <div className="d-flex justify-content-end">
                <Skeleton width="135px" height="35px" className="mb--0" />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobAdDetailsSkeleton;
