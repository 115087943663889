// Utilities & Hooks
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBucketsGetAll } from "../../api/Buckets/Buckets";
import { useVisibleBuckets } from "./hooks/useVisibleBuckets";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";

// Components
import { AnimatePresence } from "framer-motion";
import BucketItem from "./BucketItem";
import BucketsCreateNewModal from "./BucketsCreateNewModal";
import BucketsDropdown from "./BucketsDropdown";
import BucketsLoadingPlacholder from "./BucketsLoadingPlaceholder";
import BucketsRefetch from "./BucketsRefetch";
import BucketsEditModal from "./BucketsEditModal";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import ProductTourWrapper from "../ProductTour/ProductTourWrapper";

// Assets
import { FaPencilAlt as BucketEditIcon } from "react-icons/fa";

// Interfaces
import { BucketsProps } from "./interfaces";
import { BucketsResponseFields } from "../../api/Buckets/interfaces";
import { useShouldShowTour } from "../ProductTour/useShouldShowTour";

// Predefined System Buckets Ordering
export const BUCKETS_PREDEFINED_ORDER = {
  Current: 1,
  Favorites: 2,
  Interviewed: 3,
  "Offer Pending": 4,
  Hired: 5,
} as Record<string, number>;

const Buckets = ({ selectedBucket, handleBucketFilter, handlePaginationReset }: BucketsProps) => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  /*=========================
    VISIBLE BUCKETS
  ==========================*/
  const VISIBLE_BUCKETS_COUNT = useVisibleBuckets();

  /*=========================
    GET ALL BUCKETS
  ==========================*/
  const { data, isLoading, isFetching, refetch } = useBucketsGetAll();

  /*=========================
    HANDLE BUCKET ITEMS

    Map trough the list of available buckets,
    and extract the first 5 items to be displayed in the container,
    while the rest of them (if there are more than 5), will be displayed
    in the buckets extras dropdown menu
  ==========================*/
  const BUCKET_ITEMS = useMemo(() => {
    // Exit and return default dataset if there's no data
    if (!data || !data.length || isLoading) {
      return {
        bucketsMain: [],
        bucketsDropdown: [],
        bucketsTotalCount: 0,
      };
    }

    // Remap the data to match the predefined default bucket order & use the index for the already sorted custom buckets
    const BUCKETS_ORDERED = data
      .map((bucket, index) => {
        if (bucket.default) {
          return {
            ...bucket,
            display_order:
              bucket.name.toLowerCase() === "archived"
                ? data.length
                : BUCKETS_PREDEFINED_ORDER[bucket.name],
          };
        } else {
          return { ...bucket, display_order: index };
        }
      })
      .sort((bucketA, bucketB) => {
        return bucketA.display_order > bucketB.display_order ? 1 : -1;
      });

    // Append the 'All' bucket at the end of the sorted array
    const BUCKETS_MODIFIED = [
      ...BUCKETS_ORDERED,
      {
        display_order: 0,
        name: "All",
        description: "",
        id: 0,
      } as BucketsResponseFields,
    ];

    // Split the sorted buckets into two categories,
    // based on constraints of how many buckets should be
    // visible in the main container, while the rest of them will be
    // rendered in the buckets dropdown menu
    const BUCKETS_MAIN = BUCKETS_MODIFIED.slice(0, VISIBLE_BUCKETS_COUNT);
    const BUCKETS_DROPDOWN = BUCKETS_MODIFIED.slice(VISIBLE_BUCKETS_COUNT);

    return {
      bucketsMain: BUCKETS_MAIN,
      bucketsDropdown: BUCKETS_DROPDOWN,
      bucketsTotalCount: BUCKETS_MODIFIED.length,
    };
  }, [data, VISIBLE_BUCKETS_COUNT]);

  // If the selected bucket belongs to the dropdown menu
  const [selectionBelongsToDropdown, setSelectionBelongsToDropdown] = useState<boolean>(false);

  /*=========================
    ACTIVE BUCKET SELECTION
  ==========================*/
  const [activeBucket, setActiveBucket] = useState<string>(selectedBucket);

  const handleActiveBucket = useCallback(
    (bucketName: string, resetPagination: boolean = true) => {
      setActiveBucket(bucketName);
      setSearchParametersObject({
        ...searchParametersObject,
        bucket: bucketName,
        ...(resetPagination && { page: 1 }),
      });

      // Mark the dropdown menu if the bucket that was selected
      // is part of the buckets that are presented in the menu
      handleBucketBelongsToDropdown(bucketName);

      // Reset pagination to first page
      if (resetPagination) handlePaginationReset();
    },
    [searchParametersObject, activeBucket],
  );

  // Mark the dropdown menu if the bucket that was selected
  // is part of the buckets that are presented in the menu
  const handleBucketBelongsToDropdown = (bucketName: string) => {
    const bucketBelongsToDropdown: boolean = BUCKET_ITEMS.bucketsDropdown.some(bucket => {
      return bucket.name.toLowerCase() === bucketName.toLowerCase();
    });
    setSelectionBelongsToDropdown(bucketBelongsToDropdown);
  };

  // Update the active bucket state anytime the search parameters are updated
  useEffect(() => {
    const { bucket } = searchParametersObject;

    // Exit function if there's no bucket present in the search parameters
    if (!bucket || BUCKET_ITEMS.bucketsTotalCount === 0) return;

    // Check the list for existing bucket items that matches the one from the search parameters
    const ALL_BUCKETS = [...BUCKET_ITEMS.bucketsMain, ...BUCKET_ITEMS.bucketsDropdown];
    const existingBucket = ALL_BUCKETS.find(matchingBucket => {
      return matchingBucket.name.toLowerCase() === bucket.toLowerCase();
    });

    // If the bucket cannot be found, default to "Current"
    if (!existingBucket) {
      setActiveBucket("Current");
      setSearchParametersObject({ ...searchParametersObject, page: 1, bucket: "Current" });
      handlePaginationReset();
      return;
    }

    // If a matching bucket is found, pre-select it in the UI
    setActiveBucket(searchParametersObject.bucket);
    handleBucketBelongsToDropdown(searchParametersObject.bucket);
  }, [BUCKET_ITEMS]);

  // Trigger Applications bucket filtering callback
  useEffect(() => {
    handleBucketFilter(activeBucket);
  }, [activeBucket]);

  /*=========================
    NEW BUCKETS MODAL STATE
  ==========================*/
  const [isBucketsNewModalOpen, setIsBucketsNewModalOpen] = useState<boolean>(false);

  /*=========================
    EDIT BUCKETS MODAL STATE
  ==========================*/
  const [isBucketsEditModalOpen, setIsBucketsEditModalOpen] = useState<boolean>(false);

  const BUCKETS_EDIT_DATA = useMemo(() => {
    // Filter out the 'All' bucket and the default buckets before sending to the Buckets Edit Modal component
    let editableBuckets: BucketsResponseFields[] = [
      ...BUCKET_ITEMS.bucketsMain,
      ...BUCKET_ITEMS.bucketsDropdown,
    ];

    editableBuckets = editableBuckets
      .filter(bucket => bucket.name !== "All")
      .filter(bucket => !bucket.default);

    return editableBuckets;
  }, [BUCKET_ITEMS]);

  /*=========================
    CONTROL SHOWING THE TOUR
  ==========================*/
  const shouldShowBucketsTour = useShouldShowTour("buckets-improved-workflow");

  return (
    <div className="buckets">
      <div className="buckets__container">
        {isLoading ? (
          <BucketsLoadingPlacholder />
        ) : (
          <>
            <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
              {BUCKET_ITEMS.bucketsTotalCount > 0 ? (
                <>
                  <div className="d-flex align-items-center">
                    {BUCKET_ITEMS.bucketsMain.map(bucket => (
                      <BucketItem
                        key={bucket.id}
                        {...bucket}
                        modifierClass={
                          bucket.name.toLowerCase() === activeBucket.toLowerCase()
                            ? "buckets__item--active"
                            : ""
                        }
                        handleBucketSelection={handleActiveBucket}
                      />
                    ))}
                    {BUCKET_ITEMS.bucketsTotalCount > VISIBLE_BUCKETS_COUNT && (
                      <BucketsDropdown
                        bucketsDropdownItems={BUCKET_ITEMS.bucketsDropdown}
                        activeBucketName={activeBucket}
                        handleBucketSelection={handleActiveBucket}
                        selectionBelongsToDropdown={selectionBelongsToDropdown}
                        label={VISIBLE_BUCKETS_COUNT <= 0 ? "Buckets:" : null}
                      />
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <PermissionCheckComponentWrapper permissions={["buckets_create"]}>
                      <div
                        className="buckets__item buckets__item--new"
                        onClick={() => setIsBucketsNewModalOpen(true)}
                      >
                        New Bucket +
                      </div>
                    </PermissionCheckComponentWrapper>

                    <PermissionCheckComponentWrapper permissions={["buckets_edit"]}>
                      <>
                        {data && data.filter(bucket => !bucket.default).length ? (
                          <>
                            <span className="buckets__item-splitter" />

                            <div
                              className="buckets__item buckets__item--edit"
                              onClick={() => setIsBucketsEditModalOpen(true)}
                            >
                              Edit Bucket List
                              <BucketEditIcon className="flex-shrink-0 ml--10" />
                            </div>
                          </>
                        ) : null}
                      </>
                    </PermissionCheckComponentWrapper>
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-between w--100">
                  <BucketsRefetch refetchBuckets={refetch} />
                </div>
              )}
            </PermissionCheckComponentWrapper>
          </>
        )}
      </div>

      <AnimatePresence>
        {isBucketsNewModalOpen && (
          <BucketsCreateNewModal handleCloseModal={() => setIsBucketsNewModalOpen(false)} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isBucketsEditModalOpen && (
          <BucketsEditModal
            buckets={BUCKETS_EDIT_DATA}
            isFetching={isFetching}
            handleCloseModal={() => setIsBucketsEditModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* TOUR_FLAG[buckets-improved-workflow] */}
      {shouldShowBucketsTour ? (
        <ProductTourWrapper tourToShow={"buckets-improved-workflow"} isLoading={isLoading} />
      ) : null}
    </div>
  );
};

export default Buckets;
