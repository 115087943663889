import { useQuery } from "@tanstack/react-query";
import { GroupsResponseFieldsListNonAdmin } from "./interfaces";
import fetchHandler from "../fetchHandler";

/**
 *
 * Get the list of existing groups that
 * the non-admin users have access to
 *
 */
export function useGroupsGetAll() {
  return useQuery(["groups"], async () => {
    return (await fetchHandler("GET", "groups")) as GroupsResponseFieldsListNonAdmin;
  });
}
