import { Link, useParams } from "react-router-dom";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Assets
import FCHLogo from "../../../assets/images/fch-full-logo.png";

// Components
import OnlineApplicationFooter from "../../../components/OnlineApplications/OnlnieApplicationFooter";

// Types
import { InternationalizationLocales } from "../../../interfaces/global";

const OnlineApplicationAssessmentThankYou = () => {
  const [searchParams] = useExtractSearchParameters();
  const { locale } = useParams<{ locale: InternationalizationLocales }>();

  /*==================================
    TRANSLATIONS
  ===================================*/
  const [selectedLanguage] = useState<InternationalizationLocales>(locale || "en");
  const { t, i18n } = useTranslation(["sbca_thank_you"]);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className="online-application-thank-you">
      <div className="container">
        <Link to="/login/">
          <img
            src={FCHLogo}
            className="online-application-thank-you__logo"
            title="FirstChoice Hiring logo"
          />
        </Link>

        <h3 className="online-application-thank-you__header online-application-thank-you__header--assessment-thank-you">
          {t("title")}
        </h3>

        {!searchParams?.sbca_disabled ? (
          <p className="online-application-thank-you__paragraph">{t("sbca_disabled")}</p>
        ) : null}

        <p className="online-application-thank-you__paragraph mb--100">{t("check_for_spam")}</p>

        <OnlineApplicationFooter />
      </div>
    </div>
  );
};

export default OnlineApplicationAssessmentThankYou;
