// Hooks & Utilities
import { createColumnHelper } from "@tanstack/react-table";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { format, subDays } from "date-fns";
import { useState, useEffect, useMemo } from "react";
import { useBucketsGetForCompany } from "../../api/Buckets/Buckets";
import { useCompaniesGetSpecificPositions } from "../../api/Company/Company";
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import { matchSorter } from "match-sorter";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";
import { orderBuckets } from "../../components/Applications/Buckets/orderBuckets";
import { useReportGetInternalHiring } from "../../api/Reports/Reports";
import { useApplicaitonsGetSources } from "../../api/Applications/Applications";
import { useAdminGetAllCompaniesDropdown } from "../../api/Company/AdminCompany";
import { handleDateRangeString } from "../../utilities/strings/handleDateRangeString";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";

// Components
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import Datepicker from "../../components/Datepicker/Datepicker";
import Button from "../../components/Button/Button";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import Dropdown from "../../components/Dropdown/Dropdown";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";
import Tooltip from "../../components/Tooltip/Tooltip";

// Interfaces & Constants
import { ReportInternalHiringTableData } from "./interfaces";
import { DropdownItem } from "../../components/Dropdown/interfaces";
import {
  REPORTS_INTERNAL_HIRING_DOCUMENT_COLUMNS,
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
} from "./constants";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";
import { GrPowerReset as ResetFiltersIcon } from "react-icons/gr";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";

/**
 * This represents the ID of the company that is preselected by default
 *
 * The ID of `1135` corresponds to "FirstChoice Hiring" company
 * */
export const PRESELECTED_COMPANY_FILTER: number = 1135;

const InternalHiring = () => {
  /*==============================
    SEARCH PARAMETERS
  ===============================*/
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportInternalHiringTableData>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job ID</span>,
      size: 140,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("job_title", {
      header: () => <span>Job Title</span>,
      size: 210,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("applicant_name", {
      header: () => <span>Name</span>,
      size: 170,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/applications/${data.row.original.application_id}`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),

    COLUMN_HELPER.accessor("source", {
      header: () => <span>Source</span>,
      size: 130,
      enableSorting: true,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("resume", {
      header: () => <span>Has Resume?</span>,
      size: 120,
      enableSorting: true,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("sbca_score", {
      header: () => <span>SBCA</span>,
      size: 110,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("date_published", {
      header: () => <span>Date Published</span>,
      size: 160,
      enableSorting: true,
      cell: data => {
        return (
          <span>
            {data.getValue() !== "—" ? format(new Date(+data.getValue()), "MM/dd/yyyy") : "—"}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("date_received", {
      header: () => <span>Date Received</span>,
      size: 160,
      enableSorting: true,
      sortingFn: "datetime",
      cell: data => {
        return <span>{format(data.getValue(), "MM/dd/yyyy")}</span>;
      },
    }),
    COLUMN_HELPER.accessor("address", {
      header: () => <span>Address</span>,
      size: 140,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("bucket", {
      header: () => <span>Status</span>,
      size: 130,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("hired_date", {
      header: () => <span>Hired Date</span>,
      size: 130,
      enableSorting: true,
      cell: data => {
        return (
          <span>
            {data.getValue() !== "—" ? format(new Date(+data.getValue()), "MM/dd/yyyy") : "—"}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("days_elapsed", {
      header: () => <span>Days elapsed</span>,
      size: 130,
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: data => <span>{data.getValue()}</span>,
    }),
  ];

  /*=================================
    FETCH SOURCE DATA
  ==================================*/
  const { data: sourcesData, isLoading: sourcesLoading } = useApplicaitonsGetSources();

  // sources mapped dropdown options
  const sourceDropdownOptions: DropdownItem[] = useMemo(() => {
    if (!sourcesData || sourcesLoading || !sourcesData.length) return [];

    const mappedSources = sourcesData
      .filter(item => item.name)
      .map(item => {
        return { text: item.label, value: item.label };
      });

    return [{ text: "All Sources", value: "All Sources" }, ...mappedSources];
  }, [sourcesData]);

  /*====================================
    COMPANY DROPDOWN DATA FETCH
    =====================================*/
  const { data: companiesData, isLoading: companiesLoading } = useAdminGetAllCompaniesDropdown();

  const companiesDropdownOptions: DropdownItem[] = useMemo(() => {
    if (!companiesData || companiesLoading || !companiesData.length) return [];

    const sortedCompanies = companiesData
      .sort((clientA, clientB) => {
        return clientA.name.toLowerCase() > clientB.name.toLowerCase() ? 1 : -1;
      })
      .map(item => {
        return { text: item.name, value: item.id };
      });

    return sortedCompanies;
  }, [companiesData]);

  const [companyFilter, setCompanyFilter] = useState<number>(() =>
    +searchParametersObject.company && +searchParametersObject.company >= 1
      ? +searchParametersObject.company
      : PRESELECTED_COMPANY_FILTER,
  );
  const [companyFilterSlug, setCompanyFilterSlug] = useState<string | undefined>();

  // Check if the provided query param for company ID is of an existing company
  // Depending on that, extract the company slug OR reset to default company
  useEffect(() => {
    if (!companiesData || companiesLoading) return;

    const checkCompanyExistence = companiesData.some(company => company.id === companyFilter);

    if (!checkCompanyExistence) {
      setCompanyFilter(PRESELECTED_COMPANY_FILTER);
      delete searchParametersObject.company;
      setSearchParameters({ ...searchParametersObject, company: PRESELECTED_COMPANY_FILTER });
    }

    const companySlug = companiesData
      ? companiesData.find(company => company.id === companyFilter)?.slug
      : undefined;

    setCompanyFilterSlug(companySlug);
  }, [companyFilter, companiesData]);

  // Reset buckets & job title filters on company change
  // This is to avoid filtering by buckets/job titles from the previous company
  useEffect(() => {
    setBucketFilter("all");
    setJobTitleFilter("all");
    delete searchParametersObject.bucket;
    delete searchParametersObject.jobTitle;
    setSearchParameters({ ...searchParametersObject });
  }, [companyFilter]);

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    data: internalHiringData,
    isLoading: internalHiringLoading,
    isRefetching: internalHiringRefetching,
  } = useReportGetInternalHiring(companyFilter);

  /*====================================================
      JOB TITLES DATA
    ====================================================*/
  const { data: jobTitlesData, isLoading: jobTitlesLoading } =
    useCompaniesGetSpecificPositions(companyFilterSlug);

  const [dropdownJobTitles, setDropdownJobTitles] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!jobTitlesData || !jobTitlesData.length || jobTitlesLoading) return;

    // Alphabetically sort the "jobs" dropdown items
    const sortedJobs = jobTitlesData
      .sort((jobTitleA, jobTitleB) => {
        return jobTitleA.toLowerCase() > jobTitleB.toLowerCase() ? 1 : -1;
      })
      .map(jobTitle => {
        return { text: jobTitle, value: jobTitle };
      });

    setDropdownJobTitles([{ text: "All Job Titles", value: "all" }, ...sortedJobs]);
  }, [jobTitlesData]);

  /*======================================
    BUCKETS DATA
    ======================================*/
  const { data: bucketsData, isLoading: bucketsLoading } =
    useBucketsGetForCompany(companyFilterSlug);
  const [bucketsDropdown, setBucketsDropdown] = useState<DropdownItem[]>([]);

  useEffect(() => {
    // Exit function if there are no buckets available
    if (!bucketsData || !bucketsData.length || bucketsLoading || !internalHiringData) return;

    const ORDERED_BUCKETS: DropdownItem[] = orderBuckets(bucketsData);
    const MAPPED_BUCKETS = ORDERED_BUCKETS.map(bucket => {
      return {
        ...bucket,
        text: `${bucket.text} [${
          bucketsData.find(item => item.id === bucket.value)?.applications_count
        }]`,
      };
    });

    // Append the 'All' option at the beginning
    setBucketsDropdown([
      { text: `All Buckets [${internalHiringData.length}]`, value: "all" },
      ...MAPPED_BUCKETS,
    ]);
  }, [bucketsData, internalHiringData]);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<ReportInternalHiringTableData[]>([]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);

  /*====================================================
    RESUME STATUS DROPDOWN OPTIONS
  ====================================================*/
  const RESUME_DROPDOWN_OPTIONS: DropdownItem[] = [
    { text: "All resume statuses", value: "all" },
    { text: "With resume", value: "yes" },
    { text: "Without resume", value: "no" },
  ];

  /*====================================================
    SBCA STATUS DROPDOWN OPTIONS
  ====================================================*/
  const SBCA_DROPDOWN_OPTIONS: DropdownItem[] = [
    { text: "All SBCA statuses", value: "all" },
    { text: "With SBCA", value: "yes" },
    { text: "Without SBCA", value: "no" },
  ];

  /*=====================================
    DATE RANGE FILTERS
    =====================================*/
  const startDateQuery = searchParametersObject["start_date"];
  const endDateQuery = searchParametersObject["end_date"];

  const { pickerDates, handleStartDate, handleEndDate, setPickerDates } =
    useReportDateRangeHandling({
      initial_start_date: startDateQuery ?? format(subDays(Date.now(), 30), "MM/dd/yyyy"),
      initial_end_date: endDateQuery ?? format(Date.now(), "MM/dd/yyyy"),
    });

  // DATE PARAMS
  useEffect(() => {
    const { start_date, end_date } = pickerDates;

    setSearchParameters({
      ...searchParametersObject,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    });
  }, [pickerDates]);

  /*===========================
    FILTERING
  ===========================*/
  const [jobTitleFilter, setJobTitleFilter] = useState<string>("all");
  const [bucketFilter, setBucketFilter] = useState<number | "all">("all");
  const [resumeFilter, setResumeFilter] = useState<string>("all");
  const [sbcaFilter, setSbcaFilter] = useState<string>("all");
  const [sourceFilter, setSourceFilter] = useState<string>("All Sources");
  const [cityFilter, setCityFilter] = useState<string>("all");
  const [stateFilter, setStateFilter] = useState<string>("all");

  useEffect(() => {
    if (bucketFilter === "all" || !bucketsData || !bucketsData.length) return;

    if (bucketsData.findIndex(item => item.id === bucketFilter) < 0) {
      setBucketFilter("all");
      delete searchParametersObject.bucket;
      setSearchParameters({ ...searchParametersObject });
    }
  }, [bucketFilter, bucketsData]);

  const handleCityFilter = (city: string) => {
    setCityFilter(city);

    // Append the "city" filter route parameter
    if (city !== "all") {
      setSearchParameters({ ...searchParametersObject, city: city });
    } else {
      delete searchParametersObject.city;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleStateFilter = (state: string) => {
    setStateFilter(state);

    // Append the "state" filter route parameter
    if (state !== "all") {
      setSearchParameters({ ...searchParametersObject, state: state });
    } else {
      delete searchParametersObject.state;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleSourceFilter = (source: string) => {
    setSourceFilter(source);

    // Append the "source" filter route parameter
    if (source !== "All Sources") {
      setSearchParameters({ ...searchParametersObject, source: source });
    } else {
      delete searchParametersObject.source;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleCompaniesFilter = (company: number) => {
    setCompanyFilter(company);

    // Append the "company" filter route parameter
    setSearchParameters({ ...searchParametersObject, company: company });
  };

  const handleSbcaFilter = (presentSBCA: string) => {
    setSbcaFilter(presentSBCA);

    // Append the "sbca" filter route parameter
    if (presentSBCA !== "all") {
      setSearchParameters({ ...searchParametersObject, sbca: presentSBCA });
    } else {
      delete searchParametersObject.sbca;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleJobAdsTitleFilter = (title: string) => {
    setJobTitleFilter(title);

    // Append the "title" filter route parameter
    if (title !== "all") {
      setSearchParameters({ ...searchParametersObject, jobTitle: title });
    } else {
      delete searchParametersObject.jobTitle;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleBucketFilter = (bucket: number | "all") => {
    setBucketFilter(bucket);

    if (bucket !== "all") {
      setSearchParameters({ ...searchParametersObject, bucket: bucket });
    } else {
      delete searchParametersObject.bucket;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleResumeFilter = (presentResume: string) => {
    setResumeFilter(presentResume);

    if (presentResume !== "all") {
      setSearchParameters({ ...searchParametersObject, resume: presentResume });
    } else {
      delete searchParametersObject.resume;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  // Search Params filter preselection
  useEffect(() => {
    const { jobTitle, bucket, resume, sbca, source, company, city, state } =
      searchParametersObject as Record<
        "jobTitle" | "bucket" | "resume" | "sbca" | "source" | "company" | "city" | "state",
        string | undefined
      >;

    if (jobTitle) setJobTitleFilter(jobTitle);
    if (bucket && !isNaN(Number(bucket))) {
      setBucketFilter(Number(bucket));
    } else {
      delete searchParametersObject.bucket;
      setSearchParameters({ ...searchParametersObject });
    }
    if (resume) setResumeFilter(resume);
    if (sbca) setSbcaFilter(sbca);
    if (source) setSourceFilter(source);
    if (city) setCityFilter(city);
    if (state) setStateFilter(state);

    // If the value for the company search parameter is not a valid number, do not update the company filter and remove the parameter
    if (!company || !+company || +company < 1) {
      delete searchParametersObject.company;
      setSearchParameters({ ...searchParametersObject, company: PRESELECTED_COMPANY_FILTER });
    } else {
      setCompanyFilter(+company);
    }
  }, []);

  /*====================================================
    STATE & CITY DROPDOWN OPTIONS
  ====================================================*/
  const STATE_AND_CITY_DROPDOWN_OPTIONS = useMemo(() => {
    let API_REPORT_DATA = internalHiringData || [];

    // Filter API data by DATE RANGE so we only get the states/cities that exist within the selected date range
    if (pickerDates.start_date) {
      const selectedDate = format(new Date(pickerDates.start_date), "T");

      API_REPORT_DATA = API_REPORT_DATA.filter(
        entity => format(new Date(entity.date_received), "T") >= selectedDate,
      );
    }

    if (pickerDates.end_date) {
      const selectedDate = format(new Date(pickerDates.end_date), "T");

      API_REPORT_DATA = API_REPORT_DATA.filter(
        entity => format(new Date(entity.date_received), "T") <= selectedDate,
      );
    }

    // Step #1 - Filter out the empty states/cities
    // Step #2 - Map only the states/cities from the received data
    // Step #3 - Turn to set to remove duplicates & map to array
    // Step #4 - Map as dropdown options
    const ALL_CITIES = Array.from(
      new Set(
        API_REPORT_DATA.filter(item => item.applicant.city).map(item => {
          return item.applicant.city.toLowerCase();
        }),
      ),
    );

    const ALL_STATES = Array.from(
      new Set(
        API_REPORT_DATA.filter(item => item.applicant.state).map(item => {
          return item.applicant.state;
        }),
      ),
    );

    const CITY_OPTIONS = ALL_CITIES.map(city => {
      return { text: handleStringCapitalization(city, [" ", "-"]), value: city };
    });

    const STATE_OPTIONS = ALL_STATES.map(state => {
      return { text: state, value: state };
    });

    const DROPDOWN_OPTIONS = {
      STATE_DROPDOWN_OPTIONS: [{ text: "All States", value: "all" }, ...STATE_OPTIONS],
      CITY_DROPDOWN_OPTIONS: [{ text: "All Cities", value: "all" }, ...CITY_OPTIONS],
    };

    return DROPDOWN_OPTIONS;
  }, [internalHiringData, pickerDates]);

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "job_id", "job_title", "applicant.first_name",
    "applicant.last_name" data fields. It looks 
    for an exact match between the search input & 
    the "job_id" field value, the "job_title", 
    "applicant.first_name" & "applicant.last_name"
    fields are filtered using partial search.
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    const API_REPORT_DATA = internalHiringData || [];

    let REPORT_DATA;

    REPORT_DATA = API_REPORT_DATA.map(entity => {
      return {
        job_code: entity.job_code || "—",
        job_title: entity.job_title || "None (Standard Application)",

        applicant_name: `${entity.applicant.first_name} ${entity.applicant.last_name}` || "—",
        source: entity.source || "—",
        application_id: entity.id || "—",
        resume: entity.resume || "—",
        sbca_score: entity.sbca_score ? entity.sbca_score.toFixed(2) : "—",

        date_published: entity.ad_published_date
          ? format(new Date(entity.ad_published_date), "T")
          : "—",
        date_received: entity.date_received ? new Date(entity.date_received) : "—",
        address: `${
          !entity.applicant.city && !entity.applicant.state
            ? "—"
            : handleApplicantCityAndState(entity.applicant.city, entity.applicant.state)
        }`,
        city: entity.applicant.city,
        state: entity.applicant.state || "—",
        bucket: entity.bucket || "—",
        hired_date: entity.hired_date ? format(new Date(entity.hired_date), "T") : "—",
        days_elapsed: entity.days_elapsed || "—",
      };
    });

    if (searchedValue) {
      REPORT_DATA = matchSorter(REPORT_DATA, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "job_title" },
          { threshold: matchSorter.rankings.CONTAINS, key: "applicant_name" },
        ],
      });
    }

    // Filter data with selected BUCKETS filter
    if (bucketFilter !== "all") {
      const foundBucketName = bucketsData?.find(item => item.id === bucketFilter)?.name;

      REPORT_DATA = REPORT_DATA.filter(entity => entity.bucket === foundBucketName);
    }

    // Filter data with selected JOB TITLE filter
    if (jobTitleFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(
        entity => entity.job_title.toLowerCase() === jobTitleFilter.toLowerCase(),
      );
    }

    // Filter data by DATE RANGE
    if (pickerDates.start_date) {
      const selectedDate = format(new Date(pickerDates.start_date), "T");

      REPORT_DATA = REPORT_DATA.filter(
        entity => +format(new Date(entity.date_received), "T") >= +selectedDate,
      );
    }

    if (pickerDates.end_date) {
      const selectedDate = format(new Date(pickerDates.end_date), "T");

      REPORT_DATA = REPORT_DATA.filter(
        entity => +format(new Date(entity.date_received), "T") <= +selectedDate,
      );
    }

    if (resumeFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(entity => entity.resume === resumeFilter);
    }

    if (sbcaFilter !== "all") {
      if (sbcaFilter === "yes") {
        REPORT_DATA = REPORT_DATA.filter(entity => entity.sbca_score !== "—");
      } else {
        REPORT_DATA = REPORT_DATA.filter(entity => entity.sbca_score === "—");
      }
    }

    if (sourceFilter !== "All Sources") {
      REPORT_DATA = REPORT_DATA.filter(entity =>
        entity.source.toLowerCase().startsWith(sourceFilter.toLowerCase()),
      );
    }

    // Filter data by City
    if (cityFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(
        entity => entity.city.toLowerCase() === cityFilter.toLowerCase(),
      );
    }

    // Filter data by State
    if (stateFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(entity => entity.state === stateFilter);
    }

    return REPORT_DATA;
  }, [
    internalHiringData,
    bucketFilter,
    pickerDates,
    jobTitleFilter,
    searchedValue,
    resumeFilter,
    sbcaFilter,
    sourceFilter,
    companyFilter,
    cityFilter,
    stateFilter,
  ]);

  const EXPORT_DATA = useMemo(() => {
    if (!exportedTableData.length) return [];

    const formattedDates = exportedTableData.map(item => {
      return {
        ...item,
        date_published:
          item.date_published && item.date_published !== "—"
            ? format(new Date(+item.date_published), "MM/dd/yyyy")
            : "—",
        date_received: format(new Date(item.date_received), "MM/dd/yyyy"),
        hired_date:
          item.hired_date !== "—" ? format(new Date(+item.hired_date), "MM/dd/yyyy") : "—",
      };
    });

    return formattedDates;
  }, [exportedTableData]);

  /*==========================================
    REPORT DATE STAMP
    Generate a formatted string to indicate the
    selected date range filter.
  ==========================================*/
  const REPORT_TIMESTAMP_STRING = useMemo(() => {
    const startDate = pickerDates.start_date;
    const endDate = pickerDates.end_date;
    return handleDateRangeString(startDate, endDate);
  }, [pickerDates]);

  /*========================================
    CLEAR ALL CURRENTLY APPLIED FILTERS
  ========================================*/
  const handleClearFilters = () => {
    setBucketFilter("all");
    setJobTitleFilter("all");
    setSearchedValue("");
    setResumeFilter("all");
    setSbcaFilter("all");
    setSourceFilter("All Sources");
    setCityFilter("all");
    setStateFilter("all");

    // Reset date pickers state to initial values
    setPickerDates({
      start_date: format(subDays(Date.now(), 30), "MM/dd/yyyy"),
      end_date: format(Date.now(), "MM/dd/yyyy"),
    });

    // Clear all filters from search params except the company & newly set default dates
    setSearchParameters({ company: searchParametersObject.company || PRESELECTED_COMPANY_FILTER });
  };

  /*==========================================
    TABLE DATA FILTERING INDICATOR
    
    Display a loading indicator while the data is being filtered
    Used for UX only, as the data filtering is almost instant, so the user
    gets feedback that the data has been updated
  ==========================================*/
  const [tableFilteringIndicator, setTableFilteringIndicator] = useState(false);
  useEffect(() => {
    setTableFilteringIndicator(true);

    setTimeout(() => {
      setTableFilteringIndicator(false);
    }, 500);
  }, [REPORT_TABLE_DATA]);

  return (
    <div>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">Internal Hiring Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate={pickerDates.end_date ? new Date(pickerDates.end_date) : "none"}
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || "Select start date"}
                    dateFormat="m/d/Y"
                    isDisabled={internalHiringLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">End Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate={pickerDates.start_date ? new Date(pickerDates.start_date) : "none"}
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={internalHiringLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <>
        <div className="px--30 px--lg--10">
          {!internalHiringLoading ? (
            <>
              <Card modifierClass="card--padding--xl">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  {/* RESULTS COUNT */}
                  <div className="mr--20 mb--xl--10 mr--lg--0 w--100" style={{ maxWidth: 200 }}>
                    <h5 className="fw--semibold txt--no-wrap">
                      Total results: {internalHiringData?.length || 0}
                    </h5>
                    <h5 className="txt--no-wrap mb--lg--20">
                      Filtered Results: {REPORT_TABLE_DATA.length}
                    </h5>
                  </div>

                  {/* FILTERS */}
                  <div className="w--100 d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end align-items-start align-items-md-end align-items-lg-start">
                    <div
                      className="d-flex flex-wrap justify-content-start justify-content-md-end mb--10"
                      style={{ gridGap: "10px", maxWidth: "1300px" }}
                    >
                      {/* SEARCH FILTER INPUT */}
                      <InputFieldSearch
                        handleOnSearch={search => setSearchedValue(search)}
                        placeholder="Search Reports"
                        size="md"
                        modifierClass="w-max--100--lg"
                      />

                      {/* COMPANY FILTER */}
                      <DropdownSearchable
                        items={companiesDropdownOptions}
                        placeholder={"Companies"}
                        preselectedItemValue={companyFilter}
                        size="md"
                        handleItemSelected={company =>
                          handleCompaniesFilter(company.value as number)
                        }
                        isLoading={companiesLoading}
                        disabled={companiesLoading || !companiesData || !companiesData.length}
                      />
                      {/*  SOURCES FILTER DROPDOWN */}
                      <Dropdown
                        title="All Sources"
                        size="md"
                        items={sourceDropdownOptions}
                        handleItemSelected={(source: DropdownItem) =>
                          handleSourceFilter(source.text as string)
                        }
                        preselectedItemValue={sourceFilter}
                        isLoading={sourcesLoading}
                        disabled={sourcesLoading || !sourcesData || !sourcesData.length}
                      />
                      {/* JOB TITLE FILTER DROPDOWN */}
                      <DropdownSearchable
                        items={dropdownJobTitles}
                        placeholder={jobTitleFilter === "all" ? "All Job Titles" : jobTitleFilter}
                        preselectedItemValue={jobTitleFilter}
                        size="md"
                        handleItemSelected={job => handleJobAdsTitleFilter(job.value as string)}
                        isLoading={jobTitlesLoading}
                        disabled={
                          jobTitlesLoading || !dropdownJobTitles || !dropdownJobTitles.length
                        }
                      />

                      {/* CITY FILTER DROPDOWN */}
                      <DropdownSearchable
                        items={STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS}
                        placeholder={cityFilter === "all" ? "All Cities" : cityFilter}
                        preselectedItemValue={
                          STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS.find(
                            item => item.value === cityFilter,
                          )?.value ?? "all"
                        }
                        size="md"
                        handleItemSelected={city => handleCityFilter(city.value as string)}
                        isLoading={internalHiringLoading}
                        disabled={
                          internalHiringLoading ||
                          !internalHiringData ||
                          !STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS.length
                        }
                      />

                      {/* STATE FILTER DROPDOWN */}
                      <DropdownSearchable
                        items={STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS}
                        placeholder={stateFilter === "all" ? "All States" : stateFilter}
                        preselectedItemValue={
                          STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS.find(
                            item => item.value === stateFilter,
                          )?.value ?? "all"
                        }
                        size="md"
                        handleItemSelected={state => handleStateFilter(state.value as string)}
                        isLoading={internalHiringLoading}
                        disabled={
                          internalHiringLoading ||
                          !internalHiringData ||
                          !STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS.length
                        }
                      />

                      {/* BUCKET FILTER DROPDOWN */}
                      <DropdownSearchable
                        items={bucketsDropdown}
                        placeholder={
                          bucketFilter === "all" || !bucketsData
                            ? "All Buckets"
                            : bucketsData.find(item => item.id === bucketFilter)?.name ||
                              "All Buckets"
                        }
                        preselectedItemValue={
                          bucketsDropdown?.find(item => item.value === bucketFilter)?.value ?? "all"
                        }
                        size="md"
                        handleItemSelected={bucket => handleBucketFilter(bucket.value as number)}
                        isLoading={bucketsLoading}
                        disabled={bucketsLoading || !bucketsData || !bucketsData.length}
                      />
                      {/* RESUME FILTER DROPDOWN */}
                      <Dropdown
                        title="All resume statuses"
                        size="md"
                        items={RESUME_DROPDOWN_OPTIONS}
                        handleItemSelected={(resume: DropdownItem) =>
                          handleResumeFilter(resume.value as string)
                        }
                        preselectedItemValue={resumeFilter}
                      />
                      {/* SBCA FILTER DROPDOWN */}
                      <Dropdown
                        title="All SBCA statuses"
                        size="md"
                        items={SBCA_DROPDOWN_OPTIONS}
                        handleItemSelected={(sbca: DropdownItem) =>
                          handleSbcaFilter(sbca.value as string)
                        }
                        preselectedItemValue={sbcaFilter}
                      />
                      {/* ROWS IN TABLE */}
                      <Dropdown
                        title="Select number of rows"
                        preselectedItemValue={100}
                        size="md"
                        items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                        handleItemSelected={item => setRowsPerPage(item.value as number)}
                        modifierClass=""
                      />
                    </div>

                    <Tooltip
                      text="Reset Filters"
                      wrapperModifierClass="ml--10 ml--md--0 mb--lg--10"
                    >
                      <div
                        className="btn--action"
                        onClick={handleClearFilters}
                        style={{ padding: "11px 12px" }}
                        data-testid="action:clear-filters"
                      >
                        <ResetFiltersIcon />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Card>

              <Card>
                <h3 className="fw--semibold mb--5 px--20 pt--10">Export Data</h3>

                <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                    onClick={() =>
                      handleInitiateSpreadsheetGenerator(
                        EXPORT_DATA,
                        "internal_hiring_report",
                        REPORTS_INTERNAL_HIRING_DOCUMENT_COLUMNS,
                        "default-csv",
                      )
                    }
                    isDisabled={
                      !exportedTableData.length || !internalHiringData || tableFilteringIndicator
                    }
                  >
                    CSV
                  </Button>

                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15 txt--uppercase"
                    onClick={() =>
                      handleInitiateSpreadsheetGenerator(
                        EXPORT_DATA,
                        "internal_hiring_report",
                        REPORTS_INTERNAL_HIRING_DOCUMENT_COLUMNS,
                        "default-excel",
                        REPORT_TIMESTAMP_STRING,
                      )
                    }
                    isDisabled={
                      !exportedTableData.length || !internalHiringData || tableFilteringIndicator
                    }
                  >
                    Excel
                  </Button>

                  {!internalHiringRefetching && tableFilteringIndicator ? (
                    <div className="d-flex align-items-center ml--sm--0 ml--10">
                      <h6 className="txt--gray--darkest">Filtering table data...</h6>
                      <Loader size="xs" modifierWrapper="ml--10" />
                    </div>
                  ) : null}
                </div>

                <Table
                  data={REPORT_TABLE_DATA}
                  columns={REPORT_TABLE_COLUMNS}
                  isRefetching={internalHiringRefetching || tableFilteringIndicator}
                  paginationPageSize={rowsPerPage}
                  handleExportData={data => setExportedTableData(data)}
                  modifierClass="table-wrapper--no-shadow mb--0i"
                />
              </Card>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader size="lg" modifierWrapper="mt--40" />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default InternalHiring;
