import Skeleton from "react-loading-skeleton";

const CareerPagesSkeleton = () => {
  return (
    <div className="container py--25" data-testid="component:career-pages-skeleton-loader">
      <div className="w--100 d-flex justify-content-between align-items-center">
        <Skeleton width="125px" height="25px" />

        <div className="d-flex align-items-center">
          <Skeleton width="230px" height="15px" className="mr--10" />
          <Skeleton width="80px" height="35px" className="mr--10" />
          <Skeleton width="55px" height="35px" className="mr--10" />
          <Skeleton width="80px" height="35px" className="mr--10" />
        </div>
      </div>

      <Skeleton width="100%" height="1px" className="mb--30" />

      {/* BANNER */}
      <Skeleton width="100%" height="50px" className="mb--30" />

      {/* IMAGE #1 */}
      <div className="mb--50">
        <Skeleton width="200px" height="15px" />
        <Skeleton width="100%" height="40px" className="mb--10" />
        <Skeleton width="350px" height="200px" />
      </div>

      {/* IMAGE #2 */}
      <div className="mb--50">
        <Skeleton width="200px" height="15px" />
        <Skeleton width="100%" height="40px" className="mb--10" />
        <Skeleton width="350px" height="200px" />
      </div>

      {/* DYNAMIC FIELDS SECTION */}
      <div className="row">
        <div className="col-12 col-lg-4 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="200px" height="12px" />
          <Skeleton width="100%" height="40px" />
        </div>

        <div className="col-12 col-lg-8 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="250px" height="12px" />
          <Skeleton width="100%" height="100px" />
        </div>

        <div className="col-12 col-lg-4 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="200px" height="12px" />
          <Skeleton width="100%" height="40px" />
        </div>

        <div className="col-12 col-lg-8 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="250px" height="12px" />
          <Skeleton width="100%" height="100px" />
        </div>

        <div className="col-12 col-lg-4 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="200px" height="12px" />
          <Skeleton width="100%" height="40px" />
        </div>

        <div className="col-12 col-lg-8 mb--30">
          <Skeleton width="150px" height="15px" className="mb--10" />
          <Skeleton width="250px" height="12px" />
          <Skeleton width="100%" height="100px" />
        </div>
      </div>

      {/* IMAGE #3 */}
      <div className="mb--50">
        <Skeleton width="200px" height="15px" />
        <Skeleton width="100%" height="40px" className="mb--10" />
        <Skeleton width="350px" height="200px" />
      </div>

      {/* DYNAMIC FIELDS SECTION */}
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my--20">
          <Skeleton width="100px" height="15px" className="mb--10" />
          <Skeleton width="100%" height="40px" />
        </div>
      </div>
    </div>
  );
};

export default CareerPagesSkeleton;
