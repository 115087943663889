import { useEffect, useMemo, useState } from "react";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";

// Components
import FormInputSlider from "../Form/FormInputSlider";

// Interfaces
import { AssessmentStaticQuestionsList } from "../../pages/OnlineApplication/interfaces";
import { OnlineApplicationAssessmentGroupQuestionProps } from "./interfaces";

const OnlineApplicationAssessmentGroupQuestion = ({
  form,
  field,
  title,
  groupIndex,
  questions,
}: OnlineApplicationAssessmentGroupQuestionProps) => {
  /*===========================
    HANDLE ERRORS
  ============================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===========================
    CHECK THE SUM OF THE
    GROUP QUESTIONS
  ============================*/
  const [groupState, setGroupState] = useState<"good" | "bad" | "default">("default");
  const targetedAField = form.values[`a${groupIndex}`];
  const targetedBField = form.values[`b${groupIndex}`];
  const targetedCField = form.values[`c${groupIndex}`];

  const SUM_OF_FIELDS = useMemo(() => {
    const sum: number = targetedAField + targetedBField + targetedCField;

    // Group status modifier based on the sum of its fields
    switch (true) {
      case sum === 10:
        setGroupState("good");
        break;
      case sum > 10:
        setGroupState("bad");
        break;
      default:
        setGroupState("default");
    }

    return sum;
  }, [targetedAField, targetedBField, targetedCField]);

  // Trigger form field update after calculating the sum of the fields in the group
  useEffect(() => {
    form.setFieldValue(field.name, SUM_OF_FIELDS);
  }, [SUM_OF_FIELDS]);

  /*================================
    INTERNATIONALIZATION
  =================================*/
  const { t } = useTranslation();

  return (
    <div className="online-application-assessment__group">
      <h5 className="txt--left">
        {groupIndex}. {title}
      </h5>
      {questions.map((question: AssessmentStaticQuestionsList, index: number) => (
        <div
          key={`${question.id}${index + 1}`}
          className={`online-application-assessment__question-box ${
            index % 2
              ? "online-application-assessment__question-box--even"
              : "online-application-assessment__question-box--odd"
          }`}
        >
          <div className="d-flex-align-items-center">
            <p>
              [ {`${question.id.toUpperCase()}${groupIndex}`} ] {question.text}
            </p>
          </div>

          <Field
            component={FormInputSlider}
            id={`${question.id}${groupIndex}`}
            name={`${question.id}${groupIndex}`}
            maxValue={8}
            modifierClass={`slider--sum-${groupState}`}
            aria-controls={groupState}
          />
        </div>
      ))}

      {errors && touched ? <p className="input__error">{t(errors)}</p> : null}

      <hr />
    </div>
  );
};

export default OnlineApplicationAssessmentGroupQuestion;
