// Utilities
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";

import fetchHandler from "../fetchHandler";

// Interfaces
import {
  ArticleDetailsResponseFields,
  ArticlesNotificationsResponseFields,
  ArticlesResponseFields,
} from "./interfaces";
import { useNavigate } from "react-router-dom";

/**
 *
 * Get the list of existing articles from the API
 *
 */
export function useArticlesGet() {
  return useQuery(
    ["articles"],
    async () => {
      return (await fetchHandler("GET", "articles")) as ArticlesResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Get the details of a specific article using it's ID
 * @param id The ID of the targeted article
 *
 */
export function useArticlesGetSpecific(id: string) {
  const queryClient = useQueryClient();

  return useQuery(
    ["article", id],
    async () => {
      return (await fetchHandler("GET", `articles/${id}`)) as ArticleDetailsResponseFields;
    },
    {
      onError: error => toast.error(`Article ${handleErrorMessage(error)}`),
      onSuccess: () => {
        queryClient.invalidateQueries(["articles-notifications"]);
      },
    },
  );
}

/**
 *
 * Get the existing notifications for all of the unread Articles
 *
 */
export function useArticlesNotificationsGet() {
  return useQuery(
    ["articles-notifications"],
    async () => {
      return (await fetchHandler(
        "GET",
        "articles/notifications",
      )) as ArticlesNotificationsResponseFields;
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Mark a specific article notification as read, and
 * redirect the user to that articles' details page
 *
 */
export function useArticlesMarkNotificationAsRead() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(async (id: number) => {
    const cachedNotifications = queryClient.getQueryData([
      "articles-notifications",
    ]) as ArticlesNotificationsResponseFields;

    // Redirect the user to the article page corresponding to the clicked notification
    navigate(`/articles/${id}/`);

    // Safeguard to prevent decrementing unread notifications below 0
    if (cachedNotifications.unread_notifications === 0) return;

    // Check if the clicked notification is "unread" prior to the action
    const clickedNotification = cachedNotifications.notifications.find(notification => {
      return notification.id === id;
    });

    // If no matching notification was found in the cached data with the clicked one,
    // or if it's already marked as "read", exit the function preventing any updates
    if (!clickedNotification || clickedNotification.read) return;

    queryClient.setQueryData<ArticlesNotificationsResponseFields>(["articles-notifications"], {
      unread_notifications: --cachedNotifications.unread_notifications,
      notifications: cachedNotifications.notifications.map(notification => {
        if (notification.id === id) {
          return { ...notification, read: true };
        } else {
          return notification;
        }
      }),
    });
  });
}

/**
 *
 * Marks all article notifications as read
 *
 */
export function useArticlesNotificationsMarkAllAsRead() {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      return await fetchHandler("POST", "articles/notifications/mark-all-as-read");
    },
    {
      onMutate: () => {
        const cachedArticlesNotifications = queryClient.getQueryData([
          "articles-notifications",
        ]) as ArticlesNotificationsResponseFields;

        // Update the state of all notifications to be marked as 'read'
        queryClient.setQueryData(["articles-notifications"], {
          unread_notifications: 0,
          notifications: cachedArticlesNotifications.notifications.map(notification => ({
            ...notification,
            read: true,
          })),
        });

        // Display toast notification in the ui
        toast.success("All notifications marked as read!", {
          toastId: "articles-mark-all-as-read",
        });

        return { cachedArticlesNotifications };
      },
      onError: (error, _variables, context) => {
        // Dismiss success notification from the UI first
        toast.dismiss("articles-mark-all-as-read");

        queryClient.setQueryData(["articles-notifications"], context?.cachedArticlesNotifications);
        return error;
      },
    },
  );
}
