import * as XLSX from "xlsx-js-style";
import { DocumentExportColumnDetails } from "../../pages/Reports/interfaces";

export const SummaryReportExcelTemplate = (
  data: any[],
  fileName: string,
  columns: DocumentExportColumnDetails[],
  dataDate?: string,
) => {
  if (import.meta.env.MODE === "test") return;

  // Format columns according to supplied 'columns' prop
  const formattedColumns = data.map((entity: any) => {
    const obj = {};

    columns.forEach((element: DocumentExportColumnDetails) => {
      Object.assign(obj, { [element.text]: entity[element.field_name] });
    });

    return obj;
  });

  /*
    ===================================================
    Excel WorkBook & WorkSheet initiialization

    Start by generating a new excel file & converting
    the JSON data into sheet data object.
    ====================================================*/
  const workBook = XLSX.utils.book_new();

  const workSheet = XLSX.utils.json_to_sheet([]);

  if (dataDate) {
    XLSX.utils.sheet_add_aoa(workSheet, [[dataDate]], { origin: 0 });

    // Bolden the "data date" first row
    const dataDateCellAddress = XLSX.utils.encode_cell({ r: 0, c: 0 });
    if (!workSheet[dataDateCellAddress].s) {
      workSheet[dataDateCellAddress].s = { font: { bold: true } };
    } else {
      workSheet[dataDateCellAddress].s.font = { bold: true };
    }
  }

  // Append the data after the optional 'data date' row
  XLSX.utils.sheet_add_json(workSheet, formattedColumns, {
    origin: dataDate ? 1 : 0,
  });

  // Header Columns Style
  const headerStyle = {
    font: { bold: true },
    alignment: { horizontal: "center", wrapText: true },
  };

  // Primary Rows Style
  const primaryRowStyle = {
    font: { bold: true },
  };

  // Currency Formatting Style
  const currencyStyle = {
    numFmt: "$#,##0.00",
  };

  // Apply header style
  const headerRow = dataDate ? 1 : 0;
  const headerKeys = columns.map(col => col.text);

  headerKeys.forEach((key, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: index });
    if (!workSheet[cellAddress]) {
      workSheet[cellAddress] = { v: key };
    }
    workSheet[cellAddress].s = headerStyle;
  });

  // Apply style to rows where is_primary is true
  formattedColumns.forEach((_, rowIndex) => {
    if (data[rowIndex].is_primary) {
      headerKeys.forEach((_, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: headerRow + 1 + rowIndex, c: colIndex });
        if (workSheet[cellAddress]) {
          if (!workSheet[cellAddress].s) {
            workSheet[cellAddress].s = primaryRowStyle;
          } else {
            workSheet[cellAddress].s.font = primaryRowStyle.font;
          }
        }
      });
    }
  });

  // Select the 4 last columns and format each contained data cell to currency formatting
  const toCurrency = headerKeys.slice(-4);
  formattedColumns.forEach((_, rowIndex) => {
    headerKeys.forEach((headerKey, colIndex) => {
      if (toCurrency.includes(headerKey)) {
        const cellAddress = XLSX.utils.encode_cell({ r: headerRow + 1 + rowIndex, c: colIndex });
        if (!workSheet[cellAddress].s) {
          workSheet[cellAddress].s = currencyStyle;
        } else {
          workSheet[cellAddress].s = { ...currencyStyle, ...primaryRowStyle };
        }
      }
    });
  });

  // Set column widths
  const columnWidths = columns.map(dataField => ({ wpx: dataField.width_excel }));
  workSheet["!cols"] = columnWidths;

  // Append sheet to workbook and generate file
  XLSX.utils.book_append_sheet(workBook, workSheet, "Summary Report");

  return XLSX.writeFile(workBook, `${fileName}.xlsx`);
};
