import { PDFWorkerInitiatorProps } from "../../workers/interfaces";
import handleDownloadBlob from "./handleDownloadBlob";

/**
 * Utility function for initializing a new PDF generation worker
 *
 * @param columns - Array of structured column details used in the table generation
 * @param data - Table data matching the received columns schema
 * @param documentTitle - Used as a header in each page in the document
 * @param documentSubTitle - Shown below the header in each page
 * @param template - Controls which PDF template is used
 * @param setPDFLoading - Loading state setter, used to update external loading state
 * @param fileName - Downloaded PDF document file name
 */
const handleInitiatePDFWorker = ({
  columns,
  data,
  documentTitle,
  documentSubTitle,
  template,
  setPDFLoading,
  fileName,
}: PDFWorkerInitiatorProps) => {
  if (import.meta.env.MODE === "test") return;

  const localWorker = new Worker(new URL("../../workers/pdf.worker.ts", import.meta.url), {
    type: "module",
  });
  setPDFLoading(true);

  localWorker.postMessage({ data, columns, documentTitle, documentSubTitle, template });

  localWorker.onmessage = (event: MessageEvent<{ blob: Blob }>) => {
    // Update the PDF loading state
    setPDFLoading(false);

    // Initiate file download from the received blob
    handleDownloadBlob(event.data.blob, `${fileName}.pdf`);

    // Terminate the worker instance
    localWorker.terminate();
  };
};

export default handleInitiatePDFWorker;
