// Hooks
import React, { useEffect, useRef, useState } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import useDebounce from "../../hooks/useDebounce";

// Interfaces
import { InputFieldSearchProps } from "./interfaces";

// Assets
import { BiSearchAlt as SearchIcon } from "react-icons/bi";

const InputFieldSearch: React.FC<InputFieldSearchProps> = ({
  modifierClass = "",
  placeholder,
  handleOnSearch,
  predefinedSearchValue = "",
  size = "full",
  disabled = false,
}) => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  /*=================================
    HANDLE SEARCHED VALUES
  =================================*/
  const [searchedValue, setSearchedValue] = useState<string>(() => {
    return searchParametersObject.search || predefinedSearchValue;
  });
  const [previousSearchedValue, setPreviousSearchedValue] = useState<string>("");
  const debouncedSearch: string = useDebounce(searchedValue);

  const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    setSearchedValue(event.target.value);
  };

  // Anytime the debounced searched value is updated,
  // trigger the received handler for searching the data
  useEffect(() => {
    // Set the search parameters with the latest searched value
    if (debouncedSearch) {
      setSearchParametersObject({
        ...searchParametersObject,
        search: debouncedSearch,

        // Reset pagination parameter (if it was previously present)
        ...(searchParametersObject.page && { page: 1 }),
      });
    } else {
      delete searchParametersObject.search;
      setSearchParametersObject(searchParametersObject);
    }

    // Trim the searched value before triggering the callback
    const trimmedSearch: string = debouncedSearch.trim();

    // Trigger received callback
    handleOnSearch(trimmedSearch);
  }, [debouncedSearch]);

  /*=================================
    CLEAR SEARCHED VALUE ON 
    'SEARCH' URL PARAMETER CHANGE
  =================================*/
  useEffect(() => {
    const { search } = searchParametersObject;

    // If a 'search' URL parameter is present, update the previously searched state
    if (search) setPreviousSearchedValue(search);

    // If a 'search' URL parameter is not present, but we have a previously searched value in the input field, clear it
    if (!search && previousSearchedValue) {
      setSearchedValue("");
      setPreviousSearchedValue("");
    }
  }, [searchParametersObject]);

  /*=================================
    FOCUS ON INPUT FIELD WHEN
    CLICKED ON SEARCH ICON
  =================================*/
  const searchRef = useRef<HTMLInputElement>(null);
  const handleFocusOnInput = () => {
    if (!searchRef.current || disabled) return;
    searchRef.current.focus();
  };

  return (
    <div className={`input input-with-elements input--${size} ${modifierClass}`}>
      <div className="input__wrapper">
        <SearchIcon
          className="input-with-elements__element input-with-elements__element--left"
          onClick={handleFocusOnInput}
          data-testid="component-input-field-search:icon"
        />
        <input
          ref={searchRef}
          value={searchedValue}
          placeholder={placeholder}
          className="input__field"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSearchValue(event)}
          onBlur={event => setSearchedValue(event.target.value.trim())}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export default InputFieldSearch;
