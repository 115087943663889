export function handleConvertSBCARatingToClassname(sbca_rating: number | null): string {
  // Exit function and return default value if there's no valid SBCA rating
  if (sbca_rating == null) return "none";

  /*
    NO Rating -> Blue
    0 - 4.50 -> Green 
    4.51 - 6.50 -> Yellow 
    6.51 - 10 -> Red 
  */
  switch (true) {
    case sbca_rating <= 4.5:
      return "green";
    case sbca_rating >= 4.51 && sbca_rating <= 6.5:
      return "yellow";
    case sbca_rating >= 6.51 && sbca_rating <= 10:
      return "red";
    default:
      return "none";
  }
}
