import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";

// Interfaces
import { JobAdLibrariesResponseFields, JobAdSpecificLibraryResponseFields } from "./interfaces";

/**
 *
 * Get all existing Job Ad Libraries
 *
 */
export const useJobAdsGetAllLibraries = (companyId: number) => {
  return useQuery(
    ["job-ads-all-libraries", companyId],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/job-ad-library?company_id=${companyId}`,
      )) as JobAdLibrariesResponseFields[];
    },
    { enabled: !!companyId },
  );
};

/**
 *
 * Get the details for a specific Job Ad Library
 *
 * @param adId The ID of the Job Ad that we're editing
 *
 */
export const useJobAdsGetSpecificAdLibrary = (adId: number | null) => {
  return useQuery(
    ["job-ads-specific-ad-library", adId],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/job-ad-library/${adId}`,
      )) as JobAdSpecificLibraryResponseFields;
    },
    {
      // We disable the query by default, so it can be triggered manually on function call
      enabled: false,
      keepPreviousData: true,
    },
  );
};
