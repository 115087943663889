// Hooks
import { useRollbarPerson } from "@rollbar/react";
import useMetaViewportTag from "./hooks/useMetaViewportTag";
import { usePageTracking } from "./hooks/usePageTracking";

// Toastify package
import { ToastContainer } from "react-toastify";

// Context Providers
import { useAuth } from "./providers/auth-context";

// Global CSS for third-party packages
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Authentication tokens handling
import { useQuery } from "@tanstack/react-query";
import { tokenRefresher } from "./api/tokenRefresher";
import { Navigate, Route, Routes } from "react-router-dom";

// Components
import ModalEnableCookies from "./components/Modal/ModalEnableCookies";
import PermissionCheckPageWrapper from "./components/Wrappers/PermissonCheckPageWrapper";

// Routes
import { ROUTES } from "./routes";

// Interfaces & Constants
import { ApplicationRouteProps } from "./routes/interfaces";

function App() {
  const { user, isAuthenticated } = useAuth();

  // Send a request to the server to refresh the access token
  // prettier-ignore
  useQuery(["access-token"], () => {
    // Do not send a request if the user is not logged in
    if (!isAuthenticated) return { access_token: '', refresh_token: '' };
    
    // Call the refresher function to get new tokens
    return tokenRefresher();

    // Trigger token refresher function every 10 seconds
  }, { refetchInterval: 1000 * 10, retry: 0 });

  // Use currently logged in user's details for better error information on Rollbar
  useRollbarPerson(user);

  // Adds 'maximum-scale' to the viewport meta tag to prevent auto-zoom on focus on iOS
  useMetaViewportTag();

  // Initialize google analytics session
  usePageTracking();

  return (
    <>
      <Routes>
        {ROUTES.filter((route: ApplicationRouteProps) => {
          return isAuthenticated ? route.type !== "auth" : route.type !== "protected";
        }).map((route: ApplicationRouteProps) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PermissionCheckPageWrapper
                  routePermissions={route.permissions}
                  requireAllPermissions={route.requireAllPermissions}
                >
                  <>{route.element}</>
                </PermissionCheckPageWrapper>
              }
            />
          );
        })}

        <Route
          path="*"
          element={
            <Navigate
              to={isAuthenticated ? "/applications/" : "/login/"}
              state={{ redirectTo: location.pathname }}
            />
          }
        />
      </Routes>

      <ModalEnableCookies />
      <ToastContainer
        newestOnTop={true}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
        limit={3}
        autoClose={3000}
      />
    </>
  );
}

export default App;
