// Utilities & Hooks
import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";

// Interfaces
import { TimezonesResponseFields } from "./interfaces";

/**
 *
 * Get the list of Timezones from the API with
 * the current GMT hour offset values
 *
 */
export function useTimezonesGet() {
  return useQuery(["timezones"], async () => {
    return (await fetchHandler("GET", "timezones")) as TimezonesResponseFields;
  });
}
