import Loader from "../Loader/Loader";
import { ButtonProps } from "./interfaces";

const Button = ({
  type = "submit",
  isLoading = false,
  modifierClass = "",
  loaderPositioning = "right",
  isDisabled = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`btn ${modifierClass}`}
      disabled={isLoading || isDisabled}
      {...props}
    >
      <div className={`btn__content btn__content--${loaderPositioning}`}>
        <>
          {props.children}

          {isLoading && <Loader size="sm" />}
        </>
      </div>
    </button>
  );
};

export default Button;
