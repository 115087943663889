// Forimk
import { Field } from "formik";

// Interfaces
import { GeneralSectionProps } from "./interfaces";

// Components
import FormInput from "../../Form/FormInput";
import FormTextarea from "../../Form/FormTextarea";
import FormUpload from "../../Form/FormUpload";

const GeneralSection: React.FC<GeneralSectionProps> = ({ apiData, formValues, setFieldValue }) => {
  return (
    <>
      {/* ABOUT US FEATURE IMAGE SUB-SECTION */}
      <h5 className="fw--semibold mb--5">
        About Us Feature Image
        <em className="txt--sm fw--medium txt--light-gray ml--5">
          (Think of this like a cover image for your 'About Us' page)
        </em>
      </h5>

      <Field
        component={FormUpload}
        id="about_us_feature_image"
        name="about_us_feature_image"
        buttonText="Choose Image"
        placeholder="Upload About Us Feature Image"
        accept=".png, .jpg, .jpeg, .gif"
        thumbnailPreview={apiData?.about_us_feature_image ?? ""}
        hasConfirmationForClearAction
        info={
          <>
            <span className="input-upload__info--info">
              Accepted file types: gif, jpg, jpeg, png.{" "}
            </span>
            Recommended image width: 1080px.
            <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
          </>
        }
      />

      {/* CAREERS FEATURE IMAGE SUB-SECTION */}
      <h5 className="fw--semibold mb--5">
        Careers Feature Image
        <em className="txt--sm fw--medium txt--light-gray ml--5">
          (Think of this like a cover image for your 'Careers' page)
        </em>
      </h5>

      <Field
        component={FormUpload}
        id="careers_feature_image"
        name="careers_feature_image"
        buttonText="Choose Image"
        placeholder="Upload Careers Feature Image"
        accept=".png, .jpg, .jpeg, .gif"
        thumbnailPreview={apiData?.careers_feature_image ?? ""}
        hasConfirmationForClearAction
        info={
          <>
            <span className="input-upload__info--info">
              Accepted file types: gif, jpg, jpeg, png.{" "}
            </span>
            Recommended image width: 1080px.
            <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
          </>
        }
      />

      {/* SUB HEADER SUB-SECTION */}
      <h5 className="fw--semibold mb--5">
        Sub-Header text
        <em className="txt--sm fw--medium txt--light-gray ml--5">
          (This will go under your 'About...' headline and should say something about your business
          and it's employees)
        </em>
      </h5>

      <Field
        component={FormTextarea}
        id="sub_header"
        name="sub_header"
        modifierClass="mb--20"
        rows="6"
      />

      {/* ABOUT US SUB-SECTION */}
      <h5 className="fw--semibold mb--5">
        About Us
        <em className="txt--sm fw--medium txt--light-gray ml--5">
          (Provide a quick summary about your company environment and what it’s like to work there.
          Remember, applicants will be reading this so make it inviting)
        </em>
      </h5>

      <Field
        component={FormTextarea}
        id="about_us"
        name="about_us"
        modifierClass="mb--20"
        rows="6"
      />

      {/* EMBED VIDEO SUB-SECTION */}
      <h5 className="fw--semibold mb--5">Embed Video</h5>
      <h6 className="txt--light-gray fw--medium mb--10">
        Include an image or Video for Applicants to Get a Feel for Your Company
      </h6>

      <Field
        component={FormInput}
        id="embed_video"
        name="embed_video"
        placeholder="Valid YouTube URL Only"
        modifierClass="mb--30"
        disabled={formValues.team_image || formValues.meta.team_image_url}
      />

      {/* TEAM PHOTO SUB-SECTION */}
      <h5 className="fw--semibold mb--5">
        Team Photo
        <em className="txt--sm fw--medium txt--light-gray ml--5">
          (This can also be an image that speaks to your company culture)
        </em>
      </h5>

      <Field
        component={FormUpload}
        id="team_image"
        name="team_image"
        buttonText="Choose Image"
        placeholder="Upload Team Photo Image"
        accept=".png, .jpg, .jpeg, .gif"
        thumbnailPreview={apiData?.team_image ?? ""}
        hasConfirmationForClearAction
        shouldAllowPreivewClearance
        disabled={formValues.embed_video}
        previewClearanceCallback={() => setFieldValue("meta.team_image_url", "")}
        data-testid="component:career-pages-general-section-team-image-upload"
        info={
          <>
            <span className="input-upload__info--info">
              Accepted file types: gif, jpg, jpeg, png.{" "}
            </span>
            <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
          </>
        }
      />
    </>
  );
};

export default GeneralSection;
