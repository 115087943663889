// Components
import Tooltip from "../Tooltip/Tooltip";

// Interfaces
import { BucketsRefetchProps } from "./interfaces";

// Assets
import { AiOutlineReload as BucketsRefetchIcon } from "react-icons/ai";

const BucketsRefetch = ({ refetchBuckets }: BucketsRefetchProps) => {
  return (
    <div className="buckets__refetch">
      <div>
        <h5 className="fw--semibold">No Buckets found.</h5>
        <p className="m--0">Try refetching the list</p>
      </div>

      <div
        className="buckets__refetch__icon"
        onClick={() => refetchBuckets()}
        style={{ fontSize: 20 }}
        data-testid="buckets:button-refetch"
      >
        <Tooltip text="Refetch">
          <BucketsRefetchIcon />
        </Tooltip>
      </div>
    </div>
  );
};

export default BucketsRefetch;
