import { debounce } from "lodash-es";
import { useEffect, useState } from "react";

/**
 *
 * Custom hook used for extracting the device's inner height after any
 * changes have happend to the device's viewport.
 *
 * This is useful for situations where the available height of the device
 * is changed due to viewport resizing, such as opening / closing the virtual keyboard
 * that shows up on mobile devices when clicked on an input / textarea fields.
 *
 * @param delay Debouncing delay for the viewport's resize event handler. Defaults to `100ms`.
 *
 * @returns The current device's `visualViewport height`.
 *
 */
export function useVisualViewportResize(delay: number = 100): number {
  const [viewportHeight, setViewportHeight] = useState<number>(
    visualViewport?.height || window.innerHeight,
  );

  useEffect(() => {
    // If visual viewport is not supported, exit function
    if (!visualViewport) return;

    const handleViewportResize = (event: any) => setViewportHeight(event.target?.height);
    const debouncedViewportResize = debounce(handleViewportResize, delay);

    // Listen to any changes in the device's viewport
    visualViewport.addEventListener("resize", debouncedViewportResize);

    // Remove event listeners when unmounted
    return () => {
      visualViewport?.removeEventListener("resize", debouncedViewportResize);
    };
  }, [delay]);

  return viewportHeight;
}
