import { useEffect, useState } from "react";
import { format, parseJSON } from "date-fns";
import { useApplicationUpdateHiredDate } from "../../api/Applications/Applications";
import Modal from "../Modal/Modal";
import Datepicker from "../Datepicker/Datepicker";
import Button from "../Button/Button";
import useErrorReporting from "../../hooks/useErrorReporting";
import Tooltip from "../Tooltip/Tooltip";
import { ApplicationDateSectionProps } from "./interfaces";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

const ApplicationDateSection = ({
  applicant_name,
  application_id,
  applicant_hired_date,
  applicant_bucket,
  active_bucket,
  registration_date,
}: ApplicationDateSectionProps) => {
  // Hiring date update modal
  const [showHiredDateModal, setShowHiredDateModal] = useState(false);

  // Hiring date inner state based on the applicants hiring date (if present)
  const [hiredDate, setHiredDate] = useState<Record<"date", string | null>>({
    date: applicant_hired_date || null,
  });

  useEffect(() => {
    setHiredDate({ date: applicant_hired_date });
  }, [applicant_hired_date]);

  // Hiring date selection that will be sent in the mutation request
  const handleDatePick = (date: Date[]) => {
    setHiredDate({
      date: format(new Date(date[0]), "MM/dd/yyyy"),
    });
  };

  /*==========================================
    UPDATE HIRED DATE
  =======================================*/
  const handleHiredDate = useApplicationUpdateHiredDate();
  const errorReporting = useErrorReporting();

  const handleUpdateHiredDate = async () => {
    if (!hiredDate.date) return;

    try {
      setShowHiredDateModal(false);
      await handleHiredDate.mutateAsync({
        applicationId: application_id,
        newDate: hiredDate.date,
        actionType: applicant_hired_date ? "update" : "add",
      });
    } catch (error) {
      errorReporting(
        `Failed ${applicant_hired_date ? "updating" : "adding"} application hired date.`,
        error,
        {
          application_id: application_id,
          new_hired_date: hiredDate.date,
        },
      );
    }
  };

  /*==========================================
    HANDLING OF DATE SECTION IN HIRED BUCKET
  =======================================*/
  const [showHiredDate, setShowHiredDate] = useState(false);
  const [showAddHiredDate, setShowAddHiredDate] = useState(false);

  useEffect(() => {
    if (!active_bucket || active_bucket !== "Hired") {
      setShowAddHiredDate(false);
      setShowHiredDate(false);
      return;
    }

    if (applicant_hired_date) {
      setShowHiredDate(true);
      setShowAddHiredDate(false);
    } else {
      setShowHiredDate(false);
      setShowAddHiredDate(true);
    }
  }, [applicant_hired_date, active_bucket]);

  /*=================================
    CONTROL DISPLAYING OF 
    HIRED DATE MODAL
  ==================================*/
  const hasHiredDatePermission: boolean = handlePermissionCheck(["applicant_hired_date_edit"]);

  const handleOpenHiredDateModal = () => {
    if (!hasHiredDatePermission) return;
    setShowHiredDateModal(true);
  };

  return (
    <>
      {/* ADD/EDIT MODAL */}
      {showHiredDateModal ? (
        <Modal
          title={
            applicant_hired_date ? "Edit application hired date" : "Add application hired date"
          }
          text=""
          handleCloseModal={() => setShowHiredDateModal(false)}
          modifierClass="modal--md modal--fixated"
          testID="modal-hired-date"
        >
          {applicant_hired_date ? (
            <p>
              Update hiring date for <strong>{applicant_name}</strong>
            </p>
          ) : (
            <p>
              Add hiring date for <strong>{applicant_name}</strong>
            </p>
          )}

          <div className="d-flex justify-content-center mb--20">
            <Datepicker
              preselectedDate={hiredDate.date ? new Date(hiredDate.date) : undefined}
              handleSelectedDate={date => handleDatePick(date)}
              minDate={registration_date ? new Date(registration_date) : "none"}
              maxDate="today"
              enableTime={false}
              modifierClass="datepicker--full mb--0i"
              placeholder={hiredDate.date || "Select hired date"}
              dateFormat="m/d/Y"
            />
          </div>

          <div className="modal__actions">
            <Button
              className="btn btn--fluid btn--secondary"
              onClick={() => setShowHiredDateModal(false)}
            >
              Cancel
            </Button>

            <Button
              className="btn txt--capitalize btn--fluid btn--primary"
              isLoading={handleHiredDate.isLoading}
              isDisabled={!hiredDate.date}
              onClick={() => handleUpdateHiredDate()}
            >
              {applicant_hired_date ? "Update" : "Add"}
            </Button>
          </div>
        </Modal>
      ) : null}

      {/* DEFAULT APPLICATION DATE */}
      {!showHiredDate && !showAddHiredDate && active_bucket ? (
        <Tooltip
          text={active_bucket === "All" && applicant_hired_date ? "Hired Date" : "Application Date"}
          size="md"
        >
          {active_bucket === "All" && applicant_hired_date ? (
            <p className="txt--xs line-height--1 ml--5 mb--0">
              {format(new Date(applicant_hired_date), "MM/dd/yyyy")}
            </p>
          ) : (
            <p className="txt--xs line-height--1 ml--5 mb--0">
              {format(parseJSON(registration_date!), "MM/dd/yyyy")}
            </p>
          )}
        </Tooltip>
      ) : null}

      {/* APPLICATION DETAILS PAGE */}
      {!active_bucket ? (
        <>
          {/* Details page, hired applicant */}
          <ExistingHiredDate
            hiring_date={applicant_hired_date || ""}
            registration_date={registration_date}
            hasHiredDatePermission={hasHiredDatePermission}
            handleOpenHiredDateModal={handleOpenHiredDateModal}
          />

          {/* Application Details page applicant thats in "Hired" bucket without a hiring date */}
          {applicant_bucket === "Hired" && !applicant_hired_date ? (
            <AddHiredDate
              registration_date={registration_date}
              handleOpenHiredDateModal={handleOpenHiredDateModal}
              location="single-details-page"
            />
          ) : null}
        </>
      ) : null}

      {/* HIRED BUCKET -> DATE/ADD DATE */}
      {showHiredDate && applicant_hired_date ? (
        <ExistingHiredDate
          hiring_date={applicant_hired_date}
          registration_date={registration_date}
          hasHiredDatePermission={hasHiredDatePermission}
          handleOpenHiredDateModal={handleOpenHiredDateModal}
        />
      ) : null}

      {showAddHiredDate ? (
        <AddHiredDate
          registration_date={registration_date}
          handleOpenHiredDateModal={handleOpenHiredDateModal}
          location="applicant-card"
        />
      ) : null}
    </>
  );
};

/**
 * Used for displaying the "Hiring Date" of the applicant
 * If applicant has a valid hiring date, then that will be shown
 * Otherwise the applicant's registration date will be shown
 **/
const ExistingHiredDate = ({
  hiring_date,
  registration_date,
  hasHiredDatePermission,
  handleOpenHiredDateModal,
}: {
  hiring_date: string;
  registration_date: string | undefined;
  hasHiredDatePermission: boolean;
  handleOpenHiredDateModal: () => void;
}) => {
  return hiring_date ? (
    <Tooltip text={hasHiredDatePermission ? "Edit Hired Date" : "Hired Date"} size="md">
      <div className="d-flex flex-column align-items-center">
        <span
          className="applications__hired-date--active"
          style={{ cursor: hasHiredDatePermission ? "pointer" : "default" }}
          onClick={handleOpenHiredDateModal}
        >
          {format(new Date(hiring_date), "MM/dd/yyyy")}
        </span>
        <span className="applications__hired-date__label">Hired</span>
      </div>
    </Tooltip>
  ) : (
    <Tooltip text="Application Date" size="md">
      <p className="line-height--1 ml--5 mb--5">
        {format(parseJSON(registration_date!), "MM/dd/yyyy")}
      </p>
    </Tooltip>
  );
};

/**
 * Used for displaying the "Add Hired Date" button for the applicant
 * This will be shown if the targeted applicant is part of the "Hired" bucket
 * but does not have an associated hiring date value which can happen
 * for older applications that were moved to "Hired" before the feature
 * for handling the hiring date was added.
 **/
const AddHiredDate = ({
  location = "applicant-card",
  registration_date,
  handleOpenHiredDateModal,
}: {
  location: "single-details-page" | "applicant-card";
  registration_date: string | undefined;
  handleOpenHiredDateModal: () => void;
}) => {
  return (
    <div className="d-flex flex-column">
      {location === "applicant-card" ? (
        <Tooltip text="Application Date" size="md">
          <p
            className={`ml--5 mb--5 ${
              location === "applicant-card" ? "txt--xs" : "txt--center mt--5"
            }`}
          >
            {format(parseJSON(registration_date!), "MM/dd/yyyy")}
          </p>
        </Tooltip>
      ) : null}

      <PermissionCheckComponentWrapper permissions={["applicant_hired_date_edit"]}>
        <span className="applications__hired-date--inactive" onClick={handleOpenHiredDateModal}>
          Add hired date
        </span>
      </PermissionCheckComponentWrapper>
    </div>
  );
};

export default ApplicationDateSection;
