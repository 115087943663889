import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { GOOGLE_ANALYTICS_ID } from "../config/config";

/**
 *
 * Google Analytics Tag 
 * Creates a Google Analytics session and emits an event on every page change. 
 *  
 */
export const usePageTracking = () => {
  // Prevent initializing the GA Tag if on "dev" environment or if there's no valid ID to be used
  if (import.meta.env.DEV || !GOOGLE_ANALYTICS_ID) return;

  ReactGA.initialize(GOOGLE_ANALYTICS_ID);

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "FirstChoice Hiring" });
  }, [location]);
};
