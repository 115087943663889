// Utilities
import { getIn } from "formik";
import { useTranslation } from "react-i18next";

// Components
import Dropdown from "../Dropdown/Dropdown";

// Interfaces
import { FormDropdownProps } from "./interfaces";
import { DropdownItem } from "../Dropdown/interfaces";
import { useEffect } from "react";

const FormDropdown = ({ field, form, relatedField = "", ...props }: FormDropdownProps) => {
  // Anytime the value of the related field is updated,
  // trigger a reset of the value of this field
  useEffect(() => {
    // Exit function if there's no related field
    if (!relatedField) return;

    // Exit function if the related field does not have any value (yet)
    if (!form.values[relatedField]) return;

    // If the value of the co-dependent field was updated,
    // trigger a reset of the value of this field
    form.setFieldValue(field.name, "");
  }, [form.values[relatedField]]);

  /*===========================
    HANDLE ERRORS
  ============================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===========================
    APPEND MODIFIER CLASSES
  ============================*/
  const MODIFIER_CLASS: string = `${props.modifierClass} ${
    errors && touched ? "dropdown--error" : ""
  }`;

  /*===========================
    UPDATE FORMIK STATE
  
    Updates the targeted field with the selected value
  ============================*/
  const handleSelection = (item: DropdownItem) => form.setFieldValue(field.name, item.value);

  /*================================
    INTERNATIONALIZATION
  =================================*/
  const { t } = useTranslation();

  return (
    <>
      <Dropdown {...props} modifierClass={MODIFIER_CLASS} handleItemSelected={handleSelection} />

      {errors && touched ? <p className="input__error">{t(errors)}</p> : null}
    </>
  );
};

export default FormDropdown;
