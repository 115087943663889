import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";
import { AlertsResponseFields, AlertsUpdateRequestFields } from "./interfaces";

/*
    Get the details for managing alerts
*/
export const useAlertsGetDetails = () => {
  return useQuery(["alerts"], async () => {
    return (await fetchHandler("GET", "alerts")) as AlertsResponseFields;
  });
};

/*
    Update the Alerts details
*/
export const useAlertsUpdateDetails = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedDetails: AlertsUpdateRequestFields) => {
      return fetchHandler("POST", "alerts", updatedDetails);
    },
    {
      onSuccess: () => toast.success("Alert details updated successfully!"),
      onError: (error: unknown) => error,
      onSettled: () => queryClient.invalidateQueries(["alerts"]),
    },
  );
};
