// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";

// Interfaces
import { DropdownItem } from "../../../Dropdown/interfaces";
import { JobBoardsFormsProps } from "../interfaces";

// Dropdown datasets
import {
  LINKED_IN_DROPDOWN_EXPERIENCE_LEVEL,
  LINKED_IN_DROPDOWN_JOB_INDUSTRY,
} from "./dropdown-data/LinkedInDropdowns";

const LinkedInForm = ({ values, validateField, setFieldValue }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.linkedin.enabled) validateField("linkedin");
  }, [values.linkedin]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="linkedin.enabled"
        id="linkedin.enabled"
        label="Enable LinkedIn"
        checked={values.linkedin.enabled}
        labelModifierClass="input__label--sm"
      />

      {values.linkedin.enabled ? (
        <>
          <Field
            component={FormInput}
            name="linkedin.ad_title"
            id="linkedin.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormInput}
            name="linkedin.display_company"
            id="linkedin.display_company"
            label="Display Company Name"
            placeholder="Optionally set the display company name"
            modifierClass="input--default-label mb--20"
          />

          <Field
            component={FormDropdownSearchable}
            name="linkedin.industry"
            id="linkedin.industry"
            label="Job Industry"
            items={LINKED_IN_DROPDOWN_JOB_INDUSTRY}
            handleFieldUpdate={(jobIndustry: DropdownItem) => {
              setFieldValue("linkedin.industry", jobIndustry.value);
            }}
            size="full"
            placeholder="None - Optional"
            preselectedItemValue={values.linkedin.industry}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="linkedin.experience_level"
            id="linkedin.experience_level"
            label="Experience Level"
            items={LINKED_IN_DROPDOWN_EXPERIENCE_LEVEL}
            handleFieldUpdate={(experience_level: DropdownItem) => {
              setFieldValue("linkedin.experience_level", experience_level.value);
            }}
            size="full"
            placeholder="Not Applicable"
            preselectedItemValue={values.linkedin.experience_level}
            modifierClass="dropdown--default-label mb--30"
          />
        </>
      ) : null}
    </>
  );
};

export default LinkedInForm;
