// Interfaces
import { Component, ErrorInfo } from "react";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";

// Components
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import { Context, getRollbarFromContext } from "@rollbar/react";

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: "",
  };

  // Used for Rollbar instance
  static contextType = Context;

  public static getDerivedStateFromError(err: Error): State {
    console.log("error", err);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: err.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    // Report the uncaught errors from the Error Boundary directly to Rollbar's servers
    const rollbarInstance = getRollbarFromContext(this.context as any);
    rollbarInstance.error(`Uncaught error: ${error.message}`, error, errorInfo);
  }

  // Handle reloading the page if error doesn't pops up consistently
  handleReloadPage() {
    location.reload();
  }

  // Handle user redirect to the default page
  handleRedirectUser() {
    location.replace("/applications");
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Header />
          <div className="error-boundary d-flex flex-column align-items-center justify-content center py--50">
            <img src={FCHLogo} className="d-block mx--auto my--60" alt="FirstChoice Hiring logo" />

            <h2 className="error-boundary__title">Oops, something went wrong!</h2>
            <p className="error-boundary__description">{this.state.errorMessage}</p>

            <Button modifierClass="error-boundary__btn" onClick={this.handleReloadPage}>
              Reload Page
            </Button>
            <span className="error-boundary__link" onClick={this.handleRedirectUser}>
              Go to Home page
            </span>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
