import { useEffect, useState } from "react";
import Button from "../Button/Button";
import Modal from "./Modal";

const ModalEnableCookies = () => {
  const [showCookiesModal, setShowCookiesModal] = useState<boolean>(false);

  useEffect(() => {
    if (!navigator.cookieEnabled) setShowCookiesModal(true);
  }, []);

  return showCookiesModal ? (
    <Modal
      title="Enable Cookies"
      text={
        <>
          Please enable <strong>cookies</strong> in your browser in order to be able to <br /> fully
          interact with the application.
        </>
      }
      modifierClass="modal--enable-cookies"
      handleCloseModal={() => setShowCookiesModal(false)}
    >
      <Button
        modifierClass="btn--fluid btn--danger mr--10"
        onClick={() => setShowCookiesModal(false)}
      >
        Close
      </Button>
      <Button modifierClass="btn--fluid btn--primary" onClick={() => window.location.reload()}>
        Reload Page
      </Button>
    </Modal>
  ) : null;
};

export default ModalEnableCookies;
