// Utilities & Hooks
import { useEffect, useState } from "react";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useLogsEmailsGetAll, useLogsSMSGetAll } from "../../api/Logs/Logs";

// Components
import Card from "../../components/Card/Card";
import ContentHeader from "../../components/Content/ContentHeader";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Tabs from "../../components/Tabs/Tabs";
import EmailLogs from "./EmailLogs";
import SMSLogs from "./SMSLogs";
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";

// Interfaces
import { TabItemProps } from "../../components/Tabs/interfaces";
import { LogsCount } from "./interfaces";

const Logs = () => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const handleSearch = (searched: string) => setSearchedValue(searched);

  /*==================================
    FETCH DATA FROM THE API
  ===================================*/
  const { data: emailLogsData, isLoading: emailLogsIsLoading } = useLogsEmailsGetAll(searchedValue);
  const { data: smsLogsData, isLoading: smsLogsIsLoading } = useLogsSMSGetAll(searchedValue);

  /*==================================
    NUMBER OF DISPLAYED LOGS
  ===================================*/
  const [logsCount, setLogsCount] = useState<LogsCount | null>(null);
  const handleLogsCount = (logsCount: LogsCount | null) => setLogsCount(logsCount);

  /*==================================
    HANDLE ACTIVE TAB
  ===================================*/
  const [activeTab, setActiveTab] = useState<string>(() => searchParametersObject.tab || "emails");
  const [switchingTab, setSwitchingTab] = useState<boolean>(false);

  const handleTabChange = (tab: TabItemProps) => {
    setLogsCount(null);
    setSearchedValue("");
    setActiveTab(tab.id);

    // Show the table skeleton between tab swtiches
    setSwitchingTab(true);
    setTimeout(() => setSwitchingTab(false), 100);
  };

  // Reset pagination on active tab change
  useEffect(() => {
    if (searchParametersObject.page) delete searchParametersObject.page;
    setSearchParametersObject(searchParametersObject);
  }, [activeTab]);

  // Count the number of shown "Email" or "SMS" logs
  useEffect(() => {
    if (!emailLogsData || !smsLogsData) return;

    if (activeTab === "emails") {
      if (!emailLogsData.messages.length) {
        handleLogsCount(null);
      } else {
        handleLogsCount({
          shownCount: emailLogsData.messages.length,
          totalCount: emailLogsData.total,
        });
      }
    }

    if (activeTab === "sms") {
      if (!smsLogsData.smsMessages.length) {
        handleLogsCount(null);
      } else {
        handleLogsCount({
          shownCount: smsLogsData.smsMessages.length,
          totalCount: smsLogsData.total,
        });
      }
    }
  }, [activeTab, emailLogsData, smsLogsData]);

  return (
    <div className="container py--25">
      <ContentHeader modifierClass="mb--20" title="Notification Logs">
        <InputFieldSearch
          handleOnSearch={handleSearch}
          placeholder={`Search ${handleStringCapitalization(activeTab, [" "])}`}
          modifierClass="mr--10"
          size="md"
        />
      </ContentHeader>

      <div className="d-flex flex-column-reverse flex-md-row align-items-start justify-content-between">
        <Tabs
          tabs={[
            { id: "emails", text: "Emails" },
            { id: "sms", text: "SMS" },
          ]}
          activeTabId={activeTab}
          handleTabSelection={tab => handleTabChange(tab)}
        />

        {logsCount ? (
          <h5
            className="px--20 pt--10 pb--0 pb--md--20 px--md--0"
            data-testid="components:logs-show-count"
          >
            Showing <strong>{logsCount.shownCount}</strong> out of{" "}
            <strong>{logsCount.totalCount}</strong> results
          </h5>
        ) : null}
      </div>

      <Card>
        {emailLogsIsLoading || smsLogsIsLoading || switchingTab ? (
          <TableSkeletonPlaceholder modifierClass="table-wrapper table-wrapper--no-shadow table-wrapper--no-overflow" />
        ) : activeTab === "emails" ? (
          <EmailLogs emailLogs={emailLogsData} />
        ) : (
          <SMSLogs smsLogs={smsLogsData} />
        )}
      </Card>
    </div>
  );
};

export default Logs;
