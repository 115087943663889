import React from "react";
import { useTranslation } from "react-i18next";

// Utilities
import { getIn } from "formik";

// Interfaces
import { FormCheckboxProps } from "./interfaces";

const FormCheckbox = ({
  form,
  field,
  label,
  handleCheckboxNonFormAction,
  modifierClass = "",
  labelModifierClass = "",
  ...props
}: FormCheckboxProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*================================
    HANDLE CHECKBOX CHANGES
  =================================*/
  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    // If a non-form action function is received as a prop execeute that
    // Otherwise trigger updating the specified form field's value
    if (handleCheckboxNonFormAction) {
      handleCheckboxNonFormAction(event);
    } else {
      form.setFieldValue(field.name, event.currentTarget.checked);
    }
  };

  /*================================
    INTERNATIONALIZATION
  =================================*/
  const { t } = useTranslation();

  return (
    <div
      className={`input-checkbox ${modifierClass} ${
        errors && touched ? "input-checkbox--error" : ""
      }`}
    >
      <input {...field} {...props} hidden type="checkbox" onChange={handleCheckbox} />
      <label className={`input__label ${labelModifierClass}`} htmlFor={field.name}>
        {label}
      </label>

      {errors && touched && <div className="input__error">{t(errors)}</div>}
    </div>
  );
};

export default FormCheckbox;
