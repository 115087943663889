// Utilities & Hooks
import { Link } from "react-router-dom";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import { handleDistanceToNow } from "../../utilities/dates/handleDistanceToNow";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Interfaces
import { ArticleCardProps } from "./interfaces";

// Components
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

const ArticleCardList = ({
  id,
  title,
  summary,
  create_date,
  author,
  feature_image = "",
  read,
}: ArticleCardProps) => {
  return (
    <div className={`article ${!read ? "article--unread" : ""} article--list mb--20`}>
      <div className="row no-gutters">
        <div className="col-4">
          <Link to={`/articles/${id}/`} className="article__image">
            <img src={feature_image} title="Image describing the Article" />
          </Link>
        </div>

        <div className="col-8">
          <div className="article__content">
            <div>
              <Link
                to={`/articles/${id}/`}
                className="article__title"
                title={handleFullnameCombination(author)}
              >
                {title}
              </Link>
              <p className="article__author" title={handleFullnameCombination(author)}>
                By {handleFullnameCombination(author)}
              </p>
              <span className="d-inline-block txt--xs mb--20">
                {handleDistanceToNow(handleDateAsTimestamp(create_date))}
              </span>
              <p title={summary} className="article__text article__text--list">
                {summary}
              </p>
            </div>

            <div className="article__actions">
              <Link to={`/articles/${id}/`} className="article__link article__link--primary">
                Read More
              </Link>

              <PermissionCheckComponentWrapper permissions={["articles_edit"]}>
                <Link
                  to={`/articles/${id}/edit/`}
                  className="article__link article__link--secondary"
                >
                  Edit article
                </Link>
              </PermissionCheckComponentWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCardList;
