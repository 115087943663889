// Hooks
import { useMemo } from "react";
import { format } from "date-fns";

// Components
import Card from "../../components/Card/Card";
import Accordion from "../../components/Accordion/Accordion";

// Interfaces
import { ApplicationExtraInformationProps } from "../../api/Applications/interfaces";

// Assets
import { IoPerson as SupervisorIcon } from "react-icons/io5";
import { FaPhoneAlt as EmployerPhoneIcon } from "react-icons/fa";

// Constants
import { SHORT_APPLICATION_FORMS } from "../../components/Applications/statics";

const ApplicationExtraInformation = ({
  form_name,
  extra_info,
  is_hidden = false,
  isOpenByDefault,
}: ApplicationExtraInformationProps) => {
  // If there are no extra informations about the applicant, do not display the section
  if (!extra_info || !Object.entries(extra_info).length) {
    return (
      <Accordion
        id="accordion-employment-history"
        title="Employment History"
        modifierClass="accordion--rounded"
        isOpenByDefault={isOpenByDefault}
      >
        <p className="accordion__empty-message">No employment history data to display.</p>
      </Accordion>
    );
  }

  // Map the received extra info data for the applicant
  const MAPPED_EXTRA_INFO = useMemo(() => {
    // If we're working with the short form, we don't map any data
    if (SHORT_APPLICATION_FORMS.includes(form_name!)) return {};

    // Group up the employer-related fields
    const extraInfoObject: Record<string, any> = { employers: [] };
    const temporaryObject: Record<string | number, unknown> = {};

    Object.entries(extra_info).forEach(entry => {
      const [key, value] = entry;

      // Split the key by underscore and get the last element
      // which represents the number of the employer
      const splitKeyArray: string[] = key.split("_");
      const splitKeyArrayLastElement: string = splitKeyArray[splitKeyArray.length - 1];

      // Try to parse the last element from the split key
      const parsedLastElement = parseInt(splitKeyArrayLastElement);

      if (isNaN(parsedLastElement)) {
        temporaryObject[key] = value;
      } else {
        temporaryObject[parsedLastElement] = {
          // eslint-disable-next-line
          // @ts-ignore
          ...temporaryObject[parsedLastElement],
          [key]: value,
        };
      }
    });

    // Append the individually grouped employers objects to the "Employers" array
    Object.entries(temporaryObject).forEach(entry => {
      const [key, value] = entry;

      // TODO: The check with key property is temporary.
      if (typeof value === "object" && !key.startsWith("screening")) {
        extraInfoObject.employers.push(value);
      } else {
        extraInfoObject[key] = value;
      }
    });

    return extraInfoObject;
  }, [form_name, extra_info]);

  if (SHORT_APPLICATION_FORMS.includes(form_name!)) {
    return (
      <Accordion
        id="accordion-employment-history"
        title="Employment History"
        modifierClass="accordion--rounded"
        isOpenByDefault={isOpenByDefault}
      >
        {/* 
          NOTE: This is commented  out because it is not present in the current application.
          <h5 className="fw--semibold txt--blue mb--20">
            Country: <span className="txt--black fw--medium">{extra_info.country ?? "N/A"}</span>
          </h5> 
        */}

        <h5 className="fw--semibold txt--blue mb--10">Current Employer: </h5>
        <p className="txt--black break-word white-space--pre-line">
          {extra_info.current_employer ?? "N/A"}
        </p>

        <h5 className="fw--semibold txt--blue mb--10">Past Employer: </h5>
        <p className="txt--black break-word white-space--pre-line">
          {extra_info.past_employer ?? "N/A"}
        </p>
      </Accordion>
    );
  }

  return (
    <Accordion
      id="accordion-employment-history"
      title="Employment History"
      modifierClass="accordion--rounded"
      isOpenByDefault={isOpenByDefault}
    >
      {/* EMPLOYMENT HISTORY */}
      <Card modifierClass={`card--rounded card--no-shadow ${is_hidden ? "card--hidden" : ""}`}>
        {MAPPED_EXTRA_INFO.employers.length > 0 ? (
          MAPPED_EXTRA_INFO.employers.map((employer: any, index: number) => (
            <div
              key={`employer-${index}`}
              className="mb--20"
              data-testid="component:employment-details"
            >
              {/* EMPLOYER POSITION */}
              <div className="d-flex justify-content-between align-items-center mb--10">
                <h4 className="fw--semibold txt--blue">
                  {employer[`employer_position_${index + 1}`]}
                </h4>

                {/* WORKING PERIOD */}
                {employer[`start_date_${index + 1}`] ? (
                  <div className="d-flex align-items-center">
                    <span className="txt--sm">
                      {format(new Date(employer[`start_date_${index + 1}`]), "MM/dd/yyyy")}
                    </span>

                    <span className="txt--sm mx--5">-</span>

                    <span className="txt--sm">
                      {employer[`end_date_${index + 1}`]
                        ? format(new Date(employer[`end_date_${index + 1}`]), "MM/dd/yyyy")
                        : "Present"}
                    </span>
                  </div>
                ) : null}
              </div>

              {/* EMPLOYER NAME */}
              <h6 className="txt--black fw--semibold mb--5">
                <em>{employer[`employer_${index + 1}`] ?? "N/A"}</em>
              </h6>

              {/* EMPLOYER ADDRESS */}
              <p className="fw--medium txt--gray--darkest">
                {employer[`employer_address_${index + 1}`] ?? "N/A"}
              </p>

              {/* REASON FOR LEAVING */}
              <p className="fw--medium txt--black">
                Reason for leaving:
                <span className="txt--gray--darkest ml--10">
                  {employer[`reason_for_leaving_${index + 1}`] ?? "N/A"}
                </span>
              </p>

              {/* SUPERVISOR & EMPLOYER PHONE */}
              <div className="d-flex align-items-center mb--20">
                {/* SUPERVISOR */}
                <div className="d-flex align-items-center">
                  <SupervisorIcon className="txt--blue txt--xl" />
                  <span className="txt--md ml--10">
                    {employer[`supervisor_name_${index + 1}`] ?? "N/A"}
                  </span>
                </div>

                {/* EMPLOYER PHONE */}
                <div className="d-flex align-items-center ml--20">
                  <EmployerPhoneIcon className="txt--blue txt--lg" />
                  <span className="txt--md ml--10">
                    {employer[`employer_phone_${index + 1}`] ?? "N/A"}
                  </span>
                </div>
              </div>

              {MAPPED_EXTRA_INFO.employers.length !== index + 1 ? (
                <hr style={{ backgroundColor: "#eee" }} />
              ) : null}
            </div>
          ))
        ) : (
          <h5 className="fw--semibold">No employment history.</h5>
        )}
      </Card>

      {/* EDUCATION HISTORY */}
      {form_name !== "One Click Apply Form" ? (
        <>
          <hr style={{ backgroundColor: "#eee" }} />

          <Card modifierClass={`card--rounded card--no-shadow ${is_hidden ? "card--hidden" : ""}`}>
            <h2 className="fw--semibold mb--20">Education History</h2>
            <h5 className="fw--semibold mb--10">
              <em>Has Graduated Highschool?</em>
              <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                {MAPPED_EXTRA_INFO.graduate_high_school ?? "N/A"}
              </span>
            </h5>

            {MAPPED_EXTRA_INFO.graduate_high_school ? (
              <h5 className="fw--semibold mb--10">
                <em>Highschool Name:</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.high_school_name ?? "N/A"}
                </span>
              </h5>
            ) : null}
          </Card>

          <hr style={{ backgroundColor: "#eee" }} />

          {/* CRIMINAL HISTORY */}
          <Card modifierClass={`card--rounded card--no-shadow ${is_hidden ? "card--hidden" : ""}`}>
            <h2 className="fw--semibold mb--20">Criminal History</h2>

            <h5 className="fw--semibold mb--10">
              <em>Has been convicted?</em>
              <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                {MAPPED_EXTRA_INFO.has_been_convicted ?? "N/A"}
              </span>
            </h5>

            <h5 className="fw--semibold mb--10">
              <em>Has pled guilty?</em>
              <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                {MAPPED_EXTRA_INFO.has_pled_guilty ?? "N/A"}
              </span>
            </h5>

            {MAPPED_EXTRA_INFO.has_pled_guilty_details ? (
              <h5 className="fw--semibold mb--10">
                <em>Has plead guilty details:</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.has_pled_guilty_details ?? "N/A"}
                </span>
              </h5>
            ) : null}
          </Card>

          <hr style={{ backgroundColor: "#eee" }} />

          {/* OTHER QUESTIONS */}
          <Card modifierClass={`card--rounded card--no-shadow ${is_hidden ? "card--hidden" : ""}`}>
            <h2 className="fw--semibold mb--20">Other Questions</h2>

            <div className="mb--20">
              <h5 className="fw--semibold">
                <em>Country:</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.country ?? "N/A"}
                </span>
              </h5>
            </div>

            <div className="mb--20">
              <h5 className="fw--semibold">
                <em>Is 18 years or older?</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO["18_years_or_older"] ?? "N/A"}
                </span>
              </h5>
            </div>

            <div className="mb--20">
              <h5 className="fw--semibold">
                <em>Applicant Has Relative Employed Here?</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.has_any_relatives_employed ?? "N/A"}
                </span>
              </h5>
            </div>

            <div className="mb--20">
              <h5 className="fw--semibold">
                <em>Position Source:</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.heard_of_position
                    ? MAPPED_EXTRA_INFO.heard_of_position.split("_").join(" ")
                    : "N/A"}
                </span>
              </h5>
            </div>

            <div className="mb--20">
              <h5 className="fw--semibold">
                <em>Applicant Has Worked For Entity?</em>
                <span className="txt--capitalize txt--gray--darkest fw--medium ml--10">
                  {MAPPED_EXTRA_INFO.worked_with_us_in_the_past ?? "N/A"}
                </span>
              </h5>
            </div>
          </Card>
        </>
      ) : null}
    </Accordion>
  );
};

export default ApplicationExtraInformation;
