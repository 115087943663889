import { TooltipRenderProps } from "react-joyride";
import { MdClose as CloseIcon } from "react-icons/md";
import { FaChevronLeft as BackIcon, FaChevronRight as ForwardIcon } from "react-icons/fa";
import { useTour } from "../../providers/tour-context";
import { useEffect, useRef } from "react";

const ProductTourTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const {
    activeTour,
    tourCurrentStep,
    handleTourNextStep,
    handleTourPreviousStep,
    handleTourEnd,
    handlePromptTourEnd,
  } = useTour();

  // If the user presses on the "Escape" key while the
  // tour tooltip is present in the UI, prompt them with the confirmation modal
  // for ending the currently ongoing tour
  const tourTooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!tourTooltipRef || !tourTooltipRef.current) return;

    function handleUserKeyboardInput(event: KeyboardEvent) {
      // Open double confirmation modal for ending the current tour
      if (event.key === "Escape") handlePromptTourEnd();

      // User navigation with "Left" and "Right" arrow keys
      if (tourCurrentStep > 0 && event.key === "ArrowLeft") handleTourPreviousStep(0);
      if (event.key === "ArrowRight") handleTourNextStep(0);
    }

    document.body.addEventListener("keyup", handleUserKeyboardInput);
    return () => document.body.removeEventListener("keyup", handleUserKeyboardInput);
  }, [tourTooltipRef]);

  /*=======================================
  We add our own context controllers for
  managing tour steps/status to the 
  native onClick handlers passed from the
  ReactJoyRide component. This way we can 
  have total control over 'default' & 
  'manual' tours with the tooltip buttons.
  =======================================*/
  return (
    <div {...tooltipProps} className="joyride-tooltip">
      <div className="joyride-tooltip__header" ref={tourTooltipRef}>
        {step.title && <h2 className="joyride-tooltip__header__title">{step.title}</h2>}

        <div
          {...closeProps}
          className="joyride-tooltip__header__close"
          onClick={() => handlePromptTourEnd()}
        >
          <div id="close">
            <CloseIcon />
          </div>
        </div>
      </div>

      <div className="joyride-tooltip__content">{step.content}</div>
      {!step.hideFooter ? (
        <div className="joyride-tooltip__actions">
          <div
            className={`d-flex align-items-center fw--medium txt--lg txt--link ${
              !(index > 0) ? "invisible" : ""
            }`}
            {...backProps}
            onClick={e => {
              activeTour?.type === "manual" ? handleTourPreviousStep(0) : backProps.onClick(e);
            }}
            id="back"
          >
            <BackIcon className="mr--5" />
            <span>Back</span>
          </div>

          {continuous && (
            <div
              className="d-flex align-items-center fw--medium txt--lg txt--link"
              {...primaryProps}
              onClick={e => {
                if (primaryProps.title === "Last") {
                  handleTourEnd();
                }
                activeTour?.type === "manual" ? handleTourNextStep(0) : primaryProps.onClick(e);
              }}
              id="next"
            >
              <span>{primaryProps.title === "Last" ? "Finish" : "Next"}</span>
              <ForwardIcon className="ml--5" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProductTourTooltip;
