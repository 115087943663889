import { useSearchParams } from "react-router-dom";

/**
 *
 * Hook for extracting the query parameters from
 * the URL that the user visited
 *
 */
export function useExtractSearchParameters(): [Record<string, any>, Function] {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const searchParametersObject = Object.fromEntries(new URLSearchParams(searchParameters));

  return [searchParametersObject, setSearchParameters];
}

/**
 *
 * Utility function for merging the extracted
 * search parameters into a single string to be
 * then used for constructing navigation links.
 *
 * @param params Key/value pairs representing the existing URL parameters.
 *
 * @returns A single string containing all of the existing search parameters joint together.
 *
 */
export function handleMergeSearchParameters(params: Record<string, unknown>): string {
  if (!params || !Object.entries(params).length) return "";

  let parameters: string = "";
  Object.entries(params).forEach(param => {
    const [key, value] = param;

    if (parameters.startsWith("?")) {
      parameters += `&${key}=${value}`;
    } else {
      parameters += `?${key}=${value}`;
    }
  });

  return parameters;
}
