/**
 *
 * Utility function for combining the `city`, `state` and `zip_code`
 * values into a string representing the location of the applicant.
 *
 * @param city A string representation of the applicant's `city` name
 * @param state A string representation of the applicant's `state` area
 * @param zip_code A string representation of the applicant's `zip_code` area
 * @returns A concatenated string representing the applicant's location
 */
export function handleApplicantCityAndState(
  city: string,
  state: string | null = "",
  zip_code: string | null = "",
): string {
  return `${city ? city + (state || zip_code ? ", " : "") : ""}${state ? state + " " : ""}${
    zip_code || ""
  }`;
}
