import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import ArticleDetails from "../pages/Articles/ArticleDetails";
import Articles from "../pages/Articles/Articles";
import ArticlesEdit from "../pages/Articles/ArticlesEdit";
import ArticlesOverview from "../pages/Articles/ArticlesOverview";
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_ARTICLES: ApplicationRouteProps[] = [
  {
    path: "/articles",
    element: (
      <LayoutAuthenticated>
        <Articles />
      </LayoutAuthenticated>
    ),
    permissions: ["articles_user_view"],
    type: "protected",
  },
  {
    path: "/articles/:id",
    element: (
      <LayoutAuthenticated>
        <ArticleDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["articles_user_view"],
    type: "protected",
  },

  {
    path: "/articles/overview",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ArticlesOverview />
      </LayoutAuthenticated>
    ),
    permissions: ["articles_view"],
    type: "protected",
  },
  {
    path: "/articles/:id/edit",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ArticlesEdit />
      </LayoutAuthenticated>
    ),
    permissions: ["articles_edit"],
    type: "protected",
  },
];
