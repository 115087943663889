// Utilities & Hooks
import { Link, useNavigate } from "react-router-dom";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useAuthenticationAutologin } from "../../api/Authentication/Authentication";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";

// Components
import Loader from "../../components/Loader/Loader";

const Autologin = () => {
  const [searchParametersObject] = useExtractSearchParameters();
  const navigate = useNavigate();
  const existingAccessToken = LocalStorageActions.getItem("accessToken");

  // Get the email from the search params & replace space character with "+"
  const formattedEmail = searchParametersObject?.email?.replaceAll(" ", "+") || "";

  // Send request to obtain new tokens from the used autologin link
  const { isLoading } = useAuthenticationAutologin(
    formattedEmail,
    searchParametersObject.token,
    existingAccessToken,
  );

  // If there are any authentication values saved in local storage, redirect user to homepage
  if (existingAccessToken) {
    navigate("/applications/");
    return null;
  }

  // If there are missing "email" and/or "token" search parameters,
  // use a fallback UI to inform the user about the required fields
  if (!searchParametersObject.email || !searchParametersObject.token) {
    return (
      <div className="autologin">
        <h1>Missing Autologin Credentials</h1>
        <p className="fw--regular">
          Please make sure that there are valid "email" and "token" credentials included in the URL.
        </p>
        <Link to="/" className="btn btn--fluid btn--primary">
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="autologin">
      <h1>Logging In as:</h1>
      <p>{formattedEmail}</p>
      {isLoading ? <Loader size="lg" /> : null}
    </div>
  );
};

export default Autologin;
