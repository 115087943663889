import { API_BASE_URL } from "../config/config";
import { LocalStorageActions } from "../utilities/handleLocalStorage";
import { handleFetchError } from "./errorHandling";

/**
 *
 * Utility function for handling file downloads from a given URL
 *
 * @param url The URL to which the request is being sent
 *
 */
export default async function handleFileDownload(url: string) {
  const accessToken = LocalStorageActions.getItem("accessToken");

  // Prevent request if there's no valid access token
  if (!accessToken) throw new Error("You are not authenticated to do this action.");

  const response = await fetch(`${API_BASE_URL}/${url}`, {
    method: "GET",
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  });

  // Use the URL that is returned in the response by the API and assign the current windows URL
  // to it that will trigger the download of a file
  const data = await response.json();

  if (!response.ok) handleFetchError(response, data, { type: "json" });

  window.location.assign(data.url);
}
