import * as XLSX from "xlsx";

import { DocumentExportColumnDetails } from "../../pages/Reports/interfaces";

export const DefaultReportCSVTemplate = (
  data: unknown[],
  fileName: string,
  columns: DocumentExportColumnDetails[],
  dataDate?: string,
) => {
  if (import.meta.env.MODE === "test") return;

  // Format columns according to supplied 'columns' prop
  const formattedColumns = data.map((entity: any) => {
    const obj = {};

    columns.forEach((element: DocumentExportColumnDetails) => {
      Object.assign(obj, { [element.text]: entity[element.field_name] });
    });

    return obj;
  });

  /*
    ===================================================
    Excel WorkBook & WorkSheet initiialization

    Start by generating a new excel file & converting
    the JSON data into sheet data object.
    ====================================================*/
  const workBook = XLSX.utils.book_new();

  const workSheet = XLSX.utils.json_to_sheet([]);

  /*
    ===================================================
    APPEND DATA DATE AS FIRST ROW

    If the 'dataDate' prop is supplied an additional row
    is appended to the worksheet showcasing details
    about the document generation meta data.
    ====================================================*/
  if (dataDate) {
    XLSX.utils.sheet_add_aoa(workSheet, [[dataDate]], { origin: 0 });
  }

  // Append the data after the optional 'data date' row
  XLSX.utils.sheet_add_json(workSheet, formattedColumns, {
    origin: dataDate ? 1 : 0,
  });

  // Append sheet to workbook
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

  // Return the generated file with proper extension depending on type prop
  return XLSX.writeFile(workBook, `${fileName}.csv`);
};
