// Hooks & Utilities
import { getIn } from "formik";
import { useEffect, useState } from "react";
import handlePhoneStringSanitization from "../../utilities/strings/handlePhoneStringSanitization";

// Interfaces
import { FormPhoneInputProps } from "./interfaces";

const FormPhoneInput = ({
  form,
  field,
  size = "full",
  modifierClass = "",
  label = "",
  isRequired = false,
  placeholder = "(xxx) xxx-xxxx",
  ...props
}: FormPhoneInputProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const [input, setInput] = useState<string>("");

  // Use the initial form value for "phone" field to pre-populate the text state
  useEffect(() => {
    // Exit function if there are no initial values provided
    if (!Object.entries(form.initialValues).length) return;

    const targetedField = form.initialValues[field.name];

    // Exit function if the targeted field cannot be found
    if (!targetedField) return;

    setInput(handleInputNormalization(targetedField));
  }, [form.initialValues]);

  // Character limit is set to 10 because thats the
  // maximum number length after stripping away the parenthesis,
  // dash and empty spaces that is suppored in US and Canada
  const CHAR_LIMIT: number = 10;

  const handleInputNormalization = (value: string): string => {
    // Strip phone number prefix
    // Limited use case to only US & CA prefix
    const clearedPrefix = value.replace(/^\+1/, "");

    // Remove any non-numeric characters from the input
    const clearedInputString: string = clearedPrefix.replace(/[^\d]/gi, "");

    // If there's no value provided, return empty string
    if (!clearedInputString) return "";

    // Prevent users from entering more characters than the limit
    if (clearedInputString.length > CHAR_LIMIT) return input;

    // Do not append anything to the user's input
    if (clearedInputString.length < 4) return clearedInputString;

    // Append parenthesis to the number
    if (clearedInputString.length < 7) {
      return `(${clearedInputString.slice(0, 3)}) ${clearedInputString.slice(3)}`;
    }

    return `(${clearedInputString.slice(0, 3)}) ${clearedInputString.slice(
      3,
      6,
    )}-${clearedInputString.slice(6)}`;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const normalizedValue: string = handleInputNormalization(event.target.value);
    setInput(normalizedValue);

    // Trigger the callback for handling user's phone input changes
    form.setFieldValue(field.name, handlePhoneStringSanitization(normalizedValue));
  };

  // If there's a validation-related error
  const hasError = errors && touched;

  return (
    <div className={`input input--${size} ${modifierClass}`}>
      {label ? (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      ) : null}

      <div className="input__phone">
        <div className={`input__phone__prefix ${hasError ? "input__phone__prefix--error" : ""}`}>
          +1
        </div>

        <input
          {...field}
          {...props}
          className={`input__field ${hasError ? "input__field--error" : ""}`}
          type="text"
          placeholder={placeholder}
          onChange={handleInputChange}
          value={input}
        />
      </div>

      {/* DISPLAY ERROR MESSAGES */}
      {hasError && <p className="input__error">{errors}</p>}
    </div>
  );
};

export default FormPhoneInput;
