import { useEffect, useState } from "react";
import useWindowResize from "../../../hooks/useWindowResize";

/**
 *
 * Custom hook for handling how many buckets
 * are visible in the main part of the UI, based on the
 * current window size.
 *
 * @returns The total number of visible buckets that should be shown in the UI.
 */
export function useVisibleBuckets(): number {
  const [visibleBucketsCount, setVisibleBucketsCount] = useState<number>(5);
  const [windowWidth] = useWindowResize(300);

  useEffect(() => {
    switch (true) {
      case windowWidth > 1000 && windowWidth <= 1100:
        setVisibleBucketsCount(5);
        break;
      case windowWidth > 950 && windowWidth <= 1000:
        setVisibleBucketsCount(4);
        break;
      case windowWidth > 800 && windowWidth <= 950:
        setVisibleBucketsCount(3);
        break;
      case windowWidth > 650 && windowWidth <= 800:
        setVisibleBucketsCount(2);
        break;
      case windowWidth > 575 && windowWidth <= 650:
        setVisibleBucketsCount(1);
        break;
      case windowWidth <= 575:
        setVisibleBucketsCount(0);
        break;
      default:
        setVisibleBucketsCount(5);
    }
  }, [windowWidth]);

  return visibleBucketsCount;
}
