import { format } from "date-fns";
import {
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";

export const JOB_OFFER_EMAIL_COVER_NOTE: EmailTemplateModel = {
  name: "job-offer-email-cover-note",
  label: "Job Offer Email Cover Note",
  subject: (details: EmailTemplateDetailsModel) =>
    `Re: Your Job Application dated ${format(new Date(details.applicationDate), "MM/dd/yyyy")}`,
  html: (
    details: EmailTemplateDetailsModel,
  ) => `<p>Dear <span style="color:orangered;">${details.applicantName}</span>,</p>
    <br />
<p>We are pleased to extend to you an official offer of employment for the position of <span style="color:orangered;">${details.applicationPosition}</span> at <span style="color:orangered;">${details.senderCompany}</span>.</p>
<br />
<p>Please see the attached formal offer, sign it and return the signed copy to the sender of this email.</p>
<br />
<p>We look forward to having you as part of the team. If you have any questions, do not hesitate to contact me.</p>
            <br />
<p>Sincerely,</p>
<p><span style="color:orangered;">${details.senderName}</span></p>
<p><span style="color:orangered;">[TITLE]</span></p>
        <br />
<p><span style="color:orangered;">[ENCLOSURES]</span></p>
    </div>`,
};
