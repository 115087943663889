import { isAfter, isBefore } from "date-fns";
import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";
import { EMAIL_REGEX_PATTERN } from "./regexes";
import { JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD } from "../components/JobAds/Details/JobBoardsForms/constants";

/*=============================
 JOB ADS DETAILS: COMMENTS
==============================*/
export const JOB_ADS_DETAILS_COMMENTS_SCHEMA = Yup.object().shape({
  data: Yup.string()
    .required("Please enter comment!")
    .max(255, "Maximum of 255 characters allowed!")
    .test("job-ads-comment", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});

/*=============================
 JOB ADS DETAILS: AD SETTINGS
==============================*/
export const JOB_ADS_DETAILS_AD_SETTINGS_SCHEMA = Yup.object().shape({
  form_id: Yup.number().nullable().required("Please select the job application type to be used."),
  sbca_type_id: Yup.number().nullable().required("Please select the SBCA type to be used."),
  live_date: Yup.date()
    .nullable()
    .required("Please select the date when the Job Ad goes live.")
    .test("check-live-date", "Live date can't be set after Expire date.", (live_date, context) => {
      const { expire_date } = context.parent;

      // No checks needed if no live date is selected
      if (!live_date) return true;

      // Check if there's a selected expiry date value
      // and if that date is set before the selected live date
      if (expire_date && isBefore(expire_date, live_date)) return false;

      return true;
    }),
  expire_date: Yup.date()
    .nullable()
    .notRequired()
    .test(
      "check-expiry-date",
      "Expire date can't be set before Live date.",
      (expire_date, context) => {
        const { live_date } = context.parent;

        // No checks needed if no expiry date is selected
        if (!expire_date) return true;

        // Check if there's a selected live date value
        // and if that date is after the selected expiry date
        if (live_date && isAfter(live_date, expire_date)) return false;

        return true;
      },
    ),
});

/*=============================
 JOB BOARDS - APPCAST
==============================*/
export const JOB_BOARDS_FORM_APPCAST_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-appcast-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-appcast-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-appcast-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  display_company: Yup.string()
    .test("job-ads-appcast-company", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-appcast-company", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  category: Yup.string().test(
    "job-ads-appcast-category",
    "Please select the category to be used.",
    (value, context) => handleValidateEnabledJobBoard(value, context),
  ),
  posting_country: Yup.string().test(
    "job-ads-appcast-posting_country",
    "Please select the posting country.",
    (value, context) => handleValidateEnabledJobBoard(value, context),
  ),
});

/*=============================
 JOB BOARDS - TALENT
==============================*/
export const JOB_BOARDS_FORM_TALENT_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-talent-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-talent-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-talent-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  budget: Yup.string().nullable().notRequired(),
});

/*=============================
 JOB BOARDS - SNAG A JOB
==============================*/
export const JOB_BOARDS_FORM_SNAG_A_JOB_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-snagajob-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-snagajob-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-snagajob-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  display_company: Yup.string()
    .test("job-ads-snagajob-company", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-snagajob-company", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  industry: Yup.string().test(
    "job-ads-snagajob-industry",
    "Please select a job industry",
    (value, context) => handleValidateEnabledJobBoard(value, context),
  ),
});

/*=============================
 JOB BOARDS - CRAIGSLIST
==============================*/
export const JOB_BOARDS_FORM_CRAIGSLIST_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
});

/*=============================
 JOB BOARDS - LINKED IN
==============================*/
export const JOB_BOARDS_FORM_LINKEDIN_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-linkedin-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-linkedin-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-linkedin-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  display_company: Yup.string()
    .test("job-ads-linkedin-company", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-linkedin-company", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
});

/*=============================
 JOB BOARDS - INDEED
==============================*/
export const JOB_BOARDS_FORM_INDEED_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-indeed-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-indeed-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-indeed-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  salary: Yup.string()
    .test("job-ads-indeed-salary", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-indeed-salary", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    )
    .test("state-based-requirement", "Please enter salary information", (value, context) => {
      const { enabled, state } = context.parent;

      // Marks field as required if job board is enabled, there's no value in the field and
      // the state in which the ad will be posted is one of the pre-defined states where its required
      if (enabled && JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD.includes(state) && !value) {
        return false;
      }

      return true;
    }),
  promote_on_indeed: Yup.mixed().test(
    "job-ads-indeed-promote_on_indeed",
    "Please select whether you want the ad to be promoted on Indeed or not.",
    (value, context) => handleValidateEnabledJobBoard(value, context),
  ),
  indeed_budget: Yup.number()
    .nullable()
    .test("indeed.budget", "Please enter the desired budget.", (value, context) => {
      const { promote_on_indeed } = context.parent;

      // If "Promote on Indeed" is selected as an option and
      // there's no value entered for the "Indeed Budget" field, throw an error
      if (promote_on_indeed === 1 && !value) return false;

      return true;
    })
    .test("minimum-budget", "The minimum budget is $50.", (value, context) => {
      const { promote_on_indeed } = context.parent;

      // If "Promote on Indeed" is selected as an option and
      // the value is less than 50 for the "Indeed Budget" field, throw an error
      if (promote_on_indeed === 1 && (!value || value < 50)) {
        return false;
      }
      return true;
    }),
  indeed_duration: Yup.string().test(
    "job-ads-indeed-duration",
    "Please select the desired promotion duration.",
    (value, context) => {
      const { promote_on_indeed } = context.parent;

      // If "Promote on Indeed" is selected as an option and
      // there's no value entered for the "Sponsor Duraiton" field, throw an error
      if (promote_on_indeed === 1 && !value) return false;

      return true;
    },
  ),
});

/*=============================
 JOB BOARDS - ZIP RECRUITER
==============================*/
export const JOB_BOARDS_FORM_ZIP_RECRUITER_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-zip-recruiter-salary", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-zip-recruiter-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-ziprecruiter-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  // Temporarily Disabled
  // campaign: Yup.string().when("sponsored", {
  //   is: true,
  //   then: schema => schema.required("Please select a campaign"),
  //   otherwise: schema => schema.notRequired(),
  // }),
  screening_questions: Yup.array().when("screening_enabled", {
    is: true,
    then: schema =>
      schema.of(
        Yup.object({
          question: Yup.string()
            .required("Please provide a title for the question")
            .test(
              "job-ads-zip-recruiter-q1",
              "Maximum of 255 characters allowed!",
              (value, context) => handleValidateEnabledJobBoardCheckCharLimit(value, context),
            )
            .test("job-ads-zip-recruiter-q1", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
              handleValidateEnabledJobBoardCheckForHTML(value, context),
            ),
        }),
      ),
  }),
});

/*=============================
 JOB BOARDS - CAREER BUILDER
==============================*/
export const JOB_BOARDS_FORM_CAREER_BUILDER_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-careerbuilder-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-careerbuilder-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-careerbuilder-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  display_city: Yup.string()
    .test("job-ads-careerbuilder-city", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-careerbuilder-city", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  display_company: Yup.string()
    .test("job-ads-careerbuilder-company", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-careerbuilder-company", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
});

/*================================
 JOB BOARDS - FIRSTCHOICE HIRING
=================================*/
export const JOB_BOARDS_FORM_FIRSTCHOICE_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().notRequired(),
  ad_title: Yup.string()
    .test("job-ads-firstchoice-title", "Maximum of 255 characters allowed!", (value, context) =>
      handleValidateEnabledJobBoardCheckCharLimit(value, context),
    )
    .test("job-ads-firstchoice-title", "Please enter the ad title", (value, context) =>
      handleValidateEnabledJobBoard(value, context),
    )
    .test("job-ads-firstchoice-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, (value, context) =>
      handleValidateEnabledJobBoardCheckForHTML(value, context),
    ),
  job_type: Yup.string().when("enabled", {
    is: true,
    then: schema =>
      schema
        .oneOf(["Full time", "Part time", "Contractor", "Intern", "Seasonal"])
        .required("Please select the Job Type"),
    otherwise: schema => schema.notRequired(),
  }),
  categories: Yup.array().when("enabled", {
    is: true,
    then: schema => {
      return schema
        .of(Yup.string())
        .min(1, "Please select at least 1 category")
        .max(5, "Up to 5 categories can be selected")
        .required("Please select at least 1 category");
    },
    otherwise: schema => schema.notRequired(),
  }),
  salary_from: Yup.string()
    .notRequired()
    .test(
      "job-ads-firstchoice-salary-from",
      "'Salary From' must be lower or equal to 'Salary To'",
      (value, context) => {
        if (!value) return true;

        // Parse the 'from' and 'to' to integers so they can be compared
        const parsedSalaryFrom = parseInt(value);
        const parsedSalaryTo = parseInt(context.parent.salary_to);

        if (parsedSalaryFrom > parsedSalaryTo) return false;

        return true;
      },
    ),
  salary_to: Yup.string().notRequired(),
  salary_period: Yup.string().notRequired(),
  remote: Yup.boolean().default(false),
  featured: Yup.boolean().default(false),
});

/*=============================
 JOB BOARDS - GLASSDOOR

 NOTE: TO BE INCLUDED IF NEEDED
==============================*/
// export const JOB_BOARDS_FORM_GLASSDOOR_SCHEMA = Yup.object().shape({
//   enabled: Yup.boolean().notRequired(),
//   ad_title: Yup.string().test("glassdoor.ad_title", "Please enter the ad title", (value, context) =>
//     handleValidateEnabledJobBoard(value, context),
//   ),
// });

/*=================================
 JOB BOARDS MAIN VALIDATION SCHEMA
==================================*/
export const JOB_BOARDS_FORM_SCHEMA = Yup.object().shape({
  appcast: JOB_BOARDS_FORM_APPCAST_SCHEMA,
  linkedin: JOB_BOARDS_FORM_LINKEDIN_SCHEMA,
  craigslist: JOB_BOARDS_FORM_CRAIGSLIST_SCHEMA,
  snagajob: JOB_BOARDS_FORM_SNAG_A_JOB_SCHEMA,
  indeed: JOB_BOARDS_FORM_INDEED_SCHEMA,
  zip_recruiter: JOB_BOARDS_FORM_ZIP_RECRUITER_SCHEMA,
  careerbuilder: JOB_BOARDS_FORM_CAREER_BUILDER_SCHEMA,
  talent: JOB_BOARDS_FORM_TALENT_SCHEMA,
  firstchoice: JOB_BOARDS_FORM_FIRSTCHOICE_SCHEMA,
  // glassdoor: JOB_BOARDS_FORM_GLASSDOOR_SCHEMA,
});

/**
 *
 * Utility function to be used for each of the Job Boards schema tests,
 * that checks if the specific job board is marked as "enabled", and if so
 * it converts the necessary fields to "required".
 *
 * @param value The value of the individual form field of each Job Board
 * @param context The context of the individual form field of each Job Board,
 * allowing us access to the "enabled" field of the targeted Job Board
 *
 * @returns A boolean indicating if the field is required or not
 *
 */
const handleValidateEnabledJobBoard = (value: any, context: any) => {
  const { enabled } = context.parent;

  // If the field doesn't have any valid value,
  // and the entire job board group is marked as "enabled", throw an error
  if (!value && enabled) return false;

  return true;
};

/**
 *
 * Utility function to be used for each of the Job Boards schema tests,
 * that checks if the specific job board is marked as "enabled" and if the
 * field has a HTML tag contained within the text.
 *
 * @param value The value of the individual form field of each Job Board
 * @param context The context of the individual form field of each Job Board,
 * allowing us access to the "enabled" field of the targeted Job Board
 *
 * @returns A boolean indicating if the field is okay or not
 *
 */
const handleValidateEnabledJobBoardCheckForHTML = (value: any, context: any) => {
  const { enabled } = context.parent;
  if (enabled && value && handleCheckStringForHTML(value)) return false;
  return true;
};

/**
 *
 * Utility function to be used for each of the Job Boards schema tests,
 * that checks if the specific job board is marked as "enabled" and checks if
 * the text is within the specified limit of 255 characters.
 *
 *
 * @param value The value of the individual form field of each Job Board
 * @param context The context of the individual form field of each Job Board,
 * allowing us access to the "enabled" field of the targeted Job Board
 *
 * @returns A boolean indicating if the field is okay or not
 *
 */
const handleValidateEnabledJobBoardCheckCharLimit = (value: any, context: any) => {
  const { enabled } = context.parent;
  if (enabled && value && value.length > 255) return false;
  return true;
};

/*=============================
 JOB ADS EDIT PAGE
==============================*/
export const JOB_ADS_EDIT_VALIDATION_SCHEMA = Yup.object().shape({
  form_id: Yup.number()
    .nullable()
    .required("Please select the Online Application form to be used."),
  sbca_type_id: Yup.number().nullable().required("Please select the SBCA type to be used."),
  email_alerts: Yup.string()
    .notRequired()
    .max(700, "Maximum of 700 characters allowed!")
    .test("job-ads-revise-street", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    })
    .test(
      "job-ads-email-alerts",
      "Invalid email(s) provided. Please double check the list of provided emails.",
      email_alerts => {
        // Prevent checking for valid email types if there's no emails string provided
        if (!email_alerts) return true;

        // Split the provided string value based on semicolon,
        // extracting the individual emails
        const splitEmails = email_alerts.split(";");

        // Check if all of the provided emails are valid
        const providedEmailsCheck: boolean = splitEmails.every(email => {
          return email.match(EMAIL_REGEX_PATTERN);
        });

        // If at least one of the provided emails is incorrect, throw an error
        if (!providedEmailsCheck) return false;

        // Check if email with invalid length was provided
        const providedEmailsLengthCheck: boolean = splitEmails.every(email => {
          return email.length <= 50;
        });

        // If at least one of the emails has more than 50 characters, throw an error
        if (!providedEmailsLengthCheck) return false;

        return true;
      },
    ),
  address_country: Yup.string()
    .required("Please select country.")
    .max(255, "Maximum of 255 characters allowed!"),
  address_street: Yup.string()
    .required("Please enter street name.")
    .max(50, "Maximum of 50 characters allowed!")
    .test("job-ads-revise-street", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  address_city: Yup.string()
    .required("Please fill in the field.")
    .max(30, "Maximum of 30 characters allowed!")
    .test("job-ads-revise-city", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  address_state: Yup.string()
    .required("Please fill in the field.")
    .max(30, "Maximum of 30 characters allowed!"),
  address_zip: Yup.string()
    .required("Please fill in the field.")
    .max(6, "Maximum of 6 characters allowed!")
    .test("job-ads-revise-zip", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    })
    .test("job-ads-only-integeres", "Please enter a valid code!", (value, context) => {
      if (!value) return false;

      const { address_country } = context.parent;

      if (address_country === "US") {
        // US zip codes can contain ONLY numbers
        const regex = /^[0-9]+$/gi;
        const checkIfValidUSZipCode = regex.test(value);
        if (!checkIfValidUSZipCode) return false;
      } else {
        // Canadian postal codes are alphanumeric, and no special characters can be accepted
        const checkIfValidCanadianPostalCode = /^\w+$/gi.test(value);
        if (!checkIfValidCanadianPostalCode) return false;
      }

      return true;
    }),
  job_title: Yup.string()
    .required("Please enter the Job's title.")
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  headline_1: Yup.string()
    .required("Please enter headline.")
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-headline1", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  headline_2: Yup.string()
    .notRequired()
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-headline2", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  headline_3: Yup.string()
    .notRequired()
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-headline3", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_questions: Yup.string()
    .notRequired()
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-questions", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_intro: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-intro", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_description: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-desc", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_benefits: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-benefits", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_responsibilities: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-responsibilities", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_requirements: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-requirements", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  job_terms: Yup.string()
    .notRequired()
    .max(3000, "Maximum of 3000 characters allowed!")
    .test("job-ads-revise-terms", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  content: Yup.string()
    .notRequired()
    .max(65000, "Maximum of 65000 characters allowed in the text editor!"),
  reason: Yup.string()
    .notRequired()
    .max(500, "Maximum of 500 characters allowed!")
    .test("job-ads-revise-reason", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});
