import { type Placement } from "react-joyride";

type ExtendedPlacement = Placement | "auto" | "center";
type TourPositioningByScreenSize = Record<"lg" | "md" | "sm" | "xs", ExtendedPlacement>;

/**
 *
 * Set the correct placement of the tour tooltip
 * based on the current device size on which the
 * application was opened.
 *
 * @param position The expected positioning of the tooltip
 * for different device sizes. Uses the same breakpoints as Bootstrap Grid.
 *
 * @returns The positioning of the tour tooltip element.
 *
 */
export function handleTourTooltipPlacement(
  position: TourPositioningByScreenSize,
): ExtendedPlacement {
  const screenWidth = window.innerWidth;

  switch (true) {
    case screenWidth >= 992:
      return position.lg;
    case screenWidth >= 768:
      return position.md;
    case screenWidth >= 576:
      return position.sm;
    default:
      return position.xs;
  }
}
