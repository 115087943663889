// Utilities & Hooks
import { getIn } from "formik";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useTranslation } from "react-i18next";

// Interfaces
import { SliderProps } from "./interfaces";

const FormInputSlider = ({
  form,
  field,
  hasNumberBox = true,
  step = 1,
  defaultValue = 1,
  minValue = 1,
  maxValue = 10,
  isDisabled = false,
  modifierClass = "",
  ...props
}: SliderProps) => {
  /*===========================
    HANDLE ERRORS
  ============================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===========================
    HANDLE FIELD VALUE
  ============================*/
  const [sliderValue, setSliderValue] = useState<number>(minValue);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the value state that will be shown in the UI
    const currentValue: number = parseInt(event.target.value);

    switch (true) {
      case currentValue <= minValue:
        setSliderValue(minValue);
        break;
      case currentValue >= maxValue:
        setSliderValue(maxValue);
        break;
      default:
        setSliderValue(currentValue);
    }
  };

  /*================================
    DEBOUNCE THE FORM FIELD UPDATE

    Delay the updates to the form field
    to prevent drops in performance when
    the slider is being dragged quickly
  ==================================*/
  const debouncedSliderValue = useDebounce(sliderValue, 100);
  useEffect(() => {
    form.setFieldValue(field.name, debouncedSliderValue);
  }, [debouncedSliderValue]);

  /*================================
    INTERNATIONALIZATION
  =================================*/
  const { t } = useTranslation();

  return (
    <div className={`slider ${modifierClass}`}>
      {hasNumberBox && (
        <div className="slider__box">
          <input
            type="number"
            min={minValue}
            max={maxValue}
            value={sliderValue}
            className="slider__input__number"
            disabled={isDisabled}
            onChange={handleValueChange}
            data-testid="component:form-input-slider-number-box"
          />
        </div>
      )}

      <div className="slider__field">
        <input
          {...props}
          type="range"
          min={minValue}
          max={maxValue}
          step={step}
          value={sliderValue}
          className="slider__input__range"
          disabled={isDisabled}
          onChange={handleValueChange}
        />
      </div>

      {errors && touched ? (
        <p className={`slider__error ${!hasNumberBox ? "slider__error--no-box" : ""}`}>
          {t(errors)}
        </p>
      ) : null}
    </div>
  );
};

export default FormInputSlider;
