import { ApplicationsGroupedByBucket } from "../interfaces";

interface FilteredGroupedApplications {
  groupedApplications: ApplicationsGroupedByBucket;
  extractedIDs: number[];
}

/**
 *
 * Utility function that will filter out the applications that already
 * belong to the bucket that we're targeting for bulk movement action.
 *
 * @param groupedApplications The originally received dataset of applications
 * grouped based on the bucket that they are currently part of
 *
 * @param targetedBucketID The ID of the bucket that we're targeting for the
 * bulk movement of the selected applications
 *
 */
export default function filterGroupedApplications(
  groupedApplications: ApplicationsGroupedByBucket,
  targetedBucketID: number,
): FilteredGroupedApplications {
  // Exit early if there's no valid data to work with
  if (!groupedApplications || !Object.entries(groupedApplications).length || !targetedBucketID) {
    return { groupedApplications: {}, extractedIDs: [] };
  }

  const filteredGroupedApplications: ApplicationsGroupedByBucket = {};

  // Filter out all of the applications that already belong
  // to the bucket that we're about to move the selected applications
  Object.entries(groupedApplications).forEach(entry => {
    const [key, value] = entry;

    // Convert the targeted bucket ID to a string so it
    // can be matched against the key of the originally received grouped applications by bucket
    if (key !== targetedBucketID.toString()) filteredGroupedApplications[key] = value;
  });

  // Extract the IDs of all the filtered applications that
  // will be sent in the request to the server for bucket movement
  const extractedIDs: number[] = Object.values(filteredGroupedApplications).flatMap(entry => {
    if (!Array.isArray(entry)) return [];

    return entry.map(application => application.id);
  }) as number[];

  return {
    groupedApplications: filteredGroupedApplications,
    extractedIDs: extractedIDs,
  };
}
