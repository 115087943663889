// Hooks & Utilities
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import fetchHandler from "../fetchHandler";
import { useAuth } from "../../providers/auth-context";

// Interfaces
import {
  ReportApplicantCountResponseFields,
  ReportAdActivationResponseFields,
  ReportExpiringAdsResponseFields,
  ReportIndeedSponsoredResponseFields,
  ReportJobBoardStatusResponseFields,
  ReportAdPerformanceResponseFields,
  ReportInternalHiringFields,
  ReportHiringResponseFields,
  ReportZipRecruiterSponsoredResponseFields,
  ReportSummaryResponseFields,
  ReportDateType,
} from "./interfaces";
import { ReportsDateRange } from "../../pages/Reports/interfaces";

/**
 * Get Applicant Count report using the received dates for filtering
 *
 * @param dates - Contains the "start_date" & "end_date" date range properties
 */
export function useReportGetApplicantCount(dates: ReportsDateRange) {
  return useQuery(
    ["report-applicant-count", dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/application-count?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportApplicantCountResponseFields[];
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Ad Activation report using the received dates for filtering
 *
 * @param dates - Contains the "start_date" & "end_date" date range properties
 */
export function useReportGetAdActivation(dates: ReportsDateRange) {
  return useQuery(
    ["report-ad-activation", dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/ad-activation?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportAdActivationResponseFields[];
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Expiring Ads report using the received dates for filtering
 *
 * @param dates - Contains the "start_date" & "end_date" date range properties
 */
export function useReportGetExpiringAds(dates: ReportsDateRange) {
  return useQuery(
    ["report-expiring-ads", dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/expiring-ads?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportExpiringAdsResponseFields[];
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Indeed Sponsored report using the received dates for filtering
 *
 * @param dates - Contains the "start_date" & "end_date" date range properties
 */
export function useReportGetIndeedSponsored(dates: ReportsDateRange) {
  return useQuery(
    ["report-indeed-sponsored", dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/indeed-sponsored?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportIndeedSponsoredResponseFields;
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Job Board Status report using the received dates for filtering
 */
export function useReportGetJobBoardStatus() {
  return useQuery(
    ["report-job-board-status"],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/job-board-status`,
      )) as ReportJobBoardStatusResponseFields[];
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Ad Performance report using the received dates for filtering
 */
export function useReportGetAdPerformance(
  dates: ReportsDateRange,
  clients: number[],
  jobTitle: string,
) {
  return useQuery(
    ["report-ad-performance", dates, clients, jobTitle],
    async () => {
      const mappedClients = clients ? clients.map(client => `&clients[]=${client}`).join("") : "";
      const mappedDates = `?start_date=${dates.start_date}&end_date=${dates.end_date}`;
      const mappedJobTitle = jobTitle ? `&job_title=${jobTitle}` : "";
      return (await fetchHandler(
        "GET",
        `admin/reports/ad-performance${mappedDates}${mappedClients}${mappedJobTitle}`,
      )) as ReportAdPerformanceResponseFields;
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Internal Hiring report data
 */
export function useReportGetInternalHiring(companyId?: number) {
  return useQuery(
    ["report-internal-hiring", companyId],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/internal-hiring/?company_id=${companyId}`,
      )) as ReportInternalHiringFields[];
    },
    {
      enabled: !!companyId,
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Hiring report data
 */
export function useReportGetHiring(dateType: ReportDateType, dates: ReportsDateRange) {
  const { user } = useAuth();
  const companySlug = user.active_company.slug;

  return useQuery(
    ["report-hiring", companySlug, dateType, dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `reports/${companySlug}/external-hiring?date_type=${dateType}&start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportHiringResponseFields[];
    },
    {
      enabled: !!companySlug && !!dateType,
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get ZipRecruiter Sponsored report using the received dates for filtering
 *
 * @param dates - Contains the "start_date" & "end_date" date range properties
 */
export function useReportGetZipRecruiterSponsored(dates: ReportsDateRange) {
  return useQuery(
    ["report-zip-recruiter-sponsored", dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/reports/zip-recruiter-sponsored?start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportZipRecruiterSponsoredResponseFields;
    },
    {
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 * Get Summary report data
 */
export function useReportGetSummaryReport(dateType: ReportDateType, dates: ReportsDateRange) {
  const { user } = useAuth();
  const companySlug = user.active_company.slug;

  return useQuery(
    ["report-summary", companySlug, dateType, dates],
    async () => {
      return (await fetchHandler(
        "GET",
        `reports/${companySlug}/summary?date_type=${dateType}&start_date=${dates.start_date}&end_date=${dates.end_date}`,
      )) as ReportSummaryResponseFields[];
    },
    {
      enabled: !!companySlug && !!dateType,
      keepPreviousData: true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}
