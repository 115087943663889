// Components
import { Field } from "formik";
import FormCheckbox from "../Form/FormCheckbox";

const TermsOfService = () => {
  return (
    <div className="online-application__tos">
      <div className="online-application__tos__header">
        <h5>Disclosure, Terms of Use, Acknowledgements</h5>
      </div>

      <div className="online-application__tos__content">
        <p>
          This section contains the Employment Application - Disclosure, Terms of Use and
          Acknowledgement. The{" "}
          <a href="https://jobs.firstchoicehiring.com/terms-of-use/" target="_blank">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href="https://jobs.firstchoicehiring.com/privacy-policy" target="_blank">
            Privacy Policy
          </a>{" "}
          are incorporated herein by reference.
        </p>

        <p>
          I certify that all information I have provided is true, complete, and correct. I
          understand that any false or misleading information on this application will be cause for
          denial of employment or immediate termination of employment. I understand that the
          acceptance of my application is not an offer of employment. I also understand that, if I
          am hired, my employment will be at-will, meaning that either party can end the employment
          relationship at any time and for any or no reason.
        </p>

        <p>
          I expressly authorize, without reservation, the employer, its representatives, employees,
          or agents to contact and obtain information from all available sources, including, but not
          limited to, references provided, previous employers, public records, governmental
          agencies, and educational institutions to evaluate my application for employment. I hereby
          authorize all parties to provide such information, and agree to waive all rights and
          claims against any individuals, corporations, or organizations for furnishing such
          information.
        </p>

        <p>
          I understand this Employer is an equal opportunity employer which does not discriminate in
          its employment practices, or activities on the basis of race, color, national or ethnic
          origin, ancestry, age, religion or religious creed, disability or handicap, sex, gender
          identity and/or expression, sexual orientation, military or veteran status, genetic
          information, or any other characteristic protected under applicable federal, state, or
          local law.
        </p>

        <p>
          I affirm that I have read and agree to the acknowledgments, terms of use and disclosures
          contained herein.
        </p>
      </div>

      <div className="online-application__tos__footer">
        <Field
          component={FormCheckbox}
          name="terms_and_conditions"
          id="terms_and_conditions"
          label="I accept the terms and conditions."
        />
      </div>
    </div>
  );
};

export default TermsOfService;
