import { useEffect, useState } from "react";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";
import { ApplicationSortBy, ApplicationSortDirection } from "../interfaces";

type ApplicationsSortingHookReturnType = [
  ApplicationSortBy,
  ApplicationSortDirection,
  (sortBy: ApplicationSortBy) => void,
];

/**
 *
 * Application-specific hook for handling the sorting of the listed Application,
 * based on the registration date value, or the SBCA score.
 *
 *
 */
export function useApplicationsSorting(): ApplicationsSortingHookReturnType {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();
  const [sortBy, setSortBy] = useState<ApplicationSortBy>("registration_date");
  const [sortDirection, setSortDirection] = useState<ApplicationSortDirection>("desc");

  const handleSort = (selectedSortType: ApplicationSortBy) => {
    // If user clicked on the same "sort by" option, then just change the sorting direction
    // Otherwise, update the sort by value and reset the sorting direction
    if (sortBy === selectedSortType) {
      const direction: ApplicationSortDirection = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(direction);

      // Update the search parameters state object
      setSearchParametersObject({
        ...searchParametersObject,
        sort_by: selectedSortType,
        sort_direction: direction,
      });
    } else {
      setSortBy(selectedSortType);

      // If user decides to sort applications by "registration date", default to "descending" direction
      // If user decides to sort applications by "SBCA score", default to "ascending" direction
      // beause lower SBCA score implies better application.
      setSortDirection(selectedSortType === "registration_date" ? "desc" : "asc");

      // Update the search parameters state object
      setSearchParametersObject({
        ...searchParametersObject,
        sort_by: selectedSortType,
        sort_direction: selectedSortType === "registration_date" ? "desc" : "asc",
      });
    }
  };

  // Pre-select the sorting type and direction if received as search parameters
  useEffect(() => {
    if (searchParametersObject.sort_by && searchParametersObject.sort_direction) {
      setSortBy(searchParametersObject.sort_by);
      setSortDirection(searchParametersObject.sort_direction);
    }
  }, []);

  return [sortBy, sortDirection, handleSort];
}
