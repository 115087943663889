import { ApplicationRouteProps } from "./interfaces";

// Pages
import AssessmentResults from "../pages/Assessment/AssessmentResults";
import AssessmentResultsPrint from "../pages/Assessment/AssessmentResultsPrint";
import LayoutAssessment from "../layout/LayoutAssessment";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import AssessmentRequest from "../pages/Assessment/AssessmentRequest";

export const ROUTES_ASSESSMENT: ApplicationRouteProps[] = [
  {
    path: "/assessment/request",
    element: (
      <LayoutAuthenticated>
        <AssessmentRequest />
      </LayoutAuthenticated>
    ),
    permissions: ["send_sbca_link_request"],
    type: "protected",
  },
  {
    path: "/assessment/results/:sbcaReportIdentifier",
    element: (
      <LayoutAssessment>
        <AssessmentResults />
      </LayoutAssessment>
    ),
    permissions: ["applicant_dashboard_view"],
    type: "protected",
  },
  {
    path: "/assessment/results/:sbcaReportIdentifier/print",
    element: <AssessmentResultsPrint />,
    permissions: ["applicant_dashboard_view"],
    type: "protected",
  },
];
