import Skeleton from "react-loading-skeleton";

const SkeletonChatMessageListItem = () => {
  return (
    <div className="chat-message-list-item chat-message-list-item--skeleton">
      <Skeleton width="55px" height="55px" circle />

      <div className="chat-message-list-item__details">
        <div className="chat-message-list-item__header">
          <Skeleton width="200px" height="18px" />
          <Skeleton width="20px" height="8px" />
        </div>

        <p className="chat-message-list-item__last-message">
          <Skeleton width="250px" height="15px" />
        </p>
        <p className="chat-message-list-item__date">
          <Skeleton width="100px" height="10px" />
        </p>
      </div>
    </div>
  );
};

export default SkeletonChatMessageListItem;
