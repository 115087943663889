import { ApplicationDetailsMappedFields } from "../interfaces";

// Find the targeted application from the received list of applications,
// and extract that application's index position in the list.
export function handleFindCurrentApplication(
  applications: ApplicationDetailsMappedFields[],
  id: number,
) {
  // Exit function if there are no applications
  if (!applications.length) return null;

  const applicationIndex = applications.findIndex(application => application.id === id);
  return applicationIndex;
}

// Find the first previous application that has a valid resume value,
// from the currently targeted application's position.
export function handleFindPreviousApplicationWithResume(
  applications: ApplicationDetailsMappedFields[],
  id: number,
) {
  // Find the index of the currently targeted application,
  // or return "null" value if the application cannot be found
  const currentApplicationIndex = handleFindCurrentApplication(applications, id);

  if (currentApplicationIndex == null || currentApplicationIndex < 0) return null;

  // Get the part of the applications list from its start to the targeted application's index position
  const slicedApplicationsFromStart = [...applications].slice(0, currentApplicationIndex);

  // Filter out the applications so we only get the ones that have a valid resume value
  const filteredApplications = slicedApplicationsFromStart.filter(application => {
    return application.resume;
  });

  // Return the next to last element in the array of applications that have a valid resume
  // as this is the closest previous application with a valid resume that we can move to
  const lastApplication = filteredApplications[filteredApplications.length - 1];

  // If the targeted last application cannot be found, return "null"
  // which would represent there's no previous application with a resume to be moved to.
  // Otherwise return the prevoius application's ID value
  if (!lastApplication) {
    return null;
  } else {
    return lastApplication.id;
  }
}

// Find the first next application that has a valid resume value,
// from the currently targeted application's position.
export function handleFindNextApplicationWithResume(
  applications: ApplicationDetailsMappedFields[],
  id: number,
) {
  // Make a copy of the applications list, and find the index of
  // the currently targeted application or exit function and return "null" if
  // application can't be found.
  const applicationsCopy = [...applications];
  const currentApplicationIndex = handleFindCurrentApplication(applications, id);

  if (currentApplicationIndex == null || currentApplicationIndex < 0) return null;

  // Get the part of the applications list from the CURRENT application's index point to the end of the list
  const slicedApplicationsFromCurrentToEnd = applicationsCopy.slice(
    // NOTE: We increment the index by 1 here so we can
    // start from the following application and go all the way to the end of the list
    currentApplicationIndex + 1,
    applicationsCopy.length,
  );

  // Return the first element in the sliced array of applications that have a valid resume
  // as this first element is the closest next application with a valid resume that we can move to
  const filteredApplications = slicedApplicationsFromCurrentToEnd.filter(application => {
    return application.resume;
  });

  // If the targeted first next closest application cannot be found,
  // we return `null` which represents there's no "Next" applciation with a resume that we can move to
  // Otherwise return the next application with a valid resume
  if (!filteredApplications[0]) {
    return null;
  } else {
    return filteredApplications[0].id;
  }
}
