// Interfaces
import { FormikValues } from "formik";
import { OnlineApplicationFormSectionFields } from "../../../api/OnlineApplication/interfaces";
import { CountryOption } from "../../../interfaces/global";

// Constants
import { COUNTRY_BASED_ADDRESS_LABELS, STATES_DROPDOWN } from "../../../constants/countries";
import {
  FORM_FIELD_DEFAULT_SUBTYPE_FIELDS_COMPONENT_PROPS,
  FORM_FIELD_DEFAULT_COMPONENT_PROPS,
  FORM_DEFAULT_FIELDS,
  FORM_DEFAULT_FIELDS_LABEL_MAPPINGS,
  FORM_DEFAULT_FIELDS_WITH_LABELS,
} from "./constants";

// Utilities
import { handleFormSelectFieldOptions } from "./handleFormSelectFieldOptions";

/**
 *
 * Handle the props that should be passed to each of the
 * dynamically rendered components, based on the form field that
 * we're working with.
 *
 * @param formField The individual form field that we're working with
 *
 * @returns Props object that will be injected into the dynamically
 * generated form field components.
 *
 */
export function handleFormFieldComponentProps(
  formField: OnlineApplicationFormSectionFields,
  values: FormikValues,
) {
  const {
    name,
    type,
    sub_type,
    placeholder,
    default_value,
    label,
    options,
    datetime_format,
    datetime_mode,
  } = formField;

  // If there's a valid "sub_type" value received from the
  // API response, which represents fields that are more specific
  // e.g. "LinkedIn" field is a "text" type of field, but it has
  // more specific properties associated with it, then we return
  // the props that are predefined for this field, which is a "DEFAULT"
  // field by the system
  if (sub_type) return FORM_FIELD_DEFAULT_SUBTYPE_FIELDS_COMPONENT_PROPS[sub_type];

  // Otherwise we apply the general pre-defined props for
  // fields that we know can be used to construct a form
  // and return them so they populate the component that is generated
  const COMPONENT_PROPS: Record<string, any> = {
    ...FORM_FIELD_DEFAULT_COMPONENT_PROPS[type],
    placeholder,

    // Use one of the "default_value" or "placeholder" that are returned
    // in the response from the API (based on which has a value).
    // If none has a valid value, return a default placeholder
    title: default_value ?? placeholder ?? "",
  };

  // Include a label only for the "checkbox" types of fields
  if (["checkbox", "datetime"].includes(type)) COMPONENT_PROPS["label"] = label;

  // Include dropdown items if the field is of type "select" or "multiselect"
  if (["select", "multiselect"].includes(type)) {
    COMPONENT_PROPS["items"] = handleFormSelectFieldOptions(options);

    // Only include the label prop for those dropdown menus that are
    // not part of the form's default fields
    if (!FORM_DEFAULT_FIELDS.includes(name)) COMPONENT_PROPS["label"] = label;
  }

  // If the field is of type "text" and is one of the default fields,
  // then we append the modifier class that will accomodate the icons
  if (type === "text" && FORM_DEFAULT_FIELDS.includes(name)) {
    COMPONENT_PROPS["leftElement"] = FORM_DEFAULT_FIELDS_LABEL_MAPPINGS[name];

    // Append the label only to a specific set of the default fields that are received from the API
    if (FORM_DEFAULT_FIELDS_WITH_LABELS.includes(name)) COMPONENT_PROPS["label"] = label;

    // Handle the label text (using the "leftIcon" prop) based on selected country
    if (name === "city") {
      COMPONENT_PROPS["leftElement"] = (
        <p className="mb--0">
          {COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.city ?? "City"}
        </p>
      );
    }

    // Handle the label text (using the "leftIcon" prop) based on selected country
    if (name === "zip_code") {
      COMPONENT_PROPS["leftElement"] = (
        <p className="mb--0">
          {COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.code ?? "Zip Code"}
        </p>
      );
    }
  }

  // If the component name is "phone" we limit the user's input to 10 characters
  if (name === "phone") COMPONENT_PROPS["placeholder"] = "(xxx) xxx-xxxx";

  // If the field is a date time field, then include related props
  if (type === "datetime") {
    // Use the format received from the API response or use a default one
    COMPONENT_PROPS["dateFormat"] = datetime_format ?? "m/d/y";

    // Only enable time selection if such field was received
    COMPONENT_PROPS["enableTime"] = datetime_mode === "time";
  }

  /*===============================
    FIELD SPECIFIC CONTROL

    Used for controlled passing of props
    down to specific components based on their name
  =================================*/
  if (name.toLowerCase() === "state") {
    COMPONENT_PROPS["items"] = STATES_DROPDOWN[values.country as CountryOption] || [];
    COMPONENT_PROPS["disabled"] = !values.country;
    COMPONENT_PROPS["relatedField"] = "country";

    // Handle the dropdown title text based on selected country
    COMPONENT_PROPS["title"] = `Select ${
      COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.state ?? "State"
    }`;
  }

  if (name.toLowerCase() === "phone") delete COMPONENT_PROPS.leftElement;

  return COMPONENT_PROPS;
}
