import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const JobAdsEditSkeleton = () => {
  return (
    <div className="job-ads" data-testid="component:job-ads-edit-skeleton">
      <div className="job-ads-details__header">
        <div className="container">
          <div className="d-flex align-items-center">
            <span> Edit Ad:</span>
            <Skeleton width="250px" height="25px" className="ml--10" />
          </div>

          <div className="d-flex align-items-center">
            <Skeleton width="72px" height="35px" />
            <Skeleton width="72px" height="35px" className="ml--10" />
          </div>
        </div>
      </div>

      <div className="container py--25">
        <div className="job-ads-details__codes mb--30">
          <Skeleton width="105px" height="16px" />
          <Skeleton width="140px" height="16px" />
        </div>

        <div className="row">
          {/* Form Settings card */}
          <div className="col-12 col-lg-6">
            <Card modifierClass="card--padding--sm">
              <Skeleton width="150px" height="22px" className="mb--20" />

              <Skeleton width="100%" height="40px" className="mb--10" />
              <Skeleton width="100%" height="40px" className="mb--10" />
              <Skeleton width="100%" height="100px" className="mb--10" />
            </Card>
          </div>

          {/* Ad Library card */}
          <div className="col-12 col-lg-6">
            <Card modifierClass="card--padding--sm">
              <Skeleton width="150px" height="22px" className="mb--20" />

              <Skeleton width="100%" height="40px" className="mb--10" />
              <Skeleton width="100%" height="40px" className="mb--10" />
              <Skeleton width="100%" height="100px" className="mb--10" />
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Card modifierClass="card--padding--sm">
              <Skeleton width="150px" height="22px" className="mb--20" />

              <div className="d-flex justify-content-start justify-content-xl-between align-items-center flex-wrap">
                <div className="d-flex align-items-center mb--10 mr--20">
                  <Skeleton width="100px" height="20px" />
                  <Skeleton width="200px" height="35px" className="ml--20" />
                </div>

                <div className="d-flex align-items-center mb--10 mr--20">
                  <Skeleton width="100px" height="20px" />
                  <Skeleton width="200px" height="35px" className="ml--20" />
                </div>

                <div className="d-flex align-items-center mb--10">
                  <Skeleton width="100px" height="20px" />
                  <Skeleton width="200px" height="35px" className="ml--20" />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Card modifierClass="card--padding--sm">
              <Skeleton width="150px" height="22px" className="mb--20" />

              <Skeleton width="100px" height="12px" />
              <Skeleton width="200px" height="12px" className="mb--20" />

              <div className="row">
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="35px" />
                </div>
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="80px" />
                </div>
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="35px" />
                </div>
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="80px" />
                </div>
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="35px" />
                </div>
                <div className="col-12 col-lg-6 mb--20">
                  <Skeleton width="100%" height="80px" />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobAdsEditSkeleton;
