// Utilities & Hooks
import { useBucketsDelete } from "../../api/Buckets/Buckets";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

// Interfaces
import { BucketsDeleteModalProps } from "./interfaces";

const BucketsDeleteModal = ({
  bucketID,
  bucketName,
  handleCloseBucketDeleteModal,
}: BucketsDeleteModalProps) => {
  const errorReporting = useErrorReporting();

  /*============================
    BUCKET DELETING ACTION
  =============================*/
  const bucketDelete = useBucketsDelete();

  const handleBucketDelete = async () => {
    // Exit function if there's no valid bucket ID selected
    if (bucketID == null) return;

    try {
      await bucketDelete.mutateAsync(bucketID);

      // Close the modal and reset values after succesfully deleting bucket
      handleCloseBucketDeleteModal();
    } catch (error) {
      errorReporting("Failed deleting bucket", error, { bucket_id: bucketID });
    }
  };

  return (
    <Modal
      title="Delete Bucket?"
      text={
        <>
          This action will delete the <strong>{bucketName ?? "selected"}</strong> bucket. Do you
          want to continue?
        </>
      }
      modifierClass="modal--md"
      overlayModifierClass="modal-backdrop--nested"
      handleCloseModal={handleCloseBucketDeleteModal}
      testID="modal-delete-bucket"
    >
      <div className="d-flex flex-column-reverse flex-md-row justify-content-md-center align-items-center">
        <Button
          modifierClass="btn--fluid btn--primary--light"
          onClick={handleCloseBucketDeleteModal}
        >
          Close
        </Button>

        <Button
          modifierClass="btn--fluid btn--fluid--md btn--danger ml--10 ml--md--0 mb--md--10"
          onClick={handleBucketDelete}
          isLoading={bucketDelete.isLoading}
          isDisabled={bucketDelete.isLoading}
        >
          Yes, Delete Bucket
        </Button>
      </div>
    </Modal>
  );
};

export default BucketsDeleteModal;
