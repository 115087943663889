// Assets
import { MdClose as CloseIcon } from "react-icons/md";

// Interfaces
import { ModalProps } from "./interfaces";

// Hooks
import { usePreventBodyScroll } from "../../hooks/usePreventBodyScroll";
import { useRef } from "react";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { motion } from "framer-motion";
import ModalBackdrop from "./ModalBackdrop";

const Modal = ({
  title,
  text = "",
  modifierClass = "",
  overlayModifierClass = "",
  children,
  testID = "modal",
  handleCloseModal,
}: ModalProps) => {
  // Prevent the body of the page from scrolling when
  // the modal component is displayed in the UI.
  usePreventBodyScroll();

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  const modalRef = useRef<HTMLDivElement>(null);
  useOnEscapeKey(modalRef, handleCloseModal);

  return (
    <>
      <ModalBackdrop
        handleCloseModal={handleCloseModal}
        overlayModifierClass={overlayModifierClass}
      />

      <motion.div
        data-testid={`component:${testID}`}
        ref={modalRef}
        tabIndex={0}
        key={`framer-modal-${title}`}
        className={`modal ${modifierClass}`}
        initial={{
          opacity: 0,
          translateX: "-50%",
          translateY: 0,
        }}
        animate={{
          opacity: 1,
          translateX: "-50%",
          translateY: "-50%",
        }}
        exit={{
          opacity: 0,
          translateX: "-50%",
          translateY: 0,
        }}
        transition={{ duration: 1, type: "spring" }}
      >
        <CloseIcon
          className="modal__close"
          onClick={handleCloseModal}
          data-testid="component:modal-close-icon"
        />

        <h5 className="modal__title">{title}</h5>
        {text && <div className="modal__text">{text}</div>}

        {children}
      </motion.div>
    </>
  );
};

export default Modal;
