// Hooks
import { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Interfaces
import { DropdownActionsProps, DropdownItem } from "./interfaces";

// Assets
import { IoMdArrowDropdown as DropdownToggleIcon } from "react-icons/io";

// Components
import { motion, AnimatePresence } from "framer-motion";

// Constants
import { FRAMER_DROPDOWN_ACTIONS_ANIMATION } from "../../constants/framer";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";

const DropdownActions = ({
  title,
  items,
  selectedItem = null,
  handleDropdownItem,

  customToggleElement = null,
  size = "md",
  isDisabled = false,
  orientation = "bottom",
  modifierClass = "",
}: DropdownActionsProps) => {
  /*======================================
    DROPDOWN VISIBILITY
  =======================================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleDropdownVisibility = (event: React.MouseEvent<HTMLElement>) => {
    // Prevent event propagation to parent elements
    event.stopPropagation();

    if (isDisabled) return;

    setIsDropdownOpen(!isDropdownOpen);
  };

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*======================================
    DROPDOWN ITEM FUNCTIONALITY
  =======================================*/
  const handleSelectedDropdownItem = (dropdownItem: DropdownItem) => {
    // Prevent functions from being called if the item is disabled
    if (dropdownItem.disabled) return;

    handleDropdownItem(dropdownItem);
  };

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div
      className={`dropdown-actions ${isDisabled ? "dropdown-actions--disabled" : ""} ${
        isDropdownOpen ? "dropdown-actions--open" : ""
      } ${modifierClass}`}
      ref={dropdownRef}
      onClick={handleDropdownVisibility}
      tabIndex={0}
    >
      <div className="dropdown-actions__body">
        {customToggleElement ? (
          customToggleElement
        ) : (
          <>
            <span>{title}</span>
            <DropdownToggleIcon />
          </>
        )}
      </div>

      <AnimatePresence>
        {isDropdownOpen && (
          <motion.ul
            className={`dropdown-actions__content dropdown-actions__content--orientation-${orientation} dropdown-actions__content--${size}`}
            key="framer-dropdown-actions"
            variants={FRAMER_DROPDOWN_ACTIONS_ANIMATION}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5, type: "spring" }}
          >
            {items.map((dropdownItem: DropdownItem) => (
              <li
                key={dropdownItem.value}
                className={`dropdown-actions__item ${
                  dropdownItem.disabled ? "dropdown-actions__item--disabled" : ""
                } ${dropdownItem.value === selectedItem ? "dropdown-actions__item--selected" : ""}`}
                onClick={() => handleSelectedDropdownItem(dropdownItem)}
              >
                {dropdownItem.icon && (
                  <div className="dropdown-actions__item__icon">{dropdownItem.icon}</div>
                )}
                {dropdownItem.text}
                {dropdownItem.description && (
                  <p className="dropdown-actions__item__description">{dropdownItem.description}</p>
                )}
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DropdownActions;
