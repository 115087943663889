import { useState } from "react";
import { ApplicationResumeModalTypes } from "../interfaces";

/**
 *
 * Hook for handling which Applications' resume modal ("preview" or "request")
 * should be opened and displayed in the UI.
 *
 */
export function useApplicationResumeModal(): [
  ApplicationResumeModalTypes | null,
  (modalType: ApplicationResumeModalTypes | null) => void,
] {
  const [resumeModalType, setResumeModalType] = useState<ApplicationResumeModalTypes | null>(null);

  const handleResumeModal = (modalType: ApplicationResumeModalTypes | null) => {
    setResumeModalType(modalType);
  };

  return [resumeModalType, handleResumeModal];
}
