// Utilities & Hooks
import { FetchErrorType } from "../errorHandling";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import { handleAuthenticationTokensRemovalFromStorage } from "../tokenRefresher";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";
import fetchHandler from "../fetchHandler";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { AuthenticationResetPasswordPayloadFields } from "./interfaces";
import { LoginRequestBody } from "../../pages/Authentication/interfaces";
import { AuthenticationAPIResponse } from "../types";

/**
 *
 * Handle User login into the application and
 * saving authentication tokens in local storage
 *
 */
export function useAuthenticationLogin() {
  const { handleAuthenticationTokens } = useAuth();

  return useMutation(
    async (credentials: LoginRequestBody) => {
      return (await fetchHandler("POST", "auth/login", credentials)) as AuthenticationAPIResponse;
    },
    {
      onSuccess: ({ access_token, refresh_token, expires_in, is_enabled }) => {
        // Exit function and show info modal that the user is not active
        if (is_enabled === false) {
          return { is_enabled: false };
        }

        // Throw error if there are no tokens available
        if (!access_token || !refresh_token) throw new Error("No token(s) received from the API.");

        // Save authentication tokens in local storage and update authentication context
        handleAuthenticationTokens(access_token, refresh_token, expires_in);
      },
      onError: error => error,
    },
  );
}

/**
 *
 * Send autologin request for the selected user
 *
 * @param email The email of the user that we want to login as
 * @param token The token used for allowing autologin functionality
 *
 */
export function useAuthenticationAutologin(
  email: string,
  token: string,
  existingAccessToken: string | null,
) {
  const { handleAuthenticationTokens } = useAuth();
  const errorReporting = useErrorReporting();
  const navigate = useNavigate();

  return useQuery(
    ["auth-autologin", email, token],
    async () =>
      await fetchHandler("GET", `autologin?email=${encodeURIComponent(email)}&token=${token}`),
    {
      // Only send the API request if there are valid email and token values,
      // and there's no existing access token saved in local storage for the user
      enabled: !existingAccessToken && !!email && !!token,
      onSuccess: data => {
        const { access_token, refresh_token, expires_in, autologin } = data;

        // Throw error if there are no tokens available
        if (!access_token) throw new Error("No token(s) received from the API.");

        // Update authentication context with the received tokens allowing application access
        handleAuthenticationTokens(access_token, refresh_token, expires_in, autologin);

        return data;
      },
      onError: (error: FetchErrorType) => {
        errorReporting("Autologin failed", error, { email, token });

        // Redirect the user to the forbidden page
        if (error.response.status === 403) navigate("/403");

        return error;
      },
      retry: 0,
      cacheTime: 0,
    },
  );
}

/**
 *
 * Sends an email to the provided email for resetting the password
 *
 */
export function useAuthenticationForgotPassword() {
  return useMutation(
    async (email: string) => {
      return fetchHandler("POST", "auth/forgot-password", { email });
    },
    {
      onSuccess: () => {
        toast.success("If an account exists you will receive an email with a password reset link.");
      },
      onError: error => error,
    },
  );
}

/**
 *
 * Resets the user's password based on the
 * received email's "token" value that is used as
 * identifier to which user should the password be updated
 *
 */
export function useAuthenticationResetPassword() {
  const navigate = useNavigate();

  return useMutation(
    async (resetPasswordDetails: AuthenticationResetPasswordPayloadFields) => {
      return await fetchHandler("POST", "auth/reset-password", resetPasswordDetails);
    },
    {
      onSuccess: () => {
        toast.success("Password updated! You will be redirected to login page shortly...");

        setTimeout(() => {
          navigate("/login/");
        }, 3000);
      },
      onError: error => error,
    },
  );
}

/**
 *
 * Logout the user from the application
 *
 */
export function useAuthenticationLogout() {
  const { handleAuthenticationTokens, handleClearAuthenticationContext } = useAuth();

  // Check if "access" token exists in local storage
  const accessToken = LocalStorageActions.getItem("accessToken");

  return useMutation(
    async () => {
      // Prevent API request if there's no access token in storage
      if (!accessToken) return;

      return fetchHandler("POST", "auth/logout");
    },
    {
      onSuccess: () => {
        // Update the context to signal that the user has logged out
        handleAuthenticationTokens("", "", 0);

        // Remove the saved properties from local storage
        handleAuthenticationTokensRemovalFromStorage();

        // Clear the context state
        handleClearAuthenticationContext();
      },
      onError: error => error,
    },
  );
}
