/**
 *
 * Utility function for extracting the extension of the selected file,
 * based on the received `filename` value for the selection that was made.
 *
 * For example, for selected `test.docx` file, the utility function
 * will extract its extension, and return only `docx` as the value.
 *
 * @param filename The name of the file that was selected
 *
 * @returns The file's extension
 *
 */
export default function handleExtractFileExtension(filename: string) {
  // Exit function if there's no valid filename value provided
  if (!filename) return "N/A";

  const splitFilename = filename.split(".");
  const filenameExtension: string = splitFilename[splitFilename.length - 1];

  // We always convert the filename extension to lowercase
  // to ensure edge cases such as JPG vs jpg not being handled correctly.
  return filenameExtension.toLowerCase();
}
