import { Field } from "formik";
import FormInputSlider from "../Form/FormInputSlider";

// Interfaces
import { OnlineApplicationAssessmentQuestionProps } from "./interfaces";

const OnlineApplicationAssessmentQuestion = ({
  question_name,
  question_text,
}: OnlineApplicationAssessmentQuestionProps) => {
  return (
    <div key={question_name} className="online-application-assessment__question-box">
      <div className="d-flex align-items-start">
        <p>{question_name.toUpperCase()}. </p>
        <p> {question_text}</p>
      </div>

      <Field component={FormInputSlider} id={question_name} name={question_name} />
    </div>
  );
};

export default OnlineApplicationAssessmentQuestion;
