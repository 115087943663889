// Components
import { motion, Variants } from "framer-motion";
import Button from "../Button/Button";
import ModalBackdrop from "./ModalBackdrop";

// Assets
import { FaRegUserCircle as UserIcon } from "react-icons/fa";

const FRAMER_MOTION_INACTIVE_USER_MODAL: Variants = {
  initial: {
    opacity: 0,
    translateX: "-50%",
    translateY: 0,
  },
  animate: {
    opacity: 1,
    translateX: "-50%",
    translateY: "-50%",
  },
  exit: {
    opacity: 0,
    translateX: "-50%",
    translateY: 0,
  },
};

const FRAMER_MOTION_INACTIVE_USER_MODAL_ICON: Variants = {
  initial: {
    opacity: 0,
    scale: 0,
    y: 20,
  },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
  },
};

const FRAMER_MOTION_INACTIVE_USER_MODAL_ELEMENTS: Variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const ModalInactiveUser = ({ handleModalState }: { handleModalState: () => void }) => {
  return (
    <div>
      <ModalBackdrop handleCloseModal={handleModalState} />

      <motion.div
        variants={FRAMER_MOTION_INACTIVE_USER_MODAL}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{
          duration: 1,
          type: "spring",
          staggerChildren: 0.2,
          delayChildren: 0.25,
        }}
        className="modal modal--fixated modal--inactive-user"
        data-testid="component:modal-inactive-user"
      >
        <motion.div
          variants={FRAMER_MOTION_INACTIVE_USER_MODAL_ICON}
          transition={{ duration: 0.4, type: "spring" }}
        >
          <UserIcon className="modal__icon" />
        </motion.div>

        <motion.h2 variants={FRAMER_MOTION_INACTIVE_USER_MODAL_ELEMENTS} className="fw--black">
          Password Reset Required
        </motion.h2>

        <motion.p variants={FRAMER_MOTION_INACTIVE_USER_MODAL_ELEMENTS}>
          We've been busy improving FirstChoice Hiring! We just sent you an email. <br />
          Check your inbox now for your password reset message.
          <br />
          <br />
          Need help? Contact Support!
          <br />
          <br />
          <span>
            <a href="tel:+18774497595" className="txt--link mr--5">
              (877) 449-7595
            </a>
            or
            <a href="mailto:support@proexel.com" className="txt--link ml--5">
              support@proexel.com
            </a>
          </span>
        </motion.p>

        <Button modifierClass="btn--fluid btn--fluid--md btn--primary" onClick={handleModalState}>
          Close
        </Button>
      </motion.div>
    </div>
  );
};

export default ModalInactiveUser;
