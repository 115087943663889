import { TextEditorCustomImagePluginFields } from "../interfaces";

// Utilities
import { toast } from "react-toastify";
import { LocalStorageActions } from "../../../utilities/handleLocalStorage";
import handleErrorMessage from "../../../utilities/handleErrorMessage";

const CUSTOM_IMAGE_BODY = {
  type: "panel",
  items: [
    {
      type: "urlinput",
      name: "image_source",
      label: "Source",
      filetype: "image",
    },
    {
      type: "input",
      name: "image_alternative_description",
      label: "Alternative Description",
    },
    {
      type: "input",
      name: "image_title",
      label: "Image Title",
    },
    {
      type: "sizeinput",
      name: "image_size_input",
    },
  ],
};

const CUSTOM_IMAGE_BUTTONS = [
  {
    type: "cancel",
    name: "cancel",
    text: "Cancel",
    primary: false,
  },
  {
    type: "submit",
    name: "submit",
    text: "Save",
    primary: true,
  },
];

/**
 * Utility function to handle the "Cancel" button event
 * for the custom dialog window that opens up in TinyMCE for image upload
 * @param event TinyMCE's internal dialog button event handler.
 *
 * Type is `DialogChangeHandler<unknown> | undefined` but unavailable.
 */
async function handleCustomImagePluginCancelEvent(event: any, editor: any) {
  const { image_source } = event.getData() as TextEditorCustomImagePluginFields;

  // Exit function if there's no value for the image source
  if (!image_source.value) return;

  const IMAGE_DELETE_URL: string = image_source.meta.delete_url;

  // Temporary disable the UI while the request is ongoing
  editor.ui.setEnabled(false);

  // Send a request to delete the image in case
  // the user does not want to include it in the article's content
  try {
    const accessToken = LocalStorageActions.getItem("accessToken");

    await fetch(IMAGE_DELETE_URL, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    toast.error(handleErrorMessage(error) ?? "Could not delete the uploaded image.");
  } finally {
    // Re-enable the editor's UI in case
    // user decided to close the dialog while an image upload is ongoing
    editor.ui.setEnabled(true);
  }
}

/**
 * Utility function to handle the "Change" event
 * for the custom dialog window that opens up in TinyMCE for image upload,
 * which is triggered anytime something is updated (e.g. image finished uploading)
 * @param event TinyMCE's internal dialog button event handler.
 *
 * Type is `DialogChangeHandler<unknown> | undefined` but unavailable.
 */
function handleCustomImagePluginChangeEvent(event: any) {
  // Read the image data (title, dimensions) from the source's meta
  const { image_source } = event.getData() as TextEditorCustomImagePluginFields;

  // Exit function if there's no meta data available
  if (!image_source.meta || !Object.entries(image_source.meta).length) return;

  // Populate the title field with the uploaded
  // file name, or if it's a pasted external link, use that to populate the field
  event.setData({ image_title: image_source.meta.title || image_source.meta.text });

  // Populate the dimensions field (if present)
  event.setData({
    image_size_input: {
      ...(image_source.meta.width != null && { width: `${image_source.meta.width}` }),
      ...(image_source.meta.height != null && { height: `${image_source.meta.height}` }),
    },
  });
}

/**
 * Utility function to handle the "Submit" button event
 * for the custom dialog window that opens up in TinyMCE for image upload
 * @param event TinyMCE's internal dialog button event handler.
 *
 * Type is `DialogChangeHandler<unknown> | undefined` but unavailable.
 */
function handleCustomImagePluginSubmitEvent(event: any, editor: any) {
  const { image_source, image_size_input, image_title, image_alternative_description } =
    event.getData() as TextEditorCustomImagePluginFields;

  // Set the attributes of the image that is to be added into the editor
  const IMAGE_URL: string = image_source.value;
  const IMAGE_TITLE: string = image_title;
  const IMAGE_WIDTH: string = `${image_size_input.width}`;
  const IMAGE_HEIGHT: string = `${image_size_input.height}`;
  const IMAGE_ALT_DESCRIPTION: string = image_alternative_description;

  editor.insertContent(
    `<img src=${IMAGE_URL} width=${IMAGE_WIDTH} height=${IMAGE_HEIGHT} title=${IMAGE_TITLE} alt=${IMAGE_ALT_DESCRIPTION} />`,
  );

  // Closes the dialog window
  event.close();
}

/**
 * Opens up a new dialog window from TinyMCE for the configured custom image plugin button,
 * that can handle uploading an image to the server, and inserting the direct URL to the image
 * in the text editor's content, or trigger a delete request to the server if user cancels the action
 * @param editor The TinyMCE editor instance
 */
export function handleCustomImagePlugin(editor: any) {
  return editor.windowManager.open({
    title: "Image Upload",
    body: CUSTOM_IMAGE_BODY,
    buttons: CUSTOM_IMAGE_BUTTONS,

    // Triggered when clicked on "Cancel" button
    onCancel: (event: any) => handleCustomImagePluginCancelEvent(event, editor),

    // Triggered on any change that occurs
    onChange: (event: any) => handleCustomImagePluginChangeEvent(event),

    // Triggered when clicked on "Save" button
    onSubmit: (event: any) => handleCustomImagePluginSubmitEvent(event, editor),
  });
}
