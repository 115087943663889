// TanStack Table components and utilities
import {
  Column,
  ColumnPinningState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Utilities & Hooks
import { useEffect, useState } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import useWindowResize from "../../hooks/useWindowResize";

// Components
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";

// Interfaces
import { TableColumnMetaProperties, TableModifiedData, TableProps } from "./interfaces";
import { type CSSProperties } from "react";

// Assets
import {
  FaSort as SortDefaultIcon,
  FaSortDown as SortDescendingIcon,
  FaSortUp as SortAscendingIcon,
} from "react-icons/fa";
import TableNoDataMessage from "./TableNoDataMessage";

// Extending the interface for the table meta field, used for conditional row styles
declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    getRowStyles: (row: Row<TData>) => CSSProperties;
  }
}

const Table = ({
  data,
  columns,
  isRefetching,
  noDataMessage = "No data available",
  paginationPageSize = 10,
  title = "",
  modifierClass = "",
  shouldHandlePageRouteParameter = true,
  shouldShowSummarizedData = false,
  handleExportPaginationState,
  handleExportData,
}: TableProps) => {
  /*=======================
    SORTING HANDLING
  ========================*/
  const [sorting, setSorting] = useState<SortingState>([]);

  /*=======================
    PAGINATION HANDLING
  ========================*/
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: paginationPageSize,
  });

  // NOTE: Extraction of pagination state outside of the table component, used for slicing the extracted table data
  // TODO: Refactor extraction approach
  useEffect(() => {
    if (!handleExportPaginationState) return;

    handleExportPaginationState(pagination);
  }, [pagination]);

  useEffect(() => {
    const PAGINATION_UPDATE = { ...pagination, pageSize: paginationPageSize };
    setPagination(PAGINATION_UPDATE);
  }, [paginationPageSize]);

  /*==================================
    HANDLE TABLE'S PAGE AS 
    ROUTE PARAMETER FOR DIRECT ACCESS
  ===================================*/
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*==============================================
    MODIFIED DATA FOR DATA SUMMARIZATION FOOTER
  ===============================================*/
  const [modifiedData, setModifiedData] = useState<TableModifiedData>({
    data: [],
    summarizedData: null,
  });

  // In case there should be a summarization footer
  // separate the main data & the footer data in the 'modifiedData' state
  useEffect(() => {
    setModifiedData({
      data: shouldShowSummarizedData ? data.slice(0, data.length - 1) : data,
      summarizedData: shouldShowSummarizedData ? data[data.length - 1] : null,
    });
  }, [data]);

  // Expanded rows state, supply a 'subRows' array in the data to make a row expandable
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Reset the expanded state upon data change
  useEffect(() => {
    setExpanded({});
  }, [data]);

  // To pin a sticky column, supply the 'isSticky:true' field in the ColumnDef and an unique column id
  // Note: Only sticky pinned right aligned columns supported for now
  // TODO: Extend the 'ColumnDef' interface for better typesafety
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [],
    right: [],
  });

  // Use the 'windowWidth' to 'unsticky' the columns on small screens
  const [windowWidth] = useWindowResize(300);

  useEffect(() => {
    if (windowWidth < 768) {
      setColumnPinning({ left: [], right: [] });
    } else {
      setColumnPinning({
        left: [],
        right: columns.filter((column: any) => column.isSticky).map((column: any) => column.id),
      });
    }
  }, [columns, windowWidth]);

  /*=======================
    TABLE INITIALIZATION
  ========================*/
  const table = useReactTable({
    data: modifiedData.data,
    columns,
    state: { sorting, pagination, expanded, columnPinning },
    meta: {
      // Apply conditional row styles here based on row state or data
      getRowStyles: (row: Row<typeof data>) => {
        // Custom coloring & border for expanded rows and custom border for main expanded row
        if (row.getCanExpand() || row.depth === 1) {
          return {
            borderLeft:
              row.getIsExpanded() || row.depth === 1
                ? "3px solid #0166a7"
                : "3px solid transparent",
            backgroundColor:
              row.depth === 1 ? "#f7f7f7" : row.getIsExpanded() ? "#c3dbea" : "white",
            fontWeight: row.getIsExpanded() ? "700" : "",
          };
        }

        return {};
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    onExpandedChange: setExpanded,
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: false,

    onColumnPinningChange: setColumnPinning,
  });

  /*====================
    EXPORT TABLE DATA
  =====================*/
  useEffect(() => {
    // Exit if no handler is provided
    if (!handleExportData) return;

    const mappedTableData = table.getSortedRowModel().rows.map(entity => {
      return entity.original;
    });

    // Append the removed last element in case the
    // 'shouldShowSummarizedData' prop is recieved before exporting data
    if (shouldShowSummarizedData) {
      mappedTableData.push(data[data.length - 1]);
    }
    // Call handler from prop with current table data sorting
    handleExportData(mappedTableData);
  }, [table.getSortedRowModel()]);

  // Handle the table pagination and "page" URL parameter updates
  // in order to stay consistent and always use the correct value
  useEffect(() => {
    const totalTablePagesCount: number = table.getPageCount();

    // Early exit if there are no pages available for the table yet
    if (!totalTablePagesCount) return;

    // Early exit if theres no "page" route parameter or if pagination shouldn't be handled trough it
    if (!shouldHandlePageRouteParameter || !searchParametersObject.page) return;

    // Parse the received "page" parameter value to a number
    const parsedPageParameter = parseInt(searchParametersObject.page);

    // If the "page" parameter is not a valid number value or it is a negative number
    // then update the parameter to "page=1" which will also reset the internal table page index
    if (!parsedPageParameter || parsedPageParameter < 0) {
      table.resetPageIndex();
      setSearchParameters({ ...searchParametersObject, page: 1 });
      return;
    }

    // We normalize the received page parameter by subtracting one page
    // so it can correctly match the pagination component and the table page, as the pagination uses 0-based index
    const normalizedPageParameter: number = parsedPageParameter - 1;

    // Do not update the table's state if the received page parameter
    // matches the currently opened page in the table
    if (normalizedPageParameter === table.getState().pagination.pageIndex) return;

    // If the "page" URL parameter is higher in value than the total number of table pages - default to last page
    // Otherwise update the table to the page that was received as URL parameter
    let updatedPageParameter = parsedPageParameter;
    if (normalizedPageParameter > totalTablePagesCount) {
      table.setPageIndex(totalTablePagesCount - 1);
      updatedPageParameter = totalTablePagesCount;
    } else {
      table.setPageIndex(parsedPageParameter - 1);
    }

    // Update the used "page" route parameter
    setSearchParameters({ ...searchParametersObject, page: updatedPageParameter });
  }, [table.getPageCount()]);

  // Handler for overwriting original styling & applying customs styles for sticky positioned columns
  const getPinnedStickyStyle = (column: Column<any>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");

    return {
      boxShadow: isLastLeftPinnedColumn
        ? "-2px 0 3px -2px gray inset"
        : isFirstRightPinnedColumn
        ? "2px 0 2px -2px gray inset"
        : undefined,
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      // NOTE: The minus 20px below is to compensate for the table-wrapper css class padding
      right: isPinned === "right" ? `${column.getAfter("right") - 20}px` : undefined,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
    };
  };

  return (
    <>
      <div className={`table-wrapper ${modifierClass}`}>
        {title && <h3 className="table__title">{title}</h3>}

        {data.length > 0 && isRefetching ? (
          <Loader modifierWrapper="loader--table" size="lg" speed="medium" />
        ) : null}

        <table className={`table ${isRefetching ? "table--refetching" : ""}`}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={`${header.column.getCanSort() ? "sortable" : ""}`}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: header.getSize(),
                      backgroundColor: "white",
                      ...getPinnedStickyStyle(header.column),
                    }}
                  >
                    <div
                      className={`d-flex ${
                        (header.column.columnDef.meta as TableColumnMetaProperties)
                          ?.headerModifierClass ?? ""
                      }`}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {header.column.getCanSort() && (
                        <div className="table__sort">
                          {{
                            asc: <SortAscendingIcon />,
                            desc: <SortDescendingIcon />,
                          }[header.column.getIsSorted() as string] ?? <SortDefaultIcon />}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {data.length > 0 ? (
            <>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id} style={{ ...table.options.meta?.getRowStyles(row) }}>
                    {row.getVisibleCells().map(cell => (
                      <td
                        key={cell.id}
                        style={{
                          backgroundColor: table.options.meta?.getRowStyles(row).backgroundColor,
                          ...getPinnedStickyStyle(cell.column),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>

              {shouldShowSummarizedData && modifiedData.summarizedData ? (
                <tfoot>
                  {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                      {Object.keys(modifiedData.summarizedData).map((_footerKey, index) => {
                        return (
                          <td key={footerGroup.headers[index].id}>
                            {modifiedData.summarizedData[footerGroup.headers[index].id]}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tfoot>
              ) : null}
            </>
          ) : null}
        </table>

        {data.length === 0 ? (
          <TableNoDataMessage message={noDataMessage} isRefetching={isRefetching} />
        ) : null}
      </div>

      {table.getPageCount() > 1 && (
        <div className="d-flex justify-content-end">
          <Pagination
            currentPage={table.getState().pagination.pageIndex}
            pageCount={table.getPageCount()}
            handlePageChange={({ selected }) => table.setPageIndex(selected)}
            shouldHandlePageRouteParameter={shouldHandlePageRouteParameter}
          />
        </div>
      )}
    </>
  );
};

export default Table;
