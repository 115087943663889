import { useEffect } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { TabItemProps, TabsProps } from "./interfaces";

const Tabs = ({
  tabs,
  activeTabId,
  handleTabSelection,
  modifierClass = "",
  searchParamKey = "tab",
}: TabsProps) => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  const handleActiveTabSelection = (tab: TabItemProps) => {
    // Prevent callback function from being called if item is disabled
    if (tab.disabled) return;

    // Call the callback function passed as a prop
    handleTabSelection(tab);

    // Update the search parameters to include the selected tab
    delete searchParametersObject.search;
    setSearchParametersObject({ ...searchParametersObject, [searchParamKey]: tab.id });
  };

  // Pre-select the tab that is received as a search parameter
  useEffect(() => {
    if (searchParametersObject[searchParamKey]) {
      // Find the matching tab based on received search parameter
      // or default to first available tab received as a prop
      const matchingTab: TabItemProps | undefined =
        tabs.find(tab => {
          return tab.id.toLowerCase() === searchParametersObject[searchParamKey].toLowerCase();
        }) || tabs[0];

      // If no matches found, exit function
      if (!matchingTab || searchParametersObject[searchParamKey] === activeTabId) return;

      // Update the search parameters with the specific tab's ID, and trigger the callback for tab selection
      setSearchParametersObject({ ...searchParametersObject, [searchParamKey]: matchingTab.id });
      handleTabSelection(matchingTab);
    }
  }, []);

  return (
    <div className={`tabs ${modifierClass}`}>
      {tabs.map((tab: TabItemProps) => (
        <div
          key={tab.id}
          className={`tabs__item ${tab.modifierClass ? tab.modifierClass : ""} ${
            tab.id === activeTabId ? "tabs__item--active" : ""
          } ${tab.disabled ? "tabs__item--disabled" : ""}`}
          onClick={() => handleActiveTabSelection(tab)}
          aria-selected={tab.id === activeTabId}
        >
          {tab.icon && tab.icon}
          <span className="tabs__text">{tab.text}</span>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
