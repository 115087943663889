// Hooks and utilities
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  JobPositionFields,
  JobPositionCreateRequestFields,
  JobPositionUpdateRequestFields,
  JobPositionDeleteRequestFields,
  JobPositionsCompaniesCreateRequestFields,
  JobPositionsCompaniesUpdateRequestFields,
  JobPositionsCompaniesDeleteRequestFields,
  JobPositionsCompanies,
  JobPositionsCompaniesBulkDeleteRequestFields,
} from "./interfaces";

/*==========================================
  JOB POSITIONS: DEFAULT (PRE-DEFINED)
===========================================*/

/**
 *
 * Get all of the "Default" (pre-defined) job positions that exist in the system
 *
 */
export function useJobPositionsDefaultGet() {
  return useQuery(["job-positions-default"], async () => {
    return (await fetchHandler("GET", "admin/job-positions")) as JobPositionFields[];
  });
}

/**
 *
 * Create new "Default" job positions
 *
 */
export function useJobPositionsDefaultCreate() {
  const queryClient = useQueryClient();

  return useMutation(
    async (createDetails: JobPositionCreateRequestFields) => {
      return await fetchHandler("POST", "admin/job-positions", { name: createDetails.name });
    },
    {
      onMutate: createDetails => {
        // Get the cached default job positions
        const cachedDefaultJobPositions = queryClient.getQueryData([
          "job-positions-default",
        ]) as JobPositionFields[];

        // Add the newly created job position to the list of existing ones
        const updatedList = cloneDeep(cachedDefaultJobPositions) ?? [];
        updatedList.push({ id: 0, name: createDetails.name });

        // Update the cached data
        queryClient.setQueryData(["job-positions-default"], updatedList);

        // Show success notification in the UI
        toast.success("Successfully created job position!", {
          toastId: "job-positions-default-create",
        });

        // In case of an error, return the initial cached data
        return { cachedDefaultJobPositions };
      },
      onError: (error: unknown, _createDetails, context) => {
        toast.dismiss("job-positions-default-create");

        queryClient.setQueryData(["job-positions-default"], context?.cachedDefaultJobPositions);
        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["job-positions-companies"],
          refetchType: "all", // This will cause refetch of the data even though we're not using it at the moment at this view
        });
      },
    },
  );
}

/**
 *
 * Edit the targeted "Default" job position
 *
 */
export function useJobPositionsDefaultUpdate() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: JobPositionUpdateRequestFields) => {
      return await fetchHandler("PUT", `admin/job-positions/${updateDetails.id}`, {
        name: updateDetails.name,
      });
    },
    {
      onMutate: updateDetails => {
        // Get the cached default job positions
        const cachedDefaultJobPositions = queryClient.getQueryData([
          "job-positions-default",
        ]) as JobPositionFields[];

        // Update the matching default job position
        const updatedList = cloneDeep(cachedDefaultJobPositions) ?? [];
        const jobPositionIndex: number = updatedList.findIndex(
          position => position.id === updateDetails.id,
        );

        if (jobPositionIndex >= 0) {
          updatedList[jobPositionIndex].name = updateDetails.name;

          // Update the cached data
          queryClient.setQueryData(["job-positions-default"], updatedList);

          // Show success notification in the UI
          toast.success("Successfully updated job position!", {
            toastId: "job-positions-default-update",
          });
        }

        // In case of an error, return the initial cached data
        return { cachedDefaultJobPositions };
      },
      onError: (error: unknown, _updateDetails, context) => {
        toast.dismiss("job-positions-default-update");

        queryClient.setQueryData(["job-positions-default"], context?.cachedDefaultJobPositions);
        return error;
      },
    },
  );
}

/**
 *
 * Delete the targeted "Default" job position
 *
 */
export function useJobPositionsDefaultDelete() {
  const queryClient = useQueryClient();

  return useMutation(
    async (deleteDetails: JobPositionDeleteRequestFields) => {
      return await fetchHandler("DELETE", `admin/job-positions/${deleteDetails.id}`);
    },
    {
      onMutate: deleteDetails => {
        // Get the cached default job positions
        const cachedDefaultJobPositions = queryClient.getQueryData([
          "job-positions-default",
        ]) as JobPositionFields[];

        // Update the matching default job position
        let updatedList = cloneDeep(cachedDefaultJobPositions) ?? [];
        updatedList = updatedList.filter(position => position.id !== deleteDetails.id);

        // Update the cached data
        queryClient.setQueryData(["job-positions-default"], updatedList);

        // Show success notification in the UI
        toast.success("Successfully deleted job position!", {
          toastId: "job-positions-default-delete",
        });

        // In case of an error, return the initial cached data
        return { cachedDefaultJobPositions };
      },
      onError: (error: unknown, _deleteDetails, context) => {
        toast.dismiss("job-positions-default-delete");

        queryClient.setQueryData(["job-positions-default"], context?.cachedDefaultJobPositions);
        return error;
      },
    },
  );
}

/*==========================================
  JOB POSITIONS: PER COMPANY
===========================================*/

/**
 *
 * Get a list of all existing `companies` and
 * the list of the job positions that belong to them
 *
 */
export function useJobPositionsCompanyGet() {
  return useQuery(["job-positions-companies"], async () => {
    return (await fetchHandler("GET", "admin/job-positions/companies")) as JobPositionsCompanies[];
  });
}

/**
 *
 * Create new job position for the selected company
 *
 */
export function useJobPositionsCompanyCreate() {
  const queryClient = useQueryClient();

  return useMutation(
    async (createDetails: JobPositionsCompaniesCreateRequestFields) => {
      return await fetchHandler("POST", `admin/company/${createDetails.slug}/job-positions`, {
        company_id: createDetails.company_id,
        name: createDetails.name,
      });
    },
    {
      onMutate: createDetails => {
        // Get the cached dataset
        const cachedCompaniesJobPositions = queryClient.getQueryData([
          "job-positions-companies",
        ]) as JobPositionsCompanies[];

        // Make a deep copy of the cached dataset which we'll use for create actions
        const updatedCompaniesJobPositions = cloneDeep(cachedCompaniesJobPositions);

        // Find the targeted company
        const targetedCompanyIndex: number = updatedCompaniesJobPositions.findIndex(companies => {
          return companies.company.id === createDetails.company_id;
        });

        // Add the new job position to the targeted company and update the query cached data
        if (targetedCompanyIndex >= 0) {
          // Add the newly created job position to the list of already existing job positions for the targeted company
          updatedCompaniesJobPositions[targetedCompanyIndex].job_positions.push({
            key: 0,
            value: createDetails.name,
          });

          // Update the query cache data
          queryClient.setQueryData(["job-positions-companies"], updatedCompaniesJobPositions);

          // Show a success notification in the UI
          toast.success("Successfully added new job position!", {
            toastId: "job-positions-company-create",
          });
        }

        return { cachedCompaniesJobPositions };
      },
      onSettled: () => {
        // Refetch original data with database generated IDs
        queryClient.invalidateQueries(["job-positions-companies"]);
      },
      onError: (error: unknown, _createDetails, context) => {
        toast.dismiss("job-positions-company-create");

        queryClient.setQueryData(["job-positions-companies"], context?.cachedCompaniesJobPositions);
        return error;
      },
    },
  );
}

/**
 *
 * Update the targeted job position that belongs to a specific company
 *
 */
export function useJobPositionsCompanyUpdate() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: JobPositionsCompaniesUpdateRequestFields) => {
      return await fetchHandler("PUT", `admin/company/${updateDetails.slug}/job-positions`, {
        key: updateDetails.key,
        name: updateDetails.name,
        company_id: updateDetails.company_id,
      });
    },
    {
      onMutate: updateDetails => {
        // Get the cached dataset
        const cachedCompaniesJobPositions = queryClient.getQueryData([
          "job-positions-companies",
        ]) as JobPositionsCompanies[];

        // Make a deep copy of the cached dataset which we'll use for update actions
        const updatedCompaniesJobPositions = cloneDeep(cachedCompaniesJobPositions);

        // Find the targeted company
        const targetedCompanyIndex: number = updatedCompaniesJobPositions.findIndex(companies => {
          return companies.company.id === updateDetails.company_id;
        });

        // Update the targeted job position, that exists in the selected company
        if (targetedCompanyIndex >= 0) {
          // Find the targeted job position
          const targetedJobPositionIndex: number = updatedCompaniesJobPositions[
            targetedCompanyIndex
          ].job_positions.findIndex(jobPosition => {
            return jobPosition.key === updateDetails.key;
          });

          if (targetedJobPositionIndex >= 0) {
            const positionToBeUpdated =
              updatedCompaniesJobPositions[targetedCompanyIndex].job_positions[
                targetedJobPositionIndex
              ];
            positionToBeUpdated.value = updateDetails.name;

            // Update the cached data
            queryClient.setQueryData(["job-positions-companies"], updatedCompaniesJobPositions);

            // Show a success notification in the UI
            toast.success("Successfully updated job position!", {
              toastId: "job-positions-company-update",
            });
          }
        }

        return { cachedCompaniesJobPositions };
      },
      onError: (error: unknown, _updateDetails, context) => {
        toast.dismiss("job-positions-company-update");

        queryClient.setQueryData(["job-positions-companies"], context?.cachedCompaniesJobPositions);
        return error;
      },
    },
  );
}

/**
 *
 * Remove the targeted job position that belongs to a specific company
 *
 */
export function useJobPositionsCompanyDelete() {
  const queryClient = useQueryClient();

  return useMutation(
    async (deleteDetails: JobPositionsCompaniesDeleteRequestFields) => {
      return await fetchHandler("DELETE", `admin/company/${deleteDetails.slug}/job-positions`, {
        key: deleteDetails.key,
        company_id: deleteDetails.company_id,
      });
    },
    {
      onMutate: deleteDetails => {
        // Get the cached dataset
        const cachedCompaniesJobPositions = queryClient.getQueryData([
          "job-positions-companies",
        ]) as JobPositionsCompanies[];

        // Make a deep copy of the cached dataset which we'll use for delete actions
        const updatedCompaniesJobPositions = cloneDeep(cachedCompaniesJobPositions);

        // Find the targeted company
        const targetedCompanyIndex: number = updatedCompaniesJobPositions.findIndex(companies => {
          return companies.company.id === deleteDetails.company_id;
        });

        // Delete the targeted job position from the selected company
        if (targetedCompanyIndex >= 0) {
          // Find the targeted job position
          const updatedListOfJobPositions = updatedCompaniesJobPositions[
            targetedCompanyIndex
          ].job_positions.filter(jobPosition => {
            return jobPosition.key !== deleteDetails.key;
          });

          // prettier-ignore
          updatedCompaniesJobPositions[targetedCompanyIndex].job_positions = updatedListOfJobPositions;

          // Update the cached data
          queryClient.setQueryData(["job-positions-companies"], updatedCompaniesJobPositions);

          // Show a success notification in the UI
          toast.success("Successfully deleted job position!", {
            toastId: "job-positions-company-delete",
          });
        }

        return { cachedCompaniesJobPositions };
      },
      onError: (error: unknown, _deleteDetails, context) => {
        toast.dismiss("job-positions-company-delete");

        queryClient.setQueryData(["job-positions-companies"], context?.cachedCompaniesJobPositions);
        return error;
      },
    },
  );
}

/**
 *
 * Remove the selected job positions that belong to a specific company
 *
 */
export const useJobPositionsCompanyBulkDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (deleteDetails: JobPositionsCompaniesBulkDeleteRequestFields) => {
      return await fetchHandler(
        "DELETE",
        `admin/company/${deleteDetails.slug}/multiple-job-positions`,
        {
          job_position_ids: deleteDetails.job_position_ids,
        },
      );
    },
    {
      onMutate: deleteDetails => {
        const cachedCompaniesJobPositions = queryClient.getQueryData([
          "job-positions-companies",
        ]) as JobPositionsCompanies[];

        // Clone the companies positions cache
        const updatedCompaniesJobPositions = cloneDeep(cachedCompaniesJobPositions);

        // Find the targeted company using the received slug
        const targetedCompanyIndex = updatedCompaniesJobPositions.findIndex(
          company => company.company.slug === deleteDetails.slug,
        );

        // Continue only if the company is found within the cache
        if (targetedCompanyIndex >= 0) {
          // Filter out the cached positions for the targeted company based on the received positionIDs array
          const updatedPositionsArray = updatedCompaniesJobPositions[
            targetedCompanyIndex
          ].job_positions.filter(
            position => !deleteDetails.job_position_ids.includes(position.key),
          );

          updatedCompaniesJobPositions[targetedCompanyIndex].job_positions = updatedPositionsArray;

          // Update the query cache
          queryClient.setQueryData(["job-positions-companies"], updatedCompaniesJobPositions);

          // Notify user of the action success
          toast.success("Successfully deleted selected job positions!", {
            toastId: "job-positions-company-multiple-delete",
          });
        }

        // Return the initial cache in case of an error
        return { cachedCompaniesJobPositions };
      },
      onError: (error: unknown, _deleteDetails, context) => {
        toast.dismiss("job-positions-company-multiple-delete");

        queryClient.setQueryData(["job-positions-companies"], context?.cachedCompaniesJobPositions);
        return error;
      },
    },
  );
};
