import { UserRoleNames } from "../../interfaces/global";
import { useAuth } from "../../providers/auth-context";

interface ApplicationSourceAdminProps {
  source: string;
  modifierClass?: string;
}

const ApplicationSourceAdmin = ({ source, modifierClass = "" }: ApplicationSourceAdminProps) => {
  const { user } = useAuth();

  // If the currently logged in user is not Super Admin
  // then render an empty div to accomodate for the parent 'flex-between' property
  if (user.role !== UserRoleNames.SUPER_ADMIN) return <div></div>;

  return (
    <div className={`applications__source ${modifierClass}`}>
      <p>Source:</p>
      <p className="applications__source__name" title={source || "N/A"}>
        {source || "N/A"}
      </p>
    </div>
  );
};

export default ApplicationSourceAdmin;
