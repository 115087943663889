import MaintenanceMode from "../pages/Maintenance/MaintenanceMode";
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_MAINTENANCE: ApplicationRouteProps[] = [
  {
    path: "/maintenance",
    element: <MaintenanceMode />,
    permissions: ["*"],
    type: "public",
  },
];
