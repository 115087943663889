// Hooks
import { useEffect, useState } from "react";

// Components
import ChatAvatar from "./ChatAvatar";

// Context
import socket from "../../config/chat-socket";
import { useChatsContext } from "./ChatWrapper/ChatContextWrapper";
import { useAuth } from "../../providers/auth-context";

// Interfaces
import { ChatBubbleProps } from "./interfaces";
import { ChatMessagesResponseFields, ChatResponseFields } from "../../api/Chat/interfaces";

// Assets
import { IoCloseSharp as ChatBubbleCloseIcon } from "react-icons/io5";
import { useQueryClient } from "@tanstack/react-query";

const ChatBubble = ({ details }: ChatBubbleProps) => {
  const queryClient = useQueryClient();
  const chatsContext = useChatsContext();
  const { user } = useAuth();

  /*============================
    CLOSE SPECIFIC CHAT BUBBLE
  =============================*/
  const handleCloseChatBubble = (event: React.MouseEvent) => {
    event.stopPropagation();
    chatsContext.handleCloseChat(details.id);
  };

  /*============================
    COUNT THE UNREAD MESSAGES
  =============================*/
  const [chatMessages, setChatMessages] = useState<ChatResponseFields[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const [latestReceivedMessage, setLatestReceivedMessage] = useState<string>("");

  useEffect(() => {
    if (!chatMessages.length) return;

    // Count the unread messages
    const unreadMessages: number = chatMessages.filter(message => !message.is_read).length;
    setUnreadMessagesCount(unreadMessages);
  }, [chatMessages]);

  // Read all the messages for this specific chat from the cached data
  // and update the state, which will trigger re-count of the unread messages
  const messagesForChat = queryClient.getQueryData([
    "sms-chat-messages",
    details.chat_id,
  ]) as ChatMessagesResponseFields;

  useEffect(() => {
    if (!messagesForChat || !messagesForChat.messages.length) return;

    setChatMessages(messagesForChat.messages);
  }, [messagesForChat]);

  /*============================
    INCOMING MESSAGES
  =============================*/
  socket.on("inbound message", (incomingMessage: ChatResponseFields) => {
    // Do not show any messages and notifications if the
    // message that was received is not meant for the chat bubble
    if (incomingMessage.chat_id !== details.chat_id) return;

    const updatedChatMessages = [...chatMessages];
    updatedChatMessages.push(incomingMessage);
    setChatMessages(updatedChatMessages);

    // Update the text to be displayed for the latest received message
    setLatestReceivedMessage(incomingMessage.content);

    // Update the cached chat messages data for the chat for
    // which a new message was received trough the socket
    const cachedChatMessages = queryClient.getQueryData([
      "sms-chat-messages",
      incomingMessage.chat_id,
    ]) as ChatMessagesResponseFields;

    queryClient.setQueryData(["sms-chat-messages", incomingMessage.chat_id], {
      ...cachedChatMessages,
      messages: updatedChatMessages,
    });
  });

  // Remove the latest received message from state
  // shortly after its animation ends
  useEffect(() => {
    if (!latestReceivedMessage) return;

    // Reset the state shortly after the animation ends
    setTimeout(() => setLatestReceivedMessage(""), 3000);
  }, [latestReceivedMessage]);

  // Do not render anything in the UI
  // until we have the neccessary data
  if (!user.id || !chatsContext.existingUserChats) return null;

  return (
    <div className="chat-bubble" onClick={() => chatsContext.handleOpenBubbleChat(details)}>
      <ChatAvatar
        size="md"
        name={details.applicant.name}
        image_url={details.applicant.photo}
        status={details.is_blocked ? "blocked" : "active"}
      />

      {latestReceivedMessage && (
        <div className="chat-bubble__latest-message">
          <h6 className="chat-bubble__latest-message__name">{details.applicant.name}</h6>
          <p className="chat-bubble__latest-message__text">{latestReceivedMessage}</p>
        </div>
      )}

      <div
        className="chat-bubble__close"
        onClick={(event: React.MouseEvent) => handleCloseChatBubble(event)}
      >
        <ChatBubbleCloseIcon />
      </div>

      {unreadMessagesCount > 0 ? (
        <span className="notifications-counter notifications-counter--pulsating-animation">
          {unreadMessagesCount}
        </span>
      ) : null}
    </div>
  );
};

export default ChatBubble;
