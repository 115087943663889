// Hooks & Utilities
import { useChatsContext } from "../../Chat/ChatWrapper/ChatContextWrapper";
import handlePermissionCheck from "../../../utilities/handlePermissionCheck";

// Assets
import MessageIcon from "../../../assets/images/icons/applications-message-icon.svg?react";

// Components
import Tooltip from "../../Tooltip/Tooltip";

// Interfaces
import { ApplicationSendTextProps } from "../interfaces";
import { ChatDetails } from "../../Chat/ChatWrapper/interfaces";

const ApplicationSendText = ({ application_id, applicant_details }: ApplicationSendTextProps) => {
  const chatsContext = useChatsContext();
  const hasChatReadPermission = handlePermissionCheck(["sms_read"]);

  // Read the details for the targeted application
  // and send them to the chats context provider
  const handleApplicationSendText = () => {
    // Prevent opening the chat panels if there are no read permissions
    if (!hasChatReadPermission) return;

    if (!applicant_details.text_message_consent || !applicant_details.phone) return;

    // Existing user chats
    const existingChats = chatsContext.existingUserChats;

    // Details object for a brand new chat, in case
    // the selected applicant does not have an existing chat associated with them
    const CHAT_BRAND_NEW_DETAILS: ChatDetails = {
      id: applicant_details.id,
      is_blocked: 0,
      is_new_chat: true,
      chat_id: null,
      application_id: application_id,
      applicant: {
        id: applicant_details.id,
        name: applicant_details.name,
        photo: applicant_details.photo,
      },
      is_minimized: false,
    };

    if (existingChats) {
      // Check if there's a chat whose applicant ID value
      // matches the ID value of the current applicant for who
      // we want to open the chat
      const existingApplicantChatIndex: number = existingChats.findIndex(chat => {
        return chat.applicant.id === applicant_details.id;
      });

      // If applicant already has an existing chat
      if (existingApplicantChatIndex >= 0) {
        chatsContext.handleOpenChat({
          id: existingChats[existingApplicantChatIndex].id,
          is_blocked: existingChats[existingApplicantChatIndex].is_blocked,
          is_new_chat: false,
          chat_id: existingChats[existingApplicantChatIndex].id,
          application_id: null,
          applicant: {
            id: existingChats[existingApplicantChatIndex].applicant.id,
            name: existingChats[existingApplicantChatIndex].applicant.full_name,
            photo: existingChats[existingApplicantChatIndex].applicant.photo,
          },
          is_minimized: false,
        });
      } else {
        chatsContext.handleOpenChat(CHAT_BRAND_NEW_DETAILS);
      }
    } else {
      // In case there are no existing chats at all
      // for the currently logged in user
      chatsContext.handleOpenChat(CHAT_BRAND_NEW_DETAILS);
    }
  };

  return (
    <Tooltip
      text="Send Text"
      modifierClass={
        !applicant_details.phone ||
        !applicant_details.text_message_consent ||
        !hasChatReadPermission
          ? "d-none"
          : ""
      }
    >
      <div
        className={`applications__socials__icon ${
          applicant_details.phone && applicant_details.text_message_consent && hasChatReadPermission
            ? "applications__socials__icon--active"
            : "cursor--default"
        }`}
        onClick={handleApplicationSendText}
        data-testid="button:application-sms-consent"
        aria-disabled={
          applicant_details.phone && applicant_details.text_message_consent && hasChatReadPermission
            ? false
            : true
        }
      >
        <MessageIcon />
      </div>
    </Tooltip>
  );
};

export default ApplicationSendText;
