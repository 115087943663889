// Utilities
import * as Yup from "yup";
import handleExtractFileExtension from "../../../utilities/strings/handleExtractFileExtension";

// Assets
import { BsFillPersonFill as PersonIcon } from "react-icons/bs";
import EmailIcon from "../../../assets/images/icons/applications-email-application-icon.svg?react";
import PhoneIcon from "../../../assets/images/icons/phone-icon.svg?react";
import LinkedInIcon from "../../../assets/images/icons/linkedin-icon.svg?react";

// Components
import FormDropdown from "../../../components/Form/FormDropdown";
import FormInput from "../../../components/Form/FormInput";
import FormCheckbox from "../../../components/Form/FormCheckbox";
import FormUpload from "../../../components/Form/FormUpload";
import FormTextarea from "../../../components/Form/FormTextarea";
import FormDropdownMultiselect from "../../../components/Form/FormDropdownMultiselect";
import FormDatetimePicker from "../../../components/Form/FormDatetimePicker";
import FormInputWithElements from "../../../components/Form/FormInputWithElements";

// Schema Regex
import { LINKED_IN_URL_REGEX_PATTERN } from "../../../schemas/regexes";
import FormPhoneInput from "../../../components/Form/FormPhoneInput";
import { SchemasFileValueTestValidation } from "../../../schemas/constants";

/*=========================================
  FORM DEFAULT FIELDS
==========================================*/
export const FORM_DEFAULT_FIELDS: string[] = [
  "first_name",
  "last_name",
  "email",
  "phone",
  "address",
  "city",
  "zip_code",
  "state",
  "position",
  "country",
];

/** This is a pre-defined list of the default fields that WILL include labels */
export const FORM_DEFAULT_FIELDS_WITH_LABELS: string[] = [
  "first_name",
  "last_name",
  "email",
  "phone",
];

/*=========================================
  FORM DEFAULT FIELDS: LABELS
==========================================*/
export const FORM_DEFAULT_FIELDS_LABEL_MAPPINGS: Record<string, React.ReactNode> = {
  first_name: <PersonIcon />,
  last_name: <PersonIcon />,
  email: <EmailIcon />,
  phone: <PhoneIcon />,
  address: <p className="mb--0">Address</p>,
  city: <p className="mb--0">City</p>,
  zip_code: <p className="mb--0">Zip</p>,
};

/*=========================================
  FORM FIELDS COMPONENT PROPS: 
  BASED ON SUBTYPE VALUE
==========================================*/
export const FORM_FIELD_DEFAULT_SUBTYPE_FIELDS_COMPONENT_PROPS: Record<
  string,
  Record<string, React.ReactNode>
> = {
  resume: {
    uploadType: "file",
    buttonText: "Choose File",
    placeholder: "No File Chosen",
    modifierClass: "input--box-error mb--40",
    accept: ".pdf, .doc, .docx, .txt, .rtf, .odt, .ps, .wpd, .wps",
    fieldHasDedicatedFileTypeValidation: true,
    "data-testid": "upload:online-application-form-resume",
  },
  profile_image: {
    uploadType: "image",
    buttonText: "Choose File",
    placeholder: "No File Chosen",
    modifierClass: "input--box-error mb--40",
    accept: ".jpeg, .jpg, .png, .webp, .svg, .gif, .tiff",
    fieldHasDedicatedFileTypeValidation: true,
    "data-testid": "upload:online-application-form-photo",
  },
  linkedin: {
    placeholder: "https://linkedin.com/in/example",
    modifierClass: "mb--40",
    leftElement: <LinkedInIcon className="input-with-elements__element--linkedin" />,
  },
};

/*=========================================
  FORM FIELDS: VALIDATION SCHEMAS
  FOR FIELDS THAT HAVE SUBTYPE VALUE
==========================================*/
export const FILE_EXTENSIONS_RESUME: string[] = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "rtf",
  "odt",
  "ps",
  "wpd",
  "wps",
];

export const FILE_EXTENSIONS_PROFILE_IMAGE: string[] = [
  "jpeg",
  "jpg",
  "png",
  "webp",
  "svg",
  "gif",
  "tiff",
];

// Maximum allowed file size, expressed in bytes.
// This corresponds to maximum of 8 MB.
export const FILE_MAX_BYTES_SIZE: number = 8388608;

export const FORM_FIELD_SUBTYPE_FIELDS_VALIDATIONS: Record<
  string,
  Record<string, Yup.AnySchema>
> = {
  linkedin: {
    notRequired: Yup.string().matches(LINKED_IN_URL_REGEX_PATTERN, {
      message: "Must be a valid Linked In profile URL",
    }),
    required: Yup.string()
      .matches(LINKED_IN_URL_REGEX_PATTERN, {
        message: "Value must be a valid Linked In profile URL",
      })
      .required("Please provide a URL to your LinkedIn profile"),
  },
  resume: {
    notRequired: Yup.mixed()
      .nullable()
      .test(
        "application-resume-extension",
        "Resume validation failed: Allowed file types are Microsoft Word (DOC, DOCX), PDF, TXT, RTF, ODT, PS, WPD, WPS\n\nPlease correct the issue above and upload again.",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // Extract the file extension from the selected file
          const fileExtension: string = handleExtractFileExtension(
            (value as SchemasFileValueTestValidation).name,
          );

          // If file extension is not allowed, throw an error
          if (!FILE_EXTENSIONS_RESUME.includes(fileExtension)) return false;

          return true;
        },
      )
      .test("application-resume-size", "Resume must be up to a maximum of 8mb in size", value => {
        // If the value is null (default value) then no validation is applied
        if (value == null) return true;

        // If the file is bigger than 8mb, throw error, otherwise test passes
        return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
      }),
    required: Yup.mixed()
      .nullable()
      .test("application-resume", "Resume must be selected", value => {
        // If there's no resume selected, throw error
        if (!value) return false;

        return true;
      })
      .test(
        "application-resume-extension",
        "Resume validation failed: Allowed file types are Microsoft Word (DOC, DOCX), PDF, TXT, RTF, ODT, PS, WPD, WPS\n\nPlease correct the issue above and upload again.",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // Extract the file extension from the selected file
          const fileExtension: string = handleExtractFileExtension(
            (value as SchemasFileValueTestValidation).name,
          );

          // If file extension is not allowed, throw an error
          if (!FILE_EXTENSIONS_RESUME.includes(fileExtension)) return false;

          return true;
        },
      )
      .test("application-resume-size", "Resume must be up to a maximum of 8mb in size", value => {
        // If the value is null (default value) then no validation is applied
        if (value == null) return true;

        // If the file is bigger than 8mb, throw error, otherwise test passes
        return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
      }),
  },
  profile_image: {
    notRequired: Yup.mixed()
      .nullable()
      .test(
        "application-profile-image-extension",
        "Image validation failed: Allowed file types are JPEG, JPG, PNG, WEBP, SVG, GIF, TIFF\n\nPlease correct the issue above and upload again.",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // Extract the file extension from the selected file
          const fileExtension: string = handleExtractFileExtension(
            (value as SchemasFileValueTestValidation).name,
          );

          // If file extension is not allowed, throw an error
          if (!FILE_EXTENSIONS_PROFILE_IMAGE.includes(fileExtension)) return false;

          return true;
        },
      )
      .test(
        "application-profile-image-size",
        "Profile Image must be up to a maximum of 8mb in size",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // If the file is bigger than 8mb, throw error, otherwise test passes
          return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
        },
      ),
    required: Yup.mixed()
      .nullable()
      .test("application-profile-image", "Profile Image must be selected", value => {
        // If there's no profile image selected, throw error
        if (!value) return false;

        return true;
      })
      .test(
        "application-profile-image-extension",
        "Image validation failed: Allowed file types are JPEG, JPG, PNG, WEBP, SVG, GIF, TIFF\n\nPlease correct the issue above and upload again.",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // Extract the file extension from the selected file
          const fileExtension: string = handleExtractFileExtension(
            (value as SchemasFileValueTestValidation).name,
          );

          // If file extension is not allowed, throw an error
          if (!FILE_EXTENSIONS_PROFILE_IMAGE.includes(fileExtension)) return false;

          return true;
        },
      )
      .test(
        "application-profile-image-size",
        "Profile Image must be up to a maximum of 8mb in size",
        value => {
          // If the value is null (default value) then no validation is applied
          if (value == null) return true;

          // If the file is bigger than 8mb, throw error, otherwise test passes
          return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
        },
      ),
  },
};

/*=========================================
  FORM FIELDS: DEFAULT COMPONENT PROPS
==========================================*/
export const FORM_FIELD_DEFAULT_COMPONENT_PROPS: Record<string, Record<string, string | number>> = {
  text: {
    modifierClass: "mb--20",
  },
  textarea: {
    rows: 3,
    modifierClass: "mb--20",
  },
  select: {
    size: "full",
    modifierClass: "mb--20",
  },
  multiselect: {
    size: "full",
    modifierClass: "mb--20",
  },
  file: {
    uploadType: "file",
    buttonText: "Choose File",
    modifierClass: "mb--40",
  },
  image: {
    uploadType: "image",
    buttonText: "Choose File",
    placeholder: "No File Chosen",
    modifierClass: "mb--40",
    accept: ".png, .jpg, .gif, .svg, .webp",
  },
  datetime: {
    modifierClass: "datepicker--full mb--20",
    minDate: "none",
    maxDate: "today",
  },
};

/*=========================================
  FORM FIELDS: COMPONENT MAPPINGS
==========================================*/
export const FORM_FIELD_COMPONENT_MAPPINGS: Record<string, unknown> = {
  // Possible form field types
  text: FormInputWithElements,
  textarea: FormTextarea,
  select: FormDropdown,
  multiselect: FormDropdownMultiselect,
  number: FormInput,
  checkbox: FormCheckbox,
  file: FormUpload,
  image: FormUpload,
  datetime: FormDatetimePicker,
  phone: FormPhoneInput,

  // Subtypes from default fields
  resume: FormUpload,
  profile_image: FormUpload,
  linkedin: FormInput,
};
