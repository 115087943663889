/**
 *
 * Utility functionfor generating a more user friendly date range string for given dates
 *
 * @param startDate - An optional string representation of a Start Date
 * @param endDate - An optional string representation of an End Date
 * @returns - A user friendly string representation of the specified Date Range OR undefined
 */
export const handleDateRangeString = (startDate: string | null, endDate: string | null) => {
  if (!startDate && !endDate) {
    return undefined;
  }

  if (startDate && !endDate) {
    return `From ${startDate} to present`;
  }

  if (!startDate && endDate) {
    return `From beginning to ${endDate}`;
  }

  if (startDate && endDate) {
    return `From ${startDate} to ${endDate}`;
  }
};
