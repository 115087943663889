import { ModalBackdropProps } from "./interfaces";
import { motion } from "framer-motion";

const ModalBackdrop = ({ overlayModifierClass = "", handleCloseModal }: ModalBackdropProps) => {
  return (
    <motion.div
      key="framer-modal-backdrop"
      className={`modal-backdrop ${overlayModifierClass}`}
      onClick={handleCloseModal}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.6 }}
      exit={{ opacity: 0, transition: { delay: 0.1 } }}
      transition={{ duration: 0.5 }}
      data-testid="modal:backdrop"
    />
  );
};

export default ModalBackdrop;
