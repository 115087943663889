// Components
import Card from "../../Card/Card";
import FormTextarea from "../../Form/FormTextarea";
import FormInputSideLabel from "../../Form/FormInputSideLabel";
import FormDropdownSearchable from "../../../components/Form/FormDropdownSearchable";

// Hooks & Utilities
import { Field, FormikValues } from "formik";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";

const JOB_TYPE_DROPDOWN_OPTIONS: DropdownItem[] = [
  { text: "Full Time", value: "Full Time" },
  { text: "Part Time", value: "Part Time" },
];

const JobAdInformation = ({
  values,
  setFieldValue,
}: {
  values: FormikValues;
  setFieldValue: (field: string, value: unknown) => void;
}) => {
  return (
    <Card modifierClass="card--padding--sm">
      <h3 className="txt--blue fw--semibold mb--10">Job Information</h3>
      <p className="txt--md txt--black mb--0">Please enter the job information below.</p>
      <p className="txt--md txt--black mb--30">
        <strong>NOTE:</strong> Do not enter any HTML or special formatting, only plain text is
        allowed here.
      </p>

      <div className="row">
        <div className="col-12 col-lg-6 mb--20">
          <Field
            component={FormInputSideLabel}
            label="Job Title"
            id="job_title"
            name="job_title"
            placeholder="Enter Job Title"
            modifierClass="input-side-label mb--20"
            size="full"
            isRequired
          />
          <Field
            component={FormInputSideLabel}
            label="Headline 1"
            id="headline_1"
            name="headline_1"
            placeholder="Enter Headline"
            modifierClass="input-side-label mb--20"
            size="full"
            isRequired
          />
          <Field
            component={FormInputSideLabel}
            label="Headline 2"
            id="headline_2"
            name="headline_2"
            placeholder="Enter Headline"
            modifierClass="input-side-label mb--20"
            size="full"
          />
          <Field
            component={FormInputSideLabel}
            label="Headline 3"
            id="headline_3"
            name="headline_3"
            placeholder="Enter Headline"
            modifierClass="input-side-label mb--20"
            size="full"
          />
          <Field
            component={FormInputSideLabel}
            label="Job Questions"
            id="job_questions"
            name="job_questions"
            placeholder="Enter Job Questions"
            modifierClass="input-side-label mb--20"
            size="full"
          />
          <Field
            component={FormTextarea}
            name="job_intro"
            id="job_intro"
            label="Job Intro"
            placeholder="Job Intro..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />
          <Field
            component={FormTextarea}
            name="job_description"
            id="job_description"
            label="Job Description"
            placeholder="Job Description..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />
        </div>

        <div className="col-12 col-lg-6 mb--20">
          <Field
            component={FormDropdownSearchable}
            title="Job Type"
            id="job_type"
            label="Job Type"
            name="job_type"
            modifierClass="dropdown--side-label mb--20"
            size="full"
            items={JOB_TYPE_DROPDOWN_OPTIONS}
            preselectedItemValue={values.job_type}
            handleFieldUpdate={(type: DropdownItem) => {
              setFieldValue("job_type", type.value);
            }}
            framerAnimationCustomProps={{ hasSideLabel: true }}
          />

          <Field
            component={FormTextarea}
            name="job_benefits"
            id="job_benefits"
            label="Job Benefits"
            placeholder="Job Benefits..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />

          <Field
            component={FormTextarea}
            name="job_responsibilities"
            id="job_responsibilities"
            label="Job Responsibilities"
            placeholder="Job Responsibilities..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />
          <Field
            component={FormTextarea}
            name="job_requirements"
            id="job_requirements"
            label="Job Requirements"
            placeholder="Job Requirements..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />

          <Field
            component={FormTextarea}
            name="job_terms"
            id="job_terms"
            label="Job Terms"
            placeholder="Job Terms..."
            modifierClass="input-side-label mb--20"
            rows={5}
          />
        </div>
      </div>
    </Card>
  );
};

export default JobAdInformation;
