// Hooks & Utilities
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  BucketsCreateRequestFields,
  BucketsEditRequestFields,
  BucketsResponseFields,
} from "./interfaces";

/**
 *
 * Get all existing Buckets for the currently active company.
 *
 */
export function useBucketsGetAll() {
  // Read the active company's slug from the authentication context
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["buckets", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/buckets`,
      )) as BucketsResponseFields[];
    },
    { enabled: !!companySlug, retry: 0 },
  );
}

/**
 *
 * Create a new Bucket for the currently active company.
 *
 */
export function useBucketsCreate() {
  const queryClient = useQueryClient();

  // Read the active company's slug from the authentication context
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    (bucketDetails: BucketsCreateRequestFields) => {
      return fetchHandler("POST", `company/${companySlug}/buckets`, bucketDetails);
    },
    {
      onSuccess: () => toast.success("Successfully created new bucket!"),
      onError: error => error,
      onSettled: () => queryClient.invalidateQueries(["buckets", companySlug]),
    },
  );
}

/**
 *
 * Remove the targeted Bucket for the currently active company.
 *
 * The mutation hook call takes a "bucketID" parameter, representing
 * the ID of the bucket that was selected to be deleted.
 *
 */
export function useBucketsDelete() {
  const queryClient = useQueryClient();

  // Read the active company's slug from the authentication context
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    (bucketID: number) => {
      return fetchHandler("DELETE", `company/${companySlug}/buckets/${bucketID}`);
    },
    {
      onSuccess: () => toast.success("Bucket removed succesfully!"),
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["buckets", companySlug]);
        queryClient.invalidateQueries(["applications", companySlug]);
      },
    },
  );
}

/**
 *
 * Update the name of the targeted Bucket for the currently active company.
 *
 * The mutation hook call takes a `bucketDetails` parameter, which contains
 * the "id" of the bucket to be edited, and the updated "name" value to be sent
 * in the request's payload.
 *
 */
export function useBucketsEdit() {
  const queryClient = useQueryClient();

  // Read the active company's slug from the authentication context
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    (bucketDetails: BucketsEditRequestFields) => {
      return fetchHandler("PUT", `company/${companySlug}/buckets/${bucketDetails.id}`, {
        name: bucketDetails.name,
      });
    },
    {
      onSuccess: () => toast.success("Bucket updated Successfully!"),
      onError: error => error,
      onSettled: () => queryClient.invalidateQueries(["buckets", companySlug]),
    },
  );
}

/**
 *
 * Update the order in which the buckets are shown in the UI.
 *
 * The mutation hook call takes a `bucketIDs` parameter, which
 * is an array of numbers, representing the IDs of the buckets,
 * in the newly updated order after the drag and drop action.
 */
export function useBucketsReorder() {
  const queryClient = useQueryClient();

  // Read the active company's slug from the authentication context
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    (bucket_ids: number[]) => {
      return fetchHandler("POST", `company/${companySlug}/buckets/order`, { bucket_ids });
    },
    {
      onSuccess: () => toast.success("Buckets reordered succesfully!"),
      onError: error => error,
      onSettled: () => queryClient.invalidateQueries(["buckets", companySlug]),
    },
  );
}

/**
 *
 * Get all existing Buckets for specific company.
 *
 */
export function useBucketsGetForCompany(companySlug?: string) {
  return useQuery(
    ["buckets", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/buckets`,
      )) as BucketsResponseFields[];
    },
    { enabled: !!companySlug, retry: 0 },
  );
}
