// Components
import Table from "../../components/Table/Table";

// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";

// Interfaces & API Hooks
import { EmailLogFields, EmailLogsResponseFields } from "../../api/Logs/Logs";

const EmailLogs = ({ emailLogs }: { emailLogs: EmailLogsResponseFields | undefined }) => {
  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const EMAILS_TABLE_COLUMNS = useMemo(() => {
    const columnHelper = createColumnHelper<EmailLogFields>();
    const columns = [
      columnHelper.accessor("id", {
        header: () => <span>ID</span>,
        cell: data => <span>{data.getValue() || "N/A"}</span>,
        size: 30,
      }),
      columnHelper.accessor("sent_timestamp", {
        header: () => <span>Sent</span>,
        cell: data => (
          <span>
            {data.getValue() != null
              ? format(handleDateAsTimestamp(data.getValue() as number), "MM/dd/yyyy HH:mm:ss")
              : "N/A"}
          </span>
        ),
        size: 110,
      }),
      columnHelper.accessor("status", {
        header: () => <span>Status</span>,
        cell: data => (
          <span>{data.row.original.sent_timestamp !== null ? "Successful" : "Failed"}</span>
        ),
        size: 50,
      }),
      columnHelper.accessor("from", {
        header: () => <span>From</span>,
        cell: data => (
          <Link
            to={`/notification-logs/emails/details/${data.row.original.id}/`}
            className="btn--text--secondary"
          >
            <span className="fw--medium">{data.getValue()}</span>
          </Link>
        ),
        size: 200,
      }),
      columnHelper.accessor("to", {
        header: () => <span>To</span>,
        cell: data => (
          <Link
            to={`/notification-logs/emails/details/${data.row.original.id}/`}
            className="btn--text--secondary"
          >
            <span className="fw--medium">{data.getValue()}</span>
          </Link>
        ),
        size: 200,
      }),
      columnHelper.accessor("subject", {
        header: () => <span>Subject</span>,
        cell: data => (
          <Link
            to={`/notification-logs/emails/details/${data.row.original.id}/`}
            className="btn--text--secondary"
          >
            <span className="fw--medium">{data.getValue()}</span>
          </Link>
        ),
        size: 200,
      }),
    ];

    return columns;
  }, []);

  /*======================
    TABLE DATA
  =======================*/
  const EMAIL_LOGS = useMemo(() => {
    if (!emailLogs || !emailLogs.messages.length) return [];

    // Sort the data in descending order based on "ID" field value
    const emailLogsDataCopy = [...emailLogs.messages].sort((logA, logB) => {
      return logA.id > logB.id ? -1 : 1;
    });

    return emailLogsDataCopy;
  }, [emailLogs]);

  return (
    <>
      <Table
        data={EMAIL_LOGS}
        noDataMessage="No Email logs data to show"
        columns={EMAILS_TABLE_COLUMNS}
        isRefetching={false}
        paginationPageSize={100}
        modifierClass="table-wrapper--no-shadow"
      />
    </>
  );
};

export default EmailLogs;
