import { FormikErrors } from "formik";

/**
 *
 * Utility function used with Formik's form, that will manually trigger
 * form submission, and if there are any errors, it will scroll the page
 * to the position of the field that has an error.
 *
 * @param e Event object when the `onSubmit` is called
 * @param errors Object that can contain the Formik Errors
 * @param errorMessageElementClassname The targeted error message field's class name.
 * Defaults to `.input__error`.
 * @param submitForm Callback function that triggers Formik form submission
 *
 */
export default async function handleFormikScrollToFirstError(
  e: React.FormEvent,
  errors: FormikErrors<Record<string, any>>,
  errorMessageElementClassname: string = ".input__error",
  submitForm: () => Promise<void>,
) {
  // Prevent default form behavior
  e.preventDefault();

  // Trigger form submission
  await submitForm();

  // If there are any errors, scroll to the first error message element
  if (errors && Object.entries(errors).length) {
    const errorMessageField: HTMLElement | null = document.querySelector(
      errorMessageElementClassname,
    );

    if (errorMessageField) {
      const SCROLL_OFFSET: number = 50;

      // Target the parent element of the error message field
      const parentOfErrorMessageField = errorMessageField.parentElement;

      // If a parent of the error message exists, scroll to to the top of the
      // element subtracted by a specific offset value so the whole element can be visible
      if (parentOfErrorMessageField) {
        const parentElementOffset = parentOfErrorMessageField.offsetTop;
        window.scrollTo({ top: parentElementOffset - SCROLL_OFFSET });
      } else {
        // Otherwise scroll to the error message itself
        const errorMessageFieldOffest = errorMessageField.offsetTop;
        errorMessageField.scrollTo({ top: errorMessageFieldOffest - SCROLL_OFFSET });
      }
    }
  }
}

/**
 *
 * Scroll the window to the first error message that is present in the page
 * This is different from the other scroll to error function as this is triggered manually
 *
 * @param errors The errors that might exist after the form has been submitted
 * @param errorMessageElementClassname The class name of the error message element to be targeted
 *
 */
export function handleFormikScrollToFirstErrorManualTrigger(
  errors: FormikErrors<Record<string, any>>,
  errorMessageElementClassname: string = ".input__error",
) {
  // If there are any errors, scroll to the first error message element
  if (errors && Object.entries(errors).length) {
    const errorMessageField: HTMLElement | null = document.querySelector(
      errorMessageElementClassname,
    );

    if (errorMessageField) {
      const offsetTopTest = getOffsetTop(errorMessageField);
      const SCROLL_OFFSET: number = 100;

      window.scrollTo({ top: offsetTopTest - SCROLL_OFFSET });
    }
  }
}

/**
 *
 * Recursively calculate the offset top of the provided element,
 * based on the provided element's parent
 *
 * @param element HTML element to be targeted
 * @returns The total sum of the elements offset top value, based on
 * the provided element's parent elements (if there are any)
 *
 */

function getOffsetTop(element: any): number {
  return element ? element.offsetTop + getOffsetTop(element.offsetParent) : 0;
}
