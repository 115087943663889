// Hooks & Utilities
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { useLocation } from "react-router-dom";

// Components
import Tooltip from "../Tooltip/Tooltip";
import { motion, AnimatePresence } from "framer-motion";

// Assets & statics
import HelpIcon from "../../assets/images/icons/header-need-help-icon.svg?react";
import {
  FRAMER_HEADER_ANIMATION_WITH_OFFSET,
  FRAMER_HEADER_TRANSITIONS,
} from "../../constants/framer";
import { HEADER_DROPDOWN_HELP } from "./statics";

const HeaderDropdownNeedHelp = () => {
  /*======================
   HANDLE DROPDOWN MENU
  =======================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  /*=======================================
    CLOSE ON "ESCAPE" KEY & OUTSIDE CLICK
  ======================================*/
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <Tooltip
      text={HEADER_DROPDOWN_HELP.title}
      positioning="bottom"
      size="md"
      modifierClass={isDropdownOpen ? "d-none" : ""}
    >
      <div
        ref={dropdownRef}
        className={`header-dropdown header-dropdown--sm header__icon`}
        tabIndex={0}
      >
        <div
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className={`header-dropdown__body ${
            isDropdownOpen ? "header-dropdown__body--active" : ""
          }`}
          data-testid="component:dropdown-header-need-help"
        >
          <HelpIcon />
        </div>

        <AnimatePresence>
          {isDropdownOpen ? (
            <>
              <motion.div
                className="header-dropdown__content header-dropdown__content--lg header-dropdown__content--need-help"
                variants={FRAMER_HEADER_ANIMATION_WITH_OFFSET}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={FRAMER_HEADER_TRANSITIONS}
              >
                <div className="header-dropdown__group">
                  <ul className="header-dropdown__group__items">
                    {HEADER_DROPDOWN_HELP.items.map(dropdownItem => {
                      return (
                        <a
                          key={dropdownItem.text}
                          href={dropdownItem.path}
                          target="_blank"
                          className="header-dropdown__link"
                        >
                          {dropdownItem.text}
                        </a>
                      );
                    })}
                  </ul>
                </div>
              </motion.div>
            </>
          ) : null}
        </AnimatePresence>
      </div>
    </Tooltip>
  );
};

export default HeaderDropdownNeedHelp;
