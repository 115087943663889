import { toast } from "react-toastify";
import Button from "../../Button/Button";

const JobAdCraigslistEmbed = ({ live_ad_preview }: { live_ad_preview: string | null }) => {
  // Append craigslist job board search parameter to every link received from the live ad preview content
  const handleAppendEmbedCraigslistParams = (html: string | null) => {
    if (!html) return;
    const div = document.createElement("div");
    div.innerHTML = html;
    div.getElementsByTagName("a");

    Array.from(div.getElementsByTagName("a")).forEach(link => {
      if (link.href.includes("?")) {
        link.href = `${link.href}&job_board=craigslist`;
      } else {
        link.href = `${link.href}?job_board=craigslist`;
      }
    });

    return div.innerHTML;
  };

  // Copy the Job Ad's "Craigslist Embed" HTML content
  const handleCopyToClipboard = async (adHTML: string | null | undefined) => {
    if (!adHTML) return;

    try {
      await navigator.clipboard.writeText(adHTML);
      toast.info("HTML copied!");
    } catch (error) {
      toast.error("Failed copying HTML!");
    }
  };

  return (
    <>
      <h6 className="txt--lg txt--gray--darkest fw--semibold mb--10">Ad Html</h6>
      <textarea
        className="input-textarea mb--10"
        disabled
        readOnly
        rows={25}
        defaultValue={handleAppendEmbedCraigslistParams(live_ad_preview)}
      />

      <div className="d-flex justify-content-end">
        <Button
          className="btn btn--fluid btn--primary"
          onClick={() => handleCopyToClipboard(handleAppendEmbedCraigslistParams(live_ad_preview))}
        >
          Copy HTML
        </Button>
      </div>
    </>
  );
};

export default JobAdCraigslistEmbed;
