//  Hooks
import { useAuth } from "../../providers/auth-context";
import { useUserSetActiveCompany } from "../../api/User/User";
import handleErrorMessage from "../../utilities/handleErrorMessage";

// Components
import { Link, useNavigate } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import ModalBackdrop from "./ModalBackdrop";
import Button from "../Button/Button";

// Assets
import { BiError as ErrorIcon } from "react-icons/bi";
import { toast } from "react-toastify";

const FRAMER_MOTION_COMPANY_CHANGE_MODAL: Variants = {
  initial: {
    opacity: 0,
    translateX: "-50%",
    translateY: 0,
  },
  animate: {
    opacity: 1,
    translateX: "-50%",
    translateY: "-50%",
  },
  exit: {
    opacity: 0,
    translateX: "-50%",
    translateY: 0,
  },
};

const FRAMER_MOTION_COMPANY_CHANGE_MODAL_ICON: Variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const FRAMER_MOTION_COMPANY_CHANGE_MODAL_ELEMENTS: Variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

interface ModalApplicationNotInCompanyProps {
  id: number;
  name: string;
}

const ModalApplicationNotInCompany = ({ id, name }: ModalApplicationNotInCompanyProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleActiveCompany = useUserSetActiveCompany();

  // Switch the currently active company so the user can see the applicant
  const handleCompanySwitch = async () => {
    try {
      // Send API request to update the currently active company
      await handleActiveCompany.mutateAsync(id);

      // Reload the page so the changes of the active company can take effect
      window.location.reload();
    } catch (error) {
      toast.error(handleErrorMessage(error));
    }
  };

  return (
    <div data-testid="modal:application-not-in-company">
      <ModalBackdrop
        handleCloseModal={() => {
          // Prevent page redirections if there's an ongoing API request
          if (handleActiveCompany.isLoading) return;
          navigate("/applications/");
        }}
      />

      <motion.div
        variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{
          duration: 1.3,
          type: "spring",
          staggerChildren: 0.2,
          delayChildren: 0.25,
        }}
        className="modal modal--fixated modal--application-not-in-company"
      >
        <motion.div
          variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL_ICON}
          transition={{ duration: 1, type: "spring" }}
        >
          <ErrorIcon className="modal__icon" />
        </motion.div>

        <motion.h5
          variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL_ELEMENTS}
          className="mb--20 fw--medium"
        >
          The application you're trying to view is in: <br />
          <strong className="txt--black">{name}</strong>
        </motion.h5>

        <motion.p variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL_ELEMENTS} className="mb--5">
          You are currently viewing:{" "}
          <strong className="txt--black">{user.active_company.name}</strong>
        </motion.p>

        <motion.p variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL_ELEMENTS}>
          Do you want to switch to <strong className="txt--black">{name}</strong> now?
        </motion.p>

        <motion.div
          variants={FRAMER_MOTION_COMPANY_CHANGE_MODAL_ELEMENTS}
          className="d-flex justify-content-center align-items-center"
        >
          <Link
            to="/applications/"
            className="btn btn--fluid btn--fluid--md btn--secondary btn--disabled"
            onClick={event => {
              if (handleActiveCompany.isLoading) event.preventDefault();
            }}
            aria-disabled={handleActiveCompany.isLoading}
          >
            No
          </Link>

          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary--light ml--20"
            onClick={handleCompanySwitch}
            isLoading={handleActiveCompany.isLoading}
            isDisabled={handleActiveCompany.isLoading}
          >
            Yes
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ModalApplicationNotInCompany;
