// Utilities
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";
import {
  JobAdDetailsAdSettingsUpdateRequestFields,
  JobAdsAllResponseFields,
  JobAdsGetAllFilters,
  JobAdsJobBoardData,
  JobAdsLatestFields,
  JobAdsOverviewSearchResponseFields,
  JobAdsSpecificAdDetailsResponseFields,
  JobAdsStatusesResponseFields,
  JobAdStatusUpdateDetails,
  JobAdUpdateRequestFields,
  JobBoardsManualActionTriggerDetails,
} from "./interfaces";
import useErrorReporting from "../../hooks/useErrorReporting";

/**
 *
 * Get a list of all existing ads
 *
 */

export const useJobsAdsGetAll = (filters: JobAdsGetAllFilters) => {
  let filterString: string = "";
  Object.entries(filters).forEach(filter => {
    const [key, value] = filter;

    // If there's no value to work with -> skip to next entry
    if (!value || value === "all") return;

    // Handle appending the query parameters
    // When adding first filter preprend it with "?" indicating the start of the query parameters
    // When adding any additional filters prepend them with "&" indicating additional query parameter
    if (!filterString.startsWith("?")) {
      filterString += `?${key}=${encodeURIComponent(value)}`;
    } else {
      filterString += `&${key}=${encodeURIComponent(value)}`;
    }
  });

  return useQuery(["job-ads-all", filterString], async () => {
    return (await fetchHandler("GET", `admin/ads${filterString}`)) as JobAdsAllResponseFields;
  });
};

/**
 *
 * Search trough the Job Ads - Overview list, getting back
 * datasets containing the matching "Clients" and "Job Ads"
 *
 * @param searched The value that was searched for
 *
 */
export const useJobAdsOverviewSearch = (searched: string) => {
  return useQuery(
    ["job-ads-overview-search", searched],
    async () => {
      return (await fetchHandler("POST", `admin/ads/filter`, {
        search: searched,
      })) as JobAdsOverviewSearchResponseFields;
    },
    { enabled: !!searched },
  );
};

/**
 *
 * Get a list of the latest ads of all possible types
 *
 */
export const useJobAdsGetLatest = () => {
  return useQuery(["job-ads-latest"], async () => {
    return (await fetchHandler("GET", "admin/ads/latest")) as JobAdsLatestFields;
  });
};

/**
 *
 * Get a list of all existing job ads statuses
 *
 */
export const useJobAdsGetStatuses = () => {
  return useQuery(["job-ads-statuses"], async () => {
    return (await fetchHandler("GET", "admin/ads/statuses")) as JobAdsStatusesResponseFields[];
  });
};

/**
 *
 * Update the status of the targeted ad
 *
 */
export const useJobAdsUpdateStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: JobAdStatusUpdateDetails) => {
      return await fetchHandler("POST", `admin/ads/${updateDetails.ad_id}/status`, {
        status_id: updateDetails.status_id,
      });
    },
    {
      onSuccess: () => toast.success("Job Ad status updated successfully!"),
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["job-ads-all"]);
        queryClient.invalidateQueries(["job-ads-latest"]);
        queryClient.invalidateQueries(["job-ads-client-details"]);
        queryClient.invalidateQueries(["job-ads-details"]);
      },
    },
  );
};

/**
 *
 * Get the list of companies (clients) that can be used
 * for filtering the Job Ads table data.
 *
 */
export const useJobAdsGetCompanyFilters = () => {
  return useQuery(["job-ads-company-filters"], async () => {
    return (await fetchHandler("GET", "admin/ads/company-filter")) as DropdownItem[];
  });
};

/**
 *
 * Get the list of job titles that can be used
 * for filtering the Job Ads table data
 *
 */
export const useJobAdsGetJobTitleFilters = () => {
  return useQuery(["job-ads-job-title-filters"], async () => {
    return (await fetchHandler("GET", "admin/ads/job-filter")) as DropdownItem[];
  });
};

/**
 *
 * Get the details for a specific Job Ad
 *
 * @param adId The ID representing the targeted job ad
 *
 */
export const useJobAdsGetAdDetails = (adId: string | undefined) => {
  return useQuery(
    ["job-ads-details", adId],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/ads/${adId}`,
      )) as JobAdsSpecificAdDetailsResponseFields;
    },
    { enabled: !!adId },
  );
};

/**
 *
 * Update the Job Ad Settings for the specific Job Ad
 *
 * @param adId The ID representing the targeted Job Ad
 *
 */
export const useJobAdsUpdateAdSettings = (adId: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: JobAdDetailsAdSettingsUpdateRequestFields) => {
      return await fetchHandler("PUT", `admin/ads/${adId}/settings`, updateDetails);
    },
    {
      onSuccess: () => toast.success("Ad Settings updated!"),
      onError: (error: unknown) => error,
      onSettled: () => queryClient.invalidateQueries(["job-ads-details", adId]),
    },
  );
};

/*
 * Get the HTML content that will be used to populate
 * the Job Ad Preview page's content
 *
 * @param adId The ID of the targeted Job Ad that we want to preview
 *
 */
export const useJobAdsGetPreviewDetails = (adId: string | undefined) => {
  const errorReporting = useErrorReporting();

  return useQuery(
    ["job-ads-preview", adId],
    async () => {
      return (await fetchHandler("GET", `job-ads/preview/${adId}`)) as { content: string };
    },
    {
      enabled: !!adId,
      retry: 0,
      onError: error => {
        errorReporting("Job Preview failed", error, { adId });
        return error;
      },
    },
  );
};

/**
 *
 * Update the targeted Job Ad's details
 *
 * @param adId The ID of Job Ad targeted for edit (revision)
 *
 */
export const useJobAdsUpdateDetails = (adId: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: JobAdUpdateRequestFields) => {
      return await fetchHandler("PUT", `admin/ads/${adId}`, updateDetails);
    },
    {
      onSuccess: () => toast.success("Job Ad updated successfully! You will soon be redirected."),
      onError: (error: unknown) => error,
      onSettled: () => {
        queryClient.invalidateQueries(["job-ads-all"]);
        queryClient.invalidateQueries(["job-ads-latest"]);
        queryClient.invalidateQueries(["job-ads-details"]);
      },
    },
  );
};

/**
 *
 * Update the targeted Job Ad's - Job Boards details
 *
 * @param adId The ID of the targeted Job Ad
 *
 */
export const useJobAdsJobBoardsUpdate = (adId: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateData: JobAdsJobBoardData) => {
      return await fetchHandler("PUT", `admin/ads/${adId}/job-board-data`, updateData);
    },
    {
      onSuccess: () => toast.success("Job Boards updated successfully!"),
      onError: (error: unknown) => error,
      onSettled: () => {
        queryClient.invalidateQueries(["job-ads-all"]);
        queryClient.invalidateQueries(["job-ads-latest"]);
        queryClient.invalidateQueries(["job-ads-details"]);
      },
    },
  );
};

/**
 *
 * Trigger manual actions for Job Boards
 *
 */
export const useJobAdsJobBoardsManualAction = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (triggerDetails: JobBoardsManualActionTriggerDetails) => {
      return await fetchHandler(
        "POST",
        `admin/ads/${triggerDetails.ad_id}/ad-action/${triggerDetails.ad_action_id}/process`,
      );
    },
    {
      onSuccess: () => toast.success("Action successfull!"),
      onError: (error: unknown) => error,
      onSettled: () => {
        queryClient.invalidateQueries(["job-ads-all"]);
        queryClient.invalidateQueries(["job-ads-latest"]);
        queryClient.invalidateQueries(["job-ads-details"]);
      },
    },
  );
};

/**
 *
 * Get the list of job boards that can be used
 * for filtering the Job Ads table data.
 *
 */
export const useJobAdsGetJobBoards = () => {
  return useQuery(["job-ads-job-boards"], async () => {
    return await fetchHandler("GET", "admin/ads/job-boards");
  });
};
