/**
 *
 * Utility function used to encode a HTML email template into a text content variant & email client variant
 *
 * From the received HTML template we strip all the HTML tags and replace <p>, <br /> & styled <p> tags with
 * the encoded character for new line. For the text content we simply generate a temporaryDOM element, set the
 * innerHTML as the received HTML emailTemplate and extract the text content of the temporary dom element.
 * For the email encoded variant we replace SOME html characters with the encoded character for new line
 * and strip the rest of the HTML.
 *
 * @param emailTemplate The HTML email template
 * @returns A Text Content variant used for 'Copy Template' functionality & 'Email Encoded' variant formatted for mail client usage
 */
const handleEncodeEmailTemplate = (emailTemplate: string) => {
  const EMAIL_ENCODED = emailTemplate
    .replaceAll(`<p style="color:orangered;">`, "%0D%0A")
    .replaceAll("<p>", "%0D%0A")
    .replaceAll("<br />", "%0D%0A");

  const temporaryDOM = document.createElement("div");

  // USED FOR COPY/PASTE TEMPLATE
  temporaryDOM.innerHTML = emailTemplate;
  const TEMPLATE_TEXT_CONTENT = temporaryDOM.textContent || "";

  // USED FOR MAIL CLIENT TEMPLATE
  temporaryDOM.innerHTML = EMAIL_ENCODED;
  const TEMPLATE_EMAIL_ENCODED = temporaryDOM.textContent || "";

  return { TEMPLATE_TEXT_CONTENT, TEMPLATE_EMAIL_ENCODED };
};

export default handleEncodeEmailTemplate;
