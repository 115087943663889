// Components
import { Field, Form, Formik } from "formik";
import Button from "../Button/Button";
import FormDropdownSearchable from "../Form/FormDropdownSearchable";
import FormTextarea from "../Form/FormTextarea";

// Utilities & Hooks
import { useEffect, useState } from "react";
import {
  useApplicationCommentEdit,
  useApplicationCommentPost,
} from "../../api/Applications/Comments/Comments";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { DropdownItem } from "../Dropdown/interfaces";
import { ApplicationCommentMenuFormFields, ApplicationCommentMenuProps } from "./interfaces";

// Statics
import { APPLICATION_RATINGS } from "./statics";

// Schemas
import { APPLICATIONS_COMMENT } from "../../schemas/ApplicationsSchemas";

const ApplicationCommentMenu = ({
  applicationID,
  mode = "create",
  comments,
  commentDetails = null,
  handleCloseMenu,
}: ApplicationCommentMenuProps) => {
  const errorReporting = useErrorReporting();

  // Close menu if the comment being edited gets deleted in the meantime
  useEffect(() => {
    if (!commentDetails || mode !== "edit" || !comments.length) return;

    if (!comments.find(comment => comment.id === commentDetails.id)) {
      handleCloseMenu();
    }
  }, [comments]);

  /*========================
    COMMENT FORM DETAILS
  =========================*/
  const [formDetails, setFormDetails] = useState<ApplicationCommentMenuFormFields>({
    content: "",
    rating: 0,
  });

  // Populate the form fields if the menu is opened in "edit" mode
  useEffect(() => {
    if (mode === "edit" && commentDetails) {
      setFormDetails({ content: commentDetails.content, rating: commentDetails.rating });
    }
  }, [mode, commentDetails]);

  /*========================
    COMMENT ACTIONS

    (Post new, or Edit existing comments)
  =========================*/
  const [ongoingCommentAction, setOngoingCommentAction] = useState<boolean>(false);
  const postComment = useApplicationCommentPost();
  const editComment = useApplicationCommentEdit();

  const handleCommentActions = async (commentValues: ApplicationCommentMenuFormFields) => {
    setOngoingCommentAction(true);

    try {
      // Close the menu before the API request finalizes as we're using optimistic updates
      handleCloseMenu();

      if (mode === "create") {
        // Post a new comment for the application
        await postComment.mutateAsync({
          application_id: applicationID,
          ...(commentValues.content && { content: commentValues.content }),
          ...(commentValues.rating && { rating: commentValues.rating }),
        });
      } else {
        // Edit the existing targeted comment in the application
        await editComment.mutateAsync({
          application_id: applicationID,
          comment_id: commentDetails!.id,
          ...(commentValues.content && { content: commentValues.content }),
          ...(commentValues.rating && { rating: commentValues.rating }),
        });
      }

      // Close the comments menu
      handleCloseMenu();
    } catch (error) {
      errorReporting(
        `Error: ${mode === "create" ? "Creating" : "Editing"} Application's comment.`,
        error,
        {
          application_id: applicationID,
          ...(commentDetails?.id && { comment_id: commentDetails.id }),
        },
      );
    } finally {
      setOngoingCommentAction(false);
    }
  };

  return (
    <Formik
      initialValues={formDetails}
      enableReinitialize
      validationSchema={APPLICATIONS_COMMENT}
      onSubmit={handleCommentActions}
    >
      {({ setFieldValue }) => (
        <Form>
          <Field
            component={FormTextarea}
            name="content"
            id="content"
            placeholder="Type your comment here..."
            modifierClass="mb--20"
            rows={5}
          />

          <Field
            component={FormDropdownSearchable}
            items={APPLICATION_RATINGS}
            id="rating"
            name="rating"
            size="full"
            placeholder="Rate this applicant"
            preselectedItemValue={formDetails.rating}
            handleFieldUpdate={(rating: DropdownItem) => {
              setFieldValue("rating", rating.value);
            }}
            orientation="top"
            modifierClass="applications-comment__rating-dropdown mb--20"
          />

          <div className="applications-comment__rating__buttons">
            <Button
              type="button"
              modifierClass="btn--fluid btn--pill btn--transparent btn--transparent--primary--light btn--border btn--border--primary--light"
              onClick={handleCloseMenu}
            >
              Cancel
            </Button>

            <Button
              modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary ml--10"
              isLoading={ongoingCommentAction}
              isDisabled={ongoingCommentAction}
            >
              {mode === "edit" ? "Edit Comment" : "Post Comment"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationCommentMenu;
