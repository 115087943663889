// Utilities & Hooks
import { AnimatePresence, motion } from "framer-motion";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { JobPositionFields } from "../../api/JobPositions/interfaces";
import {
  useJobPositionsDefaultCreate,
  useJobPositionsDefaultDelete,
  useJobPositionsDefaultUpdate,
  useJobPositionsDefaultGet,
} from "../../api/JobPositions/JobPositions";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/Form/FormInput";
import Modal from "../../components/Modal/Modal";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

// Schemas
import { JOB_POSITIONS_WRITE_SCHEMA } from "../../schemas/JobPositionSchemas";

const JobDefaultPositions = () => {
  const errorReporting = useErrorReporting();

  /*===============================
    TABLE OF COMPANY POSITIONS
  ================================*/
  const COLUMN_HELPER = createColumnHelper<JobPositionFields>();
  const JOB_POSITIONS_DEFAULT = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Name</span>,
      cell: data => <span className="break-word">{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Actions</span>,
      enableSorting: false,
      size: 50,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => {
        const positionID: number = data.getValue();
        const positionName: string = data.row.original.name;
        return (
          <div className="table__buttons">
            <PermissionCheckComponentWrapper permissions={["job_position_edit"]}>
              <Button
                modifierClass="btn--fluid btn--primary--light"
                isDisabled={!positionID}
                onClick={() => {
                  setSelectedPositionDetails({ id: positionID, name: positionName });
                  setShowEditModal(true);
                }}
              >
                Edit
              </Button>
            </PermissionCheckComponentWrapper>

            <PermissionCheckComponentWrapper permissions={["job_position_delete"]}>
              <Button
                modifierClass="btn--fluid btn--danger ml--10"
                isDisabled={!positionID}
                onClick={() => {
                  setSelectedPositionDetails({ id: positionID, name: positionName });
                  setShowDeleteModal(true);
                }}
              >
                Delete
              </Button>
            </PermissionCheckComponentWrapper>
          </div>
        );
      },
    }),
  ];

  /*============================
    FETCH DATA FROM API
  =============================*/
  const { data, isLoading } = useJobPositionsDefaultGet();
  const [tableData, setTableData] = useState<JobPositionFields[]>([]);

  useEffect(() => {
    if (!data || !data.length || isLoading) return;

    // Order the default positions alphabetically
    const orderedTableData = [...data].sort((positionA, positionB) => {
      return positionA.name.toLowerCase() > positionB.name.toLocaleLowerCase() ? 1 : -1;
    });

    setTableData(orderedTableData);
  }, [data, isLoading]);

  /*============================
    JOB POSITION SELECTION 
  =============================*/
  const [selectedPositionDetails, setSelectedPositionDetails] = useState({
    id: null as number | null,
    name: null as string | null,
  });

  /*============================
    DEFAULT POSITION CREATE
  =============================*/
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const createDefaultPosition = useJobPositionsDefaultCreate();

  const handleDefaultPositionCreate = async ({ name }: FormikValues) => {
    try {
      handleCloseModal();
      await createDefaultPosition.mutateAsync({ name });
    } catch (error) {
      errorReporting("Failed creating 'Default' job position", error, { job_position_name: name });
    }
  };

  /*============================
    DEFAULT POSITION EDIT
  =============================*/
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const editDefaultPosition = useJobPositionsDefaultUpdate();

  const handleDefaultPositionEdit = async ({ name }: FormikValues) => {
    // Prevent any actions if there's no selected details
    if (!selectedPositionDetails.id) return;

    try {
      handleCloseModal();
      await editDefaultPosition.mutateAsync({ id: selectedPositionDetails.id, name });
    } catch (error) {
      errorReporting("Failed editing 'Default' job position", error, {
        job_position_id: selectedPositionDetails.id,
      });
    }
  };

  /*============================
    DEFAULT POSITION DELETE
  =============================*/
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteDefaultPosition = useJobPositionsDefaultDelete();

  const handleDefaultPositionDelete = async () => {
    // Prevent any actions if there's no selected details
    if (!selectedPositionDetails.id) return;

    try {
      handleCloseModal();
      await deleteDefaultPosition.mutateAsync({ id: selectedPositionDetails.id });
    } catch (error) {
      errorReporting("Failed deleting 'Default' job position", error, {
        job_position_id: selectedPositionDetails.id,
      });
    }
  };

  /*================================
    RESET SELECTED POSITION DETAILS
  =================================*/
  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedPositionDetails({ id: null, name: null });
  };

  return (
    <motion.div
      initial={{ opacity: 0, display: "none" }}
      animate={{ opacity: 1, display: "block" }}
      exit={{ opacity: 0, display: "none" }}
      transition={{ duration: 1, type: "spring" }}
    >
      <div className="d-flex justify-content-between align-items-center mb--30">
        <p className="txt--black mb--0">List of all default job positions.</p>

        <PermissionCheckComponentWrapper permissions={["job_position_create"]}>
          <Button
            type="button"
            modifierClass="btn--secondary btn--fluid"
            onClick={() => setShowCreateModal(true)}
          >
            Create
          </Button>
        </PermissionCheckComponentWrapper>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center my--30">
          <Loader size="lg" />
        </div>
      ) : (
        <Table
          data={tableData}
          columns={JOB_POSITIONS_DEFAULT}
          isRefetching={false}
          paginationPageSize={10}
          modifierClass="table-wrapper--no-shadow mb--0i px--0i"
        />
      )}

      {/* CREATE DEFAULT POSITION */}
      <AnimatePresence>
        {showCreateModal ? (
          <Modal
            title="Create Default Position"
            text=""
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <Formik
              initialValues={{ name: "" }}
              validationSchema={JOB_POSITIONS_WRITE_SCHEMA}
              onSubmit={handleDefaultPositionCreate}
            >
              <Form>
                <Field
                  component={FormInput}
                  id="name"
                  name="name"
                  placeholder="Default Job Position"
                  modifierClass="mb--15"
                />

                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary--light ml--10"
                  isLoading={createDefaultPosition.isLoading}
                  isDisabled={createDefaultPosition.isLoading}
                >
                  Create
                </Button>
              </Form>
            </Formik>
          </Modal>
        ) : null}
      </AnimatePresence>

      {/* EDIT DEFAULT POSITION */}
      <AnimatePresence>
        {showEditModal ? (
          <Modal
            title={`Edit position: ${selectedPositionDetails.name}`}
            text=""
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <Formik
              initialValues={{ name: selectedPositionDetails.name ?? "" }}
              enableReinitialize
              validationSchema={JOB_POSITIONS_WRITE_SCHEMA}
              onSubmit={handleDefaultPositionEdit}
            >
              <Form>
                <Field
                  component={FormInput}
                  id="name"
                  name="name"
                  placeholder="Default Job Position"
                  modifierClass="mb--15"
                />

                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary--light ml--10"
                  isLoading={editDefaultPosition.isLoading}
                  isDisabled={editDefaultPosition.isLoading}
                >
                  Edit
                </Button>
              </Form>
            </Formik>
          </Modal>
        ) : null}
      </AnimatePresence>

      {/* DELETE DEFAULT POSITION */}
      <AnimatePresence>
        {showDeleteModal ? (
          <Modal
            title="Delete position?"
            text={
              <>
                Are you sure you want to delete{" "}
                <strong className="txt--black">{selectedPositionDetails.name}</strong> ? This action
                is irreversible.
              </>
            }
            handleCloseModal={handleCloseModal}
            modifierClass="modal--md modal--fixated"
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                type="button"
                modifierClass="btn--fluid btn--fluid--md btn--danger ml--10"
                onClick={handleDefaultPositionDelete}
                isLoading={deleteDefaultPosition.isLoading}
                isDisabled={deleteDefaultPosition.isLoading}
              >
                Delete
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

export default JobDefaultPositions;
