// Components
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import Datepicker from "../../components/Datepicker/Datepicker";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Dropdown from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader/Loader";

// Hooks & Utilities
import { useMemo, useState } from "react";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import { useReportGetIndeedSponsored } from "../../api/Reports/Reports";
import { format, subDays } from "date-fns";
import { matchSorter } from "match-sorter";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";

// Interfaces & Constants
import { ReportIndeedSponsoredAdsResponseFields } from "../../api/Reports/interfaces";
import { ReportIndeedSponsoredTableData } from "./interfaces";
import {
  REPORTS_INDEED_SPONSORED_DOCUMENT_COLUMNS,
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
} from "./constants";

const IndeedSponsored = () => {
  /*===============================================
    DATE RANGE SELECTION

    The date range is handled using the custom hook
    which handles the 'preview dates' and the 
    'active dates'.
  ================================================*/
  // Call designated Report Date Range Handling hook with desired initial values
  const { pickerDates, filterDates, setFilterDates, handleStartDate, handleEndDate } =
    useReportDateRangeHandling({
      initial_start_date: format(subDays(Date.now(), 7), "MM/dd/yyyy"),
      initial_end_date: format(Date.now(), "MM/dd/yyyy"),
    });

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportIndeedSponsoredTableData>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.display({
      id: "row_id",
      header: () => <span>#</span>,
      size: 20,
      enableSorting: true,
      cell: props => <span>{+props.row.id + 1}</span>,
    }),
    COLUMN_HELPER.accessor("ad_id", {
      header: () => <span>Ad Id</span>,
      size: 70,
      enableSorting: true,
      cell: data => (
        <span>
          <Link to={`/job-ads/ads/${data.getValue()}/`} className="d-flex align-items-center">
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job Code</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 150,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/job-ads/ads/${data.row.original.ad_id}/`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("company", {
      header: () => <span>Company</span>,
      size: 160,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("budget", {
      header: () => <span>Budget</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("weeks", {
      header: () => <span>Weeks</span>,
      size: 40,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("post_date", {
      header: () => <span>Post Date</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{format(data.getValue(), "dd MMM yyyy hh:mm:ss aa")}</span>,
    }),
    COLUMN_HELPER.accessor("take_down_date", {
      header: () => <span>Take Down Date</span>,
      size: 100,
      enableSorting: true,
      cell: data => (
        <span>{data.getValue() ? format(data.getValue(), "dd MMM yyyy hh:mm:ss aa") : "N/A"}</span>
      ),
    }),
    COLUMN_HELPER.accessor("refreshed", {
      header: () => <span>Refreshed?</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("refresh_date", {
      header: () => <span>Refresh Date</span>,
      size: 100,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("applications", {
      header: () => <span>Applications</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("resumes", {
      header: () => <span>Resumes</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("sbcas", {
      header: () => <span>SBCAs</span>,
      size: 50,
      enableSorting: true,
      cell: data => <span>{data.getValue() ?? "N/A"}</span>,
    }),
  ];

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    isLoading: indeedSponsoredLoading,
    isRefetching: indeedSponsoredRefetching,
    isFetching: indeedSponsoredFetching,
    data: indeedSponsoredData,
  } = useReportGetIndeedSponsored(filterDates);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<
    ReportIndeedSponsoredAdsResponseFields[]
  >([]);

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "ad_id", "job_code", "title" & "company"
    data fields. 
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    let data = indeedSponsoredData?.ads ?? [];
    if (data.length && searchedValue) {
      data = matchSorter(data, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "ad_id" },
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "title" },
          { threshold: matchSorter.rankings.CONTAINS, key: "company" },
        ],
      });
    }

    const mappedData = data.map((entity, index) => {
      return {
        id: index + 1,
        ad_id: entity.ad_id,
        job_code: entity.job_code,
        title: entity.title,
        company: entity.company,
        budget: entity.budget,
        weeks: entity.weeks,
        post_date: entity.post_date ? new Date(entity.post_date) : null,
        take_down_date: entity.take_down_date ? new Date(entity.take_down_date) : null,
        refreshed: entity.refreshed ? "Yes" : "No",
        refresh_date: entity.refresh_date,
        applications: entity.applications,
        resumes: entity.resumes,
        sbcas: entity.sbcas,
      };
    });

    return mappedData;
  }, [searchedValue, indeedSponsoredData]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  /*====================================================
    DOCUMENT EXPORT DATA
  =====================================================*/
  const EXPORT_DATA = useMemo(() => {
    if (!exportedTableData.length) return [];

    const formattedDates = exportedTableData.map(item => {
      return {
        ...item,
        post_date: item.post_date
          ? format(new Date(item.post_date), "dd MMM yyyy hh:mm:ss a")
          : "N/A",
        take_down_date: item.take_down_date
          ? format(new Date(item.take_down_date), "dd MMM yyyy hh:mm:ss a")
          : "N/A",
      };
    });

    return formattedDates;
  }, [exportedTableData]);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">Indeed Sponsored Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || "Select start date"}
                    dateFormat="m/d/Y"
                    isDisabled={indeedSponsoredLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">End Date:</div>
                </div>
                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={indeedSponsoredLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-2 col-md-4 d-flex flex-column align-items-xl-start justify-content-md-end align-items-end pr--0 pr--md--15 pb--15 pb--sm--20 ">
              <Button
                modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                onClick={() => setFilterDates(pickerDates)}
                isLoading={indeedSponsoredLoading || indeedSponsoredFetching}
                isDisabled={indeedSponsoredFetching || indeedSponsoredLoading}
              >
                Run Report
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <div className="px--30">
        {!indeedSponsoredLoading ? (
          <>
            <Card modifierClass="card--padding--xl">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column align-items-start align-items-md-start">
                    <h5 className="fw--semibold mb--xl--10">
                      Currently Viewing: {filterDates.start_date} through {filterDates.end_date}
                    </h5>
                    <h5 className="mb--xl--10">Total Ads: {REPORT_TABLE_DATA.length}</h5>
                    <h5 className="mb--xl--10">
                      Total Budget:{" "}
                      {REPORT_TABLE_DATA.length &&
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          REPORT_TABLE_DATA.reduce(
                            (accumulator, currentEntity) => accumulator + currentEntity.budget,
                            0,
                          ),
                        )}
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="d-flex flex--wrap--md justify-content-start justify-content-lg-end mb--10"
                    style={{ gridGap: "10px" }}
                  >
                    <InputFieldSearch
                      handleOnSearch={search => setSearchedValue(search)}
                      placeholder="Search Reports"
                      size="md"
                    />
                    <Dropdown
                      title="Select number of rows"
                      preselectedItemValue={rowsPerPage}
                      size="md"
                      items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                      handleItemSelected={item => setRowsPerPage(item.value as number)}
                    />
                  </div>
                </div>
              </div>
            </Card>

            <Card>
              <h3 className="fw--semibold mb--5 px--20 pt--10">Export Data</h3>
              <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                  onClick={() =>
                    handleInitiateSpreadsheetGenerator(
                      EXPORT_DATA,
                      "indeed_sponsored_report",
                      REPORTS_INDEED_SPONSORED_DOCUMENT_COLUMNS,
                      "default-csv",
                      `${filterDates.start_date} to ${filterDates.end_date}`,
                    )
                  }
                  isDisabled={!REPORT_TABLE_DATA.length || indeedSponsoredFetching}
                >
                  CSV
                </Button>
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15 txt--uppercase"
                  onClick={() =>
                    handleInitiatePDFWorker({
                      columns: REPORTS_INDEED_SPONSORED_DOCUMENT_COLUMNS,
                      data: EXPORT_DATA,
                      documentTitle: "FirstChoice Hiring - Indeed Sponsored Report",
                      documentSubTitle: `Data Date: ${filterDates.start_date} to ${filterDates.end_date}`,
                      template: "default-report",
                      fileName: "indeed_sponsored_report",
                      setPDFLoading: setPDFLoading,
                    })
                  }
                  isDisabled={!REPORT_TABLE_DATA.length || indeedSponsoredFetching || PDFLoading}
                  isLoading={PDFLoading}
                >
                  PDF
                </Button>
              </div>
              <Table
                data={REPORT_TABLE_DATA}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={indeedSponsoredRefetching}
                paginationPageSize={rowsPerPage}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default IndeedSponsored;
