import VideoConferencingPublic from "../pages/VideoConferencing/VideoConferencingPublic";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_VIDEO_CONFERENCING: ApplicationRouteProps[] = [
  {
    path: "/vidconf/join/:id",
    element: <VideoConferencingPublic />,
    permissions: ["*"],
    type: "public",
  },
];
