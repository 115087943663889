// Utilities & Hooks
import { toast } from "react-toastify";
import { cloneDeep } from "lodash-es";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import fetchHandlerUpload from "../fetchHandlerUpload";
import handleFormDataFields from "../../utilities/handleFormDataFields";
import handleErrorMessage from "../../utilities/handleErrorMessage";

// Constants
import { ARTICLES_STATUSES } from "../../pages/Articles/constants";

// Interfaces
import {
  AdminArticleDetailsResponseFields,
  ArticleArchivingAction,
  ArticlesResponseFields,
  ArticlesStatusesResponseFields,
} from "./interfaces";
import { ArticlesEditFormState } from "./interfaces";

/**
 *
 * Get the list of ALL existing articles from the API.
 *
 */
export function useAdminArticlesGet() {
  return useQuery(
    ["articles-admin"],
    async () => {
      return (await fetchHandler("GET", "admin/articles")) as ArticlesResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Get the details of a specific article using it's ID
 * @param id The ID of the targeted article
 *
 */
export function useAdminArticlesGetSpecific(id: string | undefined) {
  return useQuery(
    ["article-admin", id],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/articles/${id}`,
      )) as AdminArticleDetailsResponseFields;
    },
    // Send the request ONLY if ":id" route parameter exists
    { enabled: !!id, onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Archive or Unarchive the targeted Article by its ID.
 *
 * Unarchive action will set the status of the article to "draft".
 *
 */
export function useAdminArticleArchiving() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, status }: ArticleArchivingAction) => {
      return await fetchHandler("PUT", `admin/articles/${id}/status`, {
        status_id: ARTICLES_STATUSES[status],
      });
    },
    {
      onMutate: variables => {
        // Snapshot of already existing articles before any update happens
        const previousArticles = queryClient.getQueryData([
          "articles-admin",
        ]) as ArticlesResponseFields[];

        // Destructure the variables used for updating
        const { id, status } = variables;

        // Update the "status" of the targeted article
        // and save the updated data in the query client
        const targetedArticleIndex = previousArticles.findIndex(article => article.id === id);

        // If the targeted article exists, update its status and the query data
        if (targetedArticleIndex >= 0) {
          // Make a copy of the existing articles, and modify the
          // status of the targeted article to a 'draft' status
          const updatedArticles = cloneDeep(previousArticles);
          updatedArticles[targetedArticleIndex].status = status;

          // Update the data saved in the query client
          queryClient.setQueryData(["articles-admin"], updatedArticles);

          // Show toast notification in the UI
          toast.success(
            `Article was successfully ${variables.status === "draft" ? "unarchived" : "archived"}.`,
            {
              toastId: "articles-archive",
            },
          );
        }

        return previousArticles;
      },
      onError: (err, _variables, previousArticles) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("articles-archive");

        queryClient.setQueryData(["articles-admin"], previousArticles);
        return err;
      },
    },
  );
}

/**
 *
 * Get the list of existing articles statuses (e.g. "draft", "published", "archived")
 *
 */
export function useAdminArticlesStatuses() {
  return useQuery(
    ["articles-statuses"],
    async () => {
      return (await fetchHandler(
        "GET",
        "admin/articles/status",
      )) as ArticlesStatusesResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Create a new Article
 *
 */
export function useAdminArticlesCreate() {
  const queryClient = useQueryClient();

  return useMutation(async () => await fetchHandler("POST", "admin/articles"), {
    onMutate: () => {
      // Snapshot of already existing articles before any update happens
      const existingArticles = queryClient.getQueryData([
        "articles-admin",
      ]) as ArticlesResponseFields[];

      return existingArticles;
    },
    onSuccess: (responseData, _articleDetails, existingArticlesData) => {
      const existingArticles = existingArticlesData || [];

      // If article was successfully created, push it
      // to the list of already existing articles
      existingArticles.push(responseData);

      // Update both cached queries with the newest data
      queryClient.setQueryData(["articles-admin"], existingArticles);
      queryClient.setQueryData(["articles"], existingArticles);

      toast.success("Article was created successfully.", {
        toastId: "articles-create",
      });
    },
    onError: (err, _variables, previousArticles) => {
      // Dismiss the success notification from the UI first
      toast.dismiss("articles-create");

      // Invalidate both queries if something went wrong
      queryClient.setQueryData(["articles-admin"], previousArticles);
      queryClient.setQueryData(["articles"], previousArticles);

      return err;
    },
    onSettled: () => queryClient.invalidateQueries(["articles-admin", "articles"]),
  });
}

/**
 *
 * Edit an exsting Article
 *
 * @param id The ID of the targeted Article to be edited
 *
 */
export function useAdminArticlesEdit(id: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation(
    async (articleDetails: Partial<ArticlesEditFormState>) => {
      // If the ID value does not exist for the article, exit the function
      if (!id) return;

      const formData = handleFormDataFields<ArticlesEditFormState>(articleDetails);
      formData?.append("_method", "PUT");

      return await fetchHandlerUpload("POST", `admin/articles/${id}`, formData);
    },
    {
      onMutate: variables => {
        // Snapshot of already existing articles before any update happens
        const existingArticles = queryClient.getQueryData([
          "articles-admin",
        ]) as ArticlesResponseFields[];

        // Only show notification when not requesting a "preview" of the article
        if (variables.preview === "no") {
          toast.success("Article was edited successfully.", {
            toastId: "articles-edit",
          });
        }

        return existingArticles;
      },
      onError: (err, _variables, previousArticles) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("articles-edit");

        // Invalidate both queries if something went wrong
        queryClient.setQueryData(["articles-admin"], previousArticles);
        queryClient.setQueryData(["articles"], previousArticles);

        return err;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["articles-admin"]);
        queryClient.invalidateQueries(["articles"]);
        queryClient.invalidateQueries(["articles-notifications"]);
      },
    },
  );
}
