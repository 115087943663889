import { ApplicationsSortProps } from "./interfaces";

// Assets
import { FaSortDown as SortDescendingIcon, FaSortUp as SortAscendingIcon } from "react-icons/fa";

const ApplicationsSort = ({
  sortBy = "registration_date",
  sortDirection = "desc",
  handleApplicationsSort,
}: ApplicationsSortProps) => {
  return (
    <div className="applications__sort ml--30 ml--md--0">
      <p>Sort by</p>
      <p
        className={`applications__sort__action applications__sort__action--splitter ${
          sortBy === "registration_date" ? "applications__sort__action--active" : ""
        }`}
        onClick={() => handleApplicationsSort("registration_date")}
        data-testid="button:sort-by-date"
      >
        Date
        {sortBy === "registration_date" ? (
          sortDirection === "desc" ? (
            <SortDescendingIcon />
          ) : (
            <SortAscendingIcon style={{ transform: "translateY(5px)" }} />
          )
        ) : null}
      </p>
      <p
        className={`applications__sort__action applications__sort__action--score ${
          sortBy === "sbca" ? "applications__sort__action--active" : ""
        }`}
        onClick={() => handleApplicationsSort("sbca")}
        data-testid="button:sort-by-score"
      >
        Score
        {sortBy === "sbca" ? (
          sortDirection === "desc" ? (
            <SortDescendingIcon />
          ) : (
            <SortAscendingIcon style={{ transform: "translateY(5px)" }} />
          )
        ) : null}
      </p>
    </div>
  );
};

export default ApplicationsSort;
