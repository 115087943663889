// Hooks & Utilities
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTour } from "../../providers/tour-context";
import { useVisibleBuckets } from "./hooks/useVisibleBuckets";

// Assets
import { BiChevronsRight as BucketsExtraIcon } from "react-icons/bi";

// Interfaces & Constant
import { BucketsDropdownItem, BucketsDropdownProps } from "./interfaces";
import { PRODUCT_TOURS_DATA } from "../ProductTour/constants";

const BucketsDropdown = ({
  bucketsDropdownItems,
  activeBucketName,
  handleBucketSelection,
  selectionBelongsToDropdown,
  label,
}: BucketsDropdownProps) => {
  /*========================
    BUCKETS MENU
  =========================*/
  const [isBucketsMenuOpen, setIsBucketsMenuOpen] = useState<boolean>(false);
  const bucketsMenuRef = useRef<HTMLDivElement | null>(null);

  /*========================
    SELECT THE BUCKET
    AS THE ACTIVE ITEM
  =========================*/
  const handleBucketDropdownItem = (bucketName: string) => {
    setIsBucketsMenuOpen(false);
    handleBucketSelection(bucketName);
  };

  /*========================
    CLOSE WHEN CLICKED
    OUTSIDE THE MENU
  =========================*/
  useOnClickOutside(bucketsMenuRef, () => setIsBucketsMenuOpen(false));

  // TOUR_FLAG[buckets-improved-workflow]
  const {
    tourCurrentStepName,
    handleTourEnd,
    handleTourNextStep,
    isTourRunning,
    handlePrepStep,
    activeTour,
  } = useTour();

  const visibleBucketCount = useVisibleBuckets();

  useEffect(() => {
    // exit if theres no ongoing tour
    if (!isTourRunning || !activeTour || activeTour.tourId !== "buckets-improved-workflow") return;

    const correctStepCheck: boolean = tourCurrentStepName === "buckets archived showcase";

    // only open the menu if we're at the correct step of the tour
    if (correctStepCheck) setIsBucketsMenuOpen(true);

    // trigger the next step of the tour only if we're at the correct step and the menu is open
    // otherwise, close the menu if we want to go to previous step
    if (!isBucketsMenuOpen && correctStepCheck) {
      handleTourNextStep(10);
    }

    // Remove the first step (unrelated to specific bucket) and map the rest of the step names
    const mappedSteps = PRODUCT_TOURS_DATA[0].steps.map(specificBucketStep => {
      return specificBucketStep.stepName;
    });

    // Cross reference the mapped specific bucket steps with the currently visible bucket count and find the turnover index
    // Based on that index and the state of the dropdown, toggle the dropdown on/off
    const foundIndex = mappedSteps.findIndex(step => step === tourCurrentStepName);
    if (foundIndex > visibleBucketCount && !isBucketsMenuOpen) {
      handlePrepStep(tourCurrentStepName, () => setIsBucketsMenuOpen(true), 50);
    } else if (foundIndex <= visibleBucketCount && isBucketsMenuOpen) {
      setIsBucketsMenuOpen(false);
    }

    // Note: This will leave the dropdown menu open after the tour has ended / was closed
  }, [tourCurrentStepName, isBucketsMenuOpen, visibleBucketCount, isTourRunning]);

  return (
    <div className="buckets__dropdown" ref={bucketsMenuRef}>
      <div className="d-flex align-items-center">
        {label && (
          <label
            htmlFor="dropdown"
            className="mr--10 txt--link fw--semibold txt--lg"
            onClick={() => setIsBucketsMenuOpen(!isBucketsMenuOpen)}
          >
            {label}
          </label>
        )}
        <div
          className={`buckets__dropdown__icon ${
            isBucketsMenuOpen || selectionBelongsToDropdown ? "buckets__dropdown__icon--active" : ""
          }`}
          onClick={() => setIsBucketsMenuOpen(!isBucketsMenuOpen)}
          data-testid="buckets:dropdown"
        >
          <BucketsExtraIcon />
        </div>

        {isBucketsMenuOpen && (
          <ul className="buckets__dropdown__content">
            {bucketsDropdownItems.map((bucketDropdownItem: BucketsDropdownItem, index: number) => (
              <li
                className={`buckets__dropdown__item js-bucket-${bucketDropdownItem.name
                  .replaceAll(" ", "_")
                  .toLowerCase()} ${
                  activeBucketName.toLowerCase() === bucketDropdownItem.name.toLowerCase()
                    ? "buckets__dropdown__item--active"
                    : ""
                } ${
                  index === bucketsDropdownItems.length - 1 ? "buckets__dropdown__item--last" : ""
                }`}
                key={`bucketDropdownItem-${index}`}
                onClick={() => {
                  handleBucketDropdownItem(bucketDropdownItem.name);

                  // TOUR_FLAG[buckets-improved-workflow]
                  handleTourEnd();
                }}
              >
                {bucketDropdownItem.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BucketsDropdown;
