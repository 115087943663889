// Utilities & Hooks
import { useState } from "react";
import { extractTextEditorToolbarButtonNames } from "../../WYSIWYG/utils/extractTextEditorToolbarButtonNames";
import generateAdTemplate from "./generate-ad-template";

// Component
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import Modal from "../../Modal/Modal";
import TextEditor from "../../WYSIWYG/TextEditor";

// Interfaces
import { JobAdEditorProps } from "./interfaces";
import { TextEditorButtonsFields } from "../../WYSIWYG/interfaces";
import { AnimatePresence } from "framer-motion";

const JobAdEditor = ({
  images,
  form_identifier,
  values,
  type,
  setFieldValue,
}: JobAdEditorProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  /*===================================
    FORM URL
  ====================================*/
  const [formURL] = useState<string>(() => {
    const { host, protocol } = window.location;
    const url: string = `${protocol}//${host}/online-application/initialize-oa/${form_identifier}`;
    return url;
  });

  /*===================================
    GENERATE CONTEXT FOR THE EDITOR
  ====================================*/
  const handleGenerateAdTemplate = () => {
    const GENERATED_CONTENT: string = generateAdTemplate(values, formURL, images, type);
    setFieldValue("content", GENERATED_CONTENT);
    setShowConfirmationModal(false);
  };

  /*===================================
    CUSTOM EDITOR BUTTONS
  ====================================*/
  const TEXT_EDITOR_ADS_BUTTON_DEFINITIONS: TextEditorButtonsFields[] = [
    ...(images.apply_button
      ? [
          {
            name: "applyButton",
            properties: {
              text: "Apply Btn",
              tooltip: "Insert Apply Button",
              contentToInsert: `
          <p style="text-align: center;">
            <a href="${formURL}" target="_blank" rel="noopener">
              <img src="${images.apply_button}" />
            </a>
          </p>
        `,
            },
          },
        ]
      : []),

    ...(images.logo
      ? [
          {
            name: "logoButton",
            properties: {
              text: "Logo",
              tooltip: "Insert Company Logo Image",
              contentToInsert: `
          <p style="text-align: center;">
            <img src="${images.logo}" />
          </p>
        `,
            },
          },
        ]
      : []),

    ...(images.banner
      ? [
          {
            name: "bannerButton",
            properties: {
              text: "Banner",
              tooltip: "Insert Banner Image",
              contentToInsert: `
          <p style="text-align: center;">
            <img src="${images.banner}" />
          </p>
        `,
            },
          },
        ]
      : []),

    ...(images.full_graphic
      ? [
          {
            name: "fullGraphicButton",
            properties: {
              text: "Full Graphic",
              tooltip: "Insert Full Graphic Image",
              contentToInsert: `
          <p style="text-align: center;">
            <a href="${formURL}" target="_blank" rel="noopener">
              <img src="${images.full_graphic}" />
            </a>
          </p>
        `,
            },
          },
        ]
      : []),
  ];

  return (
    <Card modifierClass="card--padding--sm">
      <div className="d-flex justify-content-between align-items-center mb--10 flex-wrap">
        <div
          className="d-flex align-items-center mb--10"
          style={{ maxWidth: "80%", width: "100%" }}
        >
          <h3 className="txt--blue fw--semibold flex-shrink-0 mr--10">Job Ad Editor</h3>

          <input
            title="Application Form URL Preview"
            className="input__field input__field--disabled--blue-tint"
            readOnly
            value={formURL}
          />

          <div className="d-flex align-items-center flex-shrink-0 ml--10">
            <a href={formURL} target="_blank" className="txt--lg fw--medium txt--link">
              (Click here to view)
            </a>
          </div>
        </div>

        <Button
          type="button"
          modifierClass="btn--fluid btn--primary flex-shrink-0 mb--10"
          onClick={() => setShowConfirmationModal(true)}
        >
          Generate Ad Template
        </Button>
      </div>

      <TextEditor
        initialValue={values.content}
        handleEditorContent={(content: string) => setFieldValue("content", content)}
        placeholder=""
        customButtons={{
          buttons: TEXT_EDITOR_ADS_BUTTON_DEFINITIONS,
          toolbar: extractTextEditorToolbarButtonNames(TEXT_EDITOR_ADS_BUTTON_DEFINITIONS),
        }}
        image_upload_url="admin/job-ads/images"
      />

      <AnimatePresence>
        {showConfirmationModal ? (
          <Modal
            title="Overwrite existing content?"
            text={
              <>
                This action will overwrite the existing content in the text editor. <br /> Are you
                sure you want to proceed?
              </>
            }
            handleCloseModal={() => setShowConfirmationModal(false)}
            modifierClass="modal--padding--md modal--fixated"
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary--light"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="button"
                modifierClass="btn--fluid btn--fluid--md btn--primary ml--10"
                onClick={handleGenerateAdTemplate}
              >
                Ok
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </Card>
  );
};

export default JobAdEditor;
