/**
 *
 * Utility function for checking if the user is visiting the application
 * from Chrome browser on an Android device, based on which a notice
 * should be displayed alongside form upload fields that files cannot
 * be uploaded directly from Google Drive.
 *
 * There is existing bug in Chrome that causes issues when trying to
 * upload some files directly from Google Drive.
 *
 * Link to bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1063576
 */
export default function handleCheckIfChromeAndroid(): boolean {
  if (!navigator.userAgent) return true;

  // Check if the user is visiting the application using Chrome browser on Android device
  return /android.*chrome/gi.test(navigator.userAgent);
}
