// Hooks & Utilities
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

// Components
import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import AssessmentEmailModal from "./Modal/AssessmentEmailModal";

// Interfaces & Globals
import { AssessmentHeaderProps } from "./interfaces";

// Assets
import FCHLogoFull from "../../assets/images/fch-full-logo-white.png";
import AiIcon from "../../assets/images/icons/assessment-ai.svg?react";
import EmailIcon from "../../assets/images/icons/assessment-mail.svg?react";
import PrintIcon from "../../assets/images/icons/assessment-print.svg?react";
import UserIcon from "../../assets/images/icons/assessment-user.svg?react";
import CompanyIcon from "../../assets/images/icons/assessment-company.svg?react";
import TagIcon from "../../assets/images/icons/assessment-tag.svg?react";
import ReportIcon from "../../assets/images/icons/assessment-report.svg?react";
import SubmittedIcon from "../../assets/images/icons/assessment-submitted.svg?react";

const AssessmentHeader = ({ sbcaReport }: AssessmentHeaderProps) => {
  const { sbcaReportIdentifier } = useParams();

  /*=====================
    EMAIL ASSESSMENT
  ======================*/
  const [emailAssessmentModal, setEmailAssessmentModal] = useState<boolean>(false);

  return (
    <>
      <div className="assessment__header">
        <div className="container container--xl">
          <div className="d-flex flex-column flex-lg-row pb--40 align-items-center align-items-lg-start justify-content-center justify-content-lg-between line-under line-under--transparent line-under--absolute">
            <Link
              to="/applications/"
              className="d-flex justify-content-center justify-content-lg-start"
            >
              <img
                src={FCHLogoFull}
                alt="FirstChoice Hiring logo"
                className="assessment__header__logo"
              />
            </Link>

            <div className="assessment__header__buttons d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-end w--100">
              <AnimatePresence>
                <motion.div
                  initial={{
                    opacity: 0,
                    translateY: "-10%",
                  }}
                  animate={{
                    opacity: 1,
                    translateX: "0",
                    translateY: "0",
                  }}
                  className="btn--md flex-shrink-0 p--0 mr--60 mr--lg--0"
                >
                  <Tooltip text="View AI Insights" size="md">
                    <a
                      className="d-flex align-items-center justify-content-center btn btn--md btn--round btn--white--inverted"
                      href="#AIInsights"
                    >
                      <AiIcon className="mr--10" />
                      AI Insights
                    </a>
                  </Tooltip>
                </motion.div>
              </AnimatePresence>

              <Tooltip
                text="Email Assessment"
                size="md"
                wrapperModifierClass="btn--md ml--10 flex-shrink-0 p--0"
              >
                <Button
                  className="btn btn--md btn--round btn--white"
                  onClick={() => setEmailAssessmentModal(true)}
                >
                  <EmailIcon className="mr--10" /> Email SBCA
                </Button>
              </Tooltip>
              <Tooltip
                text="Print Assessment"
                size="md"
                wrapperModifierClass="btn--md ml--10 flex-shrink-0 p--0"
              >
                <Link
                  to={`/assessment/results/${sbcaReportIdentifier}/print`}
                  target="_blank"
                  className="btn btn--md btn--round btn--white"
                >
                  <div className="btn__content">
                    <PrintIcon className="mr--10" />
                    Print SBCA
                  </div>
                </Link>
              </Tooltip>
            </div>
          </div>
          <div className="assessment-info-wrapper">
            {/*Prepared For*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <UserIcon />
              </div>
              <h5 className="assessment-info__title">Prepared For</h5>
              {sbcaReport && <p className="assessment-info__content">{sbcaReport.prepared_for}</p>}
            </div>

            {/*COMPANY*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <CompanyIcon />
              </div>
              <h5 className="assessment-info__title">Company</h5>
              <p className="assessment-info__content">{sbcaReport.company}</p>
            </div>

            {/*Type*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <TagIcon />
              </div>
              <h5 className="assessment-info__title">Type</h5>
              <p className="assessment-info__content">{sbcaReport.type}</p>
            </div>

            {/*Permission*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <ReportIcon />
              </div>
              <h5 className="assessment-info__title">Permission</h5>
              <p className="assessment-info__content">{sbcaReport.permission}</p>
            </div>

            {/*SUBMITTED*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <SubmittedIcon />
              </div>
              <h5 className="assessment-info__title">Submitted</h5>
              {sbcaReport && <p className="assessment-info__content">{sbcaReport.submitted}</p>}
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {emailAssessmentModal && sbcaReport && (
          <AssessmentEmailModal
            sbca_report_identifier={sbcaReportIdentifier ?? ""}
            applicant_name={sbcaReport.prepared_for}
            handleCloseModal={() => setEmailAssessmentModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default AssessmentHeader;
