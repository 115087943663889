// Components
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import Appointments from "../pages/Appointments/Appointments";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_APPOINTMENTS: ApplicationRouteProps[] = [
  {
    path: "/appointments",
    element: (
      <LayoutAuthenticated>
        <Appointments />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_appointments_view"],
    type: "protected",
  },
];
