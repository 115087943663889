import { DropdownItem } from "../../../../Dropdown/interfaces";

/*=====================
  CATEGORY
=======================*/
export const TALENT_DROPDOWN_CATEGORY: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: 1, text: "Accounting" },
  { value: 2, text: "Administration" },
  { value: 3, text: "Agriculture" },
  { value: 4, text: "Analyst" },
  { value: 5, text: "Arts" },
  { value: 6, text: "Computer" },
  { value: 7, text: "Construction" },
  { value: 8, text: "Consulting" },
  { value: 9, text: "Customer Service" },
  { value: 10, text: "Education" },
  { value: 11, text: "Engineering" },
  { value: 12, text: "Finance" },
  { value: 13, text: "Food Industry" },
  { value: 14, text: "Healthcare" },
  { value: 15, text: "Hospitality" },
  { value: 16, text: "Human Resources" },
  { value: 17, text: "Insurance" },
  { value: 18, text: "IT" },
  { value: 19, text: "Legal" },
  { value: 20, text: "Logistics" },
  { value: 21, text: "Manufacturing" },
  { value: 22, text: "Marketing" },
  { value: 23, text: "Media" },
  { value: 24, text: "Multimedia" },
  { value: 25, text: "Nursing" },
  { value: 26, text: "Physicians" },
  { value: 27, text: "Retail" },
  { value: 28, text: "Sales" },
  { value: 29, text: "Scientific" },
  { value: 30, text: "Security" },
  { value: 31, text: "Services" },
  { value: 32, text: "Skilled Labor" },
  { value: 33, text: "Social Care" },
  { value: 34, text: "Telecommunications" },
  { value: 35, text: "Training" },
  { value: 36, text: "Transportation" },
  { value: 37, text: "Travel" },
  { value: 38, text: "Warehouse" },
];

/*=====================
  BUDGET
=======================*/
export const TALENT_DROPDOWN_BUDGET: DropdownItem[] = [
  { text: "$50", value: "$50" },
  { text: "$75", value: "$75" },
  { text: "$100", value: "$100" },
];
