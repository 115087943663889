// Hooks & Functionality
import { useState } from "react";
import { useAuth } from "../../providers/auth-context";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { AnimatePresence } from "framer-motion";
import Tooltip from "../Tooltip/Tooltip";
import EditInPersonAppointment from "./modals/EditInPersonAppointment";
import EditVideoMeetingAppointment from "./modals/EditVideoMeetingAppointment";
import DeleteAppointment from "./modals/DeleteAppointment";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

// Assets
import CalendarIcon from "../../assets/images/icons/appointment-calendar-icon.svg?react";
import EditIcon from "../../assets/images/icons/edit-icon.svg?react";
import DeleteIcon from "../../assets/images/icons/appointment-delete-icon.svg?react";

// Interfaces & Config
import { API_BASE_URL } from "../../config/config";
import { AppointmentsActionsProps } from "./interfaces";
import handleDownloadBlob from "../../utilities/data/handleDownloadBlob";

const AppointmentsActions = ({ details }: AppointmentsActionsProps) => {
  const errorReporting = useErrorReporting();

  const [modalToOpen, setModalToOpen] = useState<"edit-video" | "edit-in-person" | "delete" | null>(
    null,
  );

  const handleCloseModal = () => setModalToOpen(null);

  /*==========================
    ADD TO CALENDAR
  ===========================*/
  const { user } = useAuth();
  const handleAddAppointmentToCalendar = async () => {
    // Exit function if no company slug is available
    if (!user.active_company.slug) return;

    try {
      const accessToken = LocalStorageActions.getItem("accessToken");

      const response = await fetch(
        `${API_BASE_URL}/company/${user.active_company.slug}/appointments/${details.id}/add-to-calendar`,
        {
          method: "GET",
          headers: {
            Accept: "application/*",
            "Content-Type": "application/*",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) throw new Error("Cannot download appointment ICS file.");

      const blob = await response.blob();

      // We reconstruct the received blob with the proper MIME-Type so it works on ALL platforms (mobile/web)
      const formattedMimeTypeBlob = new Blob([blob], { type: "text/calendar" });

      handleDownloadBlob(formattedMimeTypeBlob, "appointment.ics");
    } catch (error) {
      errorReporting("Failed dowloading appointment calendar ICS file", error, {
        appointment_id: details.id,
      });
    }
  };

  /*==========================
    EDIT MODALS
  ===========================*/
  const handleEditAppointment = () => {
    if (details.type === "video") {
      setModalToOpen("edit-video");
    } else {
      setModalToOpen("edit-in-person");
    }
  };

  return (
    <>
      <div className="appointments__actions">
        <Tooltip text="Add to Calendar" size="md">
          <CalendarIcon
            className="appointments__actions__icon"
            onClick={handleAddAppointmentToCalendar}
            data-testid="button:add-to-calendar"
          />
        </Tooltip>

        <PermissionCheckComponentWrapper permissions={["applicant_appointments_edit"]}>
          <Tooltip text="Edit Appointment" size="md">
            <EditIcon
              className="appointments__actions__icon"
              onClick={handleEditAppointment}
              data-testid="button:edit-appointment"
            />
          </Tooltip>
        </PermissionCheckComponentWrapper>

        <PermissionCheckComponentWrapper permissions={["applicant_appointments_delete"]}>
          <Tooltip text="Delete Appointment" size="md">
            <DeleteIcon
              className="appointments__actions__icon"
              onClick={() => setModalToOpen("delete")}
              data-testid="button:delete-appointment"
            />
          </Tooltip>
        </PermissionCheckComponentWrapper>
      </div>

      <AnimatePresence>
        {modalToOpen === "edit-in-person" && (
          <EditInPersonAppointment details={details} handleCloseModal={handleCloseModal} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {modalToOpen === "edit-video" && (
          <EditVideoMeetingAppointment details={details} handleCloseModal={handleCloseModal} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {modalToOpen === "delete" && (
          <DeleteAppointment details={details} handleCloseModal={handleCloseModal} />
        )}
      </AnimatePresence>
    </>
  );
};

export default AppointmentsActions;
