import Skeleton from "react-loading-skeleton";

const ArticlesNotificationsSkeleton = () => {
  return (
    <div
      className="articles-notifications__menu articles-notifications__menu--no-hover"
      data-testid="component:articles-notification-menu-skeleton"
    >
      <div className="articles-notifications__header">
        <Skeleton width={150} height={15} />

        <Skeleton width={100} height={10} />
      </div>

      {[...new Array(3)].map((_article, index: number) => (
        <div className="articles-notifications__item" key={`article-notification-${index}`}>
          <div className="articles-notifications__image">
            <Skeleton width={40} height={40} circle />
          </div>

          <div className="articles-notifications__content">
            <Skeleton width={200} height={15} className="mb--10" />

            <Skeleton width={170} height={8} />
            <Skeleton width={140} height={8} />
            <Skeleton width={160} height={8} />
            <Skeleton width={110} height={8} />
          </div>

          <span className="articles-notifications__timestamp">
            <Skeleton width={80} height={10} />
          </span>
        </div>
      ))}

      <div className="articles-notifications__footer">
        <Skeleton width={50} height={15} />
      </div>
    </div>
  );
};

export default ArticlesNotificationsSkeleton;
