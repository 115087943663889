// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";

// Interfaces
import { DropdownItem } from "../../../Dropdown/interfaces";
import { JobBoardsFormsProps } from "../interfaces";

// Dropdown datasets
import {
  SNAG_A_JOB_DROPDOWN_JOB_INDUSTRY,
  SNAG_A_JOB_DROPDOWN_EMPLOYMENT_TYPE,
} from "./dropdown-data/SnagAJobDropdowns";

const SnagAJobForm = ({ values, validateField, setFieldValue }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.snagajob.enabled) validateField("snagajob");
  }, [values.snagajob]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="snagajob.enabled"
        id="snagajob.enabled"
        label="Enable SnagAJob"
        checked={values.snagajob.enabled}
        labelModifierClass="input__label--sm"
      />

      {values.snagajob.enabled ? (
        <>
          <Field
            component={FormInput}
            name="snagajob.ad_title"
            id="snagajob.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormInput}
            name="snagajob.display_company"
            id="snagajob.display_company"
            label="Display Company Name"
            placeholder="Optionally set the display company name"
            modifierClass="input--default-label mb--20"
          />

          <Field
            component={FormDropdownSearchable}
            name="snagajob.industry"
            id="snagajob.industry"
            label="Job Industry (required)"
            items={SNAG_A_JOB_DROPDOWN_JOB_INDUSTRY}
            handleFieldUpdate={(jobIndustry: DropdownItem) => {
              setFieldValue("snagajob.industry", jobIndustry.value);
            }}
            size="full"
            placeholder="Select Job Industry"
            preselectedItemValue={values.snagajob.industry}
            modifierClass="dropdown--default-label mb--30"
            isRequired
          />

          <Field
            component={FormDropdownSearchable}
            name="snagajob.employment_type"
            id="snagajob.employment_type"
            label="Employment Type"
            items={SNAG_A_JOB_DROPDOWN_EMPLOYMENT_TYPE}
            handleFieldUpdate={(employmentType: DropdownItem) => {
              setFieldValue("snagajob.employment_type", employmentType.value);
            }}
            size="full"
            placeholder="Select an employment type (optional)"
            preselectedItemValue={values.snagajob.employment_type}
            modifierClass="dropdown--default-label mb--30"
          />
        </>
      ) : null}
    </>
  );
};

export default SnagAJobForm;
