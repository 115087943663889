import { format } from "date-fns";
import { ApplicantHistoryResponseFields } from "../../api/Applications/interfaces";

export type HistoryLogReturn = {
  timestamp: string;
  message: string;
};

/**
 * Construct a string out of the received timestamp value
 * in a `MM/dd/yyyy hh:mm` format that will result in the timestamp
 * being presented like this `01/01/2023 10:00 AM`
 **/
export function historyLogTimestamp(timestamp: number): string {
  return format(new Date(timestamp * 1000), "MM/dd/yyyy hh:mm a");
}

/** Utility function to standardize the formatting of the 'reason' strings received from the server */
export function historyLogReasonStandardize(reason: string): string {
  return reason.split("-").join(" ");
}

/** Construct a log message for the "Applied" action */
export function historyLogApplied(details: ApplicantHistoryResponseFields): HistoryLogReturn {
  return {
    timestamp: historyLogTimestamp(details.timestamp),
    message: "Application arrived",
  };
}

/** Construct a log message for all "Bucket Changed" actions */
export function historyLogBucketChange(details: ApplicantHistoryResponseFields): HistoryLogReturn {
  let message: string = `Application was moved to ${details.data?.bucket_name} bucket`;

  if (details.user) message += ` by ${details.user}`;
  if (details.reason && details.reason !== "manual-change") {
    message += ` (${historyLogReasonStandardize(details.reason)})`;
  }

  return {
    timestamp: historyLogTimestamp(details.timestamp),
    message: message,
  };
}

/** Construct a log message for the "Hire Date Removed" actions */
export function historyLogHireDateRemoved(
  details: ApplicantHistoryResponseFields,
): HistoryLogReturn {
  const message: string = `Hired date removed by ${details.user} (${historyLogReasonStandardize(
    details.reason!,
  )})`;

  return {
    timestamp: historyLogTimestamp(details.timestamp),
    message: message,
  };
}

/** Construct a log message for the "Hire Date Set" actions */
export function historyLogHireDateSet(details: ApplicantHistoryResponseFields): HistoryLogReturn {
  let message: string = `Hire date set to ${details.data?.hire_date} by ${details.user}`;

  if (details.reason && details.reason !== "manual-change") {
    message += ` (${historyLogReasonStandardize(details.reason)})`;
  }

  return {
    timestamp: historyLogTimestamp(details.timestamp),
    message: message,
  };
}
