import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/auth-context";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";
import fileDownloadHandler from "../fileDownloadHandler";
import fetchHandlerUpload from "../fetchHandlerUpload";

interface HRCompliantApplicationUpload {
  id: string;
  pdf: Blob;
}

interface HRCompliantApplicationFormInfo {
  company: string;
  is_compliant_form_uploaded: boolean;
}

/** Check if there's already existing HR Compliant Application Form uploaded for current company */
export function useHRCompliantApplicationCompanyFormGet() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["hr-compliant-form-company-get"],
    async () => {
      return (await fetchHandler("GET", `company/${companySlug}/hr-compliant-form`)) as {
        has_form: boolean;
      };
    },
    { enabled: !!companySlug },
  );
}

/** Upload HR Compliant Application Form to be used on company level */
export function useHRCompliantApplicationCompanyFormUpload() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (pdf: Blob) => {
      const formData = new FormData();
      formData.append("pdf", pdf);

      await fetchHandlerUpload("POST", `company/${companySlug}/upload-hr-compliant-form`, formData);
    },
    {
      onSuccess: () => toast.success("Custom Application Form successfully uploaded!"),
      onError: error => error,
    },
  );
}

/** Sends an email request to the applicant for submitting the HR compliant form */
export function useHRCompliantApplicationRequest() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (applicationId: string) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${applicationId}/compliant-form/request`,
      );
    },
    {
      onSuccess: () => toast.success("Successfully sent request for Custom Application"),
      onError: error => error,
    },
  );
}

/** Download the uploaded HR Compliant application */
export function useHRCompliantApplicationDownload() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(async (applicationId: string) => {
    return await fileDownloadHandler(
      `company/${companySlug}/applications/${applicationId}/compliant-form/download`,
    );
  });
}

/** Upload the HR Compliant application after user has filled it out */
export function useHRCompliantApplicationUserUpload() {
  return useMutation(async (application: HRCompliantApplicationUpload) => {
    const formData = new FormData();
    formData.append("pdf", application.pdf);

    await fetchHandlerUpload("POST", `hr-compliant-form/${application.id}/upload`, formData);
  });
}

/**
 *
 * Get the info about the HR Compliant Application form page
 *
 * @param encryptedId The ID of the HR Compliant Application
 *
 **/
export function useHRCompliantApplicationGetInfo(encryptedId: string | undefined) {
  return useQuery(
    ["hr-comlpiant-form-info", encryptedId],
    async () => {
      return (await fetchHandler(
        "GET",
        `hr-compliant-form/${encryptedId}/info`,
      )) as HRCompliantApplicationFormInfo;
    },
    { enabled: !!encryptedId },
  );
}

/** Download the non-filled out HR Compliant Application form */
export function useHRCompliantApplicationDownloadForm() {
  return useMutation(
    async (encryptedId: string) => {
      return await fileDownloadHandler(`hr-compliant-form/${encryptedId}/download`);
    },
    {
      onError: error => error,
    },
  );
}
