// Utilities
import { format } from "date-fns";

// Components
import ChatActions from "./ChatActions";
import ChatAvatar from "./ChatAvatar";

// Interfaces
import { ChatMessageListItemProps } from "./interfaces";

const ChatMessageListItem = ({
  id,
  photo,
  full_name,
  last_message,
  timestamp,
  modifierClass = "",
  is_read,
  is_blocked,
  hasActionsMenu,
  handleChatMessageListItem,
}: ChatMessageListItemProps) => {
  return (
    <div
      className={`chat-message-list-item ${
        !is_read ? "chat-message-list-item--unread" : ""
      } ${modifierClass}`}
      onClick={() => handleChatMessageListItem(id)}
    >
      <ChatAvatar
        name={full_name}
        image_url={photo}
        size="md"
        status={is_blocked === 0 ? "active" : "blocked"}
      />

      <div className="chat-message-list-item__details">
        <div className="chat-message-list-item__header">
          <h5 className="chat-message-list-item__title">
            <strong>{full_name}</strong>
          </h5>

          {hasActionsMenu && <ChatActions id={id} is_blocked={is_blocked} />}
        </div>
        <p className="chat-message-list-item__last-message">{last_message}</p>
        <p className="chat-message-list-item__date">
          {format(new Date(timestamp), "HH:mmaaa | MMM dd yyyy")}
        </p>
      </div>
    </div>
  );
};

export default ChatMessageListItem;
