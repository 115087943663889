/**
 * Utility function for handling the error messages in the try/catch blocks
 * @param error The thrown error that was caught in the "catch" block
 * @returns Either the error's message (if it exists; for regular errors)
 * or the error itself as a string (for irregular errors)
 */
export default function handleErrorMessage(error: unknown): string {
  const typecastedError = error as Error;
  return typecastedError?.message ? typecastedError.message : String(error);
}
