// Utilities & Hooks
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import fetchHandler from "../fetchHandler";

// Interfaces
import { CompaniesResponseFields } from "./interfaces";

/**
 *
 * Get list of all companies to which the
 * logged in user belogns to
 *
 */
export function useCompaniesGetAvailable() {
  const navigate = useNavigate();

  return useQuery(
    ["companies"],
    async () => {
      return (await fetchHandler("GET", "company")) as CompaniesResponseFields[];
    },
    {
      onSuccess: data => {
        // If there are no companies received from the API
        // redirect the user to the 'Profile Settings' page
        if (data && !data.length) navigate("/account/profile/");
      },
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 *
 * Get the list of positions that are available
 * within the company that we're currently viewing
 *
 */
export function useCompaniesGetAvailablePositions() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["companies-positions", companySlug],
    async () => {
      return (await fetchHandler("GET", `company/${companySlug}/positions`)) as string[];
    },
    { enabled: !!companySlug },
  );
}

/**
 *
 * Get the list of all positions for specific company
 *
 */
export function useCompaniesGetSpecificPositions(companySlug?: string) {
  return useQuery(
    ["companies-positions", companySlug],
    async () => {
      return (await fetchHandler("GET", `company/${companySlug}/positions`)) as string[];
    },
    { enabled: !!companySlug },
  );
}


/**
 * 
 * Get only the `currently` existing job positions for the selected company
 * 
 */
export function useCompaniesGetJobPositionsForCurrentCompany() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(["job-positions-current-company", companySlug], async() => {
    return await fetchHandler("GET", `company/${companySlug}/job-positions`) as string[]
  }, { enabled: !!companySlug })
}