import { API_BASE_URL } from "../config/config";
import { FetchHandlerMethods, FetchHandlerRequestBody, FetchHandlerURL } from "./types";
import { handleAuthenticationTokensRemovalFromStorage } from "./tokenRefresher";
import { handleFetchError } from "./errorHandling";
import { LocalStorageActions } from "../utilities/handleLocalStorage";

/**
 *
 * Utility function for handling all fetch calls in the application
 * @param method  The method that will be used in the API request
 * @param url The URL to which the API request will be sent
 * @param body The body (optional parameter) that will be sent in the API request
 *
 * @returns Processed data from the API response, or throws an error if something went wrong.
 *
 */
export default async function fetchHandler<T>(
  method: FetchHandlerMethods = "GET",
  url: FetchHandlerURL,
  body?: FetchHandlerRequestBody<T>,
) {
  // Reads the saved access token
  const accessToken = LocalStorageActions.getItem("accessToken");

  const response = await fetch(`${API_BASE_URL}/${url}`, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  // If the response status is 204, just exit the function
  if (response.status === 204) return;

  // If the response status is 401, logout the unauthenticated user
  if (response.status === 401) {
    // Remove tokens and expiration time from local storage
    handleAuthenticationTokensRemovalFromStorage();

    // Redirect the user to the login page
    location.replace("/login/");

    return null;
  }

  // Parse the response that is returned by the API
  const data = await response.json();

  // In case there's an error, display the message received
  // in the API response, or fallback to a standardized error message
  if (!response.ok) handleFetchError(response, data, { body });

  // If everything is OK, return the received data
  return data;
}
