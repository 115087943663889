// Utilities & Hooks
import { useEffect } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { InvitationCompleteRegistrationPayloadFields } from "../../api/Invitation/interfaces";
import {
  useInvitationCompleteRegistration,
  useInvitationGetInfo,
} from "../../api/Invitation/Invitation";
import { useAuthenticationLogout } from "../../api/Authentication/Authentication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Schemas
import { AUTHENTICATION_REGISTER_SCHEMA } from "../../schemas/AuthenticationSchemas";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/Form/FormInput";
import Loader from "../../components/Loader/Loader";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";

const UserInvitationRegister = () => {
  const navigate = useNavigate();
  const { invitationID }: Readonly<Params<string>> = useParams();
  const errorReporting = useErrorReporting();

  /*==============================
    INVITATION INFO
  ===============================*/
  const { data, isLoading, error } = useInvitationGetInfo(invitationID);

  // Redirect the user to the login page
  useEffect(() => {
    if (error) navigate("/403/");
  }, [error]);

  /*==============================
    COMPLETE REGISTRATION
  ===============================*/
  const authenticationLogout = useAuthenticationLogout();
  const completeRegistration = useInvitationCompleteRegistration();

  const handleCompleteRegistration = async (registrationDetails: FormikValues) => {
    // Prevent request from being sent if there's no valid "invitationID" route parameter
    if (!invitationID) return;

    try {
      const PAYLOAD: InvitationCompleteRegistrationPayloadFields = {
        invitationID,
        first_name: registrationDetails.first_name,
        last_name: registrationDetails.last_name,
        password: registrationDetails.password,
        password_confirmation: registrationDetails.password_confirmation,
      };

      await completeRegistration.mutateAsync(PAYLOAD);

      // Logout the user in case they were already logged in
      await authenticationLogout.mutateAsync();

      // Redirect the user to the login page
      setTimeout(() => {
        navigate("/login/", { replace: true });
      }, 3000);
    } catch (error) {
      errorReporting(
        "Failed completing user registration",
        error,
        {
          invitationID,
          ...registrationDetails,
        },
        "critical",
      );
    }
  };

  return (
    <>
      <div className="unauthenticated row no-gutters">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center unauthenticated__cta">
          <div className="container">
            <h1 className="fw--semibold">We do all the work so you can focus on yours.</h1>
          </div>
        </div>
        <div
          className="col-12 col-md-6 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="unauthenticated__form">
            <img
              src={FCHLogo}
              className="d-block mx--auto my--60"
              title="FirstChoice Hiring logo"
            />

            {isLoading ? (
              <div className="d-flex justify-content-center my--100">
                <Loader size="lg" />
              </div>
            ) : completeRegistration.isSuccess ? (
              <>
                <h1 className="txt--center mb--20">Registration completed!</h1>
                <p className="txt--center">You will be redirected to the login page shortly...</p>
              </>
            ) : (
              <>
                <h1 className="fw--semibold txt--center mb--20">Welcome Aboard!</h1>
                <p className="txt--center">
                  Please fill out the details below to finalize your account setup and log into your
                  dashboard.
                </p>

                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={AUTHENTICATION_REGISTER_SCHEMA}
                  onSubmit={handleCompleteRegistration}
                >
                  <Form>
                    <div className="input mb--15">
                      <label className="input__label">Email</label>

                      <div className="input__field input__field--disabled--blue-tint">
                        {data?.email ?? "N/A"}
                      </div>
                    </div>

                    <Field
                      name="first_name"
                      id="first_name"
                      label="First Name"
                      isRequired={true}
                      placeholder="John"
                      component={FormInput}
                      modifierClass="mb--15"
                      autoComplete="new-password"
                    />

                    <Field
                      name="last_name"
                      id="last_name"
                      label="Last Name"
                      isRequired={true}
                      placeholder="Doe"
                      component={FormInput}
                      modifierClass="mb--15"
                      autoComplete="new-password"
                    />

                    <Field
                      name="password"
                      id="password"
                      label="Password"
                      type="password"
                      isRequired={true}
                      component={FormInput}
                      modifierClass="mb--15"
                      autoComplete="new-password"
                    />

                    <Field
                      name="password_confirmation"
                      id="password_confirmation"
                      label="Confirm Password"
                      type="password"
                      isRequired={true}
                      component={FormInput}
                      modifierClass="mb--15"
                      autoComplete="new-password"
                    />

                    <Button
                      modifierClass="btn--xl btn--primary--light"
                      isLoading={completeRegistration.isLoading}
                      isDisabled={completeRegistration.isLoading}
                    >
                      Submit and Login
                    </Button>
                  </Form>
                </Formik>
              </>
            )}

            <div className="unauthenticated__form__contact">
              <p className="fw--semibold">Need Technical Support?</p>
              <a href="tel:+18774497595">Call us at (877) 449-7595</a>
            </div>

            <div className="unauthenticated__form__contact">
              <p className="fw--semibold">Need Sales Assistance?</p>
              <a href="tel:+18889906451">Call us at (888) 990-6451</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInvitationRegister;
