import { format } from "date-fns";
import { ProductTourIds } from "../../../components/ProductTour/interfaces";
import { LocalStorageActions } from "../../../utilities/handleLocalStorage";
import { UsersResponseFields } from "../../../api/Users/interfaces";

const TOURS_FAKE_DATA: Partial<Record<ProductTourIds, Record<string, unknown>>> = {
  "applicants-ai-summary": {
    listEntry: {
      id: 0,
      buckets: ["Current"],
      registration_date: `${format(new Date(), "yyyy-MM-dd")}T10:00:00.000000Z`,
      sbca: 4.69,
      sbca_report_identifier: "example",
      sbca_request_date: `${format(new Date(), "yyyy-MM-dd")}T10:00:00.000000Z`,
      appointments_count: 0,
      comments_count: 0,
      comments_avg_rating: null,
      applicant_id: 0,
      name: "John Doe",
      photo: "",
      hired_date: null,
      position: "Body Shop Painter",
      location: "New York City, NY ",
      phone: "",
      text_message_consent: false,
      resume: "",
      resume_request_date: "",
      linkedin: "",
      email: "john@doe.com",
      schedule_meeting: null,
      idOfNextApplicationWithResume: null,
      idOfPreviousApplicationWithResume: null,
      meeting_link: "",
      source: "Other",
      is_hidden: false,
      has_ai_sbca_summary: true,
      has_ai_resume_summary: true,
      sorting_date: format(new Date(), "yyyy-MM-dd"),
    },
  },
};

/**
 *
 * Utility function that checks if a tour is being shown to the user
 * and based on that tries to find a set of fake data to be used for that tour.
 * If no set of fake data was defined for the targeted tour, nothing is returned.
 *
 * @param user The currently logged in user details
 * @param tourName The ID of the targeted tour for which we want to use some fake dataset.
 *
 * @returns The pre-defined fake dataset for the targeted tour or `undefined`.
 *
 */
function handleToursFakeData(user: UsersResponseFields, tourName: ProductTourIds) {
  const { unwatched_product_tours } = user;

  if (
    unwatched_product_tours.find(tour => tour.name === tourName) ||
    LocalStorageActions.getItem("activeProductTour") === tourName
  ) {
    return TOURS_FAKE_DATA[tourName];
  }

  return undefined;
}

export default handleToursFakeData;
