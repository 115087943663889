// Utilities
import { getIn } from "formik";

// Components
import DropdownSearchable from "../Dropdown/DropdownSearchable";

// Interfaces
import { FormDropdownSearchableProps } from "./interfaces";
import { DropdownItem } from "../Dropdown/interfaces";

const FormDropdownSearchable = ({
  field,
  form,
  handleFieldUpdate,
  ...props
}: FormDropdownSearchableProps) => {
  /*===========================
    HANDLE ERRORS
  ============================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===========================
    APPEND MODIFIER CLASSES
  ============================*/
  const MODIFIER_CLASS: string = `${props.modifierClass} ${
    errors && touched ? "dropdown--error" : ""
  }`;

  /*===========================
    UPDATE FORMIK STATE
  
    Updates the targeted field with the selected value.
    If the latest clicked item's value was the same
    with the already selected item value, then clear out the selection
  ============================*/
  const handleSelection = (item: DropdownItem) => handleFieldUpdate(item);
  return (
    <>
      <DropdownSearchable
        {...props}
        modifierClass={MODIFIER_CLASS}
        handleItemSelected={handleSelection}
      />
      {errors && touched ? <p className="input__error">{errors}</p> : null}
    </>
  );
};

export default FormDropdownSearchable;
