import { useRollbar } from "@rollbar/react";
import { toast } from "react-toastify";

// Utilities
import handleErrorMessage from "../utilities/handleErrorMessage";
import handleExtractFileExtension from "../utilities/strings/handleExtractFileExtension";
import { FetchErrorType } from "../api/errorHandling";

type RollbarLogLevel = "critical" | "error" | "warn" | "info";
type RollbarPayloadDetails = Record<string, unknown>;

/**
 *
 * Hook for handling sending specific errors from
 * `catch` blocks to Rollbar for better organization.
 */
export default function useErrorReporting() {
  const rollbar = useRollbar();

  const handleRollbarError = (
    message: string,
    error: unknown | Error,
    extraDetails: RollbarPayloadDetails = {},
    level: RollbarLogLevel = "error",
  ) => {
    // Typecast the received custom error object
    const errorTypecasted: FetchErrorType = error as FetchErrorType;

    // Extract only the 'message' of the received error object
    const extractedErrorMessage: string = handleErrorMessage(errorTypecasted.message);

    // Display toast notification in the UI
    toast.error(extractedErrorMessage);

    // Map the received extra details to be included in the rollbar log
    // so we can handle including extra information for fields that represent file uploads
    const mappedExtraDetailsObject: RollbarPayloadDetails = {};
    Object.entries(extraDetails).forEach(property => {
      const [key, value] = property;

      // If the extra details field is a file or a blob, include extra information to be sent to rollbar
      if (value instanceof File) {
        mappedExtraDetailsObject[key] = {
          file_size: value.size,
          file_name: value.name,
          file_extension: handleExtractFileExtension(value.name),
        };
      } else {
        mappedExtraDetailsObject[key] = value;
      }
    });

    // Include erroneous response details
    mappedExtraDetailsObject["response_error"] = { ...errorTypecasted };

    // Report issue to Rollbar
    rollbar[level](`${message} - ${extractedErrorMessage}`, mappedExtraDetailsObject);
  };

  return handleRollbarError;
}
