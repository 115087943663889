import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

// SCHEMA REGEX
import { YOUTUBE_URL_REGEX_PATTERN } from "./regexes";

const MAX_CHARS_AMOUNT_INPUT_FIELD: number = 255;
const MAX_CHARS_AMOUNT_TEXTAREA_FIELD: number = 1024;

export const CAREER_PAGES_FORM_NOT_ENABLED = Yup.object().shape({
  about_us_feature_image: Yup.string().nullable().notRequired(),
  careers_feature_image: Yup.string().nullable().notRequired(),
  team_image: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "team-image",
      'Only one of "Team Photo" or "Embed Video" can be uploaded.',
      function (team_image) {
        const { embed_video } = this.parent;

        // If there's a value for both the team image, and the embed video
        // then throw an error and prevent form submission.
        // Both can't be uploaded at the same time.
        if (embed_video && team_image) return false;

        return true;
      },
    ),
  sub_header: Yup.string()
    .notRequired()
    .max(
      MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
      `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
    )
    .test("career-pages-subheader", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  about_us: Yup.string()
    .notRequired()
    .max(
      MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
      `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
    )
    .test("career-pages-aboutus", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  embed_video: Yup.string()
    .matches(YOUTUBE_URL_REGEX_PATTERN, {
      message: "Only valid YouTube URLs are allowed.",
    })
    .notRequired()
    .test(
      "embed-video",
      'Only one of "Embed Video" or "Team Photo" can be uploaded.',
      function (embed_video) {
        const { team_image } = this.parent;

        // If there's a value for both the team image, and the embed video
        // then throw an error and prevent form submission.
        // Both can't be uploaded at the same time.
        if (team_image && embed_video) return false;

        return true;
      },
    )
    .test("career-pages-embed-video", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  highlights: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .notRequired()
          .nullable()
          .max(
            MAX_CHARS_AMOUNT_INPUT_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
          )
          .test("career-pages-higlight-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
        data: Yup.string()
          .notRequired()
          .nullable()
          .max(
            MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
          )
          .test("career-pages-highlight-text", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      }),
    )
    .min(0)
    .max(6, "Maximum of 6 highlights can be included."),
  benefits: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.string()
          .notRequired()
          .max(
            MAX_CHARS_AMOUNT_INPUT_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
          )
          .test("career-pages-benefit", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      }),
    )
    .min(0)
    .max(9, "Maximum of 9 benefits can be included."),
  featured: Yup.array().of(
    Yup.object().shape(
      {
        image: Yup.string().when(["headline_1", "headline_2", "body"], {
          is: (headline_1: string, headline_2: string, body: string) => {
            return headline_1 || headline_2 || body;
          },
          then: schema =>
            schema.nullable().required("Please include an image for the featured highlight"),
          otherwise: schema => schema.nullable().notRequired(),
        }),
        headline_1: Yup.string()
          .when(["image", "headline_2", "body"], {
            is: (image: string | Blob, headline_2: string, body: string) => {
              return image || headline_2 || body;
            },
            then: schema =>
              schema
                .required("Please include the first headline for the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_INPUT_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-headline1", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
        headline_2: Yup.string()
          .when(["image", "headline_1", "body"], {
            is: (image: string | Blob, headline_1: string, body: string) => {
              return image || headline_1 || body;
            },
            then: schema =>
              schema
                .required("Please include the second headline for the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_INPUT_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-headline2", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
        body: Yup.string()
          .when(["image", "headline_1", "headline_2"], {
            is: (image: string | Blob, headline_1: string, headline_2: string) => {
              return image || headline_1 || headline_2;
            },
            then: schema =>
              schema
                .required("Please include the body of the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-body", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      },
      [
        ["image", "headline_1"],
        ["image", "headline_2"],
        ["image", "body"],
        ["headline_1", "image"],
        ["headline_1", "headline_2"],
        ["headline_1", "body"],
        ["headline_2", "image"],
        ["headline_2", "headline_1"],
        ["headline_2", "body"],
        ["body", "image"],
        ["body", "headline_1"],
        ["body", "headline_2"],
      ],
    ),
  ),
});

export const CAREER_PAGES_FORM_ENABLED = Yup.object().shape({
  meta: Yup.object().shape({
    team_image_url: Yup.string().nullable().notRequired(),
  }),
  about_us_feature_image: Yup.string().nullable().notRequired(),
  careers_feature_image: Yup.string().nullable().notRequired(),
  team_image: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "team-image",
      'Only one of "Team Photo" or "Embed Video" can be uploaded.',
      function (team_image) {
        const { embed_video } = this.parent;

        // If there's a value for both the team image, and the embed video
        // then throw an error and prevent form submission.
        // Both can't be uploaded at the same time.
        if (embed_video && team_image) return false;

        return true;
      },
    ),
  sub_header: Yup.string()
    .notRequired()
    .max(
      MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
      `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
    )
    .test("career-pages-subheader", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  about_us: Yup.string()
    .notRequired()
    .max(
      MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
      `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
    )
    .test("career-pages-aboutus", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  embed_video: Yup.string()
    .matches(YOUTUBE_URL_REGEX_PATTERN, {
      message: "Only valid YouTube URLs are allowed.",
    })
    .notRequired()
    .test(
      "embed-video",
      'Only one of "Embed Video" or "Team Photo" can be uploaded.',
      function (embed_video) {
        const { team_image } = this.parent;

        // If there's a value for both the team image, and the embed video
        // then throw an error and prevent form submission.
        // Both can't be uploaded at the same time.
        if (team_image && embed_video) return false;

        return true;
      },
    )
    .test("career-pages-embed", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  highlights: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .notRequired()
          .nullable()
          .max(
            MAX_CHARS_AMOUNT_INPUT_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
          )
          .test("career-pages-highlight-title", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
        data: Yup.string()
          .notRequired()
          .nullable()
          .max(
            MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
          )
          .test("career-pages-highlight-text", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      }),
    )
    .min(0)
    .max(6, "Maximum of 6 highlights can be included."),
  benefits: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.string()
          .notRequired()
          .max(
            MAX_CHARS_AMOUNT_INPUT_FIELD,
            `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
          )
          .test("career-pages-benefit", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      }),
    )
    .min(0)
    .max(9, "Maximum of 9 benefits can be included."),
  featured: Yup.array().of(
    Yup.object().shape(
      {
        image: Yup.string().when(["image_initial_value", "headline_1", "headline_2", "body"], {
          is: (
            image_initial_value: string | null,
            headline_1: string,
            headline_2: string,
            body: string,
          ) => {
            return !image_initial_value && (headline_1 || headline_2 || body);
          },
          then: schema =>
            schema.nullable().required("Please include an image for the featured highlight"),
          otherwise: schema => schema.nullable().notRequired(),
        }),

        image_initial_value: Yup.string().nullable().notRequired(),

        headline_1: Yup.string()
          .when(["image", "image_initial_value", "headline_2", "body"], {
            is: (
              image: string | File,
              image_initial_value: string | null,
              headline_2: string,
              body: string,
            ) => {
              return image || image_initial_value || headline_2 || body;
            },
            then: schema =>
              schema
                .required("Please include the first headline for the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_INPUT_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-headline1", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),

        headline_2: Yup.string()
          .when(["image", "image_initial_value", "headline_1", "body"], {
            is: (
              image: string | File,
              image_initial_value: string | null,
              headline_1: string,
              body: string,
            ) => {
              return image || image_initial_value || headline_1 || body;
            },
            then: schema =>
              schema
                .required("Please include the second headline for the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_INPUT_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_INPUT_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-headline2", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),

        body: Yup.string()
          .when(["image", "image_initial_value", "headline_1", "headline_2"], {
            is: (
              image: string | File,
              image_initial_value: string | null,
              headline_1: string,
              headline_2: string,
            ) => {
              return image || image_initial_value || headline_1 || headline_2;
            },
            then: schema =>
              schema
                .required("Please include the body of the featured highlight")
                .max(
                  MAX_CHARS_AMOUNT_TEXTAREA_FIELD,
                  `Maximum of ${MAX_CHARS_AMOUNT_TEXTAREA_FIELD} characters allowed!`,
                ),
            otherwise: schema => schema.notRequired(),
          })
          .test("career-pages-fhighlight-body", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
            return !handleCheckStringForHTML(value as string);
          }),
      },
      [
        ["image", "headline_1"],
        ["image", "headline_2"],
        ["image", "body"],
        ["headline_1", "image"],
        ["headline_1", "image_initial_value"],
        ["headline_1", "headline_2"],
        ["headline_1", "body"],
        ["headline_2", "image"],
        ["headline_2", "image_initial_value"],
        ["headline_2", "headline_1"],
        ["headline_2", "body"],
        ["body", "image"],
        ["body", "image_initial_value"],
        ["body", "headline_1"],
        ["body", "headline_2"],
      ],
    ),
  ),
});
