// Hooks
import { useEffect, useState } from "react";

// Components
import ProgressCircle from "../Progress/ProgressCircle";

// Interfaces
import { ApplicationSBCAResultProps } from "./interfaces";
import { SbcaScoreTraitRanking, SbcaScoreType } from "../../api/SBCA/interfaces";

// Static
import { APPLICATION_SBCA_RANGES } from "./statics";

// Statics
const SBCA_MAIN_TRAITS: Record<SbcaScoreType, "Emotions" | "Ego" | "Logic"> = {
  caregiver: "Emotions",
  thinker: "Ego",
  processor: "Logic",
};

const ApplicationSBCAResult = ({ type, details }: ApplicationSBCAResultProps) => {
  /*=============================
    TRAIT RANKING FOR SPECIFIED
    TYPE OF SBCA RESULT
  ==============================*/
  const [targetedTraitRanking, setTargetedTraitRanking] = useState<SbcaScoreTraitRanking | "">("");

  useEffect(() => {
    // Exit function if there's no type or SBCA details object provided
    if (!type || !details || !Object.entries(details)) return;

    switch (type) {
      case "caregiver":
        setTargetedTraitRanking(details.caregiver.emotions);
        break;
      case "thinker":
        setTargetedTraitRanking(details.thinker.ego);
        break;
      case "processor":
        setTargetedTraitRanking(details.processor.logic);
        break;
    }
  }, [type, details]);

  return (
    <>
      <div className={`applications-sbca-result applications-sbca-result--${details[type].color}`}>
        <ProgressCircle
          size={80}
          type={type}
          percentage={details[type].score}
          color={details[type].color}
        />

        <div className="ml--20">
          <h5 className="applications-sbca-result__title">{type}</h5>
          <p className="txt--xxs mb--10">
            <i>{APPLICATION_SBCA_RANGES[type]}</i>
          </p>

          <p className="applications-sbca-result__score">{details[type].score}%</p>

          <p className="txt--black txt--xs mb--5">
            <span className="fw--semibold">{SBCA_MAIN_TRAITS[type]}: </span>
            <span>{targetedTraitRanking}</span>
          </p>
          <p className="applications-sbca-result__characteristics">
            <span className="fw--semibold">Characteristics: </span> {details[type].characteristics}
          </p>

          {type === "thinker" && (
            <>
              <p className="applications-sbca-result__driven">
                <strong>Driven: </strong>
                <span className="applications-sbca-result__driven__score">
                  {details.thinker.driven}
                </span>
              </p>
              <p className="txt--xxs mb--10">
                <i>Range: 3.00-5.00</i>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicationSBCAResult;
