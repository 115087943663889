// Constants
import { FORM_FIELD_COMPONENT_MAPPINGS } from "./constants";

// Interfaces
import {
  OnlineApplicationDynamicFieldsValuesEnum,
  OnlineApplicationSupportedDynamicFields,
  OnlineApplicationSupportedDynamicFieldsValues,
} from "../interfaces";

/**
 *
 * Extract and format the `name` of the form's entry,
 * whether a `section` or `field` within that section.
 *
 * @param name The `name` of the form entry that we're targeting at the moment.
 * @param splitter The string value by which we want to split the original received string. Defaults to " "
 * @param join The string value by which we want to join the string that we just split. Defaults to "_"
 *
 * @returns Formatted and lowercased name of the form entry that
 * can be then used to target the correct form sections & fields,
 * or to be displayed in the UI in the correct format.
 *
 */
export function handleFormEntryName(
  name: string,
  splitter: string = " ",
  join: string = "_",
): string {
  return name.toLowerCase().split(splitter).join(join);
}

/**
 *
 * Map the received field to a specific component
 *
 * @param fieldType The type of the field that we're working with.
 *
 * @returns Component that corresponds to the received field type,
 * to be used in the dynamically genereated Form
 *
 */
export function handleFormFieldComponentMapping(fieldType: string) {
  return FORM_FIELD_COMPONENT_MAPPINGS[fieldType];
}

/**
 *
 * Based on the field type return the default value for that field,
 * based on its type. For example:
 * - a `text` type of field should have an `" "` as a default value
 * - a `number` type of field should have `0` as a default value
 * - a `checkbox` type of field should have `false` as a default value
 * - a `file` type of field should have `null` as a default
 *
 * @param fieldType The type of the field that we're working with.
 *
 * @returns Default value for the field
 *
 */
export function handleFormFieldDefaultValueBasedOnType(
  fieldType: OnlineApplicationSupportedDynamicFields,
) {
  // If there's no valid field type value received, exit function
  if (!fieldType) return;

  const valueType: OnlineApplicationSupportedDynamicFieldsValues =
    OnlineApplicationDynamicFieldsValuesEnum[fieldType];

  switch (valueType) {
    case "string":
      return "";
    case "number":
      return 0;
    case "boolean":
      return false;
    case "file":
      return null;
  }
}

/**
 *
 * Find the dynamically created form field in the
 * originally received response from the API, based on matching field name,
 * in the specified section of the form.
 *
 * This is used by the sections of the forms that are of array-type,
 * representing the sections that can have dynamic sizing.
 *
 * @param fieldName The name of the field that we're looking for
 * @param sectionFields The current section's fields, as returned from the API
 *
 * @returns The matching field in the targeted section
 *
 */

export function handleFormSectionFindMatchingField(fieldName: string, sectionFields: any) {
  const matchingField = sectionFields.find((sectionField: any) => {
    return sectionField.name.toLowerCase() === fieldName.toLowerCase();
  });

  return matchingField;
}

/**
 *
 * Based on the received field type we should limit the
 * maximum number of characters allowed for `text` and
 * `textarea` fields.
 *
 * This will eventually be updated to read the max character limit value
 * as it is received from the API response.
 *
 * We default to 255 characters limit for regular text fields.
 * We default to 5000 charachters limit for textarea fields.
 *
 * @param fieldType The type of the field as received from the API response.
 *
 * @returns The maximum number of allowed characters based on type of input field
 *
 */
export function handleFormFieldsMaximumCharactersLimit(
  fieldType: OnlineApplicationSupportedDynamicFields,
): 5000 | 255 {
  return fieldType === "textarea" ? 5000 : 255;
}
