// Components
import ChatConversationMessage from "./ChatConversationMessage";

// Interfaces
import { ChatResponseFields } from "../../../api/Chat/interfaces";
import { ChatConversationMessageGroupProps } from "./interfaces";

const ChatConversationMessageGroup = ({
  groupDate,
  groupMessages,
  chatConversationDetails,
}: ChatConversationMessageGroupProps) => {
  return (
    <div className="chat-conversation__messages__group">
      <div className="chat-conversation__messages__group__date">
        <h6>{groupDate}</h6>
      </div>

      {groupMessages.map((message: ChatResponseFields) => (
        <ChatConversationMessage
          name={
            message.direction === "in"
              ? chatConversationDetails.chatting_with.full_name
              : chatConversationDetails.currentUserName
          }
          image_url={
            message.direction === "in" ? chatConversationDetails.chatting_with.photo : null
          }
          content={message.content}
          direction={message.direction}
          time={message.timestamp}
          is_blocked={chatConversationDetails.is_blocked}
          status={message.status}
        />
      ))}
    </div>
  );
};

export default ChatConversationMessageGroup;
