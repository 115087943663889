import { Link } from "react-router-dom";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Components
import { Field, Form, Formik, FormikHelpers } from "formik";
import ContentHeader from "../../../components/Content/ContentHeader";
import FormDropdown from "../../../components/Form/FormDropdown";
import Button from "../../../components/Button/Button";
import FormInputBubbles from "../../../components/Form/FormInputBubbles";

// Schemas
import { ACCOUNT_USERS_NEW } from "../../../schemas/AccountSchemas";

// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { useUsersAddToCompany, useUsersGetList } from "../../../api/Users/Users";
import { useGroupsGetAll } from "../../../api/Groups/Groups";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Interfaces
import { UsersAddFormikFields } from "../../../api/Users/interfaces";
import { DropdownItem } from "../../../components/Dropdown/interfaces";
import { GroupFields } from "../../../api/Groups/interfaces";
import { FormInputBubblesModel } from "../../../components/Form/interfaces";

const UsersNew = () => {
  const errorReporting = useErrorReporting();

  /*==============================
    OBTAIN THE LIST OF GROUPS
  ===============================*/
  const { data: groups, isLoading: groupsIsLoading } = useGroupsGetAll();

  const groupsDropdownItems: DropdownItem[] = useMemo(() => {
    if (!groups || !groups.length || groupsIsLoading) return [];

    const mappedGroups: DropdownItem[] = groups.map((group: GroupFields) => {
      return { text: group.name, value: group.id, description: group.description };
    });

    return mappedGroups;
  }, [groups]);

  /*=====================================
    GET ALL USERS FROM CURRENT COMPANY

    Used to cross-reference with the 
    current mailing list & display
    custom validation messages
    if an email is already in the company
    or the invatation is pending.
  ======================================*/
  const [companyUsers, setCompanyUsers] = useState<{ email: string; status: string }[]>([]);
  const { data: usersData, isLoading: usersDataLoading } = useUsersGetList();

  useEffect(() => {
    if (usersDataLoading || !usersData) return;

    setCompanyUsers(
      usersData.map(user => {
        return { email: user.email, status: user.status };
      }),
    );
  }, [usersData]);

  /*==============================
    ADD A NEW USER TO THE COMPANY
  ===============================*/
  const addUser = useUsersAddToCompany();

  const handleAddUserToCompany = async (
    userDetails: UsersAddFormikFields,
    helpers: FormikHelpers<any>,
  ) => {
    try {
      await addUser.mutateAsync({ email: userDetails.email, group_id: userDetails.group_id });

      // Reset the form to its initial values after successful submit
      setFinalEmails([]);
      helpers.resetForm();
    } catch (error) {
      errorReporting("Failed adding new user to current company", error, { ...userDetails });
    }
  };

  /*==============================
    EMAILS INPUT FIELD
  ===============================*/
  const [emailValue, setEmailValue] = useState("");
  const [finalEmails, setFinalEmails] = useState<FormInputBubblesModel[]>([]);

  return (
    <div className="container py--25">
      <ContentHeader title="Add User" />

      <Link to="/account/users/" className="d-inline-flex align-items-center txt--lg my--20">
        <BackIcon className="txt--grey" />
        <span className="txt--blue fw--regular">Back</span>
      </Link>

      <Formik
        initialValues={{
          email: "",
          group_id: null,
          company_users: companyUsers,
        }}
        enableReinitialize
        validationSchema={ACCOUNT_USERS_NEW}
        onSubmit={(values, helpers) => handleAddUserToCompany(values, helpers)}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values }) => (
          <Form
            style={{
              maxWidth: 700,
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Field
              component={FormInputBubbles}
              label="Email"
              id="email"
              name="email"
              description="The email addresses entered here will be used to deliver instructions to login to the system and create a password. To add multiple emails separate them using empty space, Enter, semicolon or comma characters."
              size="full"
              placeholder="example@email.com"
              bubbledValues={finalEmails}
              handleBubbledValues={setFinalEmails}
              value={emailValue}
              modifierClass="input-side-label mb--15"
              textInput={emailValue}
              handleTextInput={setEmailValue}
              delimiters={[";", ",", " ", "Space", "Enter"]}
              internalDelimiter=";"
            />

            <Field
              key={values.group_id}
              component={FormDropdown}
              id="group_id"
              name="group_id"
              title="Select a group"
              items={groupsDropdownItems}
              size="full"
              label="User Group"
              modifierClass="dropdown--side-label mb--15"
              framerAnimationCustomProps={{ hasSideLabel: true }}
              preselectedItemValue={values.group_id}
            />
            <div className="ml--150 ml--md--0">
              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary mt--20"
                isLoading={addUser.isLoading}
                isDisabled={addUser.isLoading}
              >
                Add User
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UsersNew;
