// Utilities & Hooks
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMarkFinishTour } from "../api/Tours/Tours";
import { useAuth } from "./auth-context";
import { useNavigate, useNavigationType } from "react-router-dom";
import { LocalStorageActions } from "../utilities/handleLocalStorage";
import useErrorReporting from "../hooks/useErrorReporting";

// Constants
import { PRODUCT_TOURS_DATA } from "../components/ProductTour/constants";

// Components
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";

// Interfaces
import { ProductTourDetails, ProductTourIds } from "../components/ProductTour/interfaces";
import {
  handleMergeSearchParameters,
  useExtractSearchParameters,
} from "../hooks/useExtractSearchParameters";

interface TourContextProps {
  activeTour: ProductTourDetails | null;
  isTourRunning: boolean;
  tourCurrentStep: number;
  tourCurrentStepName: string;
  handleTourStart: (tourId: ProductTourIds) => void;
  handlePromptTourEnd: () => void;
  handleTourEnd: () => void;
  handleTourNextStep: (transitionTimeout: number, callback?: () => void) => void;
  handleTourPreviousStep: (transitionTimeout: number, callback?: () => void) => void;
  handlePrepStep: (stepName: string, callback: () => void, timeout: number) => void;
  handleDynamicTour: (dynamicTour: ProductTourDetails) => void;
  handleClearActiveTour: () => void;
  handleResumeTour: () => void;
  handlePauseTour: () => void;
}

const TourContext = createContext<TourContextProps>({
  activeTour: null,
  isTourRunning: false,
  tourCurrentStep: 0,
  tourCurrentStepName: "",
  handleTourStart: () => undefined,
  handlePromptTourEnd: () => undefined,
  handleTourEnd: () => undefined,
  handleTourNextStep: () => undefined,
  handleTourPreviousStep: () => undefined,
  handlePrepStep: () => undefined,
  handleDynamicTour: () => undefined,
  handleClearActiveTour: () => undefined,
  handleResumeTour: () => undefined,
  handlePauseTour: () => undefined,
});

const TourContextWrapper = ({ children }: { children: React.ReactNode }) => {
  // for some reason we must include this
  // TODO: check if it causes issues with something else - very important
  window.global = globalThis;

  const { user, handleUpdateUserDetails } = useAuth();
  const [searchParametersObject] = useExtractSearchParameters();
  const navigationType = useNavigationType();
  const navigate = useNavigate();

  // Manual handling of the tours
  const [activeTour, setActiveTour] = useState<ProductTourDetails | null>(null);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [tourCurrentStep, setTourCurrentStep] = useState<number>(0);
  const [showCancelTourModal, setShowCancelTourModal] = useState(false);

  // Sync between step index & step name state
  const tourCurrentStepName = useMemo(() => {
    // find the name of the step from the current tour
    // corresponding to the the current step index
    const tourStepName: string = activeTour?.steps[tourCurrentStep]?.stepName || "";

    return tourStepName;
  }, [tourCurrentStep]);

  /**
   *
   * Trigger static product tours that use pre-defined steps with static data.
   *
   * @param tourId The ID of the static tour.
   */
  const handleTourStart = (tourId: ProductTourIds) => {
    const matchingTour: ProductTourDetails | undefined = PRODUCT_TOURS_DATA.find(
      tour => tour.tourId === tourId,
    );

    // Exit function if no match was found
    if (!matchingTour) return;

    // Play the tour and save it in local storage
    LocalStorageActions.saveItem("activeProductTour", matchingTour.tourId);
    setActiveTour(matchingTour);
    setIsTourRunning(true);
  };

  /**
   *
   * Trigger dynamic product tours that depend on some
   * data that is received from the API.
   *
   * @param dynamicTourData The data to be used for the dynamic tour
   */
  const handleDynamicTour = (dynamicTourData: ProductTourDetails) => {
    if (activeTour || !dynamicTourData) return;

    setActiveTour(dynamicTourData);
    setIsTourRunning(true);
  };

  // Execute prompt tour end
  const handlePromptTourEnd = () => setShowCancelTourModal(true);

  // Execute tour end
  const markFinishedTour = useMarkFinishTour();
  const errorReporting = useErrorReporting();

  const handleTourEnd = () => {
    if (!activeTour) return;
    setShowCancelTourModal(false);

    setIsTourRunning(false);
    setActiveTour(null);
    setTourCurrentStep(0);

    // Remove the active tour from local storage after closing it
    LocalStorageActions.removeItem("activeProductTour");

    // If there are no unwatched product tours, do not try to send
    // a request to the API when cancelling the current tour
    if (!user || !user.unwatched_product_tours.length) {
      handleRedirectAfterTourEnd(activeTour.tourId);
      return;
    }

    /*============================================
      Send request to the API to mark the tour 
      that is being closed as read.
    ============================================*/
    const tourIdToBeMarkedAsWatched = user.unwatched_product_tours.find(
      unwatchedTour => unwatchedTour.name === activeTour.tourId,
    )?.id;

    if (!tourIdToBeMarkedAsWatched) {
      handleRedirectAfterTourEnd(activeTour.tourId);
      return;
    }

    try {
      markFinishedTour.mutate(tourIdToBeMarkedAsWatched);

      // Manually update the user details objects to clear out the list of unwatched product tours
      // Note: This is so we can easily close the product tour window after ending the tour
      handleUpdateUserDetails({ unwatched_product_tours: [] });
      handleRedirectAfterTourEnd(activeTour.tourId);
    } catch (error) {
      errorReporting("Failed marking current tour as finished", error);
    }
  };

  // Where should the user be redirected after ending the tour

  const handleRedirectAfterTourEnd = (tourId: ProductTourIds) => {
    const tourNavigationParameters = handleMergeSearchParameters(searchParametersObject);

    // Todo: In case the number of tours increases,
    // change this to not use if / else (or switch) statements
    if (tourId === "applicants-ai-summary") navigate(`/applications/${tourNavigationParameters}`);
  };

  const handleTourNextStep = (transitionTimeout: number = 500, callback?: () => void) => {
    // Prevent any actions if there is no active tour or tour is paused
    if (!activeTour) return;

    // Check if current step is last step, if so dont update counter
    const isLastStepOfTour: boolean = activeTour.steps.length - 1 === tourCurrentStep;

    // In case the tour should be paused between steps (cases of element toggle or animations)
    if (transitionTimeout) {
      setIsTourRunning(false);

      if (callback) callback();
      setTimeout(() => {
        setIsTourRunning(true);

        if (!isLastStepOfTour) setTourCurrentStep(tourStep => tourStep + 1);
      }, transitionTimeout);
    } else {
      if (!isLastStepOfTour) setTourCurrentStep(tourStep => tourStep + 1);

      // if there's some callback to be triggered, do it now
      if (callback) callback();
    }
  };

  const handlePrepStep = (stepName: string, callback: () => void, timeout: number) => {
    // If the current step doesnt match the callee step name, exit
    if (tourCurrentStepName !== stepName) return;

    setIsTourRunning(false);
    setTimeout(() => {
      callback();
      setIsTourRunning(true);
    }, timeout);
  };

  const handleTourPreviousStep = (transitionTimeout: number = 500, callback?: () => void) => {
    // Prevent any actions if there is no active tour or tour is paused
    if (!activeTour || !isTourRunning) return;

    if (transitionTimeout) {
      setIsTourRunning(false);

      if (callback) callback();
      setTimeout(() => {
        setIsTourRunning(true);

        setTourCurrentStep(tourStep => tourStep - 1);
      }, transitionTimeout);
    } else {
      setTourCurrentStep(tourStep => tourStep - 1);

      // if there's some callback to be triggered, do it now
      if (callback) callback();
    }
  };

  /** Clear out the currently active tour by reseting all related states */
  const handleClearActiveTour = () => {
    setTourCurrentStep(0);
    setIsTourRunning(false);
    setActiveTour(null);

    if (tourCurrentStep === 0) LocalStorageActions.removeItem("activeProductTour");
  };

  /**
   * When the user navigates back in the browser history
   * by using the browser "Back" button, clear out (reset) the currently active tour
   **/
  useEffect(() => {
    if (!isTourRunning || navigationType !== "POP") return;
    handleClearActiveTour();
  }, [navigationType]);

  // Disable user induced page scrolling in case the tour is running
  // Note: This does not prevent the tour handling context/functionality from scrolling to steps
  useEffect(() => {
    if (isTourRunning) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }

    return () => document.body.classList.remove("prevent-scroll");
  }, [isTourRunning]);

  /*=====================================
    CONTROL CURRENTLY ACTIVE TOUR

    This allows us to have more control in
    case we want to wait for animation or some user action
    before continuing the tour.
  ======================================*/
  const handleResumeTour = () => {
    if (!activeTour) return;
    setIsTourRunning(true);
  };

  const handlePauseTour = () => {
    if (!activeTour) return;
    setIsTourRunning(false);
  };

  return (
    <TourContext.Provider
      value={{
        activeTour,
        isTourRunning,
        tourCurrentStep,
        tourCurrentStepName,
        handleTourStart,
        handlePromptTourEnd,
        handleTourEnd,
        handleTourNextStep,
        handleTourPreviousStep,
        handlePrepStep,
        handleDynamicTour,
        handleClearActiveTour,
        handleResumeTour,
        handlePauseTour,
      }}
    >
      {children}
      {showCancelTourModal ? (
        <Modal
          title="End Tour?"
          text={
            <>
              Are you sure you want to end the Tour? You can always start the tour from the{" "}
              <strong>Resources</strong> page in your account menu.
            </>
          }
          modifierClass="modal--md modal--stack-tours"
          overlayModifierClass="modal--stack-tours"
          handleCloseModal={() => setShowCancelTourModal(false)}
        >
          <div className="modal__actions">
            <Button
              modifierClass="btn--fluid btn--primary"
              onClick={() => setShowCancelTourModal(false)}
            >
              Cancel
            </Button>

            <Button
              modifierClass="txt--capitalize btn--fluid btn--secondary"
              onClick={() => handleTourEnd()}
            >
              Yes, End Tour
            </Button>
          </div>
        </Modal>
      ) : null}
    </TourContext.Provider>
  );
};

const useTour = () => useContext(TourContext);

export { TourContext, TourContextWrapper, useTour };
