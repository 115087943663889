// Dashboard View mode types
export type DashboardViewMode = "grid" | "classic";

// Make specific field(s) optional from a defined interface.
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// Make a generic object, consisted of fields and values defined based on the received type
export type PartialGenericObject<T> = Partial<Record<keyof T, T[keyof T]>>;
export type GenericObject<T> = Record<keyof T, T[keyof T]>;

/* 
  The expected type for an image uploading field value.
  
  The possible values - "Blob" | "string" | "null" - are applied
  because of the following reasons:
   1. The value that we get back from the API will always be either 
      a "string" representing the URL to the image where it's uploaded,
      or "null" value if there's no uploaded image yet.
   2. The remaining option of "Blob" type is added to satisfy the
      field when it's being included in FormData to be sent in the API request 
*/
export type ImageUploadType = Blob | string | null;

// Type used for typecasting the 'location.state' field, to
// be able to read a custom 'redirectTo' state
export type RouterLocationStateRedirectTo = Record<"redirectTo", string> | null;

// Supported countries
export type CountryOption = "US" | "CA";
export interface CountryAddressLabelOptions {
  city: string;
  state: string;
  code: string;
}

// These are pre-defined system-wide user roles that are not changing
export enum UserRoleIDsEnum {
  SUPER_ADMIN = 1,
  ADMIN = 2, // Corresponds to Account Manager
}

// These are pre-defined system-wide user role names
export enum UserRoleNames {
  SUPER_ADMIN = "Super Admin",
  ACCOUNT_MANAGER = "Account Manager", // Also referred to as "Admin"
  COMPANY_USER = "Company User",
}

// Locales to be used for i18n troughout the application
export type InternationalizationLocales = "en" | "es";
