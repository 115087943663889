// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Components
import { Field, Form, Formik } from "formik";
import ContentHeader from "../../../components/Content/ContentHeader";
import Button from "../../../components/Button/Button";
import FormDropdownSearchable from "../../../components/Form/FormDropdownSearchable";
import FormInputSideLabel from "../../../components/Form/FormInputSideLabel";
import FormPhoneInput from "../../../components/Form/FormPhoneInput";

// Utilities & Hooks
import { Link, useNavigate } from "react-router-dom";
import { AdminClientsCreateRequestFields } from "../../../api/Clients/interfaces";
import { useAdminClientCreate } from "../../../api/Clients/AdminClients";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Schemas
import { ADMIN_CLIENTS_NEW_SCHEMA } from "../../../schemas/ClientsSchemas";

// Statics
import { CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN, CLIENTS_DISABLE_SBCA_DROPDOWN } from "./statics";
import {
  COUNTRY_BASED_ADDRESS_LABELS,
  COUNTRY_DROPDOWN,
  STATES_DROPDOWN,
} from "../../../constants/countries";

// Interfaces
import { DropdownItem } from "../../../components/Dropdown/interfaces";
import { CountryOption } from "../../../interfaces/global";

const AdminClientsNew = () => {
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*===============================
    CREATE A NEW USER
  ================================*/
  const createClient = useAdminClientCreate();
  const handleCreateNewClient = async (details: AdminClientsCreateRequestFields) => {
    try {
      await createClient.mutateAsync(details);

      // Redirect back to the clients listing
      navigate("/admin/clients/");
    } catch (error) {
      errorReporting("Failed creating new client", error, { ...details });
    }
  };

  return (
    <div className="container py--25">
      <ContentHeader title="Create Client" />

      <Link to="/admin/clients/" className="d-inline-flex align-items-center txt--lg my--20">
        <BackIcon className="txt--grey" />
        <span className="txt--blue fw--regular">Back</span>
      </Link>

      <Formik
        initialValues={{
          name: "",
          slug: "",
          description: "",
          website_url: "",
          address: "",
          country: "US",
          city: "",
          state: "",
          zip: "",
          phone: "",
          enable_photos: true,
          disable_sbca: false,
        }}
        validationSchema={ADMIN_CLIENTS_NEW_SCHEMA}
        onSubmit={(details: AdminClientsCreateRequestFields) => handleCreateNewClient(details)}
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{
              maxWidth: 700,
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Field
              component={FormInputSideLabel}
              label="Name"
              id="name"
              name="name"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormInputSideLabel}
              label="Slug (CareerPages url friendly name, no spaces!)"
              id="slug"
              name="slug"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormInputSideLabel}
              label="Description"
              id="description"
              name="description"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormInputSideLabel}
              label="Website"
              id="website_url"
              name="website_url"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormDropdownSearchable}
              id="country"
              name="country"
              placeholder="Select Country"
              items={COUNTRY_DROPDOWN}
              size="full"
              label="Country"
              modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
              handleFieldUpdate={(selectedCountry: DropdownItem) => {
                setFieldValue("country", selectedCountry.value);
                setFieldValue("state", "");
              }}
              preselectedItemValue={values.country}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />

            <Field
              component={FormInputSideLabel}
              label="Address"
              id="address"
              name="address"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormInputSideLabel}
              label={COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.city || "City"}
              id="city"
              name="city"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              key={values.country}
              component={FormDropdownSearchable}
              id="state"
              name="state"
              label={
                COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.state ?? "State"
              }
              placeholder={`Select ${
                COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.state ?? "State"
              }`}
              items={STATES_DROPDOWN[values.country as CountryOption]}
              size="full"
              modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
              handleFieldUpdate={(selectedState: DropdownItem) => {
                setFieldValue("state", selectedState.value);
              }}
              preselectedItemValue={values.state}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />

            <Field
              component={FormInputSideLabel}
              label={
                COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.code ?? "Zip Code"
              }
              id="zip"
              name="zip"
              modifierClass="input-side-label--lg mb--15"
              size="full"
            />

            <Field
              component={FormPhoneInput}
              label="Phone"
              id="phone"
              name="phone"
              modifierClass="input--phone-side-label mb--15"
              size="full"
            />

            <Field
              component={FormDropdownSearchable}
              id="enable_photos"
              name="enable_photos"
              placeholder={CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN[0].text}
              items={CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN}
              size="full"
              label="Applicant Photos"
              modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
              handleFieldUpdate={(photos: DropdownItem) => {
                setFieldValue("enable_photos", !!photos.value);
              }}
              preselectedItemValue={Number(values.enable_photos) || null}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />

            <Field
              component={FormDropdownSearchable}
              id="disable_sbca"
              name="disable_sbca"
              placeholder={CLIENTS_DISABLE_SBCA_DROPDOWN[0].text}
              items={CLIENTS_DISABLE_SBCA_DROPDOWN}
              size="full"
              label="SBCA"
              modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
              handleFieldUpdate={(sbca: DropdownItem) => {
                setFieldValue("disable_sbca", !!sbca.value);
              }}
              preselectedItemValue={Number(values.disable_sbca) || null}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary mt--20 ml--135"
              isLoading={createClient.isLoading}
              isDisabled={createClient.isLoading}
            >
              Create Client
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminClientsNew;
