// Flatpickr package
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

// Hooks & Utilities
import { useEffect, useRef, useState } from "react";
import { format, isToday } from "date-fns";

// Assets
import CalendarIcon from "../../assets/images/icons/applications-calendar-icon.svg?react";

// Interfaces
import { DatepickerProps } from "./interfaces";

const Datepicker = ({
  handleSelectedDate,
  dateFormat = "m/d/Y h:i K",
  isDisabled = false,
  preselectedDate = null,
  minDate = "today",
  maxDate = "none",
  enableTime = true,
  modifierClass = "",
  placeholder = format(new Date(), "MM/dd/yyyy hh:mm a"),
}: DatepickerProps) => {
  const [date, setDate] = useState<Date[] | undefined>(undefined);

  const handleDateChange = (dates: Date[]) => {
    setDate(dates);
    handleSelectedDate(dates);
  };

  // When the preselected date prop value is updated, update the state for the date
  useEffect(() => {
    if (preselectedDate === null) {
      setDate(undefined);
      return;
    }

    setDate([preselectedDate]);
  }, [preselectedDate]);

  // Reference to the Flatpickr component's instnace
  const flatpickrRef = useRef<any>();

  /*==============================
    CONTROL TIME SELECTION
  ================================*/
  const [minTime, setMinTime] = useState<string | undefined>(undefined);

  // Handle the value used for allowed selection
  // of minimum time (hours/minutes combination)
  useEffect(() => {
    // Exit function if time selection is not enabled
    if (!enableTime) return;

    if (date && isToday(date[0])) {
      setMinTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    } else {
      setMinTime(undefined);
    }
  }, [date]);

  return (
    <div className={`datepicker ${modifierClass}`}>
      <div className="datepicker__wrapper">
        <Flatpickr
          data-enable-time
          ref={flatpickrRef}
          options={{
            dateFormat,
            enableTime,
            minTime,
            minDate: minDate === "none" ? undefined : minDate,
            maxDate: maxDate === "none" ? undefined : maxDate,
            defaultDate: date,
            disableMobile: true,
          }}
          disabled={isDisabled}
          value={date}
          placeholder={placeholder}
          onChange={handleDateChange}
        />

        <CalendarIcon
          className="datepicker__icon"
          onClick={() => flatpickrRef.current.flatpickr.open()}
        />
      </div>
    </div>
  );
};

export default Datepicker;
