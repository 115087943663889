// Utilities & Hooks
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import Card from "../Card/Card";
import JobAdsTable from "./JobAdsTable";

// Interfaces
import { JobAdsLatestCardProps } from "./interfaces";
import { JobAdsLatestAd } from "../../api/JobAds/interfaces";

const JobAdsLatestCard = ({
  adGroupStatus,
  count,
  ads,
  countLimit = 5,
  clientName = "",
}: JobAdsLatestCardProps) => {
  const jobAdStatus: string = adGroupStatus.split("_").join(" ");

  // Use and display only the first 5 ads in the list
  const [overviewAds, setOverviewAds] = useState<JobAdsLatestAd[]>([]);

  useEffect(() => {
    if (!ads || !ads.length) return;

    // Slice and return only the ads that are within the count limit
    const slicedAds: JobAdsLatestAd[] = [...ads].slice(0, countLimit);
    setOverviewAds(slicedAds);
  }, [ads]);

  return (
    <div className="job-ads__latest">
      <h2 className="job-ads__latest__title">
        {jobAdStatus} ads ({count})
      </h2>

      <Card modifierClass={`job-ads__latest__card ${count === 0 ? "card--padding--xl" : ""}`}>
        {count === 0 ? (
          <p className="job-ads__latest__no-ads">
            No <span>{jobAdStatus}</span> ads found
          </p>
        ) : (
          <JobAdsTable
            ads={overviewAds}
            adGroupStatus={adGroupStatus}
            count={count}
            paginationSize={countLimit}
            shouldHandlePageRouteParameter={false}
          />
        )}

        {count > countLimit ? (
          <Link
            className="job-ads__latest__view-all"
            to={`/job-ads/ads?status=${adGroupStatus}${
              clientName ? `&client=${encodeURIComponent(clientName)}` : ""
            }`}
            title="* Count for last 60 days shown"
          >
            View all <span>{jobAdStatus}</span> ({count})
          </Link>
        ) : null}
      </Card>
    </div>
  );
};

export default JobAdsLatestCard;
