import Markdown from "react-markdown";

interface MarkdownWrapperProps {
  markdown: string;
  modifierClass?: string;
}

function MarkdownWrapper({ markdown, modifierClass = "" }: MarkdownWrapperProps) {
  return <Markdown className={`markdown-wrapper ${modifierClass}`}>{markdown}</Markdown>;
}

export default MarkdownWrapper;
