// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import {
  AdminClientsCreateRequestFields,
  AdminClientsListResponseFields,
  ClientStatusesResponseFields,
} from "./interfaces";
import { AdminClientsEditFormDetailsState } from "../../pages/Admin/Clients/interfaces";

// TODO: Move all of these API Hooks into `Company/AdminCompany.ts` file

/**
 *
 * Get the list of ALL existing clients in the application
 *
 */
export function useAdminClientsGet() {
  return useQuery(
    ["clients-admin"],
    async () => {
      return (await fetchHandler("GET", "admin/company")) as AdminClientsListResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Get a specific client based on the clients slug
 *
 */
export function useAdminClientsGetSpecific(clientSlug: string | undefined) {
  return useQuery(
    ["client-admin", clientSlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/company/${clientSlug}`,
      )) as AdminClientsListResponseFields;
    },
    {
      enabled: !!clientSlug,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 *
 * Creates a new client
 *
 * The mutation hook takes the form's details as a parameter
 *
 */
export function useAdminClientCreate() {
  const queryClient = useQueryClient();

  return useMutation(
    (clientDetails: AdminClientsCreateRequestFields) => {
      return fetchHandler<AdminClientsCreateRequestFields>("POST", "admin/company", clientDetails);
    },
    {
      onSuccess: () => toast.success("Client created successfully!"),
      onError: error => error,
      onSettled: () => queryClient.invalidateQueries(["clients-admin"]),
    },
  );
}

/**
 *
 * Updates the client details for a specific client
 *
 * @param slug The targeted client that will be updated
 *
 * The mutation hook takes the form's client details as a parameter
 */
export function useAdminClientUpdateDetails(slug: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (details: AdminClientsEditFormDetailsState) => {
      return fetchHandler("PUT", `admin/company/${slug}`, details);
    },
    {
      onSuccess: () => toast.success("Client's details were successfully updated"),
      onError: error => error,
      onSettled: () => {
        return queryClient.invalidateQueries(["clients-admin"]);
      },
    },
  );
}

/**
 *
 * Get the list of company statuses (Not used anywhere)
 *
 */
export function useAdminClientStatuses() {
  return useQuery(
    ["admin-clients-statuses"],
    async () => {
      return (await fetchHandler(
        "GET",
        "admin/company/statuses",
      )) as ClientStatusesResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}
