// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AdminUsersEditRequestFields,
  AdminUsersCreateRequestFields,
  AdminUsersResponseFields,
} from "./interfaces";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";
import handleErrorMessage from "../../utilities/handleErrorMessage";

/**
 *
 * Get the list of ALL existing users in the application
 *
 */
export function useAdminUsersGet() {
  return useQuery(
    ["users-admin"],
    async () => {
      return (await fetchHandler("GET", "admin/users")) as AdminUsersResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Get a specific user based on the user ID
 *
 */
export function useAdminUsersGetSpecific(userID: string | undefined) {
  return useQuery(
    ["user-admin", userID],
    async () => {
      return (await fetchHandler("GET", `admin/users/${userID}`)) as AdminUsersResponseFields;
    },
    {
      enabled: userID == undefined ? false : true,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 *
 * Delete the targeted user
 *
 * The mutation hook takes a "userID" parameter which can be either a number or null
 *
 */
export function useAdminUsersDelete() {
  const queryClient = useQueryClient();

  return useMutation(
    (userID: number) => {
      return fetchHandler("DELETE", `admin/users/${userID}`);
    },
    {
      onMutate: userID => {
        // Get admin users cached data
        const cachedUsersData = queryClient.getQueryData([
          "users-admin",
        ]) as AdminUsersResponseFields[];

        // Update query data with the provided user filtered out
        queryClient.setQueryData(["users-admin"], () => {
          if (!cachedUsersData) return;

          return cachedUsersData.filter(user => user.id !== userID);
        });

        // Show success notification
        toast.success("User deleted successfully.", {
          toastId: "users-admin-delete-user",
        });

        // Return old data in case of error
        return { cachedUsersData };
      },
      onError: (error, _deleteDetails, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("users-admin-delete-user");

        // Insert old cache data upon error scenario
        queryClient.setQueryData(["users-admin"], context?.cachedUsersData);
        return error;
      },
    },
  );
}

/**
 *
 * Creates a new user
 *
 * The mutation hook takes the form's details as a parameter
 *
 */
export function useAdminUsersCreate() {
  const queryClient = useQueryClient();

  return useMutation(
    (userDetails: AdminUsersCreateRequestFields) => {
      return fetchHandler<AdminUsersCreateRequestFields>("POST", "admin/users", userDetails);
    },
    {
      onMutate: () => {
        const existingUsers = queryClient.getQueryData(["users-admin"]);

        // Show success notification
        toast.success("User created successfully!", {
          toastId: "users-admin-create-user",
        });

        return existingUsers;
      },
      onError: error => {
        // Dismiss the success notification from the UI first
        toast.dismiss("users-admin-create-user");

        return error;
      },
      onSettled: () => queryClient.invalidateQueries(["users-admin"]),
    },
  );
}

/**
 *
 * Updates the account details for a specific user
 *
 * @param userID The targeted user that will be updated
 *
 * The mutation hook takes the form's user details as a parameter
 */
export function useAdminUserUpdateAccountDetails() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ userID, details }: { userID: string; details: AdminUsersEditRequestFields }) => {
      return fetchHandler("PUT", `admin/users/${userID}`, details);
    },
    {
      onMutate: ({ userID, details }) => {
        const cachedAdminUserData = queryClient.getQueryData([
          "user-admin",
          userID,
        ]) as AdminUsersResponseFields;

        queryClient.setQueryData(["user-admin", userID], {
          ...cachedAdminUserData,
          first_name: details.first_name,
          last_name: details.last_name,
          title: details.title,
          email: details.email,
        });

        // Show success notification
        toast.success("User account details were successfully updated", {
          toastId: "users-admin-update-user",
        });

        return { cachedAdminUserData };
      },
      onError: (error, variables, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("users-admin-update-user");

        queryClient.setQueryData(["user-admin", variables.userID], context?.cachedAdminUserData);
        return error;
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["user-admin", variables.userID]);
      },
    },
  );
}
