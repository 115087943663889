// Pages & Layout
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import Privileges from "../pages/Privileges/Privileges";
import PrivilegesGroupCrud from "../pages/Privileges/PrivilegesCrud";
import PrivilegesGroup from "../pages/Privileges/PrivilegesGroups";
import PrivilegesUserUpdates from "../pages/Privileges/PrivilegesUserUpdates";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_PRIVILEGES: ApplicationRouteProps[] = [
  {
    path: "/privileges",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <Privileges />
      </LayoutAuthenticated>
    ),
    permissions: ["privileges_view"],
    type: "protected",
  },
  {
    path: "/privileges/groups",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <PrivilegesGroup />
      </LayoutAuthenticated>
    ),
    permissions: ["privileges_view"],
    type: "protected",
  },
  {
    path: "/privileges/groups/new",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <PrivilegesGroupCrud />
      </LayoutAuthenticated>
    ),
    permissions: ["privileges_group_create"],
    type: "protected",
  },
  {
    path: "/privileges/groups/edit/:groupID",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <PrivilegesGroupCrud />
      </LayoutAuthenticated>
    ),
    permissions: ["privileges_group_edit"],
    type: "protected",
  },
  {
    path: "/privileges/user/:userID",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <PrivilegesUserUpdates />
      </LayoutAuthenticated>
    ),
    permissions: ["privileges_users_edit"],
    type: "protected",
  },
];
