import { RadioButtonProps } from "./interfaces";

const RadioButton = ({
  id,
  name,
  value,
  label,
  handleRadioButtonChange,
  isChecked = false,
  isDisabled = false,
  modifierClass = "",
}: RadioButtonProps) => {
  return (
    <div className={`input-radio ${modifierClass}`}>
      <input
        type="radio"
        value={value}
        id={id}
        name={name}
        hidden
        checked={isChecked}
        disabled={isDisabled}
        onChange={handleRadioButtonChange}
      />
      <label htmlFor={id} className="input__label">
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
