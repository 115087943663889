import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component that will be used to scroll to the top of the page, without rendering anything in the UI.
 * Works by listening to changes in the pathname of the current window location, and anytime this
 * pathname is changed, which represents a page change when clicked on a link, we scroll to the top of the page.
 * */
const ScrollRestoration = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollRestoration;
