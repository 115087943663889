// Utilities & Hooks
import { Link } from "react-router-dom";
import { handleDistanceToNow } from "../../utilities/dates/handleDistanceToNow";

// Interfaces
import { ArticlesResponseFields } from "../../api/Articles/interfaces";
import { ArticlesOthersProps } from "./interfaces";

// Components
import Card from "../Card/Card";

const ArticlesOthers = ({ articles }: ArticlesOthersProps) => {
  return (
    <Card modifierClass="articles-other card--rounded">
      <h3 className="articles-other__title">Other Articles</h3>

      <div className="articles-other__list">
        {articles.length > 0 ? (
          articles.map((article: ArticlesResponseFields) => (
            <Link
              key={`articles-others-${article.id}`}
              to={`/articles/${article.id}/`}
              className="articles-other__item"
            >
              <div className="articles-other__image">
                <img src={article.feature_image} />
              </div>

              <div>
                <h6 className="fw--semibold" title={article.title}>
                  {article.title}
                </h6>
                <span className="d-block txt--sm">
                  {handleDistanceToNow(article.create_date * 1000)}
                </span>
              </div>
            </Link>
          ))
        ) : (
          <h5 className="articles-other__title articles-other__title--not-found">
            No Articles found.
          </h5>
        )}
      </div>
    </Card>
  );
};

export default ArticlesOthers;
