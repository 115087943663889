import { format } from "date-fns";
import {
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";

export const REJECTION_EMAIL_NO_INTERVIEW: EmailTemplateModel = {
  name: "rejection-email-no-interview",
  label: "Rejection Email (No Interview)",
  subject: (details: EmailTemplateDetailsModel) =>
    `Re: Your Job Application dated ${format(new Date(details.applicationDate), "MM/dd/yyyy")}`,
  html: (details: EmailTemplateDetailsModel) => `
<p>Dear <span style="color:orangered;">${details.applicantName}</span>,</p>
        <br />
<p>Thank you for your interest in <span style="color:orangered">${details.senderCompany}</span>.</p>
    <br />
<p>We appreciate the opportunity to review your qualifications for our open position.</p>
    <br />
<p>Unfortunately, we have decided to not proceed further with your application at this time. We extend our best wishes in your job search.</p>
    <br />
<p>Sincerely,</p>
<p style="color:orangered;">${details.senderName}</p>
<p style="color:orangered;">[TITLE]</p>`,
};
