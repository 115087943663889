import { AssessmentStaticQuestionGroups, AssessmentStaticQuestionsList } from "../interfaces";
import * as Yup from "yup";
import { ONLINE_APPLICATION_ASSESSMENT_QUESTIONS_SCHEMA } from "../../../schemas/OnlineApplicationSchemas";

/**
 *
 * Utility function that generates the initial field and value combinations
 * for the individual question `groups` and individual `questions` within those groups,
 * so they can be used by formik.
 *
 * @param questions Pre-defined list of questions
 *
 * @returns The initial field/value combinations for each individual
 * question group, and each individual question field within that group.
 *
 */
export default function handleGenerateAssessmentQuestions(
  questions: AssessmentStaticQuestionGroups[],
) {
  // If there are no valid question groups received, exit function
  // Note: At the moment the `questions` list is pre-defined,
  // so there will always be a list of questions to be generated.
  if (!questions || !questions.length) {
    return {
      questionGroups: {},
      questionFields: {},
      VALIDATION_SCHEMA: {},
    };
  }

  // Generate the individual question groups and question fields
  const questionGroups: Record<string, number> = {};
  const questionFields: Record<string, number> = {};

  questions.forEach((questionGroup, questionGroupIndex: number) => {
    const groupFieldName: string = `${questionGroup.id}${questionGroupIndex + 1}`;
    questionGroups[groupFieldName] = 3;

    // Generate fields for each individual question from within the question group
    questionGroup.questions.forEach((question: AssessmentStaticQuestionsList) => {
      const questionFieldName: string = `${question.id}${questionGroupIndex + 1}`;
      questionFields[questionFieldName] = 1;
    });
  });

  // Generate validation schema for all the individual question groups and questions within them
  const VALIDATION_SCHEMA = handleGenerateAssessmentQuestionsValidationSchema(
    questionGroups,
    questionFields,
  );

  return {
    questionGroups,
    questionFields,
    VALIDATION_SCHEMA,
  };
}

/**
 *
 * Generate validation schemas for each individual `question group`, aswell as
 * each individual `question` within that question group.
 *
 * @param questionGroups An object representing the individual question groups
 * @param questionFields An object representing the individual questions
 *
 * @returns Validation schema that is consisted of:
 * - the pre-defined validation schema for the Online Application - Assessment form
 * - the generated validation schema for the question groups
 * - the generated validation schema for the individual questions
 *
 */
function handleGenerateAssessmentQuestionsValidationSchema(
  questionGroups: Record<string, number>,
  questionFields: Record<string, number>,
) {
  // Validation Schemas
  const questionGroupsValidationSchemas: Record<any, any> = {};
  const questionFieldsValidationSchemas: Record<any, any> = {};
  let VALIDATION_SCHEMA: Record<any, any> = ONLINE_APPLICATION_ASSESSMENT_QUESTIONS_SCHEMA;

  // Generate validation schema for each individual question group
  Object.keys(questionGroups).forEach(key => {
    questionGroupsValidationSchemas[key] = Yup.number()
      .typeError("sbca_assessment:form.errors.group_sums.type_error")
      .min(10, "sbca_assessment:form.errors.group_sums.min")
      .max(10, "sbca_assessment:form.errors.group_sums.max");
  });

  // Generate validation schema for each individual question
  // within each of the question groups
  Object.keys(questionFields).forEach(key => {
    questionFieldsValidationSchemas[key] = Yup.number()
      .typeError("sbca_assessment:form.errors.sliders.type_error")
      .min(1, "sbca_assessment:form.errors.sliders.min")
      .max(10, "sbca_assessment:form.errors.sliders.max");
  });

  // Merge the validation schemas that were generated for
  // the "question groups" and "individual questions", with the
  // pre-defined validation schema for the static fields
  VALIDATION_SCHEMA = VALIDATION_SCHEMA.shape({
    ...questionGroupsValidationSchemas,
    ...questionFieldsValidationSchemas,
  });

  return VALIDATION_SCHEMA;
}
