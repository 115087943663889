import { handleCalculatePercentage } from "../../../utilities/numbers/handleCalculatePercentage";
import { CHAT_CIRCLE_PROGRES_DASH_ARRAY } from "../constants";

/**
 *
 * Calculate the progress of the typed message,
 * when the progress is displayed trough a circular progressbar.
 *
 * Calculate the progress towards the message character limit,
 * based on the currently typed message
 *
 * @param messageLength The length of the currently typed message
 * @param maxMessageLength The maximum allowed length of the message to be typed
 * @param progressIndicator The type of progress indicator that is being used.
 *
 * @returns The progress percentage of the typed message
 *
 */
export function handleCalculateChatMessageProgress(
  messageLength: number,
  maxMessageLength: number,
  progressIndicator: "circular" | "line",
): number {
  // Calculate the percentage representing the progress
  // of the typed message (how much characters we have left until reaching the limit)
  const progressPercentage: number = handleCalculatePercentage(messageLength, maxMessageLength);

  // Calculate the offset of the stroke's dash array
  // that will result in filling the circular progress
  if (progressIndicator === "circular") {
    return CHAT_CIRCLE_PROGRES_DASH_ARRAY * ((100 - progressPercentage) / 100);
  } else {
    return progressPercentage;
  }
}
