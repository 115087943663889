import { CSSProperties, useEffect, useMemo, useState } from "react";

// Assets
import {
  FaSort as SortDefaultIcon,
  FaSortDown as SortDescendingIcon,
  FaSortUp as SortAscendingIcon,
} from "react-icons/fa";
import { MdDragIndicator as DragHandlerIcon } from "react-icons/md";

// Tanstack Table
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  type Row,
} from "@tanstack/react-table";

// "DND Kit"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

// Components
import Loader from "../Loader/Loader";
import TableNoDataMessage from "./TableNoDataMessage";

// Interfaces
import { TableColumnMetaProperties } from "./interfaces";

interface TableDnDProps {
  data: any;
  columns: any;
  modifierClass?: string;
  title?: string;
  isRefetching?: boolean;
  noDataMessage?: string;
  handleUpdateData: (reorderedData: any) => void;
}

/** Cell to be used as the drag handler */
export function TableDragHandleCell({ rowId }: { rowId: number }) {
  const { attributes, listeners, isDragging } = useSortable({ id: rowId });
  return (
    <DragHandlerIcon
      {...attributes}
      {...listeners}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
      className="table__drag-handler"
    />
  );
}

/** Row to be rendered in the table that can be dragged and used for reordering */
function DraggableRow({ row }: { row: Row<Record<"id", number> & unknown> }) {
  const { transform, transition, isDragging, setNodeRef } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.85 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0px 10px 15px 0px rgba(0,0,0,0.1)" : "unset",
    backgroundColor: "white",
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {row.getVisibleCells().map(cell => (
        <td key={cell.id} style={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}

function TableDnD({
  data,
  columns,
  title,
  modifierClass = "",
  isRefetching = false,
  noDataMessage = "No data available",
  handleUpdateData,
}: TableDnDProps) {
  /*=======================
    SORTING HANDLING
  ========================*/
  const [sorting, setSorting] = useState<SortingState>([]);

  /*=======================
    TABLE DATA
  ========================*/
  const [reorderedData, setReorderedData] = useState(data);

  // The list of unique identifiers used by DnD Kit
  const dataUniqueIds = useMemo(() => {
    return reorderedData.map((data: any) => data.id);
  }, [reorderedData]);

  // If any external updates happened to the
  // originally received dataset, update the inner state to stay in sync
  useEffect(() => {
    setReorderedData(data);
  }, [data]);

  // Initialize the Tanstack Table state
  const table = useReactTable({
    data: reorderedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: any) => row.id,
    state: { sorting },
    onPaginationChange: undefined,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  /*=======================
    DRAG AND DROP
  ========================*/
  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    let updatedData = [...reorderedData];

    if (!active || !over || active.id === over.id) return;

    // Get the indices of the elements from where
    // the drag event started and where it ends
    const startDragElementIndex: number = updatedData.findIndex(data => {
      return data.id === active.id;
    });

    const endDragElementIndex: number = updatedData.findIndex(data => {
      return data.id === over.id;
    });

    // Re-ordered table data
    updatedData = arrayMove(updatedData, startDragElementIndex, endDragElementIndex);

    // Update the order key value based on the latest order of the rows in the table
    updatedData = updatedData.map((data, key) => {
      return {
        ...data,
        order: key + 1,
      };
    });

    // Update the table data local state so UI updates instantly
    setReorderedData(updatedData);

    // Trigger a callback function in the parent component
    // (e.g. send an API request to save the re-ordered data)
    handleUpdateData(updatedData);
  };

  // DnD Kit Sensors
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={handleOnDragEnd}
      sensors={sensors}
    >
      <div className={`table-wrapper ${modifierClass}`}>
        {title && <h3 className="table__title">{title}</h3>}

        {data.length > 0 && isRefetching ? (
          <Loader modifierWrapper="loader--table" size="lg" speed="medium" />
        ) : null}

        <table className={`table ${isRefetching ? "table--refetching" : ""}`}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={`${header.column.getCanSort() ? "sortable" : ""}`}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: header.getSize(),
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      className={`d-flex ${
                        (header.column.columnDef.meta as TableColumnMetaProperties)
                          ?.headerModifierClass ?? ""
                      }`}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {header.column.getCanSort() && (
                        <div className="table__sort">
                          {{
                            asc: <SortAscendingIcon />,
                            desc: <SortDescendingIcon />,
                          }[header.column.getIsSorted() as string] ?? <SortDefaultIcon />}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {data.length > 0 ? (
            <tbody>
              <SortableContext items={dataUniqueIds} strategy={verticalListSortingStrategy}>
                {table.getRowModel().rows.map(row => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </tbody>
          ) : null}
        </table>

        {data.length === 0 ? (
          <TableNoDataMessage message={noDataMessage} isRefetching={isRefetching} />
        ) : null}
      </div>
    </DndContext>
  );
}

export default TableDnD;
