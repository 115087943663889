// Hooks & Utilities
import { useEffect, useRef, useState } from "react";
import { usePreventBodyScroll } from "../../../hooks/usePreventBodyScroll";
import { useAuth } from "../../../providers/auth-context";
import { useApplicationGetResume } from "../../../api/Applications/Applications";
import useWindowResize from "../../../hooks/useWindowResize";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";
import fileDownloadHandler from "../../../api/fileDownloadHandler";

// Components
import ApplicationArchive from "../../Applications/Buckets/ApplicationArchive";
import ApplicationSBCA from "../../Applications/ApplicationSBCA";
import ApplicationFavorite from "../../Applications/Buckets/ApplicationFavorite";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import ModalBackdrop from "../../Modal/ModalBackdrop";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// PDF Package
import { Document, Page, pdfjs } from "react-pdf";
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker.min?url";

// Register the PDF Worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

// Assets
import { MdClose as CloseIcon } from "react-icons/md";
import InfoIcon from "../../../assets/images/icons/info-icon.svg?react";

// Interfaces
import { AppointmentResumePreviewProps } from "../interfaces";
import { useOnEscapeKey } from "../../../hooks/useOnEscapeKey";

const AppointmentResumePreview = ({
  applicationDetails,
  handleCloseModal,
}: AppointmentResumePreviewProps) => {
  const errorReporting = useErrorReporting();

  /*==================================
    FETCH THE APPLICATION'S RESUME
  ===================================*/
  const applicationResume = useApplicationGetResume(applicationDetails.id);

  /*==================================
    COUNT THE TOTAL NUMBER OF PAGES
    IN THE PROVIDED RESUME PDF
  ===================================*/
  const [pdfTotalPages, setPdfTotalPages] = useState<number>(0);

  const handlePDFViewerLoadSuccess = ({ numPages }: any) => setPdfTotalPages(numPages);

  /*==================================
    HANDLE PDF RELATED ERRORS
  ===================================*/
  const [pdfHasError, setPdfHasError] = useState<boolean>(false);

  const handlePDFViewerError = () => setPdfHasError(true);

  /*================================
    DISPLAY CURRENT PDF'S PAGE
    IN THE UI BASED ON USER'S SCROLL
  ================================*/
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  // If the container ref exists in the DOM, and there's no error related to the PDF,
  // attch scroll event listeners so we can update the current page based on user's scroll
  if (pdfContainerRef && pdfContainerRef.current && !pdfHasError) {
    // This is the header element's height within the PDF viewer's container,
    // where the current and total number of pages are displayed,
    const pdfContainerHeaderElementHeight: number = 50;

    pdfContainerRef.current.addEventListener("scroll", function (this) {
      const scrolledTopDistance: number = this.scrollTop;

      this.querySelectorAll(".react-pdf__Page").forEach((pdfPage: Element, pageIndex: number) => {
        // Typecast the pdf page element to the correct type so we can have access to "offsetTop" property
        const pageAsHTMLElement = pdfPage as HTMLElement;
        const pageOffsetTop = pageAsHTMLElement.offsetTop - pdfContainerHeaderElementHeight;

        // If the user has scrolled "TO" or "PAST" the page's top offset value,
        // then we increment the state value for the current page shown in the UI
        if (scrolledTopDistance >= pageOffsetTop && currentPage <= pageIndex + 1) {
          setCurrentPage(pageIndex + 1);
        }
      });
    });
  }

  // Prevent the body of the page from scrolling when
  // the modal component is displayed in the UI.
  usePreventBodyScroll();

  /*=========================
    DOWNLOAD THE 
    APPLICATION'S RESUME
  ==========================*/
  const { user } = useAuth();
  const handleResumeDownload = async () => {
    try {
      await fileDownloadHandler(
        `company/${user.active_company.slug}/applications/${applicationDetails.id}/resume/download`,
      );
    } catch (error) {
      errorReporting("Failed dowloading application resume.", error, {
        application_id: applicationDetails.id,
      });
    }
  };

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  const modalRef = useRef<HTMLDivElement>(null);
  useOnEscapeKey(modalRef, handleCloseModal);

  /*=======================
    PASSWORD PROTECTED
  ========================*/
  const [pdfIsPasswordProtected, setPdfIsPasswordProtected] = useState<boolean>(false);

  const handleOnPassword = (callback: any, reason: any) => {
    function callbackProxy(password: string) {
      if (!password) {
        setPdfIsPasswordProtected(true);
        callback(password);
      }
    }

    switch (reason) {
      // This corresponds to NEEDS PASSWORD
      case 1:
        callbackProxy("");
        break;
      // This corresponds to INVALID
      case 2:
        callbackProxy("");
        break;
    }
  };

  /*========================
    PDF Scaling based on
    device windows size.
  ==========================*/
  const [windowWidth] = useWindowResize();
  const [pdfScaling, setPdfScaling] = useState<number>(1.5);

  useEffect(() => {
    // For desktop
    if (windowWidth >= 991 && pdfScaling !== 1.5) setPdfScaling(1.5);

    // For tablet devices
    if (windowWidth < 991 && pdfScaling !== 1) setPdfScaling(1);

    // For mobile devices
    if (windowWidth < 575 && pdfScaling !== 0.7) setPdfScaling(0.7);
  }, [windowWidth]);

  return (
    <div className="application-resume-modal__wrapper" ref={modalRef} tabIndex={0}>
      <ModalBackdrop handleCloseModal={handleCloseModal} />

      <motion.div
        className={`application-resume-modal ${
          applicationResume.isError || pdfHasError ? "application-resume-modal--error" : ""
        }`}
        key="framer-modal"
        initial={{ opacity: 0, transform: "translateX(-50%) translateY(-150px)" }}
        animate={{
          opacity: 1,
          transform: "translateX(-50%) translateY(-50%)",
          transition: { delay: 0.1 },
        }}
        exit={{ opacity: 0, transform: "translateX(-50%) translateY(-150px)" }}
        transition={{ duration: 0.4 }}
      >
        <div className="application-resume-modal__header application-resume-modal__header--appointments">
          <div className="application-resume-modal__header__buckets mr--140 mr--lg--0">
            <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
              <ApplicationArchive
                id={applicationDetails.id}
                currentBuckets={applicationDetails.buckets}
                text="Archive"
                shouldShowLoader
                name={handleFullnameCombination(applicationDetails)}
              />

              <ApplicationFavorite
                id={applicationDetails.id}
                currentBuckets={applicationDetails.buckets}
                text="Favorite"
                shouldShowLoader
                name={handleFullnameCombination(applicationDetails)}
              />
            </PermissionCheckComponentWrapper>
          </div>
          <div className="application-resume-modal__header__details">
            <h5
              className="application-resume-modal__header__details__name"
              title={handleFullnameCombination(applicationDetails)}
            >
              <strong>{handleFullnameCombination(applicationDetails)}</strong>
            </h5>

            <Link to={`/applications/${applicationDetails.id}/`}>View Details</Link>
            <div className="application-resume-modal__sbca">
              <ApplicationSBCA
                id={applicationDetails.id}
                name={handleFullnameCombination(applicationDetails)}
                email={applicationDetails.email}
                sbca={applicationDetails.sbca}
                sbca_report_identifier={applicationDetails.sbca_report_identifier}
                sbca_request_date={applicationDetails.sbca_request_date}
                has_ai_sbca_summary={applicationDetails.has_ai_sbca_summary}
              />
            </div>

            <span className="application-resume-modal__close" onClick={handleCloseModal}>
              <CloseIcon />
            </span>
          </div>
        </div>

        {pdfIsPasswordProtected ? (
          <PasswordProtectedPDFError />
        ) : applicationResume.isError || pdfHasError ? (
          <InvalidPDFError />
        ) : (
          <div className="application-resume-modal__pdf-container" ref={pdfContainerRef}>
            <div className="application-resume-modal__pdf-container__pages">
              {applicationResume.isFetching ? null : (
                <span>
                  Page {currentPage} of {pdfTotalPages} pages
                </span>
              )}
            </div>

            {applicationResume.isFetching ? (
              <Loader size="page" modifierWrapper="loader--page" />
            ) : (
              <Document
                file={applicationResume.data?.resume}
                className="application-resume-modal__previewer"
                loading={<Loader size="page" modifierWrapper="loader--page" />}
                onLoadSuccess={handlePDFViewerLoadSuccess}
                onLoadError={handlePDFViewerError}
                onPassword={handleOnPassword}
              >
                {Array.from(new Array(pdfTotalPages), (_el, index: number) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pdfScaling} />
                ))}
              </Document>
            )}
          </div>
        )}

        <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary--light"
            onClick={handleCloseModal}
          >
            Close
          </Button>

          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
            isLoading={applicationResume.isFetching}
            isDisabled={applicationResume.isFetching || applicationResume.isError}
            onClick={handleResumeDownload}
          >
            Download
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

// Error presentational component if there's no valid PDF provided
const InvalidPDFError = () => {
  return (
    <div className="application-resume-modal__pdf-error">
      <InfoIcon className="application-resume-modal__pdf-error-icon" />

      <div>
        <h6 className="mb--5">
          <strong>There was an issue previewing this resume:</strong>
        </h6>

        <p className="m--0">
          Please refresh your browser. If the resume will not display in preview mode, please
          download it from the applicant profile, or by clicking the download button below.
        </p>

        <p className="m--0">
          Need assistance? We are here to help. Please contact your Account Manager, or submit a
          help request here:{" "}
          <a
            href="https://firstchoicehiring.com/helpdesk"
            target="blank"
            className="txt--underline"
          >
            https://firstchoicehiring.com/helpdesk
          </a>
        </p>
      </div>
    </div>
  );
};

const PasswordProtectedPDFError = () => {
  return (
    <div className="application-resume-modal__pdf-error">
      <InfoIcon className="application-resume-modal__pdf-error-icon" />

      <div>
        <h6 className="mb--5">
          <strong>
            This uploaded resume is password protected and cannot be opened without it.
          </strong>
        </h6>

        <p className="m--0">
          Please inform the applicant that they uploaded a password protected resume and that they
          should re-apply.
        </p>

        <br />

        <p className="m--0">
          Need assistance? We are here to help. Please contact your Account Manager, or submit a
          help request here:{" "}
          <a
            href="https://firstchoicehiring.com/helpdesk"
            target="blank"
            className="txt--underline"
          >
            https://firstchoicehiring.com/helpdesk
          </a>
        </p>
      </div>
    </div>
  );
};

export default AppointmentResumePreview;
