// Interfaces
import { JobAdDetailsInformationProps } from "./interfaces";

// Components
import Card from "../../Card/Card";

const JobAdInformation = ({ details }: JobAdDetailsInformationProps) => {
  return (
    <Card modifierClass="card--padding--xl">
      <h3 className="txt--blue fw--semibold mb--20">Job Information</h3>

      {/* JOB SALESFORCE ORDER */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Salesforce Order</h5>
        </div>
        <div className="col-8">
          <a className="txt--lg" href={details.salesforce.link} target="_blank">
            {details.salesforce.number ?? "N/A"}
          </a>
        </div>
      </div>

      {/* JOB SALESFORCE COMMENTS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Salesforce Comments</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">
            {details.salesforce.comments || "N/A"}
          </p>
        </div>
      </div>

      {/* JOB TITLE */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Title</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_title || "N/A"}</p>
        </div>
      </div>

      {/* ADDRESS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Address</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">
            {details?.address.street || ""}
            {details?.address.city ? `, ${details.address.city}, ` : ""}
            {details?.address.state ? `${details.address.state}, ` : ""}
            {details?.address.zip ? `${details.address.zip}` : ""}
          </p>
        </div>
      </div>

      {/* HEADLINE #1 */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Headline 1</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.headline_1 || "N/A"}</p>
        </div>
      </div>

      {/* HEADLINE #2 */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Headline 2</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.headline_2 || "N/A"}</p>
        </div>
      </div>

      {/* HEADLINE #3 */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Headline 3</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.headline_3 || "N/A"}</p>
        </div>
      </div>

      {/* JOB QUESTIONS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Questions</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_questions || "N/A"}</p>
        </div>
      </div>

      {/* JOB INTRO */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Intro</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_intro || "N/A"}</p>
        </div>
      </div>

      {/* JOB DESCRIPTION */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Description</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_description || "N/A"}</p>
        </div>
      </div>
      {/* JOB TYPE */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Type</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_type || "N/A"}</p>
        </div>
      </div>

      {/* JOB BENEFITS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Benefits</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_benefits || "N/A"}</p>
        </div>
      </div>

      {/* JOB RESPONSIBILITIES */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Responsibilities</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">
            {details.job_responsibilities || "N/A"}
          </p>
        </div>
      </div>

      {/* JOB REQUIREMENTS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Requirements</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_requirements || "N/A"}</p>
        </div>
      </div>

      {/* JOB TERMS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Job Terms</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.job_terms || "N/A"}</p>
        </div>
      </div>

      {/* EMAIL ALERTS */}
      <div className="row mb--10">
        <div className="col-4">
          <h5 className="job-ads-details__information__title">Email Alerts</h5>
        </div>

        <div className="col-8">
          <p className="job-ads-details__information__text">{details.email_alerts || "N/A"}</p>
        </div>
      </div>
    </Card>
  );
};

export default JobAdInformation;
