/**
 *
 * Sort the "Highlights", "Benefits", or "Featured Highlights" data
 * that is received in the response from the API, based on the targeted field,
 * which for now is only limited to "ordinality".
 *
 * @param data The data representing the specific section
 * @returns Either an empty array if there's no valid data to be sorted, or
 * the sorted data based on ordinality value.
 *
 *
 */

import {
  CareerPagesBenefits,
  CareerPagesFeaturedHighlights,
  CareerPagesHighlights,
} from "../interfaces";

type CareerPageOrdinalitySortedData =
  | CareerPagesBenefits
  | CareerPagesHighlights
  | CareerPagesFeaturedHighlights;

export function handleCareerPagesOrdinalityDataSorting(
  data: CareerPageOrdinalitySortedData[],
): any[] {
  // If there's no data to work with, return an empty array
  if (!data || !data.length) return [];

  return data.sort((a: CareerPageOrdinalitySortedData, b: CareerPageOrdinalitySortedData) => {
    // If the field does not exist, then do not try to sort the data
    if (!a.ordinality || !b.ordinality) return 0;

    return a.ordinality > b.ordinality ? 1 : -1;
  });
}
