// Assets
import UserIcon from "../../../assets/images/icons/assessment-user.svg?react";
import UsersIcon from "../../../assets/images/icons/assessment-users.svg?react";
import BriefcaseIcon from "../../../assets/images/icons/assessment-briefcase.svg?react";

// Interfaces
import { AssessmentCommentaryProps } from "./interfaces";

const AssessmentCommentary = ({ name, data }: AssessmentCommentaryProps) => {
  return (
    <div className="box box--padding bg--gray">
      <div className="box-row align-items-center">
        <div className="box-row__icon">
          <UserIcon />
        </div>
        <p className="assessment-txt assessment-txt--lg">
          <strong>{name}</strong> is a <strong>{data.base_type}</strong>
        </p>
      </div>

      <div className="box-row align-items-center">
        <div className="box-row__icon">
          <UsersIcon />
        </div>
        <p className="assessment-txt assessment-txt--lg">
          <strong>{name}</strong> may be described by others as:{" "}
          <strong>{data.described_by_others}</strong>
        </p>
      </div>

      <div className="box-row">
        <div className="box-row__icon">
          <BriefcaseIcon />
        </div>
        <p className="assessment-txt assessment-txt--lg">
          Preferred Work Environment: <strong>{data.preferred_work_enviroment}</strong>
        </p>
      </div>
    </div>
  );
};
export default AssessmentCommentary;
