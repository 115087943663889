/**
 *
 * Utility function that calculates what percent of
 * the `total value` is the given `current value`.
 *
 * @returns Calculated percentage number.
 *
 */
export function handleCalculatePercentage(current_value: number, total_value: number): number {
  return (current_value / total_value) * 100;
}
