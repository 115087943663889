// Utilities
import { handleUserInitials } from "../../utilities/strings/handleUserInitials";
import { handleConvertSBCARatingToClassname } from "./utils/handleConvertSBCARatingToClassname";

// Interfaces
import { ApplicationPhotoProps } from "./interfaces";

const ApplicationPhoto = ({ name, photo, sbca }: ApplicationPhotoProps) => {
  return (
    <div
      className={`applications__photo applications__photo--${handleConvertSBCARatingToClassname(
        sbca,
      )}`}
    >
      {photo ? (
        <img src={photo} alt="Applicant's profile picture" />
      ) : (
        <p className="txt--center m--0 fw--semibold">{handleUserInitials(name)}</p>
      )}
    </div>
  );
};

export default ApplicationPhoto;
