import { DropdownItem } from "../components/Dropdown/interfaces";
import { CountryAddressLabelOptions, CountryOption } from "../interfaces/global";

export const STATES_USA_DROPDOWN: DropdownItem[] = [
  { value: "AL", text: "Alabama" },
  { value: "AK", text: "Alaska" },
  { value: "AS", text: "American Samoa" },
  { value: "AZ", text: "Arizona" },
  { value: "AR", text: "Arkansas" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DE", text: "Delaware" },
  { value: "DC", text: "District of Columbia" },
  { value: "FM", text: "Federated States of Micronesia" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "GU", text: "Guam" },
  { value: "HI", text: "Hawaii" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "IA", text: "Iowa" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "ME", text: "Maine" },
  { value: "MH", text: "Marshall Islands" },
  { value: "MD", text: "Maryland" },
  { value: "MA", text: "Massachusetts" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MS", text: "Mississippi" },
  { value: "MO", text: "Missouri" },
  { value: "MT", text: "Montana" },
  { value: "NE", text: "Nebraska" },
  { value: "NV", text: "Nevada" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NY", text: "New York" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "MP", text: "Northern Mariana Islands" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PW", text: "Palau" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "PR", text: "Puerto Rico" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UM", text: "U.S. Minor Outlying Islands" },
  { value: "VI", text: "U.S. Virgin Islands" },
  { value: "UT", text: "Utah" },
  { value: "VT", text: "Vermont" },
  { value: "VA", text: "Virginia" },
  { value: "WA", text: "Washington" },
  { value: "WV", text: "West Virginia" },
  { value: "WI", text: "Wisconsin" },
  { value: "WY", text: "Wyoming" },
];

export const STATES_CA_DROPDOWN: DropdownItem[] = [
  { text: "Alberta", value: "Alberta" },
  { text: "British Colombia", value: "British Colombia" },
  { text: "Manitoba", value: "Manitoba" },
  { text: "New Brunswick", value: "New Brunswick" },
  { text: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { text: "Northwest Territories", value: "Northwest Territories" },
  { text: "Nova Scotia", value: "Nova Scotia" },
  { text: "Nunavut", value: "Nunavut" },
  { text: "Ontario", value: "Ontario" },
  { text: "Prince Edward Island", value: "Prince Edward Island" },
  { text: "Quebec", value: "Quebec" },
  { text: "Saskatchewan", value: "Saskatchewan" },
  { text: "Yukon", value: "Yukon" },
];

export const STATES_DROPDOWN: Record<CountryOption, DropdownItem[]> = {
  US: STATES_USA_DROPDOWN,
  CA: STATES_CA_DROPDOWN,
};

export const COUNTRY_BASED_ADDRESS_LABELS: Record<CountryOption, CountryAddressLabelOptions> = {
  US: {
    city: "City",
    state: "State",
    code: "Zip Code",
  },
  CA: {
    city: "Municipality",
    state: "Province",
    code: "Postal Code",
  },
};

export const COUNTRY_DROPDOWN: DropdownItem[] = [
  { text: "USA", value: "US" },
  { text: "Canada", value: "CA" },
];
