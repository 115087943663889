import { BucketsResponseFields } from "../../../api/Buckets/interfaces";
import { BUCKETS_PREDEFINED_ORDER } from "../../Buckets/Buckets";

/**
 *
 * Utility function for ordering the buckets that are presented
 * in a dropdown menu using the same order as its being used
 * for the "Buckets" filtering menu on Applications page
 *
 * @param buckets - The list of Buckets obtained from the API
 *
 */
export function orderBuckets(buckets: BucketsResponseFields[]) {
  // Return an empty array if there are no available buckets
  if (!buckets.length) return [];

  return buckets
    .map((bucket, index) => {
      if (Object.prototype.hasOwnProperty.call(BUCKETS_PREDEFINED_ORDER, bucket.name)) {
        return {
          text: bucket.name,
          value: bucket.id,
          sortValue: BUCKETS_PREDEFINED_ORDER[bucket.name],
        };
      } else {
        return {
          text: bucket.name,
          value: bucket.id,
          sortValue: bucket.name.toLowerCase() === "archived" ? buckets.length : index,
        };
      }
    })
    .sort((bucketA, bucketB) => {
      return bucketA.sortValue > bucketB.sortValue ? 1 : -1;
    });
}
