// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";
import { BiShow as ShowPasswordIcon, BiHide as HidePasswordIcon } from "react-icons/bi";

// Utilities & Hooks
import { Link, useParams } from "react-router-dom";
import {
  useAdminUsersGetSpecific,
  useAdminUserUpdateAccountDetails,
} from "../../../../api/Users/AdminUsers";
import { useEffect, useMemo, useState } from "react";
import { useAdminRolesGetAll } from "../../../../api/Roles/AdminRoles";
import useErrorReporting from "../../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../../utilities/strings/handleFullnameCombination";
import useAdminUsersCompanies from "./hooks/useAdminUsersCompanies";

// Components
import { Field, Form, Formik } from "formik";
import ContentHeader from "../../../../components/Content/ContentHeader";
import Skeleton from "react-loading-skeleton";
import Button from "../../../../components/Button/Button";
import FormDropdownMultiselect from "../../../../components/Form/FormDropdownMultiselect";
import FormInputSideLabel from "../../../../components/Form/FormInputSideLabel";
import Loader from "../../../../components/Loader/Loader";
import PermissionCheckComponentWrapper from "../../../../components/Wrappers/PermissionCheckComponentWrapper";
import FormDropdownSearchable from "../../../../components/Form/FormDropdownSearchable";

// Interfaces
import { AdminUsersEditFormDetailsState } from "../interfaces";
import { DropdownItem } from "../../../../components/Dropdown/interfaces";
import { RolesResponseFields } from "../../../../api/Roles/interfaces";
import { UserRoleIDsEnum } from "../../../../interfaces/global";

// Schemas
import { ACCOUNT_ADMIN_USERS_EDIT_ACCOUNT_DETAILS } from "../../../../schemas/AccountSchemas";

const AdminUsersEdit = () => {
  const { id } = useParams();
  const errorReporting = useErrorReporting();

  /*=============================
    GET USER'S DETAILS
  ==============================*/
  const { data: userData, isLoading: userIsLoading } = useAdminUsersGetSpecific(id);

  const [userFullname, setUserFullname] = useState<string>("");
  const [userFormDetails, setUserFormDetails] = useState<AdminUsersEditFormDetailsState>({
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    company_ids: [],
    password: "",
    password_confirmation: "",
    role_id: null,
  });

  /*===============================
    GET THE LIST OF COMPANIES
  ================================*/
  const [companies, companiesLoading] = useAdminUsersCompanies();

  /*===============================
    OBTAIN THE ROLES FROM THE API
    AND MAP THEM INTO DROPDOWN ITEMS
  ================================*/
  const { data: roles, isLoading: rolesIsLoading } = useAdminRolesGetAll();

  // Map the roles into dropdown items
  const rolesDropdownItems: DropdownItem[] = useMemo(() => {
    // Return a default empty array if there's no "roles" data available yet
    if (!roles || !roles.length || rolesIsLoading) return [];

    const mappedRoles: DropdownItem[] = roles.map((role: RolesResponseFields) => {
      return { text: role.label, value: role.id, description: role.description };
    });

    return mappedRoles;
  }, [roles]);

  /*===============================
    PREPOPULATE THE USER'S 
    FORM FIELDS
  ================================*/
  useEffect(() => {
    // Exit function if there's no "user" or "roles" data available
    if (!userData || !roles || !roles.length) return;

    // Construct the user's fullname
    const fullname: string = handleFullnameCombination(userData);

    // Extract only the IDs of the companies to which the user belongs to
    // which will be used to pre-select the companies in the dropdown menu
    const companiesIDs: number[] = userData.companies.map(company => company.id);

    // Find the user's role from the list of existing roles fetched from the API
    const matchedRole: RolesResponseFields | undefined = roles.find(role => {
      return role.label.toLowerCase() === userData.role.toLowerCase();
    });

    // Update the user's related states
    setUserFullname(fullname);
    setUserFormDetails({
      first_name: userData.first_name,
      last_name: userData.last_name,
      title: userData.title || "",
      email: userData.email,
      company_ids: companiesIDs,
      role_id: matchedRole?.id ?? null,
      password: "",
      password_confirmation: "",
    });
  }, [roles, userData]);

  /*===============================
    UPDATE USER'S ACCOUNT DETAILS
  ================================*/
  const accountDetailsUpdate = useAdminUserUpdateAccountDetails();

  const handleUpdateAccountDetails = async (details: AdminUsersEditFormDetailsState) => {
    try {
      if (!id) throw new Error("No user ID found");

      // Destructure the form details
      const {
        first_name,
        last_name,
        title,
        email,
        company_ids,
        password,
        password_confirmation,
        role_id,
      } = details;

      // Construct the request object by omitting the 'email' field if
      // the latest form value for this field is exactly the same as the prepoulated one
      const accountDetailsRequest = {
        first_name,
        last_name,
        title,
        company_ids,
        email,
        role_id,
        ...(password && { password }),
        ...(password_confirmation && { password_confirmation }),
      };

      await accountDetailsUpdate.mutateAsync({ userID: id, details: accountDetailsRequest });
    } catch (error) {
      errorReporting("Failed updating user's account details", error, { user_id: id, ...details });
    }
  };

  /*===============================
    HANDLE PASSWORD 
    FIELDS VISIBILITY
  ================================*/
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  return (
    <div className="container py--25">
      <ContentHeader
        title={
          <>
            Edit User -{" "}
            {userIsLoading ? <Skeleton width="120px" height="20px" /> : userFullname || "N/A"}
          </>
        }
      />

      <Link to="/account/admin/users/" className="d-inline-flex align-items-center txt--lg my--20">
        <BackIcon className="txt--gray" />
        <span className="txt--blue fw--regular">Back</span>
      </Link>

      {userIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <>
          <Formik
            initialValues={userFormDetails}
            enableReinitialize
            validationSchema={ACCOUNT_ADMIN_USERS_EDIT_ACCOUNT_DETAILS}
            onSubmit={handleUpdateAccountDetails}
          >
            {({ values, validateForm, setFieldValue }) => (
              <Form
                style={{
                  maxWidth: 700,
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <Field
                  component={FormInputSideLabel}
                  label="First Name"
                  id="first_name"
                  name="first_name"
                  modifierClass="input-side-label mb--15"
                  size="full"
                />

                <Field
                  component={FormInputSideLabel}
                  label="Last Name"
                  id="last_name"
                  name="last_name"
                  modifierClass="input-side-label mb--15"
                  size="full"
                />

                <Field
                  component={FormInputSideLabel}
                  label="Title"
                  id="title"
                  name="title"
                  modifierClass="input-side-label mb--15"
                  size="full"
                />

                <Field
                  component={FormInputSideLabel}
                  label="Email"
                  id="email"
                  name="email"
                  modifierClass="input-side-label mb--15"
                  size="full"
                />

                <Field
                  component={FormDropdownSearchable}
                  label="Role"
                  id="role_id"
                  name="role_id"
                  modifierClass="dropdown--side-label mb--15"
                  size="full"
                  items={rolesDropdownItems}
                  placeholder="Select a role"
                  isLoading={rolesIsLoading}
                  disabled={rolesIsLoading || !rolesDropdownItems.length}
                  handleFieldUpdate={(role: DropdownItem) => {
                    setFieldValue("role_id", role.value);

                    // Clear out the selected companies if the selected role is "super admin" or "admin"
                    const { SUPER_ADMIN, ADMIN } = UserRoleIDsEnum;
                    if ([SUPER_ADMIN, ADMIN].includes(role.value as number)) {
                      setFieldValue("company_ids", []);
                    }

                    // Re-validate the form anytime the role changes, as this can trigger
                    // clearing the selection of group and company selections
                    validateForm();
                  }}
                  preselectedItemValue={values.role_id}
                  framerAnimationCustomProps={{ hasSideLabel: true }}
                />

                <Field
                  component={FormDropdownMultiselect}
                  id="company_ids"
                  name="company_ids"
                  placeholder={companiesLoading ? "Fetching Companies..." : "Select a company"}
                  items={companies}
                  isLoading={companiesLoading}
                  disabled={
                    companiesLoading ||
                    companies.length === 0 ||
                    [UserRoleIDsEnum.SUPER_ADMIN, UserRoleIDsEnum.ADMIN].includes(
                      values.role_id as number,
                    )
                  }
                  size="full"
                  label="Companies"
                  preselectedItems={values.company_ids}
                  modifierClass="dropdown--side-label mb--15"
                  searchable
                  clearable
                  handleFieldUpdate={(companies: number[]) => {
                    setFieldValue("company_ids", companies);
                  }}
                  framerAnimationCustomProps={{ hasSideLabel: true }}
                />

                <Field
                  component={FormInputSideLabel}
                  label="Password"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  modifierClass="input-side-label mb--15"
                  size="full"
                  autoComplete="new-password"
                  nestedChildrenElements={
                    <div className="input-with-elements__element input-with-elements__element--right">
                      {showPassword ? (
                        <ShowPasswordIcon onClick={() => setShowPassword(!showPassword)} />
                      ) : (
                        <HidePasswordIcon onClick={() => setShowPassword(!showPassword)} />
                      )}
                    </div>
                  }
                />

                <Field
                  component={FormInputSideLabel}
                  label="Confirm Password"
                  id="password_confirmation"
                  name="password_confirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  modifierClass="input-side-label mb--15"
                  size="full"
                  autoComplete="new-password"
                  nestedChildrenElements={
                    <div className="input-with-elements__element input-with-elements__element--right">
                      {showConfirmPassword ? (
                        <ShowPasswordIcon
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      ) : (
                        <HidePasswordIcon
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )}
                    </div>
                  }
                />

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary mt--20 mb--20 ml--135 ml--md--0"
                  isLoading={accountDetailsUpdate.isLoading}
                  isDisabled={accountDetailsUpdate.isLoading}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>

          {userData?.companies && userData.companies.length > 0 ? (
            <PermissionCheckComponentWrapper permissions={["privileges_users_edit"]}>
              <hr />

              <div className="d-flex flex-column  flex-sm-row justify-content-between align-items-center my--20">
                <h5 className="txt--blue fw--semibold">
                  {userIsLoading ? (
                    <Skeleton width="120px" height="20px" />
                  ) : (
                    `${userFullname}'s Privileges`
                  )}
                </h5>

                <Link
                  to={`/privileges/user/${id}/`}
                  className="btn btn--fluid btn--fluid--md btn--primary"
                >
                  Edit User Privileges
                </Link>
              </div>
            </PermissionCheckComponentWrapper>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AdminUsersEdit;
