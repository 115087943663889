// Components
import Tooltip from "../Tooltip/Tooltip";

// Assets
import ListView from "../../assets/images/icons/list-view.svg?react";
import GridView from "../../assets/images/icons/grid-view.svg?react";

// Interfaces
import { ViewActionsProps } from "./interfaces";

const ViewActions = ({ activeView, modifierClass = "", handleViewSelection }: ViewActionsProps) => {
  return (
    <div className={`content__actions ${modifierClass}`}>
      <Tooltip text="Classic View">
        <div
          onClick={() => handleViewSelection("classic")}
          className={`content__actions__icon ${
            activeView === "classic" ? "content__actions__icon--active" : ""
          }`}
          data-testid="view-actions-component:classic"
        >
          <ListView />
        </div>
      </Tooltip>

      <span className="content__actions__splitter" />

      <Tooltip text="Grid View">
        <div
          onClick={() => handleViewSelection("grid")}
          className={`content__actions__icon ${
            activeView === "grid" ? "content__actions__icon--active" : ""
          }`}
          data-testid="view-actions-component:grid"
        >
          <GridView />
        </div>
      </Tooltip>
    </div>
  );
};

export default ViewActions;
