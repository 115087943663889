import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

const ArticlesSkeleton = ({ mode }: { mode: "grid" | "classic" }) => {
  return (
    <div
      className={`container my--30 ${mode === "grid" ? "container--lg" : ""}`}
      data-testid="component:articles-page-skeleton"
    >
      <div className="row">
        {mode === "grid" ? (
          <>
            {[...Array(3).keys()].map((_, index) => (
              <motion.div
                key={`articles-card-grid-skeleton-${index}`}
                initial={{ opacity: 0, translateY: "30px" }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: "30px" }}
                transition={{
                  delay: 0.25 * index,
                  type: "spring",
                  repeat: Infinity,
                  repeatDelay: 0.5,
                  repeatType: "reverse",
                }}
                className="col-12 col-md-6 mb--30"
              >
                <div className="article">
                  <Skeleton width="100%" height={285} style={{ top: "-5px" }} />

                  <div className="article__content">
                    <div>
                      <Skeleton width={200} height={20} className="mb--5" />
                      <Skeleton width={150} height={15} />
                      <Skeleton width={50} height={10} className="mb--30" />

                      <div className="mb--30">
                        <Skeleton width={350} height={15} />
                        <Skeleton width={200} height={15} />
                        <Skeleton width={250} height={15} />
                        <Skeleton width={150} height={15} />
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <Skeleton width={145} height={35} className="mr--10" />
                      <Skeleton width={105} height={35} />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </>
        ) : (
          <>
            {[...Array(3).keys()].map((_, index) => (
              <motion.div
                key={`articles-card-list-skeleton-${index}`}
                initial={{ opacity: 0, translateY: "30px" }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: "30px" }}
                transition={{
                  delay: 0.25 * index,
                  type: "spring",
                  repeat: Infinity,
                  repeatDelay: 0.5,
                  repeatType: "reverse",
                }}
                className="article article--list mb--30"
              >
                <div className="row no-gutters">
                  <div className="col-4">
                    <Skeleton width="100%" height="100%" style={{ top: "-2px" }} />
                  </div>

                  <div className="col-8">
                    <div className="article__content">
                      <div>
                        <Skeleton width={200} height={20} className="mb--5" />
                        <Skeleton width={150} height={10} />
                        <Skeleton width={50} height={10} className="mb--30" />

                        <div className="mb--20">
                          <Skeleton width={350} height={15} />
                          <Skeleton width={200} height={15} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <Skeleton width={100} height={20} className="mr--10" />
                        <Skeleton width={80} height={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ArticlesSkeleton;
