import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Custom utility function that handles back navigation
 *
 * This function is used as a failsafe against empty session history back navigation
 * If the session history contains items from the same domain, we use the
 * native 'navigate(-1)', else we navigate to the provided fallback route
 *
 * @returns 'handleNavigateBack' - The navigation handler that accepts a fallback route as parameters
 *
 */
export const useBackNavigation = () => {
  const navigate = useNavigate();

  const [referrerIsSameDomain, setReferrerIsSameDomain] = useState<boolean>();

  useEffect(() => {
    // If the user navigated to the current page through the app OR it's just a page refresh the document.referrer will be empty
    // We use this to determine whether we should use the native 'navigate(-1)' or use the fallback route
    if (!document.referrer || new URL(document.referrer).hostname === window.location.hostname) {
      setReferrerIsSameDomain(true);
    } else {
      setReferrerIsSameDomain(false);
    }
  }, []);

  // The handler checks whether there are previous elements in the history stack AND whether the
  // referrer is from the same domain, based on this we chose whether we go back the specified number of pages
  // which defaults to 1 previous page or the fallback route
  const handleNavigateBack = (fallback: string) => {
    if (window.history.state && window.history.state.idx > 1 && referrerIsSameDomain) {
      navigate(-1);
    } else {
      navigate(fallback);
    }
  };

  return handleNavigateBack;
};
