import { useEffect, useRef } from "react";

type IntervalCallbackFunction = () => unknown | void;

/**
 *
 * Hook for ease of usage of `setInterval` functionality (e.g. for timers)
 *
 * @param callback The passed callback function that will be triggered where the hook is being used.
 * @param delay The amonut of delay between each iterration of the called `setInterval`. Defaults to `500ms`
 *
 */
function useInterval(callback: IntervalCallbackFunction, delay: number | null = 500) {
  const savedCallback = useRef<IntervalCallbackFunction>(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay !== "number") return;

    const tick = () => savedCallback.current();
    const interval = setInterval(tick, delay);
    return () => clearInterval(interval);
  }, [delay]);
}

export default useInterval;
