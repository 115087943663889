import { DropdownItem } from "../../../../Dropdown/interfaces";

/*================================
  JOB INDUSTRY
=================================*/
export const SNAG_A_JOB_DROPDOWN_JOB_INDUSTRY: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: "AccountingAndFinance", text: "Accounting And Finance" },
  { value: "AdministrationAndOfficeSupport", text: "Administration And Office Support" },
  { value: "AgricultureAndEnvironment", text: "Agriculture And Environment" },
  { value: "Automotive", text: "	Automotive" },
  { value: "ComputersAndTechnology", text: " +Computers And Technology+" },
  { value: "Construction", text: "Construction" },
  { value: "Customer", text: "Service	Customer Service" },
  { value: "Education", text: "Education" },
  { value: "FoodAndRestaurant", text: "Food And Restaurant" },
  { value: "GovernmentAndMilitary", text: "Government And Military" },
  { value: "Healthcare", text: "Healthcare" },
  { value: "HotelAndHospitality", text: "Hotel And Hospitality" },
  { value: "InstallationAndRepair", text: "Installation And Repair" },
  { value: "LawEnforcementAndSecurity", text: "Law Enforcement And Security" },
  { value: "Legal", text: "Legal" },
  { value: "MaintenanceAndJanitorial", text: "Maintenance And Janitorial" },
  { value: "Management", text: "Management" },
  { value: "MediaAndEntertainment", text: "Media And Entertainment" },
  { value: "PersonalCareAndServices", text: "Personal Care And Services" },
  { value: "Retail", text: "Retail" },
  { value: "SalesAndMarketing", text: "Sales And Marketing" },
  { value: "SalonSpaFitness", text: "Salon Spa Fitness" },
  { value: "Social", text: "Services	Social Services" },
  { value: "Transportation", text: "Transportation" },
  { value: "WarehouseAndProduction", text: "Warehouse And Production" },
  { value: "Wellness", text: "Wellness" },
  { value: "WorkAtHome", text: "Work At Home" },
];

/*================================
  EMPLOYMENT TYPE
=================================*/
export const SNAG_A_JOB_DROPDOWN_EMPLOYMENT_TYPE: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: "FullTime", text: "Full Time" },
  { value: "FullTime,PartTime", text: "Full Time, Part Time" },
  { value: "PartTime", text: "Part Time" },
  { value: "SeasonalFullTime", text: "Seasonal Full Time" },
  { value: "SeasonalPartTime", text: "Seasonal Part Time" },
];
