import { ScreeningQuestions } from "../../../../../api/JobAds/interfaces";
import { DropdownItem } from "../../../../Dropdown/interfaces";

export const SCREENING_QUESTIONS_PREFERRED_ANSWERS: DropdownItem[] = [
  { text: "Select a preferred answer type", value: "" },
  { text: "No", value: "no" },
  { text: "Yes", value: "yes" },
];

export const SCREENING_QUESTIONS_IS_REQUIRED: DropdownItem[] = [
  { text: "No", value: "no" },
  { text: "Yes", value: "yes" },
];

export const SCREENING_QUESTIONS_NEW_QUESTION: ScreeningQuestions = {
  question: "",
  preferred_answers: [],
  is_required: "no",
};
