import { useEffect, useState } from "react";

interface PositionsSelectionIndicator {
  selectedPositionsIDs: number[];
  paginatedPositions: number[];
  allPositions: number[];
  setSelectedPositionIDs: (newPositions: number[]) => void;
}

const PositionsSelectionIndicator = ({
  selectedPositionsIDs,
  paginatedPositions,
  allPositions,
  setSelectedPositionIDs,
}: PositionsSelectionIndicator) => {
  //   TODO: Refactor this component to encompass general use selection indicator

  const [allPositionsSelected, setAllPositionsSelected] = useState(false);
  const [isWholePageSelected, setIsWholePageSelected] = useState(false);
  const [isTotalOverlapShown, setIsTotalOverlapShown] = useState(false);

  useEffect(() => {
    if (!allPositions.length || !paginatedPositions.length) return;

    // Track if ALL of the positions are selected
    const WHOLE_AVAILABLE_DATA_SELECTED: boolean = allPositions.every((position: number) => {
      return selectedPositionsIDs.includes(position);
    });

    setAllPositionsSelected(WHOLE_AVAILABLE_DATA_SELECTED);

    // Track wether the whole current page is selected
    const WHOLE_PAGE_SELECTED: boolean = paginatedPositions.every((position: number) => {
      return selectedPositionsIDs.includes(position);
    });

    setIsWholePageSelected(WHOLE_PAGE_SELECTED);

    //  Track wether ONLY the current page is selected
    const TOTAL_OVERLAP_SHOWN_SELECTED =
      JSON.stringify(selectedPositionsIDs.sort()) === JSON.stringify(paginatedPositions.sort());

    setIsTotalOverlapShown(TOTAL_OVERLAP_SHOWN_SELECTED);
  }, [selectedPositionsIDs, paginatedPositions, allPositions]);

  // The only selection is the whole current page and that's not all of the data
  const ONLY_CURRENT_PAGE_SELECTED =
    isWholePageSelected && !allPositionsSelected && isTotalOverlapShown;

  // Individual selections among page/pages that do not match any of the other groupings
  const SINGLE_POSITIONS_SELECTED = !allPositionsSelected && !isWholePageSelected;

  //  Whole current page is selected PLUS selections from other pages, whole data is not selected
  const CURRENT_PAGE_PLUS_MORE_SELECTED =
    isWholePageSelected && !allPositionsSelected && !isTotalOverlapShown;

  if (allPositionsSelected) {
    return (
      <div className="d-flex flex-column">
        <div className="applications__selection__details">
          <span className="w--100 txt--xl">
            All existing <strong className="txt--black">{selectedPositionsIDs.length}</strong>{" "}
            positions are selected.{" "}
            <span className="txt--link" onClick={() => setSelectedPositionIDs([])}>
              <strong>Clear selection?</strong>
            </span>
          </span>
        </div>
      </div>
    );
  }

  if (ONLY_CURRENT_PAGE_SELECTED) {
    return (
      <div className="d-flex flex-column">
        <div className="applications__selection__details">
          <span className="w--100 txt--xl">
            All <strong>{selectedPositionsIDs.length}</strong> positions on this page are selected.{" "}
            <span className="txt--link" onClick={() => setSelectedPositionIDs([...allPositions])}>
              <strong>Select all {allPositions.length} positions?</strong>
            </span>
          </span>
        </div>
      </div>
    );
  }

  if (SINGLE_POSITIONS_SELECTED) {
    return (
      <div className="d-flex flex-column">
        <div className="applications__selection__details">
          <span className="w--100 txt--xl">
            <strong>{selectedPositionsIDs.length}</strong>
            {` ${selectedPositionsIDs.length > 1 ? "positions are" : "position is"}`} selected.{" "}
            <span className="txt--link" onClick={() => setSelectedPositionIDs([])}>
              <strong>Clear selection?</strong>
            </span>
          </span>
        </div>
      </div>
    );
  }

  if (CURRENT_PAGE_PLUS_MORE_SELECTED) {
    return (
      <div className="d-flex flex-column">
        <div className="applications__selection__details">
          <span className="w--100 txt--xl">
            Total <strong>{selectedPositionsIDs.length}</strong> positions are selected, including
            all <strong>{paginatedPositions.length}</strong> on this page.{" "}
            <span className="txt--link" onClick={() => setSelectedPositionIDs([...allPositions])}>
              <strong>Select all {allPositions.length} positions?</strong>
            </span>
          </span>
        </div>
      </div>
    );
  }
};

export default PositionsSelectionIndicator;
