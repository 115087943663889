// Pages & Layouts
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import CareerPagesGraphics from "../pages/Career/CareerPageGraphics";
import CareerPages from "../pages/Career/CareerPages";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_CAREER: ApplicationRouteProps[] = [
  {
    path: "/career/pages",
    element: (
      <LayoutAuthenticated>
        <CareerPages />
      </LayoutAuthenticated>
    ),
    permissions: ["career_page_view"],
    type: "protected",
  },
  {
    path: "/career/pages/graphics",
    element: (
      <LayoutAuthenticated>
        <CareerPagesGraphics />
      </LayoutAuthenticated>
    ),
    permissions: ["career_page_view"],
    type: "protected",
  },
];
