// Utilities & Hooks
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useOnlineApplicationGetInfo,
  useOnlineApplicationUploadResume,
} from "../../api/OnlineApplication/OnlineApplication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import FormUpload from "../../components/Form/FormUpload";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";
import Loader from "../../components/Loader/Loader";

// Schemas
import { ONLINE_APPLICATION_UPLOAD_RESUME_SCHEMA } from "../../schemas/OnlineApplicationSchemas";

const OnlineApplicationUploadResume = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*===========================
    INFO DETAILS
  ============================*/
  const { data: infoData, isLoading: infoIsLoading, error } = useOnlineApplicationGetInfo(id);

  // Redirect the user to the 404 page if invalid application ID was used
  useEffect(() => {
    if (!error) return;
    navigate("/404/");
  }, [error]);

  /*===========================
    TRIGGER RESUME UPLOAD
  ============================*/
  const uploadResume = useOnlineApplicationUploadResume();

  const handleUploadResume = async (values: FormikValues) => {
    // If there's no valid identifier received, prevent form submission
    if (!id || infoData?.is_resume_uploaded) return;

    try {
      await uploadResume.mutateAsync({ id: id, resume: values.resume });

      // Redirect the user to the Thank You page
      navigate("/online-application/upload/resume/thank-you/");
    } catch (error) {
      errorReporting("Failed uploading application resume", error, { ...values });
    }
  };

  return (
    <div className="online-application-upload">
      {infoIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : infoData?.is_resume_uploaded ? (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="fw--semibold txt--center mb--30">Resume is already uploaded</h1>

            <p className="online-application-upload__text txt--center mb--50">
              Thank you for completing the job application. You have already uploaded your resume.{" "}
              <br /> We appreciate your interest in this career opportunity.
            </p>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1 className="online-application-upload__title">{infoData?.company || "N/A"}</h1>

          <h3 className="online-application-upload__subtitle">Upload your Resume</h3>
          <p className="online-application-upload__text">
            Thank you for completing the job application. We appreciate your interest in this career
            opportunity. To improve the visibility of your application and to assist our hiring
            managers, please upload your resume here.
          </p>

          <Formik
            initialValues={{ resume: null }}
            validationSchema={ONLINE_APPLICATION_UPLOAD_RESUME_SCHEMA}
            onSubmit={handleUploadResume}
          >
            {({ values, errors }) => (
              <Form>
                <Field
                  component={FormUpload}
                  id="resume"
                  name="resume"
                  buttonText="Choose File"
                  label="Upload your resume:"
                  placeholder="No file chosen"
                  modifierClass="input--box-error"
                  accept=".pdf, .doc, .docx, .txt, .rtf, .odt, .ps, .wpd, .wps"
                  uploadType="file"
                  fieldHasDedicatedFileTypeValidation
                  info={
                    <>
                      <span className="input-upload__info--info">
                        Supported file types: .pdf, .doc, .docx, .txt, .rtf, .odt, .ps, .wpd, .wps{" "}
                      </span>
                    </>
                  }
                />

                <p className="online-application-upload__text">
                  Please add no-reply@firstchoicehiring.com to your address book to ensure you
                  receive valuable communication from us in the future.
                </p>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--secondary"
                  isDisabled={uploadResume.isLoading || !values.resume || errors.resume}
                  isLoading={uploadResume.isLoading}
                >
                  Upload Resume
                </Button>
              </Form>
            )}
          </Formik>

          <OnlineApplicationFooter />
        </div>
      )}
    </div>
  );
};

export default OnlineApplicationUploadResume;
