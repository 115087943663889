import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

/** Used for both "Create" and "Edit" actions */
export const JOB_POSITIONS_WRITE_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required("Please enter the name of the job position.")
    .max(50, "Maximum of 50 characters allowed!")
    .test("job-positions-write", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
});
