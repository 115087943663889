import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/auth-context";
import Button from "../../components/Button/Button";

const ErrorNotFound = () => {
  const year: number = new Date().getFullYear();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="error-pages error-pages--not-found">
      <h1>404 Not Found</h1>
      <p>You have tried to access a page which does not exist or has been moved.</p>

      <div className="d-flex justify-content-center mt--30 mb--20">
        <Button modifierClass="btn--fluid btn--fluid--md btn--primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>

      <Link to="/" className="txt--center txt--lg mb--20">
        Go to {isAuthenticated ? "Applications Dashboard" : "Login"} page
      </Link>

      <p className="error-pages__copyright">
        &copy; FirstChoice Hiring 2014-{year}. All Rights Reserved.
      </p>
    </div>
  );
};

export default ErrorNotFound;
