import { useEffect, useState } from "react";
import { OnlineApplicationColorPickerProps } from "./interfaces";

// Constants
const ONLINE_APPLICATION_ASSESSMENT_COLORS: Record<string, string>[] = [
  { name: "red", value: "#fe0000" },
  { name: "maroon", value: "#990134" },
  { name: "brown", value: "#ae7600" },
  { name: "tan", value: "#dccbba" },
  { name: "light_green", value: "#33cb00" },
  { name: "dark_green", value: "#006766" },
  { name: "yellow", value: "#ff3" },
  { name: "orange", value: "#fe9900" },
  { name: "dark_blue", value: "#0100cc" },
  { name: "light_blue", value: "#0166ff" },
  { name: "black", value: "#000" },
  { name: "gray", value: "#677373" },
  { name: "white", value: "#fff" },
];

const OnlineApplicationColorPicker = ({
  form,
  field,
  selectionLimit = 1,
}: OnlineApplicationColorPickerProps) => {
  const [selectedColors, setSelectedColors] = useState<string[]>([]);

  const handleSelectColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if the clicked color already exists in the current selection
    const selectedColorsCopy: string[] = [...selectedColors];
    const existingColorIndex: number = selectedColors.findIndex(color => {
      return color === event.target.value;
    });

    // If color already exists in selection - remove it
    // If color does not already exist in selection, and the selection limit
    // has not been reached yet, add the color to the selection array
    if (existingColorIndex >= 0) {
      selectedColorsCopy.splice(existingColorIndex, 1);
    } else if (selectedColors.length !== selectionLimit) {
      selectedColorsCopy.push(event.target.value);
    }

    setSelectedColors(selectedColorsCopy);
  };

  // Update the form field value
  useEffect(() => {
    form.setFieldValue(field.name, selectedColors);
  }, [selectedColors]);

  return (
    <div
      className="online-application-assessment__color-picker"
      data-testid={`form:online-application-assessment-color-question-wrapper-${field.name}`}
    >
      {ONLINE_APPLICATION_ASSESSMENT_COLORS.map(color => (
        <div key={`color-picker-${color.name}`} className="d-flex align-items-center">
          <input
            type="checkbox"
            value={color.name}
            className="online-application-assessment__color-picker__checkbox"
            onChange={handleSelectColor}
            disabled={
              !selectedColors.includes(color.name) && selectedColors.length === selectionLimit
            }
            data-testid="form:online-application-assessment-color-checkbox"
          />
          <div
            className="online-application-assessment__color-picker__box"
            style={{
              backgroundColor: color.value,
              borderColor: color.value === "#fff" ? "#000" : "none",
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default OnlineApplicationColorPicker;
