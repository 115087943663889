// Hooks
import React, { useEffect, useRef, useState } from "react";
import { useChatsContext } from "../ChatWrapper/ChatContextWrapper";
import { useAuth } from "../../../providers/auth-context";
import { useVisualViewportResize } from "../../../hooks/useVisualViewportResize";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

// Assets
import { MdClose as ChatPanelCloseIcon } from "react-icons/md";
import ChatPanelMinimizeIcon from "../../../assets/images/icons/chat-panel-minimize-icon.svg?react";

// Components
import ChatActions from "../ChatActions";
import ChatAvatar from "../ChatAvatar";
import ChatPanelConversation from "./ChatPanelConversation";

// Interfaces
import { ChatPanelProps } from "../interfaces";

const ChatPanel = ({ details, modifierClass = "" }: ChatPanelProps) => {
  const chatsContext = useChatsContext();
  const { user } = useAuth();

  /*============================
    MINIMIZED
  =============================*/
  const [isChatPanelMinimized, setIsChatPanelMinimized] = useState<boolean>(details.is_minimized);

  // Update the internal state anytime the received prop value changes
  useEffect(() => {
    setIsChatPanelMinimized(details.is_minimized);
  }, [details.is_minimized]);

  const handleChatPanelMinimization = (event: React.MouseEvent) => {
    event.stopPropagation();
    chatsContext.handleMinimizeChat(details.id);
  };

  // Remove active (focused) state of the chat if the panel is to be minimized,
  // and also update the minimized state value for the chat that is saved in local storage
  useEffect(() => {
    if (isChatPanelMinimized) chatsContext.handleChatSetActive(null);
  }, [isChatPanelMinimized]);

  /*============================
    TOGGLE THE CHAT

    - Mark the opened chat panel as active
    - If minimized, expand it
  =============================*/
  const handleChatToggle = () => {
    chatsContext.handleChatSetActive(details.id);

    // If the chat is minimized, we want to expand it
    if (isChatPanelMinimized) chatsContext.handleMinimizeChat(details.id);
  };

  /*==========================
    CLOSE THE CHAT PANEL
  ===========================*/
  const handleCloseChat = (event: React.MouseEvent) => {
    event.stopPropagation();
    chatsContext.handleCloseChat(details.id);
  };

  /*==========================
    NOTIFY FOR NEW MESSAGE

    Adds a blinking animation to the chat panel
    if there's a new unread message in it 
  ===========================*/
  const [hasNewUnreadMessage, setHasNewUnreadMessage] = useState<boolean>(false);

  useEffect(() => {
    // If there are no existing user's chats, exit the function
    if (!chatsContext.existingUserChats || !chatsContext.existingUserChats.length) return;

    // Find the matching opened chat panel, from the list of existing user chats
    const chatIndex: number = chatsContext.existingUserChats.findIndex(chat => {
      return chat.id === details.chat_id;
    });

    // If such chat does not exist in the list, exit function
    if (chatIndex < 0) return;

    // If the last message for this chat is read, exit the function
    if (chatsContext.existingUserChats[chatIndex].last_message.is_read) {
      setHasNewUnreadMessage(false);
    } else {
      // If the last message for this chat is unread, trigger blinking animation
      setHasNewUnreadMessage(true);
    }
  }, [chatsContext.existingUserChats]);

  /*================================
    DEFOCUS CHATS
  =================================*/
  const chatPanelRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(chatPanelRef, () => {
    // Prevent removing the active chat ID value when
    // clicked within the currently active chat panel
    if (details.id !== chatsContext.activeChatID) return;

    chatsContext.handleChatSetActive(null);
  });

  /*================================
    ACTIVE PANEL
  =================================*/
  const [chatPanelIsActive, setChatPanelIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (details.id === chatsContext.activeChatID) {
      setChatPanelIsActive(true);
    } else {
      setChatPanelIsActive(false);
    }
  }, [details, chatsContext.activeChatID]);

  /*================================
    SHRINK CHAT PANEL BASED
    ON VIEWPORT HEIGHT
  =================================*/
  const [shouldShrinkChatPanel, setShouldShrinkChatPanel] = useState<boolean>(false);
  const viewportHeight = useVisualViewportResize();

  useEffect(() => {
    // Do not do anything if there's no viewport height to work with
    if (viewportHeight === 0) return;

    // Control whether the chat panel should be shrinked or not
    // based on if the device's viewport has changed due to virtual keyboard being opened
    // Constraint is set to 385px height here as that's the maximum height of the chat panel itself
    if (viewportHeight <= 385) {
      setShouldShrinkChatPanel(true);
    } else {
      setShouldShrinkChatPanel(false);
    }
  }, [viewportHeight]);

  // Do not render anything in the UI
  // until we have the neccessary data
  if (!user.id || !chatsContext.existingUserChats) return null;

  return (
    <div
      className={`chat-panel ${isChatPanelMinimized ? "chat-panel--minimized" : ""} ${
        hasNewUnreadMessage ? "chat-panel--new-message" : ""
      } ${chatPanelIsActive ? "chat-panel--active" : ""} ${modifierClass} ${
        shouldShrinkChatPanel ? "chat-panel--shrinked" : ""
      }`}
      onClick={handleChatToggle}
      ref={chatPanelRef}
      aria-controls={
        isChatPanelMinimized ? "minimized" : chatPanelIsActive ? "focused" : "unfocused"
      }
      data-testid="components:chat-panel"
    >
      <div className="chat-panel__header">
        <div className="chat-panel__data">
          <ChatAvatar
            size="sm"
            name={details.applicant.name}
            image_url={details.applicant.photo}
            status={details.is_blocked ? "blocked" : "active"}
          />
          <h6 className="chat-panel__data__name" title={details.applicant.name}>
            {details.applicant.name}
          </h6>
        </div>

        <div className="chat-panel__actions">
          {!details.is_new_chat && details.chat_id !== null ? (
            <ChatActions
              id={details.chat_id}
              is_blocked={details.is_blocked}
              orientation={isChatPanelMinimized ? "top" : "bottom"}
            />
          ) : null}

          <ChatPanelMinimizeIcon
            className="chat-panel__actions__minimize"
            onClick={(event: React.MouseEvent) => handleChatPanelMinimization(event)}
          />
          <ChatPanelCloseIcon
            className="chat-panel__actions__close"
            onClick={(event: React.MouseEvent) => handleCloseChat(event)}
          />
        </div>
      </div>

      <div className="chat-panel__content">
        <ChatPanelConversation
          details={details}
          chatConversationDetails={{
            currentUserName: "",
            is_blocked: details.is_blocked,
            chatting_with: {
              full_name: details.applicant.name,
              photo: details.applicant.photo,
            },
          }}
        />
      </div>
    </div>
  );
};

export default ChatPanel;
