import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const PrivilegesUserSkeleton = () => {
  return (
    <div data-testid="component:privileges-user-update-skeleton">
      {/* COMPANY SELECTION SECTION */}
      <Card modifierClass="card--padding--xl">
        <Skeleton width="150px" height="20px" className="mb--10" />
        <Skeleton width="350px" height="16px" className="mb--20" />

        <Skeleton width="300px" height="40px" className="mb--20" />

        <div className="d-flex align-items-center">
          <Skeleton width="190px" height="35px" />
          <Skeleton width="190px" height="35px" className="ml--10" />
        </div>
      </Card>

      {/* PRIVILEGE GROUPS SECTION */}
      <Card modifierClass="card--padding--xl">
        <Skeleton width="200px" height="23px" className="mb--5" />
        <Skeleton width="300px" height="18px" className="mb--20" />

        <div className="row mb--30">
          {Array.from({ length: 2 }).map((_, index: number) => {
            return (
              <div key={`company-row-${index}`} className="col-12 col-lg-6">
                <Skeleton width="150px" height="15px" className="mb--10" />

                <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                {Array.from({ length: 4 }).map((_, index: number) => {
                  return (
                    <Skeleton
                      key={`company-permission-${index}`}
                      width={(index + 1) * 50 + "px"}
                      height="12px"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card>

      {/* INDIVIDUAL PRIVILEGES SECTION */}
      <Card modifierClass="card--padding--xl">
        {/* COMPANY PRIVILEGES */}
        <Skeleton width="200px" height="23px" className="mb--5" />
        <Skeleton width="300px" height="18px" className="mb--20" />

        <div className="row mb--30">
          {Array.from({ length: 4 }).map((_, index: number) => {
            return (
              <div key={`company-row-${index}`} className="col-12 col-md-6 col-lg-3 mb--25">
                <Skeleton width="150px" height="15px" className="mb--10" />

                <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                {Array.from({ length: 4 }).map((_, index: number) => {
                  return (
                    <Skeleton
                      key={`company-permission-${index}`}
                      width={(index + 1) * 50 + "px"}
                      height="12px"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default PrivilegesUserSkeleton;
