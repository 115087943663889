// Hooks & Utilities
import { Field, Form, Formik } from "formik";
import { useUsersGetList } from "../../../api/Users/Users";
import { useMemo } from "react";
import { useAppointmentsScheduleVideoMeeting } from "../../../api/Appointments/Appointments";
import { format } from "date-fns";
import { useTimezonesGet } from "../../../api/Timezones/Timezones";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

// Schemas
import { APPLICATIONS_SCHEDULE_MEETING_VIDEO_CONFERENCE } from "../../../schemas/ApplicationsSchemas";

// Components
import Button from "../../Button/Button";
import Datepicker from "../../Datepicker/Datepicker";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormInput from "../../Form/FormInput";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { ScheduleVideoConferenceFormValues, ScheduleVideoMeetingProps } from "./interfaces";

const ScheduleVideoMeeting = ({ applicationID, handleCloseMenu }: ScheduleVideoMeetingProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    LIST OF INTERVIEWERS
  ========================*/
  const { data: interviewersData, isLoading: interviewersDataIsLoading } = useUsersGetList();

  const INTERVIEWERS: DropdownItem[] = useMemo(() => {
    if (!interviewersData || !interviewersData.length || interviewersDataIsLoading) return [];

    // Map the list of company users (interviewers) into dropdown items
    const mappedInterviewers: DropdownItem[] = interviewersData
      .filter(interviewer => interviewer.status === "active")
      .map(interviewer => {
        return {
          text: handleFullnameCombination(interviewer),
          value: interviewer.id,
        };
      })
      .sort((interviewerA, interviewerB) => {
        return interviewerA.text.toLowerCase() > interviewerB.text.toLowerCase() ? 1 : -1;
      });

    return mappedInterviewers;
  }, [interviewersData]);

  /*=========================================
    LIST OF TIMEZONES
  ==========================================*/
  const { data: timezones, isLoading: timezonesLoading } = useTimezonesGet();

  const TIMEZONES: DropdownItem[] = useMemo(() => {
    if (!timezones || !Object.entries(timezones).length || timezonesLoading) return [];

    // Map the received timezones into dropdown items
    const mappedTimezones: DropdownItem[] = Object.entries(timezones).map(timezone => {
      // The "key" here is the actual timezone
      // The "value" here is the GMT hour offset
      const [key, value] = timezone;
      const [country, city] = key.split("/");

      return {
        text: `(${value.replace(/\s/, "")}) ${country}, ${city.split("_").join(" ")}`,
        value: key,
      };
    });

    return mappedTimezones;
  }, [timezones]);

  /*=========================================
    SCHEDULE A "VIDEO MEETING" APPOINTMENT
  ==========================================*/
  const scheduleVideoMeetingAppointment = useAppointmentsScheduleVideoMeeting();

  const handleScheduleVideoConference = async (values: ScheduleVideoConferenceFormValues) => {
    // Exit function if there are no valid required values
    if (values.interviewer_id === null || values.meeting_duration === null) return;

    try {
      const { interviewer_id, meeting_name, meeting_duration, timezone, appointment_time } = values;

      // Convert the selected date & time value so it also shows the hours in 12h mode with AM/PM
      const CONVERTED_APPOINTMENT_TIMESTAMP: string = format(
        appointment_time,
        "MM/dd/yyyy hh:mm a",
      );

      // Send the API request
      await scheduleVideoMeetingAppointment.mutateAsync({
        application_id: applicationID.toString(),
        interviewer_id,
        meeting_name,
        meeting_duration,
        timezone,
        appointment_time: CONVERTED_APPOINTMENT_TIMESTAMP,
        type: "video",
      });

      // Close the menu after the appointment was made
      handleCloseMenu();
    } catch (error) {
      errorReporting("Failed scheduling 'Video Meeting' appointment.", error, {
        ...values,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        interviewer_id: null,
        meeting_name: "",
        appointment_time: new Date(),
        meeting_duration: 30,
        timezone: "",
      }}
      onSubmit={handleScheduleVideoConference}
      validationSchema={APPLICATIONS_SCHEDULE_MEETING_VIDEO_CONFERENCE}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <h5>
            <strong>Schedule a meeting</strong>
          </h5>
          <hr className="mb--20" />

          <Field
            component={FormDropdownSearchable}
            id="interviewer_id"
            name="interviewer_id"
            label="Meeting With"
            placeholder="Select Interviewer"
            items={INTERVIEWERS}
            isLoading={interviewersDataIsLoading}
            disabled={interviewersDataIsLoading || INTERVIEWERS.length === 0}
            handleFieldUpdate={(meetingWith: DropdownItem) => {
              setFieldValue("interviewer_id", meetingWith.value);
            }}
            size="full mb--15"
          />

          <Field
            component={FormInput}
            label="Meeting Name"
            id="meeting_name"
            name="meeting_name"
            placeholder="Example: Account's Interview Meeting"
            modifierClass="mb--15"
          />

          <label className="dropdown__label">Date, Time and Duration</label>

          <div className="p--relative d-flex flex-column flex-md-row align-items-center mb--20">
            <Datepicker
              modifierClass="datepicker--full m--0"
              handleSelectedDate={dates => setFieldValue("appointment_time", dates[0])}
            />

            <Field
              component={FormDropdownSearchable}
              id="meeting_duration"
              name="meeting_duration"
              preselectedItemValue={values.meeting_duration}
              items={[
                { text: "30min", value: 30 },
                { text: "1h", value: 60 },
                { text: "1h 30min", value: 90 },
                { text: "2h", value: 120 },
                { text: "2h 30min", value: 150 },
                { text: "3h", value: 180 },
              ]}
              handleFieldUpdate={(meetingDuration: DropdownItem) => {
                setFieldValue("meeting_duration", meetingDuration.value);
              }}
              size="sm"
              modifierClass="datepicker__duration"
            />
          </div>

          <Field
            component={FormDropdownSearchable}
            id="timezone"
            name="timezone"
            label="Timezone"
            placeholder="Select Timezone"
            items={TIMEZONES}
            handleFieldUpdate={(timezone: DropdownItem) => {
              setFieldValue("timezone", timezone.value);
            }}
            isLoading={timezonesLoading}
            disabled={!TIMEZONES.length || timezonesLoading}
            size="full"
            modifierClass="mb--20"
          />

          <div className="d-flex flex-column-reverse flex-md-row justify-content-center align-items-center">
            <Button
              type="button"
              modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary"
              onClick={() => handleCloseMenu()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              modifierClass="btn--fluid btn--pill btn--primary--dark btn--border btn--border--primary ml--10 ml--md--0 mb--md--15"
              isLoading={scheduleVideoMeetingAppointment.isLoading}
              isDisabled={scheduleVideoMeetingAppointment.isLoading}
            >
              Schedule Video Conference
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleVideoMeeting;
