// Utilities
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { JobAdsJobBoardActionsPending } from "../../../api/JobAds/interfaces";
import { useJobAdsJobBoardsManualAction } from "../../../api/JobAds/JobAds";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Components
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import Table from "../../Table/Table";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { JobBoardActionsPendingProps, JobBoardActionsPendingTableFields } from "./interfaces";

const JobBoardActions = ({ job_board_actions_pending }: JobBoardActionsPendingProps) => {
  const { id } = useParams();
  const errorReporting = useErrorReporting();

  /*======================================
    TABLE DATA MAPPING
  =======================================*/
  const TABLE_DATA = useMemo(() => {
    const dataset: JobAdsJobBoardActionsPending[] = [...job_board_actions_pending];

    return dataset;
  }, [job_board_actions_pending]);

  /*======================================
    TABLE COLUMNS
  =======================================*/
  const COLUMNS_HELPER = createColumnHelper<JobBoardActionsPendingTableFields>();

  const JOB_BOARD_ACTIONS_TABLE_COLUMNS = [
    COLUMNS_HELPER.accessor("job_board", {
      header: () => <span>Job Board</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
      size: 300,
    }),
    COLUMNS_HELPER.accessor("action_pending", {
      header: () => <span>Action Pending</span>,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("action_time", {
      header: () => <span>Action Date</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("created_time", {
      header: () => <span>Created</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
      size: 50,
    }),
    COLUMNS_HELPER.accessor("id", {
      header: () => <span>Manual Action</span>,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <PermissionCheckComponentWrapper permissions={["ad_manager_edit"]}>
          <div className="table__buttons table__buttons--sm">
            {data.row.original.manual_action_text.toLowerCase() === "n/a" ? (
              <span>{data.row.original.manual_action_text}</span>
            ) : (
              <Button
                modifierClass="btn--fluid btn--primary btn--font-md"
                onClick={() => handleManualAction(data.getValue())}
                isDisabled={manualTrigger.isLoading}
                isLoading={manualTrigger.isLoading}
              >
                {data.row.original.manual_action_text}
              </Button>
            )}
          </div>
        </PermissionCheckComponentWrapper>
      ),
      enableSorting: false,
      size: 450,
    }),
  ];

  /*=============================================
    TRIGGER MANUAL ACTIONS
  ==============================================*/
  const manualTrigger = useJobAdsJobBoardsManualAction();
  const handleManualAction = async (ad_action_id: number) => {
    try {
      await manualTrigger.mutateAsync({ ad_id: id, ad_action_id });
    } catch (error) {
      errorReporting("Failed manual ad action trigger", error, { ad_id: id, ad_action_id });
    }
  };

  return (
    <Card modifierClass="card--padding--sm">
      <h3 className="fw--semibold txt--blue">Job Board Actions Pending</h3>

      {TABLE_DATA.length > 0 ? (
        <Table
          data={TABLE_DATA}
          columns={JOB_BOARD_ACTIONS_TABLE_COLUMNS}
          isRefetching={false}
          modifierClass="table-wrapper--no-shadow mb--0i px--0i"
          shouldHandlePageRouteParameter={false}
        />
      ) : (
        <p className="txt--black mt--20 mb--0">No pending job board actions</p>
      )}
    </Card>
  );
};

export default JobBoardActions;
