import { useEffect } from "react";

/**
 *
 * Hook for updating the `viewport` meta tag's content based on user device.
 *
 * If user visits the web application on iOS device (iPhone, iPad, iPod)
 * then `maximum-scale=1.0` is added to the content of the viewport meta tag to
 * prevent auto-zoom from happening when an input field is focused which occurs
 * on these devices.
 *
 * If user visits the web application on Android or Destktop device then the
 * contents of the `viewport` meta tag aren't changed.
 *
 */
export default function useMetaViewportTag() {
  useEffect(() => {
    const metaViewportTag = document.querySelector("meta[name=viewport]");

    // Exit function if tag cannot be found
    if (!metaViewportTag) return;

    // Check if this tag has a 'maximum-scale' set
    let viewportTagContent: string | null = metaViewportTag.getAttribute("content");

    // Exit function if there's no content for this meta tag
    if (!viewportTagContent) return;

    const maxScaleRegex: RegExp = /maximum-scale=[0-9.]+/gi;
    const checkIfIOS = /ipad|iphone|ipod/i.test(navigator.platform);

    // Do not add the tag if device is not on iOS
    if (!checkIfIOS) return;

    // Include the maximum scale only on iOS devices
    if (maxScaleRegex.test(viewportTagContent)) {
      viewportTagContent = viewportTagContent.replace(maxScaleRegex, "maximum-scale=1.0");
    } else {
      viewportTagContent = [viewportTagContent, "maximum-scale=1.0"].join(", ");
    }

    // Update the viewport meta tag
    metaViewportTag.setAttribute("content", viewportTagContent);
  }, []);
}
