import { ChatsSortBy, ChatsSortingValues } from "./interfaces";

export const CHATS_SORTING_OPTIONS: Record<ChatsSortBy, ChatsSortingValues> = {
  most_recent: {
    fieldname: "last_message.timestamp",
    direction: "desc",
  },
  blocked: {
    fieldname: "is_blocked",
    direction: "desc",
  },
  unread: {
    fieldname: "last_message.is_read",
    direction: "asc",
  },
};

// A set of skeleton placeholder messages to be displayed in the UI
// while the messages for the openede chat are being fetched
export const CHATS_MESSAGE_SKELETON_PLACEHOLDERS: Array<"in" | "out"> = [
  "in",
  "out",
  "out",
  "in",
  "out",
  "in",
];

// Hard limit of how many characters can the message have
export const CHAT_MESSAGE_CHARACTERS_COUNT_LIMIT: number = 180;

// Circular progress bar properties
export const CHAT_CIRCLE_PROGRES_RADIUS: number = 6;
export const CHAT_CIRCLE_PROGRES_DASH_ARRAY: number = 2 * Math.PI * CHAT_CIRCLE_PROGRES_RADIUS;
