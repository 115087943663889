// Utilities & Hooks
import { useMemo, useRef, useState } from "react";
import { orderBy } from "lodash";
import { useAuth } from "../../providers/auth-context";
import { motion, AnimatePresence } from "framer-motion";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowResize from "../../hooks/useWindowResize";

// Components
import { Link, useLocation } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip";
import ChatMessagesSort from "./ChatMessagesSort";
import ChatMessageListItem from "./ChatMessageListItem";
import Loader from "../Loader/Loader";
import SkeletonChatMessageListItem from "./Skeleton/SkeletonChatMesageListItem";

// Assets
import SmsIcon from "../../assets/images/icons/sms-icon.svg?react";

// Interfaces
import { ChatsForSpecificUserResponseFields } from "../../api/Chat/interfaces";
import { ChatsSortBy } from "./interfaces";

// Constants
import { CHATS_SORTING_OPTIONS } from "./constants";

// Context
import { useChatsContext } from "./ChatWrapper/ChatContextWrapper";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import {
  FRAMER_HEADER_ANIMATION_WITH_OFFSET,
  FRAMER_HEADER_TRANSITIONS,
} from "../../constants/framer";

const ChatMessagesDropdown = () => {
  const chatsContext = useChatsContext();

  const location = useLocation();
  const { user } = useAuth();

  // If the page that we're currently viewing is the "Chat Messages" dedicated page
  // then we hide the chat messages dropdown icon and we render nothing for this component
  if (location.pathname.startsWith("/chats/messages")) return null;

  /*============================
    DROPDOWN VISIBILITY
  =============================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*============================
    USER'S CHATS

    - Fetch from the API
    - Map over the received list
    - Order (sort) the chats based on selected sorting option
    - Extract up to 5 chats to be shown
  =============================*/
  const [sortBy, setSortBy] = useState<ChatsSortBy>("most_recent");
  const [windowWidth] = useWindowResize();

  const CHATS = useMemo(() => {
    // If there's no chats data available, exit function
    if (
      !chatsContext.existingUserChats ||
      !chatsContext.existingUserChats.length ||
      chatsContext.existingUserChatsLoading
    ) {
      return [];
    }

    // Make a copy of the original data before manipulating it
    let chatsData = [...chatsContext.existingUserChats];

    // Filter out the data that will be presented in the dropdown menu
    // so that we dont take into consideration messages that are not
    // matching the condition used for sorting the list of displayed messages
    if (["blocked", "unread"].includes(sortBy)) {
      chatsData = chatsData.filter((chat: any) => {
        return chat[CHATS_SORTING_OPTIONS[sortBy].fieldname];
      });
    }

    // Order the chat messages data based on selected option
    chatsData = orderBy(
      chatsData,
      [CHATS_SORTING_OPTIONS[sortBy].fieldname],
      [CHATS_SORTING_OPTIONS[sortBy].direction],
    );

    // Display the latest chats in the dropdown menu
    // If mobile device display the latest 3 chats,
    // and if tablet or desktop then display up to 5 of the latest chats
    return chatsData.slice(0, windowWidth > 575 ? 5 : 3);
  }, [chatsContext.existingUserChats, sortBy, windowWidth]);

  /*===============================
    MARK CHAT AS READ
  ================================*/
  const handleChatSelection = (chatID: number) => {
    // If there are no existing user chats, prevent any selection
    if (!chatsContext.existingUserChats || !chatsContext.existingUserChats.length) return;

    // Mark the specific chat as read in the UI
    const targetedChatIndex: number = chatsContext.existingUserChats.findIndex(chat => {
      return chat.id === chatID;
    });

    if (targetedChatIndex < 0) return;

    // Open a chat panel for the selected chat
    chatsContext.handleOpenChat({
      id: chatsContext.existingUserChats[targetedChatIndex].id,
      chat_id: chatsContext.existingUserChats[targetedChatIndex].id,
      application_id: null,
      is_blocked: chatsContext.existingUserChats[targetedChatIndex].is_blocked,
      is_new_chat: false,
      applicant: {
        id: chatsContext.existingUserChats[targetedChatIndex].applicant.id,
        name: chatsContext.existingUserChats[targetedChatIndex].applicant.full_name,
        photo: chatsContext.existingUserChats[targetedChatIndex].applicant.photo,
      },
      is_minimized: false,
    });

    // Close the dropdown menu after a chat was opened
    setIsDropdownOpen(false);
  };

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div
      className="p--relative"
      ref={dropdownRef}
      tabIndex={0}
      data-testid="component:dropdown-header-chat-messages"
    >
      <Tooltip
        text="Messages"
        positioning="bottom"
        modifierClass={`chat-dropdown__icon ${isDropdownOpen ? "d-none" : ""}`}
      >
        <div className="header__icon" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <SmsIcon />

          {chatsContext.unreadChatsCount > 0 ? (
            <span className="notifications-counter">{chatsContext.unreadChatsCount}</span>
          ) : null}
        </div>
      </Tooltip>

      <AnimatePresence>
        {isDropdownOpen ? (
          <motion.div
            key="framer-header-chat-dropdown"
            className="chat-dropdown"
            variants={FRAMER_HEADER_ANIMATION_WITH_OFFSET}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={FRAMER_HEADER_TRANSITIONS}
          >
            {/* HEADER */}
            <div className="chat-dropdown__header">
              <div className="d-flex justify-content-between align-items-center">
                <h5>
                  <strong>
                    Hi, {user.first_name ?? <Loader size="xs" modifierWrapper="ml--5" />}!
                  </strong>
                </h5>

                {chatsContext.unreadChatsCount > 0 ? (
                  <div className="chat-dropdown__unread-badge">
                    {chatsContext.unreadChatsCount} unread{" "}
                    {chatsContext.unreadChatsCount === 1 ? "message" : "messages"}
                  </div>
                ) : null}
              </div>
              <ChatMessagesSort
                sortBy={sortBy}
                handleChatMessagesSorting={sort => setSortBy(sort)}
              />
            </div>

            <div>
              {chatsContext.existingUserChatsLoading ? (
                [...Array(windowWidth > 575 ? 5 : 3).keys()].map((_, skeletonIndex: number) => (
                  <SkeletonChatMessageListItem
                    key={`skeleton-chat-messages-dropdown-${skeletonIndex}`}
                  />
                ))
              ) : CHATS.length > 0 ? (
                CHATS.map((chat: ChatsForSpecificUserResponseFields) => (
                  <ChatMessageListItem
                    key={chat.id}
                    id={chat.id}
                    photo={chat.applicant.photo}
                    full_name={chat.applicant.full_name}
                    last_message={chat.last_message.content}
                    timestamp={chat.last_message.timestamp * 1000}
                    is_read={chat.last_message.is_read}
                    is_blocked={chat.is_blocked}
                    hasActionsMenu
                    handleChatMessageListItem={handleChatSelection}
                  />
                ))
              ) : (
                <div className="chat-dropdown__no-conversations">
                  <h6 className="txt--center">No conversations found.</h6>
                </div>
              )}
            </div>

            {/* FOOTER */}
            <div className="chat-dropdown__footer">
              <Link to="/chats/messages/" className="txt--link">
                See All
              </Link>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default ChatMessagesDropdown;
