// Interfaces
import { AssessmentProgressBoxProps } from "./interfaces";
import ProgressBar from "../Progress/ProgressBar";

const AssessmentProgressBox = ({ data, modifierClass }: AssessmentProgressBoxProps) => {
  return (
    <>
      <div className={`box box--padding-xs bg--gray ${modifierClass}`}>
        <ProgressBar data={data} />
      </div>

      {data.footer_description && (
        <p className={`mt--10 progress-bar-description progress-bar-description--${data.color}`}>
          <span className="progress-bar-description__range">{data.footer_range}</span>
          {data.footer_description}
        </p>
      )}
    </>
  );
};

export default AssessmentProgressBox;
