// Utilities & Hooks
import { useAuth } from "../../providers/auth-context";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";
import handleErrorMessage from "../../utilities/handleErrorMessage";

// Interfaces
import {
  AdminCompaniesAllFormsResponseFields,
  AdminCompaniesDropdownFields,
  AdminCompaniesListResponseFields,
} from "./interfaces";

/**
 *
 * Get the list of ALL existing companies
 *
 */
export const useAdminGetAllCompanies = () => {
  return useQuery(
    ["companies-admin"],
    async () => {
      return (await fetchHandler("GET", "admin/company")) as AdminCompaniesListResponseFields[];
    },
    { onError: error => toast.error(handleErrorMessage(error)) },
  );
};

/**
 *
 * Get a list of all forms for the currently active company.
 *
 */
export const useAdminGetAllCompanyForms = () => {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(["companies-admin-forms", companySlug], async () => {
    return (await fetchHandler(
      "GET",
      `admin/company/${companySlug}/forms`,
    )) as AdminCompaniesAllFormsResponseFields[];
  });
};

/**
 *
 * Get a list of all companies for the internal report company dropdown
 *
 */
export const useAdminGetAllCompaniesDropdown = () => {
  return useQuery(["companies-admin-dropdown"], async () => {
    return (await fetchHandler("GET", `admin/company/dropdown`)) as AdminCompaniesDropdownFields[];
  });
};
