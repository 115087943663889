// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  useApplicationsMoveToBucket,
  useApplicationsUndoBucketMovement,
} from "../../../api/Applications/Applications";
import { useBucketsGetAll } from "../../../api/Buckets/Buckets";
import { orderBuckets } from "./orderBuckets";
import useFindTargetedBucket from "./useFindTargetedBucket";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Components
import DropdownSearchable from "../../Dropdown/DropdownSearchable";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { ApplicationBucketMovementProps, ApplicationBucketPreUpdateDetails } from "../interfaces";

/**
 *
 * This component will be used to:
 * - fetch the list of existing buckets,
 * - populate the dropdown menu with bucket items
 * - handle moving applications from one bucket to another
 * - and also can be used to handle moving applications to
 *   specific bucket in `bulk`
 *
 */
const ApplicationBucketMovement = ({
  id,
  title,
  name = "",
  preselectedBucket = "",
  isDisabled = false,
  dropdownSizeModifier = "sm",
  handleApplicationsResetSelection,
}: ApplicationBucketMovementProps) => {
  const [handleFindTargetedBucket] = useFindTargetedBucket();
  const errorReporting = useErrorReporting();

  /*========================
    PRE-UPDATE DETAILS

    These will be used  to handle "Undo" operations
    containing the necessary bucket informations 
    before the bucket movement action was triggered.
  =========================*/
  const [preUpdateDetails, setPreUpdateDetails] = useState<ApplicationBucketPreUpdateDetails>({
    bucketName: null,
    bucketID: null,
  });

  useEffect(() => {
    if (!preselectedBucket) return;

    // Extract the bucket name pre-update
    const bucketName: string = preselectedBucket;
    const bucketID: number | undefined = handleFindTargetedBucket(preselectedBucket);

    // Exit function if the bucket cannot be found in the list
    if (!bucketID) return;

    setPreUpdateDetails({ bucketName, bucketID });
  }, [preselectedBucket]);

  /*=================================
    FETCH & MAP THE LIST OF BUCKETS
  =================================*/
  const { data: buckets, isLoading: bucketsLoading } = useBucketsGetAll();

  const BUCKETS_DROPDOWN_ITEMS = useMemo(() => {
    // Exit function if there are no buckets available
    if (!buckets || !buckets.length || bucketsLoading) return [];

    return orderBuckets(buckets).filter(
      bucket => bucket.text.toLowerCase() !== preselectedBucket.toLowerCase(),
    );
  }, [buckets, preselectedBucket]);

  /*=================================
    PRE-SELECT THE BUCKET

    Based on the received "preselectedBucket" value,
    which represents the bucket's name, find the matching bucket
    from the list of fetched and mapped buckets dropdown items,
    and extract the ID of the matched bucket, which will then be used
    to pre-select the dropdown item corresponding to that bucket
  =================================*/
  const [preselectedBucketValue, setPreselectedBucketValue] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!preselectedBucket) return;

    // Find the bucket with the matching name from the
    // list of mapped bucket dropdown items
    const matchingBucket = BUCKETS_DROPDOWN_ITEMS.find(bucket => {
      return bucket.text.toLowerCase() === preselectedBucket.toLowerCase();
    });

    if (matchingBucket) setPreselectedBucketValue(matchingBucket.value as number);
  }, [preselectedBucket]);

  /*=================================
    MOVE THE SELECTED APPLICATION(S)
    TO THE SPECIFIED BUCKET
  =================================*/
  const [bucketDropdownKey, setBucketDropdownKey] = useState<string>(title);
  const moveApplication = useApplicationsMoveToBucket();

  const handleApplicationMoveToBucket = async (bucket: DropdownItem) => {
    const bucket_name: string = bucket.text as string;
    const bucket_id: number = bucket.value as number;

    try {
      // Display notification to the user with a possible "Undo" action
      toast.success(
        <div className="Toastify__undo-notification">
          <strong>{name}</strong> moved to:
          <br />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <strong>{bucket_name}</strong>
            </span>

            {preUpdateDetails.bucketID ? (
              <span
                className="Toastify__undo-notification__btn"
                onClick={handleApplicationUndoBucketMovement}
              >
                Undo
              </span>
            ) : null}
          </div>
        </div>,
        {
          toastId: "buckets-movement",
        },
      );

      // Update application's bucket
      await moveApplication.mutateAsync({
        bucket_name,
        bucket_id,
        application_ids: [id],
      });

      // We clear out the "key" state when the request has finished successfully
      setBucketDropdownKey("");

      // We reset the state back to the initially received "title" prop value,
      // so we can reset the "key" and trigger a rerender of the component,
      // which in itself will reset the component's internal state
      setTimeout(() => setBucketDropdownKey(title), 100);

      // Clear out the selected applications
      handleApplicationsResetSelection();
    } catch (error) {
      // Dismiss the matching success notification from the UI
      toast.dismiss("buckets-movement");

      errorReporting("Failed moving application to specific bucket", error, {
        application_id: id,
        bucket_id,
        bucket_name,
      });
    }
  };

  /*=================================
    UNDO APPLICATION BUCKET ACTION
  =================================*/
  const undoBucketMovement = useApplicationsUndoBucketMovement();

  const handleApplicationUndoBucketMovement = async () => {
    // Exit function if there's no valid pre-update bucket ID value
    if (!preUpdateDetails.bucketID) return;

    try {
      // Revert application to its previous bucket
      await undoBucketMovement.mutateAsync({
        bucket_id: preUpdateDetails.bucketID,
        application_ids: [id],
      });
    } catch (error) {
      errorReporting("Failed reverting application bucket changes", error, {
        application_id: id,
        bucket_id: preUpdateDetails.bucketID,
      });
    }
  };

  return (
    <DropdownSearchable
      key={`application-bucket-movement-${bucketDropdownKey}`}
      placeholder={title}
      size={dropdownSizeModifier}
      items={BUCKETS_DROPDOWN_ITEMS}
      preselectedItemValue={preselectedBucketValue}
      handleItemSelected={handleApplicationMoveToBucket}
      disabled={!id || isDisabled}
    />
  );
};

export default ApplicationBucketMovement;
