import { format } from "date-fns";
import {
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";

export const INITIAL_INTERVIEW_REQUEST: EmailTemplateModel = {
  name: "initial-interview-request",
  label: "Initial Interview Request",
  subject: (details: EmailTemplateDetailsModel) =>
    `Re: Your Job Application dated ${format(new Date(details.applicationDate), "MM/dd/yyyy")}`,
  html: (details: EmailTemplateDetailsModel) => `
<p>Dear <span style="color:orangered;">${details.applicantName}</span>,</p>
        <br />
<p>Thank you for your interest in applying for the position of <span style="color:orangered;">${details.applicationPosition}</span>. We would like to talk with you personally and to learn more about your professional qualifications.</p> 
    <br />
<p style="color:orangered;">[DATE OF INTERVIEW]</p>
<p style="color:orangered;">[TIME OF INTERVIEW]</p>
<p style="color:orangered;">[PLACE OF INTERVIEW OR HIRING INTERVIEWER TO CALL APPLICANT PHONE NUMBER...]</p>
    <br />
<p>Your interview will be with <span style="color:orangered;">[NAME AND JOB TITLE OF INTERVIEWER(S)]</span>. The interview is scheduled to last approximately <span style="color:orangered;">[LENGTH OF INTERVIEW]</span> and will take the form of <span style="color:orangered;">[SHORT DESCRIPTION OF FORMAT OF INTERVIEW SUCH AS PANEL, GROUP INTERVIEW, ETC...]</span>. Please contact <span style="color:orangered;">[NAME]</span> at <span style="color:orangered;">[TELEPHONE NUMBER]</span> to confirm your availability.</p> 
    <br />
<p>If you have any questions, please let <span style="color:orangered;">[CONTACT]</span> know.</p>
    <br />
<p>We are looking forward to discussing this job opportunity with you.</p>
    <br />
<p>Sincerely,</p>
<p style="color:orangered;">${details.senderName}</p>
<p style="color:orangered;">[TITLE]</p>`,
};
