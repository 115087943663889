// Hooks
import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "../../hooks/useIsInViewport";

// Interfaces
import { ProgressCircleProps } from "./interfaces";

// Assets
import CaregiverIcon from "../../assets/images/icons/sbca-caregiver-icon.svg?react";
import ThinkerIcon from "../../assets/images/icons/sbca-thinker-icon.svg?react";
import ProcessorIcon from "../../assets/images/icons/sbca-processor-icon.svg?react";

// Statics
const SBCA_INDICATOR_ICONS = {
  caregiver: <CaregiverIcon />,
  thinker: <ThinkerIcon />,
  processor: <ProcessorIcon />,
};

const ProgressCircle = ({
  type,
  size,
  strokeWidth = 7,
  percentage,
  color,
  modifierClass = "",
}: ProgressCircleProps) => {
  const progressCircleRef = useRef(null);

  // Check if progress bar is in viewport
  const isInViewport = useIsInViewport(progressCircleRef);

  // Set the progress circle initial state
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isInViewport) return;
    setProgress(percentage);
  }, [isInViewport]);

  // Calculate the necessary data for the svg elements
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <div
      ref={progressCircleRef}
      className={`progress progress-circle progress-circle--${color} ${modifierClass}`}
    >
      <div className="progress-circle__icon">{SBCA_INDICATOR_ICONS[type]}</div>

      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={viewBox}>
        <circle
          fill="none"
          stroke="#D3D3D3"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          className="progress-circle__background"
        />

        <circle
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={`${dash} ${circumference - dash}`}
          className="progress-circle__progress"
        />
      </svg>
    </div>
  );
};
export default ProgressCircle;
