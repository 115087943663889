// Hooks
import { useState } from "react";
import { useJobAdsGetLatest } from "../../api/JobAds/JobAds";

// Components
import JobAdsLatestCard from "../../components/JobAds/JobAdsLatestCard";
import JobAdHeader from "../../components/JobAds/JobAdHeader";
import JobAdsOverviewSkeleton from "./Skeleton/JobAdsOverviewSkeleton";
import JobAdsOverviewSearch from "../../components/JobAds/JobAdsOverviewSearch";

const AdsOverview = () => {
  /*============================
    FETCH LATEST ADS DATA
  =============================*/
  const { data, isLoading, isRefetching } = useJobAdsGetLatest();

  /*============================
    OVERVIEW SEARCHING
  =============================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  return (
    <div className="job-ads">
      <JobAdHeader handleSearch={(searched: string) => setSearchedValue(searched)} />

      <div className="container py--25">
        {isLoading || isRefetching ? (
          <JobAdsOverviewSkeleton />
        ) : (
          <>
            {searchedValue ? (
              <JobAdsOverviewSearch searchedValue={searchedValue} />
            ) : (
              <>
                <JobAdsLatestCard
                  adGroupStatus="new"
                  count={data?.new.count ?? 0}
                  ads={data?.new.ads ?? []}
                />

                <JobAdsLatestCard
                  adGroupStatus="pending"
                  count={data?.pending.count ?? 0}
                  ads={data?.pending.ads ?? []}
                />

                <JobAdsLatestCard
                  adGroupStatus="ready_to_post"
                  count={data?.ready_to_post.count ?? 0}
                  ads={data?.ready_to_post.ads ?? []}
                />

                <JobAdsLatestCard
                  adGroupStatus="active"
                  count={data?.active.count ?? 0}
                  ads={data?.active.ads ?? []}
                />

                <JobAdsLatestCard
                  adGroupStatus="takedown"
                  count={data?.takedown.count ?? 0}
                  ads={data?.takedown.ads ?? []}
                />

                <JobAdsLatestCard
                  adGroupStatus="expired"
                  count={data?.expired.count ?? 0}
                  ads={data?.expired.ads ?? []}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdsOverview;
