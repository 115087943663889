import { format } from "date-fns";
import {
  AppointmentInPersonType,
  AppointmentVideoType,
} from "../../../api/Appointments/interfaces";

/**
 *
 * Utility function for handling how the appointment time is displayed in the UI.
 *
 * If the type of the appointment is `video` then the local time is displayed,
 * based on the received appointment's `appointment_timestamp` value.
 *
 * Otherwise the originally scheduled appointment time is displayed.
 *
 * @param time The received appointment time value from either `appointment_time` or `appointment_timestamp` field.
 * @param type The type of the scheduled appointment, either `video` or `in-person`
 *
 * @returns Formatted date to be displayed in the UI.
 */
export function handleAppointmentDisplayedTime(
  time: string | number,
  type: AppointmentVideoType | AppointmentInPersonType,
  dateFormat: string = "MM/dd/yyyy @ hh:mm a",
) {
  if (type === "video") {
    // Exit function if received value was not a timestamp
    if (typeof time !== "number") return "N/A";

    // Display the local time based on the received timestamp
    const localTime: Date = new Date(time * 1000);
    const formattedLocalTime: string = format(localTime, dateFormat);

    return formattedLocalTime;
  } else {
    return format(new Date(time), dateFormat);
  }
}
