import { Link } from "react-router-dom";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import Tooltip from "../Tooltip/Tooltip";
import CareerPagesIcon from "../../assets/images/icons/career-pages-icon.svg?react";
import { useAuth } from "../../providers/auth-context";

const HeaderCareerPages = () => {
  const { user } = useAuth();

  const hasCareerPageEditingPermission = handlePermissionCheck(["career_page_edit"]);

  return (
    <Tooltip text="Career Page" positioning="bottom" size="md">
      {hasCareerPageEditingPermission ? (
        <Link to="/career/pages/" title="Career Page" className="header__icon">
          <CareerPagesIcon />
        </Link>
      ) : user.active_company.is_career_page_enabled ? (
        <a
          className="header__icon"
          href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${user.active_company.slug}`}
          target="_blank"
          rel="noopener"
        >
          <CareerPagesIcon />
        </a>
      ) : (
        <span className="header__icon header__icon--disabled" aria-disabled="true">
          <CareerPagesIcon />
        </span>
      )}
    </Tooltip>
  );
};

export default HeaderCareerPages;
