// Utilities
import { getIn } from "formik";

// Components
import Datepicker from "../Datepicker/Datepicker";

// Interfaces
import { FormDatetimePickerProps } from "./interfaces";

const FormDatetimePicker = ({
  form,
  field,
  label,
  placeholder = "mm/dd/yyyy",
  dateFormat = "mm/dd/yyyy",
  enableTime = false,
  preselectedDate = null,
  minDate = "today",
  maxDate = "none",
  modifierClass = "",
}: FormDatetimePickerProps) => {
  /*===============================
    HANDLE FORM FIELD ERRORS
  ================================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    HANDLE FORM FIELD VALUE
  ================================*/
  const handleUpdateFormFieldValue = (dates: Date[]) => {
    form.setFieldValue(field.name, new Date(dates[0]));
  };

  return (
    <>
      <label className="dropdown__label">{label}</label>
      <Datepicker
        modifierClass={`${modifierClass} ${errors && touched ? "datepicker--error" : ""}`}
        enableTime={enableTime}
        dateFormat={dateFormat}
        placeholder={placeholder}
        preselectedDate={preselectedDate}
        minDate={minDate}
        maxDate={maxDate}
        handleSelectedDate={handleUpdateFormFieldValue}
      />

      {errors && touched ? <div className="input__error mb--15i">{errors}</div> : null}
    </>
  );
};

export default FormDatetimePicker;
