// Components
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import Datepicker from "../../components/Datepicker/Datepicker";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";

// Hooks & Utilities
import { useMemo, useState } from "react";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import { useReportGetApplicantCount } from "../../api/Reports/Reports";
import { format, subDays } from "date-fns";
import { createColumnHelper } from "@tanstack/react-table";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";

// Interfaces & Constants
import { ReportApplicantCountResponseFields } from "../../api/Reports/interfaces";
import { REPORTS_APPLICANT_COUNT_DOCUMENT_COLUMNS } from "./constants";

const ApplicantCount = () => {
  /*===============================================
    DATE RANGE SELECTION

    The date range is handled using the custom hook
    which handles the 'preview dates' and the 
    'active dates'.
  ================================================*/
  // Call designated Report Date Range Handling hook with desired initial values
  const { pickerDates, filterDates, setFilterDates, handleStartDate, handleEndDate } =
    useReportDateRangeHandling({
      initial_start_date: format(subDays(Date.now(), 7), "MM/dd/yyyy"),
      initial_end_date: format(Date.now(), "MM/dd/yyyy"),
    });

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportApplicantCountResponseFields>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("label", {
      header: () => <span>Source</span>,
      size: 150,
      enableSorting: false,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("applications", {
      header: () => <span>Applications</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("sbcas", {
      header: () => <span>SBCAs</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("completion_ratio", {
      header: () => <span>Completion Ratio</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("current", {
      header: () => <span>Current</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("favorites", {
      header: () => <span>Favorites</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("interviewed", {
      header: () => <span>Interviewed</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("offer_pending", {
      header: () => <span>Offer Pending</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("hired", {
      header: () => <span>Hired</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),

    COLUMN_HELPER.accessor("archived", {
      header: () => <span>Archived</span>,
      size: 50,
      cell: data => <span>{data.getValue()}</span>,
    }),
  ];

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    isLoading: applicantCountLoading,
    isRefetching: applicantCountRefetching,
    isFetching: applicantCountFetching,
    data: applicantCountData,
  } = useReportGetApplicantCount(filterDates);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<ReportApplicantCountResponseFields[]>(
    [],
  );

  /*============================================
    REPORT TABLE DATA

    Remap received fetch data & turn 'completion_ratio'
    field into a number value so it get's proper 
    sorting treatment in the table component.
  =============================================*/
  const REPORT_TABLE_DATA = useMemo(() => {
    const data = applicantCountData ?? [];

    return data.map(entity => {
      return {
        label: entity.label,
        applications: entity?.applications || 0,
        sbcas: entity?.sbcas || 0,
        completion_ratio: `${entity?.completion_ratio || 0}%`,
        current: entity?.current || 0,
        favorites: entity?.favorites || 0,
        interviewed: entity?.interviewed || 0,
        offer_pending: entity?.offer_pending || 0,
        hired: entity?.hired || 0,
        archived: entity?.archived || 0,
      };
    });
  }, [applicantCountData]);

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">Applicant Count Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || undefined}
                    dateFormat="m/d/Y"
                    isDisabled={applicantCountLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">End Date:</div>
                </div>
                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={applicantCountLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-2 col-md-4 d-flex flex-column align-items-xl-start justify-content-md-end align-items-end pr--0 pr--md--15 pb--15 pb--sm--20 ">
              <Button
                modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                onClick={() => setFilterDates(pickerDates)}
                isLoading={applicantCountLoading || applicantCountFetching}
                isDisabled={applicantCountFetching || applicantCountLoading}
              >
                Run Report
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <div className="px--30">
        {!applicantCountLoading ? (
          <>
            <Card modifierClass="card--padding--xl">
              <h5 className="fw--semibold">
                Currently Viewing: {filterDates.start_date} through {filterDates.end_date}
              </h5>
            </Card>

            <Card>
              <h3 className="fw--semibold mb--5 px--20 pt--10">Export Data</h3>
              <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                  onClick={() =>
                    handleInitiateSpreadsheetGenerator(
                      exportedTableData,
                      "applicant_count_report",
                      REPORTS_APPLICANT_COUNT_DOCUMENT_COLUMNS,
                      "default-csv",
                      `${filterDates.start_date} to ${filterDates.end_date}`,
                    )
                  }
                  isDisabled={Boolean(!exportedTableData.length) || applicantCountFetching}
                >
                  CSV
                </Button>

                <Button
                  modifierClass="btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0"
                  onClick={() =>
                    handleInitiatePDFWorker({
                      columns: REPORTS_APPLICANT_COUNT_DOCUMENT_COLUMNS,
                      data: exportedTableData,
                      documentTitle: "FirstChoice Hiring - Applicant Count Report",
                      documentSubTitle: `Data Date: ${filterDates.start_date} to ${filterDates.end_date}`,
                      template: "default-report",
                      fileName: "applicant_count_report",
                      setPDFLoading: setPDFLoading,
                    })
                  }
                  isDisabled={!exportedTableData.length || applicantCountFetching || PDFLoading}
                  isLoading={PDFLoading}
                >
                  PDF
                </Button>
              </div>

              <Table
                data={REPORT_TABLE_DATA}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={applicantCountRefetching}
                shouldShowSummarizedData={true}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
                paginationPageSize={20}
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicantCount;
