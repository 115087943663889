import { useEffect, useState } from "react";
import {
  ApplicantAIResumeSummary,
  ApplicantAISbcaSummary,
} from "../../api/Applications/interfaces";
import Accordion from "../../components/Accordion/Accordion";
import MarkdownWrapper from "../../components/Markdown/Markdown";
import { useTour } from "../../providers/tour-context";

interface ApplicationGeneratedInterviewQuestionsProps {
  ai_sbca_summary: ApplicantAISbcaSummary;
  ai_resume_summary: ApplicantAIResumeSummary;
}

function ApplicationGeneratedInterviewQuestions({
  ai_sbca_summary,
  ai_resume_summary,
}: ApplicationGeneratedInterviewQuestionsProps) {
  /*====================================
    AI SUMMARY TOUR
  =====================================*/
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { tourCurrentStepName, isTourRunning, activeTour, handleResumeTour, handlePauseTour } =
    useTour();

  useEffect(() => {
    // Exit if theres no ongoing tour
    if (!isTourRunning || !activeTour || activeTour.tourId !== "applicants-ai-summary") return;

    // Toggle the state of the accordion based on the current step of the tour
    const correctStepCheck = tourCurrentStepName === "applicants-ai-summary interview questions";
    if (correctStepCheck && !isAccordionOpen) setIsAccordionOpen(true);
    if (!correctStepCheck && isAccordionOpen && window.innerWidth > 575) setIsAccordionOpen(false);

    /*================================
      Mobile only
    ================================*/
    if (window.innerWidth <= 575) {
      if (tourCurrentStepName === "applicants-ai-summary improved details page") {
        setIsAccordionOpen(false);
      }

      // Only scroll to the top of the element if we are on the correct step
      if (correctStepCheck) {
        const accordionElement = document.querySelector(
          "#accordion-interview-questions",
        ) as HTMLDivElement;
        window.scrollTo({ top: accordionElement.offsetTop });
      }
    }
  }, [isAccordionOpen, tourCurrentStepName, isTourRunning]);

  // Render fallback content if there are neither SBCA nor Resume questions
  if (!ai_sbca_summary.sbca_interview_questions && !ai_resume_summary.resume_interview_questions) {
    return (
      <Accordion
        id="accordion-interview-questions"
        title="Interview Questions"
        modifierClass="accordion--rounded"
      >
        <p className="accordion__empty-message">No interview questions at this time</p>
      </Accordion>
    );
  }

  return (
    <Accordion
      id="accordion-interview-questions"
      title="Interview Questions"
      modifierClass="accordion--rounded"
      isOpenByDefault={isAccordionOpen}
      handleCallbackOnAnimationStart={() => {
        if (window.innerWidth <= 575 && !isAccordionOpen) return;
        handlePauseTour();
      }}
      handleCallbackOnAnimationEnd={() => handleResumeTour()}
      duration={activeTour && window.innerWidth > 575 ? 300 : 0}
    >
      <h5 className="mb--10 fw--semibold">Resume Questions</h5>
      {ai_resume_summary.resume_interview_questions ? (
        <MarkdownWrapper markdown={ai_resume_summary.resume_interview_questions} />
      ) : (
        <p className="white-space--pre-line mb--20">No resume questions available.</p>
      )}

      <h5 className="mb--10 fw--semibold">SBCA Questions</h5>
      {ai_sbca_summary.sbca_interview_questions ? (
        <MarkdownWrapper markdown={ai_sbca_summary.sbca_interview_questions} />
      ) : (
        <p className="white-space--pre-line mb--10">No SBCA questions available.</p>
      )}
    </Accordion>
  );
}

export default ApplicationGeneratedInterviewQuestions;
