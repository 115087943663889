// Utilities & Hooks
import { useState, useRef, useMemo, useEffect } from "react";
import { matchSorter } from "match-sorter";
import useDebounce from "../../hooks/useDebounce";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Interfaces
import { DropdownItem, DropdownMultiselectProps } from "./interfaces";

// Assets
import { FaChevronDown as ChevronIcon } from "react-icons/fa";
import { MdClear as ClearIcon } from "react-icons/md";

// Components
import Tooltip from "../Tooltip/Tooltip";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import Loader from "../Loader/Loader";
import { motion, AnimatePresence } from "framer-motion";

// Constants
import {
  FRAMER_DROPDOWN_ANIMATION,
  FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION,
} from "../../constants/framer";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";

const DropdownMultiselect = ({
  items,
  placeholder,
  handleSelectedItems,

  preselectedItems = [],
  searchable = false,
  searchDebounce = 500,
  clearable = false,
  label = "",
  disabled = false,
  isLoading = false,
  size = "md",
  modifierClass = "",
  orientation = "bottom",
  maxScrollableHeight = "300px",
  framerAnimationCustomProps = null,
  isRequired = false,
}: DropdownMultiselectProps) => {
  /*===========================
   SAVE & MARK SELECTED ITEMS
  ============================*/
  const [selectedItemsValues, setSelectedItemsValues] = useState<any[]>(preselectedItems);
  const [selectedItemsText, setSelectedItemsText] = useState<string>("");

  // Anytime the list of pre-selected items is updated, or
  // the list of items fetched from an API, trigger an update
  // to the local state too
  useEffect(() => {
    if (!preselectedItems.length || !items.length) return;

    // Preselect the items
    setSelectedItemsValues(preselectedItems);
  }, [preselectedItems, items]);

  // If there are no pre-selected items at all, clear out the inner state
  // for currently selected items and displayed text.
  // Example use case for this is when we clear out the selection to be passed down to this component
  useEffect(() => {
    if (!preselectedItems.length) {
      setSelectedItemsText("");
      setSelectedItemsValues([]);
    }
  }, [preselectedItems.length]);

  /*===========================
   DROPDOWN MENU STATE
  ============================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdownMenu = () => {
    // If the component is marked as 'disabled', prevent it from being opened
    if (disabled || isLoading) return;

    setIsDropdownOpen(!isDropdownOpen);
  };

  /*===========================
   DROPDOWN SEARCHING STATE
  ============================*/
  const [dropdownSearchedValue, setDropdownSearchedValue] = useState<string>("");
  const debouncedDropdownSearchValue: string = useDebounce(dropdownSearchedValue, searchDebounce);
  const dropdownSearchRef = useRef<HTMLInputElement | null>(null);

  const handleDropdownSearching = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDropdownSearchedValue(event.target.value);
  };

  const handleDropdownSearchingKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key === "Enter") {
      const trimmedValue: string = event.currentTarget.value.trim();

      // Find the dropdown item with the matching text value
      const matchingDropdownItem: DropdownItem | undefined = items.find(item => {
        return item.text.toLowerCase() === trimmedValue.toLowerCase();
      });

      // Handle the matching item's selection
      if (matchingDropdownItem) {
        handleDropdownItem(matchingDropdownItem);

        // Clear out the searched value
        setDropdownSearchedValue("");
      }
    }
  };

  // Focus on the input field when the dropdown menu is opened
  useEffect(() => {
    // If the dropdown is not searchable, or the reference does not exist, exit function
    if (!searchable || !dropdownSearchRef.current) return;

    if (isDropdownOpen) {
      dropdownSearchRef.current.focus();
    } else {
      dropdownSearchRef.current.blur();
    }
  }, [isDropdownOpen]);

  /*===========================
   DROPDOWN ITEMS STATE
  ============================*/
  const dropdownItems: DropdownItem[] = useMemo(() => {
    // Return a default empty array if there's no items available
    if (!items.length) return [];

    // Search trough the list of dropdown items and return
    // only those matching the searched value
    let filteredDropdownItems: DropdownItem[] = [...items];

    if (debouncedDropdownSearchValue) {
      filteredDropdownItems = matchSorter(filteredDropdownItems, debouncedDropdownSearchValue, {
        keys: ["text"],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    // Sort the selected items to always be first
    if (selectedItemsValues.length) {
      filteredDropdownItems.sort(a => {
        // If the item's value is already part of the selected items values,
        // then we put this item to the top of the list, and this part is then sorted on
        // the selected values array on its own
        return selectedItemsValues.some(item => item === a.value) ? -1 : 1;
      });
    }
    return filteredDropdownItems;
  }, [items, selectedItemsValues, debouncedDropdownSearchValue]);

  /*================================
   HANDLE DROPDOWN ITEM SELECTION
  ================================*/
  const handleDropdownItem = (item: DropdownItem) => {
    // Prevent functionality if the item is marked as "disabled"
    if (item.disabled) return;

    // Focus on the input field
    if (dropdownSearchRef.current) dropdownSearchRef.current.focus();

    // If item does not exist - Add it to the list of selected items
    // If item already exists - Remove it from the list of selected items
    const selectedItemsValuesCopy: any[] = [...selectedItemsValues];

    // Check if the item is already selected
    const selectedItemIndex: number = selectedItemsValuesCopy.findIndex(selectedItem => {
      return selectedItem === item.value;
    });

    if (selectedItemIndex >= 0) {
      // Item already exists, remove it from array
      selectedItemsValuesCopy.splice(selectedItemIndex, 1);
    } else {
      // Item does not exist, add it to the array
      selectedItemsValuesCopy.push(item.value);
    }

    // Update the state of the selected items values,
    // which will also trigger updates to the displayed text values
    setSelectedItemsValues(selectedItemsValuesCopy);

    // Call the callback passed as prop
    handleSelectedItems(selectedItemsValuesCopy);
  };

  useEffect(() => {
    // Display the preselected items text in the dropdown's body
    const stringifiedSelectedItems: string = items
      .filter(item => selectedItemsValues.some(value => value == item.value))
      .map(item => item.text)
      .join(", ");

    setSelectedItemsText(stringifiedSelectedItems);
  }, [selectedItemsValues]);

  /*============================
   CLEAR SELECTION OF ITEMS
  ============================*/
  const handleClearSelection = (event: React.MouseEvent) => {
    event.stopPropagation();

    // Prevent clearing selection if the dropdown is marked as disabled
    if (disabled) return;

    setSelectedItemsText("");
    setSelectedItemsValues([]);
    handleSelectedItems([]);
  };

  /*============================
   CLOSE WHEN CLICKED OUTSIDE
  ============================*/
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*============================
   DROPDOWN CLASS NAMES
  ============================*/
  let DROPDOWN_CLASSNAME = `dropdown dropdown--${size}`;
  if (disabled) DROPDOWN_CLASSNAME += " dropdown--disabled";
  if (modifierClass) DROPDOWN_CLASSNAME += ` ${modifierClass}`;
  if (isDropdownOpen) DROPDOWN_CLASSNAME += " dropdown--active";

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div ref={dropdownRef} className={DROPDOWN_CLASSNAME} title={selectedItemsText} tabIndex={0}>
      {label && (
        <label
          htmlFor="dropdown"
          className={`dropdown__label ${isRequired ? "dropdown__label--required" : ""}`}
        >
          {label}
        </label>
      )}

      <div className="d-flex flex-column w--100">
        <div className="p--relative">
          <div
            className={`dropdown__body ${isDropdownOpen ? "dropdown__body--active" : ""}`}
            onClick={handleToggleDropdownMenu}
          >
            {selectedItemsText ? (
              <span className="dropdown__body__text">{selectedItemsText}</span>
            ) : (
              placeholder
            )}

            <div className="d-flex">
              {isLoading ? (
                <Loader size="sm" modifierWrapper="mr--10" />
              ) : (
                <>
                  {selectedItemsText.length > 0 && clearable && (
                    <div
                      className="dropdown__clear"
                      onClick={handleClearSelection}
                      data-testid="component-dropdown-multiselect:clear"
                    >
                      <Tooltip text="Clear Selection" size="md">
                        <ClearIcon />
                      </Tooltip>
                    </div>
                  )}
                  <ChevronIcon className="dropdown__chevron" />
                </>
              )}
            </div>
          </div>

          <AnimatePresence>
            {isDropdownOpen && (
              <motion.div
                className={`dropdown__content dropdown__content--orientation-${orientation}`}
                key="framer-dropdown-multiselect"
                variants={
                  orientation === "bottom"
                    ? FRAMER_DROPDOWN_ANIMATION
                    : FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION
                }
                initial="initial"
                animate="animate"
                exit="exit"
                custom={framerAnimationCustomProps}
                transition={{ duration: 0.5, type: "spring" }}
              >
                {searchable && (
                  <div className="dropdown__search">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="dropdown__search__input"
                      value={dropdownSearchedValue}
                      onChange={handleDropdownSearching}
                      onKeyUp={handleDropdownSearchingKeypress}
                      ref={dropdownSearchRef}
                    />
                  </div>
                )}

                <CustomScrollbars maxHeight={maxScrollableHeight}>
                  <ul>
                    {dropdownItems.length > 0 ? (
                      dropdownItems.map(item => {
                        let DROPDOWN_ITEM_CLASSNAME: string = "dropdown__item";
                        const selectedItemTextIndex: number = selectedItemsText
                          .split(", ")
                          .findIndex(selectedItem => {
                            return selectedItem.toLowerCase() === item.text.toLowerCase();
                          });

                        if (item.disabled) DROPDOWN_ITEM_CLASSNAME += " dropdown__item--disabled";

                        if (selectedItemTextIndex >= 0) {
                          DROPDOWN_ITEM_CLASSNAME += " dropdown__item--selected";
                        }

                        return (
                          <li
                            key={item.value}
                            className={DROPDOWN_ITEM_CLASSNAME}
                            onClick={() => handleDropdownItem(item)}
                            title={item.text}
                          >
                            {item.icon && <div className="dropdown__item__icon">{item.icon}</div>}
                            {item.text}
                          </li>
                        );
                      })
                    ) : (
                      <li className="dropdown__item">No item(s) found.</li>
                    )}
                  </ul>
                </CustomScrollbars>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default DropdownMultiselect;
