// Utilities & Hooks
import { Link, Params, useNavigate, useParams } from "react-router-dom";
import { useInvitationAccept, useInvitationGetInfo } from "../../api/Invitation/Invitation";
import useErrorReporting from "../../hooks/useErrorReporting";
import handleErrorMessage from "../../utilities/handleErrorMessage";

// Components
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

const UserInvitationAccept = () => {
  const navigate = useNavigate();
  const { invitationID }: Readonly<Params<string>> = useParams();
  const errorReporting = useErrorReporting();

  /*==============================
    INVITATION INFO
  ===============================*/
  const { data, isLoading, error } = useInvitationGetInfo(invitationID);

  /*==============================
    ACCEPT INVITATION
  ===============================*/
  const acceptInvitation = useInvitationAccept();

  const handleAcceptInvitation = async () => {
    // Exit function if there's no "invitationID" parameter available
    if (!invitationID) return;

    try {
      await acceptInvitation.mutateAsync(invitationID);

      // Redirect the user to the applications page after
      // they successfully accepted the invitation
      setTimeout(() => {
        navigate("/applications/", { replace: true });
      }, 5000);
    } catch (error) {
      errorReporting("Failed accepting invitation to company", error, { invitationID }, "critical");
    }
  };

  /*===================================
    HANDLE ERROR SCENARIO

    If the invitation link does not belong to
    the currently logged in user, handle that
    scenario and display the corresponding message
  =====================================*/
  if (error) {
    const errorMessage = handleErrorMessage(error);

    if (errorMessage.toLowerCase().includes("different user")) {
      return (
        <div className="error-pages">
          <div className="d-flex flex-column justify-content-center  align-items-center">
            <h3>The invitation link that you clicked belongs to a different user</h3>
            <p>Please login with the correct account to accept the invitaion.</p>
            <Link to="/" className="btn btn--fluid btn--primary">
              Go Back
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="error-pages">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3>Something went wrong!</h3>
            <p>There was an issue with the invitation link. Reload the page to try again.</p>
            <p>If the issue persists, please contact support.</p>
            <Button
              modifierClass="btn--fluid btn--primary"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          </div>
        </div>
      );
    }
  }

  return (
    // TODO: The classname will be updated as part of the refactoring process
    <div className="error-pages">
      {isLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <div className="d-flex justify-content-center flex-column align-items-center">
          {acceptInvitation.isSuccess ? (
            <>
              <h3>Invitation accepted!</h3>
              <p>You will be redirected shortly...</p>
            </>
          ) : (
            <>
              <h3>You have been invited to join: </h3>
              <h1>{data?.company || "N/A"}</h1>
              <p>Click the "Accept" button to complete your invitation</p>

              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary"
                onClick={handleAcceptInvitation}
                isLoading={acceptInvitation.isLoading}
                isDisabled={acceptInvitation.isLoading}
              >
                Accept
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserInvitationAccept;
