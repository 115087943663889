// Utilities
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import Button from "../../Button/Button";

// Interfaces
import { ScheduleMeetNowProps } from "./interfaces";

const MeetNow = ({ meeting_link }: ScheduleMeetNowProps) => {
  const [accessLink, setAccessLink] = useState<string | null>(null);

  useEffect(() => {
    if (!meeting_link) return;

    const { host, protocol } = window.location;

    const invitationLink: string = `${protocol}//${host}/vidconf/join/${meeting_link}`;
    setAccessLink(invitationLink);
  }, [meeting_link]);

  /*=======================
    TRIGGER COPYING OF THE
    PROVIDED ACCESS LINK
  ========================*/
  const handleCopyLinkInvitation = async () => {
    const { host, protocol } = window.location;

    const invitationLink: string = `${protocol}//${host}/vidconf/join/${meeting_link}`;
    try {
      await navigator.clipboard.writeText(invitationLink);
      toast.info("Copied Access Link!");
    } catch (error) {
      toast.error("Failed copying Access Link!");
    }
  };

  return (
    <>
      <h5>
        <strong>Meet Now</strong>
      </h5>
      <hr />
      <p className="txt--black">Start an instant meeting in seconds.</p>

      <Button
        modifierClass="btn btn--fluid btn--fluid--md btn--pill btn--primary--dark mb--20"
        onClick={() => accessLink && window.open(accessLink, "_blank")}
        isDisabled={!accessLink}
      >
        Start a Meeting Now
      </Button>

      <h6 className="mb--10 fw--medium">FCH Video Meeting</h6>
      <p className="txt--black line-height--default">
        Please join the meeting from your computer, tablet or smartphone.
      </p>

      <p className="m--0 txt--black">Access link:</p>
      {accessLink ? (
        <a href={accessLink} target="_blank" className="applications-schedule-meeting__access-link">
          {accessLink}
        </a>
      ) : (
        <p>N/A</p>
      )}

      <Button
        modifierClass="btn--fluid btn--fluid--md btn--pill btn--transparent btn--border btn--border--primary"
        onClick={handleCopyLinkInvitation}
        isDisabled={!accessLink}
      >
        Copy Link Invitation
      </Button>
    </>
  );
};

export default MeetNow;
