// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  UsersListResponseFields,
  UserSpecificResponseFields,
  UsersEditRequestFields,
  UsersAddFormikFields,
  UsersResponseFields,
} from "./interfaces";
import { useAuth } from "../../providers/auth-context";

/**
 *
 * Get all users that exist within the currently selected
 * (active) company.
 *
 */
export function useUsersGet() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["users-active-company", companySlug],
    async () => {
      return (await fetchHandler("GET", `company/${companySlug}/users`)) as UsersListResponseFields;
    },
    {
      enabled: !!companySlug,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 *
 * Get a list of all the users that exist in the currently selected
 * active company. This endpoint has no specific permission requirements
 * and will be used for generating the lists in dropdown menues across the application
 * (e.g. when scheduling a video meeting, sending an application trough email, etc.)
 *
 *
 */
export function useUsersGetList() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["users-list", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/users/list`,
      )) as UsersListResponseFields;
    },
    {
      enabled: !!companySlug,
      onError: error => toast.error(handleErrorMessage(error)),
    },
  );
}

/**
 *
 * Get the details of the targeted user that belongs to the currently active company
 *
 *  TODO: Read the `companySlug` from within the hook.
 *
 * @param companySlug The logged in user's "active_company" slug value
 * @param userID The ID of the targeted user whose details we need to get
 */
export function useUsersGetSpecific(
  companySlug: string | null | undefined,
  userID: string | undefined,
) {
  return useQuery(
    ["users-active-company-user", companySlug, userID],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/users/${userID}`,
      )) as UserSpecificResponseFields;
    },
    { enabled: !!companySlug, onError: error => toast.error(handleErrorMessage(error)) },
  );
}

/**
 *
 * Add a new user to the company
 *
 */
export function useUsersAddToCompany() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    ({ email, group_id }: UsersAddFormikFields) => {
      return fetchHandler("POST", `company/${companySlug}/users`, { email, group_id });
    },
    {
      onSuccess: () => toast.success("Successfully added user to company!"),
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["users-active-company", companySlug]);
        queryClient.invalidateQueries(["users-list", companySlug]);
      },
    },
  );
}

/**
 *
 * Update the targeted user's details
 *
 */
export function useUsersEditSpecific() {
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    ({ userDetails, userId }: UsersEditRequestFields) => {
      return fetchHandler("PUT", `company/${companySlug}/users/${userId}`, userDetails);
    },
    {
      onMutate: ({ userDetails, userId }) => {
        // Get the data stored in react-query's cache for the user that is being edited
        const editedUserDetails = queryClient.getQueryData([
          "users-active-company-user",
          companySlug,
          userId,
        ]);

        // Optimistically update the details of the user that is being edited
        queryClient.setQueryData(["users-active-company-user", companySlug, userId], userDetails);

        // Show success notification
        toast.success("User details updated successfully!", {
          toastId: "users-edit-user",
        });

        // Return the cached data (before mutation) in the context provider so we can fallback to it
        return editedUserDetails;
      },
      onError: (error, { userId }, editedUserDetails) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("users-edit-user");

        queryClient.setQueryData(
          ["users-active-company-user", companySlug, userId],
          editedUserDetails,
        );
        return error;
      },
      onSettled: () => {
        // Invalidate the query used for listing all the users in the active company
        queryClient.invalidateQueries(["users-active-company"]);
      },
    },
  );
}

/**
 *
 * Delete the targeted user from the currently active company
 *
 */
export function useUsersRemoveSpecific() {
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (userId: number) => {
      return await fetchHandler("DELETE", `company/${companySlug}/users/${userId}`);
    },
    {
      onMutate: userId => {
        const previousUsersState = queryClient.getQueryData([
          "users-active-company",
          companySlug,
        ]) as UsersListResponseFields;

        // Filter out the cached data, removing the targeted user
        queryClient.setQueryData<UsersListResponseFields>(
          ["users-active-company", companySlug],
          users => {
            // Do not update the cached data if the "users" data is undefined
            if (!users) return;

            return users.filter((user: UsersResponseFields) => user.id !== userId);
          },
        );

        // Show notification in the UI
        toast.success("User removed successfully!", {
          toastId: "users-remove-user",
        });

        return previousUsersState;
      },
      onError: (error, _variables, previousUsersState) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("users-remove-user");

        queryClient.setQueryData(["users-active-company", companySlug], previousUsersState);
        return error;
      },
    },
  );
}
