import Button from "../../components/Button/Button";

const MaintenanceMode = () => {
  return (
    <div className="maintenance-mode">
      <h1>Scheduled Maintenance</h1>
      <p className="mb--0">
        Access to the application is currently unavailable due to a scheduled downtime.
      </p>
      <p className="mb--10">
        Please try again in <strong>10 minutes.</strong>
      </p>

      <Button
        modifierClass="btn--text btn--text--secondary btn--fluid mb--30"
        onClick={() => window.location.reload()}
      >
        Click here to reload the page
      </Button>

      <div className="maintenance-mode__contact">
        <div className="unauthenticated__form__contact">
          <p className="fw--semibold">Need Technical Support?</p>
          <a href="tel:+18774497595">Call us at (877) 449-7595</a>
        </div>

        <div className="unauthenticated__form__contact">
          <p className="fw--semibold">Need Sales Assistance?</p>
          <a href="tel:+18889906451">Call us at (888) 990-6451</a>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
