// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useJobAdsGetStatuses, useJobAdsUpdateStatus } from "../../api/JobAds/JobAds";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Table from "../Table/Table";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import Tooltip from "../Tooltip/Tooltip";

// Constants
import {
  JOB_ADS_DATE_FIELD_MAPPINGS,
  JOB_ADS_STATUS_COLUMN_NAME_MAPPINGS,
  JOB_ADS_STATUS_UPDATES_TEXT_MAPPINGS,
} from "../../pages/JobAds/constants";

// Interfaces
import {
  JobAdStatus,
  JobAdsLatestAd,
  JobAdsStatusesResponseFields,
} from "../../api/JobAds/interfaces";
import {
  JobAdsTableAdUpdateDetails,
  JobAdsTableMappedFields,
  JobAdsTableProps,
} from "./interfaces";

const JobAdsTable = ({
  ads,
  paginationSize,
  adGroupStatus = "all",
  count = undefined,
  isRefetching = false,
  shouldHandlePageRouteParameter = true,
  shouldHandleStatusesFetching = true,
}: JobAdsTableProps) => {
  const errorReporting = useErrorReporting();

  // Fetch the list of job ad statuses so it becomes
  // available for usage within the cache
  if (shouldHandleStatusesFetching) useJobAdsGetStatuses();

  /*============================
    TABLE COLUMNS
  =============================*/
  const COLUMNS_HELPER = createColumnHelper<JobAdsTableMappedFields>();

  const LATEST_JOB_ADS_COLUMNS = [
    COLUMNS_HELPER.accessor("job_code", {
      header: () => <span>Job Code</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
      size: 105,
    }),
    COLUMNS_HELPER.accessor("date", {
      header: () => <span>{JOB_ADS_STATUS_COLUMN_NAME_MAPPINGS[adGroupStatus]}</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
      size: 90,
    }),
    COLUMNS_HELPER.accessor("age", {
      header: () => <span>Age</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
      size: 120,
    }),
    COLUMNS_HELPER.accessor("client_account", {
      header: () => <span>Client Account</span>,
      cell: data => (
        <Link to={`/job-ads/clients/${data.row.original.slug}/`}>{data.getValue()}</Link>
      ),
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("job_title", {
      header: () => <span>Job Title</span>,
      cell: data => <Link to={`/job-ads/ads/${data.row.original.ad_id}/`}>{data.getValue()}</Link>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("status", {
      header: () => <span>Ad Status</span>,
      cell: data => (
        <span className="txt--capitalize">
          {data.getValue().split("_").join(" ")}{" "}
          {data.row.original.tracking.status && data.row.original.tracking.tracker ? (
            <>
              <br />({data.row.original.tracking.tracker})
            </>
          ) : (
            ""
          )}
        </span>
      ),
      enableSorting: false,
      size: 90,
    }),
    COLUMNS_HELPER.accessor("type", {
      header: () => <span>Ad Type</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
      size: 90,
    }),
    COLUMNS_HELPER.display({
      header: () => <span>Job Boards</span>,
      cell: data => (
        <span>
          <Tooltip
            size={ads.length === 1 ? "lg" : "md"}
            text={data.row.original.job_boards && data.row.original.job_boards.join("\n")}
            positioning={
              ads.length === 1 ? "left" : data.row.index % paginationSize === 0 ? "bottom" : "top"
            }
          >
            <div className="txt--center">
              {data.row.original.job_boards ? data.row.original.job_boards.length : ""}
            </div>
          </Tooltip>
        </span>
      ),
      enableSorting: false,
      size: 100,
      id: "job_boards",
    }),
    COLUMNS_HELPER.accessor("options", {
      header: () => <span className="w--100 txt--center">Options</span>,
      cell: data => {
        const ad_current_status: JobAdStatus = data.row.original.status;
        const ad_id: number = data.row.original.ad_id;
        const { status: trackingStatus, tracker } = data.row.original.tracking;
        const isTracked = trackingStatus && tracker;
        const isCompanyActive: boolean = data.row.original.is_company_active;

        return (
          <div className="table__buttons">
            {ad_current_status !== "new" ? (
              <Link
                className={`btn btn--text btn--text--secondary ${
                  isTracked ? "job-ads__tracked-column" : ""
                }`}
                to={`/job-ads/ads/${ad_id}/`}
                title={isTracked ? `${tracker} is currently editing this ad` : ""}
              >
                Details
              </Link>
            ) : null}

            {/* CREATE BUTTON */}
            <PermissionCheckComponentWrapper permissions={["ad_manager_edit"]}>
              {ad_current_status === "new" ? (
                <Link
                  to={`/job-ads/ads/${ad_id}/edit/`}
                  className={`btn btn--text btn--text--secondary--dark px--0i ${
                    isTracked ? "job-ads__tracked-column" : ""
                  }`}
                  title={isTracked ? `${tracker} is currently editing this ad` : ""}
                >
                  Create Ad
                </Link>
              ) : null}
            </PermissionCheckComponentWrapper>

            {/* READY TO POST*/}
            {ad_current_status === "pending" ? (
              <Button
                modifierClass={`btn--text btn--text--secondary--dark ml--5 ml--md--0 ${
                  isTracked ? "job-ads__tracked-column" : ""
                }`}
                onClick={() => handleOpenAdUpdateModal(ad_id, "ready_to_post", ad_current_status)}
                title={isTracked ? `${tracker} is currently editing this ad` : ""}
              >
                Ready to Post
              </Button>
            ) : null}

            {/* PUBLISH BUTTON */}
            <PermissionCheckComponentWrapper permissions={["ad_manager_publish"]}>
              {ad_current_status === "ready_to_post" ? (
                <Button
                  modifierClass={`btn--text btn--text--secondary--dark ml--5 ml--md--0 ${
                    isTracked ? "job-ads__tracked-column" : ""
                  }`}
                  isDisabled={!isCompanyActive}
                  onClick={() => {
                    // Prevent triggering status updates if the ad belongs to inactive client
                    if (!isCompanyActive) return;

                    handleOpenAdUpdateModal(ad_id, "active", ad_current_status);
                  }}
                  title={isTracked ? `${tracker} is currently editing this ad` : ""}
                >
                  <Tooltip
                    text={
                      !isCompanyActive
                        ? "Cannot publish this ad because it belongs to inactive account!"
                        : ""
                    }
                    size="lg"
                    positioning="left"
                    modifierClass="fw--medium"
                  >
                    Publish
                  </Tooltip>
                </Button>
              ) : null}
            </PermissionCheckComponentWrapper>

            {/* TAKEDOWN BUTTON */}
            <PermissionCheckComponentWrapper permissions={["ad_manager_take_down"]}>
              {["active", "takedown"].includes(ad_current_status) ? (
                <Button
                  modifierClass={`btn--text btn--text--danger ml--5 ml--md--0 ${
                    isTracked ? "job-ads__tracked-column" : ""
                  }`}
                  onClick={() => handleOpenAdUpdateModal(ad_id, "takedown", ad_current_status)}
                  title={isTracked ? `${tracker} is currently editing this ad` : ""}
                >
                  Take Down
                </Button>
              ) : null}
            </PermissionCheckComponentWrapper>
          </div>
        );
      },
      enableSorting: false,
      size: adGroupStatus === "new" || adGroupStatus === "expired" ? 90 : 220,
    }),
  ];

  /*=======================================
    HANDLE TABLE COLUMN DATA MAPPING
  ========================================*/
  const MAPPED_TABLE_DATA = useMemo(() => {
    if (!ads || !ads.length) return [];

    const mappedData: JobAdsTableMappedFields[] = ads.map((ad: JobAdsLatestAd) => {
      const targetedDateField = JOB_ADS_DATE_FIELD_MAPPINGS[adGroupStatus];

      return {
        ad_id: ad.id,
        slug: ad.company.slug,
        job_code: ad.job_code,
        date: ad[targetedDateField] || "N/A",
        age: ad[`age_${JOB_ADS_DATE_FIELD_MAPPINGS[adGroupStatus]}`],
        client_account: ad.company.name,
        job_title: ad.title,
        status: ad.status,
        type: ad.type,
        tracking: ad.tracking,
        job_boards: ad.job_boards,
        is_company_active: ad.company.is_active,
      };
    });

    return mappedData;
  }, [ads]);

  /*=======================================
    HANDLE JOB AD STATUS UPDATES
  ========================================*/
  const [adUpdateDetails, setAdUpdateDetails] = useState<JobAdsTableAdUpdateDetails | null>(null);
  const [adUpdateModalShown, setAdUpdateModalShown] = useState<boolean>(false);

  // Open the modal prompting the user for confirmation about
  // triggering a state update for the targeted Job Ad
  const handleOpenAdUpdateModal = (
    ad_id: number,
    ad_new_status: JobAdStatus,
    ad_previous_status: JobAdStatus,
  ) => {
    setAdUpdateDetails({ ad_id, ad_new_status, ad_previous_status });
    setAdUpdateModalShown(true);
  };

  // Clear the details when the modal is closed / request is sent
  const handleCloseAdUpdateModal = () => {
    setAdUpdateDetails(null);
    setAdUpdateModalShown(false);
  };

  // Update the status of the targeted job ad
  const queryClient = useQueryClient();
  const updateAdStatus = useJobAdsUpdateStatus();

  const handleUpdateAdStatus = async () => {
    // Prevent any update attempts if there are no update details provided
    if (!adUpdateDetails) return;

    try {
      // Find the ID of the matching status
      const jobAdsStatuses = queryClient.getQueryData([
        "job-ads-statuses",
      ]) as JobAdsStatusesResponseFields[];

      // Find the status data that corresponds to the new status
      // to which we want to move the targeted ad
      const statusData: JobAdsStatusesResponseFields | undefined = jobAdsStatuses?.find(status => {
        return status.name.toLowerCase() === adUpdateDetails.ad_new_status;
      });

      // Prevent any ad status updates if there's no status data to work with
      if (!statusData) {
        toast.error("Job Ad Statuses are not available! Please refresh and try again.");
        handleCloseAdUpdateModal();
        return;
      }

      // Update the details for the targeted ad
      await updateAdStatus.mutateAsync({
        ad_id: adUpdateDetails.ad_id,
        status_id: statusData.id,
      });

      // Close the modal and reset the selected update details to their defaults
      handleCloseAdUpdateModal();
    } catch (error) {
      errorReporting("Failed updating ad status", error, {
        ad_id: adUpdateDetails.ad_id,
      });
    }
  };

  return (
    <>
      <Table
        data={MAPPED_TABLE_DATA}
        columns={LATEST_JOB_ADS_COLUMNS}
        isRefetching={isRefetching}
        paginationPageSize={paginationSize}
        modifierClass={`table-wrapper--no-shadow mb--0i ${
          count !== undefined && count > 5 ? "table-wrapper--border-last-row" : ""
        }`}
        shouldHandlePageRouteParameter={shouldHandlePageRouteParameter}
      />

      <AnimatePresence>
        {adUpdateDetails !== null && adUpdateModalShown ? (
          <Modal
            title={`Are you sure you want to ${
              JOB_ADS_STATUS_UPDATES_TEXT_MAPPINGS[adUpdateDetails.ad_new_status]
            }`}
            text=""
            handleCloseModal={handleCloseAdUpdateModal}
            modifierClass="modal--md modal--fixated p--30i"
          >
            <div className="d-flex justify-content-center align-items-center mt--20">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary--light"
                onClick={handleCloseAdUpdateModal}
              >
                Cancel
              </Button>

              <Button
                modifierClass="btn--fluid--md btn--primary ml--20"
                onClick={handleUpdateAdStatus}
                isLoading={updateAdStatus.isLoading}
                isDisabled={updateAdStatus.isLoading}
              >
                Yes
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default JobAdsTable;
