import { DropdownItem } from "../../components/Dropdown/interfaces";
import { DocumentExportColumnDetails } from "./interfaces";

export const REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS: DropdownItem[] = [
  { text: "Show 10 rows", value: 10 },
  { text: "Show 25 rows", value: 25 },
  { text: "Show 100 rows", value: 100 },
  { text: "Show 250 rows", value: 250 },
];

export const REPORTS_APPLICANT_COUNT_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Source",
    field_name: "label",
    width_pdf: 150,
    width_excel: 200,
  },
  {
    text: "Applications",
    field_name: "applications",
    width_pdf: 80,
    width_excel: 100,
  },
  {
    text: "SBCAs",
    field_name: "sbcas",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "Completion Ratio",
    field_name: "completion_ratio",
    width_pdf: 100,
    width_excel: 100,
  },
  {
    text: "Current",
    field_name: "current",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "Favorites",
    field_name: "favorites",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Interviewed",
    field_name: "interviewed",
    width_pdf: 80,
    width_excel: 100,
  },
  {
    text: "Offer Pending",
    field_name: "offer_pending",
    width_pdf: 80,
    width_excel: 100,
  },
  {
    text: "Hired",
    field_name: "hired",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Archived",
    field_name: "archived",
    width_pdf: 60,
    width_excel: 100,
  },
];

export const REPORTS_AD_ACTIVATION_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Job Boards",
    field_name: "name",
    width_pdf: "*",
    width_excel: 150,
  },
  {
    text: "Total Activations",
    field_name: "total",
    width_pdf: "*",
    width_excel: 100,
  },
];

export const REPORTS_EXPIRING_ADS_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Store",
    field_name: "store_name",
    width_pdf: 200,
    width_excel: 300,
  },
  {
    text: "Ad Name",
    field_name: "ad_name",
    width_pdf: 200,
    width_excel: 300,
  },
  {
    text: "Posted Date",
    field_name: "posted_date",
    width_pdf: 120,
    width_excel: 100,
  },
  {
    text: "Expire Date",
    field_name: "expire_date",
    width_pdf: 120,
    width_excel: 100,
  },
  {
    text: "Days Left",
    field_name: "days_left",
    width_pdf: 70,
    width_excel: 100,
  },
  {
    text: "Ad Link",
    field_name: "ad_link_label",
    width_pdf: 90,
    width_excel: 130,
  },
];

export const REPORTS_INDEED_SPONSORED_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "#",
    field_name: "id",
    width_pdf: 25,
    width_excel: 150,
  },
  {
    text: "Ad ID",
    field_name: "ad_id",
    width_pdf: 35,
    width_excel: 150,
  },
  {
    text: "Job Code",
    field_name: "job_code",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "Title",
    field_name: "title",
    width_pdf: "*",
    width_excel: 150,
  },
  {
    text: "Company",
    field_name: "company",
    width_pdf: "*",
    width_excel: 100,
  },
  {
    text: "Budget",
    field_name: "budget",
    width_pdf: 40,
    width_excel: 150,
  },
  {
    text: "Weeks",
    field_name: "weeks",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "Post Date",
    field_name: "post_date",
    width_pdf: 60,
    width_excel: 150,
  },
  {
    text: "Take Down Date",
    field_name: "take_down_date",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Refreshed",
    field_name: "refreshed",
    width_pdf: 30,
    width_excel: 150,
  },
  {
    text: "Refresh Date",
    field_name: "refresh_date",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Applications",
    field_name: "applications",
    width_pdf: 63,
    width_excel: 150,
  },
  {
    text: "Resumes",
    field_name: "resumes",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "SBCAs",
    field_name: "sbcas",
    width_pdf: 40,
    width_excel: 100,
  },
];

export const REPORTS_JOB_BOARD_STATUS_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "#",
    field_name: "id",
    width_pdf: 25,
    width_excel: 30,
  },
  {
    text: "Job Code",
    field_name: "job_code",
    width_pdf: 80,
    width_excel: 100,
  },
  {
    text: "Client",
    field_name: "client_name",
    width_pdf: "*",
    width_excel: 260,
  },
  {
    text: "Title",
    field_name: "title",
    width_pdf: "*",
    width_excel: 260,
  },
  {
    text: "Start Date",
    field_name: "start_date",
    width_pdf: 80,
    width_excel: 130,
  },
  {
    text: "End Date",
    field_name: "end_date",
    width_pdf: 80,
    width_excel: 130,
  },
];

export const REPORTS_AD_PERFORMANCE_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Company",
    field_name: "company",
    width_pdf: 60,
    width_excel: 130,
  },
  {
    text: "Title",
    field_name: "title",
    width_pdf: 60,
    width_excel: 130,
  },
  {
    text: "Start Date",
    field_name: "start_date",
    width_pdf: 45,
    width_excel: 130,
  },
  {
    text: "End Date",
    field_name: "end_date",
    width_pdf: 45,
    width_excel: 130,
  },
  {
    text: "Job Code",
    field_name: "job_code",
    width_pdf: 40,
    width_excel: 130,
  },
  {
    text: "Total",
    field_name: "total",
    width_pdf: 30,
    width_excel: 100,
  },
  {
    text: "FirstChoice",
    field_name: "firstchoice",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "CareerPages",
    field_name: "career_pages",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "Indeed",
    field_name: "indeed",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "Craigslist",
    field_name: "craigslist",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "ZipRecruiter",
    field_name: "zip_recruiter",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "SnagAJob",
    field_name: "snag_a_job",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "CareerBuilder",
    field_name: "career_builder",
    width_pdf: 40,
    width_excel: 100,
  },
  {
    text: "GeoSolutions",
    field_name: "geo_solutions",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "LinkedIn",
    field_name: "linked_in",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "AppCast",
    field_name: "appcast",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "Talent",
    field_name: "talent",
    width_pdf: 35,
    width_excel: 100,
  },
  {
    text: "Other",
    field_name: "other",
    width_pdf: 35,
    width_excel: 100,
  },
];

export const REPORTS_HIRING_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Job ID",
    field_name: "job_code",
    width_pdf: 75,
    width_excel: 100,
  },
  {
    text: "Job Title",
    field_name: "job_title",
    width_pdf: 140,
    width_excel: 250,
  },
  {
    text: "Name",
    field_name: "applicant_name",
    width_pdf: 100,
    width_excel: 150,
  },
  {
    text: "Has Resume?",
    field_name: "resume",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "SBCA",
    field_name: "sbca_score",
    width_pdf: 40,
    width_excel: 80,
  },
  {
    text: "Date Published",
    field_name: "date_published",
    width_pdf: 80,
    width_excel: 90,
  },
  {
    text: "Date Received",
    field_name: "date_received",
    width_pdf: 80,
    width_excel: 90,
  },
  {
    text: "Address",
    field_name: "address",
    width_pdf: 120,
    width_excel: 170,
  },
  {
    text: "Status",
    field_name: "bucket",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "Hired Date",
    field_name: "hired_date",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "Days elapsed",
    field_name: "days_elapsed",
    width_pdf: 70,
    width_excel: 90,
  },
];

export const REPORTS_INTERNAL_HIRING_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "Job ID",
    field_name: "job_code",
    width_pdf: 75,
    width_excel: 100,
  },
  {
    text: "Job Title",
    field_name: "job_title",
    width_pdf: 140,
    width_excel: 250,
  },
  {
    text: "Name",
    field_name: "applicant_name",
    width_pdf: 100,
    width_excel: 150,
  },
  {
    text: "Source",
    field_name: "source",
    width_pdf: 100,
    width_excel: 150,
  },
  {
    text: "Has Resume?",
    field_name: "resume",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "SBCA",
    field_name: "sbca_score",
    width_pdf: 40,
    width_excel: 80,
  },
  {
    text: "Date Published",
    field_name: "date_published",
    width_pdf: 80,
    width_excel: 90,
  },
  {
    text: "Date Received",
    field_name: "date_received",
    width_pdf: 80,
    width_excel: 90,
  },
  {
    text: "Address",
    field_name: "address",
    width_pdf: 120,
    width_excel: 170,
  },
  {
    text: "Status",
    field_name: "bucket",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "Hired Date",
    field_name: "hired_date",
    width_pdf: 70,
    width_excel: 90,
  },
  {
    text: "Days elapsed",
    field_name: "days_elapsed",
    width_pdf: 70,
    width_excel: 90,
  },
];

export const REPORTS_ZIPRECRUITER_SPONSORED_DOCUMENT_COLUMNS: DocumentExportColumnDetails[] = [
  {
    text: "#",
    field_name: "id",
    width_pdf: 20,
    width_excel: 150,
  },
  {
    text: "Ad ID",
    field_name: "ad_id",
    width_pdf: 35,
    width_excel: 150,
  },
  {
    text: "Job Code",
    field_name: "job_code",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "Title",
    field_name: "title",
    width_pdf: "*",
    width_excel: 150,
  },
  {
    text: "Company",
    field_name: "company",
    width_pdf: "*",
    width_excel: 100,
  },
  // Temporarily Disabled
  // {
  //   text: "Campaign",
  //   field_name: "campaign",
  //   width_pdf: 80,
  //   width_excel: 150,
  // },
  {
    text: "Post Date",
    field_name: "post_date",
    width_pdf: 60,
    width_excel: 150,
  },
  {
    text: "Take Down Date",
    field_name: "take_down_date",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Refreshed",
    field_name: "refreshed",
    width_pdf: 30,
    width_excel: 150,
  },
  {
    text: "Refresh Date",
    field_name: "refresh_date",
    width_pdf: 60,
    width_excel: 100,
  },
  {
    text: "Applications",
    field_name: "applications",
    width_pdf: 40,
    width_excel: 150,
  },
  {
    text: "Resumes",
    field_name: "resumes",
    width_pdf: 50,
    width_excel: 100,
  },
  {
    text: "SBCAs",
    field_name: "sbcas",
    width_pdf: 40,
    width_excel: 100,
  },
];

export const REPORT_API_DATE_TYPE_OPTIONS: DropdownItem[] = [
  {
    text: "Date Published",
    value: "published",
  },
  {
    text: "Date Received",
    value: "received",
  },
];
