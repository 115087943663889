import { FormikValues } from "formik";
import { JobAdType, JobAdsImageFields } from "../../../api/JobAds/interfaces";

/**
 *
 * Utility function that will generate "Job Ad" editor's content
 * with text, based on the values that the user has entered (at the moment)
 * in the form fields from the "Job Address" and "Job Information" sections
 *
 * @param values The form values from "Job Address" and "Job Information" sections
 * @param formURL The received URL for the online application form
 * @param images Set of image URLs to be used for the generated 'Apply Button' and 'Banner'
 *
 */
export default function generateAdTemplate(
  values: FormikValues,
  formURL: string,
  images: JobAdsImageFields,
  type: JobAdType,
): string {
  const {
    address_street,
    address_city,
    address_state,
    address_zip,
    job_title,
    headline_1,
    headline_2,
    headline_3,
    job_questions,
    job_intro,
    job_description,
    job_benefits,
    job_responsibilities,
    job_requirements,
    job_terms,
  } = values;

  // Construct text to be inserted in the editor
  const ADDRESS: string = `${address_street} ${address_city}, ${address_state} ${address_zip}`;
  const DESCRIPTION_TEXT: string = paragraphContent(job_description, "text-align: left;");
  const BENEFITS_TEXT: string = listContent(job_benefits);
  const RESPONSIBILITIES_TEXT: string = listContent(job_responsibilities);
  const REQUIREMENTS_TEXT: string = listContent(job_requirements);

  // Content to render in the header based on the type of the ad
  let JOB_AD_HEADER_CONTENT: string = "";

  switch (type) {
    case "Full Graphic":
      JOB_AD_HEADER_CONTENT = `${
        images.full_graphic
          ? `<a href="${formURL}" target="_blank">
              <img src="${images.full_graphic}" />
            </a>`
          : ""
      }
        <br />
        <br />
        ${ADDRESS}
        <br />
        <br />  
        ${
          images.apply_button
            ? `
            <a href="${formURL}" target="_blank" rel="noopener">
              <img src="${images.apply_button}" />
            </a>
            `
            : ""
        }
        <br />
      `;
      break;

    case "Banner":
      JOB_AD_HEADER_CONTENT = `${images.banner ? `<img src="${images.banner}" />` : ""} 
        <br />
        <br />
        ${ADDRESS}
        <br />
        <br />
        ${
          images.apply_button
            ? `
            <a href="${formURL}" target="_blank" rel="noopener">
              <img src="${images.apply_button}" />
            </a>
            `
            : ""
        }
        <br />
      `;
      break;

    case "Blind":
      JOB_AD_HEADER_CONTENT = `${
        images.full_graphic
          ? `<a href="${formURL}" target="_blank">
            <img src="${images.full_graphic}" />
          </a>`
          : ""
      } <br /> <br />`;
      break;

    case "Text":
      JOB_AD_HEADER_CONTENT = `
        <br />
        <br />
        ${ADDRESS}
        <br />
        <br />
        <a href="${formURL}" target="_blank" rel="noopener">Apply Here</a>
      `;
      break;

    default:
      JOB_AD_HEADER_CONTENT = `${
        images.full_graphic
          ? `<a href="${formURL}" target="_blank">
              <img src="${images.full_graphic}" />
            </a>`
          : ""
      }
        <br />
        <br />
        ${ADDRESS}
        <br />
        <br />  
      `;
  }

  const GENERATED_CONTENT: string = `
     <div style="margin: 0 auto; padding: 0; max-width: 555px; width: 100%; height: auto; font-family: 'Arial', 'Helvetica', 'sans-serif'; text-align: center">
        <p style="text-align: center;">
          ${JOB_AD_HEADER_CONTENT}
        </p>

       ${headline_1 ? `<h1 style="font-size: 20pt;"><strong>${headline_1}</strong></h1>` : ""}

       ${headline_2 ? `<h2 style="font-size: 16pt;"><strong>${headline_2}</strong></h2>` : ""}

       ${headline_3 ? `<h3 style="font-size: 16pt;"><strong>${headline_3}</strong></h3>` : ""}

       ${job_questions ? `<h4 style="font-size: 14pt;"><strong>${job_questions}</strong></h4>` : ""}

       ${paragraphContent(job_intro, "text-align: left;")}
      
       ${
         DESCRIPTION_TEXT
           ? `
            <p style="text-align: left; font-size: 15px;">
              <strong>${job_title} - Job Description:</strong>
            </p>
            ${DESCRIPTION_TEXT}
          `
           : ""
       }
       <br/>

       ${
         BENEFITS_TEXT
           ? `
            <p style="text-align: left; font-size: 15px;">
              <strong>${job_title} - Job Benefits:</strong>
            </p>
            ${BENEFITS_TEXT}
          `
           : ""
       }
       <br/>
       
       ${
         RESPONSIBILITIES_TEXT
           ? `
            <p style="text-align: left; font-size: 15px;">
              <strong>${job_title} - Job Responsibilities:</strong>
            </p>
            ${RESPONSIBILITIES_TEXT}
          `
           : ""
       }
       <br/>

       ${
         REQUIREMENTS_TEXT
           ? `
            <p style="text-align: left; font-size: 15px;">
              <strong>${job_title} - Job Requirements:</strong>
            </p>
            ${REQUIREMENTS_TEXT}
          `
           : ""
       }
       <br/>

       ${paragraphContent(job_terms, "text-align: center;", true)}
     </div>
   `;

  return GENERATED_CONTENT;
}

/**
 *
 * Utility function for generating the text to be displayed for
 * paragraph fields that are represented as textareas, so we can correctly
 * handle new lines being inserted in the textarea.
 *
 * @param paragraph The paragraph form field value
 * @param style Styling to be applied to the inserted paragraph elements
 * @param emphasized If the paragaraph element that is to be inserted is emphasized or not
 *
 * @returns String representation of the form field value, to be inserted in the text editor
 *
 */
export function paragraphContent(
  paragraph: string,
  style: string = "",
  emphasized: boolean = false,
): string {
  // If there's no valid value received for "paragraph" to be processed, return empty string
  if (!paragraph) return "";

  const splitParagraph: string[] = paragraph.split("\n").filter(p => p);

  const content: string = `${
    splitParagraph.length > 0
      ? splitParagraph
          .map((paragraphText: string) => {
            return `<p style="white-space: pre-line;${style}">${
              emphasized ? `<strong><em>${paragraphText}</em></strong>` : paragraphText
            }</p>`;
          })
          .join("")
      : ""
  }`;

  return content;
}

/**
 *
 * Utility function for generating the text to be displayed for
 * textarea field values that are to be presented as list items.
 *
 *
 * @param list The form field that will be presented as list items
 *
 * @returns String represenation of the form field value to be inserted in the text editor
 *
 */
export function listContent(list: string): string {
  // If there's no valid value received for "list" to be processed, return empty string
  if (!list) return "";

  const splitListText: string[] = list.split("\n").filter(text => text);

  const content: string = `${
    splitListText.length > 0
      ? `
        <ul style="padding-left: 40px;">
          ${splitListText
            .map((listText: string) => {
              return `<li style="text-align: left; margin-bottom: 0px;">${listText}</li>`;
            })
            .join("")}
        </ul>
      `
      : ""
  }`;
  return content;
}
