// Hooks & Utilities
import { useAuth } from "../../../providers/auth-context";

// Components
import Skeleton from "react-loading-skeleton";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Button from "../../../components/Button/Button";

// Assets
import FCHLogoFull from "../../../assets/images/fch-full-logo-white.png";
import EmailIcon from "../../../assets/images/icons/assessment-mail.svg?react";
import PrintIcon from "../../../assets/images/icons/assessment-print.svg?react";
import UserIcon from "../../../assets/images/icons/assessment-user.svg?react";
import CompanyIcon from "../../../assets/images/icons/assessment-company.svg?react";
import TagIcon from "../../../assets/images/icons/assessment-tag.svg?react";
import ReportIcon from "../../../assets/images/icons/assessment-report.svg?react";
import SubmittedIcon from "../../../assets/images/icons/assessment-submitted.svg?react";

// Interfaces & Statics
import { UserRoleNames } from "../../../interfaces/global";

const AssessmentResultsSkeleton = () => {
  const { user } = useAuth();

  return (
    <>
      <div className="assessment__header" data-testid="components:skeleton-assessment-results">
        <div className="container container--xl">
          <div className="d-flex flex-column flex-md-row pb--40 align-items-center align-items-md-start justify-content-center justify-content-md-between line-under line-under--transparent line-under--absolute">
            <img src={FCHLogoFull} alt="FirstChoice Hiring logo" />

            <div className="assessment__header__buttons d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-md-end w--100">
              <Tooltip
                text="Email Assessment"
                size="md"
                wrapperModifierClass="btn--md flex-shrink-0 p--0"
              >
                <Button className="btn btn--md btn--round btn--white">
                  <EmailIcon className="mr--10" /> Email SBCA
                </Button>
              </Tooltip>
              <Tooltip
                text="Print Assessment"
                size="md"
                wrapperModifierClass="btn--md ml--10 flex-shrink-0 p--0"
              >
                <Button className="btn btn--md btn--round btn--white">
                  <PrintIcon className="mr--10" />
                  Print SBCA
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="assessment-info-wrapper">
            {/*Prepared For*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <UserIcon />
              </div>
              <h5 className="assessment-info__title">Prepared For</h5>
              <Skeleton width={150} height={23} />
            </div>

            {/*COMPANY*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <CompanyIcon />
              </div>
              <h5 className="assessment-info__title">Company</h5>
              <Skeleton width={80} height={23} />
            </div>

            {/*Type*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <TagIcon />
              </div>
              <h5 className="assessment-info__title">Type</h5>
              <p className="assessment-info__content">
                <Skeleton width={100} height={23} />
              </p>
            </div>

            {/*Permission*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <ReportIcon />
              </div>
              <h5 className="assessment-info__title">Permission</h5>
              <p className="assessment-info__content">
                <Skeleton width={100} height={23} />
              </p>
            </div>

            {/*SUBMITTED*/}
            <div className="assessment-info">
              <div className="assessment-info__img">
                <SubmittedIcon />
              </div>
              <h5 className="assessment-info__title">Submitted</h5>
              <Skeleton width={150} height={23} />
            </div>
          </div>
        </div>
      </div>
      <div className="assessment__content">
        <div className="assessment__section">
          <div className="container container--lg">
            {/*Overall Observations*/}
            <div className="assessment__block">
              <h1 className="assessment-title assessment-title--lg">Overall Observations</h1>

              <div className="card p--0 ">
                <div className="card__header">
                  <Skeleton width={165} height={30} />
                  <Skeleton width={130} height={40} style={{ borderRadius: "100px" }} />
                </div>
                <div className="card__content">
                  <div className="line-under line-under--absolute mb--20">
                    <div className="row">
                      <div className="col md-4">
                        <div className="sbca-data sbca-data--lg">
                          <div className="sbca-data__row">
                            <span className="sbca-data__title">Target range:</span>
                            <span className="sbca-data__content">1-4.49</span>
                          </div>
                          <div className="sbca-data__row">
                            <span className="sbca-data__title">Marginal:</span>
                            <span className="span-data__content">4.50-6.49</span>
                          </div>
                          <div className="sbca-data__row">
                            <span className="sbca-data__title">Concerned:</span>
                            <span className="span-data__content">6.50+</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <Skeleton width={615} height={100} />
                      </div>
                    </div>
                  </div>
                  <p className="assessment-txt">
                    This index reflects the overall assessment including the baseline personality,
                    organizational ability, behavioral patterns and all its measured components. It
                    also measures the propensity for negativity and emotional patterns and their
                    effect on his/her environment over a period of time.
                  </p>
                </div>
              </div>
            </div>

            {/*Baseline Characteristics*/}
            <div className="assessment__block">
              <h1 className="assessment-title">Baseline Characteristics</h1>

              {Array.from({ length: 3 }).map((_item, index: number) => (
                <div key={`skeleton-card-characteristics-${index}`} className="card p--0 ">
                  <div className="card__header">
                    <Skeleton width={165} height={30} />
                    <Skeleton width={130} height={40} style={{ borderRadius: "100px" }} />
                  </div>
                  <div className="card__content">
                    <div className="line-under line-under--absolute mb--20">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex align-items-center justify-content-start">
                            <Skeleton width={104} height={104} style={{ borderRadius: "100px" }} />
                            <div className="sbca-data ml--40">
                              <div className="sbca-data__row">
                                <Skeleton width={100} height={20} />
                                <Skeleton width={50} height={20} />
                              </div>
                              <div className="sbca-data__row">
                                <Skeleton width={100} height={20} />
                                <Skeleton width={50} height={20} />
                              </div>
                              <div className="sbca-data__row">
                                <Skeleton width={100} height={20} />
                                <Skeleton width={50} height={20} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Skeleton width={"100%"} height={100} />
                          <Skeleton
                            width={"80%"}
                            height={20}
                            style={{ display: "block", margin: "10px auto 0" }}
                          />
                        </div>
                      </div>
                    </div>
                    <Skeleton width={"100%"} height={100} />
                  </div>
                </div>
              ))}
            </div>

            {/*Evaluation - Baseline:*/}
            <div className="assessment__block">
              <h1 className="assessment-title">
                Evaluation - Baseline: <Skeleton width={155} height={30} />
              </h1>

              {Array.from({ length: 5 }).map((_item, index: number) => (
                <div key={`skeleton-card-evaluation-${index}`} className="card p--0 ">
                  <div className="card__header">
                    <Skeleton width={165} height={30} />
                    <Skeleton width={130} height={40} style={{ borderRadius: "100px" }} />
                  </div>
                  <div className="card__content">
                    <div className="row">
                      <div className="col-md-4">
                        <Skeleton width={150} height={24} style={{ marginBottom: "15px" }} />
                        <Skeleton width={"100%"} height={100} />
                      </div>
                      <div className="col-md-8">
                        <Skeleton width={"100%"} height={100} />
                        <Skeleton
                          width={"80%"}
                          height={20}
                          style={{ display: "block", margin: "10px auto 0" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="assessment__section bg--white">
          <div className="container container--lg">
            <div className="assessment__block">
              <h1 className="assessment-title ">Final Thoughts</h1>
              {Array.from({ length: 5 }).map((_item, index: number) => (
                <div key={`skeleton-card-thoughts-${index}`} className="assessment-row">
                  <Skeleton width={"100%"} height={80} />
                </div>
              ))}
            </div>
            <div className="assessment__block">
              <h1 className="assessment-title ">Areas of Concern</h1>
              <Skeleton width={"100%"} height={360} />
            </div>
            <div className="assessment__block">
              <h1 className="assessment-title ">Commentary</h1>
              <Skeleton width={"100%"} height={100} />
            </div>
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <div className="assessment__block">
                <h1 className="assessment-title ">AI Insights</h1>
                <Skeleton width={"100%"} height={360} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentResultsSkeleton;
