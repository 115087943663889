// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";
import { BiShow as ShowPasswordIcon, BiHide as HidePasswordIcon } from "react-icons/bi";

// Components
import { Field, Form, Formik, FormikProps } from "formik";
import ContentHeader from "../../../../components/Content/ContentHeader";
import Button from "../../../../components/Button/Button";
import FormDropdownMultiselect from "../../../../components/Form/FormDropdownMultiselect";
import FormDropdownSearchable from "../../../../components/Form/FormDropdownSearchable";
import FormInputSideLabel from "../../../../components/Form/FormInputSideLabel";

// Interfaces
import { DropdownItem } from "../../../../components/Dropdown/interfaces";
import {
  AdminUsersCreateRequestFields,
  AdminUsersPreRequestFields,
} from "../../../../api/Users/interfaces";
import { RolesResponseFields } from "../../../../api/Roles/interfaces";
import { UserRoleIDsEnum } from "../../../../interfaces/global";

// Utilities & Hooks
import { Link, useNavigate } from "react-router-dom";
import { useAdminUsersCreate } from "../../../../api/Users/AdminUsers";
import { useEffect, useRef, useState } from "react";
import { useAdminRolesGetAll } from "../../../../api/Roles/AdminRoles";
import useErrorReporting from "../../../../hooks/useErrorReporting";
import useAdminUsersCompanies from "./hooks/useAdminUsersCompanies";

// Schemas
import { ACCOUNT_ADMIN_USERS_NEW } from "../../../../schemas/AccountSchemas";
import { usePrivilegesGroupsGetAll } from "../../../../api/Privileges/Privileges";

const AdminUsersNew = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<AdminUsersPreRequestFields>>(null);
  const errorReporting = useErrorReporting();

  /*===============================
    GET THE LIST OF COMPANIES
  ================================*/
  const [companies, companiesLoading] = useAdminUsersCompanies();

  /*===============================
    OBTAIN THE ROLES FROM THE API
    AND MAP THEM INTO DROPDOWN ITEMS
  ================================*/
  const { data: rolesData, isLoading: rolesIsLoading } = useAdminRolesGetAll();
  const [roles, setRoles] = useState<DropdownItem[]>([]);
  const [selectedRoleID, setSelectedRoleID] = useState<number | null>(null);

  useEffect(() => {
    // Return a default empty array if there's no "roles" data available yet
    if (!rolesData || !rolesData.length || rolesIsLoading) return;

    const mappedRoles: DropdownItem[] = rolesData.map((role: RolesResponseFields) => {
      return { text: role.label, value: role.id, description: role.description };
    });

    setRoles(mappedRoles);
  }, [rolesData]);

  /*===============================
    GET THE LIST OF GROUPS
  ================================*/
  const { data: groupsData, isLoading: groupsIsLoading } = usePrivilegesGroupsGetAll();
  const [groups, setGroups] = useState<DropdownItem[]>([]);
  const [preselectedGroupID, setPreselectedGroupID] = useState<number | null>(null);

  useEffect(() => {
    if (!groupsData || !groupsData.length || groupsIsLoading) return;

    const mappedGroups: DropdownItem[] = [...groupsData]
      .filter(group => {
        return selectedRoleID === UserRoleIDsEnum.ADMIN
          ? group.is_admin_only
          : !group.is_admin_only;
      })
      .map(group => {
        return { text: group.name, value: group.id, description: group.description };
      });

    setGroups(mappedGroups);

    // Prevent any group pre-selection if there's no selected role or if selected role is "super admin"
    if (!selectedRoleID || selectedRoleID === UserRoleIDsEnum.SUPER_ADMIN) return;

    // Handle Formik inner referece to be able to directly update field values
    if (!formRef || !formRef.current) return;
    const { setFieldValue } = formRef.current;

    // Handle pre-selecting the correct group based on the already selected role
    // For 'admin' (Account Manager) role, we pre-select the first available group
    // For any other selected role, we pre-select the "Standard User" group
    if (selectedRoleID === UserRoleIDsEnum.ADMIN) {
      setPreselectedGroupID(mappedGroups[0].value as number);
      setFieldValue("group_id", mappedGroups[0].value);
    } else {
      // Find the "Standard User" group and pre-select it
      const groupToBePreselected = groupsData.find(group => {
        return group.name.toLowerCase() === "standard user";
      });

      // Exit and do not try to pre-select anything if group is not found
      if (!groupToBePreselected) return;

      setPreselectedGroupID(groupToBePreselected.id);
      setFieldValue("group_id", groupToBePreselected.id);
    }
  }, [groupsData, selectedRoleID]);

  /*===============================
    CREATE A NEW USER
  ================================*/
  const createUser = useAdminUsersCreate();

  const handleCreateNewUser = async (details: AdminUsersPreRequestFields) => {
    try {
      // Construct the final request payload processing the fields before sending the API request
      const REQUEST_PAYLOAD: AdminUsersCreateRequestFields = {
        first_name: details.first_name,
        last_name: details.last_name,
        title: details.title,
        email: details.email,
        password: details.password,
        password_confirmation: details.password_confirmation,
        company_ids: details.company_ids,
        role_id: details.role_id,
        group_id: details.group_id,
      };

      await createUser.mutateAsync(REQUEST_PAYLOAD);

      // Redirect back to the users listing
      navigate("/account/admin/users/");
    } catch (error) {
      errorReporting("Failed creating new user account from 'Admin' page", error, { ...details });
    }
  };

  /*===============================
    HANDLE PASSWORD 
    FIELDS VISIBILITY
  ================================*/
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  return (
    <div className="container py--25">
      <ContentHeader title="Create User" />

      <Link to="/account/admin/users/" className="d-inline-flex align-items-center txt--lg my--20">
        <BackIcon className="txt--grey" />
        <span className="txt--blue fw--regular">Back</span>
      </Link>

      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          title: "",
          email: "",
          password: "",
          password_confirmation: "",
          group_id: null,
          role_id: null,
          company_ids: [],
        }}
        innerRef={formRef}
        validationSchema={ACCOUNT_ADMIN_USERS_NEW}
        onSubmit={(details: AdminUsersPreRequestFields) => handleCreateNewUser(details)}
      >
        {({ values, validateForm, setFieldValue }) => (
          <Form
            style={{
              maxWidth: 700,
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Field
              component={FormInputSideLabel}
              label="First Name"
              id="first_name"
              name="first_name"
              modifierClass="input-side-label mb--15"
              size="full"
            />
            <Field
              component={FormInputSideLabel}
              label="Last Name"
              id="last_name"
              name="last_name"
              modifierClass="input-side-label mb--15"
              size="full"
            />

            <Field
              component={FormInputSideLabel}
              label="Title"
              id="title"
              name="title"
              modifierClass="input-side-label mb--15"
              size="full"
            />
            <Field
              component={FormInputSideLabel}
              label="Email"
              id="email"
              name="email"
              modifierClass="input-side-label mb--15"
              size="full"
            />
            <Field
              component={FormInputSideLabel}
              label="Password"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              modifierClass="input-side-label mb--15"
              size="full"
              autoComplete="new-password"
              nestedChildrenElements={
                <div className="input-with-elements__element input-with-elements__element--right">
                  {showPassword ? (
                    <ShowPasswordIcon onClick={() => setShowPassword(!showPassword)} />
                  ) : (
                    <HidePasswordIcon onClick={() => setShowPassword(!showPassword)} />
                  )}
                </div>
              }
            />
            <Field
              component={FormInputSideLabel}
              label="Confirm Password"
              id="password_confirmation"
              name="password_confirmation"
              type={showConfirmPassword ? "text" : "password"}
              nestedChildrenElements={
                <div className="input-with-elements__element input-with-elements__element--right">
                  {showConfirmPassword ? (
                    <ShowPasswordIcon
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  ) : (
                    <HidePasswordIcon
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  )}
                </div>
              }
              modifierClass="mb--15"
              size="full"
              autoComplete="new-password"
            />
            <Field
              component={FormDropdownSearchable}
              label="Role"
              id="role"
              name="role"
              modifierClass="dropdown--side-label mb--15"
              size="full"
              items={roles}
              placeholder="Select a role"
              isLoading={rolesIsLoading}
              disabled={rolesIsLoading || !roles.length}
              handleFieldUpdate={(role: DropdownItem) => {
                setFieldValue("role_id", role.value);
                setSelectedRoleID(role.value as number);

                // Clear out the selection of the fields when a super admin role is selected
                const { SUPER_ADMIN, ADMIN } = UserRoleIDsEnum;
                if (role.value === SUPER_ADMIN) setFieldValue("group_id", null);

                // Clear out the selection of companies anytime a super admin or admin role is selected
                if ([SUPER_ADMIN, ADMIN].includes(role.value as number)) {
                  setFieldValue("company_ids", []);
                }

                // Re-validate the form anytime the role changes, as this can trigger
                // clearing the selection of group and company selections
                validateForm();
              }}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />
            <Field
              key={`group-${values.role_id}`}
              component={FormDropdownSearchable}
              label="Group"
              id="group_id"
              name="group_id"
              modifierClass="dropdown--side-label mb--15"
              size="full"
              items={groups}
              placeholder="Select a group"
              isLoading={groupsIsLoading}
              disabled={
                groupsIsLoading ||
                !groups.length ||
                [UserRoleIDsEnum.SUPER_ADMIN].includes(values.role_id!)
              }
              handleFieldUpdate={(group: DropdownItem) => setFieldValue("group_id", group.value)}
              preselectedItemValue={
                values.role_id !== UserRoleIDsEnum.SUPER_ADMIN ? preselectedGroupID : null
              }
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />
            <Field
              component={FormDropdownMultiselect}
              id="company_ids"
              name="company_ids"
              placeholder={companiesLoading ? "Fetching Companies..." : "Select a company"}
              items={companies}
              isLoading={companiesLoading}
              disabled={
                companiesLoading ||
                !companies.length ||
                [UserRoleIDsEnum.SUPER_ADMIN, UserRoleIDsEnum.ADMIN].includes(values.role_id!)
              }
              size="full"
              label="Companies"
              modifierClass="dropdown--side-label"
              searchable
              clearable
              preselectedItems={values.company_ids}
              handleFieldUpdate={(companies: number[]) => setFieldValue("company_ids", companies)}
              framerAnimationCustomProps={{ hasSideLabel: true }}
            />
            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary mt--20 ml--135 ml--md--0"
              isLoading={createUser.isLoading}
              isDisabled={createUser.isLoading}
            >
              Create User
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminUsersNew;
