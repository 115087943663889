import { PartialGenericObject } from "../interfaces/global";

/**
 * Utility function for converting the received data object
 * into a form data instance, and appending the key/value pairs
 * to the initialized form data that is to be sent in the API request
 * @param data The data that is to be sent in the API request
 * @returns A FormData instance with appended key/value pairs based
 * on data to be sent in the API request.
 */
export default function handleFormDataFields<T>(data: PartialGenericObject<T>) {
  // Exit function if it receives an empty object
  if (!Object.entries(data).length) return;

  // Initialize the form data
  const formData = new FormData();

  // Loop trough all entries within the data object
  // and append the fields and their values to the form data
  Object.entries(data).forEach(dataField => {
    const [key, value] = dataField as any;
    formData.append(key, value);
  });

  return formData;
}
