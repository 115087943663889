// Utilities & Hooks
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import { format } from "date-fns";
import { handleAppointmentDisplayedTime } from "./utils/handleAppointmentDisplayedTime";
import { toast } from "react-toastify";
import { useApplicationResumeModal } from "../Applications/hooks/useApplicationResumeModal";
import { Link } from "react-router-dom";
import handlePhoneStringSanitization from "../../utilities/strings/handlePhoneStringSanitization";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Assets
import { FaMapMarkerAlt as LocationIcon } from "react-icons/fa";
import { MdPhoneInTalk as PhoneIcon } from "react-icons/md";
import VideoMeetingIcon from "../../assets/images/icons/applications-camera-icon.svg?react";
import InPersonMeetingIcon from "../../assets/images/icons/appointment-in-person-icon.svg?react";

// Components
import ApplicationPhoto from "../../components/Applications/ApplicationPhoto";
import ApplicationResume from "../../components/Applications/Socials/ApplicationResume";
import ApplicationLinkedIn from "../../components/Applications/Socials/ApplicationLinkedIn";
import ApplicationSendEmail from "../../components/Applications/Socials/ApplicationSendEmail";
import ApplicationScheduleMeeting from "../../components/Applications/Socials/ApplicationScheduleMeeting";
import AppointmentsActions from "../../components/Appointments/AppointmentsActions";
import ApplicationModalResumeRequest from "../Applications/modals/ApplicationModalResumeRequest";
import AppointmentResumePreview from "./modals/AppointmentResumePreview";

// Interfaces
import { AppointmentCardProps } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const AppointmentsCard = ({ appointment }: AppointmentCardProps) => {
  /*===============================
    RESUME MODALS
  ================================*/
  const [resumeModalType, handleResumeModal] = useApplicationResumeModal();

  /*=============================
    START VIDEO MEETING
  ===============================*/
  const handleStartMeeting = () => {
    if (appointment.type !== "video") return;

    if (!appointment.video_link) {
      toast.error("There's no valid video link available. Please re-schedule the video meeting.");
      return;
    }

    // Open the video conferencing page in a new tab
    window.open(`/vidconf/join/${appointment.video_link}`, "_blank");
  };

  return (
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb--10">
      <div className="appointments__card">
        <div className="appointments__card__header">
          <div className="appointments__card__date">
            <span>{format(new Date(appointment.appointment_time), "MMM")}</span>
            <span className="appointments__card__date__day">
              {format(new Date(appointment.appointment_time), "d")}
            </span>
            <span>{format(new Date(appointment.appointment_time), "yyyy")}</span>
          </div>

          <div className="appointments__card__time_and_creation">
            <p
              className={`appointments__card__time_and_creation__hour ${
                appointment.type === "video"
                  ? "appointments__card__time_and_creation__hour--local-timezone"
                  : ""
              }`}
            >
              {handleAppointmentDisplayedTime(
                appointment.type === "video"
                  ? appointment.appointment_timestamp
                  : appointment.appointment_time,
                appointment.type,
                "hh:mm a",
              )}
            </p>
            <p className="appointments__card__time_and_creation__creation-date">
              Date Set: {format(new Date(appointment.appointment_time), "MM/dd/yyyy")}
            </p>
          </div>
        </div>

        <div className="appointments__card__content">
          <Link
            to={`/applications/${appointment.application.id}/`}
            state={{ previousPage: "/appointments" }}
          >
            <ApplicationPhoto
              name={handleFullnameCombination(appointment.application)}
              photo={appointment.application.photo ?? ""}
              sbca={null}
            />
          </Link>

          <div className="appointments__card__applicant-details__wrapper">
            <div className="appointments__card__applicant-details">
              <Link
                to={`/applications/${appointment.application.id}/`}
                className="appointments__card__applicant-details__name break-word"
                state={{ previousPage: "/appointments" }}
              >
                {handleFullnameCombination(appointment.application)}
              </Link>
              <p className="appointments__card__applicant-details__position break-word">
                {appointment.application.position ?? "N/A"}
              </p>

              <div className="appointments__card__applicant-details__phone">
                <PhoneIcon className="mr--5" />

                {appointment.application.phone ? (
                  <a href={`tel:${handlePhoneStringSanitization(appointment.application.phone)}`}>
                    {handlePhoneStringSanitization(appointment.application.phone)}
                  </a>
                ) : (
                  <p className="d-inline-block txt--sm m--0">N/A</p>
                )}
              </div>

              <div className="appointments__card__applicant-details__address">
                <LocationIcon className="mr--5" />
                <p>
                  {handleApplicantCityAndState(
                    appointment.application.city,
                    appointment.application.state,
                  )}
                </p>
              </div>
            </div>

            <div className="appointments__card__socials">
              <ApplicationResume
                resume={appointment.application.resume}
                tooltipText={
                  appointment.application.resume
                    ? "Preview Resume"
                    : appointment.application.resume_request_date
                    ? `Resume Requested On ${format(
                        new Date(appointment.application.resume_request_date),
                        "MM/dd/yyyy",
                      )}. Click to Request Again`
                    : "Request Resume"
                }
                handleResume={resume => handleResumeModal(resume ? "preview" : "request")}
              >
                <AnimatePresence>
                  {resumeModalType === "request" && (
                    <ApplicationModalResumeRequest
                      id={appointment.application.id}
                      name={handleFullnameCombination(appointment.application)}
                      email={appointment.application.email}
                      handleCloseModal={() => handleResumeModal(null)}
                    />
                  )}
                </AnimatePresence>

                <AnimatePresence>
                  {resumeModalType === "preview" && (
                    <AppointmentResumePreview
                      applicationDetails={appointment.application}
                      handleCloseModal={() => handleResumeModal(null)}
                    />
                  )}
                </AnimatePresence>
              </ApplicationResume>

              <ApplicationLinkedIn linkedin={appointment.application.linkedin} />

              <ApplicationSendEmail
                details={{
                  applicantName: `${appointment.application.first_name} ${appointment.application.last_name}`,
                  applicantEmail: appointment.application.email,
                  applicationDate: appointment.application.registration_date,
                  applicationPosition: appointment.application.position,
                  applicantLocation: `${appointment.application.city}${
                    appointment.application.state ? `, ${appointment.application.state}` : ""
                  }`,
                }}
              />

              <ApplicationScheduleMeeting
                meeting_type={appointment.type}
                tooltipText={appointment.type === "video" ? "Start Video Interview" : ""}
                handleScheduleMeeting={handleStartMeeting}
              />
            </div>
          </div>
        </div>

        <div className="appointments__card__footer">
          <div className="appointments__card__type">
            {appointment.type === "video" ? <VideoMeetingIcon /> : <InPersonMeetingIcon />}
            <p className="m--0">Interviewer:</p>
            <p className="m--0">{appointment.interviewer}</p>
          </div>

          <AppointmentsActions
            details={{
              id: appointment.id,
              applicationID: appointment.application.id.toString(),
              appointment_timestamp: appointment.appointment_timestamp ?? 0,
              appointment_time: appointment.appointment_time,
              timezone: appointment.timezone ?? "",
              notes: appointment.notes ?? "",
              meeting_name: appointment.meeting_name ?? "",
              meeting_duration: appointment.meeting_duration ?? 0,
              type: appointment.type,
              video_link: appointment.video_link ?? "",
              interviewer: appointment.interviewer,
              created_at: appointment.created_at,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentsCard;
