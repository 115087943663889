import { format } from "date-fns";
import {
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../../components/Applications/interfaces";

export const SECOND_INTERVIEW_REQUEST: EmailTemplateModel = {
  name: "second-interview-request",
  label: "Second Interview Request",
  subject: (details: EmailTemplateDetailsModel) =>
    `Re: Your Job Application dated ${format(new Date(details.applicationDate), "MM/dd/yyyy")}`,
  html: (details: EmailTemplateDetailsModel) => `
<p>Dear <span style="color:orangered;">${details.applicantName}</span>,</p>
        <br />
<p>Thank you for taking the time to meet with us to discuss your interest and qualifications for the position of <span style="color:orangered;">${details.applicationPosition}</span>. We were pleased to talk with you personally and to learn more about your professional qualifications. Your willingness to provide details concerning your knowledge, skills and experience was greatly appreciated.</p> 
    <br />
<p>We would like to inform you that you have passed the first round of interviews and we would like to invite you for a second interview. The details are as follows:</p>
<p style="color:orangered;">[DATE OF INTERVIEW]</p>
<p style="color:orangered;">[TIME OF INTERVIEW]</p>
<p style="color:orangered;">[PLACE/ADDRESS OF INTERVIEW]</p>
        <br />
<p>Your second interview will be with <span style="color:orangered;">[NAME AND JOB TITLE OF INTERVIEWER(S)]</span>. The interview is scheduled to last approximately <span style="color:orangered;">[LENGTH OF INTERVIEW]</span> and will take the form of <span style="color:orangered;">[SHORT DESCRIPTION OF FORMAT OF THE INTERVIEW SUCH AS PANEL, GROUP INTERVIEW, ETC.]</span>. Please contact <span style="color:orangered;">[CONTACT NAME]</span> at <span style="color:orangered;">[CONTACT TELEPHONE NUMBER]</span> to confirm your attendance at the second interview. If you have any questions, please let <span style="color:orangered;">[CONTACT NAME]</span> know.</p>
    <br />
<p>We are looking forward to meeting with you again.</p>
    <br />
<p>Sincerely,</p>
<p style="color:orangered;">${details.senderName}</p>
<p style="color:orangered;">[TITLE]</p>`,
};
