import { API_RESPONSE_ERROR_MESSAGES } from "./constants";
import { FetchHandlerRequestBody } from "./types";

/** Type definition for a custom error object that will be thrown when the response from the API is not OK. */
export type FetchErrorType = {
  message: string;
  originalRequest: FetchHandlerRequestBody<any>;
  response: {
    status: number;
    statusText: string;
    url: string;
    type: string;
    data: {
      message: string;
      exception: string;
      line: string | number;
    };
    stack: string;
  };
};

export type FetchErrorResponseType = {
  status: number;
  statusText: string;
  url: string;
  type: string;
};

/**
 *
 * Handler of the message that will be shown in the UI and logged in the error tracking service
 * based on the received response `status` and `message` from the API.
 *
 * If there's no specific `message` received in the response, uses the status-to-message mapping, to show pre-defined message.
 * If received status is not matched in the pre-defined list, use a generic message.
 *
 * @param status Status returned in the response from the API
 * @param message Message returned in the response from the API
 *
 * @returns String based message to be used for the fetch error handler.
 *
 */
export function handleFetchErrorMessage(status: number, message: string | undefined): string {
  if (message) {
    return message;
  } else {
    return API_RESPONSE_ERROR_MESSAGES[status] || "Something went wrong!";
  }
}

/**
 *
 * Handler for throwing custom error objects with specific details
 * for any erroneous response that we get from the API when using "fetch"
 *
 * @param response The response object from the API
 * @param data Object containing the data as received from the API
 *
 * @throws Custom error object
 *
 */
export function handleFetchError(
  response: FetchErrorResponseType,
  data: any,
  originalRequest: FetchHandlerRequestBody<any>,
) {
  // Error message that will be shown in the UI and logged in error tracking service
  const message: string = handleFetchErrorMessage(response.status, data.error || data.message);

  // Object to be thrown as an error for erroneous responses
  const error: FetchErrorType = {
    message,
    originalRequest,
    response: {
      status: response.status,
      statusText: response.statusText,
      url: response.url,
      type: response.type,
      stack: new Error(message)?.stack ?? "N/A",
      data: {
        message: data.message ?? "N/A",
        exception: data.exception ?? "N/A",
        line: data.line ?? "N/A",
      },
    },
  };

  // Create a form data object that will be sent to the server
  const formData = new FormData();

  // Default fields to be sent to the debugging server
  formData.append("error_message", message);
  formData.append("status", JSON.stringify(response.status));
  formData.append("statusText", response.statusText);
  formData.append("url", response.url);
  formData.append("type", response.type);

  // Append the fields received from the file upload form data
  // before sending request to the debugging server, only if we have a body from the original request
  if (originalRequest.body && originalRequest.body instanceof FormData) {
    for (const [key, val] of originalRequest.body) {
      formData.append(key, val);
    }
  }

  throw error;
}
