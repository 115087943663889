import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

const ApplicationCardListSkeletons = () => {
  return (
    <>
      {[...Array(4).keys()].map((_, index) => (
        <motion.div
          className="applications applications--list"
          key={`application-card-list-skeleton-${index}`}
          initial={{ opacity: 0, translateY: "30px" }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: "30px" }}
          transition={{
            delay: 0.1 * index,
            type: "spring",
            repeat: Infinity,
            repeatType: "mirror",
            repeatDelay: 0.6,
          }}
        >
          <div className="applications__header">
            <Skeleton width={30} height={30} />
            <Skeleton width={80} height={15} className="ml--15" />
          </div>

          {/* applications DETAILS:
        - PHOTO
        - NAME 
        - ROLE 
        - CONTACT 
        - RATING 
        - REGISTRATION DATE 
      */}
          <div className="applications__list-details-wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <Skeleton width={80} height={80} circle />

              <div className="ml--20">
                <div className="applications__details">
                  <Skeleton width={150} height={10} />
                  <Skeleton width={100} height={10} />
                </div>

                <div className="applications__contact">
                  <Skeleton width={80} height={10} />
                  <Skeleton width={80} height={10} />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <Skeleton width={80} height={15} />
              <Skeleton width={60} height={15} className="ml--10" />
            </div>
          </div>

          <div className="applications__list-actions-wrapper">
            <div className="applications__list-actions-ordering--buttons d-flex justify-content-center align-items-center mr--30 mr--md--0">
              <Skeleton width={20} height={20} circle />
              <Skeleton width={20} height={20} circle className="ml--10" />
            </div>

            <div className="applications__list-actions-ordering--socials d-flex justify-content-between align-items-center mr--30 mr--md--0">
              <Skeleton width={30} height={25} />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
            </div>

            <div className="d-flex flex-column align-items-center order-last">
              <Skeleton width={50} height={15} />
              <Skeleton width={100} height={25} />
            </div>
          </div>
        </motion.div>
      ))}
    </>
  );
};

export default ApplicationCardListSkeletons;
