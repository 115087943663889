// Hooks & Utilities
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePrivilegesGetAll } from "../../api/Privileges/Privileges";
import { useAdminUsersGet } from "../../api/Users/AdminUsers";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Components
import Card from "../../components/Card/Card";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import PrivilegesSkeleton from "./Skeletons/PrivilegesSkeleton";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";

const Privileges = () => {
  const navigate = useNavigate();

  /*=====================
    USER SELECTION
  =======================*/
  const { data: usersData, isLoading: usersLoading } = useAdminUsersGet();

  // Map the received users data into dropdown dataset
  const USERS_DROPDOWN: DropdownItem[] = useMemo(() => {
    if (!usersData || !usersData.length || usersLoading) return [];

    return usersData
      .filter(user => user.role.toLowerCase() !== "super admin")
      .map(user => {
        return { text: handleFullnameCombination(user), value: user.id };
      })
      .sort((userA, userB) => {
        return userA.text.toLowerCase() > userB.text.toLowerCase() ? 1 : -1;
      });
  }, [usersData]);

  // Redirect to the user privileges editing page on user selection
  const handleUserSelection = (userID: number) => {
    navigate(`/privileges/user/${userID}/`);
  };

  /*======================
    PRIVILEGES DATA
  =======================*/
  const { data, isLoading } = usePrivilegesGetAll();

  /*======================
    PRIVILEGES LIST
  =======================*/
  const PRIVILEGES_LIST = useMemo(() => {
    // Exit function and return default dataset if there's no data yet
    if (!data || !Object.entries(data).length || isLoading) {
      return { admin: [], public: [] };
    }

    return { admin: data.Admin, public: data.Public };
  }, [data]);

  return (
    <div className="container py--25">
      <h2 className="ml--30 mb--20">
        <strong>User Privileges</strong>
      </h2>

      {isLoading ? (
        <PrivilegesSkeleton />
      ) : (
        <>
          <div className="tabs">
            <Link to="/privileges/" className="tabs__item tabs__item--active">
              <span className="tabs__text">User Privileges</span>
            </Link>

            <Link to="/privileges/groups/" className="tabs__item">
              <span className="tabs__text">Groups</span>
            </Link>
          </div>

          <Card modifierClass="card--padding--xl">
            <PermissionCheckComponentWrapper permissions={["privileges_users_edit"]}>
              <p className="txt--black">
                Select a user to edit the user's privilege groups and individual privileges.
              </p>

              <DropdownSearchable
                placeholder="Please choose a username"
                size="full"
                items={USERS_DROPDOWN}
                handleItemSelected={(user: DropdownItem) =>
                  handleUserSelection(user.value as number)
                }
                isLoading={usersLoading}
                disabled={!USERS_DROPDOWN.length || usersLoading}
                modifierClass="mb--20"
              />

              <hr className="mb--15" />
            </PermissionCheckComponentWrapper>

            {/* PUBLIC PRIVILEGES */}
            <h3 className="txt--blue fw--semibold mb--20">Public Privileges List</h3>
            <p className="txt--black mb--40">
              Reference list of all the <strong>public</strong> privileges currently available on
              the system.
            </p>

            {Object.entries(PRIVILEGES_LIST.public).length > 0 ? (
              <div className="row mb--30">
                {Object.entries(PRIVILEGES_LIST.public).map(privilege => {
                  const privilegeGroupname: string = privilege[0];

                  return (
                    <div key={privilegeGroupname} className="col-12 col-md-6 col-lg-3 mb--25">
                      <h6 className="mb--10">
                        <strong>{privilegeGroupname}</strong>
                      </h6>
                      <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                      {Object.values(privilege[1]).map(privilegeValue => {
                        return (
                          <p
                            key={`public-privilege-${privilegeValue.id}`}
                            className="break-word txt--black txt--md mb--5"
                          >
                            {privilegeValue.label}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="txt--black">No privileges available.</p>
            )}

            {/* ADMIN-ONLY PRIVILEGES */}
            <h3 className="txt--blue fw--semibold mb--20">Admin Privileges List</h3>
            <p className="txt--black mb--40">
              Reference list of all the <strong>admin</strong> privileges currently available on the
              system.
            </p>

            {Object.entries(PRIVILEGES_LIST.admin).length > 0 ? (
              <div className="row">
                {Object.entries(PRIVILEGES_LIST.admin).map(privilege => {
                  const privilegeGroupname: string = privilege[0];

                  return (
                    <div key={privilegeGroupname} className="col-12 col-md-6 col-lg-3 mb--25">
                      <h6 className="mb--10">
                        <strong>{privilegeGroupname}</strong>
                      </h6>
                      <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                      {Object.values(privilege[1]).map(privilegeValue => {
                        return (
                          <p
                            key={`admin-privilege-${privilegeValue.id}`}
                            className="break-word txt--black txt--md mb--5"
                          >
                            {privilegeValue.label}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="txt--black">No privileges available.</p>
            )}
          </Card>
        </>
      )}
    </div>
  );
};

export default Privileges;
