// Components
import Tooltip from "../Tooltip/Tooltip";

// Assets
import { AiFillStar as RatingIcon } from "react-icons/ai";

// Interfaces
import { ApplicationRatingProps } from "./interfaces";

const ApplicationRating = ({ comments_avg_rating, modifierClass = "" }: ApplicationRatingProps) => {
  return (
    <Tooltip
      text={`${
        comments_avg_rating != null ? `${comments_avg_rating} (out of 5)` : "Application Rating"
      }`}
      size="md"
    >
      <div
        className={`applications__rating ${
          comments_avg_rating != null ? "applications__rating--active" : ""
        } ${modifierClass}`}
      >
        <RatingIcon className="mr--5" />

        <span>{comments_avg_rating == null ? "N/A" : +comments_avg_rating.toFixed(2)}</span>
      </div>
    </Tooltip>
  );
};

export default ApplicationRating;
