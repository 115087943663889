/**
 *
 * Check if the string that is received as a parameter
 * contains valid HTML tags within it.
 *
 * Example usage: Used for validation schemas test cases where we
 * throw a validation error if the string has a valid HTML contained within it,
 * such as "<h1>Example text</h1>". Note that "<h1 this is some text" will pass, as this
 * is not a valid HTML tag.
 *
 * @param text The text to be parsed and checked for existing HTML tags.
 *
 * @returns Boolean representing if the received text has HTML tags contained within it or not.
 *
 */
export function handleCheckStringForHTML(text: string): boolean {
  const document = new DOMParser().parseFromString(text, "text/html");

  // Check if at least one of the elements in the document generated
  // from the parsed string that is received as parameter, is a valid HTML tag.
  // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeTypeß
  return Array.from(document.body.childNodes).some(node => node.nodeType === 1);
}
