const OnlineApplicationFooter = () => {
  const year: number = new Date().getFullYear();
  return (
    <p className="txt--black txt--xs mb--0">
      &copy; FirstChoice Hiring 2014-{year}. All Rights Reserved.
    </p>
  );
};

export default OnlineApplicationFooter;
