// Components
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import Datepicker from "../../components/Datepicker/Datepicker";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";

// Hooks & Utilities
import { useState } from "react";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import { useReportGetAdActivation } from "../../api/Reports/Reports";
import { format, subDays } from "date-fns";
import { createColumnHelper } from "@tanstack/react-table";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";

// Interfaces & Constants
import { ReportAdActivationResponseFields } from "../../api/Reports/interfaces";
import { REPORTS_AD_ACTIVATION_DOCUMENT_COLUMNS } from "./constants";

const AdActivation = () => {
  /*===============================================
    DATE RANGE SELECTION

    The date range is handled using the custom hook
    which handles the 'preview dates' and the 
    'active dates'.
  ================================================*/
  // Call designated Report Date Range Handling hook with desired initial values
  const { pickerDates, filterDates, setFilterDates, handleStartDate, handleEndDate } =
    useReportDateRangeHandling({
      initial_start_date: format(subDays(Date.now(), 7), "MM/dd/yyyy"),
      initial_end_date: format(Date.now(), "MM/dd/yyyy"),
    });

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportAdActivationResponseFields>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Job Board</span>,
      size: 150,
      enableSorting: false,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("total", {
      header: () => <span>Total Activations</span>,
      size: 150,
      cell: data => <span>{data.getValue()}</span>,
    }),
  ];

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    isLoading: adActivationLoading,
    isRefetching: adActivationRefetching,
    isFetching: adActivationFetching,
    data: adActivationData,
  } = useReportGetAdActivation(filterDates);

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<ReportAdActivationResponseFields[]>(
    [],
  );

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">Ad Activation Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || undefined}
                    dateFormat="m/d/Y"
                    isDisabled={adActivationLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end">
                  <div className="input__label">End Date:</div>
                </div>
                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate="none"
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={adActivationLoading}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-2 col-md-4 d-flex flex-column align-items-xl-start justify-content-md-end align-items-end pr--0 pr--md--15 pb--15 pb--sm--20 ">
              <Button
                modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                onClick={() => setFilterDates(pickerDates)}
                isLoading={adActivationLoading || adActivationFetching}
                isDisabled={adActivationFetching || adActivationLoading}
              >
                Run Report
              </Button>
            </div>
          </div>
        </div>
      </Card>

      <div className="px--30">
        {!adActivationLoading ? (
          <>
            <>
              <Card modifierClass="card--padding--xl">
                <h5 className="fw--semibold">
                  Currently Viewing: {filterDates.start_date} through {filterDates.end_date}
                </h5>
              </Card>
            </>

            <Card>
              <h3 className="fw--semibold mb--5 px--20 pt--10">Export Data</h3>
              <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                  onClick={() =>
                    handleInitiateSpreadsheetGenerator(
                      exportedTableData,
                      "ad_activation_report",
                      REPORTS_AD_ACTIVATION_DOCUMENT_COLUMNS,
                      "default-csv",
                      `${filterDates.start_date} to ${filterDates.end_date}`,
                    )
                  }
                  isDisabled={Boolean(!exportedTableData.length) || adActivationFetching}
                >
                  CSV
                </Button>
                <Button
                  modifierClass="btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0"
                  onClick={() =>
                    handleInitiatePDFWorker({
                      columns: REPORTS_AD_ACTIVATION_DOCUMENT_COLUMNS,
                      data: exportedTableData,
                      documentTitle: "FirstChoice Hiring - Ad Activation Report",
                      documentSubTitle: `Data Date: ${filterDates.start_date} to ${filterDates.end_date}`,
                      template: "default-report",
                      fileName: "ad_activation_report",
                      setPDFLoading: setPDFLoading,
                    })
                  }
                  isDisabled={!exportedTableData.length || adActivationFetching || PDFLoading}
                  isLoading={PDFLoading}
                >
                  PDF
                </Button>
              </div>
              <Table
                data={adActivationData}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={adActivationRefetching}
                shouldShowSummarizedData={true}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default AdActivation;
