// Pages
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import AdActivation from "../pages/Reports/AdActivation";
import AdPerformance from "../pages/Reports/AdPerformance";
import ApplicantCount from "../pages/Reports/ApplicantCount";
import ExpiringAds from "../pages/Reports/ExpiringAds";
import HiringReport from "../pages/Reports/Hiring";
import IndeedSponsored from "../pages/Reports/IndeedSponsored";
import InternalHiring from "../pages/Reports/InternalHiring";
import JobBoardStatus from "../pages/Reports/JobBoardStatus";
import ZipRecruiterSponsored from "../pages/Reports/ZipRecruiterSponsored";
import Reports from "../pages/Reports/Reports";
// import SummaryReport from "../pages/Reports/SummaryReport";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_REPORTS: ApplicationRouteProps[] = [
  {
    path: "/reports",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <Reports />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view", "view_company_reports"],
    type: "protected",
  },
  {
    path: "/reports/expiring-ads",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ExpiringAds />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/ad-performance",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdPerformance />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/job-board-status",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <JobBoardStatus />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/applicant-count",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ApplicantCount />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/ad-activation",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdActivation />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/indeed-sponsored-ads",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <IndeedSponsored />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/hiring",
    element: (
      <LayoutAuthenticated>
        <HiringReport />
      </LayoutAuthenticated>
    ),
    permissions: ["view_company_reports"],
    type: "protected",
  },
  {
    path: "/reports/ziprecruiter-sponsored",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ZipRecruiterSponsored />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },
  {
    path: "/reports/internal-hiring",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <InternalHiring />
      </LayoutAuthenticated>
    ),
    permissions: ["reports_view"],
    type: "protected",
  },

  // NOTE: Temporarily disabled.
  // {
  //   path: "/reports/summary",
  //   element: (
  //     <LayoutAuthenticated>
  //       <SummaryReport />
  //     </LayoutAuthenticated>
  //   ),
  //   permissions: ["view_company_reports"],
  //   type: "protected",
  // },
];
