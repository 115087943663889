// Utilities
import { getIn } from "formik";

// Components
import DropdownMultiselect from "../Dropdown/DropdownMultiselect";

// Interfaces
import { FormDropdownMultiselectProps } from "./interfaces";

const FormDropdownMultiselect = ({
  field,
  form,
  handleFieldUpdate,
  ...props
}: FormDropdownMultiselectProps) => {
  /*===========================
    HANDLE ERRORS
  ============================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===========================
    APPEND MODIFIER CLASSES
  ============================*/
  const MODIFIER_CLASS: string = `${props.modifierClass} ${
    errors && touched ? "dropdown--error" : ""
  }`;

  /*===========================
    UPDATE FORMIK STATE
  
    Updates the targeted field with the selected value.
    If the latest clicked item's value was the same
    with the already selected item value, then clear out the selection
  ============================*/
  const handleSelection = (item: any[]) => {
    // Trigger field revalidation
    form.setFieldTouched(field.name);
    form.validateField(field.name);

    handleFieldUpdate(item);
  };

  return (
    <>
      <DropdownMultiselect
        {...props}
        modifierClass={MODIFIER_CLASS}
        handleSelectedItems={handleSelection}
      />
      {errors && touched ? <p className="input__error">{errors}</p> : null}
    </>
  );
};

export default FormDropdownMultiselect;
