// Utilities
import { Field, FieldArray } from "formik";
import { cloneDeep } from "lodash-es";
import React from "react";

// Components
import Button from "../Button/Button";

// Interfaces
import { OnlineApplicationArraySectionProps } from "./interfaces";

// Form Builder
import { handleFormFieldComponentProps } from "../../pages/OnlineApplication/form-builder/handleFormFieldComponentProps";
import { handleFormFieldSizes } from "../../pages/OnlineApplication/form-builder/handleFormFieldSizes";
import { handleAllowedDates } from "../../pages/OnlineApplication/form-builder/handleAllowedDates";
import {
  handleFormEntryName,
  handleFormFieldComponentMapping,
  handleFormSectionFindMatchingField,
} from "../../pages/OnlineApplication/form-builder/utilities";

const OnlineApplicationArraySection = ({
  values,
  sectionComponents,
}: OnlineApplicationArraySectionProps) => {
  const { title, section_fields, dynamic_limit } = sectionComponents;

  return (
    <FieldArray
      name={handleFormEntryName(title)}
      render={arrayHelpers => {
        const dynamicSectionName: string = handleFormEntryName(title);
        const dynamicSection: unknown = values[dynamicSectionName] as unknown;

        // Do not try to render anything if the targeted section is not a valid array
        if (!Array.isArray(dynamicSection)) return null;

        if (Array.isArray(dynamicSection))
          return (
            <>
              {dynamicSection.map((section, sectionIndex) => {
                const individualFields = Object.keys(section);

                return (
                  <React.Fragment key={`section-${sectionIndex}`}>
                    {individualFields.map((field, index) => (
                      <div
                        key={`section-${sectionIndex}-field-${index}`}
                        className={`col-12 ${handleFormFieldSizes(
                          handleFormSectionFindMatchingField(field, section_fields).width,
                        )}`}
                      >
                        <Field
                          id={`${dynamicSectionName}.${sectionIndex}.${field}`}
                          name={`${dynamicSectionName}.${sectionIndex}.${field}`}
                          component={handleFormFieldComponentMapping(
                            handleFormSectionFindMatchingField(field, section_fields).type,
                          )}
                          {...handleFormFieldComponentProps(
                            handleFormSectionFindMatchingField(field, section_fields),
                            values,
                          )}
                          {...handleAllowedDates(values, dynamicSectionName, sectionIndex, field)}
                        />
                      </div>
                    ))}

                    {/* Display "Remove" button, but only for items that are manually added by the user */}
                    {sectionIndex > sectionComponents.repetitions ? (
                      <Button
                        type="button"
                        modifierClass="btn--remove-entry btn--primary"
                        onClick={() => arrayHelpers.remove(sectionIndex)}
                      >
                        Remove Entry
                      </Button>
                    ) : null}

                    <hr className="online-application__section__divider" />
                  </React.Fragment>
                );
              })}

              {/* Prevent adding new items if the limit is reached */}
              {dynamicSection.length < dynamic_limit ? (
                <p
                  className="online-application__section__add-field"
                  onClick={() => {
                    const dynamicSectionFieldsCopy = cloneDeep(dynamicSection[0]);
                    Object.keys(dynamicSectionFieldsCopy).forEach(key => {
                      dynamicSectionFieldsCopy[key] = undefined;
                    });
                    arrayHelpers.push(dynamicSectionFieldsCopy);
                  }}
                >
                  + Add Additional {title}
                </p>
              ) : null}
            </>
          );
      }}
    />
  );
};

export default OnlineApplicationArraySection;
