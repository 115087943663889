import { API_BASE_URL } from "../config/config";
import { LocalStorageActions } from "../utilities/handleLocalStorage";
import { handleFetchError } from "./errorHandling";
import { handleAuthenticationTokensRemovalFromStorage } from "./tokenRefresher";
import { FetchHandlerMethods, FetchHandlerUploadRequestBody, FetchHandlerURL } from "./types";

/**
 * Utility function for handling all fetch calls in the application that
 * need to upload a file to the server.
 * @param method  The method that will be used in the API request
 * @param url The URL to which the API request will be sent
 * @param body The body that will be sent in the API request
 * @returns Processed data from the API response, or throws an error if something went wrong.
 */
export default async function fetchHandlerUpload<T>(
  method: FetchHandlerMethods = "GET",
  url: FetchHandlerURL,
  body: FetchHandlerUploadRequestBody<T>,
) {
  // Reads the saved access token
  const accessToken = LocalStorageActions.getItem("accessToken");

  try {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method,
      headers: {
        Accept: "application/json",
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
      body,
    });

    // If the response status is 204, just exit the function
    if (response.status === 204) return;

    // If the response status is 401, logout the unauthenticated user
    if (response.status === 401) {
      // Remove tokens and expiration time from local storage
      handleAuthenticationTokensRemovalFromStorage();

      // Redirect the user to the login page
      location.replace("/login/");

      return null;
    }

    // Parse the response that is returned by the API
    const data = await response.json();

    // Throw an error if the response is not ok
    if (!response.ok) handleFetchError(response, data, { body });

    // If everything is OK, return the received data
    return data;
  } catch (error: any) {
    handleFetchError(
      { status: 499, statusText: "Client Closed Request", url, type: "cors" },
      { error: error.message },
      { body },
    );
  }
}
