import { Link, useParams } from "react-router-dom";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";

const OnlineApplicationUploadThankYou = () => {
  const { type } = useParams();

  return (
    <div className="online-application-thank-you">
      <div className="container">
        <Link to="/login/">
          <img
            src={FCHLogo}
            className="online-application-thank-you__logo"
            title="FirstChoice Hiring logo"
          />
        </Link>

        <h3 className="online-application-thank-you__header online-application-thank-you__header--assessment-thank-you">
          Thank You!
        </h3>

        <p className="online-application-thank-you__paragraph">
          Thank you for uploading your {type}. We appreciate your interest in this career
          opportunity.
        </p>

        <p className="online-application-thank-you__paragraph mb--100">
          Please check your spam folder, or add no-reply@firstchoicehiring.com to your address book
          to ensure you receive valuable communications from us in the future.
        </p>

        <OnlineApplicationFooter />
      </div>
    </div>
  );
};

export default OnlineApplicationUploadThankYou;
