// Utilities & Hooks
import { useEffect, useState } from "react";
import { useChatGetMessages } from "../../../api/Chat/Chat";
import { groupBy } from "lodash-es";
import { format } from "date-fns";
import { useAuth } from "../../../providers/auth-context";
import { useQueryClient } from "@tanstack/react-query";

// Components
import CustomScrollbars from "../../CustomScrollbars/CustomScrollbars";
import ChatConversationMessageGroup from "./ChatConversationMessageGroup";
import ChatConversationMessageSkeleton from "./ChatConversationMessageSkeleton";
import ChatConversationForm from "./ChatConversationForm";
import Skeleton from "react-loading-skeleton";

// Interfaces
import { ChatConversationMessageGroups, ChatConversationProps } from "./interfaces";

// Constants
import { CHATS_MESSAGE_SKELETON_PLACEHOLDERS } from "../constants";
import { ChatResponseFields } from "../../../api/Chat/interfaces";

// Socket
import socket from "../../../config/chat-socket";
import { useChatsContext } from "../ChatWrapper/ChatContextWrapper";

const ChatConversation = ({
  chatID,
  chatConversationDetails,
  modifierClass = "",
  scrollableHeight,
  chatLocation = "page",
  selectedUserID,
}: ChatConversationProps) => {
  const chatsContext = useChatsContext();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  /*================================
    FETCH ALL THE MESSAGES FOR
    THE TARGETED CHAT
  =================================*/
  const { data, isLoading } = useChatGetMessages(chatID);

  /*================================
    MAP RECEIVED CHAT MESSAGES DATA
  =================================*/
  const [chatMessages, setChatMessages] = useState<ChatResponseFields[]>([]);
  const [chatMessagesUI, setChatMessagesUI] = useState<ChatConversationMessageGroups[]>([]);

  // Fetch the messages for the opened chat
  useEffect(() => {
    if (!data || !data.messages || !data.messages.length) return;
    setChatMessages(data.messages);
  }, [data]);

  // Map the chat messages state items into groups of messages
  // that will be then displayed in the UI
  useEffect(() => {
    if (!chatMessages.length) return;

    // Group the messages by date
    const groupedMessages = groupBy(chatMessages, message => {
      const formattedMessageDate: string = format(
        new Date(message.timestamp * 1000),
        "MMM dd yyyy",
      );
      return formattedMessageDate;
    });

    // If there are no groups of messages, exit function
    if (!Object.entries(groupedMessages).length) return;

    // Convert the grouped messages object to an ittreable array
    // in reverse order, meaning the latest messages will be
    // shown at the bottom of the conversation
    const arrayOfGroupedMessages = Object.entries(groupedMessages).map(message => {
      const [key, value] = message;
      return { groupDate: key, groupMessages: value };
    });

    setChatMessagesUI(arrayOfGroupedMessages);
  }, [chatMessages]);

  /*=====================================
    SEND A MESSAGE TO THE SOCKET
  ======================================*/
  const handleChatSendNewMessage = (message: string) => {
    // NOTE: We prevent sending the message if there are no permissions / if its readonly mode
    // within the ChatConversationForm component itself.
    if (!message) return;

    // Update the UI with the message that we just sent
    const SENT_MESSAGE_TIMESTAMP = new Date().getTime() / 1000;

    const SENT_MESSAGE: ChatResponseFields = {
      id: `${chatID}_${chatMessages.length + 1}`,
      chat_id: chatID,
      direction: "out",
      content: message,
      is_read: 1,
      timestamp: SENT_MESSAGE_TIMESTAMP,
      status: null,
    };

    // Update the UI state for the displayed chat messages
    const outboundMessages = [...chatMessages];
    outboundMessages.push(SENT_MESSAGE);
    setChatMessages(outboundMessages);

    // Emit event to the socket with the newly sent message
    socket.emit("outbound message", {
      chat_id: chatID,
      content: message,
      temporary_id: SENT_MESSAGE.id,
    });

    // After successfully sending a message to an existing chat,
    // update that chat's last message properties that are displayed
    // in the chat messages dropdown menu
    if (!chatsContext.existingUserChats) return;

    const updatedExistingUserChats = [...chatsContext.existingUserChats];

    // Find the matching chat, move it to the start of the list and update it's last message value
    const matchingChatIndex: number = updatedExistingUserChats.findIndex(existingChat => {
      return existingChat.id === chatID;
    });

    // Exit if chat cannot be found
    if (matchingChatIndex < 0) return;

    // Update the last message value
    updatedExistingUserChats.splice(matchingChatIndex, 1, {
      ...updatedExistingUserChats[matchingChatIndex],
      last_message: {
        ...updatedExistingUserChats[matchingChatIndex].last_message,
        content: message,
        timestamp: SENT_MESSAGE_TIMESTAMP,
      },
    });

    // Update the cached query data
    queryClient.setQueryData(["sms-chats", user.id], updatedExistingUserChats);
  };

  socket.on("inbound message", (incomingMessage: ChatResponseFields) => {
    // Prevent reading the socket event and triggering functionality
    // when the logged in user is reading the chats & messages of another user
    if (selectedUserID !== user.id) return;

    // Prevent state updates if the ID of the chat to which the message belongs to
    // does not corresponds to the currently opened chat
    if (incomingMessage.chat_id !== chatID) return;

    // Update the state with all the incoming messages
    const incomingMessages = [...chatMessages];
    incomingMessages.push(incomingMessage);
    setChatMessages(incomingMessages);
  });

  // Mark the selected chat as active so it can
  // trigger a socket event to mark the message as read
  useEffect(() => {
    // Prevent marking the message as read if logged in user
    // is trying to see the messages of another user
    if (selectedUserID !== user.id) return;

    // Mark the chat as active
    chatsContext.handleChatSetActive(chatID);

    // When the component unmounts, set the active chat to the default value
    return () => chatsContext.handleChatSetActive(null);
  }, [chatID, selectedUserID]);

  /*=====================================
    MESSAGE RECEIVED & STATUS EVENTS
  ======================================*/
  socket.on("message_received", messageDetails => {
    // Prevent reading the socket event and triggering functionality
    // when the logged in user is reading the chats & messages of another user
    if (selectedUserID !== user.id) return;

    // If the message does not belong to this chat, exit
    if (messageDetails.chat_id !== chatID) return;

    // Find the message from the list of already
    // present messages that should be updated in the UI
    const updatedMessages = [...chatMessages];
    const updatedMessageIndex: number = updatedMessages.findIndex(message => {
      return message.id === messageDetails.temporary_id;
    });

    // If the message cannot be found, exit
    if (updatedMessageIndex < 0) return;

    // Update the "id" property of the targeted message,
    // from its "temporary" value to the "real" value received from the server
    updatedMessages.splice(updatedMessageIndex, 1, {
      ...updatedMessages[updatedMessageIndex],
      id: messageDetails.id,
    });

    setChatMessages(updatedMessages);
  });

  socket.on("message_status", messageDetails => {
    // Prevent reading the socket event and triggering functionality
    // when the logged in user is reading the chats & messages of another user
    if (selectedUserID !== user.id) return;

    // If the message does not belong to this chat, exit
    if (messageDetails.chat_id !== chatID) return;

    // Find the message from the list of already
    // present messages that should be updated in the UI
    const updatedMessages = [...chatMessages];
    const updatedMessageIndex: number = updatedMessages.findIndex(message => {
      return message.id === messageDetails.message_id;
    });

    // If the message cannot be found, exit
    if (updatedMessageIndex < 0) return;

    // Update the "status" property of the targeted message
    updatedMessages.splice(updatedMessageIndex, 1, {
      ...updatedMessages[updatedMessageIndex],
      status: messageDetails.status,
    });

    setChatMessages(updatedMessages);
  });

  return (
    <div
      className={`chat-conversation chat-conversation--location--${chatLocation} ${modifierClass}`}
    >
      <div className="chat-conversation__messages">
        <CustomScrollbars
          maxHeight={scrollableHeight}
          autoScrollToBottom={{ enabled: true, updateTrigger: chatMessagesUI }}
        >
          {isLoading
            ? CHATS_MESSAGE_SKELETON_PLACEHOLDERS.map(placeholder => (
                <ChatConversationMessageSkeleton
                  chatLocation={chatLocation}
                  direction={placeholder}
                />
              ))
            : chatMessagesUI.map(group => (
                <ChatConversationMessageGroup
                  key={group.groupDate}
                  groupDate={group.groupDate}
                  groupMessages={group.groupMessages}
                  chatConversationDetails={chatConversationDetails}
                />
              ))}
        </CustomScrollbars>
      </div>

      {isLoading ? (
        <div
          className={`chat-conversation__form__skeleton ${
            chatLocation === "panel" ? "chat-conversation__form__skeleton--panel" : ""
          }`}
        >
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <ChatConversationForm
          chatLocation={chatLocation}
          selectedUserID={selectedUserID}
          handleSendChatMessage={handleChatSendNewMessage}
        />
      )}
    </div>
  );
};

export default ChatConversation;
