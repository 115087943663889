// Assets
import FavoriteIcon from "../../../assets/images/icons/applications-favorite-icon.svg?react";

// Utilities & Hooks
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useApplicationsMoveToBucket,
  useApplicationsUndoBucketMovement,
} from "../../../api/Applications/Applications";
import useErrorReporting from "../../../hooks/useErrorReporting";
import useFindTargetedBucket from "./useFindTargetedBucket";

// Components
import Tooltip from "../../Tooltip/Tooltip";
import Loader from "../../Loader/Loader";

// Interfaces
import { ApplicationBucketActionsProps, ApplicationBucketPreUpdateDetails } from "../interfaces";

const ApplicationFavorite = ({
  id,
  currentBuckets = [],
  text = "",
  name = "",
  shouldShowLoader = false,
}: ApplicationBucketActionsProps) => {
  const [handleFindTargetedBucket] = useFindTargetedBucket();
  const errorReporting = useErrorReporting();

  /*========================
    CHECK IF FAVORITED

    Should the favorite icon be marked in "active" state or not.
  =========================*/
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    if (!currentBuckets.length) return;

    // Check if one of the buckets is named "Favorite"
    setIsFavorite(currentBuckets.some((bucket: string) => bucket.toLowerCase() === "favorites"));
  }, [currentBuckets]);

  /*========================
    PRE-UPDATE DETAILS

    These will be used  to handle "Undo" operations
    containing the necessary bucket informations 
    before the bucket movement action was triggered.
  =========================*/
  const [preUpdateDetails, setPreUpdateDetails] = useState<ApplicationBucketPreUpdateDetails>({
    bucketName: null,
    bucketID: null,
  });

  useEffect(() => {
    if (!currentBuckets.length) return;

    // Extract the bucket name pre-update
    const bucketName: string = currentBuckets[0];
    const bucketID: number | undefined = handleFindTargetedBucket(currentBuckets[0]);

    // Exit function if the bucket cannot be found in the list
    if (!bucketID) return;

    setPreUpdateDetails({ bucketName, bucketID });
  }, [currentBuckets]);

  /*=================================
    MOVE THE SELECTED APPLICATIONS
    TO THE SPECIFIED BUCKET
  =================================*/
  const moveApplication = useApplicationsMoveToBucket();

  const handleApplicationMoveToBucket = async () => {
    // Find the ID and name of the bucket to which the application is about to be moved
    const bucketName: string = isFavorite ? "Current" : "Favorites";
    const bucketID = handleFindTargetedBucket(bucketName);

    // Exit function preventing any API requests if there's no bucket ID to work with
    if (!bucketID) return;

    try {
      // Display notification to the user with a possible "Undo" action
      toast.success(
        <div className="Toastify__undo-notification">
          <strong>{name}</strong> moved to:
          <br />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <strong>{bucketName}</strong>
            </span>

            {preUpdateDetails.bucketID ? (
              <span
                className="Toastify__undo-notification__btn"
                onClick={handleApplicationUndoBucketMovement}
              >
                Undo
              </span>
            ) : null}
          </div>
        </div>,
        {
          toastId: "buckets-favorite",
        },
      );

      await moveApplication.mutateAsync({
        bucket_id: bucketID,
        bucket_name: bucketName,
        application_ids: [id],
      });
    } catch (error) {
      // Dismiss the matching success notification from the UI
      toast.dismiss("buckets-favorite");

      errorReporting("Failed moving application to 'Favorites' bucket", error, {
        application_id: id,
        bucket_id: bucketID,
        bucket_name: bucketName,
      });
    }
  };

  /*=================================
    UNDO APPLICATION BUCKET ACTION
  =================================*/
  const undoBucketMovement = useApplicationsUndoBucketMovement();

  const handleApplicationUndoBucketMovement = async () => {
    // Exit function if there's no valid pre-update bucket ID value
    if (!preUpdateDetails.bucketID) return;

    try {
      // Revert application to its previous bucket
      await undoBucketMovement.mutateAsync({
        bucket_id: preUpdateDetails.bucketID,
        application_ids: [id],
      });
    } catch (error) {
      errorReporting("Failed reverting application bucket changes", error, {
        application_id: id,
        bucket_id: preUpdateDetails.bucketID,
      });
    }
  };

  return (
    <Tooltip text="Favorite">
      <div
        className={`applications__actions__icon applications__actions__icon--favorite ${
          isFavorite ? "applications__actions__icon--favorite--active" : ""
        }`}
        onClick={handleApplicationMoveToBucket}
        data-testid="component:application-bucket-favorite"
      >
        {shouldShowLoader && (moveApplication.isLoading || undoBucketMovement.isLoading) ? (
          <Loader size="sm" />
        ) : (
          <>
            <FavoriteIcon />
            {text && <p className="applications__actions__text">{text}</p>}
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default ApplicationFavorite;
