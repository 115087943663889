import { PrivilegesFields, PrivilegesUserGroups } from "../../api/Privileges/interfaces";

/**
 *
 * Utility function used for checking if at least one of the
 * selected privileges from the list, is part of the "admin" group.
 *
 * @param adminPrivileges The list of admin privileges fetched from the API.
 * @param privilegesSelection The current array of selected privileges IDs
 *
 * @returns A `boolean` representing if the current selection has at least 1
 * selected privilege which is part of the "admin" group.
 */
export function handleCheckPrivilegesSelection(
  adminPrivileges: PrivilegesFields | never[],
  privilegesSelection: number[],
): boolean {
  // Exit function if there's no data to work with
  if (!adminPrivileges || !Object.values(adminPrivileges).length) return false;

  return Object.values(adminPrivileges).some(adminPrivilegesGroups => {
    return adminPrivilegesGroups.some(adminPrivilege => {
      return privilegesSelection.some(id => id === adminPrivilege.id);
    });
  });
}

/**
 *
 * Check if the rendered checkbox in the UI is pre-selected or not,
 * based on the array of IDs that is received as a value.
 *
 * If the targeted checkbox ID is part of that array, then it's marked as checked.
 *
 * This function is used for handling pre-selection of both `Privileges` and `Privilege Groups`.
 *
 * @param targetedID The ID of the targeted checkbox item.
 * @param preselectedIDs The array of ID values that represent which items are pre-selected.
 *
 * @returns A `boolean` value representing if the targeted checkbox item,
 * `privilege` or `privilege group` should be marked as checked or not.
 *
 */
export function handlePrivilegesCheckboxPreselection(
  targetedID: number,
  preselectedIDs: number[],
): boolean {
  // Do not pre-select the targeted checkbox item if there are no pre-selected items received
  if (!preselectedIDs || !preselectedIDs.length) return false;

  // Check if the targeted checkbox item is part of the received pre-selected IDs
  return preselectedIDs.some(id => id === targetedID);
}

/**
 *
 * Utility function for marking the `Privilege Group` with an asterix (*)
 * if there are any differences between the permissions that the group originally has
 * and the list of currently selected permissions.
 *
 * If additional permissions are selected, which don't belong to the original list,
 * we mark the group with an asterix.
 *
 * If permissions that belong to the original list are de-selected,
 * we mark the group with an asterix.
 *
 * The asterix (*) in this case signals that there are differences.
 *
 * @param selectionDifferences Privilege IDs selection differences
 * @param selectedGroups The currently selected group IDs
 * @param groupDetails The details of the currently targeted (mapped) group
 *
 * @returns A `string` representing the label to be used by the group field.
 *
 */
export const handlePrivilegesGroupFieldLabelMarking = (
  selectionDifferences: number[],
  selectedGroups: number[],
  groupDetails: PrivilegesUserGroups,
) => {
  const { id, name } = groupDetails;

  // Update field label if the targeted group is currently marked as selected,
  // and there are differences in the privilege IDs selection
  const check: boolean = selectionDifferences.length > 0 && selectedGroups.includes(id);
  return check ? `${name} *` : name;
};

/**
 *
 * Utility function for marking the individual `Privileges` with an asterix (*)
 * if there are any differences between the permissions that belong to the union
 * of the currently selected groups, and the currently selected list of permissions.
 *
 * Behaves the same as the above function for hnadling the label markings for groups.
 *
 * @param selectionDifferences Privilege IDs selection differences.
 * @param privilegeDetails The details of the currently tageted (mapped) privilege.
 *
 * @returns A `string` representing the label to be used by the group field.
 *
 */
export const handlePrivilegesFieldLabelMarking = (
  selectionDifferences: number[],
  privilegeDetails: any,
): string => {
  const { id, label } = privilegeDetails;

  // Update field label if the targeted privilege ID exists in the differences array
  const check: boolean = selectionDifferences.includes(id);
  return check ? `${label} *` : label;
};
