// Utilities & Hooks
import { format } from "date-fns";
import { parseJSON } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useBucketsReorder } from "../../api/Buckets/Buckets";
import useErrorReporting from "../../hooks/useErrorReporting";

// DnD Kit utilities & components
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";

// Components
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import BucketsDeleteModal from "./BucketsDeleteModal";
import BucketsEditCustomBucketModal from "./BucketsEditCustomBucketModal";
import BucketsDraggableItem from "./BucketsDraggableItem";
import BucketsDragOverlayItem from "./BucketsDragOverlayItem";
import Tooltip from "../Tooltip/Tooltip";
import Banner from "../Banner/Banner";

// Assets
import { BsInfoCircle as InfoIcon } from "react-icons/bs";

// Interfaces
import { BucketsEditModalProps, BucketsEditModalTableFields } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const BucketsEditModal = ({ buckets, isFetching, handleCloseModal }: BucketsEditModalProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    MAP THE RECEIVED
    BUCKETS DATA FOR THE TABLE
  ==========================*/
  const BUCKETS_TABLE_DATA = useMemo(() => {
    if (!buckets || !buckets.length) return [];

    // Filter out the default buckets & map the buckets data into fields matching the table fields
    const BUCKETS_MAPPED_DATA: BucketsEditModalTableFields[] = buckets
      .filter(bucket => !bucket.default)
      .map((bucket, bucketIndex: number) => {
        return {
          id: bucket.id,
          name: bucket.name,
          display_order: bucket.display_order,
          default: bucket.default || false,
          bucket_index: bucketIndex + 1,
          last_updated: !bucket.default
            ? format(parseJSON(bucket.updated_at), "MM-dd-yy hh:mm bbb")
            : "System Default",
        };
      });

    return BUCKETS_MAPPED_DATA;
  }, [buckets]);

  /*=======================
    BUCKET ACTIONS
  ========================*/
  const [bucketID, setBucketID] = useState<number | null>(null);
  const [isBucketDeleteModalOpen, setIsBucketDeleteModalOpen] = useState<boolean>(false);
  const [isBucketEditModalOpen, setIsBucketEditModalOpen] = useState<boolean>(false);

  const handleBucketEditingNestedModals = (bucketID: number, modal: "edit" | "delete") => {
    setBucketID(bucketID);

    if (modal === "delete") {
      setIsBucketDeleteModalOpen(true);
    } else {
      setIsBucketEditModalOpen(true);
    }
  };

  const handleCloseBucketEditingNestedModals = () => {
    setBucketID(null);
    setIsBucketDeleteModalOpen(false);
    setIsBucketEditModalOpen(false);
  };

  /*=============================
    DRAG AND DROP BUCKETS
  ==============================*/
  const [draggableBuckets, setDraggableBuckets] = useState<BucketsEditModalTableFields[]>([]);
  const [draggedBucketID, setDraggedBucketID] = useState<number | null>(null);

  // Populate the draggable buckets state with
  // the mapped buckets memoized items
  useEffect(() => {
    // Only include the custom made buckets in the array of draggable items
    const filteredDraggableBuckets = [...BUCKETS_TABLE_DATA].filter(bucket => {
      return bucket.default === false;
    });

    setDraggableBuckets(filteredDraggableBuckets);
  }, [BUCKETS_TABLE_DATA]);

  // Define the sensors to be used by the DndContext
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Mark the bucket that's being dragged at the moment
  const handleOnDragStart = (event: DragStartEvent) => {
    setDraggedBucketID(event.active.id as number);
  };

  // Update the position of the dragged item,
  // and update the state of the draggable buckets,
  // which can then be used to send the request to the API
  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    // Exit function and do nothing if there's no valid
    // "over" value (e.g. dragged item is not dropped on a valid item)
    if (!over) return;

    if (active.id !== over.id) {
      setDraggableBuckets(buckets => {
        // Starting index of the dragged item
        const startingIndex: number = buckets.findIndex(bucket => {
          return bucket.id === active.id;
        });

        // Updated index of the dragged item where the item was dropped
        const updatedIndex: number = buckets.findIndex(bucket => {
          return bucket.id === over.id;
        });

        // Utility function from DnD Kit to handle the re-positioning
        // of the buckets items in the draggable buckets array
        return arrayMove(buckets, startingIndex, updatedIndex);
      });
    }

    // Clear the dragged bucket ID
    setDraggedBucketID(null);
  };

  /*=======================
    SEND REQUEST TO THE 
    API WITH REORDERED BUCKETS
  =======================*/
  const bucketsReorder = useBucketsReorder();
  const handleBucketReorder = async () => {
    // Exit function and prevent any requests if there are no draggable (custom) buckets to workk with
    if (!draggableBuckets.length) return;

    try {
      const reorderedBucketsIDs: number[] = draggableBuckets.map(bucket => bucket.id);
      await bucketsReorder.mutateAsync(reorderedBucketsIDs);
    } catch (error) {
      errorReporting("Failed reordering buckets", error, {
        bucket_ids: draggableBuckets.map(bucket => bucket.id),
      });
    }
  };

  return (
    <>
      <Modal
        title=""
        text=""
        modifierClass="modal--lg modal--fixated"
        handleCloseModal={handleCloseModal}
      >
        <Banner
          text={
            <>
              The buckets <em>Current</em>, <em>Favorites</em>, <em>Interviewed</em>,{" "}
              <em>Offer Pending</em>, <em>Hired</em> and <em>Archived</em> are default buckets for
              all companies and cannot be modified.
            </>
          }
          icon={<InfoIcon />}
          modifierClass="banner--info"
        />
        {!draggableBuckets.length ? (
          <div className="d-flex justify-content-center mb--20">
            <h4>No custom buckets available.</h4>
          </div>
        ) : (
          <>
            <div className="buckets__edit__header row">
              <h6 className="col-2 txt--left">No.</h6>
              <h6 className="col-3 txt--left">Bucket Name</h6>
              <h6 className="col-4">Action</h6>
              <h6 className="col-3">Last Updated</h6>
            </div>
            <ul>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleOnDragStart}
                onDragEnd={handleOnDragEnd}
                modifiers={[restrictToParentElement, restrictToVerticalAxis]}
              >
                <SortableContext items={draggableBuckets} strategy={verticalListSortingStrategy}>
                  {draggableBuckets.map(bucket => (
                    <BucketsDraggableItem
                      key={bucket.id}
                      bucket={bucket}
                      activeBucketID={draggedBucketID}
                      bucketsRefetching={isFetching}
                      handleBucketEditingNestedModals={handleBucketEditingNestedModals}
                    />
                  ))}
                </SortableContext>

                {createPortal(
                  <DragOverlay>
                    <BucketsDragOverlayItem
                      bucket={draggableBuckets.find(bucket => bucket.id === draggedBucketID)!}
                    />
                  </DragOverlay>,
                  document.body,
                )}
              </DndContext>
            </ul>
          </>
        )}

        <div className="buckets__edit__footer">
          <div className="modal__actions">
            <Button modifierClass="btn--fluid btn--primary--light" onClick={handleCloseModal}>
              Cancel
            </Button>

            <Tooltip
              text={draggableBuckets.length ? "" : "No custom buckets available to re-order."}
              size="lg"
              modifierClass="fw--medium"
            >
              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary"
                onClick={handleBucketReorder}
                isDisabled={bucketsReorder.isLoading || isFetching || !draggableBuckets.length}
                isLoading={bucketsReorder.isLoading || isFetching}
              >
                {isFetching ? "Refetching Buckets" : "Save Order"}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Modal>

      <AnimatePresence>
        {isBucketDeleteModalOpen && (
          <BucketsDeleteModal
            bucketID={bucketID}
            bucketName={buckets.find(bucket => bucket.id === bucketID)?.name}
            handleCloseBucketDeleteModal={handleCloseBucketEditingNestedModals}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isBucketEditModalOpen && (
          <BucketsEditCustomBucketModal
            bucketID={bucketID}
            bucketName={buckets.find(bucket => bucket.id === bucketID)?.name}
            handleCloseModal={handleCloseBucketEditingNestedModals}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default BucketsEditModal;
