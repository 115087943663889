import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

export const PRIVILEGES_GROUPS_CRUD_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required("Please enter the name of the privileges group.")
    .max(30, "Maximum of 30 characters allowed!")
    .test("privileges-name", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  description: Yup.string()
    .required("Please enter the description of the privileges group.")
    .max(255, "Maximum of 255 characters allowed!")
    .test("privileges-description", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  permissions: Yup.array()
    .of(Yup.number())
    .required("Please select at least 1 (one) privilege to be added to the group.")
    .min(1, "Please select at least 1 (one) privilege to be added to the group."),
});

export const PRIVILEGES_USER_UPDATES_SCHEMA = Yup.object().shape({
  companies: Yup.array().of(Yup.number()).min(1, "Please select a company from the dropdown menu."),
  groups: Yup.array().of(Yup.number()).min(1, "Please select at least one privileges group."),
  permissions: Yup.array()
    .of(Yup.number())
    .test("privileges", "Please select at least one privilege", (value, context) => {
      const { groups } = context.parent;

      // Show an error message if there are no selected privileges at the moment
      if (groups.length > 0 && value!.length === 0) return false;

      return true;
    }),
});
