// Hooks and Utilities
import { useEffect, useState } from "react";
import { useJobAdsJobBoardsUpdate } from "../../../api/JobAds/JobAds";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import useErrorReporting from "../../../hooks/useErrorReporting";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";

// Components
import { Formik, Form } from "formik";
import Card from "../../Card/Card";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";

// Constants
import {
  JOB_BOARD_FORM_APPCAST_DEFAULTS,
  JOB_BOARD_FORM_CAREERBUILDER_DEFAULTS,
  JOB_BOARD_FORM_CRAIGSLIST_DEFAULTS,
  JOB_BOARD_FORM_INDEED_DEFAULTS,
  JOB_BOARD_FORM_LINKEDIN_DEFAULTS,
  JOB_BOARD_FORM_SNAG_A_JOB_DEFAULTS,
  JOB_BOARD_FORM_ZIP_RECRUITER_DEFAULTS,
  JOB_BOARD_FORM_TALENT_DEFAULTS,
  JOB_BOARD_FORM_FIRSTCHOICE_DEFAULTS,
  // JOB_BOARD_FORM_GLASSDOOR_DEFAULTS,
} from "./JobBoardsForms/constants";

// Interfaces
import { JobBoardsSectionProps, JobBoardTypes } from "./interfaces";
import { JobAdsJobBoardData } from "../../../api/JobAds/interfaces";

// Assets
import InfoIcon from "../../../assets/images/icons/info-icon.svg?react";

// Forms
import AppcastForm from "./JobBoardsForms/AppcastForm";
import Button from "../../Button/Button";
import LinkedInForm from "./JobBoardsForms/LinkedInForm";
import SnagAJobForm from "./JobBoardsForms/SnagAJobForm";
import CraigslistForm from "./JobBoardsForms/CraigslistForm";
import IndeedForm from "./JobBoardsForms/IndeedForm";
import ZipRecruiterForm from "./JobBoardsForms/ZipRecruiterForm";
import CareerBuilderForm from "./JobBoardsForms/CareerBuilderForm";
import TalentForm from "./JobBoardsForms/TalentForm";
import FirstChoiceForm from "./JobBoardsForms/FirstChoiceForm";
// import GlassdoorForm from "./JobBoardsForms/GlassdoorForm";

// Schemas
import { JOB_BOARDS_FORM_SCHEMA } from "../../../schemas/JobAdSchemas";

const JobBoards = ({
  job_boards,
  job_boards_data,
  job_board_data_career_builder,
  screening_questions,
  state = null,
}: JobBoardsSectionProps) => {
  const { id } = useParams();
  const errorReporting = useErrorReporting();
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  /*=======================
   JOB BOARD TAB CONTROL
  ========================*/
  const [selectedJobBoard, setSelectedJobBoard] = useState<JobBoardTypes>("careerbuilder");

  const handleJobBoardSelection = (job_board: JobBoardTypes) => {
    setSelectedJobBoard(job_board);

    // Update the state of the search parameters object with the selected value
    setSearchParametersObject({
      ...searchParametersObject,
      job_board,
    });
  };

  // Pre-select the job board based on received search parameter
  useEffect(() => {
    if (searchParametersObject.job_board && job_boards.length) {
      // Check for existance of a matching job board based on received value
      const matchingJobBoard = job_boards.find(board => {
        return board.name.toLowerCase() === searchParametersObject.job_board.toLowerCase();
      });

      // Exit function if there's no match found
      if (!matchingJobBoard) return;

      setSelectedJobBoard(searchParametersObject.job_board);
    }
  }, [job_boards]);

  /*=======================
   JOB BOARD FORM VALUES
  ========================*/
  const [formValues, setFormValues] = useState<JobAdsJobBoardData>({
    careerbuilder: JOB_BOARD_FORM_CAREERBUILDER_DEFAULTS,
    zip_recruiter: JOB_BOARD_FORM_ZIP_RECRUITER_DEFAULTS,
    indeed: JOB_BOARD_FORM_INDEED_DEFAULTS,
    craigslist: JOB_BOARD_FORM_CRAIGSLIST_DEFAULTS,
    snagajob: JOB_BOARD_FORM_SNAG_A_JOB_DEFAULTS,
    linkedin: JOB_BOARD_FORM_LINKEDIN_DEFAULTS,
    appcast: JOB_BOARD_FORM_APPCAST_DEFAULTS,
    talent: JOB_BOARD_FORM_TALENT_DEFAULTS,
    firstchoice: JOB_BOARD_FORM_FIRSTCHOICE_DEFAULTS,
    // glassdoor: JOB_BOARD_FORM_GLASSDOOR_DEFAULTS,
  });

  useEffect(() => {
    if (!job_boards_data || !Object.entries(job_boards_data).length) return;

    // Prepopulate the form values with the data received from the API
    setFormValues({
      careerbuilder: job_boards_data.careerbuilder ?? JOB_BOARD_FORM_CAREERBUILDER_DEFAULTS,
      zip_recruiter: job_boards_data.zip_recruiter
        ? {
            ...job_boards_data.zip_recruiter,
            screening_questions: screening_questions.zip_recruiter || [
              { question: "", is_required: "no", preferred_answers: [] },
            ],
          }
        : JOB_BOARD_FORM_ZIP_RECRUITER_DEFAULTS,
      indeed: job_boards_data.indeed
        ? {
            ...job_boards_data.indeed,
            state: state,
            promote_on_indeed: job_boards_data.indeed?.promote_on_indeed ?? "no",
          }
        : JOB_BOARD_FORM_INDEED_DEFAULTS,
      craigslist: job_boards_data.craigslist ?? JOB_BOARD_FORM_CRAIGSLIST_DEFAULTS,
      snagajob: job_boards_data.snagajob ?? JOB_BOARD_FORM_SNAG_A_JOB_DEFAULTS,
      linkedin: job_boards_data.linkedin
        ? {
            ...job_boards_data.linkedin,
            experience_level: job_boards_data.linkedin?.experience_level ?? "NOT_APPLICABLE",
          }
        : JOB_BOARD_FORM_LINKEDIN_DEFAULTS,
      appcast: job_boards_data.appcast
        ? {
            ...job_boards_data.appcast,
            posting_country: job_boards_data.appcast?.posting_country ?? "USA",
          }
        : JOB_BOARD_FORM_APPCAST_DEFAULTS,
      talent: job_boards_data.talent ?? JOB_BOARD_FORM_TALENT_DEFAULTS,
      firstchoice: job_boards_data.firstchoice ?? JOB_BOARD_FORM_FIRSTCHOICE_DEFAULTS,
    });
  }, [job_boards_data, screening_questions]);

  /*============================
    UPDATE JOB BOARDS
  =============================*/
  const jobBoardsUpdate = useJobAdsJobBoardsUpdate(id);

  const handleJobBoardsChanges = async (jobBoards: JobAdsJobBoardData) => {
    try {
      const jobBoardsClone = cloneDeep(jobBoards);

      // If the selected value for promotion on indeed is "no"
      // then we remove any related fields before sending the API request
      // This separate field control is implemented so we can remove
      // the nested fields if we don't want to include the main feature of it
      if (jobBoardsClone.indeed?.promote_on_indeed === "no") {
        delete jobBoardsClone.indeed.indeed_budget;
        delete jobBoardsClone.indeed.indeed_duration;
      }

      // Remove all of the screening questions from "ZipRecruiter" job board if screening is not enabled
      if (jobBoardsClone.zip_recruiter?.screening_enabled === false) {
        delete jobBoardsClone.zip_recruiter.screening_questions;
      }

      // Remove the campaign field if the "ZipRecruiter" sponsored field is not enabled
      if (jobBoardsClone.zip_recruiter?.sponsored === false) {
        delete jobBoardsClone.zip_recruiter.campaign;
      }

      // Clear out the job board fields based on if the job board is enabled or not,
      // and if the fields have a valid value associated with them or not.
      // Empty field values are removed from the request payload object, which means
      // that field will also be removed on server side.
      Object.values(jobBoardsClone).forEach(jobBoard => {
        Object.entries(jobBoard).forEach(entry => {
          const [key, value] = entry;

          // Remove fields from the payload request object if they dont have a valid value
          if (key !== "enabled" && !value) delete jobBoard[key];

          // Remove all the fields from the job board object if marked as "disabled"
          if (!jobBoard.enabled && key !== "enabled") delete jobBoard[key];
        });
      });

      // Trigger API call
      await jobBoardsUpdate.mutateAsync(jobBoardsClone);
    } catch (error) {
      errorReporting("Failed updating ad's job boards", error, jobBoards);
    }
  };

  return (
    <div className="col-12 col-lg-6 mb--20">
      <Card modifierClass="card--padding--sm">
        <h3 className="fw--semibold txt--blue mb--20">Job Boards</h3>

        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={JOB_BOARDS_FORM_SCHEMA}
          onSubmit={handleJobBoardsChanges}
        >
          {formProps => (
            <Form>
              {Object.entries(formProps.errors).length ? (
                <div className="error-text-container">
                  <InfoIcon />
                  <p>Please fill in all required fields for all enabled job boards.</p>
                </div>
              ) : null}

              {/* JOB BOARDS TABS */}
              <div className="job-ads-details__poster__boards">
                {job_boards.map(jobBoard => (
                  <div
                    key={jobBoard.name}
                    className={`job-ads-details__poster__boards__type ${
                      jobBoard.name === selectedJobBoard
                        ? "job-ads-details__poster__boards__type--active"
                        : ""
                    } ${
                      formProps.errors[jobBoard.name as JobBoardTypes]
                        ? "job-ads-details__poster__boards__type--error"
                        : ""
                    }`}
                    onClick={() => handleJobBoardSelection(jobBoard.name as JobBoardTypes)}
                  >
                    {jobBoard.label}
                    {formProps.values[jobBoard.name as JobBoardTypes].enabled ? "*" : ""}
                  </div>
                ))}
              </div>

              {selectedJobBoard === "careerbuilder" ? (
                <CareerBuilderForm
                  {...formProps}
                  isSubmitDisabled={Object.entries(formProps.errors).length > 0}
                  isSubmitLoading={jobBoardsUpdate.isLoading}
                  job_board_data_career_builder={job_board_data_career_builder}
                />
              ) : null}

              {selectedJobBoard === "zip_recruiter" ? (
                <ZipRecruiterForm
                  {...formProps}
                  isSubmitDisabled={Object.entries(formProps.errors).length > 0}
                  isSubmitLoading={jobBoardsUpdate.isLoading}
                />
              ) : null}

              {selectedJobBoard === "indeed" ? (
                <IndeedForm
                  {...formProps}
                  isSubmitDisabled={Object.entries(formProps.errors).length > 0}
                  isSubmitLoading={jobBoardsUpdate.isLoading}
                />
              ) : null}

              {selectedJobBoard === "craigslist" ? <CraigslistForm {...formProps} /> : null}
              {selectedJobBoard === "snagajob" ? <SnagAJobForm {...formProps} /> : null}
              {selectedJobBoard === "linkedin" ? <LinkedInForm {...formProps} /> : null}
              {selectedJobBoard === "appcast" ? <AppcastForm {...formProps} /> : null}
              {selectedJobBoard === "talent" ? <TalentForm {...formProps} /> : null}
              {selectedJobBoard === "firstchoice" ? <FirstChoiceForm {...formProps} /> : null}

              <hr className="mb--20" />

              <PermissionCheckComponentWrapper permissions={["ad_manager_edit"]}>
                <div className="d-flex justify-content-end">
                  <Button
                    modifierClass="btn--fluid btn--primary"
                    isLoading={jobBoardsUpdate.isLoading}
                    isDisabled={
                      Object.entries(formProps.errors).length > 0 || jobBoardsUpdate.isLoading
                    }
                  >
                    Save Changes
                  </Button>
                </div>
              </PermissionCheckComponentWrapper>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};
export default JobBoards;
