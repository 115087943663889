/*================================
  CAREER PAGES - FORM
=================================*/
export const CAREER_PAGES_FORM_HIGHLIGHTS = [
  {
    title_placeholder: "(Example: Company Culture)",
    explanation_placeholder: "(Example: Brief summary of the casual company culture)",
  },
  {
    title_placeholder: "(Example: Work/Life Balance)",
    explanation_placeholder:
      "(Example: Brief summary of how the company feels work/life balance is important and allows for flexibility etc.)",
  },
  {
    title_placeholder: "(Example: Advancement Opportunities)",
    explanation_placeholder: "(Example: Brief summary of the multitude of opportunities available)",
  },
  {
    title_placeholder: "(Example: Peer Learning Program)",
    explanation_placeholder:
      "(Example: Brief summary about how your employees work together in training and mentorship)",
  },
  {
    title_placeholder: "(Example: Personal Development Opportunities)",
    explanation_placeholder:
      "(Example: Brief summary about product training and certification programs)",
  },
  {
    title_placeholder: "(Example: Employee Events)",
    explanation_placeholder:
      "(Example: Brief summary of activities for employees to build relationships)",
  },
];

export const CAREER_PAGES_FORM_FEATURED_HIGHLIGHTS_EXAMPLES = [
  "(Examples: This could be a featured employee or community event)",
  "(Examples: Brief summary of image context, like quote from employee or summary of event.)",
  "(Examples: Brief summary of image context, like quote from employee or summary of event.)",
];
