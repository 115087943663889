// Utilities & Hooks
import { parseJSON } from "date-fns";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useApplicationsGetSpecific } from "../../api/Applications/Applications";
import { handleAppointmentDisplayedTime } from "../../components/Appointments/utils/handleAppointmentDisplayedTime";
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.svg?react";

// Components
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

// Constants
import { SHORT_APPLICATION_FORMS } from "../../components/Applications/statics";

const ApplicationPrint = () => {
  const printedDate = format(new Date(), "hh:mmaaa MM/dd/yy");

  /*=========================
    APPLICATION DETAILS
  ==========================*/
  const { applicationID } = useParams();
  const { data: application, isLoading: applicationIsLoading } =
    useApplicationsGetSpecific(applicationID);

  // Map the received extra info data for the applicant
  // if the applicant has filed a v2 form.
  const MAPPED_EXTRA_INFO = useMemo(() => {
    // If we're working with the short form, we don't map any data
    if (
      !application ||
      !application.applicant.custom_values ||
      !Object.entries(application.applicant.custom_values).length ||
      SHORT_APPLICATION_FORMS.includes(application.form_name!)
    ) {
      return {};
    }

    // Extra info for the applicant
    const extra_info = application.applicant.custom_values;

    // Group up the employer-related fields
    const extraInfoObject: Record<string, any> = { employers: [] };
    const temporaryObject: Record<string | number, unknown> = {};

    Object.entries(extra_info).forEach(entry => {
      const [key, value] = entry;

      // Split the key by underscore and get the last element
      // which represents the number of the employer
      const splitKeyArray: string[] = key.split("_");
      const splitKeyArrayLastElement: string = splitKeyArray[splitKeyArray.length - 1];

      // Try to parse the last element from the split key
      const parsedLastElement = parseInt(splitKeyArrayLastElement);

      if (isNaN(parsedLastElement)) {
        temporaryObject[key] = value;
      } else {
        temporaryObject[parsedLastElement] = {
          // eslint-disable-next-line
          // @ts-ignore
          ...temporaryObject[parsedLastElement],
          [key]: value,
        };
      }
    });

    // Append the individually grouped employers objects to the "Employers" array
    Object.entries(temporaryObject).forEach(entry => {
      const [key, value] = entry;

      if (typeof value === "object") {
        extraInfoObject.employers.push(value);
      } else {
        extraInfoObject[key] = value;
      }
    });

    return extraInfoObject;
  }, [application]);

  useEffect(() => {
    // Exit function if application details are still being fetched
    if (!application || !Object.entries(application).length || applicationIsLoading) return;

    // Open the "Print" window
    window.print();
  }, [applicationIsLoading]);

  /*=========================
    RELOAD PAGE
  ==========================*/
  const handlePageReload = () => window.location.reload();

  return (
    <div>
      <div>
        <div className="print">
          {applicationIsLoading ? (
            <Loader size="page" modifierWrapper="loader--page" />
          ) : !application ? (
            <div className="print__text--centered my--30">
              <h1 className="mb--20">No Application data available.</h1>

              <Button modifierClass="btn--fluid btn--primary" onClick={handlePageReload}>
                Reload Page
              </Button>
            </div>
          ) : (
            <div className="print__text--regular">
              {/* HEADER SECTION */}
              <div className="print__section mb--50">
                {/* LOGO */}
                <FCHLogo className="d-block mx--auto my--20" width={226} height={75} />

                {/* CONTACT */}
                <div className="print__text--centered">
                  <p>
                    <strong style={{ fontSize: "16px" }}>Sales</strong> 1-888-990-6451 |{" "}
                    <strong style={{ fontSize: "16px" }}>Support: </strong>1-877-449-7595
                  </p>
                </div>

                {/* NOTICE */}
                <div className="print__text--subtle">
                  <strong>Notice to Applicants and Employees:</strong> Screening tests for illegal
                  drug use may be required before hiring and during your employment, should you be
                  hired. Equal access to programs, services and employment is available to all
                  persons. Those applicants requring reasonable accomodation to the application
                  and/or interview should notify a representative of the Human Resources Department.
                  <br />
                  <br />
                  We request the following information to help us make the best possible placement.
                  You should complete all portions of this application that pertain to you. All
                  information given will be held in strict confidence. If offered employment and
                  accepted, the law requires proof of your eligibility to work in the USA.
                  <hr />
                </div>

                <div className="print__text--heading">Applicant:</div>

                {/* APPLICATION DETAILS  SECTIONS*/}
                <div className="print__data">
                  <p>
                    <span className="print__text--bolder">Name:</span>{" "}
                    {handleFullnameCombination(application.applicant)}
                  </p>

                  <p>
                    <span className="print__text--bolder">Phone:</span>{" "}
                    {application?.applicant.phone ?? "N/A"}
                  </p>

                  <p>
                    <span className="print__text--bolder">Email:</span>{" "}
                    {application?.applicant.email ?? "N/A"}
                  </p>

                  <p className="txt--capitalize">
                    <strong>Country: </strong>{" "}
                    {application.applicant.custom_values?.country ?? "N/A"}
                  </p>

                  <p>
                    <span className="print__text--bolder">Address:</span>
                  </p>

                  <p className="mb--10">{application.applicant.address ?? "N/A"}</p>

                  <p>
                    {handleApplicantCityAndState(
                      application.applicant.city,
                      application.applicant.state,
                      application.applicant.zip_code,
                    )}
                  </p>

                  <p className="txt--capitalize mb--0">
                    <strong>Age 18 or older: </strong>
                    {MAPPED_EXTRA_INFO["18_years_or_older"] ?? "N/A"}
                  </p>
                </div>
              </div>

              <br />

              {/* APPLYING FOR SECTION */}
              <div className="print__section mb--50">
                <div className="print__text--heading">Applying For:</div>

                <div className="print__data">
                  <p>
                    <strong>Title: </strong> {application?.position}
                  </p>

                  {/* APPOINTMENTS */}
                  {application.appointments.length > 0 ? (
                    <>
                      <p className="print__text--subheading">Appointment(s):</p>
                      <div className="row no-gutters">
                        {application.appointments.map((appointment, index) => (
                          <div className="col-6" key={`appointments-${index}`}>
                            <div style={{ display: "table-cell" }} className="print__tab-c">
                              <p className="print__text--bolder">
                                {handleAppointmentDisplayedTime(
                                  appointment.type === "video"
                                    ? appointment.appointment_timestamp
                                    : appointment.appointment_time,
                                  appointment.type,
                                  "MMM/dd/yyyy @ hh:mm a",
                                )}
                                {appointment.type === "video" && (
                                  <span className="appointments__item__date--local">
                                    Local Time
                                  </span>
                                )}
                              </p>
                              <p>
                                <span className="print__text--bolder">Interviewer:</span>{" "}
                                {appointment.interviewer ?? "N/A"}
                              </p>
                              <p>
                                <span className="print__text--bolder">Date Set:</span>{" "}
                                {format(parseJSON(appointment.created_at), "MM/dd/yyyy")}
                              </p>
                              <p className="print__text--bolder">Appointment Notes:</p>
                              <p className="print__data">{appointment.notes ?? "N/A"}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <hr style={{ width: "50%" }} />

              {/*
                EXTRA INFORMATION (Employment History, Other Questions) 
                NOTE: This is implemented to work with the 2 types of form that exist at the moment.  
              */}
              <div className="print__section mb--50">
                {SHORT_APPLICATION_FORMS.includes(application.form_name!) ? (
                  <>
                    <div className="print__text--heading">Employment History: </div>

                    <div className="print__data">
                      <p className="white-space--pre-line">
                        <strong>Current Employer: </strong>
                        {application.applicant.custom_values?.current_employer ?? "N/A"}
                      </p>

                      <p className="white-space--pre-line">
                        <strong>Past Employer: </strong>
                        {application.applicant.custom_values?.past_employer ?? "N/A"}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="print__text--heading">Employment History: </div>

                    <div className="print__data">
                      {MAPPED_EXTRA_INFO.employers && MAPPED_EXTRA_INFO.employers.length > 0
                        ? MAPPED_EXTRA_INFO.employers.map((employer: any, index: number) => (
                            <div key={`employers-${index}`}>
                              <p>
                                <strong>Employer {index + 1}: </strong>{" "}
                                {employer[`employer_${index + 1}`] ?? "N/A"}
                              </p>
                              <p>
                                <strong>Position: </strong>
                                {employer[`employer_position_${index + 1}`]}
                              </p>

                              {/* DISPLAY WORKING DATES */}
                              {employer[`start_date_${index + 1}`] ? (
                                <>
                                  <p>
                                    <strong>Start Date: </strong>
                                    {format(
                                      new Date(employer[`start_date_${index + 1}`]),
                                      "MM/dd/yyyy",
                                    )}
                                  </p>

                                  <p>
                                    <strong>End Date: </strong>
                                    {employer[`end_date_${index + 1}`]
                                      ? format(
                                          new Date(employer[`end_date_${index + 1}`]),
                                          "MM/dd/yyyy",
                                        )
                                      : "Present"}
                                  </p>
                                </>
                              ) : null}

                              <p>
                                <strong>Address: </strong>
                                {employer[`employer_address_${index + 1}`] ?? "N/A"}
                              </p>

                              <p>
                                <strong>Phone: </strong>
                                {employer[`employer_phone_${index + 1}`] ?? "N/A"}
                              </p>

                              <p>
                                <strong>Supervisor: </strong>
                                {employer[`supervisor_name_${index + 1}`] ?? "N/A"}
                              </p>

                              <p>
                                <strong>Reason For Leaving: </strong>
                                {employer[`reason_for_leaving_${index + 1}`] ?? "N/A"}
                              </p>

                              {/* Every 3rd listed employer, add a page break so necessary data can be visible */}
                              {index !== 0 && (index + 1) % 3 === 0 ? (
                                <div className="print__page-break"></div>
                              ) : null}

                              {/* Do not show horizontal line on last employer, or where page breaks */}
                              {(index + 1) % 3 !== 0 ? <hr style={{ width: "50%" }} /> : null}
                            </div>
                          ))
                        : null}
                    </div>

                    {/* EDUCATION HISTORY SUBSECTION */}
                    <br />
                    <br />

                    <div className="print__text--heading">Education History: </div>
                    <div className="print__data">
                      <p className="txt--capitalize">
                        <strong>Has Graduated Highschool: </strong>
                        {MAPPED_EXTRA_INFO.graduate_high_school ?? "N/A"}
                      </p>
                    </div>

                    {MAPPED_EXTRA_INFO.graduate_high_school ? (
                      <div className="print__data">
                        <p className="txt--capitalize">
                          <strong>Highschool name: </strong>
                          {MAPPED_EXTRA_INFO.high_school_name ?? "N/A"}
                        </p>
                      </div>
                    ) : null}

                    {/* CRIMINAL HISTORY SUBSECTION */}
                    <div className="print__text--heading">Criminal History: </div>

                    <div className="print__data">
                      <p className="txt--capitalize">
                        <strong>Has been convicted? </strong>
                        {MAPPED_EXTRA_INFO.has_been_convicted ?? "N/A"}
                      </p>
                    </div>

                    <div className="print__data">
                      <p className="txt--capitalize">
                        <strong>Has pled guilty? </strong>
                        {MAPPED_EXTRA_INFO.has_pled_guilty ?? "N/A"}
                      </p>
                    </div>

                    {MAPPED_EXTRA_INFO.has_pled_guilty ? (
                      <div className="print__data">
                        <p className="txt--capitalize break-word">
                          <strong>Has plead guilty details: </strong>
                          {MAPPED_EXTRA_INFO.has_pled_guilty_details ?? "N/A"}
                        </p>
                      </div>
                    ) : null}

                    {/* OTHER QUESTIONS SUBSECTION */}
                    <div className="print__text--heading">Other Questions: </div>

                    <div className="print__data">
                      <p className="txt--capitalize">
                        <strong>Applicant Has Relative Employed Here: </strong>
                        {MAPPED_EXTRA_INFO.has_any_relatives_employed ?? "N/A"}
                      </p>

                      <p className="txt--capitalize">
                        <strong>Position Source: </strong>
                        {MAPPED_EXTRA_INFO.heard_of_position
                          ? MAPPED_EXTRA_INFO.heard_of_position.split("_").join(" ")
                          : "N/A"}
                      </p>

                      <p className="txt--capitalize">
                        <strong>Applicant Has Worked For Entity: </strong>
                        {MAPPED_EXTRA_INFO.worked_with_us_in_the_past ?? "N/A"}
                      </p>
                    </div>
                  </>
                )}
              </div>

              {/* LEGAL SECTION */}
              <table
                border={0}
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                className="print__text--subtle"
              >
                <tbody>
                  <tr>
                    <td style={{ borderBottom: "1px solid #E2E2E2" }} />
                  </tr>

                  <tr>
                    <td style={{ padding: "10px 0 10px 0" }}>
                      <span className="print__text--regular">
                        <strong>
                          Employment Application - Disclosure, Terms of Use, and Acknowledgement
                        </strong>
                      </span>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="print__text--regular">
                        <strong>Applicant Name:</strong>{" "}
                        {handleFullnameCombination(application.applicant)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="print__text--regular">
                        <strong>Date of Application:</strong>{" "}
                        {format(parseJSON(application.registration_date), "MM/dd/yyyy")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="print__text--print">
                        I certify that all information I have provided is true, complete, and
                        correct. I understand that the acceptance of my application is not an offer
                        of employment. I expressly authorize, without reservation, the employer, its
                        representatives, employees, or agents to contact and obtain information from
                        all available sources, including, but not limited to, references provided,
                        previous employers, public records, governmental agencies, consumer
                        reporting agencies, and educational institutions to evaluate my application
                        for employment. I hereby authorize all parties to provide such information,
                        and agree to waive all rights and claims against any individuals,
                        corporations, or organizations for furnishing such information.
                        <br />
                      </span>
                      <br />
                      <span className="print__text--print">
                        I understand this Employer is an equal opportunity employer which does not
                        discriminate in its employment practices, or activities on the basis of
                        race, color, national or ethnic origin, ancestry, age, religion or religious
                        creed, disability or handicap, sex, gender identity and/or expression,
                        sexual orientation, military or veteran status, genetic information, or any
                        other characteristic protected under applicable federal, state, or local
                        law.
                        <br />
                      </span>

                      <br />
                      <b>
                        By signing below, I affirm that I have read and agree to the terms of use
                        and disclosures contained herein.
                      </b>
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>

                  <tr>
                    <td className="pl--30">
                      <b>Signature:</b>{" "}
                      <span
                        style={{
                          borderBottom: "1px dotted #333",
                          width: "200px",
                          display: "inline-block",
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <b>Date:</b>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          borderBottom: "1px dotted #333",
                          width: "150px",
                        }}
                      />
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>

                  <tr>
                    <td className="pl--30">
                      <b>Printed: {printedDate}</b>
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationPrint;
