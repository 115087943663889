import { io } from "socket.io-client";
import { SOCKET_URL } from "./config";

/**
 *
 * Initializes SocketIO client so chat connections can be made.
 *
 * The `autoConnect` flag is set to `false` to prevent establishing connections
 * to the socket on the unauthenticated parts of the application.
 *
 * Only the authenticated pages should be able to initialize the socket client.
 *
 **/
const socket = io(SOCKET_URL, {
  autoConnect: false,
});

export default socket;
