// Hooks & Utilities
import { Field, Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { useBucketsEdit } from "../../api/Buckets/Buckets";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import Button from "../Button/Button";
import FormInput from "../Form/FormInput";
import Modal from "../Modal/Modal";

// Interfaces
import { BucketsEditFormStateFields, BucketsEditCustomBucketModalProps } from "./interfaces";

// Schemas
import { BUCKETS_SCHEMA } from "../../schemas/BucketsSchemas";

const BucketsEditCustomBucketModal = ({
  bucketID,
  bucketName,
  handleCloseModal,
}: BucketsEditCustomBucketModalProps) => {
  const errorReporting = useErrorReporting();

  /*=============================
    BUCKET EDIT FORM STATE
  ==============================*/
  const [bucketEditFormState, setBucketEditFormState] = useState<BucketsEditFormStateFields>({
    name: "",
  });

  // Reinitialize the form state field values
  // with the received bucket name (if it exists)
  useEffect(() => {
    if (!bucketName) return;

    setBucketEditFormState({ name: bucketName });
  }, [bucketName]);

  /*=============================
    BUCKET EDIT API CALL
  ==============================*/
  const bucketEdit = useBucketsEdit();

  const handleBucketEdit = async ({ name }: BucketsEditFormStateFields) => {
    // Exit function if there's an invalid bucket ID received
    if (bucketID == null) return;

    try {
      await bucketEdit.mutateAsync({ id: bucketID, name });

      // Close the modal once the bucket was updated
      handleCloseModal();
    } catch (error) {
      errorReporting("Failed editing custom bucket", error, { bucket_id: bucketID });
    }
  };

  return (
    <Modal
      title="Edit Custom Bucket"
      text=""
      modifierClass="modal--md"
      overlayModifierClass="modal-backdrop--nested"
      handleCloseModal={handleCloseModal}
    >
      <Formik
        initialValues={bucketEditFormState}
        enableReinitialize
        validationSchema={BUCKETS_SCHEMA}
        onSubmit={handleBucketEdit}
      >
        <Form>
          <Field
            component={FormInput}
            id="name"
            name="name"
            placeholder="Enter bucket's name"
            modifierClass="my--20"
            shouldAutofocus
          />
          <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
            <Button
              modifierClass="btn--fluid btn--primary--light"
              onClick={handleCloseModal}
              type="button"
            >
              Cancel
            </Button>

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
              isLoading={bucketEdit.isLoading}
              isDisabled={bucketEdit.isLoading}
            >
              Edit Bucket
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default BucketsEditCustomBucketModal;
