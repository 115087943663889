// Components
import AssessmentCard from "../AssessmentCard";
import ProgressCircle from "../../Progress/ProgressCircle";
import AssessmentProgressBox from "../AssessmentProgressBox";
import AssessmentProgressBarDrivenCard from "./AssessmentProgressBarDrivenCard";

// Interfaces
import { AssessmentProgressBarCircleCardProps } from "./interfaces";

// Statics
import { SBCA_MAIN_DESCRIPTION, SBCA_MAIN_RANGE, SBCA_MAIN_TRAITS } from "./statics";

// Assets
import CheckmarkIcon from "../../../assets/images/icons/sbca-report-check.svg?react";

const AssessmentProgressBarCircleCard = ({
  title,
  type,
  data,
}: AssessmentProgressBarCircleCardProps) => {
  // This is needed because typescript throws error for unmatching types
  const scoreType = SBCA_MAIN_TRAITS[type].toLowerCase() as "emotions" | "ego" | "logic";

  return (
    <AssessmentCard
      title={title}
      score={(+data.circle_progress_bar.score).toFixed(2)}
      scoreColor={data.circle_progress_bar.color}
      scoreType="percentage"
    >
      <div className="line-under line-under--absolute mb--20">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-start">
              {/*SBCA PIE*/}
              <div>
                <ProgressCircle
                  type={type}
                  size={110}
                  strokeWidth={20}
                  percentage={+data.circle_progress_bar.score}
                  color={data.circle_progress_bar.color}
                  modifierClass="progress-circle--lg progress-circle--animated"
                />
                <p
                  className={`txt--xl mt--10 txt--center assessment-txt--${data.circle_progress_bar.color}`}
                >
                  <strong>{data.circle_progress_bar.score}%</strong>
                </p>
              </div>
              {/*SBCA DATA*/}
              <div className="sbca-data ml--40">
                <div className="sbca-data__row">
                  <span className="sbca-data__title">{title}:</span>
                  <span className="sbca-data__content">
                    <strong>{data.score}%</strong>
                  </span>
                </div>
                <div className="sbca-data__row">
                  <span className="sbca-data__title">Range:</span>
                  <span className="span-data__content">{SBCA_MAIN_RANGE[type]}</span>
                </div>
                <div className="sbca-data__row">
                  <span className="sbca-data__title">{SBCA_MAIN_TRAITS[type]}:</span>
                  <span className="span-data__content">{data[scoreType]}</span>
                </div>

                {data.progress_bar_print && data.progress_bar_print.target.length > 0 && (
                  <div className={`progress-badge progress-badge--${data.progress_bar.color}`}>
                    {!parseInt(data.progress_bar_print.target) && (
                      <CheckmarkIcon width={20} height={15} />
                    )}
                    <span className="progress-badge__txt">{data.progress_bar_print.text}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <AssessmentProgressBox data={data.progress_bar} />
          </div>
        </div>

        {data.driven && (
          <AssessmentCard title="Driven" modifierClass="bg--gray mt--20">
            <AssessmentProgressBarDrivenCard data={data.driven} />
          </AssessmentCard>
        )}
      </div>
      <p
        className="assessment-txt m--0"
        dangerouslySetInnerHTML={{ __html: SBCA_MAIN_DESCRIPTION[type] }}
      />
    </AssessmentCard>
  );
};
export default AssessmentProgressBarCircleCard;
