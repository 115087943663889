/**
 * A utility function that converts the received timestamp value
 * into a Date object, in the correct local time, by multiplying the
 * initial timestamp by 1000 to get the correct amount of seconds.
 * @param timestamp Date in a timestamp format, as received from the server
 * @returns A Date object constructed by multiplying the received timestamp by 1000 (ms)
 * to always get the correct date value.
 */
export function handleDateAsTimestamp(timestamp: number): Date {
  const convertedTimestamp: Date = new Date(timestamp * 1000);
  return convertedTimestamp;
}
