// Hooks
import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "../../hooks/useIsInViewport";

// Statics
import { ProgressBarProps } from "./interfaces";

const ProgressBar = ({ data, modifierClass = "" }: ProgressBarProps) => {
  const progressBarRef = useRef(null);

  // Check if progress bar is in viewport
  const isInViewport = useIsInViewport(progressBarRef);

  // Set the progress bar initial state
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isInViewport) return;
    setProgress((+data.score * 100) / data.max);
  }, [isInViewport]);

  return (
    <div
      ref={progressBarRef}
      className={`progress-bar progress-bar--${data.color} ${modifierClass}`}
    >
      <span className="progress-bar__range">{data.min}</span>
      <div className="progress-bar__background">
        <div className="progress-bar__progress" style={{ width: `${progress}%` }}>
          <span className="progress-bar__progress__value" data-progress-bar-value={data.score} />
        </div>
      </div>
      <span className="progress-bar__range">{data.max}</span>
    </div>
  );
};
export default ProgressBar;
