type ExpectedNameFields = Record<"first_name" | "middle_name" | "last_name", string | null>;
type DataObject = ExpectedNameFields | Record<string, any> | null;

/**
 *
 * Utility function for joining the `first` and `last` names
 * of the user/applicant, into a single `fullname` string.
 *
 * @param dataObject The data object that is supposed to contain the
 * `first_name` and `last_name` values.
 *
 * @returns A string representing the fullname of the user/applicant
 */
export default function handleFullnameCombination(dataObject: DataObject): string {
  // If no object, or object with no entries, is received, then return default value
  if (!dataObject || !Object.entries(dataObject)) return "N/A";

  // Check if the received data object has the name properties
  if (
    !Object.prototype.hasOwnProperty.call(dataObject, "first_name") ||
    !Object.prototype.hasOwnProperty.call(dataObject, "last_name")
  )
    return "N/A";

  // If the values exist, combine them into a full name and return that as a string
  const { first_name, last_name } = dataObject;

  let fullname: string = `${first_name}`;

  // Append last name if present and if not received as 'N/A' from server
  if (last_name && last_name.toLowerCase() !== "n/a") fullname += ` ${last_name}`;

  return fullname;
}
