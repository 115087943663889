// Utilities & Hooks
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAssessmentGetSpecific } from "../../api/Assessment/Assessment";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.svg?react";

// Components
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

// Statics
import {
  SBCA_MAIN_DESCRIPTION,
  SBCA_MAIN_RANGE,
  SBCA_MAIN_TRAITS,
  SBCA_PRINT_TARGET,
} from "../../components/Assessment/AssessmentData/statics";

//Interfaces
import {
  AssessmentDetailsAreasOfConcernFields,
  AssessmentDetailsFinalThoughtsFields,
  AssessmentAttrubuteRowsFields,
  AssessmentCategoriesFields,
} from "../../api/Assessment/interfaces";

const AssessmentResultsPrint = () => {
  const { sbcaReportIdentifier } = useParams();

  /*=========================
    ASSESSMENT DETAILS
  ==========================*/
  const { data: sbcaReport, isLoading: sbcaReportIsLoading } =
    useAssessmentGetSpecific(sbcaReportIdentifier);

  useEffect(() => {
    // Exit function if application details are still being fetched
    if (!sbcaReport || !Object.entries(sbcaReport).length || sbcaReportIsLoading) return;

    // Open the "Print" window
    window.print();
  }, [sbcaReportIsLoading]);

  /*=========================
    RELOAD PAGE
  ==========================*/
  const handlePageReload = () => window.location.reload();

  return (
    <div className="print">
      {sbcaReportIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : !sbcaReport ? (
        <div className="print__text--centered my--30">
          <h1 className="mb--20">No Assessment data available.</h1>

          <Button modifierClass="btn--fluid btn--primary" onClick={handlePageReload}>
            Reload Page
          </Button>
        </div>
      ) : (
        <div className="print__text--regular">
          <div className="container container--full">
            {/* HEADER SECTION */}
            <div className="print__section">
              {/* LOGO */}
              <FCHLogo className="d-block mx--auto my--30" width={150} height={50} />

              <div className="row">
                <div className="col-6">
                  <p style={{ marginBottom: 0 }}>
                    <strong>Prepared for:</strong> {sbcaReport.prepared_for}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Company:</strong> {sbcaReport.company}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Type:</strong> {sbcaReport.type}
                  </p>
                </div>
                <div className="col-6">
                  <p style={{ marginBottom: 0 }}>
                    <strong>Permission:</strong> {sbcaReport.permission}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Submitted:</strong> {sbcaReport.submitted}
                  </p>
                </div>
              </div>
            </div>

            {/*SUMMARY*/}
            <div className="print__section">
              <hr style={{ margin: "10px 0" }} />
              <h1 className="print__text--bolder">Overall Observations</h1>
              <hr style={{ margin: "10px 0" }} />

              <h2 className="print__text--heading print__text--centered">Summary Index</h2>
              <p>
                <strong>Summary:</strong> {sbcaReport.summary.score}
              </p>
              <p className="print__text--centered">
                <strong>
                  This index reflects the overall assessment including the baseline personality,
                  organizational ability, behavioral patterns and all its measured components. It
                  also measures the propensity for negativity and emotional patterns and their
                  effect on his/her environment over a period of time.
                </strong>
              </p>
            </div>
            <div className="print__page-break">&nbsp;</div>

            {/*BASELINE CHARACTERISTICS*/}
            <div className="print__section">
              <hr style={{ margin: "10px 0" }} />
              <h1 className="print__text--bolder">Baseline Characteristics</h1>
              <hr style={{ margin: "10px 0" }} />
            </div>

            <div className="print__section">
              {/*SBCA DATA*/}
              {/*CAREGIVER*/}
              <div>
                <p style={{ marginBottom: 0 }}>
                  <strong>Caregiver: {sbcaReport.caregiver.score}%</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Range: {SBCA_MAIN_RANGE["caregiver"]}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>{SBCA_MAIN_TRAITS["caregiver"]}:</strong> {sbcaReport.caregiver.emotions}
                </p>
                <p>
                  <strong>Underlying characteristic:</strong> <br />
                  <strong>
                    [{!parseInt(sbcaReport.caregiver.progress_bar_print.target) ? "✓ " : ""}
                    {sbcaReport.caregiver.progress_bar_print.text}]
                  </strong>{" "}
                  <span className="print__text--gray">
                    {sbcaReport.caregiver.progress_bar.footer_range}
                  </span>{" "}
                  {sbcaReport.caregiver.progress_bar.footer_description}
                  <span
                    className="print__text--badge"
                    dangerouslySetInnerHTML={{
                      __html: sbcaReport.caregiver.progress_bar_print.target,
                    }}
                  />
                </p>
                <p
                  style={{ marginBottom: 0, fontSize: "11px" }}
                  dangerouslySetInnerHTML={{ __html: SBCA_MAIN_DESCRIPTION["caregiver"] }}
                />
                <hr />
              </div>
              {/*THINKER*/}
              <div>
                <p style={{ marginBottom: 0 }}>
                  <strong>Thinker: {sbcaReport.thinker.score}%</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Range: {SBCA_MAIN_RANGE["thinker"]}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>{SBCA_MAIN_TRAITS["thinker"]}:</strong> {sbcaReport.thinker.ego}
                </p>
                <p>
                  <strong>Underlying characteristic:</strong> <br />
                  <strong>
                    [ {!parseInt(sbcaReport.thinker.progress_bar_print.target) ? "✓" : ""}{" "}
                    {sbcaReport.thinker.progress_bar_print.text}]
                  </strong>{" "}
                  <span className="print__text--gray">
                    {sbcaReport.thinker.progress_bar.footer_range}
                  </span>{" "}
                  {sbcaReport.thinker.progress_bar.footer_description}
                  <span
                    className="print__text--badge"
                    dangerouslySetInnerHTML={{
                      __html: sbcaReport.thinker.progress_bar_print.target,
                    }}
                  />
                </p>
                <p
                  style={{ fontSize: "11px" }}
                  dangerouslySetInnerHTML={{ __html: SBCA_MAIN_DESCRIPTION["thinker"] }}
                />

                {sbcaReport.thinker.driven && (
                  <div style={{ marginLeft: "20px" }}>
                    <p style={{ marginBottom: 0 }}>
                      <strong>Driven: {sbcaReport.thinker.driven.score}</strong>
                    </p>
                    <p style={{ marginBottom: "10px" }}>Range: 3.00-5.00</p>
                    <p style={{ marginBottom: 0 }}>
                      Striving to achieve personal goals because of a strong need or inner
                      compulsion.
                    </p>
                  </div>
                )}
                <hr />
              </div>
              {/*PROCESSOR*/}
              <div>
                <p style={{ marginBottom: 0 }}>
                  <strong>Processor: {sbcaReport.processor.score}%</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Range: {SBCA_MAIN_RANGE["processor"]}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>{SBCA_MAIN_TRAITS["processor"]}:</strong> {sbcaReport.processor.logic}
                </p>
                <p>
                  <strong>Underlying characteristic:</strong> <br />
                  <strong>
                    [{!parseInt(sbcaReport.processor.progress_bar_print.target) ? "✓ " : ""}
                    {sbcaReport.processor.progress_bar_print.text}]
                  </strong>{" "}
                  <span className="print__text--gray">
                    {sbcaReport.processor.progress_bar.footer_range}
                  </span>{" "}
                  {sbcaReport.processor.progress_bar.footer_description}
                  <span
                    className="print__text--badge"
                    dangerouslySetInnerHTML={{
                      __html: sbcaReport.processor.progress_bar_print.target,
                    }}
                  />
                </p>
                <p
                  style={{ marginBottom: 0, fontSize: "11px" }}
                  dangerouslySetInnerHTML={{ __html: SBCA_MAIN_DESCRIPTION["processor"] }}
                />
              </div>
            </div>

            <div className="print__page-break">&nbsp;</div>

            {/*EVALUATION*/}
            <div className="print__section">
              <hr style={{ margin: "10px 0" }} />
              <h1 className="print__text--bolder">
                Evaluation - Baseline: {sbcaReport.evaluation.base_type}
              </h1>
              <hr style={{ margin: "10px 0" }} />

              {sbcaReport.evaluation.categories.map(
                (category: AssessmentCategoriesFields, index: number) => (
                  <div key={`print-categories-${index}`}>
                    <h2
                      className="print__text--heading print__text--centered"
                      style={{ marginTop: "20px" }}
                    >
                      {category.label}
                    </h2>
                    <>
                      {Array.from(category.attribute_rows).map(
                        (categoryRow: AssessmentAttrubuteRowsFields, index: number) => (
                          <div key={`print-attribute-row-${index}`} style={{ marginTop: "20px" }}>
                            <p style={{ marginBottom: 0 }}>
                              <strong>
                                {categoryRow.label}: {categoryRow.score}
                              </strong>
                            </p>
                            <p style={{ marginBottom: 0 }}>{categoryRow.description}</p>

                            <p style={{ marginBottom: 0 }}>
                              <strong>
                                Target:
                                {SBCA_PRINT_TARGET.map(item => {
                                  if (item.label === categoryRow.label) return item.target;
                                })}{" "}
                                | Actual:
                              </strong>{" "}
                              <span className="print__text--gray">
                                {categoryRow.progress_bar.footer_range}
                              </span>{" "}
                              {categoryRow.progress_bar.footer_description}
                            </p>

                            <hr />
                          </div>
                        ),
                      )}
                    </>
                  </div>
                ),
              )}
            </div>

            {/*FINAL THOUGHTS*/}
            <div className="print__section" style={{ marginTop: "20px" }}>
              <h2 className="print__text--heading print__text--centered">Final Thoughts</h2>

              {sbcaReport.final_thoughts.map(
                (item: AssessmentDetailsFinalThoughtsFields, index: number) => (
                  <div key={`print-final-thoughts-${index}`} className="row">
                    <div className="col-1">
                      <p style={{ marginBottom: "10px" }}>
                        <strong>{item.score}</strong>
                      </p>
                    </div>
                    <div className="col-11">
                      <p style={{ marginBottom: "10px" }}>
                        <strong>{item.label}: </strong>
                        {item.description}
                      </p>
                    </div>
                  </div>
                ),
              )}
              <hr />
            </div>

            {/*AREAS OF CONCERN*/}
            <div className="print__section">
              <h2 className="print__text--heading print__text--centered">Areas of Concern</h2>

              {sbcaReport.areas_of_concern.map(
                (item: AssessmentDetailsAreasOfConcernFields, index: number) => (
                  <p key={`print-areas-of-concern-${index}`} style={{ marginBottom: "10px" }}>
                    <strong>{item.label}</strong>{" "}
                    <span className="print__text--gray">{item.range}</span> {item.description}
                  </p>
                ),
              )}
              <hr />
            </div>

            {/*COMMENTARY*/}
            <div className="print__section">
              <h2 className="print__text--heading print__text--centered">Commentary</h2>
              <p style={{ marginBottom: "10px" }}>
                <strong>{sbcaReport.prepared_for}</strong> is a{" "}
                <strong>{sbcaReport.commentary.base_type}</strong>
              </p>
              <p style={{ marginBottom: "10px" }}>
                <strong>{sbcaReport.prepared_for}</strong> may be described by others as:{" "}
                <strong>{sbcaReport.commentary.described_by_others}</strong>
              </p>
              <p style={{ marginBottom: "40px" }}>
                Preferred Work Environment:{" "}
                <strong>{sbcaReport.commentary.preferred_work_enviroment}</strong>
              </p>
              <hr />
            </div>

            {/*SUMMARY*/}
            <div className="print__section">
              <h2 className="print__text--heading print__text--centered">AI Insights</h2>
              <p style={{ marginBottom: "40px", whiteSpace: "pre-line" }}>
                {sbcaReport.ai_summary.text || "A summary has not been generated yet for this applicant."}
              </p>
              
              {sbcaReport.ai_summary.text ? <p><em>Note: AI Insights can make mistakes. Consider checking important information.</em></p> : null}
              <hr />
            </div>

            <p className="print__text--centered">
              ® FirstChoice Hiring 2014-{new Date().getFullYear()}. All Rights Reserved.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentResultsPrint;
