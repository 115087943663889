// Utilities & Hooks
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Interfaces
import { JobAdDetailsLivePreviewProps } from "./interfaces";

// Components
import JobAdStatusUpdateActions from "./JobAdStatusUpdateActions";
import Button from "../../Button/Button";

const JobAdLivePreview = ({
  id,
  status,
  live_ad_preview,
  live_ad_preview_identifier,
  tracking,
  company_is_active,
}: JobAdDetailsLivePreviewProps) => {
  /*==============================
    CONSTRUCT LIVE AD PREVIEW URL
  ===============================*/
  const [liveAdPreviewSourceURL, setLiveAdPreviewSourceURL] = useState<string>("");

  useEffect(() => {
    if (!live_ad_preview_identifier) return;

    // Extract the necessary information about the host
    const { host, protocol } = window.location;

    // Construct the full URL for previewing the Ads
    const previewURL: string = `${protocol}//${host}/preview/job-ads/${live_ad_preview_identifier}`;

    setLiveAdPreviewSourceURL(previewURL);
  }, [live_ad_preview_identifier]);

  // Copy the Job Ad's "Craigslist Embed" HTML content
  const handleCopyToClipboard = async (adHTML: string | null) => {
    if (!adHTML) return;

    try {
      await navigator.clipboard.writeText(adHTML);
      toast.info("HTML copied!");
    } catch (error) {
      toast.error("Failed copying HTML!");
    }
  };

  return (
    <div className="job-ads-details__live-preview">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb--20">
        <h3 className="fw--semibold txt--blue my--10">Live Preview</h3>
        <JobAdStatusUpdateActions
          id={id}
          ad_preview_id={live_ad_preview_identifier}
          status={status}
          location="live_ad_preview"
          tracking={tracking}
          company_is_active={company_is_active}
        />
      </div>

      {liveAdPreviewSourceURL ? (
        <iframe
          title="Job Ad Live Preview"
          src={liveAdPreviewSourceURL}
          height="300px"
          className="iframe-wrapper iframe-wrapper--bordered mb--20"
        ></iframe>
      ) : (
        <h3 className="fw--semibold my--30">Ad Preview is currently unavailable.</h3>
      )}

      <hr className="mb--10" />

      <h6 className="txt--lg txt--gray--darkest fw--semibold mb--10">Ad Html</h6>
      <textarea
        className="input-textarea"
        disabled
        readOnly
        rows={3}
        defaultValue={live_ad_preview!}
      />

      <div className="d-flex justify-content-end">
        <Button
          className="btn btn--fluid btn--primary mt--10"
          onClick={() => handleCopyToClipboard(live_ad_preview)}
        >
          Copy HTML
        </Button>
      </div>
    </div>
  );
};

export default JobAdLivePreview;
