// Utilities & Hooks
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  JobAdsDeleteCommentDetails,
  JobAdsEditCommentDetails,
  JobAdsPostCommentDetails,
  JobAdsSpecificAdDetailsResponseFields,
} from "./interfaces";

/**
 *
 * Post a new comment for the targeted Job Ad
 *
 * The mutation hook takes two properties:
 * - `ad_id` represents the ID of the targeted Job Ad to which the comment belongs to
 * - `data` represents the text value of the new comment that will be posted
 *
 */
export const useJobAdsPostNewComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ ad_id, data }: JobAdsPostCommentDetails) => {
      return await fetchHandler("POST", `admin/ads/${ad_id}/ad-comment`, { data });
    },
    {
      onSuccess: () => toast.success("New comment posted!"),
      onError: (error: unknown) => error,
      onSettled: () => queryClient.invalidateQueries(["job-ads-details"]),
    },
  );
};

/**
 *
 * Edit the text of the targeted comment for the
 * Job Ad that we're viewing at the moment.
 *
 * The mutation hook takes two properties:
 * - `ad_id` represents the ID of the targeted Job Ad to which the comment belongs to
 * - `comment_id` represents the ID of the targeted comment
 * - `data` represents the text value that will be used to update the targeted comment
 *
 */
export const useJobAdsEditComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ ad_id, comment_id, data }: JobAdsEditCommentDetails) => {
      return await fetchHandler("PUT", `admin/ads/${ad_id}/ad-comment/${comment_id}`, { data });
    },
    {
      onMutate: (editDetails: JobAdsEditCommentDetails) => {
        // Get the cached query data for the targeted Job Ad Details
        const oldJobAdDetailsData = queryClient.getQueryData([
          "job-ads-details",
          editDetails.ad_id,
        ]) as JobAdsSpecificAdDetailsResponseFields;
        const updatedJobAdDetailsData = cloneDeep(oldJobAdDetailsData);

        // Update the targeted comment
        const targetedCommentIndex: number = updatedJobAdDetailsData.comments.findIndex(comment => {
          return comment.id === editDetails.comment_id;
        });

        // If comment cannot be found, return the previous state's data
        if (targetedCommentIndex < 0) return { oldJobAdDetailsData };

        // Update the text of the comment
        updatedJobAdDetailsData.comments[targetedCommentIndex].data = editDetails.data;

        // Update the cached query data
        queryClient.setQueryData(["job-ads-details", editDetails.ad_id], updatedJobAdDetailsData);

        // Show a success notification
        toast.success("Comment updated!", {
          toastId: "job-ads-comments-edit",
        });

        return { oldJobAdDetailsData };
      },
      onError: (error: unknown, editDetails, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("job-ads-comments-edit");

        queryClient.setQueryData(
          ["job-ads-details", editDetails.ad_id],
          context?.oldJobAdDetailsData,
        );

        return error;
      },
    },
  );
};

/**
 *
 * Delete the targeted comment of the Job Ad that
 * we're viewing at the moment.
 *
 */
export const useJobAdsDeleteComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ ad_id, comment_id }: JobAdsDeleteCommentDetails) => {
      return await fetchHandler("DELETE", `admin/ads/${ad_id}/ad-comment/${comment_id}`);
    },
    {
      onMutate: ({ ad_id, comment_id }) => {
        // Get the cached query data for the targeted Job Ad Details
        const oldJobAdDetailsData = queryClient.getQueryData([
          "job-ads-details",
          ad_id,
        ]) as JobAdsSpecificAdDetailsResponseFields;
        const updatedJobAdDetailsData = cloneDeep(oldJobAdDetailsData);

        // Remove the targeted comment from the list of comments
        updatedJobAdDetailsData.comments = updatedJobAdDetailsData.comments.filter(comment => {
          return comment.id !== comment_id;
        });

        // Update the cached data
        queryClient.setQueryData(["job-ads-details", ad_id], updatedJobAdDetailsData);

        // Show success notification
        toast.success("Comment deleted!", {
          toastId: "job-ads-comments-delete",
        });

        return { oldJobAdDetailsData };
      },
      onError: (error: unknown, { ad_id }, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("job-ads-comments-delete");

        // Reset the cached data to pre-mutation state
        queryClient.setQueryData(["job-ads-details", ad_id], context?.oldJobAdDetailsData);

        return error;
      },
    },
  );
};
