// Hooks & Utilities
import { Link } from "react-router-dom";

// Components
import Card from "../../components/Card/Card";
import ContentHeader from "../../components/Content/ContentHeader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

const Reports = () => {
  return (
    <div className="container py--25">
      <PermissionCheckComponentWrapper permissions={["view_company_reports"]}>
        <ContentHeader title="Company Reports" modifierClass="mb--30" />
        <Card modifierClass="mb--50i card--padding--xl">
          <div className="d-flex flex-column">
            <Link to="/reports/hiring/" className="fw--medium txt--link">
              Hiring Report
            </Link>

            {/* NOTE: Temporarily disabled. */}
            {/* <Link to="/reports/summary/" className="fw--medium txt--link">
              Summary Report
            </Link> */}
          </div>
        </Card>
      </PermissionCheckComponentWrapper>

      <PermissionCheckComponentWrapper permissions={["reports_view"]}>
        <ContentHeader title="Admin Reports" modifierClass="mb--30" />
        <Card modifierClass="card--padding--xl">
          <div className="d-flex flex-column">
            <Link to="/reports/expiring-ads/" className="mb--15 fw--medium txt--link">
              Expiring Ads
            </Link>
            <Link to="/reports/ad-performance/" className="mb--15 fw--medium txt--link">
              Ad Performance Report (Filters)
            </Link>
            <Link to="/reports/job-board-status/" className="mb--15 fw--medium txt--link">
              Job Board Status Report
            </Link>
            <Link to="/reports/applicant-count/" className="mb--15 fw--medium txt--link">
              Applicant Count Report
            </Link>
            <Link to="/reports/ad-activation/" className="mb--15 fw--medium txt--link">
              Ad Activation Report
            </Link>
            <Link to="/reports/indeed-sponsored-ads/" className="mb--15 fw--medium txt--link">
              Indeed Sponsored Ads
            </Link>
            <Link to="/reports/internal-hiring/" className="mb--15 fw--medium txt--link">
              Internal Hiring Report
            </Link>
            <Link to="/reports/ziprecruiter-sponsored/" className="mb--15 fw--medium txt--link">
              ZipRecruiter Sponsored Report
            </Link>
          </div>
        </Card>
      </PermissionCheckComponentWrapper>
    </div>
  );
};

export default Reports;
