// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  AssessmentDetailsFields,
  AssessmentDirectLogsResponseFields,
  AssessmentShareByEmailPayload,
} from "./interfaces";
import { AssessmentDirectRequestFormFields } from "../../pages/Assessment/AssessmentRequest";
import { useAuth } from "../../providers/auth-context";
import { toast } from "react-toastify";

/**
 *
 * Get the assessment details for a specific user
 * by using the sbca report identifier
 *
 */
export function useAssessmentGetSpecific(sbcaReportIdentifier: string | undefined) {
  return useQuery(
    ["assessment-details", sbcaReportIdentifier],
    async () => {
      return (await fetchHandler(
        "GET",
        `online-assessment/${sbcaReportIdentifier}/result`,
      )) as AssessmentDetailsFields;
    },
    { enabled: !!sbcaReportIdentifier },
  );
}

/**
 *
 * Share an assessment from the company
 * to another user by sending an email
 *
 */
export function useAssessmentShareByEmail() {
  return useMutation(
    async (details: AssessmentShareByEmailPayload) => {
      return await fetchHandler(
        "POST",
        `online-assessment/${details.sbca_report_identifier}/share`,
        {
          recipient_id: details.recipient_id,
          message: details.message,
        },
      );
    },
    {
      onError: error => error,
    },
  );
}

/*====================================
  SBCA DIRECT
======================================*/
export function useAssessmentSendDirectRequest() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (details: AssessmentDirectRequestFormFields) => {
      return await fetchHandler("POST", `company/${companySlug}/sbca-direct/request`, details);
    },
    {
      onSuccess: () => {
        toast.success("Successfully sent direct SBCA request!");
        queryClient.invalidateQueries(["sbca-direct-logs"]);
      },
    },
  );
}

export function useAssessmentGetSbcaDirectLogs() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["sbca-direct-logs", companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/sbca-direct/logs`,
      )) as AssessmentDirectLogsResponseFields[];
    },
    {
      enabled: !!companySlug,
    },
  );
}

export function useAssessmentResendSbcaDirectRequest() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (requestID: number) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/sbca-direct/request/${requestID}/resend`,
      );
    },
    {
      onSuccess: () => {
        toast.success("SBCA Direct Request resent successfully!");
        queryClient.invalidateQueries(["sbca-direct-logs"]);
      },
    },
  );
}

/**
 *
 * Generate/Regenerate an AI summary for the specified SBCA assesment
 *
 */
export const useRegenerateAISummary = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (sbcaIdentifier: string) => {
      return await fetchHandler("POST", `admin/regenerate-sbca-summary-data/${sbcaIdentifier}`);
    },
    {
      onMutate: sbcaIdentifier => {
        // Get old query data
        const oldQueryData = queryClient.getQueryData([
          "assessment-details",
          sbcaIdentifier,
        ]) as AssessmentDetailsFields;

        // On mutation update only the fields regarding the AI summary to trigger the 'Loading' UI
        queryClient.setQueryData(["assessment-details", sbcaIdentifier], {
          ...oldQueryData,
          ai_summary: {
            is_regenerated: true,
            last_regenerated_date: Date.now(),
            text: "",
          },
        });

        toast.success(
          `Summary is being ${
            oldQueryData.ai_summary && oldQueryData.ai_summary.last_regenerated_date
              ? "regenerated"
              : "generated"
          }`,
          {
            toastId: "summary-generation",
          },
        );
      },
      onError: () => {
        toast.dismiss("summary-generation");
      },
    },
  );
};
