/** Pre-defined text to be used as validation error message when there are HTML tags within a received text */
export const SCHEMAS_NO_HTML_MESSAGE_TEXT: string = "HTML code is not allowed!";

/** Pre-defined text to be used as validation error message for passwords */
export const SCHEMAS_PASSWORD_MESSAGE: string =
  "Your password must be at least 8 characters long. It is recommended that you use mixture of letters, numbers and special characters.";

/** Type definition for "value" parameter used in validation tests for file upload related fields */
export type SchemasFileValueTestValidation = {
  name: string;
  size: number;
};
