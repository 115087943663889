// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Utilities & Hooks
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useErrorReporting from "../../../hooks/useErrorReporting";
import {
  useAdminClientsGetSpecific,
  useAdminClientUpdateDetails,
} from "../../../api/Clients/AdminClients";

// Components
import { Field, Form, Formik } from "formik";
import ContentHeader from "../../../components/Content/ContentHeader";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import FormDropdownSearchable from "../../../components/Form/FormDropdownSearchable";
import FormInputSideLabel from "../../../components/Form/FormInputSideLabel";
import FormPhoneInput from "../../../components/Form/FormPhoneInput";

// Interfaces
import { CountryOption } from "../../../interfaces/global";
import { AdminClientsEditFormDetailsState } from "./interfaces";
import { DropdownItem } from "../../../components/Dropdown/interfaces";

// Schemas
import { ADMIN_CLIENTS_EDIT_SCHEMA } from "../../../schemas/ClientsSchemas";

// Statics
import {
  CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN,
  CLIENTS_DISABLE_SBCA_DROPDOWN,
  CLIENTS_STATUSES_DROPDOWN,
} from "./statics";
import {
  COUNTRY_BASED_ADDRESS_LABELS,
  COUNTRY_DROPDOWN,
  STATES_DROPDOWN,
} from "../../../constants/countries";

const AdminClientsEdit = () => {
  const navigate = useNavigate();

  const { slug } = useParams();
  const errorReporting = useErrorReporting();

  /*=============================
    GET CLIENT'S DETAILS
  ==============================*/
  const { data: clientData, isLoading: clientIsLoading } = useAdminClientsGetSpecific(slug);

  const [clientFormDetails, setClientFormDetails] = useState<AdminClientsEditFormDetailsState>({
    name: "",
    slug: "",
    description: "",
    status_id: 0,
    website_url: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    enable_photos: false,
    disable_sbca: false,
  });

  /*===============================
    PREPOPULATE THE USER'S
    FORM FIELDS
  ================================*/

  useEffect(() => {
    // Exit function if there's no "client" data available
    if (!clientData || !Object.entries(clientData).length || clientIsLoading) return;

    // Find and pre-select the client's status from the pre-defined values based on received value from API
    const preselectedStatusID: DropdownItem | undefined = CLIENTS_STATUSES_DROPDOWN.find(
      predefinedStatus => {
        return predefinedStatus.text.toLowerCase() === clientData.status.toLowerCase();
      },
    );

    // Update the client's related states
    setClientFormDetails({
      name: clientData.name,
      slug: clientData.slug,
      description: clientData.description || "",
      status_id: preselectedStatusID?.value ?? 0,
      website_url: clientData.website_url || "",
      country: clientData.address?.country || "US",
      address: clientData.address?.address || "",
      city: clientData.address?.city || "",
      state: clientData.address?.state || "",
      zip: clientData.address?.zip || "",
      phone: clientData.phone || "",
      enable_photos: clientData.companyOption.enable_photos,
      disable_sbca: clientData.companyOption.disable_sbca,
    });
  }, [clientData]);

  /*===============================
    UPDATE CLIENT'S DETAILS
  ================================*/
  const clientDetailsUpdate = useAdminClientUpdateDetails(slug || "");

  const handleUpdateClientDetails = async (details: AdminClientsEditFormDetailsState) => {
    try {
      await clientDetailsUpdate.mutateAsync(details);

      navigate(`/admin/clients/${details.slug}/edit/`);
    } catch (error) {
      errorReporting("Failed updating client's details.", error, { client_slug: slug, ...details });
    }
  };

  return (
    <>
      <div className="container py--25">
        <ContentHeader title="Edit Client" />

        <Link to="/admin/clients/" className="d-inline-flex align-items-center txt--lg my--20">
          <BackIcon className="txt--gray" />
          <span className="txt--blue fw--regular">Back</span>
        </Link>

        {clientIsLoading ? (
          <Loader size="page" modifierWrapper="loader--page" />
        ) : (
          <>
            <Formik
              initialValues={clientFormDetails}
              enableReinitialize
              validationSchema={ADMIN_CLIENTS_EDIT_SCHEMA}
              onSubmit={handleUpdateClientDetails}
            >
              {({ values, setFieldValue }) => (
                <Form
                  style={{
                    maxWidth: 700,
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <Field
                    component={FormInputSideLabel}
                    label="Name"
                    id="name"
                    name="name"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    component={FormInputSideLabel}
                    label="Slug (CareerPages url friendly name, no spaces!)"
                    id="slug"
                    name="slug"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                  />

                  <Field
                    component={FormInputSideLabel}
                    label="Description"
                    id="description"
                    name="description"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                    title={values.description}
                  />

                  <Field
                    component={FormDropdownSearchable}
                    id="status_id"
                    name="status_id"
                    placeholder="Select Status"
                    items={CLIENTS_STATUSES_DROPDOWN}
                    size="full"
                    label="Status"
                    modifierClass="dropdown--side-label dropdown--side-label--lg dropdown--capitalize mb--15"
                    handleFieldUpdate={(status: DropdownItem) => {
                      setFieldValue("status_id", status.value);
                    }}
                    preselectedItemValue={values.status_id}
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                  />

                  <Field
                    component={FormInputSideLabel}
                    label="Website"
                    id="website_url"
                    name="website_url"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    component={FormDropdownSearchable}
                    id="country"
                    name="country"
                    placeholder="Select Country"
                    items={COUNTRY_DROPDOWN}
                    size="full"
                    label="Country"
                    modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
                    preselectedItemValue={values.country}
                    handleFieldUpdate={(selectedCountry: DropdownItem) => {
                      setFieldValue("country", selectedCountry.value);
                      setFieldValue("state", "");
                    }}
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                    disabled
                  />

                  <Field
                    component={FormInputSideLabel}
                    label="Address"
                    id="address"
                    name="address"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    component={FormInputSideLabel}
                    label={
                      COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.city || "City"
                    }
                    id="city"
                    name="city"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    key={values.country}
                    component={FormDropdownSearchable}
                    id="state"
                    name="state"
                    label={
                      COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.state ??
                      "State"
                    }
                    placeholder={`Select ${
                      COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.state ??
                      "State"
                    }`}
                    items={STATES_DROPDOWN[values.country as CountryOption]}
                    size="full"
                    modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
                    handleFieldUpdate={(selectedState: DropdownItem) => {
                      setFieldValue("state", selectedState.value);
                    }}
                    preselectedItemValue={values.state}
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                    disabled
                  />

                  <Field
                    component={FormInputSideLabel}
                    label={
                      COUNTRY_BASED_ADDRESS_LABELS[values.country as CountryOption]?.code ??
                      "Zip Code"
                    }
                    id="zip"
                    name="zip"
                    modifierClass="input-side-label--lg mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    component={FormPhoneInput}
                    label="Phone"
                    id="phone"
                    name="phone"
                    modifierClass="input--phone-side-label mb--15"
                    size="full"
                    readOnly
                    disabled
                  />

                  <Field
                    component={FormDropdownSearchable}
                    id="enable_photos"
                    name="enable_photos"
                    placeholder={values.enable_photos ? "Enable" : "Disable"}
                    items={CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN}
                    size="full"
                    label="Applicant Photos"
                    modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
                    handleFieldUpdate={(photos: DropdownItem) => {
                      setFieldValue("enable_photos", !!photos.value);
                    }}
                    preselectedItemValue={Number(values.enable_photos) || null}
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                  />
                  <Field
                    component={FormDropdownSearchable}
                    id="disable_sbca"
                    name="disable_sbca"
                    placeholder={values.disable_sbca ? "Disable" : "Enable"}
                    items={CLIENTS_DISABLE_SBCA_DROPDOWN}
                    size="full"
                    label="SBCA"
                    modifierClass="dropdown--side-label dropdown--side-label--lg mb--15"
                    handleFieldUpdate={(sbca: DropdownItem) => {
                      setFieldValue("disable_sbca", !!sbca.value);
                    }}
                    preselectedItemValue={Number(values.disable_sbca) || null}
                    framerAnimationCustomProps={{ hasSideLabel: true }}
                  />

                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary mt--20 ml--135"
                    isLoading={clientDetailsUpdate.isLoading}
                    isDisabled={clientDetailsUpdate.isLoading}
                  >
                    Edit Client
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default AdminClientsEdit;
