// Assets
import NotificationsIcon from "../../assets/images/icons/bell.svg?react";

// Utilities & Hooks
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  useArticlesNotificationsMarkAllAsRead,
  useArticlesNotificationsGet,
} from "../../api/Articles/Articles";
import { motion, AnimatePresence } from "framer-motion";
import useErrorReporting from "../../hooks/useErrorReporting";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Components
import ArticleNotification from "./ArticleNotification";
import ArticlesNotificationsSkeleton from "./ArticlesNotificationsSkeleton";
import Loader from "../Loader/Loader";

// Interfaces
import { ArticlesNotificationsState } from "../../api/Articles/interfaces";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { FRAMER_HEADER_TRANSITIONS, FRAMER_HEADER_ANIMATION_COMMON } from "../../constants/framer";

const ArticleNotificationsMenu = () => {
  const errorReporting = useErrorReporting();

  /*=================================
   ARTICLES NOTIFICATIONS MENU STATE
  ==================================*/
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState<boolean>(false);
  const notificationsMenuRef = useRef<HTMLDivElement | null>(null);

  // Close the menu when clicked outside of it
  useOnClickOutside(notificationsMenuRef, () => setIsNotificationsMenuOpen(false));

  /*==============================
    GET ARTICLES NOTIFICATIONS
  ===============================*/
  // prettier-ignore
  const [articlesNotifications, setArticlesNotifications] = useState<ArticlesNotificationsState[]>([]);
  const [articlesNotificationsCounter, setArticlesNotificationsCounter] = useState<number>(0);
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchArticlesNotifications,
  } = useArticlesNotificationsGet();

  // Update the articles notifications state based
  // on the response from the API
  useEffect(() => {
    if (!data || !data.notifications || !data.notifications.length || isLoading) return;

    // Update the list of the notifications
    setArticlesNotifications(data.notifications);

    // Update the counter of unread notifications
    setArticlesNotificationsCounter(data.unread_notifications);
  }, [data]);

  // Trigger a refetch of the Articles Notifications
  // query anytime the menu is reopened
  useEffect(() => {
    if (!isNotificationsMenuOpen) return;

    refetchArticlesNotifications();
  }, [isNotificationsMenuOpen]);

  /*==============================
    MARK ALL NOTIFICATIONS AS READ
  ===============================*/
  const markAllAsRead = useArticlesNotificationsMarkAllAsRead();
  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead.mutateAsync();
    } catch (error) {
      errorReporting("Failed marking all notifications as read.", error);
    }
  };

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(notificationsMenuRef, () => setIsNotificationsMenuOpen(false));

  return (
    <div
      className="articles-notifications"
      ref={notificationsMenuRef}
      tabIndex={0}
      data-testid="component:dropdown-header-article-notifications"
    >
      <div
        className="articles-notifications__icon"
        onClick={() => setIsNotificationsMenuOpen(!isNotificationsMenuOpen)}
      >
        <NotificationsIcon data-testid="component:articles-notification-menu-icon" />

        {articlesNotificationsCounter > 0 && (
          <span
            className="notifications-counter"
            data-testid="component:articles-notification-menu-counter"
          >
            {articlesNotificationsCounter}
          </span>
        )}
      </div>

      <AnimatePresence>
        {isNotificationsMenuOpen && (
          <>
            {isLoading ? (
              <ArticlesNotificationsSkeleton />
            ) : (
              <motion.div
                key="framer-header-notifications-dropdown"
                className="articles-notifications__menu"
                variants={FRAMER_HEADER_ANIMATION_COMMON}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={FRAMER_HEADER_TRANSITIONS}
              >
                {articlesNotifications.length > 0 ? (
                  <>
                    <div className="articles-notifications__header">
                      <h6 className="d-flex align-items-center">
                        Notifications
                        {isFetching && <Loader size="sm" modifierWrapper="ml--10" />}
                      </h6>

                      <span className="txt--blue fw--semibold" onClick={handleMarkAllAsRead}>
                        Mark All as Read
                      </span>
                    </div>

                    {articlesNotifications.map((notification: ArticlesNotificationsState) => (
                      <ArticleNotification
                        key={notification.id}
                        {...notification}
                        handleNotificationsMenu={() => setIsNotificationsMenuOpen(false)}
                      />
                    ))}

                    <div className="articles-notifications__footer">
                      <Link
                        to="/articles/"
                        className="txt--link"
                        onClick={() => setIsNotificationsMenuOpen(false)}
                      >
                        See All
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="articles-notifications__header justify-content-center">
                    <h6 className="d-flex align-items-center txt--center">
                      No new notifications
                      {isFetching && <Loader size="sm" modifierWrapper="ml--10" />}
                    </h6>
                  </div>
                )}
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ArticleNotificationsMenu;
