import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import fetchHandler from "../fetchHandler";
import {
  ChatBlockActionsPayloadFields,
  ChatMessagesResponseFields,
  ChatNewConverationRequestFields,
  ChatsForSpecificUserResponseFields,
} from "./interfaces";

/**
 *
 * Get all the chats that exist for a specific user
 *
 * @param selectedUserID Optional parameter - The ID of the targeted user for who we want to
 * get all existing chats.
 *
 * If no valid ID is provided, defaults to the ID of the currently logged in user.
 *
 */
export function useChatGetAllUserChats(selectedUserID?: number) {
  const { user: currentUser } = useAuth();

  // If a specific user was selected from the list, use their ID to fetch the chats.
  // Otherwise default to the ID of the currently logged in user
  const id: number = selectedUserID || currentUser.id;

  // Check if the user has a permission to read the chats
  const hasPermissionToReadChats: boolean = handlePermissionCheck(["sms_read"]);

  return useQuery(
    ["sms-chats", id],
    async () => {
      return (await fetchHandler(
        "GET",
        `sms/chats?user_id=${id}`,
      )) as ChatsForSpecificUserResponseFields[];
    },
    { enabled: !!id && hasPermissionToReadChats === true },
  );
}

/**
 *
 * "Block" or "Unblock" a targeted chat
 *
 */
export function useChatBlockActions() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    async (chatBlockActions: ChatBlockActionsPayloadFields) => {
      return await fetchHandler("POST", `sms/chats/${chatBlockActions.chatID}/block`, {
        action: chatBlockActions.action,
      });
    },
    {
      onMutate: (chatActions: ChatBlockActionsPayloadFields) => {
        // Dismiss notification for chat actions (if it exists in the UI)
        toast.dismiss("chat-block");

        // Make a copy of the currently existing, cached, chats data
        const previousChats = queryClient.getQueryData([
          "sms-chats",
          user.id,
        ]) as ChatsForSpecificUserResponseFields[];

        // Find the targeted chat from the cached data
        const targetedChatIndex: number = previousChats.findIndex(previousChat => {
          return previousChat.id === chatActions.chatID;
        });

        // If targeted chat cannot be found, exit function
        if (targetedChatIndex < 0) return { previousChats };

        // Make a copy of the chats list that we'll use for mutations
        const updatedChats: ChatsForSpecificUserResponseFields[] = [...previousChats];

        // Update the properties of the targeted chat, by making a new object,
        // so we can safely update the state
        const updatedTargetedChat: ChatsForSpecificUserResponseFields = {
          ...updatedChats[targetedChatIndex],
          is_blocked: chatActions.action === "block" ? 1 : 0,
        };

        // Update the list of chats with the newly created (updated) chat
        updatedChats.splice(targetedChatIndex, 1, updatedTargetedChat);

        // Update the query data (this will also trigger UI state update)
        queryClient.setQueryData(["sms-chats", user.id], updatedChats);

        // Show success notification
        const actionSuccessMessage: string = `${
          chatActions.action === "block" ? "blocked" : "unblocked"
        }`;
        toast.success(`Chat ${actionSuccessMessage} successfully!`, {
          toastId: "chat-block",
        });

        // Return the previously cached chats so they can be used in an error scenario
        return { previousChats };
      },
      onError: (error, _variables, context) => {
        // Dismiss success notification from the UI first
        toast.dismiss("chat-block");

        // In case of an error, fallback to the previously stored chats
        queryClient.setQueryData(["sms-chats", user.id], context?.previousChats);

        // Return the error message
        return error;
      },
    },
  );
}

/**
 *
 * Get all the messages for a specific chat
 *
 */
export function useChatGetMessages(chatID: number | null) {
  return useQuery(
    ["sms-chat-messages", chatID],
    async () => {
      return (await fetchHandler(
        "GET",
        `sms/chats/${chatID}/messages`,
      )) as ChatMessagesResponseFields;
    },
    { enabled: !!chatID },
  );
}

/**
 *
 * Initialize a new chat conversation with
 * the targeted applicant for the first time
 *
 */
export function useChatInitializeNewConversation() {
  return useMutation(
    async (details: ChatNewConverationRequestFields) => {
      return await fetchHandler("POST", "sms/messages", details);
    },
    {
      onError: error => error,
    },
  );
}
