// Components
import Tooltip from "../../Tooltip/Tooltip";

// Interfaces
import { ApplicationResumeProps } from "../interfaces";

// Assets
import ResumeIcon from "../../../assets/images/icons/applications-resume-icon.svg?react";

const ApplicationResume: React.FC<ApplicationResumeProps> = ({
  resume,
  children,
  handleResume,

  tooltipText = "Preview Resume",
}) => {
  return (
    <>
      <Tooltip text={tooltipText} size="md">
        <div
          className={`applications__socials__icon ${
            resume ? "applications__socials__icon--active" : ""
          }`}
          onClick={() => handleResume(resume)}
          data-testid="button:resume-action"
          aria-disabled={resume ? false : true}
        >
          <ResumeIcon />
        </div>
      </Tooltip>

      {children}
    </>
  );
};

export default ApplicationResume;
