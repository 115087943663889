import * as Yup from "yup";
import {
  FILE_EXTENSIONS_PROFILE_IMAGE,
  FILE_EXTENSIONS_RESUME,
  FILE_MAX_BYTES_SIZE,
} from "../pages/OnlineApplication/form-builder/constants";
import handleExtractFileExtension from "../utilities/strings/handleExtractFileExtension";
import { SchemasFileValueTestValidation } from "./constants";

export const ONLINE_APPLICATION_ASSESSMENT_QUESTIONS_SCHEMA = Yup.object().shape({
  accept: Yup.boolean().oneOf([true], "sbca_assessment:form.errors.accept.required"),
  extra_curricular: Yup.string()
    .max(255, "sbca_assessment:form.errors.extra_curricular.max")
    .required("sbca_assessment:form.errors.extra_curricular.required"),
  job_father: Yup.string()
    .max(255, "sbca_assessment:form.errors.job_father.max")
    .required("sbca_assessment:form.errors.job_father.required"),
  job_mother: Yup.string()
    .max(255, "sbca_assessment:form.errors.job_mother.max")
    .required("sbca_assessment:form.errors.job_mother.required"),
  favorite_member: Yup.number()
    .nullable()
    .required("sbca_assessment:form.errors.favorite_member.required"),
  q1: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q2: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q3: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q4: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q5: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q6: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q7: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q8: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q9: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q10: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q11: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q12: Yup.number()
    .typeError("sbca_assessment:form.errors.sliders.type_error")
    .min(1, "sbca_assessment:form.errors.sliders.min")
    .max(10, "sbca_assessment:form.errors.sliders.max"),
  q13: Yup.array().min(2, "sbca_assessment:form.errors.colors.two_colors"),
  q14: Yup.array().min(1, "sbca_assessment:form.errors.colors.most_liked"),
  q15: Yup.array().min(1, "sbca_assessment:form.errors.colors.most_disliked"),
});

export const ONLINE_APPLICATION_UPLOAD_RESUME_SCHEMA = Yup.object().shape({
  resume: Yup.mixed()
    .nullable()
    .test("application-resume", "Resume must be selected for upload.", value => {
      // If there's no resume selected, throw error
      if (!value) return false;

      return true;
    })
    .test(
      "application-resume-extension",
      "Resume validation failed: Allowed file types are Microsoft Word (DOC, DOCX), PDF, TXT, RTF, ODT, PS, WPD, WPS\n\nPlease correct the issue above and upload again.",
      value => {
        // If the value is null (default value) then no validation is applied
        if (!value) return false;

        // Extract the file extension from the selected file
        const fileExtension: string = handleExtractFileExtension(
          (value as SchemasFileValueTestValidation).name,
        );

        // If file extension is not allowed, throw an error
        if (!FILE_EXTENSIONS_RESUME.includes(fileExtension)) return false;

        return true;
      },
    )
    .test("application-resume-size", "Resume must be up to a maximum of 8mb in size", value => {
      // If the value is null (default value) then no validation is applied
      if (!value) return false;

      // If the file is bigger than 8mb, throw error, otherwise test passes
      return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
    }),
});

export const ONLINE_APPLICATION_UPLOAD_PHOTO_SCHEMA = Yup.object().shape({
  photo: Yup.mixed()
    .nullable()
    .test("application-profile-image", "Profile Image must be selected", value => {
      // If there's no profile image selected, throw error
      if (!value) return false;

      return true;
    })
    .test(
      "application-profile-image-extension",
      "Image validation failed: Allowed file types are JPEG, JPG, PNG, WEBP, SVG, GIF, TIFF\n\nPlease correct the issue above and upload again.",
      value => {
        // If the value is null (default value) then no validation is applied
        if (!value) return false;

        // Extract the file extension from the selected file
        const fileExtension: string = handleExtractFileExtension(
          (value as SchemasFileValueTestValidation).name,
        );

        // If file extension is not allowed, throw an error
        if (!FILE_EXTENSIONS_PROFILE_IMAGE.includes(fileExtension)) return false;

        return true;
      },
    )
    .test(
      "application-profile-image-size",
      "Profile Image must be up to a maximum of 8mb in size",
      value => {
        // If the value is null (default value) then no validation is applied
        if (!value) return false;

        // If the file is bigger than 8mb, throw error, otherwise test passes
        return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
      },
    ),
});
