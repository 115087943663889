import { getIn } from "formik";
import { FormInputWithElementsProps } from "./interfaces";

const FormInputWithElements = ({
  form,
  field,
  label = "",
  modifierClass = "",
  description = "",
  isRequired = false,
  isDisabled = false,
  leftElement = null,
  rightElement = null,
  size = "full",
  ...props
}: FormInputWithElementsProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    TRIM FORM FIELD VALUE
  ================================*/
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue: string = event.target.value.trim();

    // Update the form value to which this field corresponds to
    form.setFieldValue(field.name, trimmedValue);

    // Trigger internal Formik 'onBlur' events for the field
    field.onBlur(event);
  };

  return (
    <div
      className={`input input-with-elements input--${size} ${modifierClass} ${
        errors && touched ? "input-with-elements--error" : ""
      } ${isDisabled ? "input-with-elements--disabled" : ""}`}
    >
      {label && (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div className="input__wrapper">
        {leftElement && (
          <label
            htmlFor={field.name}
            className="input-with-elements__element input-with-elements__element--left"
          >
            {leftElement}
          </label>
        )}

        <input
          {...field}
          {...props}
          onBlur={handleOnBlur}
          disabled={isDisabled}
          className={`input__field`}
        />

        {rightElement && (
          <label
            htmlFor={field.name}
            className="input-with-elements__element input-with-elements__element--right"
          >
            {rightElement}
          </label>
        )}
      </div>

      {/* DESCRIPTION OF THE FIELD */}
      {description && <p className="input__description">{description}</p>}

      {/* DISPLAY ERROR MESSAGES */}
      {errors && touched && <p className="input__error">{errors}</p>}
    </div>
  );
};

export default FormInputWithElements;
