import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAccessPermissions } from "../../interfaces/permissions";
import { useAuth } from "../../providers/auth-context";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

interface PermissionCheckPageWrapperProps {
  children: JSX.Element;
  routePermissions?: UserAccessPermissions[];

  /** Requires that the user has `all` of the expected permissions before granting access */
  requireAllPermissions?: boolean;
}

/**
 *
 * Component that checks if the user has access to a specific page route.
 *
 * This is being used as part of the Authenticated application layout, where
 * all of the authenticated routes are being rendered.
 *
 * If the user has the required permission to access the targeted page (e.g. "/admin/clients")
 * then user will be allowed to see that page. Otherwise will be redirected to the dashboard home page.
 *
 */
const PermissionCheckPageWrapper = ({
  children,
  routePermissions = ["*"],
  requireAllPermissions = false,
}: PermissionCheckPageWrapperProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  /*============================================
    INITIALIZE QUERY CLIENT AND 
    READ THE STATE OF THE "PROFILE" API REQUEST
  =============================================*/
  const applicationsPermissionCheck: boolean = handlePermissionCheck(["applicant_dashboard_view"]);
  const allowAccessBasedOnPermissionCheck: boolean = handlePermissionCheck(
    routePermissions,
    requireAllPermissions,
  );

  /*======================================
    REDIRECT USER TO "403" PAGE
    IF THEY DONT HAVE ACCESS TO THE PAGE
  =======================================*/
  useEffect(() => {
    // If user is already on the unauthorized page, do nothing
    if (location.pathname.startsWith("/unauthorized")) return;

    // If there's no user's role value in the authentication context (yet), proceed rendering the requested page
    if (!user.role) return;

    // If access is allowed then allow the user to visit the page
    // Otherwise proceed to redirect the user back to "Applications" page
    if (allowAccessBasedOnPermissionCheck) return;

    if (location.pathname.startsWith("/applications") && !applicationsPermissionCheck) {
      // If user was redirected to "Applications" page due to not having the necessary permission,
      // but then also does not have the permission to see the "Applications" page, redirect again
      // to the profile settings page.
      setTimeout(() => navigate("/account/profile"), 50);
    } else if (!allowAccessBasedOnPermissionCheck) {
      // If the user does not have the necessary permission to view a specific page, redirect to "Applications" page
      navigate("/applications/");
    }
  }, [allowAccessBasedOnPermissionCheck, routePermissions, user.role, location.pathname]);

  return children;
};

export default PermissionCheckPageWrapper;
