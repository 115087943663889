// Hooks & Utilities
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useJobAdsGetStatuses, useJobAdsUpdateStatus } from "../../../api/JobAds/JobAds";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Interfaces
import { JobAdsStatusesResponseFields, JobAdStatus } from "../../../api/JobAds/interfaces";
import { JobAdStatusUpdateActionsProps } from "./interfaces";

// Constants
import { JOB_ADS_STATUS_UPDATES_TEXT_MAPPINGS } from "../../../pages/JobAds/constants";

// Components
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";
import Tooltip from "../../Tooltip/Tooltip";
import { AnimatePresence } from "framer-motion";

const JobAdStatusUpdateActions = ({
  id,
  status,
  location = "header",
  ad_preview_id,
  tracking,
  company_is_active,
}: JobAdStatusUpdateActionsProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    FETCH JOB AD STATUSES
  ===========================*/
  const { data: jobAdStatusesData, isLoading: jobAdStatusesLoading } = useJobAdsGetStatuses();

  /*=========================
    UPDATE AD STATUS
  ===========================*/
  const [adStatusUpdateDetails, setAdStatusUpdateDetails] = useState<Record<
    "status",
    JobAdStatus
  > | null>(null);
  const [showAdStatusUpdateModal, setShowAdStatusUpdateModal] = useState<boolean>(false);
  const updateAdStatus = useJobAdsUpdateStatus();

  const handleOpenAdStatusUpdateModal = (newStatus: JobAdStatus) => {
    setShowAdStatusUpdateModal(true);
    setAdStatusUpdateDetails({ status: newStatus });
  };

  const handleCloseAdStatusUpdateModal = () => {
    setShowAdStatusUpdateModal(false);
    setAdStatusUpdateDetails(null);
  };

  const handleUpdateAdStatus = async () => {
    // Prevent any update actions if there's no job ad ID available
    if (!id || !parseInt(id)) {
      toast.error("Invalid Job Ad ID provided.");
      return;
    }

    // Prevent any update actions if there's no selected status or no ID is present
    if (!adStatusUpdateDetails) return;

    // Prevent any update actions if the job ad statuses are still loading
    if (jobAdStatusesLoading) {
      toast.info("Please wait while Job Ad Statuses are being fetched, then try again.");
      return;
    }

    // Find the status data that corresponds to the new status
    // to which we want to move the targeted ad
    const statusData: JobAdsStatusesResponseFields | undefined = jobAdStatusesData?.find(status => {
      return status.name.toLowerCase() === adStatusUpdateDetails.status.toLowerCase();
    });

    // Prevent any ad status updates if there's no status data to work with
    if (!statusData) return;

    try {
      // Update the details for the targeted ad
      await updateAdStatus.mutateAsync({
        ad_id: parseInt(id),
        status_id: statusData.id,
      });

      // Close the modal and reset update details to defaults
      handleCloseAdStatusUpdateModal();
    } catch (error) {
      errorReporting("Failed updating ad status", error, { ad_id: id, status_id: statusData.id });
    }
  };

  /*==========================
    TRACKING
  ============================*/
  const [isTracked, setIsTracked] = useState<boolean>(false);

  useEffect(() => {
    const trackingStatus: boolean = Boolean(tracking.status && tracking.tracker);
    setIsTracked(trackingStatus);
  }, [tracking.status]);

  return (
    <>
      <div className="job-ads-details__actions">
        <PermissionCheckComponentWrapper permissions={["ad_manager_edit"]}>
          {status !== "takedown" ? (
            <Tooltip
              text={isTracked ? `${tracking.tracker} is currently editing this ad` : ""}
              size="md"
            >
              <Link
                to={`/job-ads/ads/${id}/edit/`}
                className={`btn btn--fluid btn--primary ${isTracked ? "job-ads__tracked-btn" : ""}`}
              >
                {status === "new" ? "Create Ad" : "Revise Ad"}
              </Link>
            </Tooltip>
          ) : null}
        </PermissionCheckComponentWrapper>

        <PermissionCheckComponentWrapper permissions={["ad_manager_take_down"]}>
          {status !== "expired" ? (
            <Tooltip
              text={isTracked ? `${tracking.tracker} is currently editing this ad` : ""}
              size="md"
            >
              <Button
                modifierClass={`btn--fluid btn--danger ml--10 ${
                  isTracked ? "job-ads__tracked-btn" : ""
                }`}
                onClick={() => handleOpenAdStatusUpdateModal("takedown")}
              >
                Take Down Ad
              </Button>
            </Tooltip>
          ) : null}
        </PermissionCheckComponentWrapper>

        {status === "pending" ? (
          <Tooltip
            text={isTracked ? `${tracking.tracker} is currently editing this ad` : ""}
            size="md"
          >
            <Button
              modifierClass={`btn--fluid btn--primary--light ml--10 ${
                isTracked ? "job-ads__tracked-btn" : ""
              }`}
              onClick={() => handleOpenAdStatusUpdateModal("ready_to_post")}
            >
              Ready To Post
            </Button>
          </Tooltip>
        ) : null}

        <PermissionCheckComponentWrapper permissions={["ad_manager_publish"]}>
          {status === "ready_to_post" ? (
            <Tooltip
              text={isTracked ? `${tracking.tracker} is currently editing this ad` : ""}
              size="md"
            >
              <Button
                modifierClass={`btn--fluid btn--primary--light ml--10 ${
                  isTracked ? "job-ads__tracked-btn" : ""
                }`}
                isDisabled={!company_is_active}
                onClick={() => {
                  // Prevent triggering status updates if the ad belongs to inactive client
                  if (!company_is_active) return;

                  handleOpenAdStatusUpdateModal("active");
                }}
              >
                Publish Ad
              </Button>
            </Tooltip>
          ) : null}
        </PermissionCheckComponentWrapper>

        {location === "live_ad_preview" && ad_preview_id ? (
          <Link
            to={`/preview/job-ads/${ad_preview_id}/`}
            target="_blank"
            className="btn btn--fluid btn--primary--light ml--10"
          >
            Preview
          </Link>
        ) : null}
      </div>

      <AnimatePresence>
        {showAdStatusUpdateModal && adStatusUpdateDetails ? (
          <Modal
            title={`Are you sure you want to ${
              JOB_ADS_STATUS_UPDATES_TEXT_MAPPINGS[adStatusUpdateDetails.status]
            }`}
            text=""
            handleCloseModal={() => setShowAdStatusUpdateModal(false)}
            modifierClass="modal--md modal--fixated p--30i"
          >
            <div className="d-flex justify-content-center align-items-center mt--20">
              <Button
                modifierClass="btn--fluid btn--primary--light"
                onClick={() => setShowAdStatusUpdateModal(false)}
              >
                Cancel
              </Button>

              <Button
                modifierClass="btn--fluid--md btn--primary ml--20"
                onClick={handleUpdateAdStatus}
                isLoading={updateAdStatus.isLoading}
                isDisabled={updateAdStatus.isLoading}
              >
                Yes
              </Button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default JobAdStatusUpdateActions;
