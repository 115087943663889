// Interfaces
import { DropdownItem } from "../../../components/Dropdown/interfaces";

/**
 *
 * Map the received `options` field values into dropdown items, which
 * exist if the field is either of `select` or `multiselect` type.
 *
 * @param options An array of objects representing the `select` / `multiselect` field selection options
 *
 * @returns Array of dropdown items
 *
 */
export function handleFormSelectFieldOptions(
  options: Record<string, string | number>[] | null,
): DropdownItem[] {
  // Exit function if there's no selection options provided
  if (!options || !options.length) return [];

  // Exit if the array of options only consists of empty objects
  if (options.every(option => !Object.entries(option).length)) return [];

  // Filter out all of the empty objects and only map the ones that have valid key/value pairs within them
  const mappedOptions: DropdownItem[] = options
    .filter(option => Object.entries(option).length)
    .map(option => {
      const temporaryDropdownObject: DropdownItem = { text: "", value: "" };

      for (const [dropdownText, dropdownValue] of Object.entries(option)) {
        temporaryDropdownObject.text = dropdownText;
        temporaryDropdownObject.value = dropdownValue;
      }

      return temporaryDropdownObject;
    });

  return mappedOptions;
}
