import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../Card/Card";
import { JobAdDetailsAdLinksProps } from "./interfaces";

const JobAdLinks = ({ form_identifier, live_ad_preview_identifier }: JobAdDetailsAdLinksProps) => {
  /*=============================
    CONSTRUCT AD PREVIEW URL
  ==============================*/
  const [previewAdURL, setPreviewAdURL] = useState<string>("");

  useEffect(() => {
    const { host, protocol } = window.location;
    const url: string = `${protocol}//${host}/preview/job-ads/${live_ad_preview_identifier}`;
    setPreviewAdURL(url);
  }, [live_ad_preview_identifier]);

  /*==================================
    CONSTRUCT AD FORM APPLICATION URL
  ===================================*/
  const [formApplicationURL, setFormApplicationURL] = useState<string>("");

  useEffect(() => {
    const { host, protocol } = window.location;
    const url: string = `${protocol}//${host}/online-application/initialize-oa/${form_identifier}`;
    setFormApplicationURL(url);
  }, [form_identifier]);

  return (
    <div className="col-12 col-lg-6 mb--20">
      <Card modifierClass="card--padding--xl">
        <div className="job-ads-details__ad-links">
          <h3 className="fw--semibold txt--blue mb--20">Ad Links</h3>

          <h6 className="txt--lg txt--gray--darkest fw--semibold mb--10">
            Ad URL
            <Link
              to={`/preview/job-ads/${live_ad_preview_identifier}`}
              target="_blank"
              className="txt--md txt--blue ml--5"
            >
              (Click here to view ad)
            </Link>
          </h6>
          <textarea
            title="Job Ad URL preview"
            className="fw--regular input-textarea input-textarea--disabled mb--20"
            defaultValue={previewAdURL}
            rows={3}
            readOnly
            disabled
          />

          <h6 className="txt--lg txt--gray--darkest fw--semibold mb--10">
            URL for Application
            <Link
              to={`/online-application/initialize-oa/${form_identifier}`}
              target="_blank"
              className="txt--md txt--blue ml--5"
            >
              (Click here to view application)
            </Link>
          </h6>
          <textarea
            title="Job Application URL preview"
            className="fw--regular input-textarea input-textarea--disabled"
            defaultValue={formApplicationURL}
            rows={3}
            readOnly
            disabled
          />
        </div>
      </Card>
    </div>
  );
};

export default JobAdLinks;
