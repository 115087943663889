import { useEffect } from "react";

/**
 *
 * Hook that will add a class to the `body` HTML element
 * that will prevent the page from being scrolled.
 *
 * Example use case for this hook is when the `ModalAction` component
 * is mounted and rendered in the UI, that will add the class to the body element
 * and prevent it from being scrolled. And when the component gets unmounted,
 * the class is removed from the `body` element making the page scrollable again.
 *
 */
export function usePreventBodyScroll() {
  useEffect(() => {
    // Add the class that will be used to prevent scrolling in the body element
    document.body.classList.add("prevent-scroll");

    // On component unmount, remove the class from the body
    return () => document.body.classList.remove("prevent-scroll");
  }, []);
}
