import { DropdownItem } from "../../../../Dropdown/interfaces";

/*================================
  SPONSORED CAMPAIGNS
=================================*/
// Temporarily Disabled
// export const ZIP_RECRUITER_DROPDOWN_SPONSORED_CAMPAIGNS: DropdownItem[] = [
//   { text: "Vehicle Technicians", value: "campaign 1" },
//   { text: "Sales & Management", value: "campaign 2" },
//   { text: "Engineering & Medical", value: "campaign 3" },
//   { text: "Trades & Labor", value: "campaign 4" },
// ];

/*================================
  JOB CATEGORY
=================================*/
export const ZIP_RECRUITER_DROPDOWN_JOB_CATEGORY: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: 301, text: "Accounting/Finance" },
  { value: 302, text: "Admin/Secretarial" },
  { value: 303, text: "Advertising" },
  { value: 304, text: "Architect/Design" },
  { value: 305, text: "Art/Media/Writers" },
  { value: 306, text: "Automotive" },
  { value: 307, text: "Banking" },
  { value: 308, text: "Biotech/Pharmaceutical" },
  { value: 309, text: "Computer/Software" },
  { value: 310, text: "Construction/Skilled Trade" },
  { value: 311, text: "Customer Service" },
  { value: 312, text: "Domestic Help/Care" },
  { value: 313, text: "Education" },
  { value: 314, text: "Engineering" },
  { value: 315, text: "Environmental Science" },
  { value: 316, text: "Events" },
  { value: 317, text: "Everything Else" },
  { value: 318, text: "Facilities/Maintenance" },
  { value: 319, text: "General Labor/Warehouse" },
  { value: 320, text: "Gov/Military" },
  { value: 322, text: "Healthcare" },
  { value: 323, text: "Hospitality/Restaurant" },
  { value: 321, text: "HR & Recruiting" },
  { value: 324, text: "Information Technology" },
  { value: 325, text: "Insurance" },
  { value: 326, text: "Internet" },
  { value: 327, text: "Law Enforcement/Security" },
  { value: 328, text: "Legal" },
  { value: 329, text: "Management & Exec" },
  { value: 330, text: "Manufacturing/Operations" },
  { value: 331, text: "Marketing/PR" },
  { value: 332, text: "Nonprofit & Fund" },
  { value: 333, text: "Oil/Energy/Power" },
  { value: 334, text: "Quality Assurance" },
  { value: 335, text: "Real Estate" },
  { value: 336, text: "Research & Dev" },
  { value: 337, text: "Retail" },
  { value: 338, text: "Sales & Biz Dev" },
  { value: 339, text: "Salon/Beauty/Fitness" },
  { value: 340, text: "Social Services" },
  { value: 341, text: "Supply Chain/Logistics" },
  { value: 342, text: "Telecommunications" },
  { value: 343, text: "Travel" },
  { value: 344, text: "Trucking/Transport" },
  { value: 345, text: "TV/Film/Musicians" },
  { value: 346, text: "Vet & Animal Care" },
  { value: 347, text: "Work from Home" },
];

/*================================
  JOB STATUS
=================================*/
export const ZIP_RECRUITER_DROPDOWN_JOB_STATUS: DropdownItem[] = [
  { text: "Select a job status", value: "" },
  { value: 4, text: "Full-Time" },
  { value: 5, text: "Part-Time" },
  { value: 6, text: "Contractor" },
  { value: 7, text: "Temporary" },
  { value: 8, text: "Other" },
];

/*================================
  JOB EXPERIENCE LEVEL
=================================*/
export const ZIP_RECRUITER_DROPDOWN_JOB_EXPERIENCE_LEVEL: DropdownItem[] = [
  { text: "Select a job experience level", value: "" },
  { value: "intern", text: "Intern" },
  { value: "entry", text: "Entry Level (0-2 years)" },
  { value: "mid", text: "Mid Level (3-6 years)" },
  { value: "senior", text: "Senior Level (7+ years)" },
  { value: "director", text: "Director" },
  { value: "executive", text: "Executive" },
];

/*================================
  JOB EDUCATION LEVEL
=================================*/
export const ZIP_RECRUITER_DROPDOWN_JOB_EDUCATION_LEVEL: DropdownItem[] = [
  { text: "Select an education level", value: "" },
  { value: "ged", text: "High School Diploma/GED" },
  { value: "assoc", text: "	Associates Degree" },
  { value: "undergrad", text: "Bachelors Degree" },
  { value: "grad", text: "	Masters or Ph. D" },
];

/*================================
  JOB COMPENSATION TYPE
=================================*/
export const ZIP_RECRUITER_DROPDOWN_JOB_COMPENSATION_TYPE: DropdownItem[] = [
  { text: "Select a compensation type", value: "" },
  { value: 10, text: "Annually" },
  { value: 11, text: "Monthly" },
  { value: 12, text: "Weekly" },
  { value: 13, text: "Daily" },
  { value: 14, text: "Hourly" },
];
