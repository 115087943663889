import Skeleton from "react-loading-skeleton";
import { ChatConversationLocation } from "./interfaces";

const ChatConversationMessageSkeleton = ({
  chatLocation = "page",
  direction,
}: {
  chatLocation?: ChatConversationLocation;
  direction: "in" | "out";
}) => {
  return (
    <div
      className={`chat-message chat-message--skeleton chat-message--${direction} ${
        chatLocation === "panel" ? "chat-message--skeleton--panel" : ""
      }`}
    >
      {direction === "in" && <Skeleton width="33px" height="33px" circle className="mr--10" />}

      <div className="chat-message__content">
        <h6 className="chat-message__author">
          <Skeleton width="150px" height="15px" />
        </h6>

        <div className="chat-message__bubble">
          <Skeleton width="100%" height="35px" />
          <p className="chat-message__bubble__time">
            <Skeleton width="40px" height="10px" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatConversationMessageSkeleton;
