// Hooks & Utilities
import { Link, useParams } from "react-router-dom";
import { useLogsSMSGetSpecific } from "../../api/Logs/Logs";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";

// Components
import ContentHeader from "../../components/Content/ContentHeader";
import Card from "../../components/Card/Card";
import Skeleton from "react-loading-skeleton";
import Linkify from "linkify-react";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

const SMSLogDetails = () => {
  const { id } = useParams();

  // Fetch the details for the specific SMS log
  const { data, isError, isLoading } = useLogsSMSGetSpecific(id);

  return (
    <div className="container py--25">
      <ContentHeader
        modifierClass="mb--20"
        title={
          <div className="d-flex align-items-center">
            <Link
              to="/notification-logs/?tab=sms"
              className="d-flex align-items-center fw--medium txt--lg mr--20"
            >
              <BackIcon className="mr--5" />
              Back
            </Link>
            SMS Log - {id}
          </div>
        }
      />

      <Card modifierClass="card--padding--xl">
        {isLoading ? (
          <>
            <p className="mb--10 txt--black" data-testid="component:skeleton-logs-sms-details">
              <strong>Sent:</strong> <Skeleton width="60px" height="15px" />
            </p>

            <p className="mb--10 txt--black">
              <strong>Status:</strong> <Skeleton width="130px" height="15px" />
            </p>

            <p className="mb--10 txt--black">
              <strong>To:</strong> <Skeleton width="130px" height="15px" />
            </p>

            <p className="mb--10 txt--black">
              <strong>From:</strong> <Skeleton width="130px" height="15px" />
            </p>

            <p className="mb--10 txt--black">
              <strong>Content:</strong> <Skeleton width="200px" height="15px" />
            </p>
          </>
        ) : isError ? (
          <p>Failed obtaining details data for the targeted SMS Log.</p>
        ) : !data || !Object.entries(data).length ? (
          <h5>No SMS Log Details data available.</h5>
        ) : (
          <>
            {/* WHEN IT WAS SENT */}
            <p className="mb--10 txt--black">
              <strong className="mr--5">Sent:</strong>
              {data.sent ? format(handleDateAsTimestamp(data.sent), "MM/dd/yyyy H:mm:ss") : "N/A"}
            </p>

            {/* WAS IT DELIVERED OR NOT */}
            <p className="mb--10 txt--black txt--capitalize">
              <strong className="mr--5">Status:</strong>
              {data.status || "N/A"}
            </p>

            {/* TO WHO IT WAS SENT */}
            <p className="mb--10 txt--black">
              <strong className="mr--5">To:</strong>
              {data.to || "N/A"}
            </p>

            {/* FROM WHO IT WAS SENT */}
            <p className="mb--10 txt--black">
              <strong className="mr--5">From:</strong>
              {data.from || "N/A"}
            </p>

            {/* THE ACTION TYPE */}
            <p className="mb--10 txt--black">
              <strong className="mr--5">Action:</strong>
              {data.action || "N/A"}
            </p>

            {/* THE MESSAGE CONTENT */}
            <p className="mb--10 txt--black white-space--pre-line">
              <strong className="mr--5">Content:</strong>

              <Linkify
                as="span"
                options={{
                  target: "_blank",
                  className: "fw--medium txt--underline txt--link",
                  defaultProtocol: "https",
                }}
              >
                {data.content}
              </Linkify>
            </p>
          </>
        )}
      </Card>
    </div>
  );
};

export default SMSLogDetails;
