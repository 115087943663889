// Hooks & Utilities
import {
  useCareerPagesGetGraphics,
  useCareerPagesUpdateGraphics,
} from "../../api/CareerPages/CareerPages";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { CareerPagesGraphicsFormStateFields } from "./interfaces";
import { CareerPageGraphicsUpdateRequestFields } from "../../api/CareerPages/interfaces";

// Components
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import FormUpload from "../../components/Form/FormUpload";
import Button from "../../components/Button/Button";
import ContentHeader from "../../components/Content/ContentHeader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";
import Loader from "../../components/Loader/Loader";

const CareerPagesGraphics = () => {
  const errorReporting = useErrorReporting();

  /*==============================
    FETCH CAREER PAGES GRAPHICS
  ===============================*/
  const { data, isLoading, isError } = useCareerPagesGetGraphics();

  /*=================================
    UPDATE THE CAREER PAGE GRAPHICS
  ==================================*/
  const updateCareerPageGraphics = useCareerPagesUpdateGraphics();

  const handleCareerPageDetailsUpdate = async (values: CareerPagesGraphicsFormStateFields) => {
    // Prevent sending the API request if still loading the graphics, of if there's an error
    if (isLoading || isError) return;

    try {
      /*=====================================
        CONSTRUCT THE FINAL REQUEST PAYLOAD
      =======================================*/
      const REQUEST_PAYLOAD: CareerPageGraphicsUpdateRequestFields = {
        ...values,

        // Only include the images if they have a valid value
        ...(values.company_logo && { company_logo: values.company_logo }),
        ...(values.banner_image && { banner_image: values.banner_image }),
        ...(values.full_graphic_image && { full_graphic_image: values.full_graphic_image }),
        ...(values.apply_image && { apply_image: values.apply_image }),
      };

      await updateCareerPageGraphics.mutateAsync(REQUEST_PAYLOAD);
    } catch (error) {
      errorReporting("Failed updating Career Page Graphics", error, { ...values });
    }
  };

  return (
    <div className="container py--25">
      <Formik
        initialValues={{
          company_logo: null,
          apply_image: null,
          banner_image: null,
          full_graphic_image: null,
        }}
        onSubmit={handleCareerPageDetailsUpdate}
      >
        {({ errors }) => (
          <Form>
            <ContentHeader title="Career Pages - Graphics" modifierClass="mb--20">
              <>
                <Link to="/career/pages/" className="btn btn--fluid btn--secondary mr--20">
                  Go Back
                </Link>

                <PermissionCheckComponentWrapper permissions={["career_page_edit"]}>
                  <Button
                    modifierClass="btn--fluid btn--primary--light"
                    isLoading={updateCareerPageGraphics.isLoading}
                    isDisabled={
                      isLoading ||
                      isError ||
                      Object.entries(errors).length > 0 ||
                      updateCareerPageGraphics.isLoading
                    }
                  >
                    Save Graphics
                  </Button>
                </PermissionCheckComponentWrapper>
              </>
            </ContentHeader>

            {isLoading ? (
              <Loader size="page" modifierWrapper="loader--page" />
            ) : (
              <div className="row">
                {/* COMPANY LOGO */}
                <div className="col-12 col-lg-6 mb--30">
                  <h5 className="fw--semibold mb--5">
                    Company Logo
                    <em className="txt--sm fw--medium txt--light-gray ml--5">
                      (This helps applicants identify your company)
                    </em>
                  </h5>

                  <Field
                    component={FormUpload}
                    id="company_logo"
                    name="company_logo"
                    buttonText="Choose Image"
                    placeholder="Upload Company Logo"
                    accept=".png, .jpg, .jpeg, .gif"
                    thumbnailPreview={data?.company_logo ?? ""}
                    hasConfirmationForClearAction
                    info={
                      <>
                        <span className="input-upload__info--info">
                          Accepted file types: gif, jpg, jpeg, png.{" "}
                        </span>
                        <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
                      </>
                    }
                  />
                </div>

                {/* APPLY IMAGE */}
                <div className="col-12 col-lg-6 mb--30">
                  <h5 className="fw--semibold mb--5">Apply Image</h5>

                  <Field
                    component={FormUpload}
                    id="apply_image"
                    name="apply_image"
                    buttonText="Choose Image"
                    placeholder="Upload Apply Image"
                    accept=".png, .jpg, .jpeg, .gif"
                    thumbnailPreview={data?.apply_image ?? ""}
                    hasConfirmationForClearAction
                    info={
                      <>
                        <span className="input-upload__info--info">
                          Accepted file types: gif, jpg, jpeg, png.{" "}
                        </span>
                        Recommended image width: 1080px.
                        <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
                      </>
                    }
                  />
                </div>

                {/* BANNER IMAGE */}
                <div className="col-12 col-lg-6 mb--30">
                  <h5 className="fw--semibold mb--5">Banner Image</h5>

                  <Field
                    component={FormUpload}
                    id="banner_image"
                    name="banner_image"
                    buttonText="Choose Image"
                    placeholder="Upload Banner Image"
                    accept=".png, .jpg, .jpeg, .gif"
                    thumbnailPreview={data?.banner_image ?? ""}
                    hasConfirmationForClearAction
                    info={
                      <>
                        <span className="input-upload__info--info">
                          Accepted file types: gif, jpg, jpeg, png.{" "}
                        </span>
                        Recommended image width: 1080px.
                        <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
                      </>
                    }
                  />
                </div>

                {/* FULL GRAPHIC */}
                <div className="col-12 col-lg-6">
                  <h5 className="fw--semibold mb--5">Full Graphic Image</h5>

                  <Field
                    component={FormUpload}
                    id="full_graphic_image"
                    name="full_graphic_image"
                    buttonText="Choose Image"
                    placeholder="Upload Full Graphic Image"
                    accept=".png, .jpg, .jpeg, .gif"
                    thumbnailPreview={data?.full_graphic_image ?? ""}
                    hasConfirmationForClearAction
                    info={
                      <>
                        <span className="input-upload__info--info">
                          Accepted file types: gif, jpg, jpeg, png.{" "}
                        </span>
                        Recommended image width: 1080px.
                        <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
                      </>
                    }
                  />
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CareerPagesGraphics;
