// Utilities
import { format } from "date-fns";

// Components
import ChatAvatar from "../ChatAvatar";
import { motion } from "framer-motion";

// Interfaces
import { ChatConversationMessageProps } from "./interfaces";

// Assets
import {
  MdDoneAll as ChatMessageDeliveredIcon,
  MdWarning as ChatMessageFailedIcon,
} from "react-icons/md";

const ChatConversationMessage = ({
  name,
  content,
  status,
  image_url = null,
  is_blocked = 0,
  direction = "out",
  time,
}: ChatConversationMessageProps) => {
  return (
    <motion.div
      className={`chat-message chat-message--${direction}`}
      initial={{ opacity: 0, y: "10px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, type: "spring" }}
    >
      {direction === "in" && (
        <ChatAvatar
          name={name}
          image_url={image_url}
          size="sm"
          status={is_blocked ? "blocked" : "active"}
        />
      )}

      <div className="chat-message__content">
        <h6 className="chat-message__author">{name}</h6>

        <div className="chat-message__bubble">
          <p className="chat-message__bubble__text">{content}</p>
          <p className="chat-message__bubble__time">{format(new Date(time * 1000), "hh:mmaaa")}</p>
        </div>

        {status === "message-delivered" ? (
          <div className="chat-message__status chat-message__status__delivered">
            <ChatMessageDeliveredIcon />
            <h6>Message delivered.</h6>
          </div>
        ) : null}

        {status === "message-failed" ? (
          <div className="chat-message__status chat-message__status__failed">
            <div className="chat-message_status__failed__icon">
              <ChatMessageFailedIcon />
            </div>
            <h6>Message not sent.</h6>
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ChatConversationMessage;
