// Interfaces
import {
  OnlineApplicationDynamicFieldsWidths,
  OnlineApplicationDynamicFieldsColumns,
} from "../interfaces";

/**
 * Combinations of field sizes, corresponding to
 * specific column size, based on received `width` value.
 */
const FORM_FIELD_WIDTH_TO_COLUMN_MAPPINGS: Record<
  OnlineApplicationDynamicFieldsWidths,
  OnlineApplicationDynamicFieldsColumns
> = {
  25: "col-lg-3",
  33: "col-lg-4",
  50: "col-lg-6",
  100: "col-12",
};

/**
 *
 * Map the received field's `width` value to a grid column.
 * The values map as:
 *
 * - width of `25` maps to `col-lg-3`
 * - width of `33` maps to `col-lg-4`
 * - width of `50` maps to `col-lg-6`
 * - and width of `100` maps to `col-12`
 *
 * @param fieldWidth The expected width of the field.
 *
 * @returns The class name corresponding to the grid column to be used.
 *
 */
export function handleFormFieldSizes(
  fieldWidth: OnlineApplicationDynamicFieldsWidths,
): OnlineApplicationDynamicFieldsColumns {
  // If no "width" value is received, default to full width
  if (!fieldWidth) return "col-12";

  return FORM_FIELD_WIDTH_TO_COLUMN_MAPPINGS[fieldWidth];
}
