import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enPagesI18n from "./locales/en/pages";
import esPagesI18n from "./locales/es/pages";

const translationResources = {
  en: { ...enPagesI18n },
  es: { ...esPagesI18n },
};

i18n.use(initReactI18next).init({
  resources: translationResources,
  fallbackLng: "en",
  lng: "en",
  supportedLngs: ["en", "es"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
