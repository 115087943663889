// Hooks
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useJobAdsGetAdDetails, useJobAdsUpdateDetails } from "../../api/JobAds/JobAds";
import { useJobAdsGetSpecificAdLibrary } from "../../api/JobAds/JobAdsLibraries";
import { useBackNavigation } from "../../hooks/useBackNavigation";
import useErrorReporting from "../../hooks/useErrorReporting";
import fetchHandler from "../../api/fetchHandler";

// Components
import { Form, Formik, FormikProps } from "formik";
import Button from "../../components/Button/Button";
import JobAdAddress from "../../components/JobAds/Edit/JobAdAddress";
import JobAdEditor from "../../components/JobAds/Edit/JobAdEditor";
import JobAdFormSettings from "../../components/JobAds/Edit/JobAdFormSettings";
import JobAdInformation from "../../components/JobAds/Edit/JobAdInformation";
import JobAdLibrary from "../../components/JobAds/Edit/JobAdLibrary";
import JobAdHeader from "../../components/JobAds/JobAdHeader";
import JobAdsEditSkeleton from "./Skeleton/JobAdsEditSkeleton";
import Loader from "../../components/Loader/Loader";
import Banner from "../../components/Banner/Banner";

// Assets
import { MdWarningAmber as WarningIcon } from "react-icons/md";

// Interfaces
import { JobAdUpdateRequestFields } from "../../api/JobAds/interfaces";

// Validation schema
import { JOB_ADS_EDIT_VALIDATION_SCHEMA } from "../../schemas/JobAdSchemas";

const JobAdsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();
  const handleNavigateBack = useBackNavigation();

  /*========================
    GET JOB AD DETAILS
  ==========================*/
  const { data, isLoading } = useJobAdsGetAdDetails(id);

  /*========================
    FORM FIELDS
  ==========================*/
  const formikInnerRef = useRef<FormikProps<JobAdUpdateRequestFields>>(null);

  const [formValues, setFormValues] = useState<JobAdUpdateRequestFields>({
    form_id: null,
    sbca_type_id: null,
    email_alerts: "",
    address_country: "",
    address_street: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    job_title: "",
    headline_1: "",
    headline_2: "",
    headline_3: "",
    job_questions: "",
    job_intro: "",
    job_description: "",
    job_benefits: "",
    job_responsibilities: "",
    job_requirements: "",
    job_terms: "",
    job_type: "Full Time",
    content: "",
    reason: "",
  });

  // Update the form field values with the values received from the API response
  useEffect(() => {
    if (!data || !Object.entries(data).length || isLoading) return;

    setFormValues({
      // Use the form ID value received from the API, or default to ID 2 which represents "Short Application Form"
      form_id: data.ad_settings?.form_id || 2,

      sbca_type_id: data.ad_settings?.sbca_id,
      email_alerts: data.job_information?.email_alerts || "",
      address_country: data.job_information?.address.country || "US",
      address_street: data.job_information?.address.street,
      address_state: data.job_information?.address.state || "",
      address_city: data.job_information?.address.city || "",
      address_zip: data.job_information?.address.zip || "",
      job_title: data.job_information?.job_title,
      headline_1: data.job_information?.headline_1 || "",
      headline_2: data.job_information?.headline_2 || "",
      headline_3: data.job_information?.headline_3 || "",
      job_questions: data.job_information?.job_questions || "",
      job_intro: data.job_information?.job_intro || "",
      job_description: data.job_information?.job_description || "",
      job_benefits: data.job_information?.job_benefits || "",
      job_responsibilities: data.job_information?.job_responsibilities || "",
      job_requirements: data.job_information?.job_requirements || "",
      job_terms: data.job_information?.job_terms || "",
      job_type: data.job_information?.job_type || "Full Time",
      content: data.job_information?.content || "",
      reason: "",
    });
  }, [data]);

  /*========================
    CHECK SELECTED CLIENT
  ==========================*/
  const [selectedClientID, setSelectedClientID] = useState<number | undefined>(data?.company.id);

  /*===============================
    LOAD JOB AD LIBRARY
  ================================*/
  const [selectedJobAdLibraryID, setSelectedJobAdLibraryID] = useState<number | null>(null);

  const {
    data: jobAdLibraryData,
    isFetching: jobAdLibraryRefetching,
    refetch: fetchJobAdLibrary,
  } = useJobAdsGetSpecificAdLibrary(selectedJobAdLibraryID);

  // Once we have a valid job ad library ID selected,
  // trigger API request to obtain the data for that Job Ad Library
  useEffect(() => {
    if (!selectedJobAdLibraryID) return;

    handleFetchJobAdLibrary();
  }, [selectedJobAdLibraryID]);

  // Trigger manual fetch of the targeted job ad library.
  // After the request goes trough, we reset the selected library ID,
  // so the function can be called consecutively if the user
  // wants to "reload" the same ad library
  const handleFetchJobAdLibrary = async () => {
    await fetchJobAdLibrary();
    setSelectedJobAdLibraryID(null);
  };

  // Once we have the targeted Job Ad Library's data,
  // use it to reinitailize the form
  useEffect(() => {
    if (!jobAdLibraryData || jobAdLibraryRefetching) return;

    setFormValues({
      ...formValues,
      job_title: jobAdLibraryData?.job_title || "",
      headline_1: jobAdLibraryData?.headline_1 || "",
      headline_2: jobAdLibraryData?.headline_2 || "",
      headline_3: jobAdLibraryData?.headline_3 || "",
      job_questions: jobAdLibraryData?.job_questions || "",
      job_intro: jobAdLibraryData?.job_intro || "",
      job_description: jobAdLibraryData?.job_description || "",
      job_benefits: jobAdLibraryData?.job_benefits || "",
      job_responsibilities: jobAdLibraryData?.job_responsibilities || "",
      job_requirements: jobAdLibraryData?.job_requirements || "",
      job_terms: jobAdLibraryData?.job_terms || "",
      content: jobAdLibraryData?.content || "",
    });
  }, [jobAdLibraryData]);

  // Re-validate the form anytime the initially received form values
  // (either from the job ad itself or from a job ad template) are updated
  // We intentionally wait 50ms before triggering the re-validation due to
  // state updates happening in the background which trigger re-validation on state that is not fully updated
  // TODO: Find better implementation for this.
  useEffect(() => {
    if (formikInnerRef.current) {
      setTimeout(() => {
        formikInnerRef.current?.validateForm();
      }, 50);
    }
  }, [formValues]);

  /*========================
    UPDATE JOB AD
  =========================*/
  const updateJobAd = useJobAdsUpdateDetails(id);

  const handleUpdateJobAd = async (values: JobAdUpdateRequestFields) => {
    try {
      await updateJobAd.mutateAsync(values);

      // Redirect the user to the details page
      setTimeout(() => {
        navigate(`/job-ads/ads/${id}/`);
      }, 5000);
    } catch (error) {
      errorReporting("Failed updating (revising) ad details.", error, { ad_id: id, ...values });
    }
  };

  /*===========================
    PING SERVER ABOUT AD EDIT
  =============================*/
  useEffect(() => {
    // Ping the server on page laod
    handlePingServer();

    // Ping the server on every 20 seconds
    const interval = setInterval(() => handlePingServer(), 1000 * 20);
    return () => clearInterval(interval);
  }, []);

  const handlePingServer = () => fetchHandler("POST", `admin/ads/${id}/track`);

  return (
    <div className="job-ads-edit">
      <JobAdHeader />

      {isLoading ? (
        <JobAdsEditSkeleton />
      ) : !data ? 
        <div className="d-flex flex-column align-items-center justify-content-center wrapper--height">
          <h2 className="txt--center fw--semibold mb--30">No Job Ad data available.</h2>

          <div>
            <Button
              modifierClass="btn--fluid btn--primary--light"
              onClick={() => handleNavigateBack(`/job-ads/ads/${id}/`)}
            >
              Go Back
            </Button>

            <Button
              modifierClass="btn--fluid btn--primary ml--10"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </div>
        </div>
      : (
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={JOB_ADS_EDIT_VALIDATION_SCHEMA}
          onSubmit={handleUpdateJobAd}
          innerRef={formikInnerRef}
        >
          {({ values, errors, resetForm, setFieldValue, setFieldTouched }) => (
            <Form>
              {jobAdLibraryRefetching ? (
                <div className="page-reload-overlay page-reload-overlay--bg-white">
                  <Loader size="page" />
                </div>
              ) : null}
              <div className="job-ads-details__header">
                <div className="container">
                  <h3 className="fw--semibold">
                    <strong>Edit Ad:</strong>

                    <span className="txt--gray--darkest ml--10 break-word">
                      {data?.title || "N/A"} at{" "}
                    </span>
                    <Link to={`/job-ads/clients/${data?.company.slug}/`}>{data?.company.name}</Link>
                  </h3>

                  <div className="d-flex align-items-center">
                    <Link
                      to={`/job-ads/ads/${id}`}
                      type="button"
                      className="btn btn--fluid btn--danger"
                    >
                      Cancel
                    </Link>
                    <Button
                      modifierClass="btn--fluid btn--primary ml--10 flex-shrink-0"
                      isLoading={updateJobAd.isLoading}
                      isDisabled={updateJobAd.isLoading || !!Object.entries(errors).length}
                    >
                      Save Ad
                    </Button>
                  </div>
                </div>
              </div>

              <div className="container py--25">
                {selectedClientID !== data?.company.id ? (
                  <Banner
                    text={
                      <>
                        <strong>Notice</strong>: You are selecting an ad from another client. It is
                        important to proofread the ad before finalizing to ensure there are no
                        references to the wrong client details, such as name, address, contact
                        person, etc.
                      </>
                    }
                    icon={<WarningIcon />}
                    modifierClass="banner--danger mb--50"
                  />
                ) : null}

                <div className="d-flex justify-content-between align-items-center mb--20">
                  <div className="job-ads-details__codes">
                    <p>Job Code: {data?.job_code || "N/A"}</p>
                    <p>
                      Salesforce Order:{" "}
                      {data?.salesforce.link ? (
                        <a href={data?.salesforce.link} target="_blank">
                          {data?.salesforce.number ?? "N/A"}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </div>
                </div>

                <div className="row">
                  {/* FORM SETTINGS CARD */}
                  <JobAdFormSettings
                    formValues={values}
                    form_identifier={data.form_identifier}
                    setFieldValue={setFieldValue}
                  />

                  {/* AD LIBRARY CARD */}
                  <JobAdLibrary
                    jobAdClientID={data.company.id}
                    salesforceComments={data.job_information.salesforce.comments}
                    handleFetchJobAdLibrary={(libraryID: number) => {
                      resetForm();
                      setSelectedJobAdLibraryID(libraryID);
                    }}
                    handleSelectedClient={(clientID: number) => setSelectedClientID(clientID)}
                  />
                </div>

                {/* JOB ADDRESS */}
                <JobAdAddress
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />

                {/* JOB INFORMATION */}
                <JobAdInformation values={values} setFieldValue={setFieldValue} />

                {/* JOB AD EDITOR */}
                {errors.content ? <p className="input__error">{errors.content}</p> : null}
                <JobAdEditor
                  form_identifier={data.form_identifier}
                  values={values}
                  images={data.images}
                  type={data.type}
                  setFieldValue={setFieldValue}
                />
                {errors.content ? <p className="input__error">{errors.content}</p> : null}

                <div className="d-flex justify-content-end">
                  <Link
                    to={`/job-ads/ads/${id}`}
                    type="button"
                    className="btn btn--fluid btn--danger"
                  >
                    Cancel
                  </Link>
                  <Button
                    modifierClass="btn--fluid btn--primary ml--10 flex-shrink-0"
                    isLoading={updateJobAd.isLoading}
                    isDisabled={updateJobAd.isLoading || !!Object.entries(errors).length}
                  >
                    Save Ad
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default JobAdsEdit;
