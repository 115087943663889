/**
 * Capitalizes the first letter of a given string
 * @param initialString The initial string value as received from external source (e.g. API)
 * @param splitters An array of strings representing splitters used to split the initial value (e.g. empty spaces, '-', ',', etc.)
 * @returns A string firstly split into the provided delimiters (splitters) and capitalized each chunk.
 */
export function handleStringCapitalization(
  initialString: string,
  splitters: string[] = [""],
): string {
  // Exit function and return empty string if there's no string to be capitalized
  if (!initialString) return "";

  let capitalized = initialString;

  // For each provided splitter, capitalize the contained substring
  splitters.forEach(splitter => {
    capitalized = capitalized
      .split(splitter)
      .map(subString => {
        // If substring is empty after splitting, skip the chunk and do not include it back in the list of items to be joined
        if (!subString) return;
        return subString[0].toUpperCase() + subString.slice(1);
      })
      .join(splitter);
  });

  return capitalized;
}
