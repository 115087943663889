import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

/**
 * Custom hook used for extracting window's width after resize or on page load.
 *
 * @param delay Delay used for the debounce. Defaults to `700ms`
 * @param defaultWidth An optional argument that is used for pre-defining the
 * starting window width size. Defaults to `outerWidth` of the current window.
 *
 * @returns The outer's window width number value.
 *
 */
function useWindowResize(delay = 700, defaultWidth = window.outerWidth) {
  const [windowWidth, setWindowWidth] = useState(defaultWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.outerWidth);
    const debouncedHandleResize = debounce(handleResize, delay);

    window.addEventListener("resize", debouncedHandleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [delay]);

  return [windowWidth];
}

export default useWindowResize;
