import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";
import { BiRightArrowCircle as RightIcon } from "react-icons/bi";

// Components
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";

const OnlineApplicationThankYou = () => {
  const { identifier } = useParams();
  const [searchParams] = useExtractSearchParameters();

  // Append "Appcast" application tracking script if such URL parameter is received
  useEffect(() => {
    if (searchParams?.tracker === "appcast") {
      const applyCompletePixel = document.createElement("script");
      applyCompletePixel.src = "https://click.appcast.io/pixels/generic3-19004.js?ent=147";
      applyCompletePixel.type = "text/javascript";
      document.body.appendChild(applyCompletePixel);

      // Clean up - remove the script on unmount
      return () => {
        document.body.removeChild(applyCompletePixel);
      };
    }
  }, []);

  return (
    <div className="online-application-thank-you">
      <div className="container">
        <Link to="/login/">
          <img
            src={FCHLogo}
            className="online-application-thank-you__logo"
            title="FirstChoice Hiring logo"
          />
        </Link>

        <h3 className="online-application-thank-you__header">You're Almost Done</h3>
        <br />
        <p className="online-application-thank-you__paragraph">
          In order to be considered for this opportunity, we need you to complete the{" "}
          <Link to={`/online-application/assessment/${identifier}/`}>SBCA Questionnaire</Link>,
          which is part of the application process.
        </p>
        <p className="online-application-thank-you__paragraph">
          Please click on the button below to continue.
        </p>

        <Link
          to={`/online-application/assessment/${identifier}/`}
          target="_blank"
          className="btn btn--fluid btn--success"
        >
          SBCA Questionnaire
          <RightIcon className="btn--next-page" />
        </Link>

        <p className="online-application-thank-you__paragraph mb--100">
          Please check your spam folder, or add no-reply@firstchoicehiring.com to your address book
          to ensure you receive valuable communications from us in the future.
        </p>

        {/* TRACKING PIXELS */}
        {searchParams?.tracker === "talent" ? (
          <img
            src="https://www.talent.com/tracker/img-pixel.php?tracker=firstchoice-ppc"
            data-testid="tracker:talent"
          />
        ) : null}

        {searchParams?.tracker === "zip_recruiter" ? (
          <img
            src="https://track.ziprecruiter.com/conversion?enc_account_id=35533936"
            width="1"
            height="1"
            data-testid="tracker:zip_recruiter"
          />
        ) : null}

        <OnlineApplicationFooter />
      </div>
    </div>
  );
};

export default OnlineApplicationThankYou;
