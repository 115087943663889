// Components
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import TableSkeletonPlaceholder from "../../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../../components/Table/Table";
import PermissionCheckComponentWrapper from "../../../components/Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { UsersResponseFields } from "../../../api/Users/interfaces";
import { UsersTargetedUserDetails, UsersListMappedFields } from "./interfaces";

// Utilities & Hooks
import { matchSorter } from "match-sorter";
import { createColumnHelper } from "@tanstack/react-table";
import { format, parseJSON } from "date-fns";
import { useMemo, useState } from "react";
import { useUsersRemoveSpecific, useUsersGet } from "../../../api/Users/Users";
import { useAuth } from "../../../providers/auth-context";
import { useInvitationDelete, useInvitationResend } from "../../../api/Invitation/Invitation";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

const UsersTable = ({ searchedUsers }: { searchedUsers: string }) => {
  const errorReporting = useErrorReporting();

  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<UsersListMappedFields>();
  const USERS_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Name</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      size: 100,
    }),
    COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 50,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("email", {
      header: () => <span>E-mail</span>,
      cell: data => <span title={data.getValue()}>{data.getValue() || "N/A"}</span>,
      size: 90,
    }),
    COLUMN_HELPER.accessor("group", {
      header: () => <span>User Group</span>,
      cell: data => <span title={data.getValue()}>{data.getValue() || "N/A"}</span>,
      size: 30,
    }),
    COLUMN_HELPER.accessor("last_login", {
      header: () => <span>Last Login</span>,
      cell: data => (
        <span>
          {data.getValue()
            ? format(parseJSON(data.getValue().toString()), "dd MMM yyyy HH:mm")
            : "N/A"}
        </span>
      ),
      size: 50,
    }),
    COLUMN_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
      size: 30,
    }),
    COLUMN_HELPER.accessor("id", {
      header: () => <span>Action</span>,
      enableSorting: false,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <div className="table__buttons">
          {data.row.original.status === "active" ? (
            <>
              <PermissionCheckComponentWrapper permissions={["sms_read_company_messages"]}>
                <Link
                  to={`/chats/messages/${data.getValue()}/`}
                  className="btn btn--fluid btn--fluid--sm btn--primary--cyan mr--5"
                >
                  View Messages
                </Link>
              </PermissionCheckComponentWrapper>

              {/* If the user does not belong to any group (Account Manager or Super Admin, do not show buttons) */}
              {data.row.original.group ? (
                <>
                  <PermissionCheckComponentWrapper permissions={["applicant_dashboard_users_edit"]}>
                    <Link
                      className="btn btn--fluid btn--fluid--sm btn--primary--light mr--5"
                      to={`/account/users/${data.getValue()}/edit/`}
                    >
                      Edit
                    </Link>
                  </PermissionCheckComponentWrapper>

                  <PermissionCheckComponentWrapper
                    permissions={["applicant_dashboard_users_delete"]}
                  >
                    <Button
                      modifierClass="btn--fluid btn--fluid--sm btn--danger"
                      onClick={() => {
                        handleOpenActionModal(
                          data.getValue(),
                          data.row.original.name,
                          "delete-user",
                        );
                      }}
                    >
                      Remove
                    </Button>
                  </PermissionCheckComponentWrapper>
                </>
              ) : null}
            </>
          ) : (
            <>
              <Button
                modifierClass="btn--fluid btn--fluid--sm btn--primary mr--10"
                onClick={() => handleInvitationResend(data.getValue())}
                isLoading={resendInvitationID === data.getValue() && invitationResend.isLoading}
                isDisabled={resendInvitationID === data.getValue() && invitationResend.isLoading}
              >
                Resend Invitation
              </Button>

              <PermissionCheckComponentWrapper permissions={["applicant_dashboard_users_delete"]}>
                <Button
                  modifierClass="btn--fluid btn--fluid--sm btn--danger"
                  onClick={() => {
                    handleOpenActionModal(
                      data.getValue(),
                      data.row.original.name,
                      "delete-invitation",
                    );
                  }}
                >
                  Delete
                </Button>
              </PermissionCheckComponentWrapper>
            </>
          )}
        </div>
      ),
    }),
  ];

  /*=========================
    GET ALL USERS FROM
    THE CURRENTLY ACTIVE COMPANY
  ==========================*/
  const { user } = useAuth();

  // Read the currently active company value from the authentication context
  // or default to a specified value if it doesn't exists
  const { data, isLoading, isFetching } = useUsersGet();

  const users = useMemo(() => {
    // Exit function and return default value if there's no data
    if (!data || !data.length || isLoading) return [];

    let mappedUsers: UsersListMappedFields[] = data.map((user: UsersResponseFields) => {
      return {
        id: user.id,
        name: handleFullnameCombination(user),
        title: user.title,
        email: user.email || "N/A",
        last_login: user.last_login || 0,
        group: user.group ?? "",
        status: user.status,
      };
    });

    // When user searches, filter out the list of users displayed in the table
    if (searchedUsers) {
      mappedUsers = matchSorter(mappedUsers, searchedUsers, {
        keys: ["name", "email", { key: "group", threshold: matchSorter.rankings.EQUAL }],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    return mappedUsers;
  }, [data, searchedUsers]);

  /*=========================
    MODALS
  ==========================*/
  const [targetedUser, setTargetedUser] = useState<UsersTargetedUserDetails | null>(null);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState<boolean>(false);
  const [isInvitationDeleteModalOpen, setIsInvitationDeleteModalOpen] = useState<boolean>(false);

  const handleOpenActionModal = (
    userID: number,
    userName: string,
    modal: "delete-user" | "delete-invitation",
  ) => {
    setTargetedUser({ id: userID, name: userName });

    if (modal === "delete-user") {
      setIsUserDeleteModalOpen(true);
    } else {
      setIsInvitationDeleteModalOpen(true);
    }
  };

  const handleResetModalState = () => {
    setTargetedUser(null);
    setIsUserDeleteModalOpen(false);
    setIsInvitationDeleteModalOpen(false);
  };

  /*====================================
    DELETE THE TARGETED USER
    FROM THE CURRENTLY ACTIVE COMPANY
  =====================================*/
  const userRemove = useUsersRemoveSpecific();

  const handleUserRemove = async () => {
    // Exit the function if there's no targeted user
    // or a valid active company's "slug" value available yet
    if (targetedUser === null || !user.active_company.slug) return;

    try {
      // Close the modal and reset the targeted user's data
      handleResetModalState();

      await userRemove.mutateAsync(targetedUser.id);
    } catch (error) {
      errorReporting("Failed deleting user", error, {
        user_id: targetedUser.id,
        company_slug: user.active_company.slug,
      });
    }
  };

  /*======================================
    USER INVITATIONS

    Actions can be triggered only
    if the user is in a "pending" state
  ======================================*/
  const [resendInvitationID, setResendInvitationID] = useState<number | null>(null);

  const invitationResend = useInvitationResend();
  const invitationDelete = useInvitationDelete();

  const handleInvitationResend = async (userID: number) => {
    // ID to be used to control the loading & disabled state of the
    // "Resend Invitation" button states, so only the button which we clicked will be affected
    setResendInvitationID(userID);

    try {
      await invitationResend.mutateAsync(userID);

      // Reset the selected invitation ID
      setResendInvitationID(null);

      // Close the modal after the invitaion was successfully deleted
      handleResetModalState();
    } catch (error) {
      errorReporting("Failed resending user invitation", error, { user_id: userID });
    }
  };

  const handleInvitationDelete = async () => {
    // Exit the function if there's no targeted user
    // or a valid active company's "slug" value available yet
    if (targetedUser === null || !user.active_company.slug) return;

    try {
      // Close the modal and reset the targeted user's data
      handleResetModalState();

      await invitationDelete.mutateAsync(targetedUser.id);
    } catch (error) {
      errorReporting("Failed deleting user invitation", error, { user_id: targetedUser.id });
    }
  };

  return (
    <>
      {isLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper" />
      ) : (
        <Table
          data={users}
          columns={USERS_TABLE_COLUMNS}
          isRefetching={isFetching}
          paginationPageSize={10}
        />
      )}

      <AnimatePresence>
        {isUserDeleteModalOpen && (
          <Modal
            title="Delete User?"
            text={
              <>
                This action will delete <strong className="txt--black">{targetedUser?.name}</strong>{" "}
                from the company. This action is{" "}
                <strong className="txt--black">irreversible</strong>. Do you want to continue?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleResetModalState}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleResetModalState}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--secondary"
                onClick={handleUserRemove}
                isDisabled={userRemove.isLoading}
                isLoading={userRemove.isLoading}
              >
                Yes, Remove User
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isInvitationDeleteModalOpen && (
          <Modal
            title="Delete Invitation?"
            text={
              <>
                This action will delete the invitation that was sent to{" "}
                <strong className="txt--black">{targetedUser?.name}</strong> .This action is{" "}
                <strong className="txt--black">irreversible</strong>. Do you want to continue?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleResetModalState}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleResetModalState}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--secondary"
                onClick={handleInvitationDelete}
                isDisabled={invitationDelete.isLoading}
                isLoading={invitationDelete.isLoading}
              >
                Yes, Delete Invitation
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default UsersTable;
