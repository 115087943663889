import { DropdownItem } from "../../../../Dropdown/interfaces";

/*================================
  BASE PAY RATE
=================================*/
export const CAREER_BUILDER_DROPDOWN_BASE_PAY_RATE: DropdownItem[] = [
  { text: "Select Base Pay Rate", value: "" },
  { text: "Yearly", value: "yearly" },
  { text: "Hourly", value: "hourly" },
];

/*================================
  MANAGING OTHERS
=================================*/
export const CAREER_BUILDER_DROPDOWN_MANAGING_OTHERS: DropdownItem[] = [
  { text: "Not Specified", value: "" },
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

/*================================
  TRAVEL REQUIRED
=================================*/
export const CAREER_BUILDER_DROPDOWN_TRAVEL_REQUIRED: DropdownItem[] = [
  { text: "Not Specified", value: "" },
  { text: "None", value: "none" },
  { text: "Negligible", value: "negligible" },
  { text: "25%", value: "25%" },
  { text: "50%", value: "50%" },
  { text: "Road Warrior", value: "road_warrior" },
];

/*================================
  RELOCATION COVERED
=================================*/
export const CAREER_BUILDER_DROPDOWN_RELOCATION_COVERED: DropdownItem[] = [
  { text: "Not Specified", value: "" },
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

/*================================
  JOB CATEGORIES
=================================*/
export const CAREER_BUILDER_DROPDOWN_JOB_CATEGORIES: DropdownItem[] = [
  { value: 1, text: "Accounting" },
  { value: 2, text: "Admin - Clerical" },
  { value: 54, text: "Automotive" },
  { value: 38, text: "Banking" },
  { value: 53, text: "Biotech" },
  { value: 19, text: "Business Development" },
  { value: 59, text: "Business Opportunity" },
  { value: 43, text: "Construction" },
  { value: 20, text: "Consultant" },
  { value: 3, text: "Customer Service" },
  { value: 21, text: "Design" },
  { value: 27, text: "Distribution - Shipping" },
  { value: 31, text: "Education" },
  { value: 4, text: "Engineering" },
  { value: 22, text: "Entry Level" },
  { value: 18, text: "Executive" },
  { value: 17, text: "Facilities" },
  { value: 5, text: "Finance" },
  { value: 60, text: "Franchise" },
  { value: 6, text: "General Business" },
  { value: 51, text: "General Labor" },
  { value: 46, text: "Government" },
  { value: 70, text: "Government - Federal" },
  { value: 55, text: "Grocery" },
  { value: 23, text: "Health Care" },
  { value: 40, text: "Hospitality - Hotel" },
  { value: 7, text: "Human Resources" },
  { value: 8, text: "Information Technology" },
  { value: 56, text: "Installation - Maint - Repair" },
  { value: 34, text: "Insurance" },
  { value: 15, text: "Inventory" },
  { value: 30, text: "Legal" },
  { value: 41, text: "Legal Admin" },
  { value: 37, text: "Management" },
  { value: 29, text: "Manufacturing" },
  { value: 9, text: "Marketing" },
  { value: 47, text: "Media - Journalism - Newspaper" },
  { value: 58, text: "Nonprofit - Social Services" },
  { value: 50, text: "Nurse" },
  { value: 10, text: "Other" },
  { value: 49, text: "Pharmaceutical" },
  { value: 24, text: "Professional Services" },
  { value: 16, text: "Purchasing - Procurement" },
  { value: 25, text: "QA - Quality Control" },
  { value: 57, text: "Real Estate" },
  { value: 26, text: "Research" },
  { value: 35, text: "Restaurant - Food Service" },
  { value: 33, text: "Retail" },
  { value: 11, text: "Sales" },
  { value: 12, text: "Science" },
  { value: 13, text: "Skilled Labor - Trades" },
  { value: 28, text: "Strategy - Planning" },
  { value: 14, text: "Supply Chain" },
  { value: 48, text: "Telecommunications" },
  { value: 32, text: "Training" },
  { value: 44, text: "Transportation" },
  { value: 69, text: "Veterinary Services" },
  { value: 45, text: "Warehouse" },
];

/*================================
 EDUCATION LEVEL
=================================*/
export const CAREER_BUILDER_DROPDOWN_EDUCATION_LEVEL: DropdownItem[] = [
  { text: "Select an education level", value: "" },
  { value: "DR321", text: "2 Year Degree" },
  { value: "DR32", text: "4 Year Degree" },
  { value: "DR", text: "Doctorate" },
  { value: "DR3", text: "Graduate Degree" },
  { value: "DR3211", text: "High School" },
  { value: "DR3210", text: "None" },
  { value: "DRNS", text: "Not Specified" },
];

/*================================
 EMPLOYMENT TYPE
=================================*/
export const CAREER_BUILDER_DROPDOWN_EMPLOYMENT_TYPE: DropdownItem[] = [
  { text: "Select an employment type", value: "" },
  { value: "JTCH", text: "Contract to Hire" },
  { value: "JTCT", text: "Contractor" },
  { value: "JTFR", text: "Franchises" },
  { value: "JTFT", text: "Full-Time" },
  { value: "JTFP", text: "Full-Time/Part-Time" },
  { value: "JTIN", text: "Intern" },
  { value: "JTPT", text: "Part-Time" },
  { value: "JTPD", text: "Per Diem" },
  { value: "JTSE", text: "Seasonal/Temp" },
];

/*================================
 INDUSTRY TYPE
=================================*/
export const CAREER_BUILDER_DROPDOWN_JOB_INDUSTRY: DropdownItem[] = [
  { value: "IND067", text: "Accounting - Finance" },
  { value: "IND001", text: "Advertising" },
  { value: "IND046", text: "Agriculture" },
  { value: "IND002", text: "Airline - Aviation" },
  { value: "IND004", text: "Architecture - Building" },
  { value: "IND005", text: "Art - Photography - Journalism" },
  { value: "IND006", text: "Automotive - Motor Vehicles - Parts" },
  { value: "IND007", text: "Banking - Financial Services" },
  { value: "IND008", text: "Biotechnology" },
  { value: "IND018", text: "Broadcasting - Radio - TV" },
  { value: "IND009", text: "Building Materials" },
  { value: "IND068", text: "Chemical" },
  { value: "IND012", text: "Computer Hardware" },
  { value: "IND013", text: "Computer Software" },
  { value: "IND010", text: "Construction" },
  { value: "IND014", text: "Consulting" },
  { value: "IND053", text: "Consumer Products" },
  { value: "IND059", text: "Credit - Loan - Collections" },
  { value: "IND064", text: "Defense - Aerospace" },
  { value: "IND015", text: "Education - Teaching - Administration" },
  { value: "IND047", text: "Electronics" },
  { value: "IND016", text: "Employment - Recruiting - Staffing" },
  { value: "IND017", text: "Energy - Utilities - Gas - Electric" },
  { value: "IND054", text: "Entertainment" },
  { value: "IND048", text: "Environmental" },
  { value: "IND069", text: "Exercise - Fitness" },
  { value: "IND003", text: "Fashion - Apparel - Textile" },
  { value: "IND019", text: "Food" },
  { value: "IND066", text: "Funeral - Cemetery" },
  { value: "IND020", text: "Government - Civil Service" },
  { value: "IND021", text: "Healthcare - Health Services" },
  { value: "IND071", text: "Homebuilding" },
  { value: "IND022", text: "Hospitality" },
  { value: "IND050", text: "Hotel - Resort" },
  { value: "IND023", text: "HVAC" },
  { value: "IND052", text: "Import - Export" },
  { value: "IND024", text: "Industrial" },
  { value: "IND025", text: "Insurance" },
  { value: "IND026", text: "Internet - ECommerce" },
  { value: "IND062", text: "Landscaping" },
  { value: "IND027", text: "Law Enforcement" },
  { value: "IND028", text: "Legal" },
  { value: "IND058", text: "Library Science" },
  { value: "IND065", text: "Managed Care" },
  { value: "IND029", text: "Manufacturing" },
  { value: "IND073", text: "Medical Equipment" },
  { value: "IND030", text: "Merchandising" },
  { value: "IND070", text: "Military" },
  { value: "IND031", text: "Mortgage" },
  { value: "IND072", text: "Newspaper" },
  { value: "IND011", text: "Not for Profit - Charitable" },
  { value: "IND032", text: "Office Supplies - Equipment" },
  { value: "IND033", text: "Oil Refining - Petroleum - Drilling" },
  { value: "IND034", text: "Other Great Industries" },
  { value: "IND045", text: "Packaging" },
  { value: "IND035", text: "Pharmaceutical" },
  { value: "IND036", text: "Printing - Publishing" },
  { value: "IND037", text: "Public Relations" },
  { value: "IND038", text: "Real Estate - Property Mgt" },
  { value: "IND040", text: "Recreation" },
  { value: "IND049", text: "Restaurant" },
  { value: "IND039", text: "Retail" },
  { value: "IND061", text: "Sales - Marketing" },
  { value: "IND057", text: "Securities" },
  { value: "IND060", text: "Security" },
  { value: "IND041", text: "Semiconductor" },
  { value: "IND056", text: "Social Services" },
  { value: "IND042", text: "Telecommunications" },
  { value: "IND043", text: "Training" },
  { value: "IND044", text: "Transportation" },
  { value: "IND051", text: "Travel" },
  { value: "IND055", text: "Wireless" },
];

/*================================
  JOB SKINS
=================================*/
export const CAREER_BUILDER_DROPDOWN_JOB_SKIN: DropdownItem[] = [
  { value: "JBHM1HY74Z7HY7760K9M", text: "Aaction Air_SJB_2016/09" },
  { value: "JB8G5D5634BY6WQMW5C0", text: "ABRA AUTO BODY & GLASS_SJB_2016/01" },
  { value: "JBHQ6GM6PXCD2QK27H9P", text: "ACADEMY CHRYSLER DODGE JEEP RAM_SJB_2016/03" },
  { value: "JBHQ3PX74H0LXB4273M0", text: "Acura Columbus OH" },
  { value: "JBD87D76JGMRXK8K8SML", text: "Acura Of Columbus_SJB_2018/12" },
  { value: "JBHP4L062H5RL3YNZGK3", text: "Acura of Peoria_SJB_2018/04" },
  { value: "JB302ZN6SGBS8GZGH5W1", text: "Acura of Seattle_SJB_2018/05" },
  { value: "JB905NK69DF35GVB07XB", text: "ACURA OF SPRINGFIELD_SJB_2015/07" },
  { value: "JBHM1N87690PYG0CKNB4", text: "Advanced Detection Technology_SJB_2015/12" },
  { value: "JB8Q4WX611SZTTWZB06M", text: "Advantage Ford_SJB_2016/08" },
  { value: "JBD66BG78JCL034R3FKF", text: "Al Packer Ford West_SJB_06/2019" },
  { value: "JBD65V5748SQ53LPD4RD", text: "Al Packer’s White Marsh Ford_SJB_2019/02" },
  { value: "JBGX4TL6FR0CC49RQM3Y", text: "ALL BORO_SJB_2018/01" },
  { value: "JBHT10D714XHS0YYCG61", text: "Allan Vigil Ford Lincoln_SJB_2016/06" },
  { value: "JBHR1Q3740BW6CS6GY55", text: "ALLEN TURNER CHEVROLET_SJB_2015/06" },
  { value: "JBHQ22H61TBFH67TRZFQ", text: "ALLEN TURNER HYUNDAI_SJB_2015/04" },
  { value: "JBHQ22H61TBFH67TRZFQ", text: "ALLEN TURNER HYUNDAI_SJB_2015/04" },
  { value: "JBHP8BP5YLQH2N6P21M7", text: "American Restoration_SJB_2018/02" },
  { value: "JBHP8BP5YLQH2N6P21M7", text: "American Restoration_SJB_2018/02" },
  { value: "JB336MV6XDSSPY98YQDN", text: "Anchor Services_SJB_2018/10" },
  { value: "JBHQ8DN704D1532HZ525", text: "ANDERSON FORD MAZDA_SJB_2016/01" },
  { value: "JBHM8C25YP0RZ1MJ0YTG", text: "Anderson Ford_SJB_2017/05" },
  { value: "JBHM8C25YP0RZ1MJ0YTG", text: "Anderson Ford_SJB_2017/05" },
  { value: "JB8Q1Y76KQVSY52GC4JH", text: "Angela Krause Ford Lincoln_SJB_2016/08" },
  { value: "JBHL0GC69960W3Z992HB", text: "APPLE CHEVROLET_SJB_2015/06" },
  { value: "JBHT5X66HJD9GJ3LSMQF", text: "Applied Software_SJB_2014/12" },
  { value: "JBHP09B6BXCD5H0QVHP2", text: "Arlington Acura In Palatine_SJB_2018/01" },
  { value: "JBHP09B6BXCD5H0QVHP2", text: "Arlington Acura In Palatine_SJB_2018/01" },
  { value: "JBHT6R66NLHBX8NCHNQ5", text: "Arlington Heights Ford_SJB_2017/02" },
  { value: "JB8Q2G163D06LLSNJ4S7", text: "Arlington Heights Ford_SJB_2017/02" },
  { value: "JBHP4406LRSVMC8GSWJJ", text: "Arlington Toyota_SJB_2014_12" },
  { value: "JB8J30W785WHG3MRPK9F", text: "Asheboro Automall_SJB_2016/06" },
  { value: "JB8Q10H70RR020TCHBWT", text: "Asheboro Chrysler Dodge Jeep Ram_SJB_2016/12" },
  { value: "JB8Q7M56T3MF3G5JDY4S", text: "Asheboro Honda_SJB_2016/12" },
  { value: "JB903376PYDSSVP85XFM", text: "Asheboro Nissan_SJB_2016/12" },
  { value: "JBHL6D673C7SS5SKVPY1", text: "ASHEVILLE FORD LINCOLN_SJB_2015/11" },
  { value: "JBHT0TS6ZL7B9Q0WPQRG", text: "Athens Chevrolet_SJB_2018/05" },
  { value: "JBHM166713GTYJK21LNC", text: "Athens Ford_SJB_2015-05" },
  { value: "JBHT7Z16MGJPY9Y6XH3D", text: "Atlanta Automotive Group_SJB_2016/05" },
  { value: "JBHN4MM6TBHFYKXJPPG3", text: "Atlanta Classic Cars_SJB_2014/12" },
  { value: "JBHR5FF6B16S1PQ1GSP4", text: "ATLANTA TOYOTA_SJB_2016/03" },
  { value: "JBJH6G86KSJKT67J8CB4", text: "Audi Greenville" },
  { value: "JBCH6JR63RGS2TNRKFYX", text: "Audi of Charlotte_SJB_2018/01" },
  { value: "JBHQ2FQ6N9DCSFM9XV43", text: "Audi of Columbia_SJB_2016/09" },
  { value: "JB902QR62ND4ZXSMDX42", text: "Audi South Atlanta_SJB_2017/12" },
  { value: "JBD71J870FJ1GV3W0WPH", text: "Auto Outlet of PA_SJB_2018/12" },
  { value: "JBHN0GX6C9G3B0TXHV5Q", text: "AUTO PARK HONDA_SJB_2016/04" },
  { value: "JB3083L75783J04RYZ5H", text: "Autobahn Fort Worth 06/19" },
  { value: "JBHM67T72KX18V9F8J1Y", text: "Autohaus Lancaster Inc_SJB_2017/05" },
  { value: "JB8Q6MV6WYYH55SMXD4S", text: "BEACH AUTOMOTIVE GROUP_SJB_2015/08" },
  { value: "JB8Q66B632WJCMSWRKGS", text: "Beaty Chevrolet_SJB_2015/04" },
  { value: "JB8Q6926Q0S2YRZ0KSPN", text: "Beaty Chevrolet_SJB_2017/03" },
  { value: "JBHR7YY775T9F05YZFD6", text: "Beaver Toyota_SJB_2015/01" },
  { value: "JB8Q3JH6MY3MJBNJFS5T", text: "BECK FORD LINCOLN_SJB_2015/06" },
  { value: "JB317K465NWXMYC7ZQGT", text: "Bell Audi_SJB_2019/03" },
  { value: "JB335FL6KDVS13MTDH3R", text: "Bell Ford - NJ_SJB_2019/03" },
  { value: "JBHT50L5YVLB03XBRVH5", text: "Ben Davis Chevrolet_SJB_2016/02" },
  { value: "JB902S06JCJ50KFXLT8F", text: "Benson Alfa Romeo & Fiat_SJB_2016/12" },
  { value: "JB8Q83178VYBRLQR7WRF", text: "Benson Alfa Romeo of Greer_SJB_2016/12" },
  { value: "JBHP0TF63TVKMQVJXDR3", text: "BENSON CHRYSLER DODGE JEEP_SJB_2015/07" },
  { value: "JBHV6WZ5WGF37FVLPNSC", text: "Benson Chrysler Jeep Dodge_SJB_2016/12" },
  { value: "JB8Q6PT5Z4TRRMMJ668V", text: "Benson Chrysler Jeep Dodge_SJB_2017/01" },
  { value: "JBHQ3B562G6ZBR0T7TGY", text: "BENSON FORD_SJB_2015/03" },
  { value: "JBHT6GL6V4264GKSS4DF", text: "Benson Hyundai_SJB_2016/11" },
  { value: "JB8G1PZ6BY444W0WK4GY", text: "Berge Mazda of Volkswagen_SJB_2016/08" },
  { value: "JBHQ0RZ6XHGSF3571KWK", text: "Best of Clinton, Inc._SJB_2018/03" },
  { value: "JB2V7PQ78HGFK1SJ5R62", text: "Bidleman Chevrolet Buick GMC, Inc_SJB_2021_11_06" },
  { value: "JBHL4636BGSS905YYFL9", text: "BILL BARTH AUTOMOTIVE_SJB_2016/04" },
  { value: "JB8G7FP5WT4D9H95HN8J", text: "Bill Barth Ford_SJB_2016/04" },
  { value: "JBD63Z66L0PN6CJ95W10", text: "Bill Collins Ford Lincoln of Louisville_SJB_2018/12" },
  { value: "JBHL1CZ6VLF1LGQHCKN9", text: "Bill Cramer Chevrolet Cadillac Buick GMC_SJB_2015/10" },
  { value: "JB8S6ZF6PZH6RBL6G7X3", text: "Bluebird Homecare Inc_SJB_2017/04" },
  { value: "JBHS2SG618QYN2S8PX99", text: "BMW of Gwinnett Place_SJB_2017/05" },
  { value: "JB8G27Z6LGPZ4793SBM8", text: "BMW of South Atlanta_SJB_2016/08" },
  { value: "JBHP3616B3V293PXQQN5", text: "BMW of South Atlanta_SJB_2016/08" },
  { value: "JBHV65N6K7D5CBTQRFC8", text: "BMW of South Atlanta_SJB_2016/08" },
  { value: "JBHT3PG6R5BLLZ1D1GT4", text: "Bob Gillingham Ford_SJB_2016/11" },
  { value: "JBHN3FG6XFGPPL5X6L14", text: "BOB KING AUTOMOTIVE GROUP_SJB_2015/01" },
  { value: "JBHQ3C26VMBBSP8BNZ6V", text: "Bob Rohrman Hyundai" },
  { value: "JBHQ3C26VMBBSP8BNZ6V", text: "Bob Rohrman Hyundai_SJB_2018/02" },
  { value: "JBJH22S6QDN16YY57R8G", text: "Bob Rohrman Mitsubishi_SJB_2018/03" },
  { value: "JBCH3RY6FXDH95NNPZQH", text: "Bob Rohrman Subaru of Fort Wayne_SJB_2017/12" },
  { value: "JBHM2666G4GRP8K2S957", text: "BOB TYLER TOYOTA _SJB_2015/11" },
  { value: "JB2P1LF6H852XCXJK0VQ", text: "Bob-Boyd Auto Family_SJB_2018/10" },
  { value: "JB2Z2Q76DDWW2P0L0X0S", text: "Bob-Boyd Lincoln, Inc_SJB_2019/02" },
  { value: "JBD60H96HHDZLPJ4FBVR", text: "Bob-Boyd Lincoln_SJB_2018/10" },
  { value: "JBHP45K760LZG4F9N5MV", text: "Brannon Honda_SJB_2016/08" },
  { value: "JBHM3VS63F2WR0S67HLY", text: "Brattleboro Subaru_SJB_2017/09" },
  { value: "JBHL7P46Z7T7BK8GKV3T", text: "Briggs Auto Group_SJB_2017/07" },
  { value: "JBHR2C666M7LS7JW28CT", text: "Briggs Nissan Lawrence_SJB_2017/09" },
  { value: "JBHM3Z76Z5HKZXY90J2W", text: "Briggs Topeka_SJB_2017/09" },
  { value: "JBHQ1346ZV6HRLQB65D0", text: "Brownsville Toyota_SJB_2017/02" },
  { value: "JBHN89271NZKYYV6FN4N", text: "Buckeye Superstore_SJB_2016/05" },
  { value: "JBHR29B625GQRZDHBTV7", text: "Bud Brown Volkswagen_SJB_2018/01" },
  { value: "JBHM2QH6Y2DTKF5TLMRW", text: "Buerkle Acura_SJB_2018/03" },
  { value: "JB2R1X96HZM6DMJFH4QX", text: "Burns Chevrolet_SJB_2019/03" },
  { value: "JBHS883787Y18G55H2FF", text: "Butler Ford_SJB_2018/04" },
  { value: "JBJ53WQ6K16Q0N2MLXPQ", text: "Butler Honda_SJB_2018/04" },
  { value: "JB8S6R36DZC3M7MZX67G", text: "Butler Lexus of South Atlanta_SJB_2018/01" },
  { value: "JBHM3856PHCDZQGW190S", text: "Butler Lexus of South Atlanta_SJB_2018/02" },
  { value: "JB8J0JT68QDJZF5HW155", text: "Butler Nissan_SJB_2018/03" },
  { value: "JBDD69C6QB59S0JQZ08Z", text: "C & R Fleet Services, Inc_SJB_2019/05" },
  { value: "JBHM2F16XL53W89JRHHD", text: "Cadillac Mazda of Lake Lainer_SJB_2016/09" },
  { value: "JB2Y11X787H607JTT6CK", text: "Camelback Ford_SJB_2018/10" },
  { value: "JBD67386CY0KP14GCW1H", text: "Camelback Hyundai_SJB_2018/06" },
  { value: "JBGW05J6TFPG14S028X9", text: "Camelback Volkswagen Subaru Mazda_SJB_2018/01" },
  { value: "JBHL0PV6KMF8ZFT0HG6P", text: "Campbell Auto Group_SJB_2018/01" },
  { value: "JBHL0PV6KMF8ZFT0HG6P", text: "Campbell Auto Group_SJB_2018/01" },
  { value: "JB900WZ79HBP10K40W7W", text: "Campbell Nissan of Edmonds_SJB_2017/06" },
  { value: "JBHS4L176BRFYLFN86ZY", text: "Campbell Nissan of Edmonds_SJB_2017/10" },
  { value: "JB8S5PM5WBMF0LKZVFHF", text: "Campbell Nissan of Everett_SJB_2017/06" },
  { value: "JB2S5J86S6N8RTXS1672", text: "Campers Inn RV_SJB_2018/12" },
  { value: "JBHR6JM5X654WGNN2KVB", text: "CAMPING WORLD OF LITTLE ROCK _SJB_2015/02" },
  { value: "JBHT39Z6R92PB57TKSW1", text: "CANNON BUICK CADILLAC_SJB_2015/04" },
  { value: "JBHR140605C29HP4HFCS", text: "CAPITAL CADILLAC_SJB_2016/05" },
  { value: "JB8Q0DX72X7FBCZTNBZG", text: "CAPITAL CHEVROLET_SJB_2016/05" },
  { value: "JBHT0CN6GBR3NSSLJR8G", text: "CAPITAL FORD CHARLOTTE_SJB_2014_12" },
  { value: "JBHS7736YMWP7K8SN8QY", text: "Capital Ford Lincoln of Wilmington_SJB_2017/03" },
  { value: "JBHS29Z71L2QGJ3G9YSR", text: "CAPITAL FORD ROCKY MOUNT_SJB_2016/05" },
  { value: "JB8S07761S190FMK1VCF", text: "Capital Ford Wilmington_SJB_2016/03" },
  { value: "JB900GF6D8VSPLNW9RKC", text: "CAPITAL HYUNDAI SUBARU OF GREENSBORO _SJB_2016/03" },
  { value: "JBHR7GQ74XD432K4ZQML", text: "Capital Nissan of Wilmington_SJB_2016/03" },
  { value: "JBHP2PF617X0T8TS30XY", text: "Capital Subaru of Greenville_SJB_2018/04" },
  { value: "JBHR88661LLB9T577L1J", text: "CAR TOWN KIA_SJB_2015/07" },
  { value: "JB908B76NT4MGW3KK4J7", text: "Carolina Ford_SJB_2016/06" },
  { value: "JBJH08R61FT5DQLX02BW", text: "Carolina Volkswagen" },
  { value: "JB8S7W670PL4W1JFDT23", text: "Carolinas Collision Center_SJB_2016/12" },
  { value: "JB8S3DG678HT0JTM3KPX", text: "CARTOWN CHRYSLER DODGE JEEP RAM OF PARIS _SJB_2015/12" },
  { value: "JBHV5MT65356NTX19W09", text: "Central Florida Chrysler Jeep Dodge_SJB_2014/12" },
  { value: "JBHL3YT6NFD9NQD037N1", text: "Chatham Parkway Toyota  _SJB_2014_12" },
  { value: "JB305Z56T0XGRPJH8BR3", text: "Chenal Restoration_SJB_2018/05" },
  { value: "JBHS32S67P2PG5796BMX", text: "CHEROKEE COUNTY TOYOTA_SJB_2015/06" },
  { value: "JB8G6YL6KPNSYQTGXBCV", text: "Cherokee Ford Lincoln_SJB_2016/03" },
  { value: "JBHV7H36MKR1R7MDBF69", text: "Cherry Hill Nissan_SJB_2016/10" },
  {
    value: "JBHN59N66WF6PWGQ1F08",
    text: "CHEVROLET BUICK GMC CADILLAC OF MURFREESBORO_SJB_2015/11",
  },
  {
    value: "JBHT7LV6PL47D3L535WT",
    text: "CHEVROLET BUICK GMC CADILLAC OF MURFREESBORO_SJB_2015/11",
  },
  { value: "JBD834S75RX30VNR1127", text: "Chevrolet Buick GMC Of Puyallup_SJB_2019/02" },
  { value: "JB305RZ5W85MS0YDPBN9", text: "Chevrolet of Canton_SJB_2019/02" },
  { value: "JBD64SD6RHFMLJSBHJ2P", text: "Chevrolet of Everett_SJB_2019/03" },
  { value: "JBHM0LF6H0KXPWMYHHGD", text: "CHEVROLET OF MORRISTOWN_SJB_2015/08" },
  { value: "JBHR69R63CNCRLV040N7", text: "Chevrolet of Morristown_SJB_2015/08" },
  { value: "JBHN66864V2QLBJVDW6F", text: "CHRYSLER DODGE JEEP RAM OF PARIS_SJB_2015/08" },
  { value: "JBCL3N16C4T6DYFCYZD1", text: "Chuck Olson Kia_SJB_2019/02" },
  { value: "JB8S17B6XZ3M4WQQG9SW", text: "Classic Cadillac_SJB_2016/11" },
  { value: "JBHQ06L6CQXLJ2QLXCJL", text: "Classic Chevrolet Buick GMC - Granbury_SJB_2017/09" },
  { value: "JBHM0DB63L78M0P4VX7N", text: "Claude Nolan Cadillac_SJB_2014/11" },
  { value: "JB8J3D46WQRDRX570ZN3", text: "Coach Specialists of Texas" },
  { value: "JBHM0T272SGYJ36W9H5T", text: "Coastal Chevrolet_SJB_2017/02" },
  { value: "JBHM5G06SWDRPZP2N500", text: "COBB COUNTY TOYOTA_SJB_2015/09" },
  { value: "JBHN7CZ6WH68LB88P1JH", text: "Cobb County Toyota_SJB_2017/01" },
  { value: "JB8S2S56YVJBSNTXRM2F", text: "Cobb County Toyota_SJB_2017/06" },
  { value: "JBCM39B5YG1TXM0L499S", text: "Coffman Truck sales_SJB_2019/02" },
  { value: "JB333YC69MDNDFDC4K6C", text: "COIT_SJB_2018/05" },
  { value: "JB8G5Z1684V08NS6RGWQ", text: "Cole and Sons_SJB_2016/06" },
  { value: "JBDF73S70N7D4XNGVW1P", text: "Cole Buick GMC_SJB_2018/10" },
  { value: "JBDH46Y6QZLLR2DGM7Y1", text: "Cole Chrysler Jeep Dodge Ram_SJB_2018/12" },
  { value: "JB3305G5WP3MJZ5J76BQ", text: "Cole Ford Lincoln_SJB_2018/10" },
  { value: "JB315X16G7FKGYJTYHC8", text: "Cole Krum Chevrolet_SJB_2018/12" },
  { value: "JB3075861H9PLZVRQRSW", text: "Cole Nissan_SJB_2019/02" },
  { value: "JBHL71M5X0B9BCW8G7H5", text: "Colonial Buick GMC_SJB_2016/10" },
  { value: "JBHP7XF6YNL17ZTLQSNG", text: "Community Honda of Orland Park_SJB_2016/08" },
  { value: "JB2W0876LSBSKC8FHMY6", text: "Concord Mazda_SJB_2018/10" },
  { value: "JB8G0TX6G1GWF3MHFNCP", text: "Conyers Nissan_SJB_2016/11" },
  { value: "JB8G2WD6H9WTDFCWC170", text: "Copy of Community Honda of Orland Park_SJB_2016/08" },
  { value: "JBHR0CQ68ZTVGCH358PN", text: "Copy of Educators Now, LLC_SJB_2017/12" },
  { value: "JB907KN6DXFW1C2VCBLS", text: "Copy of Lou Sobh Honda_SJB_2017/12" },
  { value: "JBHP6RR71BYZ6TRWKRRW", text: "Costal Chevrolet_SJB_2017/02" },
  { value: "JBHS84N6607TL50XGVYK", text: "Courtesy Chrysler Dodge Jeep Ram_SJB_2017/06" },
  { value: "JBDD8HT77RWPJNK2YHV8", text: "Courtesy Ford Lincoln of Rome_SJB_2018/05" },
  { value: "JBHM7S66GMLFWRCC2XKC", text: "Courtesy Ford of Atlanta_SJB_2017/05" },
  { value: "JBHT3DB6HVNSFFG7DZR0", text: "Cowboy Toyota_SJB_2017/09" },
  { value: "JBHR5G76PWCDZSXGZRV9", text: "COX AUTO GROUP _SJB_2015/04" },
  { value: "JBHT2D96QT6P7166Y2SM", text: "Cox Toyota_SJB_2016/09" },
  { value: "JBHS33X68DTM4JJGYCLL", text: "Craig and Landreth Cars_SJB_2017/06" },
  { value: "JB8S81Q6W0P2642YRJR5", text: "Craig and Landreth Chrysler Dodge Jeep RAM_SJB_2017/06" },
  { value: "JBHM58B63YBFXQGCRN5H", text: "Craig and Landreth Clarksville_SJB_2017/06" },
  { value: "JBHP2296FKLGSRQCK8YH", text: "CRITZ AUTO GROUP_SJB_2015/04" },
  { value: "JBHM4D66PKX87043G99Z", text: "Cronin ford_SJB_2015/11" },
  { value: "JBDD6RJ6NRCM4H7NT55H", text: "Crown Automotive Group_SJB_06/2021" },
  { value: "JB8G76N72C1HFQMK2W4P", text: "CROWN FORD_SJB_2015/10" },
  { value: "JBJ53156SN8VL5JV6Z2R", text: "Crown Information Management_SJB_2017/06" },
  { value: "JBDH4GC799DH9SS4STLG", text: "Cutting Edge Property Management_SJB_2018/06" },
  { value: "JBHQ1B66F26W75JC2K56", text: "Dan Vaden Chevrolet Cadillac Brunswick_SJB_2016/09" },
  { value: "JB8G3CH6ZK2GLMHQ7PFZ", text: "Dan Vaden Chevrolet Cadillac Savannah_SJB_2017/02" },
  { value: "JB2V58M5VY3WPNB8XPBG", text: "Dave Hallman Chevrolet_SJB_2019/02" },
  { value: "JBHN5L9613NV3Z5VVWG3", text: "Dave Kirk Automotive Group_SJB_2015-05" },
  { value: "JB2W76G73VX09MCV3T9D", text: "Dave Smith Motors_SJB_2018/10" },
  { value: "JB900DS63CSG5KHCC68H", text: "Davey Tree Expert Greensboro_SJB_2016/08" },
  { value: "JBHS6VT685Y0XT3B5BW6", text: "Davey Tree Expert Greensboro_SJB_2016/08" },
  { value: "JBD726Q5Y03867PVV67G", text: "Deacon Jones GM of Smithfield_SJB_2019/02" },
  { value: "JBHV4GC5X5NYJ5TY3VNF", text: "Deland Nissan_SJB_2015-05" },
  { value: "JBHV8CK62781CKJXXWQ8", text: "DELRAY MOTORS_SJB_2015/01" },
  { value: "JBHS01B77NQ0DKMYVPBH", text: "Delray Motors_SJB_2015/3" },
  { value: "JBHN8BJ724T3K96WF1YN", text: "Delray Nissan_SJB_2016/08" },
  { value: "JBHL3PX6HX9W095C51VC", text: "Delray Nissan_SJB_2016/08" },
  { value: "JBGX30276Z038FFJBX1D", text: "Delray Nissan_SJB_2018/03" },
  { value: "JBHQ2S96JJNBDCCG39J5", text: "Devoe cadillac_SJB_2015/03" },
  { value: "JBHN0PB6RF2MTJ8N16Y1", text: "Devon Nissan_SJB_2018/01" },
  { value: "JBHP49Q5Z6PTHFZ7F2MD", text: "Dick Dyer Toyota_SJB_2016/08" },
  { value: "JBHN5916M27XXCTX6B06", text: "Doral Lincoln_SJB_2015/11" },
  { value: "JB8Q8H874K0TP88LNWRD", text: "Dyer Chevrolet Fort Pierce_SJB_2018/03" },
  { value: "JBHR5KC6J5819TLYPP2N", text: "Dyer Chevrolet Vero Beach_SJB_2018/03" },
  { value: "JB904W067C6H52HS1T1P", text: "Dyer Mazda/Subaru_SJB_2018/03" },
  { value: "JB8S1B6647X4TFWQD1QB", text: "East Charlotte Nissan_SJB_2017/04" },
  { value: "JBHS7BZ6DSJ7K5VLGSXC", text: "EASTERN SHORE TOYOTA_SJB_2015/09" },
  { value: "JBCL2MQ69CRGMXMYR2WP", text: "Ed Martin Acura_SJB_2019/01" },
  { value: "JB8Q38Y6Y3F5FLZ3S1WR", text: "Ed Voyles Hyundai_SJB_2018/04" },
  { value: "JBHV74H75T6R93TTTXCW", text: "ED Voyles Kia of Chamblee_SJB_2016/09" },
  { value: "JBHP56S6S6PX7STFL9RV", text: "Ed Voyles of Acura_SJB_2016/10" },
  { value: "JB8Q3337475KBW32FPR4", text: "Educators Now, LLC_SJB_2017/12" },
  { value: "JB8S6RL6JDB51152QHC9", text: "Edwards Chevrolet_SJB_2016/03" },
  { value: "JBHQ1776JC60KJ3SXKSD", text: "Edwards Chevrolet_SJB_2016/06" },
  { value: "JBHS4W472F9RPW126C0H", text: "EH Automotive_SJB_2017/06" },
  { value: "JB8J19B6LJC14C4CJP9J", text: "Elk Grove Toyota_SJB_2018/03" },
  { value: "JB2Z3BW766X996GD764Y", text: "ePipe Plumbing and Pipe Restoration_SJB_2018/10" },
  { value: "JBHN36Z6FTTB9QKQBJVN", text: "ESTERO BAY CHEVROLET_SJB_2015/03" },
  { value: "JBHT12G6ZWQJFDQ4590K", text: "EVANS MOTORWORKS_SJB_2015/10" },
  { value: "JBHQ5KT6LG51L1S72TKC", text: "Everett Chevrolet Buick GMC Cadillac_SJB_2016/09" },
  { value: "JBHS52470N43FJNR7YJ4", text: "Everett Nissan_SJB_2017/03" },
  { value: "JBHM4SV70N01M7NP7XP8", text: "Excellent Choice Auto Sales_SJB_2017/09" },
  { value: "JBHM7GF6FF5S83WFXX1H", text: "Extreme Automotive Group_SJB_2016/11" },
  { value: "JBHL5NF6Z9QC7LYCZKYN", text: "Fairway Ford_SJB_2016/06" },
  { value: "JBHM579656H0SLMHV7N4", text: "Fayetteville Automall_SJB_2018/03" },
  { value: "JB8S6R56TX4KQFQSRYDF", text: "Fayetteville Kia_SJB_2017/12" },
  { value: "JBHV1JW616SHWY3G394M", text: "FIAT OF PENSACOLA_SJB_2015/07" },
  { value: "JB8G82V65J049TCJ65Q2", text: "Fiesta Lincoln_SJB_2016/01" },
  { value: "JB8J2H566WMWDNBSYQYL", text: "Finnin Ford & Kia_SJB_2016/01" },
  { value: "JBHS1ZQ65D95DFC03PPH", text: "Firkin Nissan_SJB_2017/04" },
  { value: "JBHP2GN6X629CV2J43XV", text: "First Acura_SJB_2016/06" },
  { value: "JBD949K5VNXTHZYW8ZND", text: "First Choice Hiring_SJB_06/2019" },
  { value: "JBD93M56BQGP02M0Z54P", text: "Flagler Chrysler Dodge Jeep Ram_SJB_2019/01" },
  { value: "JBJ52RG63XX7VWFV52WR", text: "Flagstaff Buick GMC_SJB_2017/05" },
  { value: "JBCM8H46HZZ8VMRRNDCQ", text: "Fleet Repair Solutions_SJB_2018/05" },
  { value: "JBHS7ZF6KW5YXL2D10YK", text: "Florence Toyota_SJB_2016/11" },
  { value: "JBHT1L96X1YPFKFJ4S4T", text: "FOLGER AUTOMOTIVE GROUP_SJB_2015/04" },
  { value: "JBHT4ZV6FTZPJPYD2DCC", text: "Ford Lincoln of Cookeville_SJB_2018/05" },
  { value: "JB8G4QM6S2V40QDB0FKY", text: "FORD LINCOLN OF FRANKLIN_SJB_2015/09" },
  { value: "JB8J77070B263Z37T2V8", text: "FORD OF MURFREEBORO_SJB_2015/07" },
  { value: "JBHT14D78XW7T6GCMGQS", text: "FordDirect_SJB_2016/05" },
  { value: "JB2R5P25WQDLCPHPCDP5", text: "Franklin Chrysler Dodge Jeep Ram_SJB_2018/06" },
  { value: "JBHT3766Y6PVRHTH47GS", text: "Fred Anderson Nissan Raleigh_SJB_2015/12" },
  { value: "JBHT0866RBQ5SPZGQ2DQ", text: "Fred Anderson Toyota of Charleston_SJB_2016/11" },
  { value: "JBHR29G77Z5RJ723DF0V", text: "Freedom Honda Sumer_SJB_2017/02" },
  { value: "JBHM1WP61WV8XPN8HMBX", text: "Freedom Honda Sumer_SJB_2017/02" },
  { value: "JBHS1XD5XDJH02MKNGF0", text: "FT. Lauderdale Nissan_SJB_2016/10" },
  { value: "JBHT1M6717VVH6CDCQ56", text: "Galeana - Your Dodge Jeep RAM_SJB_2015/03" },
  { value: "JBHN1QR5W4GHCVSK038K", text: "GALEANA KIA_SJB_2015/08" },
  { value: "JBDD2CC6CSQG4YS74TNR", text: "Gallatin Honda_SJB_2019/03" },
  { value: "JBJJ3BP6WKHF9GPWK628", text: "Gary Miller Chrysler Dodge Jeep RAM_SJB_2018/01" },
  { value: "JBHM7NN6JXZQS64JYLRM", text: "GARY YEOMANS FORD LINCOLN_SJB_2015/02" },
  { value: "JBHV5WT5XBTW4LZZPGTS", text: "GATEWAY BUICK GMC_SJB_2016/03" },
  { value: "JBHS7656PQ585MKP6WHR", text: "Gator Ford_SJB_2015/04" },
  { value: "JBHP59R62X0SBK667D0L", text: "Gerald Jones Auto Group_SJB_2016/11" },
  { value: "JBHR6XJ6FK9DFB1XFRDD", text: "Gettel Nissan_SJB_2016/11" },
  { value: "JBHT75B6YMW3HJVDNM69", text: "Gettel Nissan_SJB_2016/12" },
  { value: "JBHR617701XXTRVKLKKF", text: "Ginn Motor Company_SJB_2014_12" },
  { value: "JBHP5J96JXGYLZ41DV80", text: "Gladstone Mitsubishi_SJB_2015/04" },
  { value: "JBGW02F6N5XJQKLJPDR2", text: "Glendora Chevrolet_SJB_2017/05" },
  { value: "JBHL5TB6GS24QL4RMRP4", text: "Glendora Hyundai_SJB_2017/05" },
  { value: "JBHR5276YML7G2TS6RZK", text: "Glenn Collision Center_SJB_2016/09" },
  { value: "JBHL6TQ64GQVS3Y1D6PY", text: "GLENN FORD LINCOLN_SJB_2015/02" },
  { value: "JB905ZW6Y8PF2FPS04PM", text: "Glenn Nissan_SJB_2016/09" },
  { value: "JBHR15K77CF44GRCP2YZ", text: "GLENN’S FREEDOM DODGE CHRYSLER JEEP RAM_SJB_2015/02" },
  { value: "JB2N7MG6VQ0MJGBFXKBZ", text: "Gold Coast Cadillac_SJB_2018/11" },
  { value: "JBHQ6TQ6HNQCTPDNNC77", text: "Gordon Chevrolet_SJB_2017/12" },
  { value: "JBHP8GY7308FP92HWKM8", text: "GRAINGER HONDA_SJB_2015/03" },
  { value: "JB8J6386JGY944LFQ28Q", text: "GRAINGER NISSAN OF ANDERSON_SJB_2015/12" },
  { value: "JBHP28V78HBCTVVWZH2D", text: "GRAINGER NISSAN_SJB_2016/02" },
  { value: "JBHR2NX6Y1M4K3H8RD75", text: "GRAYSON AUTOMOTIVE GROUP_SJB_2015/03" },
  { value: "JBHS4Z063DYVFJVJRTSP", text: "Green Ford_SJB_2017/02" },
  { value: "JB8Q5P566PTS874YMLZW", text: "Green Ford_SJB_2017/02" },
  { value: "JBHM18F6GWK4PMPRQHVN", text: "Greene Ford_SJB_2017/04" },
  { value: "JBHP2BC6CWS85NTD8WM5", text: "Greenway Automotive Group_SJB_2014/11" },
  { value: "JBHM7VP72YR1JBB7TTBH", text: "GREENWAY DODGE CHRYSLER JEEP RAM_SJB_2015/03" },
  { value: "JB8Q0VR6PZSMC97DC1F7", text: "Gregory Auto Group_SJB_2016/01" },
  { value: "JB900B26K3DG5G8QKPYM", text: "GREGORY HYUNDAI_SJB_2015/06" },
  { value: "JBHM1FR5YX97QSLZY371", text: "GREGORY INFINITI_SJB_2015/07" },
  { value: "JBHV6GF79QJ47KV9MFCZ", text: "GRIFFIN MOTOR COMPANY_SJB_2015/12" },
  { value: "JBHP0VG78VVKLKZ2JXR1", text: "Gulf Chrysler Dodge Jeep_SJB_2017/04" },
  { value: "JBDD7P16S4KXT3NS78BL", text: "Gunther Volkswagen Coconut Creek_SJB_2019/02" },
  { value: "JBDH82Q67PQ0QYHDMDYP", text: "Gunther Volvo Cars Coconut Creek_SJB_2018/05" },
  { value: "JB901H370P5TFF22Q04D", text: "GUNTHER VW OF DELRAY BEACH_SJB_2015" },
  { value: "JBHP5L26N8HTGHMWCY8W", text: "GWINNETT PLACE FORD _SJB_2015/08" },
  { value: "JB8G7SZ642P75LLHXM4Y", text: "GWINNETT PLACE NISSAN_SJB_2015/09" },
  { value: "JBHR1ZP5WCCKQ5HQXSSZ", text: "HAROLD CHEVROLET BUICK_SJB_2016/03" },
  { value: "JB303185VM9B89P6893R", text: "Harry Robinson Buick GMC_SJB_2018/12" },
  { value: "JBHP7YF5WFGCNF52V25V", text: "Headquarters Nissan_SJB_2014_12" },
  { value: "JBHL2TV5YK1B8NMGP9LF", text: "Healthy Home Heating & Air, LLC_SJB_2017/12" },
  { value: "JBCH7WW6PQ7C4Q632H9Q", text: "Heinrich Chevrolet 3/26/18" },
  { value: "JBHT46B6G7FCVM8H020S", text: "Heinrich Services_SJB_2018/05" },
  { value: "JBHQ4KB6X4JK0XN1KJTK", text: "Hendrick Chevrolet_SJB_2015/12" },
  { value: "JB8G73J685VBK3R5HW10", text: "Hendrick Toyota Apex_SJB_2016/08" },
  { value: "JB8J83F5YQTZKHN0LK9Q", text: "Hennessy Automobile Companies_SJB_2015/04" },
  { value: "JB8Q5CG6BD02GJDKNVZ4", text: "Hennessy Buick GMC_SJB_2015/11" },
  { value: "JB9029W64NTJ18VF6549", text: "Heritage Automotive Group_SJB_2016/04" },
  { value: "JBHT5CT65R9650X18ZRZ", text: "Heritage Volkswagen of South Atlanta_SJB_2017/05" },
  { value: "JBHR0W16RFHW5Z9SRRG6", text: "Heritage Volkswagen of West Atlanta_SJB_2016/09" },
  { value: "JBHP7HJ67Y7ZJGT5HFH0", text: "Heyward Allen Buick Cadillac GMC_SJB_2017/02" },
  { value: "JBJH23B6R85ZR8L9GFDD", text: "Heyward Allen Toyota_SJB_2017/03" },
  { value: "JBHQ1DY6XHW3WBN560JS", text: "Hickory Mazda Mitsubishi_SJB_2016/10" },
  { value: "JBD74VC6RBQ36M7R2L41", text: "High Mountain Transport LLC_SJB_2018/12" },
  { value: "JB8S1J35WHR2KBWKFYM6", text: "Honda BMW of Champaign_SJB_2018/03" },
  { value: "JBHM44H6W1X49PSRT0MP", text: "HONDA MALL OF GEORGIA_SJB_2014/12" },
  { value: "JBHL1LD6VKZCGHH1C2H4", text: "Honda of Conyers_SJB_2016/09" },
  { value: "JBHN2BM76JQ868JNLMTQ", text: "HONDA OF LAKE JACKSON_SJB_2014_12" },
  { value: "JBHV49B6Y9N6V1BQ687C", text: "Honda of Superstition Springs_SJB_2017/02" },
  { value: "JBHM0XR61XNCGFS4S781", text: "Honda on Grand_SJB_2015/12" },
  { value: "JBCH67P6D9Q46TFS2CDV", text: "Hugh White_SJB_2018/04" },
  { value: "JBHT0C273M8SMZ7W1809", text: "Huntersville Ford_SJB_2015/11" },
  { value: "JB2P4B56LSFJX9FQJT2G", text: "Husker Auto Group_2018/05" },
  { value: "JB8G5W864FTYBPX12GNJ", text: "Hyundai Greensboro_SJB_2016/03" },
  { value: "JB8G7JQ5WZ9NL06MXM9Z", text: "Hyundai of Asheville_SJB_2016/11" },
  { value: "JB8Q3G061R1P9ZMTVXSN", text: "HYUNDAI OF ATHENS_SJB_2015/06" },
  { value: "JBD789K6KJGX06Y608NB", text: "Hyundai of Cool Springs_SJB_2018/10" },
  { value: "JB8G7ZC6NPZBHFH03XLF", text: "Hyundai of Kennesaw_SJB_2016/05" },
  { value: "JBHR8DH6358D9579FHR5", text: "Hyundai of Kennesaw_SJB_2017/06" },
  { value: "JB2N0R971M55ZVHKGTVL", text: "Hyundai of North Charleston_SJB_2019/02" },
  { value: "JBHP8BG6CC273BDWKC7Q", text: "I-95 Toyota & Scion_SJB_2015/07" },
  { value: "JBHV0JC77NP6NHTTN6Q7", text: "Infiniti Coconut Creek_SJB_2014_12" },
  { value: "JBHR2S86FRBF4KL4F79W", text: "Infiniti of Hilton Head_SJB_2017/02" },
  { value: "JBHM5NX5YPKYP04VB644", text: "Infiniti of Hilton Head_SJB_2017/02" },
  { value: "JBDH54174QFVG3WNL9VJ", text: "INFINITI of Tacoma at Fife_SJB_2018/10" },
  { value: "JBDH54174QFVG3WNL9VJ", text: "INFINITI of Tacoma at Fife_SJB_2018/10" },
  { value: "JBCH4DG62JN863N7P9JS", text: "Interstate Toyota_SJB_2018/03" },
  { value: "JBCH5T8678QPKZPMJ2J1", text: "Isringhausen Imports_SJB_2018/05" },
  { value: "JBHP1PY64KHDLCV2GQLL", text: "Isselhardt Nissan_SJB_2018/03" },
  { value: "JBHN2DY77847TVBWFSWQ", text: "Jack Wilson Chevrolet Buick GMC" },
  { value: "JBHL0HN68L5H3QRYDHBJ", text: "JACKSON ACURA_SJB_2015/02" },
  {
    value: "JBHS6F7686338R896DX1",
    text: "Jacksonville Chrysler Jeep Dodge Ram Bay Meadows_SJB_2014/12",
  },
  { value: "JBHT13Y6LH6TGW62CP6D", text: "Jacksonville Chrysler Jeep Dodge Ram_SJB_2014_12" },
  { value: "JB2S1P46Q2CMYYVC04G2", text: "Jacky Jones Chrysler Dodge Jeep Ram_SJB_2018/06" },
  { value: "JBJ53M5625FZG288S39Q", text: "Jacky Jones Lincoln_SJB_2017/05" },
  { value: "JBHM1F4764LKHZY86HKW", text: "JAGUAR FORT MYERS_SJB_2015/03" },
  { value: "JBCH4SV73FJ71GBYMPJG", text: "Jason_Pilger_Hyundai_SJB_2018/02" },
  { value: "JBCH4SV73FJ71GBYMPJG", text: "Jason_Pilger_Hyundai_SJB_2018/02" },
  { value: "JBHR7936ZL2D4TPKH2K8", text: "JAY WOLFE ACURA_SJB_2015/10" },
  { value: "JBHT81D6YKMVJLZF5PSB", text: "JAY WOLFE HONDA_SJB_2015/10" },
  { value: "JBHS0QR74CMK3L0DWRYY", text: "JAY WOLFE TOYOTA OF WEST COUNTY_SJB_2015/07" },
  { value: "JBHQ7D06Q5N62PJ34M5Q", text: "JAY WOLFE TOYOTA – KANSAS CITY_SJB_2015/06" },
  { value: "JB8Q63F750TD6JL0B14G", text: "JC Cooling & Heating_SJB_2018/01" },
  { value: "JBJH6JF74SXG7W0CHCMS", text: "Jeff Haas Mazda_SJB_2017/12" },
  { value: "JBHV34L6GXCFPJX29CMY", text: "JENKINS AUTOMOTIVE GROUP_SJB_2015/03" },
  { value: "JBHM6LZ6TV4YKZCRH5JQ", text: "Jenkins Kia of Gainesville_SJB_2017/03" },
  { value: "JBHT52178JGP6C1HBZ5Y", text: "JERRY HAMM CHEVROLET_SJB_2015/02" },
  { value: "JB2P6QX6TH3NGKXM6VJD", text: "Jim Burke Automotive_SJB_2018/06" },
  { value: "JBHT0P068RKP8F4NT2BZ", text: "JIM ELLIS AUTO GROUP_SJB_2016/04" },
  { value: "JBHQ3386ZMLT025NHJ9Z", text: "JIM ELLIS AUTO GROUP_SJB_2016/05" },
  { value: "JBHM5KM6ZFGZYL1MS8J0", text: "JIM HUDSON AUTOMOTIVE GROUP_SJB_2016/01" },
  { value: "JBGX6ZN6N7HJX0YG0V96", text: "Jim Hudson Lexus of Augusta_SJB_2017/03" },
  { value: "JBHR4N46FH1NB4TL6RF6", text: "Jim Hudson Toyota_SJB_2017/05" },
  { value: "JBDF7X8743WGGBXF22MH", text: "Jim Shorkey CDJR_SJB_2018/07" },
  { value: "JBHQ1HF6T3NG3KBTSBFM", text: "Joel Rogers Classic Chevrolet of Houston_SJB_2017/05" },
  { value: "JB8G85Q6XLXV0T5B1G0B", text: "John Megel Chevrolet_SJB_2017/06" },
  { value: "JB8G7GD6WLJD3VGTPH56", text: "JOHNSON LEXUS OF RALEIGH_SJB_2015" },
  { value: "JB8G43T67J2CXFBL8FD2", text: "Joseph Airport Hyundai_SJB_2017/02" },
  { value: "JB902W278QJP0TF5Y1N3", text: "Joseph Airport Toyota_SJB_2017/05" },
  { value: "JB3Q4LM6NTFQZFDQ43WN", text: "Joseph Cadillac of Dublin" },
  { value: "JB907ZH699517R97V56C", text: "Joseph Toyota of Cincinnati_SJB_2018/04" },
  { value: "JBCH3Y86SJ8RT8T6RN35", text: "Joyce Honda_SJB_2018/01" },
  { value: "JBCH3Y86SJ8RT8T6RN35", text: "Joyce Honda_SJB_2018/01" },
  { value: "JBHV7Y26MQ4ZJ13CN4J5", text: "JT’S CHRYSLER DODGE JEEP RAM_SJB_2015/11" },
  { value: "JBJJ17666F1VQSLXK3NS", text: "Just Let Me Do It_SJB_2017/12" },
  { value: "JBD94WB63QDPNPBQCJ13", text: "Keffer Mazda_SJB_2018/07" },
  { value: "JBD87BS77J84WL23MHTM", text: "Kenosha Nissan_SJB_2018/06" },
  { value: "JBHP5J1775YQ8BQG98V5", text: "KEY BUICK GMC_SJB_2015/02" },
  { value: "JB8Q31F695HKHF2KV3NP", text: "Kia of Murfreesboro_SJB_2016/08" },
  { value: "JBHN5M06RWVK8NF83BT2", text: "KIA OF NORTH GRAND RAPIDS_SJB_2015/08" },
  { value: "JB2W5XL6879WDX82W6DH", text: "Kiddie Academy of Lakewood Ranch_SJB_2018/10" },
  { value: "JBDH5N17534J4RQTJYSV", text: "Kiddie Academy of Phoenixville_SJB_2018/10" },
  { value: "JB8J6S164TYCBFQ7G8VL", text: "Kinsel Ford Lincoln_SJB_2016/08" },
  { value: "JB2P7SC68DN0LHPPXZRS", text: "Kirkland Nissan_SJB_2018/12" },
  { value: "JB306BT776Y3M0VKG5KK", text: "Koeppel Ford_SJB_2018/07" },
  { value: "JB2Z5GF60SGNP137JPDN", text: "Koeppel Nissan_SJB_2018/07" },
  { value: "JBD88BB74N3699SXYFCG", text: "Koeppel Subaru_SJB_2018/06" },
  { value: "JB8G59N720RHL86DVGHC", text: "Krause Auto_SJB_2017/02" },
  { value: "JBCM4KF64389VQ3YH21D", text: "Lake Norman Hyundai_SJB_2019/02" },
  { value: "JBHP0RY6VSF077FFNMNZ", text: "Lake Norman Infiniti_SJB_2017/06" },
  { value: "JBHS7K864P0KRJCVVLK9", text: "Lake Powell Ford" },
  { value: "JB903SL73P85HL4LJNSJ", text: "Lance Cunningham Ford_SJB_2017/02" },
  { value: "JBHM0PF665JWRKKY2JXN", text: "LAND ROVER FORT MYERS_SJB_2015/03" },
  { value: "JBHM1GM75R16P0B7JK6S", text: "LAND ROVER JAGUAR FORT MYERS_SJB_2015/03" },
  { value: "JB8J86B6TPL6SPJ4Q7PP", text: "Landers Chevrolet_SJB_2016/08" },
  { value: "JBHS09D60X7Y5F9JNTHM", text: "Landers Chrysler Dodge Jeep Ram_SJB_2016/07" },
  { value: "JB8J32J5ZY1NKQG4WYTV", text: "Landers Chrysler Dodge Jeep Ram_SJB_2016/07" },
  { value: "JBHQ88J63MNQG6T7DK7X", text: "Landers Dodge Chrysler_SJB_2015/11" },
  { value: "JBHV0LK6902C4HG623CD", text: "Landers Ford_SJB_2016/09" },
  { value: "JBHM4WX66PT0BXC99JLN", text: "LANDMARK CHRYSLER DODGE JEEP RAM_SJB_2015/03" },
  { value: "JBHL2KD6B9L44RY8D0X6", text: "Landmark Chrysler Jeep Dodge Ram_SJB_2014_12" },
  { value: "JBHP2Y76C5S76MF85YL7", text: "LANNAN CHEVROLET OF LOWELL_SJB_2015/12" },
  { value: "JBHP2L46Y9PCNH29PX12", text: "LAREDO DODGE CHRYSLER JEEP_SJB_2015/02" },
  { value: "JBDD2M6625621930125H", text: "Larry H. Miller Chevrolet - Provo_SJB_2019/03" },
  { value: "JB2P0Y46VHKXKZQHZSF9", text: "Larry H. Miller Chevrolet_SJB_2018/10" },
  {
    value: "JBDH1G46TSZPHCFDFLWG",
    text: "Larry H. Miller Chrysler Jeep Dodge Ram - Provo_SJB_2019/02",
  },
  {
    value: "JB2V2Y86NHYSCVSZ30H6v",
    text: "Larry H. Miller Chrysler Jeep Dodge Ram of Albuquerque_SJB_2019/",
  },
  { value: "JB337MR71TCCJHWBKBMF", text: "Larry H. Miller Dodge Ram Peoria_SJB_2019/02" },
  { value: "JBD86DT789J4LBBN3TVT", text: "Larry H. Miller Hyundai Peoria_SJB_2019/05" },
  { value: "JBDF8656M3CNBX26FB32", text: "Larry H. Miller Lexus - Utah_SJB_2019/03" },
  { value: "JBDH4Y36XBW5CGGVJ8V1", text: "Larry H. Miller Nissan Arapahoe_SJB_2019/04" },
  { value: "JB33759694BPSKF285W6", text: "Larry H. Miller Southwest Hyundai_SJB_2018/10" },
  { value: "JB2N7FN66FSFP6VS29DT", text: "Larry H. Miller Super Ford, Salt Lake City_SJB_2018/12" },
  { value: "JB3011D6YVC8SYHF5NJL", text: "Larry H. Miller Toyota Colorado Springs_SJB_2019/02" },
  { value: "JBDD2XS6CPCNWPJR3J5C", text: "Larry H. Miller Toyota Lemon Grove_SJB_2019/02" },
  { value: "JBCL0CL6VR1Y2RX8ML78", text: "Larry H. Miller Volkswagen Avondale_SJB_2018/12" },
  { value: "JB311SR5XVP1K3PDK575", text: "Larry H.Miller Toyota Peoria_SJB_2018/06" },
  { value: "JBHL0Z26CJGV8RFHKB5W", text: "Lee Hyundai of Fayetteville_SJB_2017/04" },
  { value: "JBHM8166QJF83BHBS5RG", text: "Lee Hyundai of Goldsboro_SJB_2016/06" },
  { value: "JBHT84H752QCR6H8QDKB", text: "Lee Kia of Greenville_SJB_2017/06" },
  { value: "JB2R3X87679FFSB0KSQL", text: "Lee’s Summit Dodge Chrysler Jeep Ram_SJB_2018/11" },
  { value: "JBHT4Y06GF72NXGLG01G", text: "Leith Automotive Group_SJB_2014_12" },
  { value: "JBHR4JP6KWM73Z41PXHQ", text: "Lexus of Bellevue_2018/05" },
  { value: "JBGX89H5XJ8WDBZ5HFPJ", text: "Lexus of Northborough_SJB_2017/02" },
  { value: "JB8G0LS700MY4RVDT9VY", text: "Lexus of Northborough_SJB_2017/02" },
  { value: "JBDF3VY73XFSP1LFN56P", text: "Lexus of Tacoma at Fife_SJB_2018/05" },
  { value: "JB301LM6X33679H8GJWX", text: "Liechty Dodge_SJB_2019/02" },
  { value: "JBDD23M6ZQ0KLY7XVQRZ", text: "Lindsay Automotive Group_SJB_2019/05" },
  { value: "JBHM1HK6ZKZPLVHVF8RK", text: "Loganville Ford_SJB_2016/06" },
  { value: "JBCH18R6R652YMTG5C3P", text: "Lou Sobh Automotive_SJB_2017/12" },
  { value: "JB8G81N783ZXHKQPRJQ5", text: "Lou Sobh Honda_SJB_2017/07" },
  { value: "JB8S6YH656DZSGY6R2M0", text: "Lou Sobh Honda_SJB_2017/12" },
  { value: "JBCM2JV67WTNPJXJB38F", text: "Love Chevrolet_SJB_2018/12" },
  { value: "JBHT3VG6HYY846DY4178", text: "Love Honda_SJB_2018/02" },
  { value: "JBGX2R6707KNN0FH141T", text: "Love Motorsports_SJB_2018/03" },
  { value: "JBHP72P706FV6FTY9XR4", text: "Low Country Pre-Owned_SJB_2018/04" },
  { value: "JBHN1F76VBXD3XY81C2W", text: "Low Country Volkswagen_SJB_2017/04" },
  { value: "JBJ56Q36NXN61Y6XJTYC", text: "Lynnes Nissan City_SJB_2018/03" },
  { value: "JBJH2QS65FJTF76JDMNN", text: "Lynnes Nissan East_SJB_2017/09" },
  { value: "JBHR58Q6KF735RVMRVN7", text: "Lynnes Subaru_SJB_2017/09" },
  { value: "JB8S5BH76Z32Z2W3BTX9", text: "Mahalak Auto Group_SJB_2016/03" },
  { value: "JBHL46M70L9CVN4DR7BG", text: "Mahalak Auto Group_SJB_2017/05" },
  { value: "JBHS4Z06386N6GFM74PK", text: "Malcolm Cunningham Hyundai_SJB_2015-05" },
  { value: "JB8Q47L6YFSJ2VGMHH5J", text: "MALL OF GA CHRYSLER JEEP DODGE RAM_SJB_2016/02" },
  { value: "JB905GJ6S34168SCFFPK", text: "MARCH/HODGE AUTOMOITVE_SJB_2015/08" },
  { value: "JB8G6PG69LXXBBSHK0Z5", text: "MARIETTA TOYOTA_SJB_2015/11" },
  { value: "JBHL5706HB19TN9Y03SQ", text: "Mark Jacobson Toyota_SJB_2014_12" },
  { value: "JBHS65B71H062S59HXP6", text: "Mark Mitsubishi Phoenix_SJB_2018/04" },
  { value: "JB904XM6VRH2BK7M055Q", text: "Matt Blatt Kia_SJB_2016/04" },
  { value: "JBHL1HJ5YHKVSR8W6Y48", text: "MAXIE PRICE CHEVROLET_SJB_2015/02" },
  { value: "JBHP09J5ZN2DP123YX8N", text: "Mazda of Erie_SJB_2015/12" },
  { value: "JBHL71L707YSP8WRMHLV", text: "MAZDA OF ROSWELL_SJB_2015/12" },
  { value: "JB8S5YN64VNT2DZ2XF3K", text: "MCGRATH ACURA OF DOWNTOWN CHICAGO_SJB_2015/10" },
  { value: "JBHR4DM6K0VSDB36VN0Z", text: "McGrath Acura of Westmont_SJB_2018/01" },
  { value: "JBDD7BV61JXWF2WPRQRD", text: "McGrath Arlington KIA_SJB_2018/10" },
  { value: "JBHQ5W8679B7LQDXXNDC", text: "McGrath City Honda_SJB_2015/12" },
  { value: "JBHR18R74FKPQC3GPKZY", text: "McGrath City Hyundai_SJB_2015/10" },
  { value: "JBD96P26SSNWLKZ3D0Z2", text: "McGrath Imports_SJB_2019/02" },
  { value: "JBHQ31H72FB6PSK12R1Z", text: "Mcgrath Lexus of Chicago_SJB_2016/09" },
  { value: "JBHV5TV5YD7KM7GRPHGV", text: "Mcgrath Lexus of Westmont_SJB_2016/08" },
  { value: "JBHQ5ZT6BWF6M4F0N149", text: "Mercedes Benz of Fayetteville_SJB_2017/05" },
  { value: "JBHV7VC75T1ZQRK1PQ50", text: "MERCEDES BENZ OF SOUTH ATLANTA_SJB_2015/12" },
  { value: "JB2N8CZ7987MV4YTHN4L", text: "Mercedes-Benz BMW of Myrtle Beach_SJB_2018/10" },
  { value: "JBD668M7549DJHRWRT1J", text: "Mercedes-Benz of Charlottesville_SJB_2018/12" },
  { value: "JBGX8H5680T0MTC8RR3G", text: "Mercedes-Benz of Flagstaff_SJB_2017/06" },
  { value: "JBHP10Z6NMXDZFH72ZDF", text: "Mercedes-Benz of Raleigh_SJB_2018/03" },
  { value: "JBDH7J06Y921DC49LWZ9", text: "Midtown Toyota_SJB_2019/02" },
  { value: "JBHM13H774MYQ0WJ6SDQ", text: "MIDWAY FORD MIAMI_SJB_2015/09" },
  { value: "JBHS3266PF5TLSFNQGJD", text: "Midway Rehab Center_SJB_2016/10" },
  { value: "JBHP34V649GJMT9DLYQG", text: "MIKE REICHENBACH CHEVROLET_SJB_2018/01" },
  { value: "JBHL6ZD69BZW02KW9CGR", text: "MILTON RUBEN SUPERSTORE_SJB_2015/03" },
  { value: "JB8J48M5YVTSP0JGYZWF", text: "Milton Ruben Toyota_SJB_2017/03" },
  { value: "JB8S3HW5XBYQGX2ND0QJ", text: "MINI OF KENNESAW_SJB_2016/01" },
  { value: "JBHN1Y26VW29QRQPLDJB", text: "Mini of Knoxville_SJB_2016/08" },
  { value: "JB8G5PR6SMJ943CTC2YG", text: "MINI of Knoxville_SJB_2016/08" },
  { value: "JBHQ1V66CBHN60K56QPS", text: "Mini/BMW Service Center_SJB_2016/09" },
  { value: "JBHM5SJ6MK58F58S2F2Y", text: "Monroe Superstore_SJB_2016/03" },
  { value: "JBHV2BN6NX8SPF0WVV8P", text: "MONTGOMERYVILLE AUTO COMPLEX_SJB_2016/04" },
  { value: "JB8J6DF5VPJYJP2TGLZS", text: "Montgomeryville Auto Complex_SJB_2016/05" },
  { value: "JBHQ34M628F4654S6G7X", text: "Montgomeryville Auto Complex_SJB_2017/06" },
  { value: "JB8S43M6BS8D1S5JC2S2", text: "Montgomeryville_SJB_2016/05" },
  { value: "JB8S1SP630SBG8VYKPD1", text: "Mooresville Ford_SJB_2016/08" },
  { value: "JBHQ4Q46B859YVSSB5JG", text: "Mooresville Ford_SJB_2016/08" },
  { value: "JBHM69R70YS1CPX9M2V6", text: "MotorCars of Georgia_SJB_2018/03" },
  { value: "JB2P7PV6N2WVF8NQ1038", text: "Mountain States Toyota_SJB_2018/06" },
  { value: "JB8S66V6LLDL5QXB7YF9", text: "Mountain View Chevrolet_SJB_2016/01" },
  { value: "JBHM4YJ76TC7Q8CV9K0N", text: "Mountain View Nissan_SJB_2016/09" },
  { value: "JBHS5526JY0Y3VFB9RKQ", text: "Mtn. View_SJB_2016/03" },
  { value: "JBD67YL67VXK83Y38S7Y", text: "Music City Honda_SJB_2019/03" },
  { value: "JBHS89H5Y5MVL303XTZ4", text: "Nalley Auto Group_SJB_2016/05" },
  { value: "JBHR0G5609KP0FS15BZ6", text: "Nalley Automotive Group Brunswick_SJB_2017/01" },
  { value: "JBHT27Q6G47BP4G8X0VC", text: "NASH CHEVROLET_SJB_2015/06" },
  { value: "JBHP31Y62B99509PM4T9", text: "NASHVILLE CHRYSLER DODGE JEEP RAM_SJB_2015" },
  { value: "JBHR6RZ6YM4TW8RV4RH5", text: "National Parking_SJB_2016/12" },
  { value: "JBHS7DB5X71ML834JFZQ", text: "National Parking_SJB_2016/12" },
  { value: "JBHM4YM6YCQ7GNV8P225", text: "NEW SMYRNA CHRYSLER JEEP DODGE RAM_SJB_2015/03" },
  { value: "JBHL2ZM72BQHCKVVJNF3", text: "Newnan Peachtree Chrysler Dodge Jeep Ram_SJB_2016/01" },
  { value: "JBHT4X96XK222R2SHVYP", text: "Newnan Peachtree_SJB_2016/01" },
  { value: "JB906QM6H9GZ2BM3V7ZX", text: "Newnan Peachtree_SJB_2016/01" },
  { value: "JB8S3LB6B7MJFD7JDKLC", text: "Nextaff_SJB_2018/03" },
  { value: "JBHP7MC712G77ZYYR1B2", text: "NIMNICHT BUICK GMC_SJB_2016/04" },
  { value: "JB8G78D749GXXN25QYJB", text: "Nimnicht Chevrolet_SJB_2016/08" },
  { value: "JB8Q2WD6FJ5MFGCZQ8YN", text: "Nimnitch Automotive Group_SJB_2015-05" },
  { value: "JBHT0306B0NN0DCC049S", text: "Nissan of Athens_SJB_2015-05" },
  { value: "JB8Q7YX72ZQV0CPVR24S", text: "Nissan of Melbourne_SJB_2016/05" },
  { value: "JBJ556W660LF71QZXPCT", text: "Norm Reeves Acura Of Mission Viejo_SJB_2018/03" },
  { value: "JBJ556W660LF71QZXPCT", text: "Norm Reeves Acura Of Mission Viejo_SJB_2018/03" },
  { value: "JBCH01265DP5SF6L62YQ", text: "North Point Volvo Car_SJB_2017/03" },
  { value: "JB3P0HL6TFYMQWNB4H2Z", text: "Novo Surgical Inc." },
  { value: "JBD86VC760KZD85GCXLL", text: "Oak Lawn Toyota_SJB_2019/03" },
  { value: "JBDH04B6CDPN2ZF0VT60", text: "Okeechobee Dodge Chrysler Jeep Ram_SJB_2018/05" },
  { value: "JBHS4Q56WFVXL6SJY7N2", text: "Olathe Ford RV Center_SJB_2017/03" },
  { value: "JB8J3NL74P5MSPPXTH0B", text: "OLATHE FORD_SJB_2015/09" },
  { value: "JBHM7976V5P6XWFVSBCB", text: "Ole Ben Franklin Motors_SJB_2016/11" },
  { value: "JBCH03F76SH28TPYN5Q6", text: "Orrin B. Hayes, Inc_SJB_2017/09" },
  { value: "JBHQ5G870K4YV7J5HB6J", text: "Outlet Auto_SJB_2016/11" },
  { value: "JBJJ8636Y3M6Q5PWXMGT", text: "O’Brien Automotive Family_SJB_2017/05" },
  { value: "JBHQ31G6DYV4SGKHGD58", text: "PANAMA CITY TOYOTA_SJB_2015/03" },
  { value: "JBHM5KN5XZRJNF7JFT7B", text: "Parks Automotive Group_SJB_2014/12" },
  { value: "JB2N5F56G9P522SCT91S", text: "Parks Chevrolet Charlotte_SJB_2019/02" },
  { value: "JBHM50Y6T0YK1LZDPFKQ", text: "Parks Chevrolet Huntersville_SJB_2017/01" },
  { value: "JB8Q23R693571Z68WVKM", text: "Parks Chevrolet Kernersville_SJB_2017/05" },
  { value: "JBHQ2NJ6CBL1QSNKW85Y", text: "Parkside Detail and Accessories_SJB_2018/04" },
  { value: "JBHQ7KT6VKRMZKXQGTHS", text: "Parkway Ford_SJB_2015/11" },
  { value: "JBDH6RF70JNDDK8FLDPV", text: "Pat O’Brien Chevrolet_SJB_2018/12" },
  { value: "JB8Q0VW66ZCNXVJTZ835", text: "PAULDING CHRYSLER DODGE JEEP RAM_SJB_2015/10" },
  { value: "JB8G2PW6PDPPNJSCGKTR", text: "Pecheles Automotive_SJB_2018/04w" },
  { value: "JBHT3705YJ7MW6MVMV1X", text: "Pecheles Honda_SJB_2018/04" },
  { value: "JB8Q7XZ6RM0N5Y9WPGNY", text: "Pensacola Used Car Superstore_SJB_2016/08" },
  { value: "JBHP7RC5WMYZBCVR0RWW", text: "PENSKE AUTOMOTIVE GROUP_SJB_2014/12" },
  { value: "JBHN68W6Q76JGB306HD5", text: "Penske Honda_SJB_2014/11" },
  { value: "JBHS2V878S0VJ3JXG1FD", text: "Performance Ford Lincoln_SJB_2016/10" },
  { value: "JBD86TN6X95JZ7R2PVV7", text: "Peruzzi Buick_SJB_2018/07" },
  { value: "JB334DC6G6N8L0VC69FS", text: "Peruzzi Mitsubishi_SJB_2018/07" },
  { value: "JBD813K6LL1QTC8DLTKR", text: "Peruzzi Nissan_SJB_2018/07" },
  { value: "JBDH226640TLW05FDKFT", text: "Peruzzi Toyota_SJB_2018/07" },
  { value: "JBHR32T6LG2BMBK4HKK9", text: "PETE MOORE AUTOMOTIVE_SJB_2015/01" },
  { value: "JBHR6C079596PQKDK34Y", text: "Phil Hughes Automotive Group_SJB_2017/01" },
  { value: "JBCH20B62S4L0L9WNBNM", text: "Phoenix Instruments Inc_SJB_2017/03" },
  { value: "JBHS6XW6DF2PRVS4T425", text: "PIRTEK Fluid Transfer Solutions Pty. Ltd_SJB_2016/12" },
  { value: "JB8G3FC6HLS8LPWMP2N7", text: "PIRTEK FLUID TRANSFER SOLUTIONS_SJB_2016/04" },
  { value: "JB8Q7XW6PWWD2MSB9XGY", text: "Pirtek-Dobbins_SJB_2017/05" },
  { value: "JB8Q0JY6G0SKTPZ3YDDW", text: "Pirtek-Woodinville_SJB_2016/12" },
  { value: "JB8J5BX765FHJCSVB0FJ", text: "Port Charlotte Honda_SJB_2017/03" },
  { value: "JB8G01476QC0W30X2VSM", text: "Port Charlotte Volkswagen_SJB_2017/04" },
  { value: "JB2Z2LT6HBHGVMJK1LWV", text: "Premier Automotive 2018 12 04" },
  { value: "JBHM2HF749SZHM8M64LC", text: "Premier Chevrolet Buick GMC_SJB_2017/07" },
  { value: "JBHV52S65K4W97Z65PVS", text: "Proexel Media Inc_SJB_2016/08" },
  { value: "JB8G4DG7354FZR14RX9V", text: "Proexel Media Inc_SJB_2016/08" },
  { value: "JBD84XC5Y8FF1V2JLM44", text: "Quakertown Mitsubishi_SJB_2018/07" },
  { value: "JBHN5RX6HMHGPHZDP0TD", text: "Quality Fabrication & Supply, Inc_SJB_2017/12" },
  { value: "JB8S3RN6D4YCLQPW2VN6", text: "R Walls Inc_SJB_2016/09" },
  { value: "JBHM35R75V0J5FDS8GW3", text: "Ralph Hayes Toyota_SJB_2017/02" },
  { value: "JBHT44379R15H6N6S129", text: "Ralph Hayes Toyota_SJB_2017/02" },
  { value: "JB8J3JZ65FGQB1TWZMCJ", text: "Renaldo Auto Mall_SJB_2017/09" },
  { value: "JBJ52C86W33FMBL70K0V", text: "Renaldo Cadillac Buick GMC_SJB_2018/02" },
  { value: "JB307YX76W6PDVB1KZXM", text: "Ressler Motors_SJB_2018/07" },
  { value: "JBHV1GY70LNG7S85J8LJ", text: "RICE TOYOTA_SJB_2015/08" },
  { value: "JB8S2JS6T8GSWX52665L", text: "Ricks RV Center" },
  { value: "JBCH1RM65YPSK8GGR3GV", text: "Rippy Cadillac_SJB_2017/06" },
  { value: "JBHR1PV763JB5PLZWFY7", text: "RITCHEY CADILLAC BUICK GMC_SJB_2015/03" },
  { value: "JB8Q5MR75NWKS1WQK4FB", text: "Ritchey Cadillac Buick GMC_SJB_2017/05" },
  { value: "JBHL19Q6FZWH6HFSV3P3", text: "RIVERCHASE KIA_SJB_2016/04" },
  { value: "JBHT1BJ5W7WTQ6GFQH5F", text: "Riverhead Toyota_SJB_2018/03" },
  { value: "JBHT0CY64P8YRBMWTN27", text: "Riverside Auto Group_SJB_2016/08" },
  { value: "JB905CY5WG4C8VJS2RMY", text: "Riverside Auto Group_SJB_2016/09" },
  { value: "JB307NG79K2MKGS1H1J1", text: "Riverside Buick GMC_SJB_2018/10" },
  { value: "JBCH6PH6J6VQ0FCZMG4C", text: "Rochester Toyota_SJB_2018/01" },
  { value: "JB2W4FW71V5FW81V9J5L", text: "Rocky Mountain Yeti Jackson_SJB_2018/11" },
  { value: "JBD71DM6M6738S92Z16G", text: "Romeo Auto Group 06/19" },
  { value: "JBGX12G670XPMNTC6RW0", text: "Roswell INFINITI of North Atlanta_SJB_2018/03" },
  { value: "JBHM3S25Y44G4CN2X19P", text: "Roth Cadillac_SJB_2018/03" },
  { value: "JBHL69561SXDGQ6VHV6F", text: "Roto Rooter - Pensacola_SJB_2017/02" },
  { value: "JBHP74Z675NSFGR18L6J", text: "Roto Rooter-Pensacola_SJB_2017/02" },
  { value: "JBHL2Q46M45G274MV1R2", text: "RUSTY WALLACE HONDA_SJB_2015/07" },
  { value: "JBHN87B6SFZ2R78MTBS9", text: "Sam Leman Chrysler Dodge Jeep Ram_SJB_2016/10" },
  { value: "JBHN1RF5YCQ2BJPGX9G1", text: "San Antonio Dodge Chrysler Jeep Ram_SJB_2015/02" },
  { value: "JBHP6HK5ZHLWL8ZGXSRH", text: "Sanford Honda_SJB_2017/01" },
  { value: "JBHL0FD6850Z8TBFYFJJ", text: "Sanford Honda_SJB_2018/03" },
  { value: "JBHP68G72CLQW94FM1HC", text: "Schaumburg Honda Automobiles_SJB_2018/01" },
  { value: "JBD86DT789J4LBBN3TVT", text: "Schimmer Automotive Family" },
  { value: "JBHV5F96108MLC8HL6KC", text: "SEAM AUTOMOTIVE GROUP_SJB_2016/04" },
  { value: "JBHQ37C6D08WJ2VWJ3H6", text: "Seaview Buick GMC_SJB_2015/12" },
  { value: "JB8S0FQ65D0XZTPTB893", text: "SEAVIEW BUICK GMC_SJB_2016/04" },
  { value: "JBHS13875WKTT9XGD04X", text: "SECRET CITY CHRYSLER DODGE JEEP_SJB_2015/07" },
  { value: "JBJH1VK696940CT3N6DQ", text: "Select Mitsubishi-Gwinnett_SJB_2018/03" },
  { value: "JBDD4Q172ZF1W4K80J4J", text: "Sharpnack Chevrolet Buick Cadillac Ford_SJB_2018/12" },
  { value: "JB2N1C15Y76031748ZSL", text: "Shepard Auto Group_SJB_2018/10" },
  { value: "JBD83D25YDNG6JCDY8PS", text: "Shepard Chrysler Dodge Jeep Ram_SJB_2018/10" },
  { value: "JBHQ0KN72NFVLR71DF9Z", text: "Shottenkirk Cherokee CDJR_SJB_2016/08" },
  { value: "JBHN58K732JWB8WTK80Z", text: "Shottenkirk Cherokee CDJR_SJB_2016/08" },
  { value: "JBGW37Q6KBRH3786HMZY", text: "Shottenkirk Chevrolet / KIA of Quincy_SJB_2018/03" },
  { value: "JBHT81F63JL82BB3WY6J", text: "Shottenkirk Chevrolet Waukee_SJB_2018/02" },
  { value: "JB9076T6C5YBH205Q0ND", text: "SHOTTENKIRK FORD_SJB_2016/05" },
  { value: "JB2S59672RRRXKCDR0Z8", text: "Shottenkirk Fort Madison_SJB_2018/12" },
  { value: "JBHV05V78P2W6H8F4ML1", text: "Shottenkirk Honda of Cartersville_SJB_2017/01" },
  { value: "JBDD81G62FGH7KKQQB03", text: "Shottenkirk Honda of Davis_SJB_2018/12" },
  { value: "JB2R4H26VVRFYS4HM85N", text: "Shottenkirk Toyota of Granbury_SJB_2019/02" },
  { value: "JB332DT7828C1QH31446", text: "Shottenkirk Toyota of Weatherford_SJB_2019/02" },
  { value: "JBCL1JM79BCH8BS7VQP9", text: "Shottenkirk West Burlington" },
  { value: "JBHP84H6TNW4XS6T7JLS", text: "Simi Valley Chevrolet_SJB_2017/05" },
  { value: "JBHM4YR6WXB42HTMHKQR", text: "Smart Chevrolet and Body Shop_SJB_2016/10" },
  { value: "JBCM02D6ZT7VKPTJ83TQ", text: "Smoky Mountain Chrysler Dodge Jeep RAM_SJB_2019/02" },
  { value: "JBHS14J5YYKX2WVV89FG", text: "Sons Acura_SJB_2015/12" },
  { value: "JBHR2NL6MFV0M65GW49D", text: "SONS ACURA_SJB_2016/04" },
  { value: "JBHP1W36DP6CG6164FZ7", text: "Sons Honda_SJB_2016/06" },
  { value: "JBHL5RM6DX8SW1W47NN6", text: "Sons Kia_SJB_2016/06" },
  { value: "JB2Y3M961RJK762XPV1F", text: "Sound Ford_SJB_2018/10" },
  { value: "JBD653Q5Z16GF1L45N41", text: "South Charlotte Hyundai_SJB_2018/10" },
  { value: "JBHP1DH5XDZS52JLG52W", text: "Southeast Kia_SJB_2015/12" },
  { value: "JB8S2YX672ZKPCGDZD6W", text: "Space Coast Honda_SJB_2017/02" },
  { value: "JBHS2NQ6FWDBQNHWRX4W", text: "Space Coast Honda_SJB_2017/02" },
  { value: "JBHQ1QW6K789SBYH4NF1", text: "Sparks Toyota_SJB_2016/08" },
  { value: "JB9045Q6MMRL3LC56CMT", text: "Spartanburg Chrysler Dodge Jeep Ram_SJB_2016/08" },
  { value: "JB8J20S72635LRC00DKR", text: "Specialty Sales Classics_SJB_2017/09" },
  { value: "JB8G7P76N3SKZ6JB9FFM", text: "SPM_SJB_2017/07" },
  { value: "JBHM4BF66394K7CQ3R82", text: "Spradley Barr Motors_SJB_2016/03" },
  { value: "JB2P4MZ6341H5M7MZSL5", text: "Star Cars_SJB_2018/07" },
  { value: "JBJH5DK6CQYBND3SWJQG", text: "Steve Cannon State Farm_SJB_2017/02" },
  { value: "JBHM5YW6DJZWXJTTSPS5", text: "STEVE RAYMAN CHEVROLET_SJB_2016/05" },
  { value: "JBHM67H6WHFDJT2HCSS9", text: "Steve White Motors_SJB_2016/11" },
  { value: "JBHN1Z1645670YR26SWB", text: "Steven White Volkswagen_SJB_2017/02" },
  { value: "JBGW4R66CW07DWST32M5", text: "Steven White Volkswagen_SJB_2017/02" },
  { value: "JB8Q82H6VNZR9DMJTD3Q", text: "Stevens Jersey City Ford_SJB_2017/09" },
  { value: "JB8Q82M7234K9J5N67ZM", text: "Stewart Brothers Inc._SJB_2017/05" },
  { value: "JBHP08Z6H2TXL5Y9BMFS", text: "Stokes Brown Toyota of Hilton Head_SJB_2015/03" },
  { value: "JB8J1Y8672P9M29QHBLJ", text: "Subaru Concord_SJB_2016/11" },
  { value: "JBHR5SJ6CVJY0C7MDKGD", text: "Subaru of Cherry Hill_SJB_2017/02" },
  { value: "JBHN4J46YZR37P734XDL", text: "Subaru of Macon_SJB_2016/11" },
  { value: "JBD77GP6MGWLP20JRV1H", text: "Subaru of Puyallup_SJB_2019/03" },
  { value: "JBHS4686CF20V4B2M0Z3", text: "Subaru South Blvd_SJB_2016/10" },
  { value: "JB9034Y6RYW06RQZ0P5G", text: "Subaru South Blvd_SJB_2017/02" },
  { value: "JBHQ81K662G4MMWXX73Y", text: "SUN TOYOTA _SJB_2015/10" },
  { value: "JBHL4MB6PJDDGML99ZSC", text: "Suncoast CDJR_SJB_2015-05" },
  { value: "JB3310J6WGTLZY9Y2RKP", text: "Sunset Chevrolet_SJB_2018/07" },
  { value: "JBHN2FL6LF93TVSWMDBQ", text: "SUNSET FORD AUTOMOTIVE_SJB_2015/07" },
  { value: "JB2Y4GM654Y281R84XWG", text: "Sunset Kia_SJB_2018/07" },
  { value: "JB8S0GN5XDD48QXZWYV4", text: "Superstition Springs Chrysler Jeep Dodge_SJB_2016/06" },
  { value: "JB8S0MC6F50HWWNNZJ08", text: "Sutherlin Nissan Mall of Georgia_SJB_2017/06" },
  { value: "JB9064P6JX94XX2N3G1L", text: "Sutton Acura_SJB_2016/08" },
  { value: "JB306TR6K0C767HJ8TB9", text: "Sutton Chevrolet_SJB_2019/03" },
  { value: "JBDH1CV6RCJ99L1SHCYC", text: "Tameron Honda_SJB_2019/01" },
  { value: "JBHR6JM73R9XKXCSC5CJ", text: "Tamiami Automotive Group_SJB_2015/03" },
  { value: "JBHV7MN6ZXDVL73DWZ4F", text: "TAMIAMI FORD INC_SJB_2015/03" },
  { value: "JBHM4SP5W8CHNG4MMCKQ", text: "Tamiami Hyundai _SJB_2015/03" },
  { value: "JB8Q3TW6JM8YY014JTN2", text: "TAMIAMI HYUNDAI_SJB_2015/10" },
  { value: "JBHL210758CGXP2R0D1K", text: "TAYLOR AUTOMOTIVE FAMILY_SJB_2016/01" },
  { value: "JB8Q6NQ6NM7XJBQRS91Z", text: "TAYLOR AUTOMOTIVE FAMILY_SJB_2016/04" },
  { value: "JBHQ5LW6ZYF0S5WBXBHV", text: "TAYLOR CADILLAC KIA HYUNDAI VW_SJB_2016/01" },
  { value: "JB8G2WR67RW9VSRBDKR0", text: "Taylor Dealerships-Athens_SJB_2017/05" },
  { value: "JBHQ2736CZ24PCR50Q0L", text: "Taylor Dealerships_SJB_2016/10" },
  { value: "JB8G39S6YXXBCW5HT2S8", text: "Taylor Honda_SJB_2016/06" },
  { value: "JBD81W16BP317CC6GP3G", text: "Team Chevrolet_SJB_2018/10" },
  { value: "JBHS2DG6C84RQWLQT5CT", text: "Ted Russell Ford Kingston Pike_SJB_2016/06" },
  { value: "JBHL3QW615ZSPY6QW28B", text: "Ted Russell Ford Lincoln_SJB_2016/12" },
  { value: "JBHS0JG640XD7PN2VVJM", text: "Texas Medical Distributors_SJB_2018/01" },
  { value: "JBGX5PC691DXM6M8K48L", text: "The Autobarn City Mazda" },
  { value: "JBJ50YD6CC35102FH209", text: "The Autobarn Volkswagen of Evanston_SJB_2017/12" },
  { value: "JBCH8C36GCFF4LX2T2N9", text: "The Autobarn_SJB_2018/02" },
  { value: "JBHN538621KJRDWSDQVB", text: "The Collision Barn_SJB_2018/02" },
  { value: "JBHT37M69PWGFL9M969L", text: "The Davey Tree Expert Company_SJB_2016/05" },
  { value: "JB8J01B79R50WT0238CR", text: "The Pink Plumber_SJB_2018/03" },
  { value: "JBHT5MT5X5F5SGZYYBGG", text: "The Star Companies_SJB_2018/01" },
  { value: "JBHS6W270DR8KXZ3L8SZ", text: "THOMASVILLE TOYOTA_SJB_2014/11" },
  { value: "JBHT7RR73PBJ7SVVB024", text: "THORNTON ROAD CHRYSLER DODGE JEEP RAM_SJB_2014_12" },
  { value: "JBHS5XH662PDGM7M9H4K", text: "THORNTON ROAD HYUNDAI OF LITHIA SPRINGS _SJB_2016/01" },
  { value: "JBHP47062VLP1C3K0HQZ", text: "Thornton Road KIA_SJB_2016/11" },
  { value: "JB8G62Z5WR83J4BBVNKR", text: "TOYOTA KNOXVILLE_SJB_2016/03" },
  { value: "JBDH0PM71LK77962SCSP", text: "Toyota Of Fort Worth_SJB_2018/07" },
  { value: "JBD85PQ6N7XBZHKGVKGF", text: "Toyota of Kirkland_SJB_2018/05" },
  { value: "JB8G44H772QBKX3KW2ZT", text: "Toyota of Mcdonough+_SJB_2016/08" },
  { value: "JBHM7Z36S5WQXLC5Z344", text: "Toyota of Murfreesboro_SJB_2017/04" },
  { value: "JBHM2C76J3JNN4RG3CNF", text: "Toyota of Renton_SJB_2018/05" },
  { value: "JBHT0PS5Y5978TF6QT6C", text: "Toyota of Stamford_SJB_2017/12" },
  { value: "JBD90NN69MX9BVSWJPYH", text: "Toyota of Stamford_SJB_2018/10" },
  { value: "JBD71Z365GSC6JNSHPRH", text: "Toyota on Western_SJB_2019/02" },
  { value: "JBHP62K6FTKNW4BTLFQB", text: "TOYOTA SOUTH_SJB_2015/03" },
  { value: "JB2R6N46B3FJHLM9XGLW", text: "TRAC Solutions_SJB_2018/05" },
  { value: "JBHP61F6KTYV4SZ181HF", text: "Tradition Automotive Group_SJB_2016/09" },
  { value: "JBHS2NY6YML3HDWDFS81", text: "Tri-City Ford_SJB_2016/11" },
  { value: "JBHM72L6YK1JMC8HTDTR", text: "TRONCALLI AUTO GROUP_SJB_2016/03" },
  { value: "JBHV6V75XYDRYH5R6DVB", text: "Twin City Dealerships_SJB_2016/11" },
  { value: "JBHT0N16HZS4FKGQR76K", text: "U.S. AUTO SALES_SJB_2015/02" },
  { value: "JB305Q55YCJHNNXD48T5", text: "Umansky Auto Group_SJB_2018/12" },
  { value: "JB2P34Q60XBPZ05SZHBF", text: "Umansky Chrysler Dodge Jeep Ram_SJB_2018/10" },
  { value: "JB2V3RK659VYCHN3QTCK", text: "Umansky Honda_SJB_2018/10" },
  { value: "JBD76Q16V5JFNB7CCDKB", text: "Umansky Motor Cars of Milwaukee_SJB_2019/02" },
  { value: "JBDD70V74XZ8BFGT1JRJ", text: "Umansky Subaru_SJB_2018/10" },
  { value: "JB2Z2KM730LFV8KC36SL", text: "Umansky Toyota_SJB_2018/10" },
  { value: "JB8G1NW6P1HXXY7S3SQN", text: "UNION COUNTY KIA_SJB_2015/11" },
  { value: "JBGW3S26574QXYTYLDG6", text: "Union Park Automotive Group_SJB_2017/10" },
  { value: "JB8J4VL73BL74613DX0G", text: "Union Park Automotive Group_SJB_2017/10" },
  { value: "JBHN7TY6GPT1JL2ZR3Y5", text: "Union Park Volvo Cars_SJB_2018/03" },
  { value: "JBHT77577V86LPSBJ0JT", text: "UNITED BMW GWINNETT_SJB_2016/05" },
  { value: "JB8S3V36BRTV4BDBTQVL", text: "UNIVERSITY FORD KIA DURHAM_SJB_2016/05" },
  { value: "JB305NL6MCPFH0XM1TYR", text: "US Adventure RV_SJB_2018/10" },
  { value: "JBHV3MC740XX5NJC7XG2", text: "USAUTO INC_SJB_2016/03" },
  { value: "JBHQ7KX63MJVM3QX7054", text: "Vaden Assurance_SJB_2018/01" },
  { value: "JB9087X6LVBRWWYSBD60", text: "Vaden Automotive Group_SJB_2016/08" },
  { value: "JBHL17W64795D3QPJZK6", text: "Vaden Nissan of Hilton Head_SJB_2016/11" },
  { value: "JB8S660763Q1S53TQNCP", text: "Vaden Nissan of Hinesville_SJB_2016/09" },
  { value: "JB8Q1777959VKRCSXXBS", text: "Vaden Nissan of Stateboro_SJB_2017/03" },
  { value: "JBHN1WC6HQ6NZTTSNXSM", text: "Vaden Nissan_SJB_2016/03" },
  { value: "JB8J0Q369HKNHBW3P0H6", text: "VADEN OF BEAUFORT_SJB_2015/11" },
  { value: "JBHN0D66WJ7YCYXP78DP", text: "Vaden Toyota of Sylacauga_SJB_2016/08" },
  { value: "JBHQ5XZ73H6T2K6HZSDZ", text: "VANN YORK AUTO GROUP_SJB_2014/12" },
  { value: "JB8G10P782MGM7MHB1J0", text: "Vann York Toyota_SJB_2017/02" },
  { value: "JBD87LR6SJY3B2CRXZNG", text: "Venice Toyota_SJB_2018/07" },
  { value: "JBDH0XJ77WPKMHZMX295", text: "Victory Ford_SJB_2018/10" },
  { value: "JBHM2Q871YLH08R0192G", text: "Vision Ford Lincoln Hyundai_SJB_2017/05" },
  { value: "JBJ51QG634HNDBT3K68R", text: "Vogt RV Centers_SJB_2018/02" },
  { value: "JBHV2YP68ZRMGKS348P5", text: "VOGT RV_SJB_2015/06" },
  { value: "JBJ561X6SSGKQJ0SXJTB", text: "Volkswagen of Athens_SJB_2017/02" },
  { value: "JB8J3VJ60H12JDY64SV8", text: "Volkswagen of Athens_SJB_2017/02" },
  { value: "JBD67P873MVF5C80HHQX", text: "Volkswagen of Kirkland_SJB_2018/10" },
  { value: "JBGW3ZM69QWYM6PWR3WW", text: "Volkswagen of Marietta_SJB_2017/06" },
  { value: "JB8G78X634GZB244SWZ5", text: "Volvo Cars Glen Cove_SJB_2017/06" },
  { value: "JBHS07972RJR0QLFTZ27", text: "VOLVO CARS WINSTON-SALEM_SJB_2018/01" },
  { value: "JBHP3876KR1RSR90P8BR", text: "Volvo of Virginia Beach_SJB_2017/02" },
  { value: "JB2S3WF6GCPN64GYT1V3", text: "VW of Kirkland_SJB_2018/06" },
  { value: "JBHP15K70HZW619GRF3M", text: "WADE FORD_SJB_2016/03" },
  { value: "JBHM1ZQ636VS7BRQZJ76", text: "Walker Chevrolet_SJB_2016/11" },
  { value: "JBHR0VX651NRRTZTQ7PX", text: "Walters Gaiennie Automotive Group_SJB_2016/10" },
  { value: "JB8S20W65NVTFXQ8HRMY", text: "WESLEY CHAPEL TOYOTA_SJB_2015/10" },
  { value: "JBHQ6CG6WBBRR2C1DT6B", text: "WESLEY CHAPEL TOYOTA_SJB_2015/10" },
  { value: "JBHV8GR6GP6Q91X4MZ52", text: "WEST PALM BEACH NISSAN_SJB_2015/09" },
  { value: "JB8Q1T66S3WPK9V0XLGK", text: "West Palm Beach Nissan_SJB_2016/06" },
  { value: "JB9054067KVJ8FQY4KMF", text: "WG Automotive Group_SJB_2016/10" },
  { value: "JBHL2JQ6CDG1YRM0F251", text: "Wholesale Unlimited_SJB_2018/05" },
  { value: "JB9088T784Q2CKFHW2J9", text: "Wiginton Fire Systems_SJB_2017/10" },
  { value: "JB8J0SZ683TTBP7R1XQY", text: "WILLIAMS BUICK GMC_SJB_2015/10" },
  { value: "JB8Q1496CPZ6FD56H0J4", text: "Williams Subaru_SJB_2016/11" },
  { value: "JBHM663789DM4YLM2V8P", text: "Wolfchase CJDR_SJB_2016/06" },
  { value: "JBHT4ZW68B98971B5GXK", text: "Wolfchase Toyota Scion_SJB_2016/05" },
  { value: "JB2R3RG75T5RG7PGD2KP", text: "Woolley Home Solutions_SJB_2018/05" },
  { value: "JB8J48H6X195YNFF7XCT", text: "World Ford - Pensacola_SJB_2016/01" },
  { value: "JBHR3N96X964DNTBT0ZL", text: "WORLD FORD PENSACOLA_SJB_2015" },
  { value: "JBHM2F961KYNF12LZ7WL", text: "WYNNE FORD VOLVO _SJB_2015/11" },
  { value: "JBHT0LS6D8SFL8X4DHS2", text: "Wyoming Valley Motors_SJB_2014/12" },
  { value: "JBHV4JB75XTBD96QRKK4", text: "Youngs RV Centers_SJB_2015/10" },
  { value: "JB8G5V06P8J8JH7Y9X5T", text: "Yuma Honda_SJB_2017/06" },
];
