// Hooks
import { useState } from "react";

// Formik
import { Field, FieldArray } from "formik";

// Components
import FormInput from "../../Form/FormInput";
import Tooltip from "../../Tooltip/Tooltip";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

// Assets
import { MdDeleteForever as DeleteHighlightIcon } from "react-icons/md";

// Interfaces
import { BenefitsSectionProps } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const BenefitsSection: React.FC<BenefitsSectionProps> = ({ benefits }) => {
  /*===========================
    DOUBLE CONFIRMATION MODAL

    Display a double confirmation modal if
    the user wants to remove an existing benefit
  ============================*/
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState<boolean>(false);
  const [targetedBenefitIndex, setTargetedBenefitIndex] = useState<number | null>(null);

  // Reset to default values when the modal is closed
  const handleCloseDeleteModal = () => {
    setShowConfirmDeletionModal(false);
    setTargetedBenefitIndex(null);
  };

  return (
    <>
      <FieldArray
        name="benefits"
        render={arrayHelpers => (
          <div className="my--30">
            <h5 className="d-flex flex-column flex-md-row justify-content-between align-items-center fw--semibold">
              <span className="mb--md--10">
                Benefits
                <em className="fw--medium txt--md txt--light-gray ml--5">
                  (Example: Medical, Dental, Life & Disability Plans)
                </em>
              </span>

              {benefits.length < 9 && (
                <Button
                  className="btn btn--text btn--text--secondary btn--text--inherit"
                  type="button"
                  onClick={() => arrayHelpers.push({ data: "" })}
                >
                  + Add Benefit <em>&nbsp;(9 max)</em>{" "}
                </Button>
              )}
            </h5>

            <div className="row">
              {benefits.length > 0 &&
                benefits.map((_, benefitIndex: number) => (
                  <div
                    className="col-12 col-lg-6 col-xl-4 my--10"
                    key={`benefits-section-${benefitIndex}`}
                  >
                    <h6 className="career-pages__field__title">
                      Benefit {benefitIndex + 1}
                      <Tooltip text="Remove Benefit" size="md">
                        <DeleteHighlightIcon
                          className="career-pages__delete"
                          data-testid="component:career-pages-benefits-section-delete-btn"
                          onClick={() => {
                            setShowConfirmDeletionModal(true);
                            setTargetedBenefitIndex(benefitIndex);
                          }}
                        />
                      </Tooltip>
                    </h6>

                    <Field
                      component={FormInput}
                      id={`benefits.${benefitIndex}.data`}
                      name={`benefits.${benefitIndex}.data`}
                      placeholder="Benefit Example"
                    />
                  </div>
                ))}
            </div>

            <AnimatePresence>
              {showConfirmDeletionModal && (
                <Modal
                  title="Remove Benefit?"
                  text="This action will remove the targeted Benefit from the Career Page. Are you sure you want to remove it?"
                  modifierClass="modal--md modal--fixated"
                  handleCloseModal={handleCloseDeleteModal}
                >
                  <div className="modal__actions">
                    <Button
                      modifierClass="btn--fluid btn--primary"
                      onClick={handleCloseDeleteModal}
                      type="button"
                    >
                      Cancel
                    </Button>

                    <Button
                      modifierClass="txt--capitalize btn--fluid btn--secondary"
                      type="button"
                      onClick={() => {
                        // If there's no valid benefit index value, prevent action from executing
                        if (targetedBenefitIndex == null) return;

                        // Remove the targeted benefit from the array and close the modal
                        arrayHelpers.remove(targetedBenefitIndex);
                        handleCloseDeleteModal();
                      }}
                    >
                      Yes, Remove Benefit
                    </Button>
                  </div>
                </Modal>
              )}
            </AnimatePresence>
          </div>
        )}
      />
    </>
  );
};

export default BenefitsSection;
