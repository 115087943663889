// Components
import { NavLink } from "react-router-dom";
import InputFieldSearch from "../Inputs/InputFieldSearch";

// Interfaces
import { JobAdHeaderProps } from "./interfaces";

const JobAdHeader = ({ handleSearch }: JobAdHeaderProps) => {
  return (
    <div className="job-ads__header-container">
      <div className="job-ads__header">
        <div className="d-flex align-items-center">
          <NavLink to="/job-ads/overview/" className="job-ads__header__link">
            Overview
          </NavLink>
          <NavLink to="/job-ads/ads/" className="job-ads__header__link">
            Ads
          </NavLink>
          <NavLink to="/job-ads/clients/" className="job-ads__header__link">
            Clients
          </NavLink>
        </div>

        {handleSearch ? (
          <InputFieldSearch
            placeholder="Search"
            size="lg"
            handleOnSearch={(searched: string) => handleSearch && handleSearch(searched)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default JobAdHeader;
