// Hooks
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useMarketingBannersClose } from "../../api/Marketing/Banners";
import { useTour } from "../../providers/tour-context";
import useInterval from "../../hooks/useInterval";
import useErrorReporting from "../../hooks/useErrorReporting";
import parse from "html-react-parser";

// Assets
import { IoClose as MarketingBannerCloseIcon } from "react-icons/io5";
import { FaChevronUp as BannerPrev, FaChevronDown as BannerNext } from "react-icons/fa";

// Framer
import { FRAMER_MARKETING_BANNER_ANIMATION } from "../../constants/framer";

// Components
import AnimateHeight from "react-animate-height";

// Interfaces
import { BannersListResponse } from "../../api/Marketing/interfaces";

function MarketingBanner({
  banners,
  showBannerOnPageLoad,
}: {
  banners: BannersListResponse[];
  showBannerOnPageLoad: boolean;
}) {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { isTourRunning } = useTour();
  const errorReporting = useErrorReporting();

  useEffect(() => {
    setShowBanner(showBannerOnPageLoad);
  }, [showBannerOnPageLoad]);

  // Reset the currently shown banner to the first one in the list
  // if the list of banners has been updated in the meantime
  useEffect(() => {
    setCurrentShownBannerIndex(0);
  }, [banners]);

  /*==============================
    CYCLE TROUGH AVAILABLE BANNERS
  ===============================*/
  const [pauseInterval, setPauseInterval] = useState(false);
  const [currentShownBannerIndex, setCurrentShownBannerIndex] = useState(0);

  useInterval(
    () => {
      let prevIndex = currentShownBannerIndex;

      // Only start cycling trough the banners if there's more than 1 item available
      if (banners.length > 1) prevIndex++;

      // Once the current cycle iterration exceeds the number of received banners,
      // reset back to the first one in the received list
      if (prevIndex > banners.length - 1) prevIndex = 0;

      setCurrentShownBannerIndex(prevIndex);
    },
    pauseInterval ? null : 10_000,
  );

  /*==============================
    CLOSE TARGETED BANNER
  ===============================*/
  const closeBanners = useMarketingBannersClose();
  const handleCloseBanners = async () => {
    try {
      setShowBanner(false);
      await closeBanners.mutateAsync();
    } catch (error) {
      errorReporting("Failed closing all banners!", error);
    }
  };

  /*==============================
    BANNER MANUAL MOVEMENT
  ===============================*/
  const [disableBannerCycleButtons, setDisableBannerCycleButtons] = useState<boolean>(false);

  const handleGoToPreviousBanner = () => {
    // Prevent any banner cycle actions (both manual and auto-cycling)
    if (disableBannerCycleButtons) return;
    setPauseInterval(true);
    setDisableBannerCycleButtons(true);

    // Go to the previous banner in the list
    if (currentShownBannerIndex === 0) {
      setCurrentShownBannerIndex(banners.length - 1);
    } else {
      setCurrentShownBannerIndex(currentShownBannerIndex - 1);
    }
  };

  const handleGoToNextBanner = () => {
    // Prevent any banner cycle actions (both manual and auto-cycling)
    if (disableBannerCycleButtons) return;
    setPauseInterval(true);
    setDisableBannerCycleButtons(true);

    // Go to the next banner in the list
    if (currentShownBannerIndex === banners.length - 1) {
      setCurrentShownBannerIndex(0);
    } else {
      setCurrentShownBannerIndex(currentShownBannerIndex + 1);
    }
  };

  // Re-enable the manual movement buttons and the
  // auto-cycling of the banners after 1s to avoid users spamming the actions
  useEffect(() => {
    if (!disableBannerCycleButtons) return;

    const disableBtnsTimeout = setTimeout(() => {
      setDisableBannerCycleButtons(false);
      setPauseInterval(false);
    }, 1000);

    return () => clearTimeout(disableBtnsTimeout);
  }, [disableBannerCycleButtons]);

  // If there are no banners to be shown, do not render anything
  if (isTourRunning || !banners.length || !banners[currentShownBannerIndex]) {
    return null;
  }

  return (
    <AnimateHeight
      className="marketing-banner__wrapper"
      duration={500}
      height={showBanner ? "auto" : 0}
    >
      <div className="marketing-banner" data-testid="component:marketing-banner">
        <div className="marketing-banner__content">
          <AnimatePresence mode="wait" initial={false} custom={currentShownBannerIndex}>
            <motion.div
              key={currentShownBannerIndex}
              custom={currentShownBannerIndex}
              variants={FRAMER_MARKETING_BANNER_ANIMATION}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                y: { type: "spring" },
                opacity: { duration: 0.5 },
              }}
              className="marketing-banner__message"
            >
              {banners[currentShownBannerIndex].hyperlink ? (
                <a href={banners[currentShownBannerIndex].hyperlink} target="_blank">
                  {parse(banners[currentShownBannerIndex].text)}
                </a>
              ) : (
                <>{parse(banners[currentShownBannerIndex].text)}</>
              )}
            </motion.div>
          </AnimatePresence>

          {banners.length > 1 ? (
            <div className="marketing-banner__step">
              <span>
                {currentShownBannerIndex + 1} / {banners.length}
              </span>
              <div className="d-flex flex-column">
                <BannerPrev onClick={handleGoToPreviousBanner} />
                <BannerNext onClick={handleGoToNextBanner} />
              </div>
            </div>
          ) : null}

          <div
            className="marketing-banner__close"
            onClick={handleCloseBanners}
            data-testid="component:marketing-banner-close"
          >
            <MarketingBannerCloseIcon />
          </div>
        </div>
      </div>
    </AnimateHeight>
  );
}

export default MarketingBanner;
