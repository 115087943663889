/*============================
 AD POSTER: JOB BOARDS
=============================*/
export const JOB_BOARD_FORM_CAREERBUILDER_DEFAULTS = {
  enabled: false, // Required
  ad_title: "", // Required
  display_city: "",
  display_company: "",
  job_skin: "",
  category1: "", // Required
  category2: "", // Optional
  category3: "", // Optional
  industry1: "", // Required
  industry2: "", // Optional
  industry3: "", // Optional
  education_level: "", // Optional
  employment_type: "", // Optional
  base_pay_low: "",
  base_pay_high: "",
  base_pay_rate: "",
  bonus_pay: "",
  commission_pay: "",
  other_pay: "",
  min_experience: "",
  max_experience: "",
  managing_others: "",
  travel_required: "",
  relocation_covered: "",
};

export const JOB_BOARD_FORM_ZIP_RECRUITER_DEFAULTS = {
  enabled: false,
  sponsored: false,
  // Temporarily Disabled
  // campaign: "",
  ad_title: "", // Required,
  category_id: "", // Optional
  job_status: "", // Optional
  experience_level: "", // Optional
  education_level: "", // Optional,
  compensation_type: "", // Optional,
  compensation_min: "", // Optional; number
  compensation_max: "", // Optional; number
  has_commission: false, // Optional; boolean
  resume_not_required: false, // Optional; boolean
  benefits_dental: false, // Optional; boolean
  benefits_medical: false, // Optional; boolean
  benefits_vision: false, // Optional; boolean
  benefits_life_insurance: false, // Optional; boolean
  benefits_retirement_savings: false, // Optional; boolean
  screening_enabled: false, // Optional; boolean
  screening_questions: [{ question: "", is_required: "no", preferred_answers: [] }],
};

export const JOB_BOARD_FORM_INDEED_DEFAULTS = {
  enabled: false,
  ad_title: "", // Required,
  salary: "", // Optional
  experience_level: "", // Optional
  job_status: "", // Optional,
  category1: "", // Optional
  category2: "", // Optional
  category3: "", // Optional
  education_level: "", // Optional,
  promote_on_indeed: "no", // Required
  indeed_budget: null, // Optional
  indeed_duration: "", // Optional
  state: null,
};

export const JOB_BOARD_FORM_CRAIGSLIST_DEFAULTS = {
  enabled: false, // Required
};

export const JOB_BOARD_FORM_SNAG_A_JOB_DEFAULTS = {
  enabled: false, // Required
  ad_title: "", // Required,
  display_company: "",
  industry: "", // Required
  employment_type: "",
};

export const JOB_BOARD_FORM_LINKEDIN_DEFAULTS = {
  enabled: false, // Required
  ad_title: "", // Required
  display_company: "",
  industry: "",
  experience_level: "NOT_APPLICABLE",
};

export const JOB_BOARD_FORM_APPCAST_DEFAULTS = {
  enabled: false, // Required
  ad_title: "", // Required
  display_company: "",
  category: "",
  posting_country: "USA",
};

export const JOB_BOARD_FORM_TALENT_DEFAULTS = {
  enabled: false, // Required
  ad_title: "", // Required
  category: "",
  budget: null,
};

export const JOB_BOARD_FORM_GLASSDOOR_DEFAULTS = {
  enabled: false,
  ad_title: "", // Required
  category: "", // Optional
  job_industry: "", // Optional
};

export const JOB_BOARD_FORM_FIRSTCHOICE_DEFAULTS = {
  enabled: false,
  ad_title: "", // Required
  salary_from: "", // Required
  salary_to: "", // Required
  salary_period: "", // Required
  job_type: "", // Required
  categories: [], // Required
  remote: false, // Optional
  featured: false, // Optional
};

/**
 *
 * An array of strings representing the countries for which the "salary" field
 * from the "Indeed" job board form will be marked as "required" due to legislation requirements.
 *
 */
export const JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD: string[] = ["NY", "CA"];
