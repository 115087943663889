// Utilities & Hooks
import { Field, Form, Formik } from "formik";
import { useUsersGetList } from "../../../api/Users/Users";
import { useMemo } from "react";
import { format } from "date-fns";
import { useAppointmentsScheduleInPerson } from "../../../api/Appointments/Appointments";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";

// Schemas
import { APPLICATIONS_SCHEDULE_MEETING_IN_PERSON } from "../../../schemas/ApplicationsSchemas";

// Components
import Button from "../../Button/Button";
import Datepicker from "../../Datepicker/Datepicker";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormTextarea from "../../Form/FormTextarea";
import { motion } from "framer-motion";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { InPersonAppointmentFormFields, InPersonAppointmentProps } from "./interfaces";
import { FRAMER_SCHEDULE_MEETING_ANIMATION } from "../../../constants/framer";

const InPersonAppointment = ({
  applicationID,
  handleCloseMenu,
  framerAnimationProps,
}: InPersonAppointmentProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    LIST OF INTERVIEWERS
  ========================*/
  const { data: interviewersData, isLoading: interviewersDataIsLoading } = useUsersGetList();

  const INTERVIEWERS: DropdownItem[] = useMemo(() => {
    if (!interviewersData || !interviewersData.length || interviewersDataIsLoading) return [];

    // Map the list of company users (interviewers) into dropdown items
    const mappedInterviewers: DropdownItem[] = interviewersData
      .filter(interviewer => interviewer.status === "active")
      .map(interviewer => {
        return {
          text: handleFullnameCombination(interviewer),
          value: interviewer.id,
        };
      })
      .sort((interviewerA, interviewerB) => {
        return interviewerA.text.toLowerCase() > interviewerB.text.toLowerCase() ? 1 : -1;
      });

    return mappedInterviewers;
  }, [interviewersData]);

  /*==================================
    SCHEDULE "IN-PERSON" APPOINTMENT
  ===================================*/
  const scheduleInPersonAppointment = useAppointmentsScheduleInPerson();

  const handleInPersonAppointment = async (values: InPersonAppointmentFormFields) => {
    // Exit function if there's no valid interviewer selection
    if (values.interviewer_id === null) return;

    try {
      const { interviewer_id, appointment_time, notes } = values;

      // Convert the selected date & time value so it also shows the hours in 12h mode with AM/PM
      const CONVERTED_APPOINTMENT_TIMESTAMP: string = format(
        appointment_time,
        "MM/dd/yyyy hh:mm a",
      );

      // Send the API Request
      await scheduleInPersonAppointment.mutateAsync({
        application_id: applicationID.toString(),
        type: "in-person",
        appointment_time: CONVERTED_APPOINTMENT_TIMESTAMP,
        interviewer_id,
        ...(notes && { notes }),
      });

      // Close the menu after the appointment was made
      handleCloseMenu();
    } catch (error) {
      errorReporting("Failed scheduling 'InPerson' appointment.", error, { ...values });
    }
  };

  return (
    <motion.div
      key="framer-schedule-meeting-in-person"
      variants={FRAMER_SCHEDULE_MEETING_ANIMATION}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: 1, type: "spring" }}
      custom={framerAnimationProps}
    >
      <Formik
        initialValues={{ interviewer_id: null, appointment_time: new Date(), notes: undefined }}
        validationSchema={APPLICATIONS_SCHEDULE_MEETING_IN_PERSON}
        onSubmit={handleInPersonAppointment}
      >
        {({ setFieldValue }) => (
          <Form>
            <Field
              component={FormDropdownSearchable}
              id="interviewer_id"
              name="interviewer_id"
              label="Meeting With"
              placeholder="Select Interviewer"
              items={INTERVIEWERS}
              isLoading={interviewersDataIsLoading}
              disabled={interviewersDataIsLoading || INTERVIEWERS.length === 0}
              size="full"
              handleFieldUpdate={(meetingWith: DropdownItem) =>
                setFieldValue("interviewer_id", meetingWith.value)
              }
              modifierClass="mb--20"
            />

            <label className="dropdown__label">Date and Time</label>
            <Datepicker
              modifierClass="datepicker--full mb--20"
              handleSelectedDate={dates => setFieldValue("appointment_time", dates[0])}
            />

            <Field
              component={FormTextarea}
              id="notes"
              name="notes"
              placeholder="Type your notes here"
              label="Appointment Notes"
              rows={6}
              modifierClass="mb--20"
            />

            <div className="d-flex flex-column-reverse flex-md-row justify-content-md-center align-items-center">
              <Button
                type="button"
                modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary--light"
                onClick={() => handleCloseMenu()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary ml--10 ml--md--0 mb--md--10"
                isLoading={scheduleInPersonAppointment.isLoading}
                isDisabled={scheduleInPersonAppointment.isLoading}
              >
                Set Appointment
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};

export default InPersonAppointment;
