// Assets
import { useState } from "react";
import EmailIcon from "../../../assets/images/icons/applications-email-icon.svg?react";

// Components
import Tooltip from "../../Tooltip/Tooltip";
import { AnimatePresence } from "framer-motion";
import ApplicationEmailTemplatesModal from "../modals/ApplicationEmailTemplatesModal";
import { ApplicationEmailTemplateDetails } from "../interfaces";

const ApplicationSendEmail = ({ details }: { details: ApplicationEmailTemplateDetails }) => {
  /*===============================
    SEND EMAIL MODAL
  ================================*/
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  return (
    <>
      <Tooltip text="Send Email">
        <div
          className={`applications__socials__icon applications__socials__icon--email ${
            details.applicantEmail ? "applications__socials__icon--active" : ""
          }`}
          data-testid="button:mail-action"
          aria-disabled={details.applicantEmail ? false : true}
          onClick={details.applicantEmail ? () => setShowEmailModal(true) : undefined}
        >
          <EmailIcon />
        </div>
      </Tooltip>

      <AnimatePresence>
        {showEmailModal && (
          <ApplicationEmailTemplatesModal
            details={details}
            handleCloseModal={() => setShowEmailModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ApplicationSendEmail;
