// Components
import { AnimatePresence, motion } from "framer-motion";
import Loader from "../Loader/Loader";

// Interfaces
import { TableNoDataMessageProps } from "./interfaces";

const TableNoDataMessage = ({ message, isRefetching = false }: TableNoDataMessageProps) => {
  return (
    <>
      <AnimatePresence mode="popLayout">
        {!isRefetching ? (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 1, type: "spring" }}
          >
            <h6 className={`table__no-data ${isRefetching ? "table--refetching" : ""}`}>
              {message}
            </h6>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <AnimatePresence mode="popLayout">
        {isRefetching ? (
          <motion.div
            className="d-flex justify-content-center align-items-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 1, type: "spring" }}
          >
            <h6 className={`table__no-data ${isRefetching ? "table--refetching" : ""}`}>
              Fetching data...
            </h6>
            {isRefetching ? <Loader size="sm" modifierWrapper="ml--10" /> : null}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default TableNoDataMessage;
