import Button from "../Button/Button";
import { useBackNavigation } from "../../hooks/useBackNavigation";

const ErrorNotFound = ({ title, fallbackRoute }: { title: string; fallbackRoute: string }) => {
  /*==============================
    CUSTOM BACK NAVIGATION
  ===============================*/
  const handleNavigateBack = useBackNavigation();

  return (
    <div className="error-boundary d-flex flex-column align-items-center justify-content center py--50">
      <h2 className="error-boundary__title">{title}</h2>

      <p className="error-boundary__description">
        The resource that you tried accessing couldn't be found.
      </p>

      <div className="d-flex flex-column align-items-center">
        <Button
          modifierClass="error-boundary__btn"
          onClick={() => handleNavigateBack(fallbackRoute)}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default ErrorNotFound;
