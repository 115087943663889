import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT } from "./constants";

// SCHEMA REGEX
import { SLUG_REGEX_PATTERN, WEBSITE_URL_REGEX_PATTERN } from "./regexes";

/*========================
  ADMIN - CLIENTS
=========================*/
export const ADMIN_CLIENTS_NEW_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required("Please enter the name of the client")
    .max(50, "Maximum of 50 characters allowed!")
    .test("client-name", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  slug: Yup.string()
    .matches(SLUG_REGEX_PATTERN, {
      message: "Slug can only contain letters, numbers, and hyphen (-) characters",
    })
    .required("Please enter slug")
    .max(50, "Maximum of 50 characters allowed!")
    .test("client-slug", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  description: Yup.string()
    .notRequired()
    .max(1024, "Maximum of 1024 characters allowed!")
    .test("client-desc", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  website_url: Yup.string()
    .matches(WEBSITE_URL_REGEX_PATTERN, {
      message: "Invalid website url format",
    })
    .notRequired()
    .max(100, "Maximum of 100 characters allowed!")
    .test("client-url", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  country: Yup.string().required("Please select country."),
  address: Yup.string()
    .notRequired()
    .max(255, "Maximum of 255 characters allowed!")
    .test("client-address", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  city: Yup.string()
    .notRequired()
    .max(30, "Maximum of 30 characters allowed!")
    .test("client-city", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  state: Yup.string()
    .required("Please fill in the field.")
    .max(30, "Maximum of 30 characters allowed!"),
  zip: Yup.string()
    .notRequired()
    .max(6, "Maximum of 6 characters allowed!")
    .test("client-zip", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  phone: Yup.string()
    .notRequired()
    .max(30, "Maximum of 30 characters allowed!")
    .test("client-phone", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  enable_photos: Yup.boolean().required(),
  disable_sbca: Yup.boolean().required(),
});

export const ADMIN_CLIENTS_EDIT_SCHEMA = Yup.object().shape({
  name: Yup.string().notRequired(),
  slug: Yup.string()
    .matches(SLUG_REGEX_PATTERN, {
      message: "Slug can only contain letters, numbers, and hyphen (-) characters",
    })
    .required("Please enter slug")
    .max(50, "Maximum of 50 characters allowed!")
    .test("client-slug", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  description: Yup.string().notRequired(),
  website_url: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  address: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  state: Yup.string().notRequired(),
  zip: Yup.string().notRequired(),
  phone: Yup.string().notRequired(),
  enable_photos: Yup.boolean().notRequired(),
  disable_sbca: Yup.boolean().notRequired(),
});
