// Hooks & Utilities
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useAdminGetAllCompanies } from "../../../api/Company/AdminCompany";
import { useJobAdsGetAllLibraries } from "../../../api/JobAds/JobAdsLibraries";
import { handleDateAsTimestamp } from "../../../utilities/dates/handleDateAsTimestamp";

// Components
import { Field } from "formik";
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import DropdownSearchable from "../../Dropdown/DropdownSearchable";
import FormTextarea from "../../Form/FormTextarea";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { JobAdLibraryProps } from "./interfaces";

const JobAdLibrary = ({
  jobAdClientID,
  salesforceComments,
  handleFetchJobAdLibrary,
  handleSelectedClient,
}: JobAdLibraryProps) => {
  /*==========================
    FETCH LIST OF CLIENTS
  ===========================*/
  const [selectedClientID, setSelectedClientID] = useState<number>(jobAdClientID);
  const { data: clientsData, isLoading: clientsIsLoading } = useAdminGetAllCompanies();

  // Map the received clients data into dropdown items
  const CLIENTS_DATA: DropdownItem[] = useMemo(() => {
    if (!clientsData || !clientsData.length || clientsIsLoading) return [];

    return clientsData
      .map(client => {
        return { text: client.name, value: client.id };
      })
      .sort((clientA, clientB) => {
        return clientA.text.toLowerCase() > clientB.text.toLowerCase() ? 1 : -1;
      });
  }, [clientsData]);

  // Anytime the currently selected client is changed,
  // trigger the callback function that will be used to check whether
  // we're viewing an ad from another client
  useEffect(() => {
    handleSelectedClient(selectedClientID);
  }, [selectedClientID]);

  /*===========================
    FETCH LIST OF AD LIBRARIES
  ============================*/
  const { data: librariesData, isLoading: librariesLoading } =
    useJobAdsGetAllLibraries(selectedClientID);

  const LIBRARIES_DATA: DropdownItem[] = useMemo(() => {
    // Return empty datset if there's no data to work with
    if (!librariesData || !Object.entries(librariesData).length || librariesLoading) return [];

    // Map the received libraries data into dropdown items
    const LIBRARIES_DROPDOWN_ITEMS: DropdownItem[] = librariesData
      .sort((libraryA, libraryB) => (libraryA.timestamp > libraryB.timestamp ? -1 : 1))
      .map(library => {
        const libraryDate: string = format(
          handleDateAsTimestamp(library.timestamp),
          "hh:mma - MM-dd-yyyy",
        );
        const libraryText: string = `${library.title} - ${libraryDate} - ${library.job_code}`;
        return { text: libraryText, value: library.id, sortValue: library.timestamp };
      });

    return LIBRARIES_DROPDOWN_ITEMS;
  }, [librariesData, selectedClientID]);

  /*==========================
    LOAD AD DETAILS
  ===========================*/
  const [selectedJobAdLibrary, setSelectedJobAdLibrary] = useState<number | null>(null);

  // Reset the selected Job Ad Library any time
  // the currently selected client is updated
  useEffect(() => setSelectedJobAdLibrary(null), [selectedClientID]);

  // Trigger fetch of the data for the currently selected job ad library
  const handleLoadAd = () => {
    if (!selectedJobAdLibrary) return;

    handleFetchJobAdLibrary(selectedJobAdLibrary);
  };

  return (
    <div className="col-12 col-lg-6">
      <Card modifierClass="card--padding--sm">
        <h3 className="txt--blue fw--semibold mb--20">Ad Library</h3>

        <DropdownSearchable
          items={CLIENTS_DATA}
          placeholder="Select Client"
          preselectedItemValue={jobAdClientID}
          size="full"
          handleItemSelected={client => setSelectedClientID(client.value as number)}
          isLoading={clientsIsLoading}
          disabled={clientsIsLoading || !CLIENTS_DATA.length}
          modifierClass="mb--20"
        />

        <DropdownSearchable
          key={selectedClientID}
          items={LIBRARIES_DATA}
          placeholder="Select Job Ad Library"
          size="full"
          handleItemSelected={library => setSelectedJobAdLibrary(library.value as number)}
          isLoading={librariesLoading}
          disabled={librariesLoading || !LIBRARIES_DATA.length}
          modifierClass="mb--20"
          sortOnSearch={{ enabled: true }}
        />

        <div className="row">
          <div className="col-12 col-lg-4">
            <p className="w--100 txt--gray--darkest fw--semibold txt--lg">Salesforce Comments:</p>
          </div>

          <div className="col-12 col-lg-8">
            <p className="w--100 txt--left break-word white-space--pre-line">
              {salesforceComments ?? "N/A"}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-4">
            <p
              style={{ maxWidth: "180px" }}
              className="w--100 txt--gray--darkest fw--semibold txt--lg"
            >
              Revision Reason:
            </p>
          </div>
          <div className="col-12 col-lg-8">
            <Field
              component={FormTextarea}
              id="reason"
              name="reason"
              rows={5}
              placeholder="Enter a reason for this revision (optional)"
              modifierClass="mb--10 w--100"
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button
            type="button"
            modifierClass="btn--fluid btn--secondary mb--10"
            onClick={handleLoadAd}
            isDisabled={selectedJobAdLibrary ? false : true}
          >
            Load Ad
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default JobAdLibrary;
