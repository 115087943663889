// Utilities & Hooks
import { useEffect, useRef } from "react";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";

// Interfaces
import { FormInputProps } from "./interfaces";

const FormInput = ({
  form,
  field,
  label = "",
  isRequired = false,
  modifierClass = "",
  description = "",
  size = "full",
  tooltip = null,
  shouldAutofocus = false,
  ...props
}: FormInputProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    TRIM FORM FIELD VALUE
  ================================*/
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue: string = event.target.value.trim();

    // Update the form value to which this field corresponds to
    form.setFieldValue(field.name, trimmedValue);

    // Trigger internal Formik 'onBlur' events for the field
    field.onBlur(event);
  };

  // Automtaically focus on the input field
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!shouldAutofocus || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [shouldAutofocus]);

  /*================================
    INTERNATIONALIZATION
  =================================*/
  const { t } = useTranslation();

  return (
    <div className={`input input--${size} ${modifierClass}`}>
      {label && (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div className="input__wrapper">
        <input
          ref={inputRef}
          {...field}
          {...props}
          onBlur={handleOnBlur}
          className={`input__field ${errors && touched ? "input__field--error" : ""} ${
            tooltip ? "input__field--tooltip" : ""
          }`}
        />

        {/* TOOLTIP ICON WITHIN THE INPUT  */}
        {tooltip && <div className="input__tooltip">{tooltip}</div>}
      </div>
      {description && <p className="input__description">{description}</p>}

      {/* DISPLAY ERROR MESSAGES */}
      {errors && touched && <p className="input__error">{t(errors)}</p>}
    </div>
  );
};

export default FormInput;
