// Hooks & Utilities
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApplicationHistory } from "../../api/Applications/Applications";
import {
  HistoryLogReturn,
  historyLogApplied,
  historyLogBucketChange,
  historyLogHireDateRemoved,
  historyLogHireDateSet,
} from "./history-log";
import handleDataPagination from "../../utilities/data/handleDataPagination";

// Components
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import ComponentErrorBoundary from "../../pages/ErrorBoundary/ComponentErrorBoundary";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

const ApplicationHistory = ({
  registration_date,
  is_hidden,
}: {
  registration_date: string;
  is_hidden: boolean;
}) => {
  const { applicationID } = useParams();

  /*=========================
    APPLICATION ACTION
    HISTORY DATA
  ==========================*/
  const { data, isLoading, isRefetching, error, refetch } = useApplicationHistory(applicationID);
  const [applicationHistory, setApplicationHistory] = useState<HistoryLogReturn[]>([
    // Add the registration date (date when the user has applied)
    // as a default entry to the log history list, which gets
    // overwritten if theres log data received from the server
    historyLogApplied({
      user: null,
      action: "applied",
      reason: null,
      timestamp: new Date(registration_date).getTime() / 1000,
      data: null,
    }),
  ]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);

  useEffect(() => {
    if (!data || !data.length || isLoading || error || isRefetching) return;

    const historyData: HistoryLogReturn[] = [];

    data.forEach(history => {
      switch (history.action) {
        case "bucket-change":
          historyData.push(historyLogBucketChange(history));
          break;
        case "hire-date-removed":
          historyData.push(historyLogHireDateRemoved(history));
          break;
        case "hire-date-set":
          historyData.push(historyLogHireDateSet(history));
          break;
      }
    });

    // Add the registration date (date when the user has applied)
    // as a first action that has happend to the start of the list
    historyData.push(
      historyLogApplied({
        user: null,
        action: "applied",
        reason: null,
        timestamp: new Date(registration_date).getTime() / 1000,
        data: null,
      }),
    );

    // Paginate the data displayed in the UI with up to 20 items per page
    const totalPages: number = Math.ceil(historyData.length / 20);
    const paginatedHistoryData = handleDataPagination(historyData, currentPage, 20);

    setTotalPagesCount(totalPages);
    setApplicationHistory(paginatedHistoryData);
  }, [data, isRefetching, isLoading, currentPage]);

  return (
    <Card modifierClass={`card--rounded ${is_hidden ? "card--hidden" : ""}`}>
      <h2 className="d-flex align-items-center fw--semibold mb--20">
        History
        {isRefetching ? <Loader size="sm" modifierWrapper="ml--10" /> : null}
      </h2>

      {isLoading ? (
        <div className="d-flex align-items-center">
          <p className="mb--0 mr--10">Loading Applicant History...</p>
          <Loader size="sm" />
        </div>
      ) : error ? (
        <div>
          <p className="mb--0">Failed obtaining applicant history data.</p>
          <p className="txt--link" onClick={() => refetch()}>
            Please try again.
          </p>
        </div>
      ) : applicationHistory.length > 0 ? (
        <ul>
          {applicationHistory.map((log, index) => (
            <li className="mb--5 pr--5" key={index}>
              <span className="d-inline-block mb--5">
                <strong>{log.timestamp}</strong>
              </span>
              <br />
              <p className="mb--10">{log.message}</p>
              {index < applicationHistory.length - 1 ? <hr className="mb--10" /> : null}
            </li>
          ))}
        </ul>
      ) : isRefetching ? (
        <h6 className="mb--0">Refetching applicant history data...</h6>
      ) : (
        <h6 className="mb--0">No applicant history data available.</h6>
      )}

      {totalPagesCount > 1 ? (
        <div className="d-flex justify-content-end my--20">
          <Pagination
            pageCount={totalPagesCount}
            currentPage={currentPage}
            handlePageChange={({ selected }) => setCurrentPage(selected)}
          />
        </div>
      ) : null}
    </Card>
  );
};

/** Error fallback component for the Error Boundary used on component-level */
const ApplicationHistoryFallback = ({
  is_hidden,
  handleResetErrorBoundary,
}: {
  is_hidden: boolean;
  handleResetErrorBoundary: () => void;
}) => {
  return (
    <Card modifierClass={`card--rounded ${is_hidden ? "card--hidden" : ""}`}>
      <h2 className="d-flex align-items-center fw--semibold mb--20">History</h2>
      <p className="txt--error fw--semibold">
        There was an error displaying the history log for this applicant.
      </p>
      <Button modifierClass="btn--fluid btn--primary" onClick={handleResetErrorBoundary}>
        Try Again
      </Button>
    </Card>
  );
};

const ApplicationHistoryWrapper = ({
  registration_date,
  is_hidden,
}: {
  registration_date: string;
  is_hidden: boolean;
}) => {
  const [resetKey, setResetKey] = useState<number | null>(null);

  return (
    <PermissionCheckComponentWrapper permissions={["view_application_log"]}>
      <ComponentErrorBoundary
        key={resetKey}
        fallbackUI={
          <ApplicationHistoryFallback
            is_hidden={is_hidden}
            handleResetErrorBoundary={() => setResetKey(Math.random())}
          />
        }
      >
        <ApplicationHistory is_hidden={is_hidden} registration_date={registration_date} />
      </ComponentErrorBoundary>
    </PermissionCheckComponentWrapper>
  );
};

export default ApplicationHistoryWrapper;
