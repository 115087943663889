import { useState } from "react";
// import { DropdownItem } from "../../Dropdown/interfaces";

type ApplicationsPositionFilteringHookReturnType = [string, (position: string) => void];

/**
 *
 * Application-specific hook for handling the filtering of the listed Application,
 * based on the currently selected `Position` from the associated dropdown menu,
 * returning in the UI only the Application with the matching position value.
 *
 */
export function useApplicationsPositionFiltering(): ApplicationsPositionFilteringHookReturnType {
  const [applicationPositionFilter, setApplicationPositionFilter] = useState<string>("all");
  const handleApplicationPositionFilter = (position: string) => {
    setApplicationPositionFilter(position);
  };

  return [applicationPositionFilter, handleApplicationPositionFilter];
}
