// Interfaces
import { AssessmentScoreSummaryProps } from "./interfaces";
import { AssessmentDetailsFinalThoughtsFields } from "../../../api/Assessment/interfaces";

const AssessmentScoreSummary = ({ data }: AssessmentScoreSummaryProps) => {
  return (
    <>
      {data.map((item: AssessmentDetailsFinalThoughtsFields, index: number) => (
        <div key={`assessment-score-${index}`} className="assessment-row">
          <div className={`assessment-score assessment-score--${item.class}`}>{item.score}</div>
          <div className="assessment-row__content">
            <h4>
              <strong>{item.label}</strong>
            </h4>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default AssessmentScoreSummary;
