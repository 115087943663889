import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const ArticleDetailsSkeleton = () => {
  return (
    <div className="row" data-testid="component:article-details-skeleton">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb--10">
          <Skeleton width={100} height={20} />
        </div>
      </div>
      <div className="col-12 col-lg-8">
        <Card modifierClass="card--padding--xl card--rounded">
          <div className="row align-items-center mb--20">
            <div className="col-12 col-md-6">
              <Skeleton width="100%" height={220} className="mb--20" />
            </div>

            <div className="col-12 col-md-6">
              <Skeleton width={250} height={20} />

              <div className="d-flex align-items-center my--10">
                <Skeleton width={100} height={20} className="mr--10" />
                <Skeleton width={100} height={20} />
              </div>
            </div>
          </div>

          <Skeleton width={500} height={20} className="mb--5" />
          <Skeleton width={350} height={20} className="mb--5" />
          <Skeleton width={250} height={20} className="mb--5" />
          <Skeleton width={400} height={20} className="mb--5" />
          <Skeleton width={280} height={20} className="mb--5" />
        </Card>
      </div>

      <div className="col-12 col-lg-4">
        <Card modifierClass="articles-other card--rounded">
          <div className="articles-other__title">
            <Skeleton width={150} height={30} />
          </div>

          <div className="articles-other__list">
            <div className="articles-other__item">
              <Skeleton circle width={85} height={85} className="mr--15" />

              <div className="d-flex flex-column">
                <Skeleton width={150} height={20} />
                <Skeleton width={80} height={10} />
              </div>
            </div>

            <div className="articles-other__item">
              <Skeleton circle width={85} height={85} className="mr--15" />

              <div className="d-flex flex-column">
                <Skeleton width={150} height={20} />
                <Skeleton width={80} height={10} />
              </div>
            </div>

            <div className="articles-other__item">
              <Skeleton circle width={85} height={85} className="mr--15" />

              <div className="d-flex flex-column">
                <Skeleton width={150} height={20} />
                <Skeleton width={80} height={10} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
export default ArticleDetailsSkeleton;
