import { useEffect, useState } from "react";
import { ApplicationsSelectionIndicatorProps } from "./interfaces";
import { uniq } from "lodash-es";

const ApplicationsSelectionIndicator = ({
  selectedApplicationsIDs,
  paginatedApplications,
  bucketApplications,
  applicationsBucketFilter,
  isDataFiltered,
  filteredApplications,
  setSelectedApplicationsIDs,
}: ApplicationsSelectionIndicatorProps) => {
  const [isWholeBucketSelected, setIsWholeBucketSelected] = useState(false);
  const [isWholePageSelected, setIsWholePageSelected] = useState(false);
  const [isTotalOverlapShown, setIsTotalOverlapShown] = useState(false);

  useEffect(() => {
    if (!bucketApplications.length) {
      setIsWholeBucketSelected(false);
      setIsWholePageSelected(false);
      return;
    }

    /*====================================
    Compare selection & selection overlap
    ====================================*/
    const WHOLE_AVAILABLE_DATA_SELECTED = isDataFiltered
      ? filteredApplications.every(application => {
          return selectedApplicationsIDs.includes(application);
        })
      : JSON.stringify(selectedApplicationsIDs.sort()) ===
        JSON.stringify(bucketApplications.sort());

    const WHOLE_PAGE_SELECTED = paginatedApplications.every((applicationID: number) => {
      return selectedApplicationsIDs.includes(applicationID);
    });

    // NOTE: We use this to handle a scenario where the whole current page is selected, but there are also selections from other pages
    const TOTAL_OVERLAP_SHOWN_SELECTED =
      JSON.stringify(selectedApplicationsIDs.sort()) ===
      JSON.stringify(paginatedApplications.sort());

    /*=========================================
    Update state according to above comparisons
    =========================================*/
    setIsWholeBucketSelected(WHOLE_AVAILABLE_DATA_SELECTED);

    setIsWholePageSelected(WHOLE_PAGE_SELECTED);

    setIsTotalOverlapShown(TOTAL_OVERLAP_SHOWN_SELECTED);
  }, [
    selectedApplicationsIDs,
    paginatedApplications,
    bucketApplications,
    filteredApplications,
    isDataFiltered,
  ]);

  const handleBucketApplicationSelection = (action: "select" | "deselect") => {
    if (action === "select") {
      // Select ALL applications in bucket
      setSelectedApplicationsIDs(
        isDataFiltered
          ? uniq([...selectedApplicationsIDs, ...filteredApplications])
          : uniq([...selectedApplicationsIDs, ...bucketApplications]),
      );
    } else if (action === "deselect") {
      // Empty selection
      setSelectedApplicationsIDs([]);
    }
  };

  return !isDataFiltered ? (
    <div className="applications__selection__details">
      <span className="w--100 txt--xl">
        {isWholeBucketSelected ? (
          <>
            All <strong className="txt--black">{selectedApplicationsIDs.length}</strong>{" "}
            applications in <strong className="txt--black">{applicationsBucketFilter}</strong>{" "}
            bucket are selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
              <strong>Clear Selection?</strong>
            </a>
          </>
        ) : isWholePageSelected && isTotalOverlapShown ? (
          <>
            All <strong className="txt--black">{paginatedApplications.length}</strong> applications
            on this page are selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("select")}>
              <strong>
                Select all {bucketApplications.length} in {applicationsBucketFilter} bucket?
              </strong>
            </a>
          </>
        ) : isWholePageSelected && !isTotalOverlapShown && !isWholeBucketSelected ? (
          <>
            Total <strong className="txt--black">{selectedApplicationsIDs.length}</strong> selected,
            including all <strong className="txt--black">{paginatedApplications.length}</strong>{" "}
            applications on this page are selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("select")}>
              <strong>
                Select all {bucketApplications.length} in {applicationsBucketFilter} bucket?
              </strong>
            </a>
          </>
        ) : null}

        {!isWholeBucketSelected && !isWholePageSelected && !isTotalOverlapShown ? (
          <>
            <strong className="txt--black">{selectedApplicationsIDs.length}</strong>{" "}
            {` application${selectedApplicationsIDs.length > 1 ? "s" : ""} `}
            selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
              <strong>Clear selection?</strong>
            </a>
          </>
        ) : null}
      </span>
    </div>
  ) : (
    <div className="applications__selection__details">
      <span className="w--100 txt--xl">
        {isWholeBucketSelected &&
        JSON.stringify(selectedApplicationsIDs.sort()) ===
          JSON.stringify(filteredApplications.sort()) ? (
          <>
            All <strong className="txt--black">{selectedApplicationsIDs.length}</strong> filtered
            applications are selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
              <strong>Clear Selection?</strong>
            </a>
          </>
        ) : isWholeBucketSelected ? (
          <>
            Total <strong className="txt--black">{selectedApplicationsIDs.length}</strong> filtered
            applications selected, including all{" "}
            <strong className="txt--black">{filteredApplications.length}</strong> applications with
            current filter.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
              <strong>Clear Selection?</strong>
            </a>
          </>
        ) : null}

        {isWholePageSelected && isTotalOverlapShown && !isWholeBucketSelected ? (
          <>
            All <strong className="txt--black">{paginatedApplications.length}</strong> filtered
            applications on this page are selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("select")}>
              <strong>Select all {filteredApplications.length} filtered applications?</strong>
            </a>
          </>
        ) : null}

        {isWholePageSelected && !isTotalOverlapShown && !isWholeBucketSelected ? (
          <>
            Total <strong className="txt--black">{selectedApplicationsIDs.length}</strong> filtered
            applications selected, including all{" "}
            <strong className="txt--black">{paginatedApplications.length}</strong> applications on
            this page are selected.{" "}
            {JSON.stringify(selectedApplicationsIDs.sort()) !==
            JSON.stringify(filteredApplications.sort()) ? (
              <a className="txt--link" onClick={() => handleBucketApplicationSelection("select")}>
                <strong>Select all {filteredApplications.length} filtered applications?</strong>
              </a>
            ) : (
              <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
                <strong>Clear Selection?</strong>
              </a>
            )}
          </>
        ) : null}

        {!isWholeBucketSelected && !isWholePageSelected && !isTotalOverlapShown ? (
          <>
            <strong className="txt--black">{selectedApplicationsIDs.length}</strong>{" "}
            {` filtered application${selectedApplicationsIDs.length > 1 ? "s" : ""} `}
            selected.{" "}
            <a className="txt--link" onClick={() => handleBucketApplicationSelection("deselect")}>
              <strong>Clear selection?</strong>
            </a>
          </>
        ) : null}
      </span>
    </div>
  );
};

export default ApplicationsSelectionIndicator;
