// Pages & Layout
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import BannersPage from "../pages/Marketing/Banners";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_MARKETING: ApplicationRouteProps[] = [
  {
    path: "/marketing/banners",
    element: (
      <LayoutAuthenticated>
        <BannersPage />
      </LayoutAuthenticated>
    ),
    permissions: ["marketing_banners_view"],
    type: "protected",
  },
];
