import { API_BASE_URL } from "../config/config";
import { AuthenticationAPIResponse } from "./types";

// Utilities
import { LocalStorageActions } from "../utilities/handleLocalStorage";
import handleErrorMessage from "../utilities/handleErrorMessage";

/**
 *
 * Utility function for sending a request to the server
 * which should generate new access and refresh tokens that
 * guarantee usage of the application as long as the user is authenticated
 *
 */
export async function tokenRefresher() {
  // Get the tokens from local storage
  const accessToken = LocalStorageActions.getItem("accessToken");
  const refreshToken = LocalStorageActions.getItem("refreshToken");
  const tokenExpiresIn = LocalStorageActions.getItem("expiresIn") || 0;
  const isAutologin = LocalStorageActions.getItem("autologin") || false;

  try {
    // If user used the "autologin" feature, do not try to refresh any tokens
    if (isAutologin) return { accessToken };

    // If user manually logged in, but there are no tokens to work with, throw an error
    if (!accessToken || !refreshToken) {
      throw new Error("Could not refresh the access token.");
    }

    // If there's a valid access token, and the expiry date is not yet reached,
    // just return the access token instead of sending additional requests
    if (accessToken && tokenExpiresIn) {
      const currentTimestamp: number = new Date().getTime();

      // We should check the expiration date of the token
      // 10 minutes before it is set to expire
      const tokenExpirationEarlyCheck: number = tokenExpiresIn - 1000 * 60 * 10;

      // If the current timestamp hasn't yet reached the early expiration check,
      // just return the existing access token that we already have in local storage
      if (tokenExpirationEarlyCheck >= currentTimestamp) {
        return { accessToken, refreshToken };
      }
    }

    // Send a request to the API to obtain the new tokens
    const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    const { access_token, refresh_token, expires_in }: AuthenticationAPIResponse =
      await response.json();

    // Throw an error if the response had some issue
    if (!response.ok) throw new Error("Could not refresh the access token. Please try again.");

    // Save the tokens and expiration timestamp values in local storage
    // after successfully refreshing the active access token
    handleAuthenticationTokensInLocalStorage(access_token, refresh_token, expires_in);

    return { access_token, refresh_token };
  } catch (error) {
    const errorMessage: string = handleErrorMessage(error);

    // In case of an error when trying to refresh the access token,
    // which at the same time means the user should not be authenticated anymore,
    // remove the existing token values from storage and reload page
    handleAuthenticationTokensRemovalFromStorage();

    // Redirect the user to the login page containing info about the message to be shown
    const redirectURL = `${location.pathname}${location.search}`;
    location.replace(`/login/?message=expired_token&redirectTo=${encodeURIComponent(redirectURL)}`);

    throw new Error(errorMessage);
  }
}

/**
 *
 * Handle saving the received tokens and expiration timestamp into local storage
 *
 * @param accessToken The access token value received from the API upon login / token refresh.
 * @param refreshToken The refresh token value received from the API upon login / token refresh.
 * @param expiresIn The expiration timestamp (representing `in how much time will the token expire`)
 * received from the API upon login / token refresh.
 *
 */
export function handleAuthenticationTokensInLocalStorage(
  accessToken: string,
  refreshToken: string,
  expiresIn: number,
  autologin?: boolean,
) {
  // Save the newly issued tokens in local storage
  LocalStorageActions.saveItem("accessToken", accessToken);
  LocalStorageActions.saveItem("refreshToken", refreshToken);

  // If user used the "autologin" feature, save the value in local storage so it can be reused
  if (autologin) LocalStorageActions.saveItem("autologin", autologin);

  // Save the expiration date in local storage as a sum
  // of the current timestamp and the expiration timestamp received from the API
  const tokenExpirationSum: number = new Date().getTime() + expiresIn * 1000;
  LocalStorageActions.saveItem("expiresIn", tokenExpirationSum);
}

/**
 *
 * Handle removal of tokens and expiration timestamp that are saved in local storage.
 * This function will be triggered in the following scenarios:
 * - Upon manual `logout` by the user.
 * - Upon error scenario in the `tokenRefresher` function, failing to refresh the
 * currently active access token.
 *
 */
export function handleAuthenticationTokensRemovalFromStorage() {
  LocalStorageActions.removeItem("accessToken");
  LocalStorageActions.removeItem("refreshToken");
  LocalStorageActions.removeItem("expiresIn");
  LocalStorageActions.removeItem("autologin");
}
