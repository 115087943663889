import * as Yup from "yup";
import { handleCheckStringForHTML } from "../utilities/strings/handleCheckStringForHTML";
import { SCHEMAS_NO_HTML_MESSAGE_TEXT, SCHEMAS_PASSWORD_MESSAGE } from "./constants";

// SCHEMA REGEX
import { PASSWORD_REGEX_PATTERN } from "./regexes";

export const AUTHENTICATION_LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});

export const AUTHENTICATION_REGISTER_SCHEMA = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter your first name")
    .max(30, "Maximum of 30 characters allowed!")
    .test("auth-register-fname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  last_name: Yup.string()
    .required("Please enter your last name")
    .max(30, "Maximum of 30 characters allowed!")
    .test("auth-register-lname", SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
      return !handleCheckStringForHTML(value as string);
    }),
  password: Yup.string()
    .matches(PASSWORD_REGEX_PATTERN, {
      message: SCHEMAS_PASSWORD_MESSAGE,
    })
    .min(8, "Your password needs to be at least 8 characters long.")
    .max(50, "Maximum of 50 characters allowed!")
    .required("Please enter your password"),
  password_confirmation: Yup.string().when("password", {
    is: (password: string) => !!password,
    then: schema =>
      schema
        .oneOf([Yup.ref("password")], "Passwords do not match.")
        .required("Please confirm the password"),
  }),
});

export const AUTHENTICATION_FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email address."),
});

export const AUTHENTICATION_RESET_PASSWORD_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .matches(PASSWORD_REGEX_PATTERN, {
      message: SCHEMAS_PASSWORD_MESSAGE,
    })
    .min(8, "Your password needs to be at least 8 characters long.")
    .max(50, "Maximum of 50 characters allowed!")
    .required("Please enter your password"),
  password_confirmation: Yup.string().when("password", {
    is: (password: string) => (password && password.length > 0 ? true : false),
    then: schema =>
      schema
        .oneOf([Yup.ref("password")], "Passwords do not match.")
        .required("Please confirm the password"),
  }),
});
