// Utilities & Hooks
import { useState } from "react";
import { format } from "date-fns";
import { useApplicationCommentDelete } from "../../api/Applications/Comments/Comments";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import { useAuth } from "../../providers/auth-context";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { AnimatePresence } from "framer-motion";
import Tooltip from "../Tooltip/Tooltip";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { motion } from "framer-motion";

// Interfaces
import { ApplicationCommentProps } from "./interfaces";

// Assets
import EditIcon from "../../assets/images/icons/edit-icon.svg?react";
import DeleteIcon from "../../assets/images/icons/appointment-delete-icon.svg?react";
import { AiFillStar as RatingIcon } from "react-icons/ai";

const ApplicationComment = ({
  applicationID,
  comment,
  index,
  handleCommentEdit,
}: ApplicationCommentProps) => {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  /*===============================
    COMMENT ACTIONS (EDIT, DELETE)
  ================================*/
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteComment = useApplicationCommentDelete();

  const handleCommentDelete = async () => {
    // Prevent comment from being deleted if there's no valid
    // application ID value received for the application that we're viewing
    if (!applicationID) return;

    try {
      setShowDeleteModal(false);

      await deleteComment.mutateAsync({ comment_id: comment.id, application_id: applicationID });
    } catch (error) {
      errorReporting("Failed deleting application comment", error, {
        comment_id: comment.id,
        application_id: applicationID,
      });
    }
  };

  // Do not try to render any content if theres
  // no valid "comment" prop data received
  if (!comment) return null;

  return (
    <>
      <motion.div
        className="applications-comment"
        key={`framer-motion-application-details-comment-${index}`}
        initial={{ opacity: 0, translateY: "15px" }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, transition: { delayChildren: 0, delay: 0 } }}
        transition={{ type: "spring" }}
      >
        <div className="applications-comment__details">
          <div className="applications-comment__header">
            <h5>{comment.author}</h5>

            {comment.rating ? (
              <Tooltip text="Rating (out of 5)" size="md">
                <div className="applications-comment__rating">
                  <RatingIcon />
                  <span>{comment.rating}</span>
                </div>
              </Tooltip>
            ) : null}
          </div>

          <p className={`applications-comment__time ${!comment.content ? "mb--0" : ""}`}>
            <i>
              {format(handleDateAsTimestamp(comment.updated_timestamp), "MM/dd/yyyy @ hh:mm a")}
            </i>
          </p>

          <div className="applications-comment__content pr--10">{comment.content}</div>
        </div>

        {user.id === comment.author_id || user.role.toLowerCase() === "super admin" ? (
          <motion.div
            className="applications-comment__actions"
            initial={{ opacity: 0, translateY: "-50px" }}
            animate={{ opacity: comment.id ? 1 : 0, translateY: comment.id ? 0 : "-50px" }}
            transition={{ type: "spring" }}
          >
            <Tooltip text="Edit Comment" size="md">
              <EditIcon
                className={`applications-comment__actions__icon ${
                  !comment.id ? "applications-comment__actions__icon--disabled" : ""
                }`}
                onClick={() => {
                  // Prevent opening "Edit" menu if there's no ID available
                  if (!comment.id) return;

                  handleCommentEdit(comment.id);
                }}
                data-testid="button:application-comment-edit"
              />
            </Tooltip>

            <Tooltip text="Delete Comment" size="md" wrapperModifierClass="ml--10">
              <DeleteIcon
                className={`applications-comment__actions__icon ${
                  !comment.id ? "applications-comment__actions__icon--disabled" : ""
                }`}
                onClick={() => {
                  // Prevent opening the modal if there's no ID available
                  if (!comment.id) return;
                  setShowDeleteModal(true);
                }}
                data-testid="button:application-comment-delete"
              />
            </Tooltip>
          </motion.div>
        ) : null}
      </motion.div>

      <AnimatePresence>
        {showDeleteModal && (
          <Modal
            title="Delete comment?"
            text={
              <>
                This action will delete the targeted comment. <br /> Are you sure you want to
                continue?
              </>
            }
            handleCloseModal={() => setShowDeleteModal(false)}
            modifierClass="modal--md modal--fixated"
            testID="modal-delete-comment"
          >
            <div className="modal__actions">
              <Button
                modifierClass="btn--fluid btn--primary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>

              <Button
                modifierClass="btn--fluid btn--secondary"
                onClick={handleCommentDelete}
                isLoading={deleteComment.isLoading}
                isDisabled={deleteComment.isLoading}
              >
                Yes, Delete Comment
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default ApplicationComment;
