// Hooks & Utilities
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  usePrivilegesGetAll,
  usePrivilegesGroupEdit,
  usePrivilegesGroupsCreate,
  usePrivilegesGroupsGetSpecific,
} from "../../api/Privileges/Privileges";
import { handleCheckPrivilegesSelection, handlePrivilegesCheckboxPreselection } from "./utils";
import useErrorReporting from "../../hooks/useErrorReporting";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Components
import { ErrorMessage, Field, Form, Formik } from "formik";
import ContentHeader from "../../components/Content/ContentHeader";
import FormInput from "../../components/Form/FormInput";
import Toggle from "../../components/Toggle/Toggle";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import FormCheckbox from "../../components/Form/FormCheckbox";
import PrivilegesCrudSkeleton from "./Skeletons/PrivilegesCrudSkeleton";

// Interfaces
import { PrivilegesCrudFormFields } from "../../api/Privileges/interfaces";

// Schemas
import { PRIVILEGES_GROUPS_CRUD_SCHEMA } from "../../schemas/PrivilegesSchemas";

const PrivilegesGroupCrud = () => {
  const errorReporting = useErrorReporting();

  /*=============================
    ROUTE PARAMETRS

    Controls whether the page is in
    "create" or "edit" mode
  ===============================*/
  const { groupID } = useParams();
  const navigate = useNavigate();

  /*==============================
    FETCH THE EXISTING PRIVILEGES
    AND GENERATE FORM FIELDS
  ===============================*/
  const { data: privilegesData, isLoading: privilegesLoading } = usePrivilegesGetAll();

  const PRIVILEGES_LIST = useMemo(() => {
    // Exit function and return default dataset if there's no data yet
    if (!privilegesData || !Object.entries(privilegesData).length || privilegesLoading) {
      return { admin: [], public: [] };
    }

    return { admin: privilegesData.Admin, public: privilegesData.Public };
  }, [privilegesData]);

  /*==============================
    PRIVILEGES GROUP FORM
  ================================ */
  const formikRef = useRef<any>();
  const {
    data: groupData,
    isLoading: groupLoading,
    isFetching: groupFetching,
  } = usePrivilegesGroupsGetSpecific(groupID);

  const [privilegesForm, setPrivilegesForm] = useState<PrivilegesCrudFormFields>({
    name: "",
    description: "",
    is_admin_only: false,
    permissions: [],
  });

  // Re-initialize the form fields after fetching the group's data when in "edit" mode
  useEffect(() => {
    if (!groupData || !Object.entries(groupData).length || groupLoading) return;

    // Update the state when data is fetched
    setPrivilegesForm(groupData);
  }, [groupData]);

  // After re-initializing the privileges form values
  // check if at least one of the selected privileges is part of the "admin-only" group
  useEffect(() => {
    handlePrivilegesAdminSelection(privilegesForm.permissions);
  }, [privilegesForm]);

  /*==============================
    PRIVILEGES SELECTION 
  ===============================*/
  const [isAdminOnlyManuallyToggled, setIsAdminOnlyManuallyToggled] = useState<boolean>(false);
  const [isAdminOnlyToggleDisabled, setIsAdminOnlyToggleDisabled] = useState<boolean>(false);

  // Handle the selection of the targeted privilege
  const handlePrivilegesIDSelection = (privilegeID: number, isChecked: boolean) => {
    // Exit function if there's no reference to work with yet
    if (!formikRef.current) return;

    const { values, setFieldValue } = formikRef.current;
    let selectedPrivilegeIDs: number[] = [...values.permissions];

    // Handle selection and de-selection of privileges from the list
    if (isChecked) {
      selectedPrivilegeIDs.push(privilegeID);
    } else {
      selectedPrivilegeIDs = selectedPrivilegeIDs.filter((id: number) => id !== privilegeID);
    }

    // Update the list of selected privileges
    setFieldValue("permissions", selectedPrivilegeIDs);

    // Check if at least one of the selected privileges is part
    // of the "admin-only" privileges
    handlePrivilegesAdminSelection(selectedPrivilegeIDs);
  };

  // Check if *at least one* of the selected privileges is
  // part of the "admin-only" privileges and if so, trigger the toggle button
  const handlePrivilegesAdminSelection = (selectedPrivilegeIDs: number[]) => {
    // Exit function if there's no reference to work with yet
    if (!formikRef.current) return;

    const { setFieldValue } = formikRef.current;
    const selectedAdminPrivilege: boolean = handleCheckPrivilegesSelection(
      PRIVILEGES_LIST.admin,
      selectedPrivilegeIDs,
    );

    setIsAdminOnlyToggleDisabled(selectedAdminPrivilege);

    // Only re-initialize the value for this field if the
    // toggle button was not activated by the user manually
    if (!isAdminOnlyManuallyToggled) setFieldValue("is_admin_only", selectedAdminPrivilege);
  };

  /*==============================
    HANDLE FORM SUBMISSION
  ===============================*/
  const createPrivilegesGroup = usePrivilegesGroupsCreate();
  const updatePrivilegesGroup = usePrivilegesGroupEdit(groupID);

  const handlePrivilegesForm = async (values: PrivilegesCrudFormFields) => {
    try {
      // If a "groupID" route parameter is present, send "PUT" request to edit the group
      // Otherwise send a "POST" request to create a new privileges group
      if (groupID) {
        await updatePrivilegesGroup.mutateAsync(values);
      } else {
        await createPrivilegesGroup.mutateAsync(values);

        // Redirect the user back to the privileges groups listing page
        navigate("/privileges/groups/");
      }
    } catch (error) {
      errorReporting(`Failed ${groupID ? "updating" : "creating"} privileges groups`, error, {
        ...values,
      });
    }
  };

  return (
    <div className="container py--25">
      <ContentHeader title={groupID ? "Edit Group" : "Create Group"} />
      <Link
        to="/privileges/groups/"
        className="d-inline-flex align-items-center txt--lg mt--20 mb--40"
      >
        <BackIcon className="txt--grey" />
        <span className="txt--blue fw--regular ml--10">Back</span>
      </Link>

      {privilegesLoading || groupFetching ? (
        <PrivilegesCrudSkeleton />
      ) : (
        <Formik
          initialValues={privilegesForm}
          enableReinitialize
          onSubmit={handlePrivilegesForm}
          innerRef={formikRef}
          validationSchema={PRIVILEGES_GROUPS_CRUD_SCHEMA}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Field
                component={FormInput}
                label="Group Name"
                id="name"
                name="name"
                placeholder="Enter Group's Name"
                modifierClass="input--default-label mb--30"
              />

              <Field
                component={FormInput}
                label="Group Description"
                id="description"
                name="description"
                placeholder="Enter Group's Description"
                modifierClass="input--default-label mb--30"
              />

              <Toggle
                id="is_admin_only"
                name="is_admin_only"
                textLeft="Public"
                textRight="Admin"
                isToggled={values.is_admin_only}
                handleOnChange={event => {
                  setFieldValue("is_admin_only", event.target.checked);
                  setIsAdminOnlyManuallyToggled(event.target.checked);
                }}
                modifierClass="mb--20"
                isDisabled={isAdminOnlyToggleDisabled}
              />

              <Card modifierClass="card--padding--xl p--relative">
                {/* PUBLIC PRIVILEGES */}
                <h3 className="txt--blue-light fw--semibold mb--20">Public Privileges List</h3>
                <p className="txt--black mb--40">
                  Reference list of all the <strong>public</strong> privileges currently available
                  on the system.
                </p>

                {Object.entries(PRIVILEGES_LIST.public).length > 0 ? (
                  <div className="row mb--30">
                    {Object.entries(PRIVILEGES_LIST.public).map(privilege => {
                      const privilegeGroupname: string = privilege[0];

                      return (
                        <div
                          className="col-12 col-md-6 col-lg-3 mb--25"
                          key={`privileges-public-${privilegeGroupname}`}
                        >
                          <h6 className="mb--10">
                            <strong>{privilegeGroupname}</strong>
                          </h6>
                          <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                          {Object.values(privilege[1]).map(privilegeValue => {
                            return (
                              <Field
                                key={`privileges-public-${privilegeValue.id}`}
                                component={FormCheckbox}
                                name={`permissions.${privilegeValue.id}`}
                                id={`permissions.${privilegeValue.id}`}
                                label={privilegeValue.label}
                                labelModifierClass="input__label--sm mb--0i"
                                modifierClass="mt--0i mb--0i"
                                handleCheckboxNonFormAction={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handlePrivilegesIDSelection(
                                    privilegeValue.id,
                                    event.target.checked,
                                  );
                                }}
                                checked={handlePrivilegesCheckboxPreselection(
                                  privilegeValue.id,
                                  values.permissions,
                                )}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="txt--black">No privileges available.</p>
                )}

                {/* ADMIN-ONLY PRIVILEGES */}
                <h3 className="txt--blue-light fw--semibold mb--20">Admin Privileges List</h3>
                <p className="txt--black mb--40">
                  Reference list of all the <strong>admin</strong> privileges currently available on
                  the system.
                </p>

                {Object.entries(PRIVILEGES_LIST.admin).length > 0 ? (
                  <div className="row">
                    {Object.entries(PRIVILEGES_LIST.admin).map(privilege => {
                      const privilegeGroupname: string = privilege[0];

                      return (
                        <div
                          className="col-12 col-md-6 col-lg-3 mb--25"
                          key={`privileges-admin-${privilegeGroupname}`}
                        >
                          <h6 className="mb--10">
                            <strong>{privilegeGroupname}</strong>
                          </h6>
                          <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                          {Object.values(privilege[1]).map(privilegeValue => {
                            return (
                              <Field
                                key={`privileges-admin-${privilegeValue.id}`}
                                component={FormCheckbox}
                                name={`permissions.${privilegeValue.id}`}
                                id={`permissions.${privilegeValue.id}`}
                                label={privilegeValue.label}
                                labelModifierClass="input__label--sm mb--0i"
                                modifierClass="mt--0i mb--0i"
                                handleCheckboxNonFormAction={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handlePrivilegesIDSelection(
                                    privilegeValue.id,
                                    event.target.checked,
                                  );
                                }}
                                checked={handlePrivilegesCheckboxPreselection(
                                  privilegeValue.id,
                                  values.permissions,
                                )}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="txt--black">No privileges available.</p>
                )}
              </Card>

              {errors.permissions && touched.permissions ? (
                <span className="d-block input__error" style={{ fontSize: "14px" }}>
                  <ErrorMessage name="permissions" />
                </span>
              ) : null}

              <Button
                modifierClass="btn--fluid btn--primary my--30"
                isLoading={createPrivilegesGroup.isLoading || updatePrivilegesGroup.isLoading}
                isDisabled={createPrivilegesGroup.isLoading || updatePrivilegesGroup.isLoading}
              >
                {groupID ? "Edit" : "Create"} Group
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PrivilegesGroupCrud;
