import { CareerPagesFeaturedHighlights } from "../interfaces";

/**
 *
 * Filter out the "Featured Highlights" from the payload that will
 * be sent in the request to the API, so that the only featured highlights
 * that will be included will be those with valid "id" and "image" values.
 *
 * Note: The rest of the fields (headline_1, headline_2, and body) will always be present anyways.
 *
 * @param featured A list of featured highlights coming from Formik's form values.
 *
 * @returns Either an empty array, or an array of Featured Highlights with valid values.
 */
export function handleCareerPagesFilteredHighlightsData(
  featured: CareerPagesFeaturedHighlights[],
): CareerPagesFeaturedHighlights[] {
  const filteredHighlights = featured.filter(featuredHighlight => {
    const modifiedFeaturedHighlight = { ...featuredHighlight };

    // Remove unnecessary properties
    delete modifiedFeaturedHighlight.ordinality;
    delete modifiedFeaturedHighlight.image_initial_value;

    // Include the featured highlights that were populated on "Client Side"
    // as these will always have the "image" field as a File and their "id" will be null
    if (
      modifiedFeaturedHighlight.id === null &&
      modifiedFeaturedHighlight.headline_1 &&
      modifiedFeaturedHighlight.headline_2 &&
      modifiedFeaturedHighlight.body &&
      modifiedFeaturedHighlight.image
    ) {
      return true;
    }

    // Include the featured highlights that were prepopulated with values
    // received from the API, as these will always have a valid "id" value
    if (
      modifiedFeaturedHighlight.id != null &&
      modifiedFeaturedHighlight.headline_1 &&
      modifiedFeaturedHighlight.headline_2 &&
      modifiedFeaturedHighlight.body
    ) {
      return true;
    }
  });

  return filteredHighlights;
}
