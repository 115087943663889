// Utilities & Hooks
import { useState } from "react";
import { useApplicationRequestSBCA } from "../../../api/Applications/Applications";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../Button/Button";
import FormTextarea from "../../Form/FormTextarea";
import Modal from "../../Modal/Modal";

// Interfaces
import { ApplicationModalSBCAProps } from "../interfaces";

// Schemas
import { APPLICATIONS_SBCA_MODAL } from "../../../schemas/ApplicationsSchemas";
import ApplicationModalSuccessfulRequest from "./ApplicationModalSuccessfulRequest";
import { AnimatePresence } from "framer-motion";

const ApplicationModalSBCA = ({
  applicationID,
  applicantName,
  applicantEmail,
  handleCloseModal,
}: ApplicationModalSBCAProps) => {
  const errorReporting = useErrorReporting();

  /*===============================
    REQUEST SBCA
  ================================*/
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const requestSBCA = useApplicationRequestSBCA();

  const handleRequestSBCA = async (values: FormikValues) => {
    try {
      await requestSBCA.mutateAsync({ id: values.id, message: values.message });

      // Display the "Success" modal after the request has completed
      setShowSuccessModal(true);
    } catch (error) {
      errorReporting("Failed requesting application SBCA", error, {
        application_id: applicationID,
        applicant_name: applicantName,
        applicant_email: applicantEmail,
      });
    }
  };

  return (
    <>
      {!showSuccessModal ? (
        <Modal
          title="Request Assessment"
          text=""
          modifierClass="modal--fixated application-action-modal"
          handleCloseModal={handleCloseModal}
          testID="modal-request-sbca"
        >
          <h6>
            <strong>Applicant:</strong> {applicantName}
          </h6>
          <h6>
            <strong>Email:</strong> {applicantEmail}
          </h6>

          <Formik
            initialValues={{
              id: applicationID,
              message:
                "Hello again! We got your application, but you still need to take our candidate assessment. Please click below to complete your application and then you're all set!",
            }}
            enableReinitialize
            validationSchema={APPLICATIONS_SBCA_MODAL}
            onSubmit={handleRequestSBCA}
          >
            <Form>
              <Field
                id="message"
                name="message"
                placeholder="Your message to the applicant"
                modifierClass="mb--20"
                rows={7}
                component={FormTextarea}
              />

              <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
                <Button
                  type="button"
                  modifierClass="btn--fluid btn--primary--light"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
                  isLoading={requestSBCA.isLoading}
                  isDisabled={requestSBCA.isLoading}
                >
                  Request SBCA
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal>
      ) : null}

      <AnimatePresence>
        {showSuccessModal ? (
          <ApplicationModalSuccessfulRequest
            title="Request Assessment"
            text="An email has been sent containing a link for the applicant to complete their assessment."
            handleCloseModal={handleCloseModal}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default ApplicationModalSBCA;
