import { SbcaScoreType } from "../../../api/SBCA/interfaces";

export const SBCA_MAIN_TRAITS: Record<SbcaScoreType, "Emotions" | "Ego" | "Logic"> = {
  caregiver: "Emotions",
  thinker: "Ego",
  processor: "Logic",
};

export const SBCA_MAIN_RANGE = {
  caregiver: "20.00-30.00",
  thinker: "30.00-40.00",
  processor: "30.00-40.00",
};

export const SBCA_MAIN_DESCRIPTION = {
  caregiver:
    "The <strong>Caregiver’s</strong> overall qualities are modesty, supportiveness, optimism and loyalty. His core strengths are adaptability, flexibility and willingness to serve. They are comfortable doing favors, complimenting others and making people feel welcome. When it comes to action they work hard and get things done for others. They do not ever want to be put on the spot, be seen as weak, submissive or selfish. Under stress they may withdraw or pretend the cause of the stress does not exist. They are de-motivated by tense situations, hostility, and condescension.",
  thinker:
    "The <strong>Thinker’s</strong> overall qualities are confidence, ambition, competitiveness, and forcefulness. They are comfortable directing things, achieving goals and assuming leadership. Some of their core strengths are self confidence, capacity to produce and persuasiveness. They are creative and think on their feet. They like to take the lead, initiate conversation, direct others and press for results. Many times they end up dominating a conversation. They like to have control over every situation. They can be too persistent at times and overconfident. It is very difficult for them to admit to a mistake.",
  processor:
    "The <strong>Processor</strong> is analytical, methodical and independent. He is comfortable managing people and providing resources and factual ideas. He tries to put things in perspective and is very inquisitive when trying to find solutions for problems. They will only do what they are capable of doing and are well balanced in their viewpoints. They are very intelligent, organized and calculating. Under stress they will analyze where it comes from and try to logically resolve it. Their main focus in a working environment is to be respected, treated fairly and to feel important.",
};

export const SBCA_PRINT_TARGET = [
  {
    label: "Perceptive",
    target: "3.00-5.00",
  },
  {
    label: "Initiator Index",
    target: "3.00-5.00",
  },
  {
    label: "Leadership",
    target: "3.00-5.00",
  },
  {
    label: "Sales Drive",
    target: "3.00-5.00",
  },
  {
    label: "Confidence Level",
    target: "3.00-5.00",
  },
  {
    label: "Organization Level",
    target: "3.00-6.00",
  },
  {
    label: "Persistence",
    target: "3.00-5.00",
  },
  {
    label: "Learning",
    target: "3.00-5.00",
  },
  {
    label: "Manageability",
    target: "3.00-5.00",
  },
  {
    label: "Sociable",
    target: "0.00-3.50",
  },
  {
    label: "Assertiveness",
    target: "3.00-4.50",
  },
  {
    label: "Accommodating",
    target: "3.00-5.00",
  },
  {
    label: "Emotional Control",
    target: "0.00-4.00",
  },
  {
    label: "Rejection Level",
    target: "0.00-5.00",
  },
  {
    label: "Customer Sensitivity",
    target: "0.00-3.50",
  },
  {
    label: "Stress",
    target: "0.00-4.00",
  },
  {
    label: "Attitude",
    target: "0.00-4.00",
  },
];
