// Utilities & Hooks
import { useEffect } from "react";
import { useAuth } from "../../providers/auth-context";
import { useTour } from "../../providers/tour-context";
import { ProductTourWrapperProps, ProductTourIds } from "./interfaces";
import { useNavigate } from "react-router-dom";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";

// Components
import ReactJoyride, { ACTIONS, CallBackProps, STATUS } from "react-joyride";
import ProductTourTooltip from "./ProductTourTooltip";

// Constants
import { PRODUCT_TOURS_DATA } from "./constants";

const ProductTourWrapper = ({
  tourToShow,
  isLoading = false,
  isDisabled = false,
  dynamicTour = null,
}: ProductTourWrapperProps) => {
  const {
    isTourRunning,
    activeTour,
    tourCurrentStep,
    handleTourStart,
    handleTourEnd,
    handleDynamicTour,
  } = useTour();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !tourToShow) return;

    /** The ID of the currently unwatched tour */
    const unwatchedTour: ProductTourIds | undefined = user.unwatched_product_tours
      .map(tour => tour.name as ProductTourIds)
      .filter(tour => tour.toLowerCase() === tourToShow.toLowerCase())[0];

    /** The ID of the manually started tour to be watched */
    const startedTourId: string | undefined = LocalStorageActions.getItem("activeProductTour");

    /*==================================
      DYNAMIC TOURS

      Pre-defined tour that consists of steps
      that use dynamic data from the API.
    ===================================*/
    if (dynamicTour) {
      // When user manually started the dynamic tour
      if (startedTourId === dynamicTour.tourId) {
        handleDynamicTour(dynamicTour);
        return;
      }

      // When user has the dynamic tour on the unwatched tours list
      if (unwatchedTour && unwatchedTour === dynamicTour.tourId) {
        handleDynamicTour(dynamicTour);
        return;
      }
    }

    /*==================================
      STATIC TOURS

      Pre-defined tours that consists of steps 
      that use static data.
    ===================================*/
    const matchingPredefinedTour = PRODUCT_TOURS_DATA.find(tour => tour.tourId === startedTourId);

    // When user manually started the static tour
    if (startedTourId && matchingPredefinedTour) {
      handleTourStart(matchingPredefinedTour.tourId);
      return;
    }

    // When user has the static tour on the unwatched tours list
    if (unwatchedTour) {
      handleTourStart(unwatchedTour);
      return;
    }
  }, [user, dynamicTour]);

  // Manually controll the movement of the product tour
  const handleTourCallback = (data: CallBackProps) => {
    // Control navigating to another page if such metadata is included in the step
    if (data.lifecycle === "complete" && data.step.data) {
      const { next, prev } = data.step.data;

      if (data.action === "next" && next?.link) navigate(next.link);
      if (data.action === "prev" && prev?.link) navigate(prev.link);
    }

    // Control when the tour is closed
    if (
      ([STATUS.ERROR, STATUS.SKIPPED, ACTIONS.CLOSE, STATUS.FINISHED] as string[]).includes(
        data.status,
      ) ||
      data.action === ACTIONS.CLOSE
    ) {
      handleTourEnd();
    }
  };

  // If there's no active tour at the moment, do not render anything
  if (!activeTour) return null;

  // Only render the wrapper for the matching active tour
  if (activeTour.tourId !== tourToShow) return null;

  return !isLoading || !isDisabled ? (
    <div>
      <div className="joyride-tooltip__custom-overlay"></div>
      <ReactJoyride
        tooltipComponent={ProductTourTooltip}
        callback={handleTourCallback}
        run={isTourRunning}
        steps={activeTour ? activeTour.steps : []}
        continuous
        showProgress={true}
        key={activeTour?.tourId}
        stepIndex={activeTour?.type == "manual" ? tourCurrentStep : undefined}
        disableOverlayClose
        disableCloseOnEsc
      />
    </div>
  ) : null;
};

export default ProductTourWrapper;
