import Skeleton from "react-loading-skeleton";

const BucketsLoadingPlacholder = () => {
  return (
    <div
      className="d-flex flex-column flex-md-row justify-content-between align-items-center w--100"
      data-testid="component:buckets-tabs-skeleton"
    >
      <div className="buckets__item">
        <Skeleton width={100} height={20} />
      </div>
      <div className="buckets__item">
        <Skeleton width={130} height={20} />
      </div>
      <div className="buckets__item">
        <Skeleton width={130} height={20} />
      </div>
      <div className="buckets__item">
        <Skeleton width={130} height={20} />
      </div>
    </div>
  );
};

export default BucketsLoadingPlacholder;
