// Utilities & Hooks
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import { handleDistanceToNow } from "../../utilities/dates/handleDistanceToNow";
import { useArticlesMarkNotificationAsRead } from "../../api/Articles/Articles";

// Interfaces
import { ArticleNotificationProps } from "./interfaces";

const ArticleNotification = ({
  id,
  create_date,
  title,
  summary,
  feature_image,
  read = false,
  handleNotificationsMenu,
}: ArticleNotificationProps) => {
  const markAsRead = useArticlesMarkNotificationAsRead();

  const handleNotification = async (notificationId: number) => {
    // Mark the notification as read and redirect the user to the specific article page
    await markAsRead.mutateAsync(notificationId);

    // Close the notifications menu after redirecting the user
    handleNotificationsMenu();
  };

  return (
    <div
      className={`articles-notifications__item ${
        !read ? "articles-notifications__item--unread" : ""
      }`}
      onClick={() => handleNotification(id)}
      data-testid={`component:articles-notification--${read ? "read" : "unread"}`}
    >
      <div className="articles-notifications__image">
        <img src={feature_image} />
      </div>

      <div className="articles-notifications__content">
        <h6 title={title} className="articles-notifications__content__title">
          {title}
        </h6>

        <p title={summary} className="articles-notifications__content__summary">
          {summary}
        </p>
      </div>

      <span className="articles-notifications__timestamp">
        {handleDistanceToNow(handleDateAsTimestamp(create_date))}
      </span>
    </div>
  );
};

export default ArticleNotification;
