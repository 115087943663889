// Utilities & Hooks
import { useState } from "react";
import { Link } from "react-router-dom";

// Components
import ContentHeader from "../../../components/Content/ContentHeader";
import InputFieldSearch from "../../../components/Inputs/InputFieldSearch";
import AdminClientsTable from "./AdminClientsTable";
import PermissionCheckComponentWrapper from "../../../components/Wrappers/PermissionCheckComponentWrapper";

const AdminClients = () => {
  /*==========================
    SEARCH TROUGH THE LIST
    OF USERS IN THE TABLE
  ===========================*/
  const [searchedClients, setSearchedClients] = useState<string>("");

  return (
    <div className="container py--25">
      <ContentHeader
        title={
          <PermissionCheckComponentWrapper permissions={["client_admin_create"]}>
            <Link
              to="/admin/clients/new/"
              className="btn btn--fluid btn--fluid--md btn--secondary ml--20"
            >
              Add Client
            </Link>
          </PermissionCheckComponentWrapper>
        }
        modifierClass="content__header--no-underline"
      >
        <InputFieldSearch
          placeholder="Search Clients"
          size="lg"
          handleOnSearch={search => setSearchedClients(search)}
        />
      </ContentHeader>

      <AdminClientsTable searchedClients={searchedClients} />
    </div>
  );
};

export default AdminClients;
