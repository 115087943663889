import { useQueryClient } from "@tanstack/react-query";
import { BucketsResponseFields } from "../../../api/Buckets/interfaces";
import { useAuth } from "../../../providers/auth-context";

/**
 *
 * Hook used for finding the targeted bucket from the
 * list of buckets returned from the API, and extracting
 * the ID of that bucket.
 *
 * @returns The ID of the targeted bucket
 *
 */
export default function useFindTargetedBucket() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  // Find the matching bucket based on the provided targeted bucket name
  const handleFindTargetedBucket = (targetedBucket: string) => {
    // If there's no received targeted bucket, exit function
    if (!targetedBucket) return;

    const cachedBuckets = queryClient.getQueryData([
      "buckets",
      user.active_company.slug,
    ]) as BucketsResponseFields[];

    // If there's no cached data, exit function
    if (!cachedBuckets) return;

    const matchingBucket: BucketsResponseFields | undefined = cachedBuckets.find(bucket => {
      return bucket.name.toLowerCase() === targetedBucket.toLowerCase();
    });

    // If bucket cannot be found, exit function preventing API requests
    if (!matchingBucket) return;

    // Update the ID for the matching bucket
    return matchingBucket.id;
  };

  return [handleFindTargetedBucket];
}
