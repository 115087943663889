// Hooks
import { useState } from "react";

// Components
import { Link } from "react-router-dom";
import ContentHeader from "../../../components/Content/ContentHeader";
import UsersTable from "./UsersTable";
import PermissionCheckComponentWrapper from "../../../components/Wrappers/PermissionCheckComponentWrapper";
import InputFieldSearch from "../../../components/Inputs/InputFieldSearch";

const Users = () => {
  /*==========================
    SEARCH TROUGH THE LIST
    OF USERS IN THE TABLE
  ===========================*/
  const [searchedUsers, setSearchedUsers] = useState<string>("");

  return (
    <div className="container py--25">
      <PermissionCheckComponentWrapper permissions={["applicant_dashboard_users_create"]}>
        <ContentHeader
          title={
            <Link
              to="/account/users/new/"
              className="btn btn--fluid btn--fluid--md btn--secondary mr--10"
            >
              Add User
            </Link>
          }
          modifierClass="content__header--no-underline mb--10"
        >
          <InputFieldSearch
            placeholder="Search Users"
            size="lg"
            handleOnSearch={search => setSearchedUsers(search)}
          />
        </ContentHeader>
      </PermissionCheckComponentWrapper>

      <UsersTable searchedUsers={searchedUsers} />
    </div>
  );
};

export default Users;
