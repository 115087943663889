interface ToggleProps {
  id: string;
  name: string;
  textLeft: string;
  textRight?: string;
  isToggled?: boolean;
  isDisabled?: boolean;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  modifierClass?: string;
}

const Toggle = ({
  id,
  name,
  textLeft,
  isToggled = false,
  isDisabled = false,
  textRight = "",
  modifierClass = "",
  handleOnChange,
}: ToggleProps) => {
  return (
    <div className={`toggle ${isDisabled ? "toggle--disabled" : ""} ${modifierClass}`}>
      <label htmlFor={name}>
        <span className="toggle__text toggle__text--left">{textLeft}</span>
      </label>

      <input
        type="checkbox"
        name={name}
        id={id}
        checked={isToggled}
        hidden
        disabled={isDisabled}
        onChange={handleOnChange}
        data-testid="toggle-component"
      />

      <label htmlFor={name} className="toggle__button">
        <div className="toggle__button__inner"></div>
      </label>

      <label htmlFor={name}>
        <span className="toggle__text toggle__text--right">{textRight}</span>
      </label>
    </div>
  );
};

export default Toggle;
