// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";
import Button from "../../../Button/Button";

// Interfaces
import { DropdownItem } from "../../../Dropdown/interfaces";
import { JobBoardsFormsProps } from "../interfaces";

// Assets
import { FaDollarSign as DollarIcon } from "react-icons/fa";
import { BsInfoCircleFill as SalaryInfoIcon } from "react-icons/bs";

// Dropdown datasets
import {
  INDEED_DROPDOWN_JOB_EXPERIENCE_LEVEL,
  INDEED_DROPDOWN_JOB_STATUS,
  INDEED_DROPDOWN_JOB_CATEGORY,
  INDEED_DROPDOWN_MINIMUM_EDUCATION_LEVEL,
  INDEED_PROMOTE,
  INDEED_SPONSOR_DURATION,
} from "./dropdown-data/IndeedDropdown";
import FormInputWithElements from "../../../Form/FormInputWithElements";
import { JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD } from "./constants";
import { STATES_USA_DROPDOWN } from "../../../../constants/countries";
import Tooltip from "../../../Tooltip/Tooltip";

const IndeedForm = ({
  values,
  isSubmitDisabled = false,
  isSubmitLoading = false,
  validateField,
  setFieldValue,
}: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.indeed.enabled) validateField("indeed");
  }, [values.indeed]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Field
          component={FormCheckbox}
          name="indeed.enabled"
          id="indeed.enabled"
          label="Enable Indeed"
          checked={values.indeed.enabled}
          labelModifierClass="input__label--sm"
        />
        {values.indeed.enabled ? (
          <div className="d-flex justify-content-end w--100">
            <Button
              modifierClass="btn--fluid btn--primary"
              isDisabled={isSubmitDisabled || isSubmitLoading}
              isLoading={isSubmitLoading}
            >
              Save Changes
            </Button>
          </div>
        ) : null}
      </div>

      {values.indeed.enabled ? (
        <>
          <Field
            component={FormInput}
            name="indeed.ad_title"
            id="indeed.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormInput}
            name="indeed.salary"
            id="indeed.salary"
            label={`Salary ${
              JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD.includes(values.indeed.state)
                ? "(required)"
                : ""
            }`}
            tooltip={
              <Tooltip
                size="lg"
                modifierClass="input-field-test"
                toggle="hover"
                text={`Best practices are as follows:\n
              -	If the salary is a range use the following example format: $10 - $20 / hour.\n
              -	Always specify a pay period, e.g., per year, per week, per hour, etc.\n
              -	Keep number formats as simple as possible\n
              -	Include a currency symbol\n
              -	Do not use any commas, spaces, apostrophes, etc.\n
              -	Do not use shorthand expressions, e.g. "k" to express thousands\n

              NOTE: If a job does not have a salary, leave the field blank for that job. Salary ranges that are unreasonably wide will not be posted.`}
              >
                <SalaryInfoIcon />
              </Tooltip>
            }
            isRequired={JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD.includes(
              values.indeed.state,
            )}
            placeholder="Salary ($50000 per year OR $4000-$5000 per month)"
            modifierClass="input--default-label mb--20"
            description={
              JOB_BOARD_FORM_INDEED_STATES_REQUIRED_SALARY_FIELD.includes(values.indeed.state)
                ? `* Salary information is required in the state of ${
                    STATES_USA_DROPDOWN.find(state => values.indeed.state === state.value)?.text ??
                    "N/A"
                  }.`
                : ""
            }
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.experience_level"
            id="indeed.experience_level"
            label="Job Experience Level"
            items={INDEED_DROPDOWN_JOB_EXPERIENCE_LEVEL}
            handleFieldUpdate={(jobExperience: DropdownItem) => {
              setFieldValue("indeed.experience_level", jobExperience.value);
            }}
            size="full"
            placeholder="Select a job experience level - optional"
            preselectedItemValue={values.indeed.experience_level}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.job_status"
            id="indeed.job_status"
            label="Job Status"
            items={INDEED_DROPDOWN_JOB_STATUS}
            handleFieldUpdate={(jobStatus: DropdownItem) => {
              setFieldValue("indeed.job_status", jobStatus.value);
            }}
            size="full"
            placeholder="Select a job status - optional"
            preselectedItemValue={values.indeed.job_status}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.category1"
            id="indeed.category1"
            label="Category 1"
            items={INDEED_DROPDOWN_JOB_CATEGORY}
            handleFieldUpdate={(category: DropdownItem) => {
              setFieldValue("indeed.category1", category.value);
            }}
            size="full"
            placeholder="None - optional"
            preselectedItemValue={values.indeed.category1}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.category2"
            id="indeed.category2"
            label="Category 2"
            items={INDEED_DROPDOWN_JOB_CATEGORY}
            handleFieldUpdate={(category: DropdownItem) => {
              setFieldValue("indeed.category2", category.value);
            }}
            size="full"
            placeholder="None - optional"
            preselectedItemValue={values.indeed.category2}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.category3"
            id="indeed.category3"
            label="Category 3"
            items={INDEED_DROPDOWN_JOB_CATEGORY}
            handleFieldUpdate={(category: DropdownItem) => {
              setFieldValue("indeed.category3", category.value);
            }}
            size="full"
            placeholder="None - optional"
            preselectedItemValue={values.indeed.category3}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.education_level"
            id="indeed.education_level"
            label="Minimum Education Level"
            items={INDEED_DROPDOWN_MINIMUM_EDUCATION_LEVEL}
            handleFieldUpdate={(educationLevel: DropdownItem) => {
              setFieldValue("indeed.education_level", educationLevel.value);
            }}
            size="full"
            placeholder="Select an education level - optional"
            preselectedItemValue={values.indeed.education_level}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="indeed.promote_on_indeed"
            id="indeed.promote_on_indeed"
            label="Promote on Indeed? (required)"
            items={INDEED_PROMOTE}
            handleFieldUpdate={(promote: DropdownItem) => {
              setFieldValue("indeed.promote_on_indeed", promote.value);
            }}
            size="full"
            placeholder="Please select one of the provided options"
            preselectedItemValue={values.indeed.promote_on_indeed}
            modifierClass="dropdown--default-label mb--30"
            isRequired
          />

          {values.indeed.promote_on_indeed === 1 ? (
            <div className="row">
              <div className="col-12 col-lg-6">
                <Field
                  component={FormInputWithElements}
                  name="indeed.indeed_budget"
                  id="indeed.indeed_budget"
                  label="Indeed Budget"
                  isRequired
                  placeholder="Amount"
                  modifierClass="input--default-label mb--20"
                  leftElement={<DollarIcon />}
                  type="number"
                  min={0}
                />
              </div>

              <div className="col-12 col-lg-6">
                <Field
                  component={FormDropdownSearchable}
                  name="indeed.indeed_duration"
                  id="indeed.indeed_duration"
                  label="Sponsor Duration"
                  isRequired
                  items={INDEED_SPONSOR_DURATION}
                  handleFieldUpdate={(promote: DropdownItem) => {
                    setFieldValue("indeed.indeed_duration", promote.value);
                  }}
                  size="full"
                  placeholder="Select Duration"
                  preselectedItemValue={values.indeed.indeed_duration}
                  modifierClass="dropdown--default-label mb--30"
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default IndeedForm;
