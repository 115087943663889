// Utilities & Hooks
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/auth-context";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";
import fetchHandler from "../../fetchHandler";

// Interfaces
import { ApplicationCommentsFields, ApplicationDetailsFields } from "../interfaces";
import {
  ApplicationCommentsDeleteRequestFields,
  ApplicationCommentsEditRequestFields,
  ApplicationCommentsPostRequestFields,
} from "./interfaces";

/**
 *
 * Post a new comment for the application that we're currently viewing.
 *
 * The API mutation hook call takes an object argument with the following fields:
 *
 * - `application_id` - representing the application for which we'll post the new comment
 * - `content` - the content (text) of the newly posted comment
 * - `rating` - the rating of the newly posted comment
 *
 */
export function useApplicationCommentPost() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (newComment: ApplicationCommentsPostRequestFields) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${newComment.application_id}/comments`,
        { content: newComment.content, rating: newComment.rating },
      );
    },
    {
      onMutate: newComment => {
        // Get the cached data
        const cachedApplicationDetails = queryClient.getQueryData([
          "applications-details",
          newComment.application_id,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Exit function if there's no cached data
        if (!cachedApplicationDetails) return;

        // Add the new comment to the list
        const updatedApplicationsDetails = cloneDeep(cachedApplicationDetails);
        updatedApplicationsDetails.comments.push({
          id: 0,
          author: handleFullnameCombination(user),
          author_id: user.id,
          content: newComment.content || "",
          rating: newComment.rating || 0,
          updated_timestamp: new Date().getTime() / 1000,
        });

        // Recalculate the comments average rating value
        // prettier-ignore
        updatedApplicationsDetails.comments_avg_rating = calculateAvgCommentsRating(updatedApplicationsDetails.comments);

        // Update cached data
        queryClient.setQueryData(
          ["applications-details", newComment.application_id, companySlug],
          updatedApplicationsDetails,
        );

        // Show a success notification in the UI
        toast.success("Successfully posted new comment!", {
          toastId: "application-details-comments-create",
        });

        return { cachedApplicationDetails };
      },
      onSuccess: (data, newComment) => {
        const updatedApplicationDetails = queryClient.getQueryData([
          "applications-details",
          newComment.application_id,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Get the latest comment and update the details received from the successful request
        // This is done to make sure that the comment has the correct ID value issued by the server
        updatedApplicationDetails.comments[updatedApplicationDetails.comments.length - 1] = data;

        // Update the cached data
        queryClient.setQueryData(
          ["applications-details", newComment.application_id, companySlug],
          updatedApplicationDetails,
        );
      },
      onError: (error, newComment, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("application-details-comments-create");

        // Revert to original data before throwing the error
        queryClient.setQueryData(
          ["applications-details", newComment.application_id, companySlug],
          context?.cachedApplicationDetails,
        );

        return error;
      },
    },
  );
}

/**
 *
 * Edit an existing comment for the application that we're currently viewing.
 *
 * The API mutation hook call takes an object argument with the following fields:
 *
 * - `application_id` - representing the application for which we'll post the new comment
 * - `comment_id` - representing the comment that we're targeting for edit
 * - `content` - the content (text) of the newly posted comment
 * - `rating` - the rating of the newly posted comment
 *
 */
export function useApplicationCommentEdit() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (editComment: ApplicationCommentsEditRequestFields) => {
      return await fetchHandler(
        "PUT",
        `company/${companySlug}/applications/${editComment.application_id}/comments/${editComment.comment_id}`,
        {
          content: editComment.content,
          rating: editComment.rating,
        },
      );
    },
    {
      onMutate: editCommentDetails => {
        // Find the cached application's details
        const cachedApplicationDetails = queryClient.getQueryData([
          "applications-details",
          editCommentDetails.application_id,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Exit if there's no cached data
        if (!cachedApplicationDetails) return;

        // Find the matching comment
        const targetedCommentIndex = cachedApplicationDetails.comments.findIndex(comment => {
          return comment.id === editCommentDetails.comment_id;
        });

        // Exit function and return cached data if comment cannot be found
        if (targetedCommentIndex < 0) return { cachedApplicationDetails };

        // Update the comments of the targeted aplpication details
        const clonedApplicationDetails = cloneDeep(cachedApplicationDetails);
        clonedApplicationDetails.comments[targetedCommentIndex] = {
          ...clonedApplicationDetails.comments[targetedCommentIndex],
          content: editCommentDetails.content || "",
          rating: editCommentDetails.rating || 0,
          updated_timestamp: new Date().getTime() / 1000,
        };

        // Recalculate the comments average rating value
        // prettier-ignore
        clonedApplicationDetails.comments_avg_rating = calculateAvgCommentsRating(clonedApplicationDetails.comments);

        // Update the cached data
        queryClient.setQueryData(
          ["applications-details", editCommentDetails.application_id, companySlug],
          clonedApplicationDetails,
        );

        // Show a success notification in the UI
        toast.success("Successfully edited comment!", {
          toastId: "application-details-comments-edit",
        });

        // Return previous data for error scenarios
        return { cachedApplicationDetails };
      },
      onError: (error, editDetails, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("application-details-comments-edit");

        queryClient.setQueryData(
          ["applications-details", editDetails.application_id, companySlug],
          context?.cachedApplicationDetails,
        );
        return error;
      },
    },
  );
}

/**
 *
 * Delete an existing comment for the application that we're currently viewing.
 *
 * The API mutation hook call takes an object argument with the following fields:
 *
 * - `application_id` - representing the application for which we'll post the new comment
 * - `comment_id` - representing the comment that we're targeting for edit
 *
 */
export function useApplicationCommentDelete() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (deleteComment: ApplicationCommentsDeleteRequestFields) => {
      return await fetchHandler(
        "DELETE",
        `company/${companySlug}/applications/${deleteComment.application_id}/comments/${deleteComment.comment_id}`,
      );
    },
    {
      onMutate: deleteDetails => {
        // Find the cached application's details
        const cachedApplicationDetails = queryClient.getQueryData([
          "applications-details",
          deleteDetails.application_id,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Exit if there's no cached data
        if (!cachedApplicationDetails) return;

        // Update the comments of the targeted aplpication details
        const clonedApplicationDetails = cloneDeep(cachedApplicationDetails);
        clonedApplicationDetails.comments = clonedApplicationDetails.comments.filter(comment => {
          return comment.id !== deleteDetails.comment_id;
        });

        // Recalculate the comments average rating value
        // prettier-ignore
        clonedApplicationDetails.comments_avg_rating = calculateAvgCommentsRating(clonedApplicationDetails.comments);

        // Update the cached data with the removed comment and updated average rating
        queryClient.setQueryData(
          ["applications-details", deleteDetails.application_id, companySlug],
          clonedApplicationDetails,
        );

        // Show a success notification in the UI
        toast.success("Successfully deleted comment!", {
          toastId: "application-details-comments-delete",
        });

        // Return previous data for error scenarios
        return { cachedApplicationDetails };
      },
      onError: (error, deleteDetails, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("application-details-comments-delete");

        queryClient.setQueryData(
          ["applications-details", deleteDetails.application_id, companySlug],
          context?.cachedApplicationDetails,
        );
        return error;
      },
    },
  );
}

/**
 *
 * Utility function that will calculate the average comments rating
 * so we can use it in the optimistic updates.
 *
 * @param comments The updated list of comments that belong to the edited Application
 *
 * @returns `number` if valid calculated average raing, or `null` so we can display 'N/A' in the UI.
 *
 */
function calculateAvgCommentsRating(comments: ApplicationCommentsFields[]): number | null {
  // Filter out the comments removing any comment without valid rating
  const filteredComments = comments.filter(comment => comment.rating);

  // Exit function if there's no comments to work with
  if (!filteredComments.length) return null;

  // Sumarize the ratings of all currently existing comments
  const commentsRatingSum = filteredComments.reduce((acc, currValue) => acc + currValue.rating, 0);

  // We default to null which is displayed as "N/A" in the UI in case there are no comments left
  return commentsRatingSum / filteredComments.length || null;
}
