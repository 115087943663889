import { AnimatePresence } from "framer-motion";
import {
  useApplicationRemovePhoto,
  useApplicationRemoveResume,
} from "../../api/Applications/Applications";
import { ApplicationDetailsFields } from "../../api/Applications/interfaces";
import Button from "../Button/Button";
import Card from "../Card/Card";
import Modal from "../Modal/Modal";
import { useState } from "react";
import useErrorReporting from "../../hooks/useErrorReporting";
import { useAuth } from "../../providers/auth-context";

const ApplicationsDetailsAdminActions = ({
  application,
}: {
  application: ApplicationDetailsFields;
}) => {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  /*============================
    ADMIN ACTIONS MODAL STATES
  ==============================*/
  const [showRemovePhotoModal, setShowRemovePhotoModal] = useState<boolean>(false);
  const [showRemoveResumeModal, setShowRemoveResumeModal] = useState<boolean>(false);

  const handleClosePhotoModal = () => {
    setShowRemovePhotoModal(false);
  };

  const handleCloseResumeModal = () => {
    setShowRemoveResumeModal(false);
  };

  /*================================
    ADMIN ACTIONS MUTATION HANDLERS
  =================================*/
  const applicationRemovePhoto = useApplicationRemovePhoto();
  const applicationRemoveResume = useApplicationRemoveResume();

  const handleRemovePhoto = async () => {
    if (!application || !application.id) return;

    try {
      await applicationRemovePhoto.mutateAsync(Number(application.id));
      handleClosePhotoModal();
    } catch (error) {
      errorReporting("Failed delete application photo request", error, {
        application_id: application.id,
        company_slug: user.active_company.slug,
      });
    }
  };

  const handleRemoveResume = async () => {
    if (!application || !application.id) return;

    try {
      await applicationRemoveResume.mutateAsync(Number(application.id));
      handleCloseResumeModal();
    } catch (error) {
      errorReporting("Failed delete application resume request", error, {
        application_id: application.id,
        company_slug: user.active_company.slug,
      });
    }
  };

  //  Used for conditional rendering of the 'Admin Actions' section
  const hasAvailableAdminActions = application.applicant.photo || application.resume;

  if (!hasAvailableAdminActions) {
    return null;
  }

  return (
    <>
      {/* REMOVE PHOTO MODAL */}
      <AnimatePresence>
        {application && showRemovePhotoModal && (
          <Modal
            title="Remove Application Photo?"
            text={
              <>
                This action will delete{" "}
                <strong className="txt--black">{`${application.applicant.first_name} ${
                  application.applicant.last_name || ""
                }`}</strong>
                's photo. This action is <strong className="txt--black">irreversible</strong>. Do
                you want to continue?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleClosePhotoModal}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleClosePhotoModal}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--danger"
                onClick={() => handleRemovePhoto()}
                isDisabled={applicationRemovePhoto.isLoading}
                isLoading={applicationRemovePhoto.isLoading}
              >
                Yes, Remove Photo
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>

      {/* REMOVE RESUME MODAL */}
      <AnimatePresence>
        {application && showRemoveResumeModal && (
          <Modal
            title="Remove Application Resume?"
            text={
              <>
                This action will delete{" "}
                <strong className="txt--black">{`${application.applicant.first_name} ${
                  application.applicant.last_name || ""
                }`}</strong>
                's resume. This action is <strong className="txt--black">irreversible</strong>. Do
                you want to continue?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleCloseResumeModal}
          >
            <div className="modal__actions">
              <Button modifierClass="btn--fluid btn--primary" onClick={handleCloseResumeModal}>
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--danger"
                onClick={() => handleRemoveResume()}
                isDisabled={applicationRemoveResume.isLoading}
                isLoading={applicationRemoveResume.isLoading}
              >
                Yes, Remove Resume
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
      <Card modifierClass="card--rounded">
        <h2 className="fw--semibold mb--20">Admin Actions</h2>
        <div className="d-flex flex-column align-items-start">
          {/* REMOVE APPLICATION PHOTO */}
          {application.applicant.photo ? (
            <Button
              className="btn btn--text btn--text--danger px--0i mb--5"
              onClick={() => setShowRemovePhotoModal(true)}
            >
              Remove Application Photo
            </Button>
          ) : null}

          {/* REMOVE APPLICATION RESUME */}
          {application.resume ? (
            <Button
              className="btn btn--text btn--text--danger px--0i mb--5"
              onClick={() => setShowRemoveResumeModal(true)}
            >
              Remove Application Resume
            </Button>
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default ApplicationsDetailsAdminActions;
