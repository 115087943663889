import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";

interface SbcaTypesResponseFields {
  id: number;
  name: string;
  description: string;
}

/**
 *
 * Get the list of SBCA types that are publicly available
 *
 */
export function useGetSBCATypes() {
  return useQuery(["sbca-types"], async () => {
    return (await fetchHandler("GET", `sbca-types`)) as SbcaTypesResponseFields[];
  });
}

/**
 *
 * Get the list of available SBCA types for admin pages
 *
 */
export const useAdminGetSBCATypes = () => {
  return useQuery(["admin-sbca-types"], async () => {
    return (await fetchHandler("GET", `admin/sbca-types`)) as SbcaTypesResponseFields[];
  });
};
