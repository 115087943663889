import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const ArticlesManagementSkeleton = () => {
  return (
    <div className="container py--25" data-testid="component:articles-edit-skeleton">
      <div className="d-flex justify-content-between align-items-center mb--10">
        <Skeleton width={150} height={30} />
        <Skeleton width={180} height={20} />
      </div>

      <Card modifierClass="card--padding--xl">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Skeleton width="100%" height={40} className="mb--15" />
            <Skeleton width="100%" height={125} className="mb--15" />
            <Skeleton width="100%" height={36} className="mb--15" />
          </div>

          <div className="col-12 col-lg-6">
            <Skeleton width="100%" height={36} />
            <Skeleton width="70%" height={10} className="mb--15" />
            <Skeleton width="100" height={170} className="mb--15" />
          </div>
        </div>
      </Card>

      <Card modifierClass="card--padding--xl">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Skeleton width="100%" height={40} />
          </div>

          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-end align-items-center">
              <Skeleton width={72} height={35} className="mr--10" />
              <Skeleton width={140} height={35} className="mr--10" />
              <Skeleton width={70} height={35} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ArticlesManagementSkeleton;
