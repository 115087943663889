import { DropdownItem } from "../../../../Dropdown/interfaces";

/*================================
  JOB EXPERIENCE LEVEL
=================================*/
export const INDEED_DROPDOWN_JOB_EXPERIENCE_LEVEL: DropdownItem[] = [
  { text: "Select a job experience level - optional", value: "" },
  { value: 0, text: "None" },
  { value: 1, text: "1+ years" },
  { value: 2, text: "2+ years" },
  { value: 3, text: "3+ years" },
  { value: 4, text: "4+ years" },
  { value: 5, text: "5+ years" },
  { value: 6, text: "6+ years" },
  { value: 7, text: "7+ years" },
  { value: 8, text: "8+ years" },
  { value: 9, text: "9+ years" },
  { value: 10, text: "10+ years" },
];

/*================================
  JOB STATUS
=================================*/
export const INDEED_DROPDOWN_JOB_STATUS: DropdownItem[] = [
  { text: "Select a job status - optional", value: "" },
  { value: 1, text: "Full Time" },
  { value: 2, text: "Part Time" },
  { value: 3, text: "Contract" },
  { value: 4, text: "Internship" },
  { value: 5, text: "Temporary" },
];

/*================================
  JOB CATEGORY
=================================*/
export const INDEED_DROPDOWN_JOB_CATEGORY: DropdownItem[] = [
  { text: "None - optional", value: "" },
  { value: 1, text: "Accounting/Finance" },
  { value: 2, text: "Administrative" },
  { value: 3, text: "Arts/Entertainment/Publishing" },
  { value: 4, text: "Banking/Loans" },
  { value: 11, text: "Computer/Internet" },
  { value: 5, text: "Construction/Facilities" },
  { value: 6, text: "Customer Service" },
  { value: 7, text: "Education/Training" },
  { value: 8, text: "Engineering/Architecture" },
  { value: 9, text: "Government/Military" },
  { value: 10, text: "Healthcare" },
  { value: 12, text: "Hospitality/Travel" },
  { value: 13, text: "Human Resources" },
  { value: 14, text: "Insurance" },
  { value: 15, text: "Law Enforcement/Security" },
  { value: 16, text: "Legal" },
  { value: 17, text: "Manufacturing/Mechanical" },
  { value: 18, text: "Marketing/Advertising/PR" },
  { value: 19, text: "Non-Profit/Volunteering" },
  { value: 20, text: "Pharmaceutical/Bio-tech" },
  { value: 21, text: "Real Estate" },
  { value: 22, text: "Restaurant/Food Service" },
  { value: 23, text: "Retail" },
  { value: 24, text: "Sales" },
  { value: 25, text: "Telecommunications" },
  { value: 26, text: "Transportation/Logistics" },
  { value: 27, text: "Upper Management/Consulting" },
];

/*================================
  MINIMUM EDUCATION LEVEL
=================================*/
export const INDEED_DROPDOWN_MINIMUM_EDUCATION_LEVEL: DropdownItem[] = [
  { text: "Select an education level - optional", value: "" },
  { value: 1, text: "High School or equivalent" },
  { value: 2, text: "Certification" },
  { value: 3, text: "Vocational" },
  { value: 4, text: "Associate Degree" },
  { value: 5, text: "Bachelor's Degree" },
  { value: 6, text: "Master's Degree" },
  { value: 7, text: "Doctorate" },
  { value: 8, text: "Professional" },
  { value: 9, text: "Some College Coursework Completed" },
  { value: 10, text: "Vocational - HS Diploma" },
  { value: 11, text: "Vocational - Degree" },
  { value: 12, text: "Some High School Coursework" },
];

/*================================
  PROMOTE ON INDEED
=================================*/
export const INDEED_PROMOTE: DropdownItem[] = [
  { text: "No", value: "no" },
  { text: "Yes", value: 1 },
];

/*================================
  SPONSOR DURATION
=================================*/
export const INDEED_SPONSOR_DURATION: DropdownItem[] = [
  { text: "1 day", value: "1 day" },
  { text: "3 days", value: "3 days" },
  { text: "5 days", value: "5 days" },
  { text: "1 week", value: "1 week" },
  { text: "2 weeks", value: "2 weeks" },
  { text: "3 weeks", value: "3 weeks" },
  { text: "1 month", value: "1 month" },
];
