import { RefObject, useEffect } from "react";

type ScrollSettings = {
  block: "start" | "end" | "center" | "nearest";
  behavior?: "auto" | "smooth";
  inline?: "start" | "end" | "center" | "nearest";
};

/**
 *
 * Utility hook used for scrolling the user's viewport to a specifically targeted element.
 *
 * @param `stateTrigger` The inner state at the place where the hook is being used, that will trigger the scroll functionality
 * @param `ref` Reference to a specifically targeted element from the UI
 * @param `targetedArea` The area that will be targeted within the referenced element. This is the scrollable area.
 * @param `targetedElement` The element that is to be targeted and scrolled to within the scrollable area.
 * @param `scrollSettings` `optional` Configuration settings for `scrollIntoView` method.
 *
 */
export default function useScrollToActiveElement(
  stateTrigger: unknown,
  ref: RefObject<HTMLElement>,
  targetedArea: string,
  targetedElement: string,
  scrollSettings: ScrollSettings = { block: "center" },
) {
  useEffect(() => {
    // Exit function if the referenced element is not available
    if (!ref.current) return;

    // Target the area that where the scrollable content exists
    const scrollableArea: HTMLElement | null = ref.current.querySelector(targetedArea);

    // Exit if area cannot be found
    if (!scrollableArea) return;

    // Target the specific element that exists within the scrollable area
    const elementToFocus: HTMLElement | null = scrollableArea.querySelector(targetedElement);

    if (!elementToFocus) return;

    elementToFocus.scrollIntoView(scrollSettings);
  }, [stateTrigger]);
}
