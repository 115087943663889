// Utilities & Hooks
import { Field, Form, Formik } from "formik";
import { useBucketsCreate } from "../../api/Buckets/Buckets";

// Interfaes
import { BucketsCreateRequestFields } from "../../api/Buckets/interfaces";
import { BucketsCreateNewModalProps } from "./interfaces";

// Schemas
import { BUCKETS_SCHEMA } from "../../schemas/BucketsSchemas";

// Components
import Button from "../Button/Button";
import FormInput from "../Form/FormInput";
import Modal from "../Modal/Modal";
import useErrorReporting from "../../hooks/useErrorReporting";

const BucketsCreateNewModal = ({ handleCloseModal }: BucketsCreateNewModalProps) => {
  const errorReporting = useErrorReporting();

  /*============================
    TRIGGER CREATING A NEW
    BUCKET FOR THE ACTIVE COMPANY
  =============================*/
  const bucketsCreate = useBucketsCreate();

  const handleBucketsNew = async ({ name }: BucketsCreateRequestFields) => {
    try {
      await bucketsCreate.mutateAsync({ name });

      // Close the modal
      handleCloseModal();
    } catch (error) {
      errorReporting("Failed creating new bucket", error, { bucket_name: name });
    }
  };

  return (
    <Modal
      title="Add Custom Bucket"
      text=""
      modifierClass="modal--md"
      handleCloseModal={handleCloseModal}
    >
      <Formik
        initialValues={{ name: "" }}
        validationSchema={BUCKETS_SCHEMA}
        onSubmit={handleBucketsNew}
      >
        <Form>
          <Field
            component={FormInput}
            id="name"
            name="name"
            placeholder="Enter bucket's name"
            modifierClass="my--20"
            shouldAutofocus
          />
          <div className="modal__actions">
            <Button
              type="button"
              modifierClass="btn--fluid btn--primary--light"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary"
              isLoading={bucketsCreate.isLoading}
              isDisabled={bucketsCreate.isLoading}
            >
              Add Bucket
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default BucketsCreateNewModal;
