// Hooks & Utilities
import { useGetAvailableTours } from "../../api/Tours/Tours";
import { useNavigate } from "react-router-dom";
import { LocalStorageActions } from "../../utilities/handleLocalStorage";

// Components
import Skeleton from "react-loading-skeleton";
import ContentHeader from "../../components/Content/ContentHeader";

// Interfaces
import { ResourcesGroups, ResourcesItem } from "./interfaces";

// PDF assets
import { RESOURCES_GROUPS } from "./statics";

const Resources = () => {
  const { data: toursData, isLoading: toursLoading } = useGetAvailableTours();

  const navigate = useNavigate();

  const handleStartProductTour = (tourID: string) => {
    // Save the ID of the product tour to local LocalStorageActions
    LocalStorageActions.saveItem("activeProductTour", tourID);

    // Redirect the user to the applications page
    // Note: We can change this to accept a dynamic URL parameter from the tour
    // if in the future we need tours on other pages of the application.
    navigate("/applications/");
  };

  return (
    <div className="container py--25">
      <ContentHeader title="Resources" modifierClass="mb--25" />
      <div key={`resource-group-tour`}>
        <h4 className="fw--medium mb--15">New Feature Guides</h4>
        <div className="mb--30 d-flex flex-column align-items-start">
          {toursLoading ? (
            <Skeleton width={170} />
          ) : toursData && toursData.length ? (
            toursData.map(tour => (
              <button
                key={`tour-${tour.id}`}
                onClick={() => handleStartProductTour(tour.name)}
                className="btn btn--text btn--text--secondary txt--left btn--font-md mb--10 px--0i"
              >
                {tour.label}
              </button>
            ))
          ) : null}
        </div>
      </div>

      {RESOURCES_GROUPS.map((resourceGroup: ResourcesGroups, groupIndex: number) => (
        <div key={`resource-group-${groupIndex}`}>
          <h4 className="fw--medium mb--15">{resourceGroup.title}</h4>

          <div className="mb--30 d-flex flex-column align-items-start">
            {resourceGroup.pdfs.map((resource: ResourcesItem, resourceIndex: number) => (
              <a
                key={`resource-${resourceIndex}`}
                href={`https://fch-assets.s3.amazonaws.com/${resource.link}`}
                className="btn btn--text--secondary txt--left btn--font-md mb--10"
                target="_blank"
                rel="noopener"
              >
                {resource.text}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Resources;
