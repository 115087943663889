// Utilities & Hooks
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAdminGetAllCompanies } from "../../../../api/Company/AdminCompany";
import { useExtractSearchParameters } from "../../../../hooks/useExtractSearchParameters";

// Components
import ContentHeader from "../../../../components/Content/ContentHeader";
import DropdownMultiselect from "../../../../components/Dropdown/DropdownMultiselect";
import InputFieldSearch from "../../../../components/Inputs/InputFieldSearch";
import AdminUsersTable from "./AdminUsersTable";
import PermissionCheckComponentWrapper from "../../../../components/Wrappers/PermissionCheckComponentWrapper";

// Interfaces
import { DropdownItem } from "../../../../components/Dropdown/interfaces";

const UsersAdmin = () => {
  const [searchParameterObject, setSearchParameterObject] = useExtractSearchParameters();

  /*==========================
    SEARCH TROUGH THE LIST
    OF USERS IN THE TABLE
  ===========================*/
  const [searchedUsers, setSearchedUsers] = useState<string>("");

  /*==========================
    FILTER USERS IN THE TABLE
    BASED ON SELECTED COMPANIES
  ===========================*/
  const [companiesFilter, setCompaniesFilter] = useState<string[]>([]);
  const { data: companies, isLoading: companiesIsLoading } = useAdminGetAllCompanies();

  const companiesDropdownItems: DropdownItem[] = useMemo(() => {
    if (!companies || !companies.length || companiesIsLoading) return [];

    // Map the companies to dropdown items
    return companies
      .map(company => {
        return { text: company.name, value: company.name };
      })
      .sort((companyA, companyB) => {
        return companyA.text.toLowerCase() > companyB.text.toLowerCase() ? 1 : -1;
      });
  }, [companies]);

  const handleCompaniesSelection = (companies: string[]) => {
    setCompaniesFilter(companies);

    // Join the selected companies in a string
    const companiesAsString: string = companies.join(";");

    // If there's a valid companies string value, add it as a search parameter.
    // Otherwise remove it from the search parameters
    if (companiesAsString) {
      setSearchParameterObject({ ...searchParameterObject, companies: companiesAsString, page: 1 });
    } else {
      delete searchParameterObject.companies;
      setSearchParameterObject({ ...searchParameterObject, page: 1 });
    }
  };

  // Pre-select the companies filters if they are present in the search parameters
  useEffect(() => {
    if (searchParameterObject.companies) {
      setCompaniesFilter(searchParameterObject.companies.split(";"));
    }
  }, [searchParameterObject.companies]);

  return (
    <div className="container container--xl py--25">
      <ContentHeader
        title={
          <PermissionCheckComponentWrapper permissions={["users_admin_create"]}>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center">
              <span className="mb--md--10">Users</span>
              <Link
                to="/account/admin/users/new/"
                className="btn btn--fluid btn--fluid--md btn--secondary ml--20 ml--md--0"
              >
                Add User
              </Link>
            </div>
          </PermissionCheckComponentWrapper>
        }
        modifierClass="content__header--no-underline"
      >
        <DropdownMultiselect
          items={companiesDropdownItems}
          placeholder="Filter by company"
          isLoading={companiesIsLoading}
          disabled={companiesIsLoading || !companiesDropdownItems.length}
          handleSelectedItems={handleCompaniesSelection}
          preselectedItems={companiesFilter}
          modifierClass="mr--10 mr--md--0 mb--md--15"
          size="lg"
          searchable
          clearable
        />

        <InputFieldSearch
          placeholder="Search Users"
          size="lg"
          handleOnSearch={search => setSearchedUsers(search)}
        />
      </ContentHeader>

      <AdminUsersTable companiesFilter={companiesFilter} searchedUsers={searchedUsers} />
    </div>
  );
};

export default UsersAdmin;
