// Hooks
import { useState } from "react";

// Components
import InPersonAppointment from "./InPersonAppointment";
import VideoConferencing from "./VideoConferecing";
import { AnimatePresence } from "framer-motion";

// Assets
import InPersonAppointmentsIcon from "../../../assets/images/icons/applications-in-person-appointment-icon.svg?react";
import VideoConferenceIcon from "../../../assets/images/icons/applications-camera-icon.svg?react";

// Interfaces
import { ScheduleMeetingMenuProps } from "./interfaces";
import {
  AppointmentInPersonType,
  AppointmentVideoType,
} from "../../../api/Appointments/interfaces";

const ScheduleMeetingMenu = ({
  applicationID,
  meeting_link,
  handleCloseMenu,
}: ScheduleMeetingMenuProps) => {
  /*=======================
    CONTROL ACTIVE TAB
  ========================*/
  const [activeTab, setActiveTab] = useState<AppointmentVideoType | AppointmentInPersonType>(
    "video",
  );

  return (
    <div className="applications-schedule-meeting">
      <div className="applications-schedule-meeting__tabs">
        <div
          className={`applications-schedule-meeting__tabs__item applications-schedule-meeting__tabs__item--in-person ${
            activeTab === "in-person" ? "applications-schedule-meeting__tabs__item--active" : ""
          }`}
          onClick={() => setActiveTab("in-person")}
        >
          <InPersonAppointmentsIcon />
          <span>In-Person Appointments</span>
        </div>
        <div
          className={`applications-schedule-meeting__tabs__item applications-schedule-meeting__tabs__item--video ${
            activeTab === "video" ? "applications-schedule-meeting__tabs__item--active" : ""
          }`}
          onClick={() => setActiveTab("video")}
        >
          <VideoConferenceIcon />
          <span>Video Conferencing</span>
        </div>
      </div>

      <AnimatePresence initial={false} custom={activeTab}>
        <>
          {activeTab === "video" ? (
            <VideoConferencing
              applicationID={applicationID}
              meeting_link={meeting_link}
              handleCloseMenu={handleCloseMenu}
              framerAnimationProps={activeTab}
            />
          ) : (
            <InPersonAppointment
              applicationID={applicationID}
              handleCloseMenu={handleCloseMenu}
              framerAnimationProps={activeTab}
            />
          )}
        </>
      </AnimatePresence>
    </div>
  );
};

export default ScheduleMeetingMenu;
