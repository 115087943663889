import { DropdownItem } from "../../../../Dropdown/interfaces";

export const FIRSTCHOICE_DROPDOWNS_CATEGORIES: DropdownItem[] = [
  {
    text: "Accounting",
    value: "Accounting",
  },
  {
    text: "Admin-Clerical",
    value: "Admin-Clerical",
  },
  {
    text: "Automotive",
    value: "Automotive",
  },
  {
    text: "Banking",
    value: "Banking",
  },
  {
    text: "Biotech",
    value: "Biotech",
  },
  {
    text: "Business Development",
    value: "Business Development",
  },
  {
    text: "Caregiving",
    value: "Caregiving",
  },
  {
    text: "Construction",
    value: "Construction",
  },
  {
    text: "Consultant",
    value: "Consultant",
  },
  {
    text: "Customer Service",
    value: "Customer Service",
  },
  {
    text: "Design",
    value: "Design",
  },
  {
    text: "Education",
    value: "Education",
  },
  {
    text: "Electronics",
    value: "Electronics",
  },
  {
    text: "Energy",
    value: "Energy",
  },
  {
    text: "Engineering",
    value: "Engineering",
  },
  {
    text: "Entertainment",
    value: "Entertainment",
  },
  {
    text: "Entry Level",
    value: "Entry Level",
  },
  {
    text: "Executive",
    value: "Executive",
  },
  {
    text: "Facilities",
    value: "Facilities",
  },
  {
    text: "Fashion",
    value: "Fashion",
  },
  {
    text: "Finance",
    value: "Finance",
  },
  {
    text: "General Business",
    value: "General Business",
  },
  {
    text: "General Labor",
    value: "General Labor",
  },
  {
    text: "Government",
    value: "Government",
  },
  {
    text: "Healthcare",
    value: "Healthcare",
  },
  {
    text: "Hospitality-Hotel",
    value: "Hospitality-Hotel",
  },
  {
    text: "Human Resources",
    value: "Human Resources",
  },
  {
    text: "Information Technology",
    value: "Information Technology",
  },
  {
    text: "Installation-Maint-Repair",
    value: "Installation-Maint-Repair",
  },
  {
    text: "Insurance",
    value: "Insurance",
  },
  {
    text: "Inventory",
    value: "Inventory",
  },
  {
    text: "Law Enforcement",
    value: "Law Enforcement",
  },
  {
    text: "Legal",
    value: "Legal",
  },
  {
    text: "Management",
    value: "Management",
  },
  {
    text: "Manufacturing",
    value: "Manufacturing",
  },
  {
    text: "Marketing",
    value: "Marketing",
  },
  {
    text: "Media",
    value: "Media",
  },
  {
    text: "Mining",
    value: "Mining",
  },
  {
    text: "Miscellaneous",
    value: "Miscellaneous",
  },
  {
    text: "Nonprofit",
    value: "Nonprofit",
  },
  {
    text: "Nurse",
    value: "Nurse",
  },
  {
    text: "Pharmaceutical",
    value: "Pharmaceutical",
  },
  {
    text: "Professional Services",
    value: "Professional Services",
  },
  {
    text: "Publishing",
    value: "Publishing",
  },
  {
    text: "Purchasing-Procurement",
    value: "Purchasing-Procurement",
  },
  {
    text: "QA-Quality Control",
    value: "QA-Quality Control",
  },
  {
    text: "Real Estate",
    value: "Real Estate",
  },
  {
    text: "Research",
    value: "Research",
  },
  {
    text: "Restaurant-Food Service",
    value: "Restaurant-Food Service",
  },
  {
    text: "Retail",
    value: "Retail",
  },
  {
    text: "Sales",
    value: "Sales",
  },
  {
    text: "Science",
    value: "Science",
  },
  {
    text: "Security Clearance",
    value: "Security Clearance",
  },
  {
    text: "Skilled Labor",
    value: "Skilled Labor",
  },
  {
    text: "Sports",
    value: "Sports",
  },
  {
    text: "Strategy-Planning",
    value: "Strategy-Planning",
  },
  {
    text: "Telecommunications",
    value: "Telecommunications",
  },
  {
    text: "Training",
    value: "Training",
  },
  {
    text: "Translation",
    value: "Translation",
  },
  {
    text: "Transportation",
    value: "Transportation",
  },
  {
    text: "Veterinary Services",
    value: "Veterinary Services",
  },
  {
    text: "Warehousing-Logistics",
    value: "Warehousing-Logistics",
  },
];

export const FIRSTCHOICE_DROPDOWNS_JOB_TYPES: DropdownItem[] = [
  {
    text: "Full Time",
    value: "Full time",
  },
  {
    text: "Part Time",
    value: "Part time",
  },
  {
    text: "Contractor",
    value: "Contractor",
  },
  {
    text: "Intern",
    value: "Intern",
  },
  {
    text: "Seasonal",
    value: "Seasonal",
  },
];

export const FIRSTCHOICE_DROPDOWNS_SALARY_PERIOD: DropdownItem[] = [
  {
    text: "Hourly",
    value: "hourly",
  },
  {
    text: "Daily",
    value: "daily",
  },
  {
    text: "Weekly",
    value: "weekly",
  },
  {
    text: "Monthly",
    value: "monthly",
  },
  {
    text: "Yearly",
    value: "yearly",
  },
];
