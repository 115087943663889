import { useMemo } from "react";
import { useAdminGetAllCompanies } from "../../../../../api/Company/AdminCompany";
import { AdminCompaniesListResponseFields } from "../../../../../api/Company/interfaces";
import { DropdownItem } from "../../../../../components/Dropdown/interfaces";
import { handleStringCapitalization } from "../../../../../utilities/strings/handleStringCapitalization";

type AdminUsersCompaniesHookReturn = [DropdownItem[], boolean];

/**
 * Custom hook for fetching the companies list and then
 * mapping the received list of companies into a list of dropdown items
 * @returns A list of companies mapped as dropdown items, and a loading indicator
 */
export default function useAdminUsersCompanies(): AdminUsersCompaniesHookReturn {
  const { data, isLoading: companiesLoading } = useAdminGetAllCompanies();

  // Map the receivied companies data into dropdown items
  const companies = useMemo(() => {
    // Exit & return default value if there's no data
    if (!data || !data.length || companiesLoading) return [];

    // Map the companies to dropdown items
    const companyDropdownItems: DropdownItem[] = data
      .map((company: AdminCompaniesListResponseFields) => {
        return { text: handleStringCapitalization(company.name, [" "]), value: company.id };
      })
      .sort((companyA, companyB) => {
        return companyA.text.toLowerCase() > companyB.text.toLowerCase() ? 1 : -1;
      });

    return companyDropdownItems;
  }, [data]);

  return [companies, companiesLoading];
}
