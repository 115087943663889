import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

const ApplicationCardGridSkeletons = () => {
  return (
    <div className="row gutters-sm" data-testid="component:applications-cards-skeleton">
      {[...Array(4).keys()].map((_, index) => (
        <motion.div
          className="col-12 col-md-6 col-lg-4 col-xl-3 mb--20"
          key={`application-card-grid-skeleton-${index}`}
          initial={{ opacity: 0, translateY: "30px" }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: "30px" }}
          transition={{
            delay: 0.3 * index,
            type: "spring",
            repeat: Infinity,
            repeatDelay: 0.6,
            repeatType: "mirror",
          }}
        >
          <div className="applications applications--grid">
            <div className="applications__header">
              <Skeleton width={60} height={15} />

              <Skeleton width={80} height={15} />
            </div>

            {/* USER PHOTO / INITIALS / RATING / APPOINTMENTS */}
            <div className="d-flex justify-content-between align-items-center my--10">
              <Skeleton width={50} height={15} />
              <Skeleton width={80} height={80} circle />
              <Skeleton width={50} height={15} />
            </div>

            {/* USER NAME / ROLE */}
            <div className="applications__details">
              <Skeleton width={150} height={20} />
              <Skeleton width={100} height={15} />
            </div>

            {/* USER ADDRESS / CONTACT PHONE */}
            <div className="applications__contact">
              <Skeleton width={100} height={15} />
              <Skeleton width={100} height={15} className="ml--10" />
            </div>

            {/* USER SOCIALS ICONS */}
            <div className="applications__socials">
              <Skeleton width={30} height={25} />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
              <Skeleton width={30} height={25} className="ml--5" />
            </div>

            {/* FOOTER */}
            <div className="d-flex justify-content-between align-items-end">
              <Skeleton width={20} height={20} circle />
              <Skeleton width={100} height={25} />
              <Skeleton width={20} height={20} circle />
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default ApplicationCardGridSkeletons;
