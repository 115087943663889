// Hooks & Utilities
import { useParams } from "react-router-dom";
import {
  usePrivilegesGetCompanyPermissionsAndGroups,
  usePrivilegesGetUser,
  usePrivilegesUserUpdate,
} from "../../api/Privileges/Privileges";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  handlePrivilegesCheckboxPreselection,
  handlePrivilegesFieldLabelMarking,
  handlePrivilegesGroupFieldLabelMarking,
} from "./utils";
import { useBackNavigation } from "../../hooks/useBackNavigation";

// Assets
import { FaChevronLeft as BackIcon } from "react-icons/fa";

// Components
import { ErrorMessage, Field, Form, Formik } from "formik";
import ContentHeader from "../../components/Content/ContentHeader";
import Card from "../../components/Card/Card";
import FormDropdownSearchable from "../../components/Form/FormDropdownSearchable";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import FormCheckbox from "../../components/Form/FormCheckbox";
import Skeleton from "react-loading-skeleton";
import PrivilegesUserSkeleton from "./Skeletons/PrivilegesUserSkeleton";
import Banner from "../../components/Banner/Banner";

// Interfaces
import { DropdownItem } from "../../components/Dropdown/interfaces";
import { PrivilegesUserUpdateFields } from "../../api/Privileges/interfaces";

// Schemas
import { PRIVILEGES_USER_UPDATES_SCHEMA } from "../../schemas/PrivilegesSchemas";
import useErrorReporting from "../../hooks/useErrorReporting";
import { AnimatePresence } from "framer-motion";
import Loader from "../../components/Loader/Loader";

interface PrivilegesConfirmationModal {
  title: string;
  text: React.ReactNode;
  company_action: "single" | "all";
}

const PrivilegesUserUpdates = () => {
  const { userID } = useParams();
  const errorReporting = useErrorReporting();

  /*====================================
    FETCH THE USER PRIVILEGES DATA
  =====================================*/
  const { data, isLoading, isRefetching } = usePrivilegesGetUser(userID);

  const PRIVILEGES_DATA = useMemo(() => {
    if (!data || !Object.entries(data).length || isLoading) {
      return {
        companies: [],
        groups: [],
        permissions: {
          admin: [],
          public: [],
        },
        user: { full_name: "", has_admin_role: false },
      };
    }

    return {
      companies: data.companies,
      groups: data.groups,
      permissions: {
        admin: data.permissions.Admin,
        public: data.permissions.Public,
      },
      user: data.user,
    };
  }, [data]);

  /*====================================
    USER'S COMPANIES
  =====================================*/
  const [companies, setCompanies] = useState<DropdownItem[]>([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState<number | null>(null);

  useEffect(() => {
    if (!PRIVILEGES_DATA.companies.length) return;

    // Map the received companies data into a dropdown-suitable dataset
    const COMPANIES_MAPPED: DropdownItem[] = PRIVILEGES_DATA.companies
      .map(company => {
        return { text: company.name, value: company.id };
      })
      .sort((companyA, companyB) => {
        return companyA.text.toLowerCase() > companyB.text.toLowerCase() ? 1 : -1;
      });

    setCompanies(COMPANIES_MAPPED);
    setSelectedCompanyID(COMPANIES_MAPPED[0].value as number);
  }, [PRIVILEGES_DATA.companies]);

  /*====================================
    FETCH GROUPS AND PERMISSIONS FOR
    SELECTED COMPANY
  =====================================*/
  const { data: companyDetails, isLoading: companyDetailsLoading } =
    usePrivilegesGetCompanyPermissionsAndGroups(userID, selectedCompanyID);

  /*====================================
    FORM
  =====================================*/
  const formRef = useRef<any>(null);
  const [formValues, setFormValues] = useState<PrivilegesUserUpdateFields>({
    companies: [],
    groups: [],
    permissions: [],
  });
  const [selectionDifference, setSelectionDifference] = useState<number[]>([]);

  // Handle form values reinitialization when selected company changes
  useEffect(() => {
    if (!companyDetails || !Object.entries(companyDetails).length || !selectedCompanyID) return;

    setFormValues({
      companies: [selectedCompanyID],
      groups: companyDetails.groups,
      permissions: companyDetails.permissions,
    });
  }, [companyDetails]);

  /*=============================
   PRIVILEGE GROUPS SELECTION
  ==============================*/
  const handlePrivilegesGroupSelection = async (isChecked: boolean, groupID: number) => {
    // Exit function if there's no formik reference available to work with
    if (!formRef || !formRef.current) return;

    const { values, setFieldValue, setFieldTouched } = formRef.current;

    // Add the newly selected group to the form array, or remove it if it exists
    let selectedPrivilegeGroupIDs: number[] = [...values.groups];

    if (isChecked) {
      selectedPrivilegeGroupIDs.push(groupID);
    } else {
      selectedPrivilegeGroupIDs = selectedPrivilegeGroupIDs.filter(id => id !== groupID);
    }

    // Extract the privileges belonging to each of the currently selected groups
    const unionOfPrivileges = PRIVILEGES_DATA.groups
      .filter(group => {
        return selectedPrivilegeGroupIDs.some(selectedGroupID => {
          return selectedGroupID === group.id;
        });
      })
      .flatMap(group => group.permissions);

    // A list of unique privileges IDs that belong to the selected groups
    const uniquePrivilegeIDs = [...new Set(unionOfPrivileges)];

    // Update "groups" form values and mark them as touched to handle potential errors
    await setFieldValue("groups", selectedPrivilegeGroupIDs);
    await setFieldTouched("groups");

    // Update "permissions" form values and mark them as touched to handle potential errors
    await setFieldValue("permissions", uniquePrivilegeIDs);
    await setFieldTouched("permissions");

    // Anytime a new group is selected, which causes
    // reinitialization of the selected form privileges,
    // we clear out the differences array and start from empty zero
    setSelectionDifference([]);
  };

  /*==================================
    INDIVIDUAL PRIVILEGES SELECTION
  ===================================*/
  const handleIndividualPrivilegesSelection = async (isChecked: boolean, privilegeID: number) => {
    // Exit function if there's no formik reference available to work with
    if (!formRef || !formRef.current) return;

    const { values, setFieldValue, setFieldTouched } = formRef.current;

    // Add the newly selected privilege to the form array, or remove it if it exists
    let selectedPrivilegeIDs: number[] = [...values.permissions];

    // Handle adding / removing the targeted privilege ID from the selection array
    if (isChecked) {
      selectedPrivilegeIDs.push(privilegeID);
    } else {
      selectedPrivilegeIDs = selectedPrivilegeIDs.filter(id => id !== privilegeID);
    }

    // Check for differences in the privileges selection
    // If the list of currently selected privileges differs from the
    // union of privileges extracted from all of the currently selected groups,
    // then we use this differences list to mark the corresponding privileges with an asterix.
    let selectionDifferenceCopy: number[] = [...selectionDifference];

    if (selectionDifferenceCopy.includes(privilegeID)) {
      selectionDifferenceCopy = selectionDifferenceCopy.filter(id => id !== privilegeID);
    } else {
      selectionDifferenceCopy.push(privilegeID);
    }

    // Update "permissions" form values and mark it as touched so we can handle potential errors
    await setFieldValue("permissions", selectedPrivilegeIDs);
    await setFieldTouched("permissions");

    // Update the selection difference state to mark the privileges
    setSelectionDifference(selectionDifferenceCopy);
  };

  /*====================================
    FORM SUBMISSION CONFIRMATION MODAL
  =====================================*/
  const [showConfirmationModal, setShowConfirmationModal] = useState<PrivilegesConfirmationModal>({
    title: "",
    text: "",
    company_action: "single",
  });

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal({
      title: "",
      text: "",
      company_action: "single",
    });
  };

  /*====================================
    UPDATE USER PRIVILEGES
  =====================================*/
  const updatePrivileges = usePrivilegesUserUpdate(userID);

  const handlePrivilegesUpdate = async (values: PrivilegesUserUpdateFields) => {
    try {
      // If "Save for this company" is selected, we update only the currently selected company.
      // If "Save for all companies" is selected, we update the privileges for all companies in which the user exists
      let companies: number[] = [...values.companies];

      if (showConfirmationModal.company_action === "all") {
        const extractedCompanyIDs: number[] = PRIVILEGES_DATA.companies.map(company => {
          return company.id;
        });
        companies = extractedCompanyIDs;
      }

      // Trigger API request
      await updatePrivileges.mutateAsync({ ...values, companies });

      // Close the confirmation modal and reset to default state
      handleCloseConfirmationModal();
    } catch (error) {
      errorReporting("Failed updating user privileges", error, { user_id: userID, ...values });
    }
  };

  /*==============================
    CUSTOM BACK NAVIGATION
  ===============================*/
  const handleNavigateBack = useBackNavigation();

  return (
    <div className="container py--25">
      <ContentHeader
        title={
          <>
            Edit User Privileges -{" "}
            {isLoading || isRefetching ? (
              <Skeleton width="200px" height="18px" className="ml--10" />
            ) : (
              PRIVILEGES_DATA.user.full_name || "N/A"
            )}
          </>
        }
        modifierClass="mb--30"
      />

      {companies.length > 0 ? (
        <span
          className="d-inline-flex align-items-center txt--link txt--lg mb--40"
          onClick={() => handleNavigateBack(`/account/admin/users/${userID}/edit/`)}
        >
          <BackIcon className="txt--grey" />
          <span className="txt--blue--light fw--regular ml--10">Back</span>
        </span>
      ) : null}

      {isLoading || isRefetching ? (
        <PrivilegesUserSkeleton />
      ) : companies.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1>This user is not part of any company.</h1>
          <p>Privileges cannot be updated for users that do not have active companies.</p>
          <Button
            modifierClass="btn--primary btn--fluid"
            onClick={() => handleNavigateBack(`/account/admin/users/${userID}/edit/`)}
          >
            <span className="txt--blue--light fw--regular">Go Back</span>
          </Button>
        </div>
      ) : (
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={handlePrivilegesUpdate}
          innerRef={formRef}
          validationSchema={PRIVILEGES_USER_UPDATES_SCHEMA}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              {/* UPDATE PRIVILEGES */}
              <Card modifierClass="card--padding--xl">
                <h3 className="txt--blue fw--semibold mb--20">Update Privileges</h3>
                <p className="txt--black">
                  Select the company for which the user's privileges will be updated.
                </p>

                <Field
                  component={FormDropdownSearchable}
                  items={companies}
                  id="companies"
                  name="companies"
                  size="xl"
                  placeholder="Select Company"
                  preselectedItemValue={selectedCompanyID}
                  handleFieldUpdate={(company: DropdownItem) => {
                    setSelectedCompanyID(company.value as number);
                    setFieldValue("companies", [company.value]);
                  }}
                  modifierClass="mb--30"
                  disabled={!PRIVILEGES_DATA.companies.length}
                />

                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    type="button"
                    modifierClass="btn--fluid btn--primary--light"
                    isDisabled={Object.entries(errors).length > 0 || companyDetailsLoading}
                    onClick={() => {
                      setShowConfirmationModal({
                        title: "Update for selected company",
                        text: (
                          <>
                            This action will update the user's privileges for the selected company.{" "}
                            <br /> Do you want to continue?
                          </>
                        ),
                        company_action: "single",
                      });
                    }}
                  >
                    Update for this company
                  </Button>

                  <Button
                    type="button"
                    modifierClass="btn--fluid btn--secondary ml--20"
                    isDisabled={Object.entries(errors).length > 0 || companyDetailsLoading}
                    onClick={() => {
                      setShowConfirmationModal({
                        title: "Update for all companies",
                        text: (
                          <>
                            This action will update the user's privileges for all the companies to
                            which they belong. <br />
                            Do you want to continue?
                          </>
                        ),
                        company_action: "all",
                      });
                    }}
                  >
                    Update for all companies
                  </Button>
                </div>

                {/* FORM VALIDATION ERRORS */}
                {errors.companies && touched.companies ? (
                  <span
                    className="d-block input__error my--10"
                    style={{ fontSize: "14px", marginBottom: "15px 0" }}
                  >
                    <ErrorMessage name="companies" />
                  </span>
                ) : null}

                {errors.groups && touched.groups ? (
                  <span
                    className="d-block input__error my--10"
                    style={{ fontSize: "14px", margin: "15px 0" }}
                  >
                    <ErrorMessage name="groups" />
                  </span>
                ) : null}

                {errors.permissions && touched.permissions ? (
                  <span
                    className="d-block input__error my--10"
                    style={{ fontSize: "14px", marginBottom: "15px 0" }}
                  >
                    <ErrorMessage name="permissions" />
                  </span>
                ) : null}
              </Card>

              {/* DOUBLE CONFIRMATION MODAL */}
              <AnimatePresence>
                {showConfirmationModal.title ? (
                  <Modal
                    title={showConfirmationModal.title}
                    text={showConfirmationModal.text}
                    handleCloseModal={handleCloseConfirmationModal}
                    modifierClass="modal--fixated"
                  >
                    <Button
                      modifierClass="btn--fluid btn--primary"
                      onClick={handleCloseConfirmationModal}
                      type="button"
                    >
                      Cancel
                    </Button>

                    <Button
                      modifierClass="btn--fluid btn--secondary ml--10"
                      isLoading={updatePrivileges.isLoading}
                      isDisabled={updatePrivileges.isLoading}
                    >
                      Yes, Update
                    </Button>
                  </Modal>
                ) : null}
              </AnimatePresence>

              {companyDetailsLoading ? (
                <Loader size="page" modifierWrapper="loader--page my--30" />
              ) : (
                <>
                  {selectionDifference.length > 0 ? (
                    <Banner text="* Indicates that there are differences between the currently selected privileges and the privileges that belong to the selected group(s)." />
                  ) : null}

                  {/* PRIVILEGE GROUPS */}
                  <Card modifierClass="card--padding--xl">
                    <h3 className="txt--blue fw--semibold mb--20">Privilege Groups</h3>
                    <p className="txt--black">A list of all the available privilege groups.</p>

                    <div className="row mb--20">
                      {/* PUBLIC GROUP OF PRIVILEGES */}
                      <div
                        className={`col-12 mb--30 ${
                          PRIVILEGES_DATA.user.has_admin_role ? "col-lg-6" : ""
                        }`}
                      >
                        <h5 className="txt--black fw--semibold mb--20">Public</h5>
                        <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                        {PRIVILEGES_DATA.groups.length > 0 ? (
                          PRIVILEGES_DATA.groups
                            .filter(group => !group.is_admin_only)
                            .map(group => (
                              <Field
                                key={`public-group-${group.id}`}
                                component={FormCheckbox}
                                name={`groups.${group.id}`}
                                id={`groups.${group.id}`}
                                label={handlePrivilegesGroupFieldLabelMarking(
                                  selectionDifference,
                                  values.groups,
                                  group,
                                )}
                                labelModifierClass="input__label--sm mb--0i"
                                modifierClass="d-inline-flex mt--0i mb--0i"
                                checked={handlePrivilegesCheckboxPreselection(
                                  group.id,
                                  values.groups,
                                )}
                                handleCheckboxNonFormAction={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handlePrivilegesGroupSelection(event.target.checked, group.id);
                                }}
                              />
                            ))
                        ) : (
                          <h6 className="txt--black">
                            There are no public privilege groups available.
                          </h6>
                        )}
                      </div>

                      {/* ADMIN GROUP OF PRIVILEGES */}
                      {PRIVILEGES_DATA.user.has_admin_role ? (
                        <div className="col-12 col-lg-6">
                          <h5 className="txt--black fw--semibold mb--20">Admin</h5>
                          <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                          {PRIVILEGES_DATA.groups.length > 0 ? (
                            PRIVILEGES_DATA.groups
                              .filter(group => group.is_admin_only)
                              .map(group => (
                                <Field
                                  key={`admin-group-${group.id}`}
                                  component={FormCheckbox}
                                  name={`groups.${group.id}`}
                                  id={`groups.${group.id}`}
                                  label={handlePrivilegesGroupFieldLabelMarking(
                                    selectionDifference,
                                    values.groups,
                                    group,
                                  )}
                                  labelModifierClass="input__label--sm mb--0i"
                                  modifierClass="d-inline-flex mt--0i mb--0i"
                                  checked={handlePrivilegesCheckboxPreselection(
                                    group.id,
                                    values.groups,
                                  )}
                                  handleCheckboxNonFormAction={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    handlePrivilegesGroupSelection(event.target.checked, group.id);
                                  }}
                                />
                              ))
                          ) : (
                            <h6 className="txt--black">
                              There are no admin privilege groups available.
                            </h6>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </Card>

                  {/* PRIVILEGES LISTING */}
                  <Card modifierClass="card--padding--xl p--relative">
                    {/* PUBLIC PRIVILEGES */}
                    <h3 className="txt--blue-light fw--semibold mb--20">Public Privileges List</h3>
                    <p className="txt--black mb--40">
                      Reference list of all the <strong>public</strong> privileges currently
                      available on the system.
                    </p>

                    {Object.entries(PRIVILEGES_DATA.permissions.public).length > 0 ? (
                      <div className="row mb--30">
                        {Object.entries(PRIVILEGES_DATA.permissions.public).map(privilege => {
                          const privilegeGroupname: string = privilege[0];

                          return (
                            <div
                              className="col-12 col-md-6 col-lg-3 mb--25"
                              key={`privilege-public-${privilegeGroupname}`}
                            >
                              <h6 className="mb--10">
                                <strong>{privilegeGroupname}</strong>
                              </h6>
                              <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                              {Object.values(privilege[1]).map(privilegeValue => {
                                return (
                                  <Field
                                    key={`privilege-public-${privilegeValue.id}`}
                                    component={FormCheckbox}
                                    name={`permissions.${privilegeValue.id}`}
                                    id={`permissions.${privilegeValue.id}`}
                                    label={handlePrivilegesFieldLabelMarking(
                                      selectionDifference,
                                      privilegeValue,
                                    )}
                                    labelModifierClass="input__label--sm mb--0i"
                                    modifierClass="mt--0i mb--0i"
                                    checked={handlePrivilegesCheckboxPreselection(
                                      privilegeValue.id,
                                      values.permissions,
                                    )}
                                    handleCheckboxNonFormAction={(
                                      event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      handleIndividualPrivilegesSelection(
                                        event.target.checked,
                                        privilegeValue.id,
                                      );
                                    }}
                                  />
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="txt--black">No privileges available.</p>
                    )}

                    {/* ADMIN PRIVILEGES */}
                    {PRIVILEGES_DATA.user.has_admin_role ? (
                      <>
                        <h3 className="txt--blue-light fw--semibold mb--20">
                          Admin Privileges List
                        </h3>
                        <p className="txt--black mb--40">
                          Reference list of all the <strong>admin</strong> privileges currently
                          available on the system.
                        </p>

                        {Object.entries(PRIVILEGES_DATA.permissions.admin).length > 0 ? (
                          <div className="row">
                            {Object.entries(PRIVILEGES_DATA.permissions.admin).map(privilege => {
                              const privilegeGroupname: string = privilege[0];

                              return (
                                <div
                                  className="col-12 col-md-6 col-lg-3 mb--25"
                                  key={`privilege-admin-${privilegeGroupname}`}
                                >
                                  <h6 className="mb--10">
                                    <strong>{privilegeGroupname}</strong>
                                  </h6>
                                  <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                                  {Object.values(privilege[1]).map(privilegeValue => {
                                    return (
                                      <Field
                                        key={`privilege-admin-${privilegeValue.id}`}
                                        component={FormCheckbox}
                                        name={`permissions.${privilegeValue.id}`}
                                        id={`permissions.${privilegeValue.id}`}
                                        label={handlePrivilegesFieldLabelMarking(
                                          selectionDifference,
                                          privilegeValue,
                                        )}
                                        labelModifierClass="input__label--sm mb--0i"
                                        modifierClass="mt--0i mb--0i"
                                        checked={handlePrivilegesCheckboxPreselection(
                                          privilegeValue.id,
                                          values.permissions,
                                        )}
                                        handleCheckboxNonFormAction={(
                                          event: React.ChangeEvent<HTMLInputElement>,
                                        ) => {
                                          handleIndividualPrivilegesSelection(
                                            event.target.checked,
                                            privilegeValue.id,
                                          );
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="txt--black">No privileges available.</p>
                        )}
                      </>
                    ) : null}
                  </Card>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PrivilegesUserUpdates;
