import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";

// Interfaces
import { JobBoardsFormsProps } from "../interfaces";
import { DropdownItem } from "../../../Dropdown/interfaces";

// Dropdown datasets
import { TALENT_DROPDOWN_BUDGET, TALENT_DROPDOWN_CATEGORY } from "./dropdown-data/TalentDropdowns";

const TalentForm = ({ values, validateField, setFieldValue }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.talent.enabled) validateField("talent");
  }, [values.talent]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="talent.enabled"
        id="talent.enabled"
        label="Enable Talent"
        checked={values.talent.enabled}
        labelModifierClass="input__label--sm"
      />

      {values.talent.enabled ? (
        <>
          <Field
            component={FormInput}
            name="talent.ad_title"
            id="talent.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormDropdownSearchable}
            name="talent.category"
            id="talent.category"
            label="Category"
            items={TALENT_DROPDOWN_CATEGORY}
            handleFieldUpdate={(category: DropdownItem) => {
              setFieldValue("talent.category", category.value);
            }}
            size="full"
            placeholder="None - Optional"
            preselectedItemValue={values.talent.category}
            modifierClass="dropdown--default-label mb--20"
          />

          <Field
            component={FormDropdownSearchable}
            name="talent.budget"
            id="talent.budget"
            label="Budget"
            items={TALENT_DROPDOWN_BUDGET}
            handleFieldUpdate={(budget: DropdownItem) => {
              setFieldValue("talent.budget", budget.value);
            }}
            size="full"
            placeholder="Select a budget"
            preselectedItemValue={values.talent.budget}
            modifierClass="dropdown--default-label mb--20"
          />
        </>
      ) : null}
    </>
  );
};

export default TalentForm;
