// Utilities & Hooks
import { Field, Form, Formik } from "formik";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useAuthenticationResetPassword } from "../../api/Authentication/Authentication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import FormPasswordInput from "../../components/Form/FormPasswordInput";

// Schemas
import { AUTHENTICATION_RESET_PASSWORD_SCHEMA } from "../../schemas/AuthenticationSchemas";

// Interfaces
import { ResetPasswordState } from "./interfaces";

const ResetPassword = () => {
  const errorReporting = useErrorReporting();

  /*==============================
    EXTRACT QUERY PARAMETERS
  ===============================*/
  const [searchParametersObject] = useExtractSearchParameters();

  /*==============================
    RESET PASSWORD
  ===============================*/
  const resetPassword = useAuthenticationResetPassword();

  // Send a request to the API to reset the password
  const handleResetPassword = async ({ password, password_confirmation }: ResetPasswordState) => {
    // If there's no available "token" query parameter,
    // exit function and prevent sending request to the API
    if (!searchParametersObject["token"]) return;

    try {
      await resetPassword.mutateAsync({
        token: searchParametersObject["token"],
        password,
        password_confirmation,
      });
    } catch (error) {
      errorReporting(
        "Failed 'Reset Password' action",
        error,
        {
          reset_password_token: searchParametersObject["token"],
        },
        "critical",
      );
    }
  };

  return (
    <>
      <h1 className="h4 my--30 txt--center fw--semibold">Reset Your Password</h1>

      <Formik
        initialValues={{ password: "", password_confirmation: "" }}
        validationSchema={AUTHENTICATION_RESET_PASSWORD_SCHEMA}
        enableReinitialize
        onSubmit={handleResetPassword}
      >
        <Form>
          <Field
            name="password"
            id="password"
            label="Password"
            isRequired={true}
            placeholder="*******"
            component={FormPasswordInput}
            modifierClass="mb--15"
          />

          <Field
            name="password_confirmation"
            id="password_confirmation"
            label="Confirm Password"
            isRequired={true}
            placeholder="*******"
            component={FormPasswordInput}
            modifierClass="mb--15"
          />

          <Button
            type="submit"
            modifierClass="btn--xl btn--primary"
            isLoading={resetPassword.isLoading}
            isDisabled={resetPassword.isLoading}
          >
            {resetPassword.isLoading ? "Resetting Password..." : "Reset Password"}
          </Button>

          <div className="d-flex justify-content-center">
            <Link className="txt--md my--20 mb--20 txt--underline-on-hover" to="/login/">
              Go Back
            </Link>
          </div>
        </Form>
      </Formik>

      <div className="mt-5">
        <p className="txt--gray-darker txt--center mb--30">
          <span className="d-block fw--semibold mb--5">
            Having trouble processing your reset request?
          </span>
          Call us at 1-877-449-7595 for help.
        </p>
        <p className="txt--gray-darker txt--center">
          <span className="fw--semibold mb--5">Note:</span> Please contact your local IT person or
          department to verify that the domain names firstchoicehiring.net, and
          firstchoicehiring.com are white-listed in your company's email server.
        </p>
      </div>
    </>
  );
};

export default ResetPassword;
