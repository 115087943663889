import { JobAdStatus } from "../../api/JobAds/interfaces";
import { DropdownItem } from "../../components/Dropdown/interfaces";

export const JOB_AD_TYPES: DropdownItem[] = [
  { text: "All Types", value: "all" },
  { text: "Banner", value: "Banner" },
  { text: "Blind", value: "Blind" },
  { text: "Full Graphic", value: "Full Graphic" },
  { text: "Text", value: "Text" },
];

export const JOB_ADS_STATUS_COLUMN_NAME_MAPPINGS: Partial<
  Record<JobAdStatus, "Expired" | "Posted" | "Created">
> = {
  all: "Created",
  expired: "Expired",
  takedown: "Posted",
  active: "Posted",
  new: "Created",
  pending: "Created",
  ready_to_post: "Created",
};

export const JOB_ADS_DATE_FIELD_MAPPINGS: Record<
  JobAdStatus,
  "create_date" | "expire_date" | "live_date"
> = {
  all: "create_date",
  new: "create_date",
  pending: "create_date",
  ready_to_post: "create_date",
  active: "live_date",
  takedown: "live_date",
  expired: "expire_date",
};

export const JOB_ADS_STATUS_UPDATES_TEXT_MAPPINGS: Partial<Record<JobAdStatus, string>> = {
  new: "create this ad?",
  pending: "move this ad to pending state?",
  ready_to_post: "mark this ad as ready to post?",
  active: "publish this ad?",
  takedown: "take down this ad?",
  expired: "mark this ad as expired?", // Text to be confirmed
};

export const JOB_ADS_CLIENTS_FILTER_LETTERS = [
  "#",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "all",
];
