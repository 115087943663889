// Hooks & Utilities
import { getIn } from "formik";
import { useEffect, useState } from "react";

// Assets
import DeleteIcon from "../../assets/images/icons/appointment-delete-icon.svg?react";

// Interfaces
import { FormInputBubblesModel, FormInputBubblesProps } from "./interfaces";

const FormInputBubbles = ({
  form,
  field,
  label,
  isRequired = false,
  modifierClass = "",
  description = "",
  size = "full",
  bubbledValues,
  handleBubbledValues,
  textInput,
  handleTextInput,
  delimiters = [";", "Enter"],
  internalDelimiter = ";",
  ...props
}: FormInputBubblesProps) => {
  /*===============================
    HANDLE FORMIK ERRORS
  ================================*/
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    HANDLE DELETE BUBBLE BUTTON
  ================================*/
  const handleRemoveBubble = (selectedValueId: number) => {
    const copyBubbledValues = [...bubbledValues];

    const bubbleToRemove = copyBubbledValues.findIndex(entity => {
      return entity.id === selectedValueId;
    });

    copyBubbledValues.splice(bubbleToRemove, 1);
    handleBubbledValues([...copyBubbledValues]);
    form.setFieldValue(
      field.name,
      copyBubbledValues.map(bubbledValue => bubbledValue.value).join(internalDelimiter),
    );
  };

  /*===============================
    HANDLE INPUT CHANGE
  ================================*/
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Manually update the touched state of the field upon change
    if (!touched) form.setFieldTouched(field.name, true);

    // Ignore provided delimiters as  values if they are inserted as single value
    if (delimiters.some(delimiter => event.target.value === delimiter)) return handleTextInput("");

    // Replace all provided delimiters from the input with a semicolon for unification
    let value = event.target.value;
    delimiters.forEach((delimiter: string) => {
      value = value.replaceAll(delimiter, internalDelimiter);
    });

    // Split the unified delimiters value by the set semicolon
    if (value.split(internalDelimiter).length > 1) {
      const copyFinalValues = value.split(internalDelimiter).map((value, index) => {
        return { id: index + 1, value: value };
      });

      // Filter out the empty values after the delimiter unification split
      const filteredDelimiterValues = copyFinalValues.filter(
        bubble => bubble.value !== "" || delimiters.includes(bubble.value),
      );

      // Update the bubbledValues state
      handleBubbledValues([...bubbledValues, ...filteredDelimiterValues]);

      // Update the form value
      form.setFieldValue(
        field.name,
        filteredDelimiterValues.map(finalValue => finalValue.value).join(internalDelimiter),
      );
    } else {
      handleTextInput(value);
    }
  };

  /*===============================
    HANDLE BUBBLING
  ================================*/
  const handleBubbleSet = (event: React.KeyboardEvent<HTMLElement>) => {
    // If the input is empty and 'Backspace' has been pressed, remove the last bubbled element
    if (!textInput && event.key === "Backspace" && bubbledValues && bubbledValues.length) {
      const copyBubbledValues = [...bubbledValues];
      copyBubbledValues.pop();
      form.setFieldValue(
        field.name,
        copyBubbledValues.map(bubbledValue => bubbledValue.value).join(internalDelimiter),
      );

      handleBubbledValues(copyBubbledValues);
    }

    // Key triggers are the provided delimiters
    if (delimiters.includes(event.key) && textInput) {
      //disable form submission on enter press
      event.preventDefault();

      // Push current textInput as bubble
      const copyBubbledValues = [...bubbledValues];
      copyBubbledValues.push({ id: bubbledValues.length, value: textInput });
      handleBubbledValues(copyBubbledValues);

      // Push current textInput into form field
      form.setFieldValue(
        field.name,
        copyBubbledValues.map(value => value.value).join(internalDelimiter),
      );
      handleTextInput("");
    }
  };

  // Convert any existing value in the input field that wasn't already converted to a bubble
  // when the user clicks anywhere outside of the input field
  const handleIncompleteValueBubbling = () => {
    // Do not trigger any action if there's no value in the input field
    if (!textInput) return;

    // Push current textInput as bubble
    const copyBubbledValues = [...bubbledValues];
    copyBubbledValues.push({ id: bubbledValues.length, value: textInput });
    handleBubbledValues(copyBubbledValues);

    // Push current textInput into form field
    form.setFieldValue(
      field.name,
      copyBubbledValues.map(value => value.value).join(internalDelimiter),
    );
    handleTextInput("");
  };

  /*===============================
    FAULTY VALUES STATE

    Used to color bubbles that
    fail validation schema.
  ================================*/
  const [faultyValues, setFaultyValues] = useState<string[]>([]);

  useEffect(() => {
    // Empty the erroneous values state if there are none
    if (!errors) return setFaultyValues([]);

    // Split the errors into individual strings
    const errorsArray = errors.split(",") ?? [];

    // Extract only the value from the error message & put into state
    // NOTE:Always put the erroneous value as first entity in validation schema error message
    setFaultyValues(errorsArray.map((value: string) => value.split(" ")[0]));
  }, [errors]);

  return (
    <>
      <div className={`input input-side-label input--${size} ${modifierClass}`}>
        {label && (
          <label
            className={`input__label ${isRequired ? "input__label--required" : ""}`}
            htmlFor={field.name}
          >
            {label}
          </label>
        )}

        <div className="input__wrapper">
          <div className={`input-bubbles ${errors && touched ? "input-bubbles--error" : ""}`}>
            <label>
              {bubbledValues && bubbledValues.length
                ? bubbledValues.map((selectedValue: FormInputBubblesModel) => (
                    <span
                      className={`input-bubbles__value${
                        faultyValues.includes(selectedValue.value)
                          ? " input-bubbles__value--invalid"
                          : ""
                      } `}
                      data-testid="component:input-bubbles"
                      key={selectedValue.id}
                    >
                      {selectedValue.value}{" "}
                      <DeleteIcon
                        className="input-bubbles__icon"
                        onClick={() => handleRemoveBubble(selectedValue.id)}
                        data-testid="component:input-bubbles-delete-btn"
                      />
                    </span>
                  ))
                : null}
              <input
                onChange={handleInputChange}
                onKeyDown={handleBubbleSet}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  // Trigger formik built-in on blur event
                  field.onBlur(event);

                  // Bubble up any incomplete values
                  handleIncompleteValueBubbling();
                }}
                {...props}
              />
            </label>
          </div>

          {/* DISPLAY ERROR MESSAGES */}
          {errors &&
            touched &&
            errors.split(",").map((error: string, index: number) => (
              <p className="input__error" key={error + index}>
                {error}
              </p>
            ))}

          {description && <p className="input__description">{description}</p>}
        </div>
      </div>
    </>
  );
};

export default FormInputBubbles;
