/**
 *
 * Utility function that will strip a given string if the string
 * ends with the specified character.
 *
 * For example if provided string is "Example 123," and the provided
 * ending character to be removed is "," then the result will be "Example 123"
 *
 * @param str String to be checked and stripped from the specified ending character
 * @param endingCharacter The character used to check if the string ends with it so it can be removed
 * @param defaultReturn The default string to be returned by the function in case there's no valid string value received
 *
 */
export default function handleStripStringFromLastCharacter(
  str: string | null | undefined,
  endingCharacter: string,
  defaultReturn: string = "",
): string {
  if (!str) return defaultReturn;
  return str.endsWith(endingCharacter) ? str.slice(0, str.length - 1) : str;
}
