// Hooks
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useJobAdsGetClientDetails } from "../../../api/JobAds/JobAdsClients";
import handleStripStringFromLastCharacter from "../../../utilities/strings/handleStripStringFromLastCharacter";

// Components
import Card from "../../../components/Card/Card";
import JobAdHeader from "../../../components/JobAds/JobAdHeader";
import JobAdsLatestCard from "../../../components/JobAds/JobAdsLatestCard";
import JobAdsOverviewSkeleton from "../Skeleton/JobAdsOverviewSkeleton";

// Assets
import JobAdsClientDefaultLogo from "../../../assets/images/job-ads-client-default-logo.png";
import { handleStripProtocolFromLinks } from "../../../utilities/strings/handleStripProtocolFromLinks";

const JobAdsClientDetails = () => {
  // Read the client (company) slug from the URL
  const { slug } = useParams();

  /*==============================
    FETCH CLIENT DETAILS DATA
  ===============================*/
  const { data, isLoading, isRefetching } = useJobAdsGetClientDetails(slug);

  /*==============================
    APPLICATION FORM URL
  ===============================*/
  const [clientFormURL, setClientFormURL] = useState<string>("");

  useEffect(() => {
    if (!data || !Object.entries || isLoading) return;
    const hostURL: string = window.location.host;
    const hostProtocol: string = window.location.protocol;
    const formURL: string = `${hostProtocol}//${hostURL}/online-application/initialize-oa/${data.form_identifier}`;

    setClientFormURL(formURL);
  }, [data]);

  return (
    <div className="job-ads">
      <JobAdHeader />

      <div className="container py--25">
        {isLoading || isRefetching ? (
          <JobAdsOverviewSkeleton />
        ) : (
          <>
            <Card modifierClass="card--padding--xl">
              <div className="row mb--20">
                {/* CLIENT INFO SECTION*/}
                <div className="col-12 col-lg-4 mb--20">
                  <h3 className="fw--semibold mb--10">
                    {data?.name ?? "N/A"}{" "}
                    <span
                      className={`txt--capitalize txt--blue-dark ${
                        data?.status === "disabled" ? "txt--error" : ""
                      }`}
                    >
                      ({data?.status})
                    </span>
                  </h3>

                  <p
                    className="txt--black fw--medium mb--10"
                    data-testid="component:job-ads-client-details-address"
                  >
                    {handleStripStringFromLastCharacter(data?.address.street, ",")}
                    {data?.address.city
                      ? `, ${handleStripStringFromLastCharacter(data.address.city, ",")}, `
                      : ""}
                    {data?.address.state
                      ? `${handleStripStringFromLastCharacter(data.address.state, ",")}, `
                      : ""}
                    {data?.address.zip
                      ? `${handleStripStringFromLastCharacter(data.address.zip, ",")}`
                      : ""}
                  </p>

                  {data?.salesforce ? (
                    <a
                      className="fw--medium txt--blue-light"
                      href={data.salesforce}
                      target="_blank"
                      rel="noopener"
                      style={{ fontSize: "13px" }}
                    >
                      SalesForce Account
                    </a>
                  ) : null}
                </div>

                {/* CLIENT LOGO SECTION */}
                <div className="col-12 col-lg-4 mb--20 d-flex justify-content-center">
                  {data?.logo ? (
                    <img
                      src={data.logo}
                      alt="Client Logo"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  ) : (
                    <img src={JobAdsClientDefaultLogo} alt="Client Logo" />
                  )}
                </div>

                {/* CLIENT CONTACT SECTION */}
                <div className="col-12 col-lg-4 mb--20">
                  {data?.phone ? (
                    <a href={`tel:+1${data.phone}`} className="d-block txt--black mb--10">
                      <span className="fw--semibold">P:</span> {data.phone}
                    </a>
                  ) : (
                    <span className="d-block txt--black mb--10">
                      <span className="fw--semibold">P:</span> N/A
                    </span>
                  )}

                  {data?.website ? (
                    <a
                      href={handleStripProtocolFromLinks(data.website)}
                      className="fw--medium txt--blue-light"
                      target="_blank"
                      rel="noopener"
                      style={{ fontSize: "13px" }}
                      data-testid="component:job-ads-client-details-website-link"
                    >
                      {data.website}
                    </a>
                  ) : null}
                </div>
              </div>

              <hr className="mb--20" />

              <p className="fw--semibold mb--10">
                <span className="txt--gray--darkest">Website Application: </span>
                <a className="txt--blue-light break-word" href={clientFormURL} target="_blank">
                  {clientFormURL}
                </a>
              </p>
            </Card>

            <JobAdsLatestCard
              adGroupStatus="new"
              count={data?.ads.new.count ?? 0}
              ads={data?.ads.new.ads ?? []}
              clientName={data?.name}
            />

            <JobAdsLatestCard
              adGroupStatus="pending"
              count={data?.ads.pending.count ?? 0}
              ads={data?.ads.pending.ads ?? []}
              clientName={data?.name}
            />

            <JobAdsLatestCard
              adGroupStatus="ready_to_post"
              count={data?.ads.ready_to_post.count ?? 0}
              ads={data?.ads.ready_to_post.ads ?? []}
              clientName={data?.name}
            />

            <JobAdsLatestCard
              adGroupStatus="active"
              count={data?.ads.active.count ?? 0}
              ads={data?.ads.active.ads ?? []}
              clientName={data?.name}
            />

            <JobAdsLatestCard
              adGroupStatus="takedown"
              count={data?.ads.takedown.count ?? 0}
              ads={data?.ads.takedown.ads ?? []}
              clientName={data?.name}
            />

            <JobAdsLatestCard
              adGroupStatus="expired"
              count={data?.ads.expired.count ?? 0}
              ads={data?.ads.expired.ads ?? []}
              clientName={data?.name}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default JobAdsClientDetails;
