export const PASSWORD_REGEX_PATTERN: RegExp =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-=[\]{}\\|'";:/?.,<>~`])([a-zA-Z0-9!@#$%^&*()-=[\]{}\\|'";:/?.,<>~`]+){8,}$/;

export const EMAIL_REGEX_PATTERN: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const YOUTUBE_URL_REGEX_PATTERN: RegExp =
  /https:\/\/(www\.youtube\.com\/watch\?v|youtu\.be\/|www\.youtube\.com\/embed\/|youtube.com\/watch\?v)/;

export const SLUG_REGEX_PATTERN: RegExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const WEBSITE_URL_REGEX_PATTERN: RegExp =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/;

export const LINKED_IN_URL_REGEX_PATTERN: RegExp =
  /^(http(s)?:\/\/)?([\w]+\.)?((linkedin\.com)|(linked\.in))\/(mwlite\/)?(in|pub|profile)\/.*/gi;
