// Utilities & Hooks
import { getIn } from "formik";
import { useEffect, useRef, useState } from "react";
import handleExtractFileExtension from "../../utilities/strings/handleExtractFileExtension";
import handleCheckIfChromeAndroid from "../../utilities/handleCheckIfChromeAndroid";

// Components
import Button from "../Button/Button";
import Tooltip from "../Tooltip/Tooltip";
import Modal from "../Modal/Modal";
import Alert from "../Alert/Alert";

// Interfaces
import { FormUploadProps } from "./interfaces";

// Assets
import { MdClear as ClearUploadIcon } from "react-icons/md";
import { AnimatePresence } from "framer-motion";

const FormUpload = ({
  form,
  field,
  buttonText,
  placeholder,
  modifierClass = "",
  label = "",
  isRequired = false,
  thumbnailPreview = "",
  thumbnailPreviewModifiers = "",
  info = null,
  clearableFromParent = false,
  hasConfirmationForClearAction = false,
  uploadType = "image",
  fieldHasDedicatedFileTypeValidation = false,
  disabled = false,
  shouldAllowPreivewClearance = false,
  previewClearanceCallback,
  ...props
}: FormUploadProps) => {
  /*==========================
    PREVIEW IMAGE 
  ===========================*/
  const thumbnailRef = useRef<HTMLImageElement | null>(null);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [hasThumbnailImagePreview, setHasThumbnailImagePreview] = useState<boolean>(false);

  // Display the thumbnail preview image
  // if there's one passed as a prop (e.g. fetched from the API)
  useEffect(() => {
    if (!thumbnailPreview) return;
    setPreviewImage(thumbnailPreview);
    setHasThumbnailImagePreview(true);
  }, [thumbnailPreview]);

  /*==========================
    FIELD ERRORS
  ===========================*/
  const errors = getIn(form.errors, field.name);

  /*==========================
    UPLOAD INPUT REFERENCE
  ===========================*/
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleFileUploadButtonClick = () => {
    if (!hiddenFileInput.current) return;

    hiddenFileInput.current.click();
  };

  /*==========================
    TRIGGER FILE UPLOAD
  ===========================*/
  const [fileName, setFileName] = useState<string>("");

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Exit function and prevent uploads if the field is marked as "disabled"
    if (disabled) return;

    // Exit function if there weren't any files selected
    if (!event.target.files) return;

    const selectedFile = event.target.files[0];

    // Read the received 'accept' prop values, representing
    // each of the individual file types that can be selected
    const { accept } = props as any;

    // Extract the individual accepted file types,
    // sanitize them, and map them into an array of strings,
    // that will be then checked if the selected file's file type
    // matches some of the file types in the provided list
    const acceptedFileTypes: string[] = accept
      .split(".")
      .filter((fileType: string) => fileType != "")
      .map((fileType: string) => {
        const sanitizedFileType: string = fileType.trim();
        return sanitizedFileType.replace(/,/gi, "");
      });

    // Check if the file type of the file that we selected for upload
    // matches some of the accepted file types received as a prop
    const isSupportedFileType: boolean = acceptedFileTypes.some(fileType => {
      const fileExtension: string = handleExtractFileExtension(selectedFile.name);
      return fileExtension.toLowerCase() === fileType.toLowerCase();
    });

    // If the selected file does not match any of the accepted file types,
    // then throw an error and exit function preventing file selection.
    if (!isSupportedFileType) {
      form.setFieldError(
        field.name,
        `Unsupported file type. Selected file must be of one of the following types: ${accept}`,
      );

      /*

        TODO: Rethink approach, maybe letting defined validation
        schemas handle the error cases (invalid file types) scenarios?

        ==============================================

        In case the file has a dedicated file type validation,
        trough a defined validation schema 'test' that checks the file type,
        then we update the form field's value and let the validation schema
        handle the error scenario and prevention of uploading the file

      */
      if (fieldHasDedicatedFileTypeValidation) form.setFieldValue(field.name, selectedFile);

      return;
    }

    // If File Reader is not available to the user's browser, prevent trying to
    // initialize it and show an error in the UI
    if (!window.FileReader) {
      form.setFieldError(
        field.name,
        "This browser does not have support for file uploads. Please try again with a modern browser.",
      );
      return;
    }

    // Creates a thumbnail image preview of the
    // image selected for uploading
    const reader = new FileReader();

    // If the uploaded file is an image, show a thumbnail preview image
    if (uploadType === "image") {
      reader.onload = function (readerEvent: any) {
        // Initialize image object so we can read the dimensions before proceeding
        const image = new Image();
        image.src = readerEvent.target.result;

        // If there's no available reference to the thumbnail element,
        // exit the function and prevent generating the thumbnail image
        if (!thumbnailRef.current) return;

        // Update the thumbnail's source
        thumbnailRef.current.src = readerEvent.target.result;

        // Toggle the transition class for the thumbnail image preview element
        setHasThumbnailImagePreview(true);

        // Update the text representing the selected filename
        // and update the form field's value with the selected file
        setFileName(selectedFile.name);

        // Update the state of the targeted form field
        form.setFieldValue(field.name, selectedFile);
      };
    } else {
      // Update the "file" field value and let validation schema
      // do the check if if the file we're trying to upload is valid or not
      setFileName(selectedFile.name);
      form.setFieldValue(field.name, selectedFile);
    }

    reader.readAsDataURL(selectedFile);
  };

  /*==========================
  CLEAR THE SELECTED FILE
  ===========================*/
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  // If the component received the prop to include confirmation
  // for the clearance action, then show a modal.
  // Otherwise, directly remove the selected file.
  const handleClearAction = () => {
    if (hasConfirmationForClearAction) {
      setShowConfirmationModal(true);
    } else {
      handleClearUpload();
    }
  };

  // Clear the file that was selected to be uploaded
  const handleClearUpload = () => {
    setHasThumbnailImagePreview(false);

    // Usage of timeout here so we can have the
    // full transition effect applied before removing the
    // image source that's used to display the thumbnail
    setTimeout(() => {
      form.setFieldValue(field.name, null);
      setFileName("");
      setPreviewImage("");

      if (thumbnailRef.current) thumbnailRef.current.src = "";
    }, 400);

    // Close the confirmation modal (if it was present)
    if (showConfirmationModal) handleCloseConfirmationModal();

    // Trigger a manual function received as a prop to do extra functionality if needed
    // (e.g. clear out the original value received for the field that was used to generate the thumbnail preview)
    if (previewClearanceCallback) previewClearanceCallback();
  };

  // Close the confirmation modal and remove it from the UI
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  /*==========================
    TRIGGER FIELD CLEARANCE
    FROM THE PARENT WHERE ITS USED
  ===========================*/
  useEffect(() => {
    if (!clearableFromParent) return;

    handleClearUpload();
  }, [clearableFromParent]);

  /*=====================================
    DISPLAY FILENAME
    
    In case there is a new file uploaded
    preview it's name, else preview the 
    name of the currently used file
    fetched from api, including the
    file extension.
  ======================================*/
  const [previewFileName, setPreviewFileName] = useState<string>("");

  useEffect(() => {
    if (fileName) {
      setPreviewFileName(fileName);
    } else if (thumbnailPreview) {
      setPreviewFileName(thumbnailPreview.split("/").pop()!.split("?")[0]);
    }
  }, [fileName, thumbnailPreview]);

  return (
    <>
      <div className={`input-upload ${disabled ? "input-upload--disabled" : ""} ${modifierClass}`}>
        {handleCheckIfChromeAndroid() ? (
          <Alert
            text={
              <>
                <p className="txt--lg ">
                  Please be aware that files cannot be uploaded directly from Google Drive when
                  using Chrome Browser on an Android device.
                </p>
                <p className="txt--lg">
                  In order to upload the file make sure to select it from your device storage
                  directly.
                </p>
                <p className="txt--lg mb--0">
                  If you have it on Google Drive, please first download it to your device before
                  selecting it for upload.
                </p>
              </>
            }
            type="info"
          />
        ) : null}

        {label && (
          <label
            className={`input__label ${isRequired ? "input__label--required" : ""}`}
            htmlFor={field.name}
          >
            {label}
          </label>
        )}

        <input
          {...field}
          {...props}
          value=""
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUpload}
          disabled={disabled}
        />

        <div className="input__wrapper">
          <Button
            type="button"
            modifierClass="btn--fluid btn--primary"
            onClick={handleFileUploadButtonClick}
            isDisabled={disabled}
          >
            {buttonText}
          </Button>

          {/* DISPLAY THE NAME OF THE SELECTED FILE */}
          <div title={fileName} className={`input__field ${errors ? "input__field--error" : ""}`}>
            {fileName || placeholder}
          </div>

          {/* CLEAR SELECTION BUTTON */}
          {(fileName || (shouldAllowPreivewClearance && hasThumbnailImagePreview)) && (
            <div className="input-upload__clear" onClick={handleClearAction}>
              <Tooltip text="Remove Selected File" positioning="bottom">
                <ClearUploadIcon />
              </Tooltip>
            </div>
          )}

          {/* DISPLAY UPLOADING INFO (IF AVAILABLE) */}
          {info && <div className="input-upload__info">{info}</div>}

          {/* DISPLAY ERROR MESSAGES */}
          {errors && <p className="input__error">{errors}</p>}

          {/* PREVIEW THE IMAGE */}
          <div
            className={`input-upload__thumbnail ${
              hasThumbnailImagePreview ? "input-upload__thumbnail--active" : ""
            } ${
              hasThumbnailImagePreview && thumbnailPreviewModifiers ? thumbnailPreviewModifiers : ""
            } mt--10`}
          >
            <img
              ref={thumbnailRef}
              src={previewImage && typeof previewImage === "string" ? previewImage : ""}
            />
          </div>
          {previewFileName ? (
            <span data-testid="component:form-upload-filename-preview">{previewFileName}</span>
          ) : null}
        </div>
      </div>

      <AnimatePresence>
        {showConfirmationModal && (
          <Modal
            title="Remove selected file?"
            text="This action will remove the file that is selected to be uploaded. Are you sure you want to remove it?"
            modifierClass="modal--md modal--fixated"
            handleCloseModal={handleCloseConfirmationModal}
          >
            <div className="modal__actions">
              <Button
                type="button"
                modifierClass="btn--fluid btn--primary"
                onClick={handleCloseConfirmationModal}
              >
                Cancel
              </Button>

              <Button
                type="button"
                modifierClass="txt--capitalize btn--fluid btn--secondary"
                onClick={handleClearUpload}
              >
                Yes, Remove it
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default FormUpload;
