// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { matchSorter } from "match-sorter";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAdminClientsGet } from "../../../api/Clients/AdminClients";
import { handleStripProtocolFromLinks } from "../../../utilities/strings/handleStripProtocolFromLinks";

// Interfaces
import { AdminClientsTableProps, ClientListMappedFields } from "./interfaces";
import { AdminClientsListResponseFields } from "../../../api/Clients/interfaces";

// Components
import TableSkeletonPlaceholder from "../../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../../components/Table/Table";
import PermissionCheckComponentWrapper from "../../../components/Wrappers/PermissionCheckComponentWrapper";

const AdminClientsTable = ({ searchedClients }: AdminClientsTableProps) => {
  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<ClientListMappedFields>();
  const CLIENTS_ADMIN_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("name", {
      header: () => <span>Name</span>,
      size: 200,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("description", {
      header: () => <span>Desc</span>,
      size: 500,
      cell: data => {
        const description: string = data.getValue() || "N/A";
        return (
          <span className="txt--ellipsis-vertical white-space--pre-line" title={description}>
            {description}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      size: 100,
      cell: data => <span className="txt--capitalize">{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("website_url", {
      header: () => <span>Website</span>,
      size: 300,
      enableSorting: false,
      cell: data => (
        <>
          {data.getValue() ? (
            <a
              href={handleStripProtocolFromLinks(data.getValue() || "")}
              className="btn--text--secondary"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="component:admin-clients-table-website-link"
            >
              {data.getValue()}
            </a>
          ) : (
            "N/A"
          )}
        </>
      ),
    }),
    COLUMN_HELPER.accessor("slug", {
      header: () => <span>Action</span>,
      enableSorting: false,
      meta: {
        headerModifierClass: "justify-content-end",
      },
      cell: data => (
        <div className="table__buttons">
          <PermissionCheckComponentWrapper permissions={["client_admin_edit"]}>
            <Link
              to={`/admin/clients/${data.getValue()}/edit/`}
              className="btn btn--fluid btn--primary--light"
            >
              Edit
            </Link>
          </PermissionCheckComponentWrapper>
        </div>
      ),
    }),
  ];

  /*=========================
    CLIENTS TABLE DATA
  ==========================*/
  const { data, isLoading, isFetching } = useAdminClientsGet();

  const adminClients = useMemo(() => {
    // Exit & return a default value if there's no data
    if (!data || !data.length || isLoading) return [];

    // Map the received fields from the API to what we need for the table
    let mappedClients: ClientListMappedFields[] = data.map(
      (client: AdminClientsListResponseFields) => {
        return {
          id: client.id,
          name: client.name,
          description: client.description,
          status: client.status,
          slug: client.slug,
          website_url: client.website_url,
        };
      },
    );

    // Filter the clients based on their name if there is an existing searched value
    if (searchedClients) {
      mappedClients = matchSorter(mappedClients, searchedClients, {
        keys: ["name"],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    return mappedClients;
  }, [data, searchedClients]);

  return (
    <>
      {isLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper" />
      ) : (
        <Table
          data={adminClients}
          columns={CLIENTS_ADMIN_TABLE_COLUMNS}
          isRefetching={isFetching}
          paginationPageSize={10}
        />
      )}
    </>
  );
};

export default AdminClientsTable;
