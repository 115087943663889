// Utilities & Hooks
import { useOnlineApplicationGetAssessmentFormQuestions } from "../../../api/OnlineApplication/OnlineApplication";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useErrorReporting from "../../../hooks/useErrorReporting";
import fetchHandler from "../../../api/fetchHandler";
import handleErrorMessage from "../../../utilities/handleErrorMessage";
import handleGenerateAssessmentQuestions from "./handleGenerateAssessmentQuestions";
import handleFormikScrollToFirstError from "../../../utilities/forms/formik";

// Components
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import FormCheckbox from "../../../components/Form/FormCheckbox";
import FormDropdown from "../../../components/Form/FormDropdown";
import FormInput from "../../../components/Form/FormInput";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import OnlineApplicationColorPicker from "../../../components/OnlineApplications/OnlineApplicationColorPicker";
import OnlineApplicationAssessmentGroupQuestion from "../../../components/OnlineApplications/OnlineApplicationAssessmentGroupQuestion";
import FormInputSlider from "../../../components/Form/FormInputSlider";
import OnlineApplicationAssessmentQuestion from "../../../components/OnlineApplications/OnlineApplicationAssessmentQuestion";
import OnlineApplicationFooter from "../../../components/OnlineApplications/OnlnieApplicationFooter";

// Constants
import {
  ONLINE_APPLICATION_ASSESSMENT_STATIC_QUESTIONS,
  ONLINE_APPLICATION_ASSESSMENT_WORK_ENVIRONMENT,
} from "./constants";

// Interfaces
import { AssessmentStaticQuestionGroups } from "../interfaces";
import { OnlineApplicationAssessmentQuestionProps } from "../../../components/OnlineApplications/interfaces";
import { InternationalizationLocales } from "../../../interfaces/global";

// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_KEY } from "../../../config/config";

// Validation schemas
import { ONLINE_APPLICATION_ASSESSMENT_QUESTIONS_SCHEMA } from "../../../schemas/OnlineApplicationSchemas";

// Assets
import ComodoSecureLogo from "../../../assets/images/comodo_secure_white.png";
import FlagUS from "../../../assets/images/icons/flag-us-icon.svg?react";
import FlagES from "../../../assets/images/icons/flag-es-icon.svg?react";

const OnlineApplicationAssessment = () => {
  const navigate = useNavigate();
  const { identifier } = useParams();
  const errorReporting = useErrorReporting();

  /*==================================
    TRANSLATIONS
  ===================================*/
  const { t, i18n } = useTranslation(["sbca_assessment"]);
  const [selectedLanguage, setSelectedLanguage] = useState<InternationalizationLocales>(() => {
    // This will read the first listed language in the "Preferred Languages" of the browser
    // We're using "slice" here so it will extract only the first two letters of the value that can
    // be returned from "navigator.language" due to differences in how that value is returned
    // between Chrome / Firefox / Safari browsers.
    // Defaults to "en" in case the property is not being supported.
    const preselectedLanguage = navigator.language
      ? (navigator.language.toLowerCase().slice(0, 2) as InternationalizationLocales)
      : "en";

    return ["en", "es"].includes(preselectedLanguage) ? preselectedLanguage : "en";
  });

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  /*==================================
    GOOGLE ReCAPTCHA TOKEN
  ===================================*/
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  // Update the state for the recaptcha token,
  // so the users can proceed with submitting the form
  // NOTE: If the ReCAPTCHA has expired, this function will
  // be automatically called with and the token value will be set to `null`
  const handleGoogleRecaptchaToken = (token: string | null) => setRecaptchaToken(token);

  /*==================================
    FETCH FORM QUESTIONS
  ===================================*/
  const {
    data: questionsData,
    isLoading: questionsLoading,
    error,
  } = useOnlineApplicationGetAssessmentFormQuestions(identifier);
  const [existingSBCAError, setExistingSBCAError] = useState<boolean>(false);

  // Handle the loading state and error fallbacks of the page
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!questionsLoading && !error) {
      setPageLoading(false);
      return;
    }

    if (error) {
      const errorMessage = handleErrorMessage(error);
      if (errorMessage.toLowerCase().includes("sbca submission already submitted")) {
        setExistingSBCAError(true);
      } else {
        navigate("/404/");
      }
    }
  }, [questionsLoading]);

  /*==================================
    MAP THE RECEIVED FORM QUESTIONS
  ===================================*/
  const QUESTIONS_DATA = useMemo(() => {
    // Exit function if there's no data (yet)
    if (
      questionsLoading ||
      !questionsData ||
      !Object.entries(questionsData.questions) ||
      !questionsData.questions.en ||
      !questionsData.questions.es
    ) {
      return {
        name: "N/A",
        questions: [],
      };
    }

    // Convert the questions object, into an array of objects so we can loop trough it
    const QUESTIONS_LIST: OnlineApplicationAssessmentQuestionProps[] = Object.entries(
      questionsData.questions[selectedLanguage],
    ).map(question => {
      const [key, value] = question;
      return {
        question_name: key,
        question_text: value,
      };
    });

    return { name: questionsData.name ?? "N/A", questions: QUESTIONS_LIST };
  }, [questionsData, selectedLanguage]);

  /*==================================
    FORM VALUES
  ===================================*/
  const [formInitialValues, setFormInitialValues] = useState<Record<string, any>>({
    accept: false,
    extra_curricular: "",
    job_father: "",
    job_mother: "",
    favorite_member: null,

    // These are the color-selection questions,
    // which are defined as statics
    q13: [],
    q14: [],
    q15: [],
  });

  /*==================================
    FORM VALIDATION SCHEMA
  ===================================*/
  const [formValidationSchema, setFormValidationSchema] = useState<any>(
    ONLINE_APPLICATION_ASSESSMENT_QUESTIONS_SCHEMA,
  );

  // Append all the fetched questions received in the API response
  // to the form's initial values object
  useEffect(() => {
    if (!QUESTIONS_DATA.questions.length) return;

    const appendedFormInitialValues: Record<string, number> = {};
    QUESTIONS_DATA.questions.forEach(question => {
      appendedFormInitialValues[question.question_name] = 1;
    });

    // Go trough the list of static 31 questions and generate
    // their corresponding field and group field names, and the
    // validation schemas for each individual field
    const { questionGroups, questionFields, VALIDATION_SCHEMA } = handleGenerateAssessmentQuestions(
      ONLINE_APPLICATION_ASSESSMENT_STATIC_QUESTIONS,
    );

    // Update the state for Formik's initial form values
    // and the validation schema that will be used
    setFormInitialValues({
      ...formInitialValues,
      ...appendedFormInitialValues,
      ...questionGroups,
      ...questionFields,
    });
    setFormValidationSchema(VALIDATION_SCHEMA);
  }, [QUESTIONS_DATA]);

  /*=========================
    ASSESSMENT FORM
  ==========================*/
  const [ongoingFormSubmission, setOnGoingFormSubmission] = useState<boolean>(false);

  const handleAssessmentForm = async (values: FormikValues) => {
    setOnGoingFormSubmission(true);

    try {
      const noQuestionGroupValues = { ...values };
      for (const key of Object.keys(noQuestionGroupValues)) {
        if (key.startsWith("group")) delete noQuestionGroupValues[key];
      }

      const answersObjectValues: Record<string, any> = {};
      for (const [key, value] of Object.entries(noQuestionGroupValues)) {
        // Get all the question's answers except the pre-screening questions
        // and the "q13" (color selection) question that will be split in 2 parts
        if (
          ![
            "accept",
            "job_father",
            "job_mother",
            "favorite_member",
            "extra_curricular",
            "q13",

            // These are the "Example" group questions that shouldn't be included
            "example-1",
            "example-2",
            "example-3",
          ].includes(key)
        ) {
          answersObjectValues[key] = value;
        }

        // Split the Q13 into two parts
        if (key === "q13") {
          answersObjectValues["q13p1"] = value[0];
          answersObjectValues["q13p2"] = value[1];
        }

        // Use the string values for Q14 & Q15
        if (key === "q14") answersObjectValues["q14"] = value[0];
        if (key === "q15") answersObjectValues["q15"] = value[0];
      }

      // Final request payload to the API
      const REQUEST_PAYLOAD = {
        accept: values.accept,
        job_father: values.job_father,
        job_mother: values.job_mother,
        extra_curricular: values.extra_curricular,
        favorite_member: values.favorite_member,
        answers: answersObjectValues,
      };

      // Submit the Assessment form
      await fetchHandler("POST", `online-assessment/${identifier}/submit`, REQUEST_PAYLOAD);

      // Redirect the user to the "Thank You" page
      navigate(`/online-application/assessment/${selectedLanguage}/thank-you/`);
    } catch (error) {
      const errorMessage = handleErrorMessage(error).toLowerCase();

      // Show existing SBCA notice to the user if SBCA was already submitted,
      // or a toast notification if there was some different type of error
      if (errorMessage.includes("sbca submission already submitted")) {
        setExistingSBCAError(true);
      } else {
        errorReporting(
          "Failed submitting online application assessment (SBCA) form",
          error,
          {
            ...values,
          },
          "critical",
        );
      }
    } finally {
      setOnGoingFormSubmission(false);
    }
  };

  /*===============================
    DISPLAY SBCA NOTICE UI TO THE USER
    IN CASE THEY ALREADY HAVE SBCA
  ================================*/
  if (existingSBCAError) {
    return (
      <div className="error-pages error-pages--not-found">
        <div className="container">
          <h2>SBCA Already Submitted!</h2>

          <p className="mb--20i">
            You have already submitted your Success Building Candidate Assessment (SBCA).
          </p>

          <p className="txt--black txt--xs mb--0">
            &copy; FirstChoice Hiring 2014-{new Date().getFullYear()}. All Rights Reserved.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="online-application-assessment">
      <div className="container">
        {pageLoading ? (
          <Loader size="page" modifierWrapper="loader--page" />
        ) : (
          <>
            <div className="online-application-assessment__language">
              <p>{t("change_language")}</p>

              <div
                onClick={() => {
                  setSelectedLanguage("en");
                }}
                className={`online-application-assessment__language__btn ${
                  selectedLanguage === "en"
                    ? "online-application-assessment__language__btn--selected"
                    : ""
                }`}
              >
                <FlagUS />
                <span>EN</span>
              </div>

              <div
                onClick={() => {
                  setSelectedLanguage("es");
                }}
                className={`online-application-assessment__language__btn ${
                  selectedLanguage === "es"
                    ? "online-application-assessment__language__btn--selected"
                    : ""
                }`}
              >
                <FlagES />
                <span>ES</span>
              </div>
            </div>

            <h2 className="mb--5">{t("title")}</h2>

            <Formik
              initialValues={formInitialValues}
              enableReinitialize
              validationSchema={formValidationSchema}
              onSubmit={handleAssessmentForm}
            >
              {({ errors, touched, submitForm }) => (
                <Form
                  onSubmit={e => {
                    handleFormikScrollToFirstError(e, errors, ".input__error", submitForm);
                  }}
                >
                  {/* ACCEPT BOX */}
                  <div className="online-application__tos online-application__tos--accept">
                    <div className="online-application__tos__content">
                      <p>
                        <Trans
                          i18nKey="sbca_assessment:accept_box"
                          values={{ name: QUESTIONS_DATA.name }}
                          components={{ strong: <strong /> }}
                        />
                      </p>
                    </div>

                    <div className="online-application__tos__footer">
                      <Field
                        component={FormCheckbox}
                        name="accept"
                        id="accept"
                        label={t("accept_checkbox")}
                      />
                    </div>
                  </div>

                  {/* PRE-SCREENING QUESTIONS */}
                  <h3 className="mb--20">{t("pre_screening")}</h3>

                  <hr />
                  <p className="online-application-assessment__message online-application-assessment__message--danger">
                    {t("note")}
                  </p>
                  <hr />

                  {/* Extra Curricular question */}
                  <div className="online-application-assessment__question-box online-application-assessment__question-box--odd">
                    <p>{t("form.questions.extra_curricular")}</p>
                    <Field
                      component={FormInput}
                      name="extra_curricular"
                      id="extra_curricular"
                      title="Extra Curricular"
                    />
                  </div>

                  {/* Job - Father */}
                  <div className="online-application-assessment__question-box online-application-assessment__question-box--even">
                    <p> {t("form.questions.line_of_work_father")}</p>
                    <Field
                      component={FormInput}
                      name="job_father"
                      id="job_father"
                      title="Line of Work - Father"
                    />
                  </div>

                  {/* Job - Mother */}
                  <div className="online-application-assessment__question-box online-application-assessment__question-box--odd">
                    <p> {t("form.questions.line_of_work_mother")}</p>
                    <Field
                      component={FormInput}
                      name="job_mother"
                      id="job_mother"
                      title="Line of Work - Mother"
                    />
                  </div>

                  {/* Extra Curricular question */}
                  <div className="online-application-assessment__question-box online-application-assessment__question-box--even">
                    <p>{t("form.questions.work_environment")}</p>
                    <Field
                      component={FormDropdown}
                      title={t("form.questions.work_environment_placeholder")}
                      items={ONLINE_APPLICATION_ASSESSMENT_WORK_ENVIRONMENT}
                      name="favorite_member"
                      id="favorite_member"
                      size="full"
                    />
                  </div>

                  <h5 className="mb--10">{t("rate_the_following_questions")}</h5>

                  <div data-testid="form:online-application-assessment-prescreening-questions-wrapper">
                    {QUESTIONS_DATA.questions.length > 0 ? (
                      QUESTIONS_DATA.questions.map(
                        (question: OnlineApplicationAssessmentQuestionProps) => (
                          <OnlineApplicationAssessmentQuestion
                            key={question.question_name}
                            {...question}
                          />
                        ),
                      )
                    ) : (
                      <div className="d-flex flex-column justify-content-center align-items-center mb--30">
                        <span className="mb--10 txt--error">{t("no_pre_screening_questions")}</span>
                        <Button
                          type="button"
                          modifierClass="btn--fluid btn--primary"
                          onClick={() => window.location.reload()}
                        >
                          {t("no_pre_screening_questions_reload_btn")}
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="online-application-assessment__question-box online-application-assessment__question-box--colors">
                    <div className="d-flex align-items-center">
                      <p>Q13. </p>
                      <p>{t("form.questions.colors_describe_best")}</p>
                    </div>
                    <Field
                      component={OnlineApplicationColorPicker}
                      id="q13"
                      name="q13"
                      selectionLimit={2}
                    />
                    {errors["q13"] && touched["q13"] ? (
                      <span className="input__error">
                        <ErrorMessage
                          name="q13"
                          render={(errorMessage: string) => t(errorMessage)}
                        />
                      </span>
                    ) : null}
                  </div>

                  <div className="online-application-assessment__question-box online-application-assessment__question-box--colors">
                    <div className="d-flex align-items-center">
                      <p>Q14. </p>
                      <p>{t("form.questions.colors_most_liked")}</p>
                    </div>
                    <Field
                      component={OnlineApplicationColorPicker}
                      id="q14"
                      name="q14"
                      selectionLimit={1}
                    />
                    {errors["q14"] && touched["q14"] ? (
                      <span className="input__error">
                        <ErrorMessage
                          name="q14"
                          render={(errorMessage: string) => t(errorMessage)}
                        />
                      </span>
                    ) : null}
                  </div>

                  <div className="online-application-assessment__question-box online-application-assessment__question-box--colors mb--10">
                    <div className="d-flex align-items-center">
                      <p>Q15. </p>
                      <p>{t("form.questions.colors_most_disliked")}</p>
                    </div>
                    <Field
                      component={OnlineApplicationColorPicker}
                      id="q15"
                      name="q15"
                      selectionLimit={1}
                    />
                    {errors["q15"] && touched["q15"] ? (
                      <span className="input__error">
                        <ErrorMessage
                          name="q15"
                          render={(errorMessage: string) => t(errorMessage)}
                        />
                      </span>
                    ) : null}
                  </div>

                  <h3 className="txt--left">{t("candidate_assessment_subtitle")}</h3>

                  <hr />

                  <p className="online-application-assessment__message">
                    {t("candidate_assessment_text")}
                  </p>

                  <hr />

                  {/* EXAMPLE GROUP OF QUESTIONS*/}
                  <div className="online-application-assessment__group">
                    <h5 className="txt--left">
                      {t("form.questions.assessment_questions.example.title")}
                    </h5>
                    <div className="online-application-assessment__question-box online-application-assessment__question-box--odd">
                      <div className="d-flex-align-items-center">
                        <p>[ A ] {t("form.questions.assessment_questions.example.a")}</p>
                      </div>

                      <Field
                        component={FormInputSlider}
                        id="example-1"
                        name="example-1"
                        defaultValue={3}
                        isDisabled
                        modifierClass="slider--sum-good"
                      />
                    </div>
                    <div className="online-application-assessment__question-box online-application-assessment__question-box--even">
                      <div className="d-flex-align-items-center">
                        <p>[ B ] {t("form.questions.assessment_questions.example.b")}</p>
                      </div>

                      <Field
                        component={FormInputSlider}
                        id="example-2"
                        name="example-2"
                        defaultValue={3}
                        isDisabled
                        modifierClass="slider--sum-good"
                      />
                    </div>
                    <div className="online-application-assessment__question-box online-application-assessment__question-box--odd">
                      <div className="d-flex-align-items-center">
                        <p>[ C ] {t("form.questions.assessment_questions.example.c")}</p>
                      </div>

                      <Field
                        component={FormInputSlider}
                        id="example-3"
                        name="example-3"
                        defaultValue={4}
                        isDisabled
                        modifierClass="slider--sum-good"
                      />
                    </div>

                    <hr />
                  </div>

                  <div data-testid="form:online-application-assessment-static-questions-wrapper">
                    {ONLINE_APPLICATION_ASSESSMENT_STATIC_QUESTIONS.map(
                      (questionGroup: AssessmentStaticQuestionGroups, index: number) => (
                        <Field
                          key={`${questionGroup.id}${index + 1}`}
                          component={OnlineApplicationAssessmentGroupQuestion}
                          id={`${questionGroup.id}${index + 1}`}
                          name={`${questionGroup.id}${index + 1}`}
                          title={questionGroup.title}
                          questions={questionGroup.questions}
                          groupIndex={index + 1}
                        />
                      ),
                    )}
                  </div>

                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    className="online-application__recaptcha"
                    onChange={handleGoogleRecaptchaToken}
                    onErrored={() => setRecaptchaToken(null)}
                  />

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Button
                      modifierClass="online-application__btn__submit btn btn--fluid"
                      isDisabled={!recaptchaToken || ongoingFormSubmission}
                      isLoading={ongoingFormSubmission}
                    >
                      {t("form.submit_btn")}
                    </Button>
                    <p>{t("thank_you_message")}</p>
                  </div>
                </Form>
              )}
            </Formik>

            <img src={ComodoSecureLogo} className="d-block mx--auto mb--20" alt="Comodo Secure" />

            <OnlineApplicationFooter />
          </>
        )}
      </div>
    </div>
  );
};

export default OnlineApplicationAssessment;
