/**
 *
 * Utility function for sanitizing the received string `phone` value
 * from the API response, removing all special characters and words.
 *
 * The final phone string value that will be returned will be
 * in the format of `+1234567890`.
 *
 * @example 123-(234).235 -> +123234235
 *
 * @param phone Receied phone string value from the API response
 * @returns The sanitized phone string value, or "N/A" if valid value is available.
 */
export default function handlePhoneStringSanitization(phone: string | undefined): string {
  // If there's no valid phone value, return a default value
  if (!phone) return "";

  // Find all non-digit characters and remove them from the received phone string
  let sanitizedPhoneString: string = phone.replace(
    /[a-zA-Z\s\-().\W!@#$%^&*_={[}]|;:'",<>?\/~`]/gi,
    "",
  );

  // If the number starts with "00", swap it with a "+" prefix
  if (sanitizedPhoneString.startsWith("00")) {
    sanitizedPhoneString = sanitizedPhoneString.slice(2);
    sanitizedPhoneString = `+${sanitizedPhoneString}`;
  }

  // Based on sanitized phone length, append the necessary prefix characters
  if (sanitizedPhoneString.length === 10) {
    sanitizedPhoneString = `+1${sanitizedPhoneString}`;
  } else if (sanitizedPhoneString.length === 11) {
    sanitizedPhoneString = `+${sanitizedPhoneString}`;
  }

  return sanitizedPhoneString;
}
