import { getIn } from "formik";
import { FormTextareaProps } from "./interfaces";

const FormTextarea = ({
  form,
  field,
  label = "",
  isRequired = false,
  modifierClass = "",
  ...props
}: FormTextareaProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    TRIM FORM FIELD VALUE
  ================================*/
  const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const trimmedValue: string = event.target.value.trim();

    // Update the form value to which this field corresponds to
    form.setFieldValue(field.name, trimmedValue);

    // Trigger internal Formik 'onBlur' events for the field
    field.onBlur(event);
  };

  return (
    <div className={`input ${modifierClass}`}>
      {label && (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div className="input__wrapper">
        <textarea
          {...field}
          {...props}
          onBlur={handleOnBlur}
          className={`input-textarea ${errors && touched ? "input-textarea--error" : ""}`}
        ></textarea>

        {/* DISPLAY ERROR MESSAGES */}
        {errors && touched && <p className="input__error">{errors}</p>}
      </div>
    </div>
  );
};

export default FormTextarea;
