import handlePhoneStringSanitization from "./handlePhoneStringSanitization";

/**
 *
 * Utility function for processing the phone numbers of the
 * applicants that were exported from the dashboard, formatting
 * them all into "(xxx) xxx-xxxx" number, or using a fallback text
 * for invalid phone numbers.
 *
 * @param phone The phone number of the individual applicant that is to be exported to a CSV file
 * @returns Phone string in the "(xxx) xxx-xxxx" format
 *
 */
export function handleCSVExportPhoneFormatting(phone: string | undefined): string {
  if (!phone) return "N/A";

  // Sanitize the received phone number, removing any non digit characters
  let sanitizedPhoneNumber: string = handlePhoneStringSanitization(phone);

  // Check if the sanitized phone number has a country prefix; to be used
  // as extra step when confirming the validity of the phone number
  // (e.g. if the original phone is "+112345678901" this will still be invalid after the sanitization)
  const hasCountryPrefix: boolean = sanitizedPhoneNumber.startsWith("+");

  // Exit and return a default message for invalid phone numbers
  if (
    sanitizedPhoneNumber.length <= 10 ||
    sanitizedPhoneNumber.length > 12 ||
    (!hasCountryPrefix && sanitizedPhoneNumber.length >= 12)
  ) {
    return "Invalid Phone Number";
  }

  // Remove the "+1" prefix from the sanitized phone number
  sanitizedPhoneNumber = sanitizedPhoneNumber.slice(2);

  // Format the phone number as "(xxx) xxx-xxxx"
  return `(${sanitizedPhoneNumber.slice(0, 3)}) ${sanitizedPhoneNumber.slice(
    3,
    6,
  )}-${sanitizedPhoneNumber.slice(6)}`;
}
