// Pages & Layout
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import Chats from "../pages/Chats/Chats";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_CHAT: ApplicationRouteProps[] = [
  {
    path: "/chats/messages",
    element: (
      <LayoutAuthenticated>
        <Chats />
      </LayoutAuthenticated>
    ),
    permissions: ["sms_read"],
    type: "protected",
  },
  {
    path: "/chats/messages/:id",
    element: (
      <LayoutAuthenticated>
        <Chats />
      </LayoutAuthenticated>
    ),
    permissions: ["sms_read_company_messages"],
    type: "protected",
  },
];
