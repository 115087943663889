// Hooks
import { useState } from "react";

// Components
import MeetNow from "./MeetNow";
import ScheduleVideoMeeting from "./ScheduleVideoMeeting";
import RadioButton from "../../Inputs/RadioButton";
import { motion } from "framer-motion";

// Interfaces
import { VideoConferecingViews, VideoConferencingProps } from "./interfaces";
import { FRAMER_SCHEDULE_MEETING_ANIMATION } from "../../../constants/framer";

const VideoConferencing = ({
  applicationID,
  meeting_link,
  handleCloseMenu,
  framerAnimationProps,
}: VideoConferencingProps) => {
  /*=======================
    VIDEO CONFERENCING
  ========================*/
  const [videoConferenceView, setVideoConferenceView] = useState<VideoConferecingViews>("meet_now");

  return (
    <motion.div
      key="framer-schedule-meeting-video"
      variants={FRAMER_SCHEDULE_MEETING_ANIMATION}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: 1, type: "spring" }}
      custom={framerAnimationProps}
    >
      <h6 className="mb--10 fw--medium">
        <strong>Choose an option for the video conference</strong>
      </h6>

      <div className="d-flex align-items-center mb--20">
        <RadioButton
          value="meet_now"
          id="meet_now"
          name="meet_now"
          isChecked={videoConferenceView === "meet_now"}
          handleRadioButtonChange={() => setVideoConferenceView("meet_now")}
          label="Meet Now"
        />

        <RadioButton
          value="schedule_meeting"
          id="schedule_meeting"
          name="schedule_meeting"
          isChecked={videoConferenceView === "schedule_meeting"}
          handleRadioButtonChange={() => setVideoConferenceView("schedule_meeting")}
          label="Schedule Meeting"
          modifierClass="ml--20"
        />
      </div>

      {videoConferenceView === "meet_now" ? (
        <MeetNow meeting_link={meeting_link} />
      ) : (
        <ScheduleVideoMeeting applicationID={applicationID} handleCloseMenu={handleCloseMenu} />
      )}
    </motion.div>
  );
};

export default VideoConferencing;
