import AppointmentsIcon from "../../assets/images/icons/appointments-icon.svg?react";
import ApplicationsIcon from "../../assets/images/icons/applications-icon.svg?react";
import ArticlesIcon from "../../assets/images/icons/communication-icon.svg?react";
import ClientsIcon from "../../assets/images/icons/clients-icon.svg?react";
import MessagesIcon from "../../assets/images/icons/messages-icon.svg?react";
import AdManagerIcon from "../../assets/images/icons/ad-manager-icon.svg?react";
import PrivilegesIcon from "../../assets/images/icons/privileges-icon.svg?react";
import ReportsIcon from "../../assets/images/icons/reports-icon.svg?react";
import JobPositionsIcon from "../../assets/images/icons/job-board-icon.svg?react";
import SendSBCARequestIcon from "../../assets/images/icons/send-sbca-request-icon.svg?react";
import EmailTemplatesIcon from "../../assets/images/icons/email-templates-icon.svg?react";
import VideoConferenceIcon from "../../assets/images/icons/video-conference-icon.svg?react";
import ResourcesIcon from "../../assets/images/icons/resources-icon.svg?react";
import { AiOutlineNotification as MarketingAnnouncementsIcon } from "react-icons/ai";

import { HeaderGroupRouteProps, HeaderAdminDropdownRouteProps } from "./interfaces";

export const ACCOUNT_DROPDOWN_MY_ACCOUNT: HeaderGroupRouteProps = {
  title: "My Account",
  items: [
    {
      path: "/account/profile/",
      text: "Profile Settings",
      permissions: ["*"],
    },
    {
      path: "/account/users/",
      text: "Users",
      permissions: ["applicant_dashboard_users_edit"],
    },
    {
      path: "/account/admin/users/",
      text: "Users Admin",
      permissions: ["users_admin_view"],
    },
  ],
};

export const ACCOUNT_DROPDOWN_SETTINGS: HeaderGroupRouteProps = {
  title: "Settings",
  items: [
    {
      path: "/account/manage-alerts/",
      text: "Manage Alerts",
      permissions: ["ad_alerts_manage"],
    },
  ],
};

export const HEADER_DROPDOWN_HELP: HeaderGroupRouteProps = {
  title: "Need Help?",
  items: [
    {
      externalPath: true,
      path: "https://firstchoicehiring.com/helpdesk",
      text: "Contact Support",
      permissions: ["*"],
    },
    {
      externalPath: true,
      path: "tel:+18889906451",
      text: "Sales 1-888-990-6451",
      permissions: ["*"],
    },
    {
      externalPath: true,
      path: "tel:+18774497595",
      text: "Support 1-877-449-7595",
      permissions: ["*"],
    },
  ],
};

export const ADMIN_MENU_DROPDOWN: HeaderAdminDropdownRouteProps[] = [
  {
    path: "/job-ads/overview/",
    text: "Ad Manager",
    icon: <AdManagerIcon />,
    permissions: ["ad_manager_view"],
  },
  {
    path: "/appointments/",
    text: "Appointments",
    icon: <AppointmentsIcon />,
    permissions: ["applicant_appointments_view"],
  },
  {
    path: "/applications/",
    text: "Applications",
    icon: <ApplicationsIcon />,
    permissions: ["applicant_dashboard_view"],
  },
  {
    path: "/articles/",
    text: "Articles",
    icon: <ArticlesIcon />,
    permissions: ["articles_user_view"],
  },
  {
    path: "/articles/overview/",
    text: "Articles Overview",
    icon: <ArticlesIcon />,
    permissions: ["articles_view"],
  },
  {
    path: "/job-positions/",
    text: "Job Positions",
    icon: <JobPositionsIcon />,
    permissions: ["company_job_position_view"],
  },
  {
    path: "/admin/clients/",
    text: "Clients",
    icon: <ClientsIcon />,
    permissions: ["client_admin_view"],
  },
  {
    path: "/notification-logs/",
    text: "Notification Logs",
    icon: <MessagesIcon />,
    permissions: ["messages_view"],
  },
  {
    path: "/reports/",
    text: "Reports",
    icon: <ReportsIcon />,
    permissions: ["reports_view", "view_company_reports"],
  },
  {
    path: "/account/email-templates/",
    text: "Email Templates",
    icon: <EmailTemplatesIcon />,
    permissions: ["*"],
  },
  {
    path: "/account/vidconf/",
    text: "Video Conferencing",
    icon: <VideoConferenceIcon />,
    permissions: ["*"],
  },
  {
    path: "/account/resources/",
    text: "Resources",
    icon: <ResourcesIcon />,
    permissions: ["*"],
  },
  {
    path: "/privileges/",
    text: "Privileges",
    icon: <PrivilegesIcon />,
    permissions: ["privileges_view"],
  },
  {
    path: "/assessment/request/",
    text: "Send SBCA Request",
    icon: <SendSBCARequestIcon />,
    permissions: ["send_sbca_link_request"],
  },
  {
    path: "/marketing/banners",
    text: "Announcement Banners",
    icon: <MarketingAnnouncementsIcon />,
    permissions: ["marketing_banners_view"],
  },
];
