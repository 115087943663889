import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import TourAiSummary from "../pages/Tours/Applicants/TourAiSummary";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_TOURS: ApplicationRouteProps[] = [
  {
    path: "/tours/applicants/ai-summary",
    element: (
      <LayoutAuthenticated isFullHeader={true}>
        <TourAiSummary />
      </LayoutAuthenticated>
    ),
    permissions: ["*"],
    type: "protected",
  },
];
