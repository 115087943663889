// Utilities & Hooks
import { useRef, useState } from "react";

// Packages
import { Editor } from "@tinymce/tinymce-react";

// Configuration
import { TINY_MCE_WYSIWYG_EDITOR_KEY } from "../../config/config";

// TinyMCE Text Editor Plugins
import { handleCustomImagePlugin } from "./custom-plugins/customImagePlugin";

// Statics & Interfaces
import { TextEditorProps } from "./interfaces";

// Utilities
import handleCustomImageUpload from "./utils/customImageUpload";
import handleLinkTargetOnLinkPaste from "./utils/handleLinkTargetOnLinkPaste";

// Components
import Loader from "../Loader/Loader";

const TextEditor = ({
  initialValue = "",
  handleEditorContent,
  isDisabled = false,
  placeholder = "",
  customButtons,
  image_upload_url,
}: TextEditorProps) => {
  const [isEditorInitializing, setIsEditorInitializing] = useState<boolean>(true);
  const editorRef = useRef<any>(null);

  return (
    <>
      {isEditorInitializing && (
        <h6 className="d-flex align-items-center my--20">
          Initializing Editor...
          <Loader size="sm" modifierWrapper="ml--10" />
        </h6>
      )}

      <Editor
        value={initialValue}
        apiKey={TINY_MCE_WYSIWYG_EDITOR_KEY}
        id="wysiwyg-editor"
        onInit={(_event, editor) => {
          editorRef.current = editor;
          setIsEditorInitializing(false);
        }}
        ref={editorRef}
        onEditorChange={handleEditorContent}
        disabled={isDisabled}
        init={{
          height: window.screen.height - 250,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "table",
            "codesample",
            "help",
            "wordcount",
            "powerpaste",
          ],
          relative_urls: false,
          remove_script_host: false,
          automatic_uploads: false,
          placeholder,
          auto_focus: undefined,
          toolbar: [
            "undo redo | fontsize | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link imageCustomPlugin | ",
            customButtons?.toolbar ?? "",
          ],
          toolbar_mode: "sliding",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt",
          link_context_toolbar: true,
          link_default_target: "_blank",

          // Append 'target="_blank"' to pasted links
          paste_postprocess: handleLinkTargetOnLinkPaste,

          // POWER PASTE PLUGIN
          powerpaste_allow_local_images: true,
          powerpaste_word_import: "prompt",
          powerpaste_html_import: "prompt",
          powerpaste_googledocs_import: "prompt",

          // CUSTOM BUTTONS
          setup: editor => {
            customButtons && customButtons.buttons.length > 0
              ? customButtons.buttons.forEach(btn => {
                  editor.ui.registry.addButton(btn.name, {
                    text: btn.properties.text,
                    tooltip: btn.properties.tooltip,
                    onAction: () => {
                      // Insert content in the editor if the button
                      // a valid value for the corresponding field
                      if (btn.properties.contentToInsert) {
                        editor.insertContent(btn.properties.contentToInsert);
                      }

                      // Trigger additional action if there is any
                      if (btn.properties.action) btn.properties.action();
                    },
                  });
                })
              : null;

            // CUSTOM IMAGE UPLOADING BUTTON
            editor.ui.registry.addButton("imageCustomPlugin", {
              icon: "image",
              tooltip: "Image Upload",
              onAction: () => handleCustomImagePlugin(editor),
            });
          },

          // IMAGE UPLOADING
          paste_data_images: false,
          image_title: true,
          image_caption: true,
          file_picker_types: "image",
          file_picker_callback: async callback => {
            return handleCustomImageUpload(callback, editorRef.current, image_upload_url);
          },
        }}
      />
    </>
  );
};

export default TextEditor;
