// Utilities & Hooks
import { useMemo } from "react";
import { format } from "date-fns";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import { useArticlesGetSpecific } from "../../api/Articles/Articles";
import parse, { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Assets
import { FaAngleLeft as BackIcon, FaPen as EditIcon } from "react-icons/fa";
import { BsInfoCircle as InfoIcon } from "react-icons/bs";

// Components
import Card from "../../components/Card/Card";
import ArticlesOthers from "../../components/Articles/ArticlesOthers";
import ArticleDetailsSkeleton from "./Skeleton/ArticleDetails";
import ErrorNotFound from "../../components/Error/ErrorNotFound";
import Banner from "../../components/Banner/Banner";
import LoaderRefetch from "../../components/Loader/LoaderRefetch";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

const ArticleDetails = () => {
  const { id } = useParams() as Record<string, string>;
  const [searchParams] = useSearchParams();

  /*=================================
    ARTICLE DETAILS
  ===================================*/
  const { data, isLoading, error, isRefetching } = useArticlesGetSpecific(id);

  const articleDetails = useMemo(() => {
    // Return a default value if there's no data
    if (!data || !Object.entries(data).length || !data.data)
      return {
        details: {
          feature_image: "N/A",
          title: "N/A",
          author: { first_name: "N/A", middle_name: "N/A", last_name: "N/A" },
          create_date: null,
          content: "",
          summary: "",
        },
        other_articles: [],
      };

    // Extract and return the necessary fields so they can be consumed by the components
    return { details: data.data, other_articles: data.other_articles };
  }, [data]);

  // HTML Parser Options
  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      // Remove DOM nodes that represent iframes
      if (domNode instanceof HTMLIFrameElement && domNode.name.startsWith("iframe")) return <></>;
    },
  };

  if (error) return <ErrorNotFound title="Article not found" fallbackRoute="/articles/" />;

  return (
    <div className="container py--25">
      {isLoading ? (
        <ArticleDetailsSkeleton />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb--20">
            <Link to="/articles/" className="d-flex align-items-center txt--md txt--link">
              <BackIcon className="mr--5" />
              Back to all articles
            </Link>
            {/* The edit button is shown only if the user has one of the admin , 
                and the article is not being viewed in a "preview" mode
            */}
            {searchParams.get("mode") !== "preview" ? (
              <PermissionCheckComponentWrapper permissions={["articles_edit"]}>
                <Link
                  to={`/articles/${id}/edit/`}
                  className="d-flex align-items-center txt--md txt--link"
                >
                  <EditIcon className="mr--5" />
                  Edit Article
                </Link>
              </PermissionCheckComponentWrapper>
            ) : null}
          </div>

          {searchParams.get("mode") === "preview" && (
            <Banner text="You're viewing this article in a preview mode." icon={<InfoIcon />} />
          )}

          <div className="row">
            <div className="col-12 col-lg-8">
              <Card modifierClass="card--padding--xl card--rounded">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                    <div className="article-details__image">
                      <img src={articleDetails?.details.feature_image} alt="Placeholder" />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <h5 className="article-details__title">
                      {articleDetails?.details.title || "No Title Available"}
                    </h5>
                    <div className="article-details__meta">
                      <span>By {handleFullnameCombination(articleDetails.details.author)}</span>

                      <span>
                        {articleDetails?.details?.create_date
                          ? format(
                              handleDateAsTimestamp(articleDetails.details.create_date),
                              "MMM dd, yyyy - hh:mm aaa",
                            )
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>

                {articleDetails.details.summary ? (
                  <p className="txt--black fw--semibold my--20">
                    <em>{articleDetails.details.summary}</em>
                  </p>
                ) : null}

                {articleDetails.details.content ? (
                  <div className="parsed-html my--20">
                    {parse(articleDetails.details.content, parserOptions)}
                  </div>
                ) : null}
              </Card>
            </div>

            <div className="col-12 col-lg-4">
              <ArticlesOthers articles={articleDetails.other_articles} />
            </div>
          </div>

          {isRefetching && <LoaderRefetch title="Refetching Article..." />}
        </>
      )}
    </div>
  );
};

export default ArticleDetails;
