// Hooks & Utilities
import { useEffect, useMemo, useState } from "react";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";
import { matchSorter } from "match-sorter";
import { useJobAdsGetAllClients } from "../../../api/JobAds/JobAdsClients";

// Components
import Card from "../../../components/Card/Card";
import JobAdHeader from "../../../components/JobAds/JobAdHeader";
import TableSkeletonPlaceholder from "../../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import JobAdsClientsTable from "../../../components/JobAds/JobAdsClientsTable";

// Interfaces
import { JobAdsClientsResponseFields } from "../../../api/JobAds/interfaces";

// Constants
import { JOB_ADS_CLIENTS_FILTER_LETTERS } from "../constants";

const JobAdsClients = () => {
  /*==============================
    SEARCH PARAMETERS
  ===============================*/
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*===============================
    SEARCH TROUGH CLIENTS
  ===============================*/
  const [searchedClients, setSearchedClients] = useState<string>("");

  /*===============================
    LETTER FILTERING
  ===============================*/
  const [clientLetterFilter, setClientLetterFilter] = useState<string>("all");

  const handleClientLetterFilter = (filterLetter: string) => {
    setClientLetterFilter(filterLetter);

    // Append the selected letter as search parameter
    if (filterLetter !== "all") {
      setSearchParameters({
        ...searchParametersObject,
        letter: filterLetter === "#" ? "num" : filterLetter,
      });
    } else {
      // Remove the "letter" search parameter completely before updating the state
      delete searchParametersObject.letter;

      setSearchParameters(searchParametersObject);
    }
  };

  useEffect(() => {
    const { letter } = searchParametersObject;
    if (letter) setClientLetterFilter(letter === "num" ? "#" : letter);
  }, []);

  /*===============================
    GET ALL CLIENTS
  ===============================*/
  const { data, isLoading } = useJobAdsGetAllClients();

  const JOB_ADS_CLIENTS = useMemo(() => {
    if (!data || !data.length || isLoading) return [];

    let MAPPED_CLIENTS: JobAdsClientsResponseFields[] = [...data];

    // Filter the dataset based on the selected letter
    switch (true) {
      case !["#", "all"].includes(clientLetterFilter):
        MAPPED_CLIENTS = MAPPED_CLIENTS.filter(client => {
          return client.name.toLowerCase().startsWith(clientLetterFilter.toLowerCase());
        });
        break;
      case clientLetterFilter === "#":
        MAPPED_CLIENTS = MAPPED_CLIENTS.filter(client => {
          return client.name.toLowerCase().match(/^[0-9].+/gi);
        });
        break;
    }

    // Search trough the dataset
    if (searchedClients) {
      MAPPED_CLIENTS = matchSorter(MAPPED_CLIENTS, searchedClients, {
        keys: ["name"],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    // Sort the data alphabetically based on the name of the client
    MAPPED_CLIENTS = MAPPED_CLIENTS.sort((clientA, clientB) => {
      return clientA.name.toLowerCase() > clientB.name.toLowerCase() ? 1 : -1;
    });

    return MAPPED_CLIENTS;
  }, [data, searchedClients, clientLetterFilter]);

  return (
    <div className="job-ads">
      <JobAdHeader handleSearch={(search: string) => setSearchedClients(search)} />

      <div className="container py--25">
        <Card>
          <h2 className="fw--semibold pl--30 my--20">Clients</h2>

          <h5 className="txt--lg fw--semibold pl--30 mb--10">Filter by Letter:</h5>
          <div
            className="d-flex align-items-center pl--30"
            data-testid="component:job-ads-clients-letter-filters"
          >
            {JOB_ADS_CLIENTS_FILTER_LETTERS.map(filterLetter => (
              <span
                key={`filterLetter-${filterLetter}`}
                className={`job-ads__letter-filter ${
                  filterLetter === clientLetterFilter ? "job-ads__letter-filter--selected" : ""
                } `}
                onClick={() => handleClientLetterFilter(filterLetter)}
              >
                {filterLetter}
              </span>
            ))}
          </div>

          {isLoading ? (
            <TableSkeletonPlaceholder modifierClass="card--no-shadow" />
          ) : (
            <JobAdsClientsTable data={JOB_ADS_CLIENTS} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default JobAdsClients;
