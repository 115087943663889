// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";

// Interfaces
import { DropdownItem } from "../../../Dropdown/interfaces";
import { JobBoardsFormsProps } from "../interfaces";

// Dropdown datasets
import {
  APPCAST_DROPDOWNS_CATEGORY,
  APPCAST_DROPDOWN_POSTING_COUNTRY,
} from "./dropdown-data/AppcastDropdowns";

export const AppcastForm = ({ values, validateField, setFieldValue }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.appcast.enabled) validateField("appcast");
  }, [values.appcast]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="appcast.enabled"
        id="appcast.enabled"
        label="Enable Appcast"
        checked={values.appcast.enabled}
        labelModifierClass="input__label--sm"
      />

      {values.appcast.enabled ? (
        <>
          <Field
            component={FormInput}
            name="appcast.ad_title"
            id="appcast.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormInput}
            name="appcast.display_company"
            id="appcast.display_company"
            label="Display Company Name"
            placeholder="Optionally set the display company name"
            modifierClass="input--default-label mb--20"
          />

          <Field
            component={FormDropdownSearchable}
            name="appcast.category"
            id="appcast.category"
            label="Category (required)"
            items={APPCAST_DROPDOWNS_CATEGORY}
            handleFieldUpdate={(category: DropdownItem) => {
              setFieldValue("appcast.category", category.value);
            }}
            size="full"
            placeholder="None - Optional"
            preselectedItemValue={values.appcast.category}
            modifierClass="dropdown--default-label mb--20"
            isRequired
          />

          <Field
            component={FormDropdownSearchable}
            name="appcast.posting_country"
            id="appcast.posting_country"
            label="Posting Country (required)"
            items={APPCAST_DROPDOWN_POSTING_COUNTRY}
            size="full"
            placeholder="Select a Posting Country"
            handleFieldUpdate={(postingCountry: DropdownItem) => {
              setFieldValue("appcast.posting_country", postingCountry.value);
            }}
            preselectedItemValue={values.appcast.posting_country}
            modifierClass="dropdown--default-label mb--30"
            isRequired
          />
        </>
      ) : null}
    </>
  );
};

export default AppcastForm;
