import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import { JobAdsClientDetailsResponseFields, JobAdsClientsResponseFields } from "./interfaces";

/**
 *
 * Get the list of all existing clients (companies)
 *
 */
export const useJobAdsGetAllClients = () => {
  return useQuery(["job-ads-all-clients"], async () => {
    return (await fetchHandler("GET", "admin/ads/companies")) as JobAdsClientsResponseFields[];
  });
};

/**
 *
 * Get the details for a specific client (company)
 *
 * @param clientSlug The slug corresponding to a specific client (company)
 *
 */
export const useJobAdsGetClientDetails = (clientSlug: string | undefined) => {
  return useQuery(
    ["job-ads-client-details", clientSlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/ads/companies/${clientSlug}`,
      )) as JobAdsClientDetailsResponseFields;
    },
    { enabled: !!clientSlug },
  );
};
