// Interfaces
import { OnlineApplicationFormComponentsFields } from "../../../api/OnlineApplication/interfaces";
import { OnlineApplicationFormInitialValues } from "../interfaces";

// Utilities
import { handleFormFieldDefaultValueBasedOnType, handleFormEntryName } from "./utilities";

/**
 *
 * Generate the initial values for all the fields
 * that are belonging to a section marked as `dynamic` (represented as array of fields)
 *
 * @param component Each individual component (field) of that section
 *
 * @returns An array of fields representing the fields that are
 * part of the dynamic section of the form
 *
 */
function handleFormArrayFieldsInitialValues(
  component: OnlineApplicationFormComponentsFields,
): Record<string, Record<string, unknown>[]> {
  const arrayFormField: Record<string, unknown>[] = [];

  // Append dynamic sections to the form based on the number
  // of times the section repeats itself by default
  for (let repeated = 0; repeated <= component.repetitions; repeated++) {
    let dynamicSectionObject: Record<string, unknown> = {};

    component.section_fields.forEach(field => {
      for (const [key, value] of Object.entries(field)) {
        if (key === "name") {
          dynamicSectionObject = {
            ...dynamicSectionObject,
            [value]: handleFormFieldDefaultValueBasedOnType(field.type),
          };
        }
      }
    });

    // Append the individual fields to the dynamic section's array
    arrayFormField.push(dynamicSectionObject);
  }

  // Extract the name of the section to which the array fields are to be connected to
  const sectionComponentName: string = handleFormEntryName(component.title);

  return { [sectionComponentName]: arrayFormField };
}

/**
 *
 * Map field names to initial Formik's form state values.
 *
 * @param components List of components that will be used to
 * construct the form, as received from the API response.
 *
 * @returns An object containing the initial values for the Formik's form state.
 *
 */
export function handleFormFieldsInitialValues(components: OnlineApplicationFormComponentsFields[]) {
  // Exit function if there are no received section components for the form
  if (!components || !components.length) return {};

  let initialValuesObject: OnlineApplicationFormInitialValues | any = {
    terms_and_conditions: false,
  };

  // Loop trough each component's section fields,
  // extract the field's "name" value to be used as the
  // "key" for the initial values object, and set the "value"
  // for the related key to be the default value based on the field's type
  components.forEach(component => {
    if (component.dynamic) {
      // Extract the name of the section to which the array fields are to be connected to
      const formDynamicFields = handleFormArrayFieldsInitialValues(component);
      initialValuesObject = {
        ...initialValuesObject,
        ...formDynamicFields,
      };
    } else {
      component.section_fields.forEach(field => {
        for (const [fieldProperty, fieldValue] of Object.entries(field)) {
          // Only add properties to the initial values object
          // when we reach the "name" property of the received form field
          if (fieldProperty === "name") {
            initialValuesObject = {
              ...initialValuesObject,
              [fieldValue]: handleFormFieldDefaultValueBasedOnType(field.type),
            };
          }
        }
      });
    }
  });

  return initialValuesObject;
}
