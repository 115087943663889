// Interfaces
import { ApplicationRouteProps } from "./interfaces";

// Components
import Applications from "../pages/Applications/Applications";
import ApplicationsDetails from "../pages/Applications/ApplicationsDetails";
import ApplicationPrint from "../pages/Applications/ApplicationPrint";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";

export const ROUTES_APPLICATIONS: ApplicationRouteProps[] = [
  {
    path: "/applications",
    element: (
      <LayoutAuthenticated>
        <Applications />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_dashboard_view"],
    type: "protected",
  },
  {
    path: "/applications/:applicationID",
    element: (
      <LayoutAuthenticated>
        <ApplicationsDetails />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_dashboard_view"],
    type: "protected",
  },
  {
    path: "/applications/:applicationID/print",
    element: <ApplicationPrint />,
    permissions: ["applicant_dashboard_view"],
    type: "protected",
  },
];
