// Utilities
import handlePermissionCheck from "../../../utilities/handlePermissionCheck";

// Assets
import ScheduleMeetingIcon from "../../../assets/images/icons/applications-camera-icon.svg?react";

// Components
import Tooltip from "../../Tooltip/Tooltip";

// Interfaces
import { ApplicationScheduleMeetingProps } from "../interfaces";

const ApplicationScheduleMeeting = ({
  meeting_type,
  handleScheduleMeeting,

  tooltipText = "Video Interview",
}: ApplicationScheduleMeetingProps) => {
  return (
    <>
      {handlePermissionCheck(["applicant_appointments_create"]) && meeting_type === "video" ? (
        <Tooltip text={tooltipText} size="md">
          <div
            className={`applications__socials__icon applications__socials__icon--meeting applications__socials__icon--active`}
            onClick={handleScheduleMeeting}
            data-testid="button:video-action"
          >
            <ScheduleMeetingIcon />
          </div>
        </Tooltip>
      ) : (
        <div
          className="applications__socials__icon applications__socials__icon--meeting applications__socials__icon--disabled"
          data-testid="button:video-action"
        >
          <ScheduleMeetingIcon />
        </div>
      )}
    </>
  );
};

export default ApplicationScheduleMeeting;
