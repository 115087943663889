// Utilities & Hooks
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useJobAdsGetPreviewDetails } from "../../api/JobAds/JobAds";
import parse, { DOMNode, HTMLReactParserOptions } from "html-react-parser";

// Components
import Loader from "../../components/Loader/Loader";

const JobAdPreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  /*=========================
    FETCH DATA
  ==========================*/
  const { data, isLoading, error } = useJobAdsGetPreviewDetails(id);

  useEffect(() => {
    // Typecast the received error object
    const errorObject = error as { message: string };
    if (!errorObject) return;

    // Redirect to the "404 - Not Found" page if error is of that type
    if (errorObject.message.toLowerCase().includes("not found")) {
      navigate("/404/");
    }
  }, [error]);

  /*=========================
    PARSE PREVIEW DETAILS
  ==========================*/
  const [jobAdPreviewDetails, setJobAdPreviewDetails] = useState<string>("");

  useEffect(() => {
    if (!data || isLoading) return;

    setJobAdPreviewDetails(data.content);
  }, [data]);

  // HTML Parser Options
  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      // Remove DOM nodes that represent iframes
      // eslint-disable-next-line
      // @ts-ignore
      if (domNode.name && domNode.name.startsWith("iframe")) return <></>;
    },
  };

  // Update all links in the generated content to append correct search parameters to them
  useEffect(() => {
    if (!jobAdPreviewDetails) return;
    const links = document.querySelectorAll("a");
    const { search } = window.location;

    // list the hrefs of all links
    links.forEach((link: HTMLAnchorElement) => {
      // if the targeted link has search parameters included
      // and we have search parameteres in the visited page URL, merge them together
      if (link.search && search) {
        const clearedSearchParameters = search.split("?").slice(0).join("");
        link.search = `${link.search}&${clearedSearchParameters}`;
      } else if (!link.search && search) {
        // If the targeted link does not have any search parameters,
        // while there are search parameters in the visited page URL, add those params to the link
        link.search = search;
      }
    });
  }, [jobAdPreviewDetails]);

  return (
    <div className="job-ads--preview-page">
      <div className="job-ads--preview-page__wrapper">
        <div className="container py--25">
          {isLoading ? (
            <Loader size="page" modifierWrapper="loader--page" />
          ) : (
            <div className="parsed-html">
              {jobAdPreviewDetails ? (
                <>{parse(jobAdPreviewDetails, parserOptions)}</>
              ) : (
                <div className="my--30">
                  <h3 className="txt--center">
                    <strong>No Job Ad preview details</strong>
                  </h3>
                  <p className="txt--center">
                    Make sure there is valid HTML content provided for this Job Ad that will be
                    presented on this screen.
                  </p>

                  <p className="txt--center">
                    If an issue persists and you keep getting this message, please contact support{" "}
                    <a
                      className="job-ads--preview-page__support-link"
                      href="https://firstchoicehiring.com/helpdesk"
                      target="_blank"
                      rel="noopener"
                    >
                      here
                    </a>{" "}
                    .
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobAdPreview;
