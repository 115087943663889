import { DropdownItem } from "../../../../Dropdown/interfaces";

/*==============================
  CATEGORY
===============================*/
export const APPCAST_DROPDOWNS_CATEGORY: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: 1, text: "Accounting" },
  { value: 2, text: "Accounting & Finance" },
  { value: 3, text: "Admin/Clerical" },
  { value: 4, text: "Advertising/Public Relations" },
  { value: 5, text: "Arts/Entertainment/Gaming" },
  { value: 6, text: "Automotive" },
  { value: 7, text: "Aviation" },
  { value: 8, text: "Banking" },
  { value: 9, text: "Biotechnology/Pharmaceutical" },
  { value: 10, text: "Building/Facilities" },
  { value: 11, text: "Business Opportunity" },
  { value: 12, text: "Childcare" },
  { value: 13, text: "Clerical/Administrative" },
  { value: 14, text: "Communications/Media/Writers" },
  { value: 15, text: "Community & Social Services" },
  { value: 16, text: "Construction & Extraction" },
  { value: 17, text: "Construction/Trades" },
  { value: 18, text: "Consultant" },
  { value: 19, text: "Customer Service" },
  { value: 20, text: "Design" },
  { value: 21, text: "Education" },
  { value: 22, text: "Education, Training, & Library" },
  { value: 23, text: "Emergency/Fire" },
  { value: 24, text: "Engineering" },
  { value: 25, text: "Engineering & Architecture" },
  { value: 26, text: "Executive" },
  { value: 27, text: "Farming, Fishing, & Forestry" },
  { value: 28, text: "Farming, Fishing, and Forestry" },
  { value: 29, text: "Finance" },
  { value: 30, text: "Government" },
  { value: 31, text: "Government - Military" },
  { value: 33, text: "Healthcare" },
  { value: 34, text: "Healthcare - Allied Health" },
  { value: 35, text: "Healthcare - Nursing" },
  { value: 36, text: "Healthcare - Physicians" },
  { value: 37, text: "Healthcare/Medical" },
  { value: 38, text: "Hospitality" },
  { value: 39, text: "Hourly" },
  { value: 32, text: "HR" },
  { value: 40, text: "Human Resources" },
  { value: 41, text: "Information Technology" },
  { value: 42, text: "Installation, Maintenance, & Repair" },
  { value: 43, text: "Insurance" },
  { value: 44, text: "Journalism" },
  { value: 45, text: "Law Enforcement" },
  { value: 46, text: "Legal" },
  { value: 47, text: "Legal Services" },
  { value: 48, text: "Management" },
  { value: 49, text: "Manufacturing & Production" },
  { value: 50, text: "Manufacturing/Production" },
  { value: 51, text: "Marketing" },
  { value: 52, text: "Marketing & Advertising" },
  { value: 53, text: "Media & Communication" },
  { value: 54, text: "Merchandising/Purchasing/Retail" },
  { value: 55, text: "Non-Profit" },
  { value: 56, text: "Not Specified" },
  { value: 57, text: "Oil/Energy/Power" },
  { value: 58, text: "Other" },
  { value: 59, text: "Personal & Home Services" },
  { value: 60, text: "Public Relations" },
  { value: 61, text: "Public Utilities & Services" },
  { value: 62, text: "Real Estate" },
  { value: 63, text: "Real Estate & Building Maintenance" },
  { value: 64, text: "Research" },
  { value: 65, text: "Restaurant" },
  { value: 66, text: "Retail" },
  { value: 67, text: "Sales" },
  { value: 68, text: "Sales & Sales Management" },
  { value: 69, text: "Salon/Beauty/Fitness" },
  { value: 70, text: "Science" },
  { value: 71, text: "Science/Biotech" },
  { value: 72, text: "Security" },
  { value: 73, text: "Social Services" },
  { value: 74, text: "Sports & Fitness" },
  { value: 75, text: "Supply Chain/Logistics" },
  { value: 76, text: "Technology" },
  { value: 77, text: "Telecommunications" },
  { value: 78, text: "Transportation" },
  { value: 79, text: "Transportation/Logistics" },
  { value: 80, text: "Travel/Hospitality/Restaurant" },
  { value: 81, text: "Travel/Tourism" },
  { value: 82, text: "Vet/Animal Services" },
];

/*==============================
  POSTING COUNTRY
===============================*/
export const APPCAST_DROPDOWN_POSTING_COUNTRY: DropdownItem[] = [
  { text: "USA", value: "USA" },
  { text: "Canada", value: "CA" },
];
