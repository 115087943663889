import * as Yup from "yup";
import { SCHEMAS_PASSWORD_MESSAGE } from "./constants";

// SCHEMA REGEX
import { PASSWORD_REGEX_PATTERN } from "./regexes";

/*========================
  USER: PROFILE SETTINGS
=========================*/
export const USER_PROFILE_SETTINGS_UPDATE = Yup.object().shape({
  current_password: Yup.string()
    .required("Please enter your current password")
    .matches(PASSWORD_REGEX_PATTERN, SCHEMAS_PASSWORD_MESSAGE)
    .min(8, SCHEMAS_PASSWORD_MESSAGE)
    .max(50, "Maximum of 50 characters allowed!"),
  new_password: Yup.string()
    .required("Please enter a new password")
    .min(8, "Your password is too short.")
    .max(50, "Maximum of 50 characters allowed!")
    .matches(PASSWORD_REGEX_PATTERN, SCHEMAS_PASSWORD_MESSAGE),
  new_password_confirmation: Yup.string()
    .required("Please confirm the password")
    .oneOf([Yup.ref("new_password")], "Your passwords do not match.")
    .required("Please confirm the password")
    .max(50, "Maximum of 50 characters allowed!"),
});
