import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMemo } from "react";

const PieChart = ({ data, colors, options }: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const CHART_DATA = useMemo(() => {
    return {
      labels: data.legend_labels,
      datasets: [
        {
          label: data.dataset_label,
          data: data.datasets,
          backgroundColor: colors,
          borderColor: ["#FFFFFF"],
          borderWidth: 1,
        },
      ],
    };
  }, [data]);

  return <Pie data={CHART_DATA} options={options} />;
};

export default PieChart;
