export const PIE_CHART_COLORS = [
  "#B0E0E6",
  "#FF721D",
  "#483D8B",
  "#90EE90",
  "#556B2F",
  "#0B65C2",
  "#E9967A",
  "#FF6347",
  "#FFE121",
  "#98FB98",
  "#E1302A",
];
