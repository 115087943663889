// Utilities
import { createColumnHelper } from "@tanstack/react-table";

// Interfaces
import { JobAdsClientsResponseFields } from "../../api/JobAds/interfaces";
import { JobAdsClientsTableProps } from "./interfaces";

// Components
import { Link } from "react-router-dom";
import Table from "../Table/Table";
import { handleStripProtocolFromLinks } from "../../utilities/strings/handleStripProtocolFromLinks";

const JobAdsClientsTable = ({
  data,
  shouldHandlePageRouteParameter = true,
  paginationPageSize = 20,
}: JobAdsClientsTableProps) => {
  /*===============================
    TABLE COLUMNS
  ================================*/
  const COLUMNS_HELPER = createColumnHelper<JobAdsClientsResponseFields>();

  const CLIENTS_TABLE_COLUMNS = [
    COLUMNS_HELPER.accessor("name", {
      header: () => <span>Company Name</span>,
      cell: data =>
        data.getValue() ? (
          <Link to={`/job-ads/clients/${data.row.original.slug}/`}>
            {" "}
            <span>{data.getValue()}</span>
          </Link>
        ) : (
          <span>N/A</span>
        ),
      enableSorting: true,
    }),
    COLUMNS_HELPER.accessor("address", {
      header: () => <span>Address</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("phone", {
      header: () => <span>Phone</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
      size: 100,
    }),
    COLUMNS_HELPER.accessor("website", {
      header: () => <span>Website</span>,
      cell: data =>
        data.getValue() ? (
          <a
            href={handleStripProtocolFromLinks(data.getValue())}
            target="_blank"
            rel="noopener"
            data-testid="component:job-ads-clients-website-link"
          >
            {data.getValue()}
          </a>
        ) : (
          <span>N/A</span>
        ),
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
      enableSorting: false,
      size: 70,
    }),
  ];

  return (
    <Table
      data={data}
      columns={CLIENTS_TABLE_COLUMNS}
      isRefetching={false}
      paginationPageSize={paginationPageSize}
      modifierClass="table-wrapper--no-shadow mb--0i"
      shouldHandlePageRouteParameter={shouldHandlePageRouteParameter}
    />
  );
};

export default JobAdsClientsTable;
