// Pages
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import HRCompliantFormCompanyUpload from "../pages/HRCompliant/HRCompliantFormCompanyUpload";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_HR_COMPLIANT_APPLICATION: ApplicationRouteProps[] = [
  {
    path: "/hr-compliant-form",
    element: (
      <LayoutAuthenticated>
        <HRCompliantFormCompanyUpload />
      </LayoutAuthenticated>
    ),
    permissions: ["hr_compliant_form_view"],
    type: "protected",
  },
];
