import { useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import { VideoConferenceGetLinkFields, VideoConferenceFields } from "./interfaces";

/**
 *
 * Join a video conference meeting
 *
 * @param videoConferenceHash The hashed link for joining the
 * video conference extracted from the URL parameter
 *
 */
export function useVideoConferencingJoin(videoConferenceHash: string | undefined) {
  return useQuery(
    ["video-conferencing-public", videoConferenceHash],
    async () => {
      return (await fetchHandler(
        "GET",
        `video-conference/join/${videoConferenceHash}`,
      )) as VideoConferenceFields;
    },
    { enabled: !!videoConferenceHash },
  );
}

/**
 *
 * Get the details for a video conference
 *
 */
export function useVideoConferencingGetLink() {
  return useQuery(["video-conferencing-get-link"], async () => {
    return (await fetchHandler("GET", "video-conference")) as VideoConferenceGetLinkFields;
  });
}
