/**
 *
 * Strip received link values from any pre-defined "http://" or "https://" protocols that they had
 * and replace them with "https://"
 *
 * @param link The link value as received from the API response
 *
 * @returns Stripped and sanitized link string that defaults to always use `https://` protocol for accessing the link
 *
 */
export function handleStripProtocolFromLinks(link: string): string {
  if (!link) return "";

  // Remove "http" or "https" protocol from the link
  const sanitizedLinks: string = link.replace(/^http[s]?:\/\//gi, "");

  // Return a sanitized link that will always have a "https" as a protocol
  return `https://${sanitizedLinks}`;
}
