// Utilities & Hooks
import { useEffect, useMemo, useState } from "react";
import { useUsersGetList } from "../../../api/Users/Users";
import { useTimezonesGet } from "../../../api/Timezones/Timezones";
import { useAppointmentsEditVideoMeetingAppointment } from "../../../api/Appointments/Appointments";
import useErrorReporting from "../../../hooks/useErrorReporting";
import handleFullnameCombination from "../../../utilities/strings/handleFullnameCombination";
import { format } from "date-fns";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import {
  AppointmentsEditVideoMeetingAppointmentProps,
  AppointmentsEditVideoMeetingFormDetails,
} from "../interfaces";
import { ScheduleVideoConferenceFormValues } from "../../Applications/ScheduleMeeting/interfaces";

// Components
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormInput from "../../Form/FormInput";
import Datepicker from "../../Datepicker/Datepicker";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

// Schemas
import { APPLICATIONS_SCHEDULE_MEETING_VIDEO_CONFERENCE } from "../../../schemas/ApplicationsSchemas";

const EditVideoMeetingAppointment = ({
  details,
  handleCloseModal,
}: AppointmentsEditVideoMeetingAppointmentProps) => {
  const errorReporting = useErrorReporting();

  /*=========================
    VIDEO MEETING FORM
  ========================*/
  const [formDetails, setFormDetails] = useState<AppointmentsEditVideoMeetingFormDetails>({
    interviewer_id: null,
    meeting_name: "",
    appointment_time: new Date(),
    meeting_duration: null,
    timezone: "",
  });

  /*=========================
    LIST OF INTERVIEWERS
  ========================*/
  const { data: interviewersData, isLoading: interviewersDataIsLoading } = useUsersGetList();

  const INTERVIEWERS: DropdownItem[] = useMemo(() => {
    if (!interviewersData || !interviewersData.length || interviewersDataIsLoading) return [];

    // Map the list of company users (interviewers) into dropdown items
    const mappedInterviewers: DropdownItem[] = interviewersData
      .filter(user => user.status === "active")
      .map(interviewer => {
        return {
          text: handleFullnameCombination(interviewer),
          value: interviewer.id,
        };
      });

    return mappedInterviewers;
  }, [interviewersData]);

  /*=========================================
    LIST OF TIMEZONES
  ==========================================*/
  const { data: timezones, isLoading: timezonesLoading } = useTimezonesGet();

  const TIMEZONES: DropdownItem[] = useMemo(() => {
    if (!timezones || !Object.entries(timezones).length || timezonesLoading) return [];

    // Map the received timezones into dropdown items
    const mappedTimezones: DropdownItem[] = Object.entries(timezones).map(timezone => {
      // The "key" here is the actual timezone
      // The "value" here is the GMT hour offset
      const [key, value] = timezone;
      const [country, city] = key.split("/");

      return {
        text: `(${value.replace(/\s/, "")}) ${country}, ${city.split("_").join(" ")}`,
        value: key,
      };
    });

    return mappedTimezones;
  }, [timezones]);

  /*=========================================
    UPDATE THE FORM DETAILS

    Update the form details state after the data
    was successfully fetched for the Timezones and Interviewers
  ==========================================*/
  useEffect(() => {
    // Find the matching interviewer and extract the ID
    const matchingInterviewer = INTERVIEWERS.find(interviewer => {
      return interviewer.text.toLowerCase() === details.interviewer.toLowerCase();
    });

    setFormDetails({
      interviewer_id: (matchingInterviewer?.value as number) ?? null,
      appointment_time: new Date(details.appointment_time),
      meeting_name: details.meeting_name ?? "",
      meeting_duration: details.meeting_duration,
      timezone: details.timezone ?? "",
    });
  }, [timezones, INTERVIEWERS]);

  /*=========================================
    SCHEDULE A "VIDEO MEETING" APPOINTMENT
  ==========================================*/
  const editVideoMeetingAppointment = useAppointmentsEditVideoMeetingAppointment();

  const handleScheduleVideoConference = async (values: ScheduleVideoConferenceFormValues) => {
    // Exit function if there are no valid required values
    if (values.interviewer_id === null || values.meeting_duration === null) return;

    try {
      const { interviewer_id, meeting_name, meeting_duration, timezone, appointment_time } = values;

      // Convert the selected date & time value so it also shows the hours in 12h mode with AM/PM
      const CONVERTED_APPOINTMENT_TIMESTAMP: string = format(
        appointment_time,
        "MM/dd/yyyy hh:mm a",
      );

      // Send the API request
      await editVideoMeetingAppointment.mutateAsync({
        application_id: details.applicationID,
        appointment_id: details.id,
        type: "video",
        interviewer_id,
        meeting_name,
        meeting_duration,
        timezone,
        appointment_time: CONVERTED_APPOINTMENT_TIMESTAMP,
      });

      // Close the menu after the appointment was made
      handleCloseModal();
    } catch (error) {
      errorReporting("Failed editing 'Video Meeting' appointment.", error, {
        application_id: details.applicationID,
        appointment_id: details.id,
      });
    }
  };

  return (
    <Modal
      title=""
      text=""
      modifierClass="modal--fixated applications-schedule-meeting applications-schedule-meeting-modal"
      handleCloseModal={handleCloseModal}
    >
      <Formik
        initialValues={formDetails}
        enableReinitialize
        onSubmit={handleScheduleVideoConference}
        validationSchema={APPLICATIONS_SCHEDULE_MEETING_VIDEO_CONFERENCE}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <h2 className="txt--center mb--20">
              <strong>Edit Video Conference</strong>
            </h2>

            <Field
              component={FormDropdownSearchable}
              id="interviewer_id"
              name="interviewer_id"
              label="Meeting With"
              placeholder="Select Interviewer"
              items={INTERVIEWERS}
              isLoading={interviewersDataIsLoading}
              disabled={interviewersDataIsLoading || INTERVIEWERS.length === 0}
              handleFieldUpdate={(meetingWith: DropdownItem) => {
                setFieldValue("interviewer_id", meetingWith.value);
              }}
              preselectedItemValue={formDetails.interviewer_id}
              size="full mb--15"
            />

            <Field
              component={FormInput}
              label="Meeting Name"
              id="meeting_name"
              name="meeting_name"
              placeholder="Example: Account's Interview Meeting"
              modifierClass="mb--15"
            />

            <label className="dropdown__label">Date, Time and Duration</label>

            <div className="p--relative d-flex flex-column flex-md-row align-items-center mb--20">
              <Datepicker
                modifierClass="datepicker--full m--0"
                preselectedDate={formDetails.appointment_time}
                handleSelectedDate={dates => setFieldValue("appointment_time", dates[0])}
                placeholder={
                  formDetails.appointment_time
                    ? format(formDetails.appointment_time, "MM/dd/yyyy hh:mm a")
                    : undefined
                }
              />
              {errors.appointment_time && touched.appointment_time ? (
                <span className="input__error" style={{ position: "absolute", bottom: "-22px" }}>
                  <ErrorMessage name="appointment_time" />
                </span>
              ) : null}

              <Field
                component={FormDropdownSearchable}
                id="meeting_duration"
                name="meeting_duration"
                placeholder="30min"
                items={[
                  { text: "30min", value: 30 },
                  { text: "1h", value: 60 },
                  { text: "1h 30min", value: 90 },
                  { text: "2h", value: 120 },
                  { text: "2h 30min", value: 150 },
                  { text: "3h", value: 180 },
                ]}
                handleFieldUpdate={(meetingDuration: DropdownItem) => {
                  setFieldValue("meeting_duration", meetingDuration.value);
                }}
                preselectedItemValue={values.meeting_duration}
                size="sm"
                modifierClass="datepicker__duration"
              />
            </div>

            <Field
              component={FormDropdownSearchable}
              id="timezone"
              name="timezone"
              label="Timezone"
              placeholder="Select Timezone"
              items={TIMEZONES}
              handleFieldUpdate={(timezone: DropdownItem) => {
                setFieldValue("timezone", timezone.value);
              }}
              isLoading={timezonesLoading}
              disabled={!TIMEZONES.length || timezonesLoading}
              preselectedItemValue={details.timezone}
              size="full"
              modifierClass="mb--30"
            />

            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="submit"
                modifierClass="btn--fluid btn--pill btn--primary--dark btn--border btn--border--primary ml--10"
                isLoading={editVideoMeetingAppointment.isLoading}
                isDisabled={
                  editVideoMeetingAppointment.isLoading ||
                  (errors && Object.keys(errors).length ? true : false)
                }
              >
                Save New Meeting Details
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditVideoMeetingAppointment;
