import { format } from "date-fns";
import { ApplicationsResponseFields } from "../../../api/Applications/interfaces";
import { DocumentExportColumnDetails } from "../../../pages/Reports/interfaces";
import handleInitiateSpreadsheetGenerator from "../../../utilities/data/handleInitiateSpreadsheetGenerator";
import { handleCSVExportPhoneFormatting } from "../../../utilities/strings/handleCSVExportPhoneFormatting";

/**
 *
 * Utility function for exporting selected applications data
 * in CSV format. The function is adjusted to handle both v1 & v2 application forms
 * that can include either the 'current_employer' & 'past_employer' from the v1 form
 * OR the 'employer_1', 'employer_2' which are with dynamic length according to needs
 *
 * @param data Array of application data objects
 *
 */
export const handleExportSelectedCSV = (data: ApplicationsResponseFields[] | undefined) => {
  if (!data) return;

  // Call the 'handleEmployerColumns' function to generate the appropriate dynamic columns for the received data
  const { employerColumns, employerDataFieldNames } = handleEmployerColumns(data);

  // Build columns object with the predefined expected values PLUS the dynamically present fields
  const columns: DocumentExportColumnDetails[] = [
    { text: "Job Title", field_name: "job_title", width_pdf: "*", width_excel: 0 },
    { text: "First Name", field_name: "first_name", width_pdf: "*", width_excel: 0 },
    { text: "Last Name", field_name: "last_name", width_pdf: "*", width_excel: 0 },
    { text: "Street Address", field_name: "street_address", width_pdf: "*", width_excel: 0 },
    { text: "City", field_name: "city", width_pdf: "*", width_excel: 0 },
    { text: "State", field_name: "state", width_pdf: "*", width_excel: 0 },
    { text: "Zip Code", field_name: "zip_code", width_pdf: "*", width_excel: 0 },
    { text: "Application Date", field_name: "registration_date", width_pdf: "*", width_excel: 0 },
    { text: "Phone Number", field_name: "phone_number", width_pdf: "*", width_excel: 0 },
    { text: "Email Address", field_name: "email_address", width_pdf: "*", width_excel: 0 },
    { text: "SBCA Score", field_name: "sbca_score", width_pdf: "*", width_excel: 0 },
    { text: "Text Consent", field_name: "text_consent", width_pdf: "*", width_excel: 0 },
    { text: "Bucket", field_name: "bucket", width_pdf: "*", width_excel: 0 },
    ...employerColumns,
  ];

  // Map the received data before sending to the CSV generator function
  // Also include the dynamic 'employerDataFieldNames' with their appropriate field data
  // Sort the final result in alphabetical order by the 'job_title' keys
  const EXPORT_APPLICATIONS_DATA = data
    .map((entity: ApplicationsResponseFields) => {
      // Destructure & map the extra employer fields with their values
      const mappedValuesObject = {};
      employerDataFieldNames.forEach((field_name: string) =>
        Object.assign(mappedValuesObject, {
          [field_name]: entity.applicant.employer[field_name] || "N/A",
        }),
      );

      return {
        job_title: entity.position || "N/A",
        first_name: entity.applicant.first_name || "N/A",
        last_name: entity.applicant.last_name || "N/A",
        street_address: entity.applicant.address || "N/A",
        city: entity.applicant.city || "N/A",
        state: entity.applicant.state || "N/A",
        zip_code: entity.applicant.zip_code || "N/A",
        registration_date: entity.registration_date
          ? format(new Date(entity.registration_date), "yyyy/MM/dd hh:mma")
          : "N/A",
        phone_number: handleCSVExportPhoneFormatting(entity.applicant.phone),
        email_address: entity.applicant.email || "N/A",
        sbca_score: entity.applicant.sbca || "N/A",
        text_consent: entity.text_message_consent,
        bucket: entity.buckets[0] || "N/A",
        ...mappedValuesObject,
      };
    })
    .sort((a, b) => {
      return a.job_title.localeCompare(b.job_title, "en");
    });

  // Call generator function with the created columns and mapped data
  handleInitiateSpreadsheetGenerator(
    EXPORT_APPLICATIONS_DATA,
    "applications_data",
    columns,
    "default-csv",
  );
};

/**
 *
 * Utility function used to generate columns for data that includes dynamic/optional fields
 *
 * @param applications Applications data object containing the dynamic field keys in the employer object
 * @returns The dynamic columns that are meant to be passed into 'DocumentExportColumnDetails' column objects
 *
 */
export const handleEmployerColumns = (applications: ApplicationsResponseFields[]) => {
  const employerColumns: DocumentExportColumnDetails[] = [];
  const employerDataFieldNames: string[] = [];

  // Go trough each application
  applications.forEach((application: ApplicationsResponseFields) => {
    // Go trough each applications' "employer" data
    Object.keys(application.applicant.employer).forEach(key => {
      // Exit function if this column object already exists in the array
      const columnExists = employerColumns.some(col => {
        return col.field_name === key;
      });

      if (columnExists) return;

      // Manipulate the name of the employer field so it will be uppercased
      const employerFieldName = key
        .split("_")
        .map(str => {
          if (!str) return ""; // Safeguard in case the key somehow is an empty string
          return str[0].toUpperCase() + str.slice(1);
        })
        .join(" ");

      // Append the newly created column object to the array of columns
      // that will be used for the CSV export
      employerColumns.push({
        text: employerFieldName,
        field_name: key,
        width_pdf: "*",
        width_excel: 0,
      });

      // Column field names that will match the data field names of each entity
      employerDataFieldNames.push(key);
    });
  });

  return { employerColumns, employerDataFieldNames };
};
