// Components
import AssessmentProgressBox from "../AssessmentProgressBox";

// Interfaces
import { AssessmentProgressBarCardProps } from "./interfaces";
import { AssessmentAttrubuteRowsFields } from "../../../api/Assessment/interfaces";

const AssessmentProgressBarCard = ({ data }: AssessmentProgressBarCardProps) => {
  return (
    <>
      {Array.from(data).map((categoryRow: AssessmentAttrubuteRowsFields, index: number) => (
        <div
          key={`attribute-row-${index}`}
          className={`${index !== data.length - 1 ? "line-under line-under--absolute mb--20" : ""}`}
        >
          <div className="row">
            <div className="col-md-4">
              <h3 className="mb--10">
                <strong>
                  {categoryRow.label}:{" "}
                  <span className={`assessment-txt--${categoryRow.progress_bar.color}`}>
                    {categoryRow.score}
                  </span>
                </strong>
              </h3>
              <p className="assessment-txt">{categoryRow.description}</p>
            </div>
            <div className="col-md-8">
              <AssessmentProgressBox data={categoryRow.progress_bar} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default AssessmentProgressBarCard;
