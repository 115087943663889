// Utilities & hooks
import { useUserUpdateProfileSettings } from "../../api/User/User";
import { USER_PROFILE_SETTINGS_UPDATE } from "../../schemas/UserSchemas";
import { useAuth } from "../../providers/auth-context";
import { useBackNavigation } from "../../hooks/useBackNavigation";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Formik, Form, Field } from "formik";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import ContentHeader from "../../components/Content/ContentHeader";
import Skeleton from "react-loading-skeleton";
import FormPasswordInput from "../../components/Form/FormPasswordInput";

// Interfaces
import { UserUpdateProfileSettingsPayload } from "../../api/User/interfaces";

const ProfileSettings = () => {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  /*==============================
    UPDATE SETTINGS
  ===============================*/
  const updateProfileSettings = useUserUpdateProfileSettings();

  const handleUpdateProfileSettings = async (values: UserUpdateProfileSettingsPayload) => {
    try {
      await updateProfileSettings.mutateAsync(values);
    } catch (error) {
      errorReporting("Failed updating profile settings", error, { ...values });
    }
  };

  /*==============================
    CUSTOM BACK NAVIGATION
  ===============================*/
  const handleNavigateBack = useBackNavigation();

  return (
    <div className="container py--25">
      <ContentHeader title="Profile Settings" modifierClass="mb--25" />
      <Card modifierClass="card--padding--xl">
        <div className="table-wrapper table-wrapper--no-shadow px--0i py--0i">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%", wordBreak: "break-word" }}>
                  {!user.first_name ? (
                    <Skeleton width="250px" height="15px" />
                  ) : (
                    <>
                      {user.first_name} {user.last_name}
                    </>
                  )}
                </td>
                <td style={{ width: "50%", wordBreak: "break-word" }}>
                  {!user.email ? <Skeleton width="200px" height="15px" /> : user.email}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="fw--semibold txt--md txt--black mb--40">
          <span className="txt--blue-light txt--lg d-block">Did you know?</span>
          Security experts agree you should change your password a minimum of every 90 days.
        </p>

        <Formik
          initialValues={{ current_password: "", new_password: "", new_password_confirmation: "" }}
          validationSchema={USER_PROFILE_SETTINGS_UPDATE}
          enableReinitialize
          onSubmit={handleUpdateProfileSettings}
        >
          <Form className="container--xxs mx--auto">
            <Field
              component={FormPasswordInput}
              id="current_password"
              name="current_password"
              placeholder="Enter current password"
              modifierClass="mb--15"
            />

            <Field
              component={FormPasswordInput}
              id="new_password"
              name="new_password"
              placeholder="Enter new Password"
              modifierClass="mb--15"
            />

            <Field
              component={FormPasswordInput}
              id="new_password_confirmation"
              name="new_password_confirmation"
              placeholder="Confirm new password"
              modifierClass="mb--15"
            />

            <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-center">
              {user.active_company.slug ? (
                <Button
                  type="button"
                  modifierClass="btn--fluid btn--fluid--md btn--primary--light fw--semibold mb--md--10"
                  onClick={() => handleNavigateBack("/applications/")}
                >
                  Cancel
                </Button>
              ) : null}

              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--md--0"
                isLoading={updateProfileSettings.isLoading}
                isDisabled={updateProfileSettings.isLoading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </Card>
    </div>
  );
};
export default ProfileSettings;
