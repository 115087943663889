// Hooks
import { useEffect, useMemo } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useCompaniesGetAvailablePositions } from "../../api/Company/Company";
import { useAuth } from "../../providers/auth-context";

// Components
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Inputs/Checkbox";
import ApplicationBulkBucketMovement from "./Buckets/ApplicationBulkBucketMovement";
import InputFieldSearch from "../Inputs/InputFieldSearch";
import DropdownMultiselect from "../Dropdown/DropdownMultiselect";
import Button from "../Button/Button";

// Interfaces
import { ApplicationFiltersProps } from "./interfaces";
import { DropdownItem } from "../Dropdown/interfaces";
import { UserRoleNames } from "../../interfaces/global";

// Statics
import { APPLICAITON_DETAILS_FILTERS } from "./statics";

const ApplicationFilters = ({
  applicationsIDs,
  currentBucketFilter,
  hasActiveSelection = false,
  hasAllApplicationsSelected = false,
  filtersDisabled = false,
  groupedApplicationBuckets = {},
  handleApplicationsPositionFilter,
  handleApplicationsFilters,
  handleApplicationsAllSelection,
  handleApplicationsResetSelection,
  handleApplicationsSearch,
  handlePaginationReset,
  handleExportSelected,
}: ApplicationFiltersProps) => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  const { user } = useAuth();

  /*================================
    COMPANY POSITIONS
  =================================*/
  const { data, isLoading } = useCompaniesGetAvailablePositions();

  const COMPANY_POSITIONS: DropdownItem[] = useMemo(() => {
    // Exit function if there are no companies available
    if (!data || !data.length || isLoading) return [];

    const mappedPosistions: DropdownItem[] = data
      .map((position: string) => {
        return { text: position, value: position };
      })
      .sort((positionA, positionB) => {
        return positionA.text.toLowerCase() > positionB.text.toLowerCase() ? 1 : -1;
      });

    return [{ text: "All Positions", value: "all" }, ...mappedPosistions];
  }, [data]);

  /*===================================
    COMPANY POSITION FILTER SELECTION
  ====================================*/
  const handlePositionFiltersSelection = (position: string, resetPagination: boolean = true) => {
    // Trigger received applications filtering callback
    handleApplicationsPositionFilter(position);

    // Update the search parameters
    setSearchParametersObject({
      ...searchParametersObject,
      position: position,
      ...(resetPagination && { page: 1 }),
    });

    // Reset pagination to first page
    if (resetPagination) handlePaginationReset();
  };

  // Pre-select the "Company Position" filter if received as a search parameter in the URL
  useEffect(() => {
    const { position } = searchParametersObject;

    // Exit function if there's no received 'position' search parameter, or if there are no items to work with
    if (!position || COMPANY_POSITIONS.length === 0) return;

    // Check for matching existing position with the one received from the search parameter
    const matchingPosition: DropdownItem | undefined = COMPANY_POSITIONS.find(companyPosition => {
      const companyPositionName: string = companyPosition.value as string;
      return companyPositionName.toLowerCase() === position.toLowerCase();
    });

    handlePositionFiltersSelection(matchingPosition ? position : "all", false);
  }, [COMPANY_POSITIONS]);

  /*===================================
    DETAILS FILTER SELECTION

    (SBCA/Resume/Linked In)
  ====================================*/
  const handleDetailsFiltersSelection = (filters: string[], resetPagination: boolean = true) => {
    // Trigger received applications filtering callback
    handleApplicationsFilters(filters);

    // Update the search parameters to include the 'details' filters
    if (filters.length) {
      setSearchParametersObject({
        ...searchParametersObject,
        details: filters.join(","),
        ...(resetPagination && { page: 1 }),
      });
    } else {
      delete searchParametersObject.details;
      setSearchParametersObject({ ...searchParametersObject, ...(resetPagination && { page: 1 }) });
    }

    // Reset pagination to first page
    if (resetPagination) handlePaginationReset();
  };

  // Pre-select the received "Details" filters (if they exist in the search parameters)
  useEffect(() => {
    if (!searchParametersObject.details) return;

    // Check if the received "details" search parameter exists in the pre-defined list
    const preselectedDetailsFilters = APPLICAITON_DETAILS_FILTERS.filter(filter => {
      return searchParametersObject.details.split(",").some((param: string) => {
        const filterName: string = filter.value as string;
        return param.toLowerCase() === filterName.toLowerCase();
      });
    }).map(filter => filter.value);

    handleDetailsFiltersSelection(preselectedDetailsFilters as string[], false);
  }, []);

  return (
    <div className="applications__filters">
      <div className="applications__filters__container">
        {/* APPLICATION POSITION */}
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-md-center justify-content-xl-start w--100">
          <InputFieldSearch
            handleOnSearch={handleApplicationsSearch}
            placeholder="Search"
            modifierClass="mr--10 mb--md--10 mr--md--0"
            size="md"
          />

          <Dropdown
            title="All Positions"
            size="sm"
            items={COMPANY_POSITIONS}
            handleItemSelected={(position: DropdownItem) => {
              handlePositionFiltersSelection(position.value as string);
            }}
            isLoading={isLoading}
            disabled={filtersDisabled || isLoading || !COMPANY_POSITIONS.length}
            modifierClass="mr--10 mb--md--10 mr--md--0"
            preselectedItemValue={searchParametersObject.position || null}
          />

          {/* APPLICATION FILTERING OPTIONS */}
          <DropdownMultiselect
            items={APPLICAITON_DETAILS_FILTERS}
            disabled={filtersDisabled}
            placeholder="More"
            clearable
            size="xs"
            preselectedItems={searchParametersObject?.details?.split(",") || []}
            handleSelectedItems={handleDetailsFiltersSelection}
          />
        </div>

        {/* APPLICATION SELECTION AND MOVEMENT TO BUCKETS */}
        <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
          <div className="applications__selection">
            <Checkbox
              id="all"
              name="all"
              value="all"
              label="Select All"
              handleCheckboxAction={handleApplicationsAllSelection}
              modifierClass={hasActiveSelection ? "input-checkbox--indeterminate" : ""}
              isChecked={!filtersDisabled && hasAllApplicationsSelected}
              isDisabled={filtersDisabled}
              isIndeterminate={hasActiveSelection && !hasAllApplicationsSelected}
            />
            <ApplicationBulkBucketMovement
              ids={applicationsIDs}
              title="Move To"
              isDisabled={filtersDisabled}
              groupedApplicationBuckets={groupedApplicationBuckets}
              handleApplicationsResetSelection={handleApplicationsResetSelection}
              currentBucketFilter={currentBucketFilter}
            />
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--md--0"
                onClick={() => handleExportSelected()}
                isDisabled={!hasActiveSelection}
              >
                Export CSV
              </Button>
            ) : null}
          </div>
        </PermissionCheckComponentWrapper>
      </div>
    </div>
  );
};

export default ApplicationFilters;
