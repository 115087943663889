import { DropdownItem } from "../../../components/Dropdown/interfaces";

export const CLIENTS_APPLICANT_PHOTO_VIEWING_DROPDOWN: DropdownItem[] = [
  { value: 0, text: "Disable" },
  { value: 1, text: "Enable" },
];

export const CLIENTS_DISABLE_SBCA_DROPDOWN: DropdownItem[] = [
  { value: 1, text: "Disable" },
  { value: 0, text: "Enable" },
];

export const CLIENTS_STATUSES_DROPDOWN: DropdownItem[] = [
  { text: "Active", value: 1, description: "Active client and is in good standing" },
  { text: "Warning", value: 2, description: "Active client not in good standing" },
  { text: "Suspended", value: 3, description: "Inactive client not in good standing" },
  { text: "Disabled", value: 4, description: "Disabled client" },
  { text: "Pending", value: 5, description: "Inactive client pending activation" },
];
