// Components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MarketingBanner from "../components/Banner/MarketingBanner";

// Context Provider
import { ChatContextWrapper } from "../components/Chat/ChatWrapper/ChatContextWrapper";

// Hooks
import { useAuth } from "../providers/auth-context";
import { useEffect, useState } from "react";

interface LayoutAuthenticatedProps {
  children: React.ReactNode;
  isFullHeader?: boolean;
}

const LayoutAuthenticated = ({ isFullHeader = true, children }: LayoutAuthenticatedProps) => {
  const { user } = useAuth();
  const [showBannerOnPageLoad, setShowBannerOnPageLoad] = useState<boolean>(false);

  useEffect(() => {
    if (!user || !user.marketing_banners.length) {
      setShowBannerOnPageLoad(false);
    } else {
      setShowBannerOnPageLoad(true);
    }
  }, [user]);

  return (
    <ChatContextWrapper>
      {showBannerOnPageLoad ? (
        <MarketingBanner
          banners={user.marketing_banners}
          showBannerOnPageLoad={showBannerOnPageLoad}
        />
      ) : null}
      <Header isFullHeader={isFullHeader} />
      <main>{children}</main>
      <Footer />
    </ChatContextWrapper>
  );
};

export default LayoutAuthenticated;
