// Hooks
import { useEffect } from "react";

// Components
import { Field, FieldArray } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";
import Button from "../../../Button/Button";

// Interfaces
import { JobBoardsFormsProps } from "../interfaces";
import { DropdownItem } from "../../../Dropdown/interfaces";

// Assets
import { FaDollarSign as DollarIcon } from "react-icons/fa";

// Dropdown datasets
import {
  ZIP_RECRUITER_DROPDOWN_JOB_CATEGORY,
  ZIP_RECRUITER_DROPDOWN_JOB_COMPENSATION_TYPE,
  ZIP_RECRUITER_DROPDOWN_JOB_EDUCATION_LEVEL,
  ZIP_RECRUITER_DROPDOWN_JOB_EXPERIENCE_LEVEL,
  ZIP_RECRUITER_DROPDOWN_JOB_STATUS,
} from "./dropdown-data/ZipRecruiterDropdowns";
import FormInputWithElements from "../../../Form/FormInputWithElements";
import {
  SCREENING_QUESTIONS_IS_REQUIRED,
  SCREENING_QUESTIONS_NEW_QUESTION,
  SCREENING_QUESTIONS_PREFERRED_ANSWERS,
} from "./dropdown-data/ScreeningQuestions";

const ZipRecruiterForm = ({
  values,
  isSubmitDisabled = false,
  isSubmitLoading = false,
  validateField,
  setFieldValue,
}: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.zip_recruiter.enabled) validateField("zip_recruiter");
  }, [values.zip_recruiter]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Field
          component={FormCheckbox}
          name="zip_recruiter.enabled"
          id="zip_recruiter.enabled"
          label="Enable ZipRecruiter"
          checked={values.zip_recruiter.enabled}
          labelModifierClass="input__label--sm"
        />

        {values.zip_recruiter.enabled ? (
          <div className="d-flex justify-content-end w--100">
            <Button
              modifierClass="btn--fluid btn--primary"
              isDisabled={isSubmitDisabled || isSubmitLoading}
              isLoading={isSubmitLoading}
            >
              Save Changes
            </Button>
          </div>
        ) : null}
      </div>

      {values.zip_recruiter.enabled ? (
        <>
          <Field
            component={FormCheckbox}
            name="zip_recruiter.sponsored"
            id="zip_recruiter.sponsored"
            label="Sponsored"
            checked={values.zip_recruiter.sponsored}
            labelModifierClass="input__label--sm"
          />

          {/* Temporarily Disabled */}
          {/* {values.zip_recruiter.sponsored ? (
            <>
              <Field
                component={FormDropdownSearchable}
                name="zip_recruiter.campaign"
                id="zip_recruiter.campaign"
                label="Campaign"
                items={ZIP_RECRUITER_DROPDOWN_SPONSORED_CAMPAIGNS}
                handleFieldUpdate={(campaign: DropdownItem) => {
                  setFieldValue("zip_recruiter.campaign", campaign.value);
                }}
                isRequired
                size="full"
                placeholder="Select a Campaign"
                preselectedItemValue={values.zip_recruiter.campaign}
                modifierClass={`dropdown--default-label ${
                  values.zip_recruiter.campaign ? "mb--20" : ""
                }`}
              />
              {!values.zip_recruiter.campaign ? (
                <p className="input__error">Please select a campaign</p>
              ) : null}
            </>
          ) : null} */}

          <Field
            component={FormInput}
            name="zip_recruiter.ad_title"
            id="zip_recruiter.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormDropdownSearchable}
            name="zip_recruiter.category_id"
            id="zip_recruiter.category_id"
            label="Job Category"
            items={ZIP_RECRUITER_DROPDOWN_JOB_CATEGORY}
            handleFieldUpdate={(jobCategory: DropdownItem) => {
              setFieldValue("zip_recruiter.category_id", jobCategory.value);
            }}
            size="full"
            placeholder="None - Optional"
            preselectedItemValue={values.zip_recruiter.category_id}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="zip_recruiter.job_status"
            id="zip_recruiter.job_status"
            label="Job Status"
            items={ZIP_RECRUITER_DROPDOWN_JOB_STATUS}
            handleFieldUpdate={(jobStatus: DropdownItem) => {
              setFieldValue("zip_recruiter.job_status", jobStatus.value);
            }}
            size="full"
            placeholder="Select a job status"
            preselectedItemValue={values.zip_recruiter.job_status}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="zip_recruiter.experience_level"
            id="zip_recruiter.experience_level"
            label="Job Experience Level"
            items={ZIP_RECRUITER_DROPDOWN_JOB_EXPERIENCE_LEVEL}
            handleFieldUpdate={(jobExperience: DropdownItem) => {
              setFieldValue("zip_recruiter.experience_level", jobExperience.value);
            }}
            size="full"
            placeholder="Select a job experience level"
            preselectedItemValue={values.zip_recruiter.experience_level}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="zip_recruiter.education_level"
            id="zip_recruiter.education_level"
            label="Education Level"
            items={ZIP_RECRUITER_DROPDOWN_JOB_EDUCATION_LEVEL}
            handleFieldUpdate={(educationLevel: DropdownItem) => {
              setFieldValue("zip_recruiter.education_level", educationLevel.value);
            }}
            size="full"
            placeholder="Select an education level"
            preselectedItemValue={values.zip_recruiter.education_level}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormDropdownSearchable}
            name="zip_recruiter.compensation_type"
            id="zip_recruiter.compensation_type"
            label="Compensation Type"
            items={ZIP_RECRUITER_DROPDOWN_JOB_COMPENSATION_TYPE}
            handleFieldUpdate={(compensationType: DropdownItem) => {
              setFieldValue("zip_recruiter.compensation_type", compensationType.value);
            }}
            size="full"
            placeholder="Select a compensation type"
            preselectedItemValue={values.zip_recruiter.compensation_type}
            modifierClass="dropdown--default-label mb--30"
          />

          <Field
            component={FormInputWithElements}
            name="zip_recruiter.compensation_min"
            id="zip_recruiter.compensation_min"
            label="Compensation Min"
            placeholder="Amount (example 32000 or 32000.00)"
            modifierClass="input--default-label mb--20"
            leftElement={<DollarIcon />}
            type="number"
            min={0}
            step="0.05"
          />

          <Field
            component={FormInputWithElements}
            name="zip_recruiter.compensation_max"
            id="zip_recruiter.compensation_max"
            label="Compensation Max"
            placeholder="Amount (example 72000 or 72000.00)"
            modifierClass="input--default-label mb--20"
            leftElement={<DollarIcon />}
            type="number"
            min={0}
            step="0.05"
          />

          <Field
            component={FormCheckbox}
            name="zip_recruiter.has_commission"
            id="zip_recruiter.has_commission"
            label="Has Commission"
            checked={values.zip_recruiter.has_commission}
            labelModifierClass="input__label--sm mb--0i"
          />

          <Field
            component={FormCheckbox}
            name="zip_recruiter.resume_not_required"
            id="zip_recruiter.resume_not_required"
            label="Resume not required"
            checked={values.zip_recruiter.resume_not_required}
            labelModifierClass="input__label--sm mb--0i"
          />

          <div className="input--default-label">
            <label className="input__label mb--0i">Benefits</label>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <Field
                component={FormCheckbox}
                name="zip_recruiter.benefits_dental"
                id="zip_recruiter.benefits_dental"
                label="Dental"
                checked={values.zip_recruiter.benefits_dental}
                modifierClass="mb--0i"
                labelModifierClass="input__label--sm mb--0i"
              />
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <Field
                component={FormCheckbox}
                name="zip_recruiter.benefits_medical"
                id="zip_recruiter.benefits_medical"
                label="Medical"
                checked={values.zip_recruiter.benefits_medical}
                modifierClass="mb--0i"
                labelModifierClass="input__label--sm mb--0i"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <Field
                component={FormCheckbox}
                name="zip_recruiter.benefits_vision"
                id="zip_recruiter.benefits_vision"
                label="Vision"
                checked={values.zip_recruiter.benefits_vision}
                modifierClass="mb--0i"
                labelModifierClass="input__label--sm mb--0i"
              />
            </div>

            <div className="col-12 col-sm-6">
              <Field
                component={FormCheckbox}
                name="zip_recruiter.benefits_life_insurance"
                id="zip_recruiter.benefits_life_insurance"
                label="Life Insurance"
                checked={values.zip_recruiter.benefits_life_insurance}
                modifierClass="mb--0i"
                labelModifierClass="input__label--sm mb--0i"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6">
              <Field
                component={FormCheckbox}
                name="zip_recruiter.benefits_retirement_savings"
                id="zip_recruiter.benefits_retirement_savings"
                label="Retirement Savings"
                checked={values.zip_recruiter.benefits_retirement_savings}
                labelModifierClass="input__label--sm mb--0i"
              />
            </div>
          </div>

          <div className="input--default-label">
            <label className="input__label mb--0i">Screening Questions</label>
          </div>

          <Field
            component={FormCheckbox}
            name="zip_recruiter.screening_enabled"
            id="zip_recruiter.screening_enabled"
            label="Enable Screening Questions"
            checked={values.zip_recruiter.screening_enabled}
            labelModifierClass="input__label--sm"
          />

          {values.zip_recruiter.screening_enabled ? (
            <>
              <p className="txt--md txt--black">
                Enter up to 5 questions consisting of "Yes" or "No" answers.
              </p>

              <FieldArray
                name="zip_recruiter.screening_questions"
                render={arrayHelpers => (
                  <div>
                    {values.zip_recruiter.screening_questions.length ? (
                      <div className="row no-gutters">
                        {values.zip_recruiter.screening_questions.map(
                          (_: unknown, questionIndex: number) => (
                            <div className="w--100" key={`screening-question-${questionIndex}`}>
                              {/* Title of each question */}
                              <div className="row">
                                <div className="col-12">
                                  <Field
                                    component={FormInput}
                                    name={`zip_recruiter.screening_questions.${questionIndex}.question`}
                                    id={`zip_recruiter.screening_questions.${questionIndex}.question`}
                                    label={`Question #${questionIndex + 1}`}
                                    placeholder={`Screening question ${questionIndex + 1}`}
                                    modifierClass="input--default-label mb--20"
                                    isRequired
                                  />
                                </div>

                                {/* Selection if the answer is required or not */}
                                <div className="col-12 col-lg-6">
                                  <Field
                                    component={FormDropdownSearchable}
                                    name={`zip_recruiter.screening_questions.${questionIndex}.is_required`}
                                    id={`zip_recruiter.screening_questions.${questionIndex}.is_required`}
                                    label="Is this question required?"
                                    items={SCREENING_QUESTIONS_IS_REQUIRED}
                                    handleFieldUpdate={(answer: DropdownItem) => {
                                      setFieldValue(
                                        `zip_recruiter.screening_questions.${questionIndex}.is_required`,
                                        answer.value,
                                      );
                                    }}
                                    size="full"
                                    placeholder="Select Required or not"
                                    preselectedItemValue={
                                      values.zip_recruiter.screening_questions[questionIndex]
                                        .is_required
                                    }
                                    modifierClass="dropdown--default-label mb--30"
                                  />
                                </div>

                                {/* Selection of preferred answer */}
                                <div className="col-12 col-lg-6">
                                  <Field
                                    component={FormDropdownSearchable}
                                    name={`zip_recruiter.screening_questions.${questionIndex}.preferred_answers`}
                                    id={`zip_recruiter.screening_questions.${questionIndex}.preferred_answers`}
                                    label={`Preferred Answer #${questionIndex + 1}`}
                                    items={SCREENING_QUESTIONS_PREFERRED_ANSWERS}
                                    handleFieldUpdate={(answer: DropdownItem) => {
                                      setFieldValue(
                                        `zip_recruiter.screening_questions.${questionIndex}.preferred_answers`,
                                        [answer.value],
                                      );
                                    }}
                                    size="full"
                                    placeholder="Select a preferred answer"
                                    preselectedItemValue={
                                      values.zip_recruiter.screening_questions[questionIndex]
                                        .preferred_answers[0]
                                    }
                                    modifierClass="dropdown--default-label mb--30"
                                  />
                                </div>
                              </div>

                              {/* Show the "Remove" button if there's more than one question */}
                              {values.zip_recruiter.screening_questions.length > 1 ? (
                                <div>
                                  <Button
                                    type="button"
                                    modifierClass="btn--danger btn--fluid mb--10"
                                    onClick={() => arrayHelpers.remove(questionIndex)}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              ) : null}

                              {/* Do not display separator line on the 5th question */}
                              {questionIndex < 4 ? <hr /> : null}
                            </div>
                          ),
                        )}

                        {values.zip_recruiter.screening_questions.length < 5 ? (
                          <Button
                            type="button"
                            modifierClass="btn--secondary btn--fluid my--20"
                            onClick={() => {
                              arrayHelpers.push(SCREENING_QUESTIONS_NEW_QUESTION);
                            }}
                          >
                            Add Question
                          </Button>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <p>No Screening Questions added.</p>

                        <Button
                          type="button"
                          modifierClass="btn--secondary btn--fluid my--20"
                          onClick={() => {
                            arrayHelpers.push(SCREENING_QUESTIONS_NEW_QUESTION);
                          }}
                        >
                          Add Question
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default ZipRecruiterForm;
