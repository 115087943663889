// Interfaces
import { ApplicationRouteProps } from "./interfaces";

// Pages
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Autologin from "../pages/Authentication/Autologin";
import LayoutUnauthenticated from "../layout/LayoutUnauthenticated";

export const ROUTES_AUTHENTICATION: ApplicationRouteProps[] = [
  {
    path: "/login",
    element: (
      <LayoutUnauthenticated>
        <Login />
      </LayoutUnauthenticated>
    ),
    permissions: ["*"],
    type: "auth",
  },
  {
    path: "/forgot-password",
    element: (
      <LayoutUnauthenticated>
        <ForgotPassword />
      </LayoutUnauthenticated>
    ),
    permissions: ["*"],
    type: "auth",
  },
  {
    path: "/reset-password",
    element: (
      <LayoutUnauthenticated>
        <ResetPassword />
      </LayoutUnauthenticated>
    ),
    permissions: ["*"],
    type: "auth",
  },
  {
    path: "/autologin",
    element: <Autologin />,
    permissions: ["*"],
    type: "public",
  },
];
