import React, { useRef, useState } from "react";

// Hooks & Utils
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { motion, AnimatePresence } from "framer-motion";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import { useAuth } from "../../providers/auth-context";

// Interfaces
import { HeaderDropdownGeneralProps } from "./interfaces";

// Statics
import { ADMIN_MENU_DROPDOWN } from "./statics";
import { FRAMER_HEADER_TRANSITIONS, FRAMER_HEADER_ANIMATION_COMMON } from "../../constants/framer";

// Assets
import { FaEllipsisH, FaChevronDown as ChevronIcon } from "react-icons/fa";

// Components
import { Link } from "react-router-dom";
import useWindowResize from "../../hooks/useWindowResize";

const HeaderDropdownAdminMenu: React.FC<HeaderDropdownGeneralProps> = ({ modifierClass = "" }) => {
  const { user } = useAuth();

  /*======================
    Close dropdown on click outside
  =======================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  // Use the window width to control the text shown for the menu
  const [windowWidth] = useWindowResize(100, window.innerWidth);

  return (
    <div
      ref={dropdownRef}
      className={`header-dropdown header-dropdown--admin ${modifierClass}`}
      tabIndex={0}
      data-testid="component:dropdown-header-admin-menu"
    >
      <button
        className={`header-dropdown__body ${isDropdownOpen ? "header-dropdown__body--active" : ""}`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {windowWidth > 767 ? (
          <FaEllipsisH fill="#fff" />
        ) : (
          <>
            Application Links
            <ChevronIcon className="header-dropdown__chevron" />
          </>
        )}
      </button>

      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            key="framer-header-admin-dropdown"
            className="header-dropdown__content header-dropdown__content--lg"
            variants={FRAMER_HEADER_ANIMATION_COMMON}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={FRAMER_HEADER_TRANSITIONS}
          >
            {ADMIN_MENU_DROPDOWN.map((link, index) => {
              if (link.path === "/assessment/request/" && user.active_company.disable_sbca) return;

              return (
                <PermissionCheckComponentWrapper
                  key={`header-dropdown-admin-permission-${index}`}
                  permissions={link.permissions}
                >
                  <Link
                    key={`dropdown-settings-${index}`}
                    className="header-dropdown__link header-dropdown__link--lg"
                    to={link.path}
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    <span className="header-dropdown__link__icon">{link.icon}</span>
                    {link.text}
                  </Link>
                </PermissionCheckComponentWrapper>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default HeaderDropdownAdminMenu;
