/**
 *
 * Utility function for handling pagination of the
 * mapped data that was received in the API response.
 *
 * @param dataArray The array containing the mapped data
 * @param currentPage Page on which the user is currently
 * @param countPerPage Total number of items displayed per page
 *
 * @returns The paginated part of the received mapped data
 */
export default function handleDataPagination<T>(
  dataArray: T[],
  currentPage: number,
  countPerPage: number,
): T[] {
  // If somehow a negative number is provided then exit the function
  if (currentPage < 0) return dataArray;

  // Copy the original array so we don't modify the data directly
  const originalArrayCopy = [...dataArray];

  const paginatedArray: T[] = originalArrayCopy.splice(currentPage * countPerPage, countPerPage);

  return paginatedArray;
}
