// Hooks & Utilities
import { format } from "date-fns";
import { useRegenerateAISummary } from "../../../api/Assessment/Assessment";
import { useAuth } from "../../../providers/auth-context";
import { handleDateAsTimestamp } from "../../../utilities/dates/handleDateAsTimestamp";
import useErrorReporting from "../../../hooks/useErrorReporting";

// Assets
import WarningIcon from "../../../assets/images/icons/assessment-warning.svg?react";

// Components
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";

// Interfaces
import { AssessmentAISummaryProps } from "./interfaces";
import { UserRoleNames } from "../../../interfaces/global";
import MarkdownWrapper from "../../Markdown/Markdown";

const AssessmentAISummary = ({
  isRegenerated,
  lastRegeneratedDate,
  text,
  sbcaReportIdentifier,
}: AssessmentAISummaryProps) => {
  const { user } = useAuth();
  const regenerateSummary = useRegenerateAISummary();

  const errorReporting = useErrorReporting();

  const handleRegenerateSummary = async () => {
    try {
      await regenerateSummary.mutateAsync(sbcaReportIdentifier);
    } catch (error) {
      errorReporting("Failed requesting SBCA AI Summary regeneration", error, {
        sbca_identifier: sbcaReportIdentifier,
      });
    }
  };

  return (
    <>
      <div className="box box--padding bg--gray mb--20">
        <div className="d-flex align-items-center">
          {isRegenerated ? (
            <div className="d-flex align-items-center">
              <Loader size="sm" modifierWrapper="mr--20" />
              <p className="assessment-txt assessment-txt--lg mb--0">
                A summary is being generated...
              </p>
            </div>
          ) : !text ? (
            <>
              <WarningIcon className="mr--15" />
              <p className="assessment-txt assessment-txt--lg mb--0">
                A summary has not been generated yet for this applicant.
              </p>
            </>
          ) : (
            <div>
              <MarkdownWrapper markdown={text} />
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb--10">
        <p className="mb--0 mb--sm--15 txt--center--sm">
          AI Insights can make mistakes. Consider checking important information.
        </p>

        {user.role === UserRoleNames.SUPER_ADMIN || user.role === UserRoleNames.ACCOUNT_MANAGER ? (
          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold"
            onClick={handleRegenerateSummary}
            isDisabled={isRegenerated || regenerateSummary.isLoading}
            isLoading={regenerateSummary.isLoading}
          >
            {lastRegeneratedDate ? "Regenerate Summary" : "Generate Summary"}
          </Button>
        ) : null}
      </div>

      {user.role === UserRoleNames.SUPER_ADMIN || user.role === UserRoleNames.ACCOUNT_MANAGER ? (
        <div className="d-flex justify-content-center justify-content-sm-end">
          <p className="mb--0">Last Generated Date: </p>
          <p className="ml--5">
            {isRegenerated
              ? "Generating..."
              : lastRegeneratedDate
              ? format(new Date(handleDateAsTimestamp(lastRegeneratedDate)), "MM/dd/yyyy hh:mm a")
              : "Never"}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default AssessmentAISummary;
