// Pages
import Users from "../pages/Account/Users/Users";
import ProfileSettings from "../pages/Account/ProfileSettings";
import UsersNew from "../pages/Account/Users/UsersNew";
import UsersEdit from "../pages/Account/Users/UsersEdit";
import Resources from "../pages/Account/Resources";
import ManageAlerts from "../pages/Account/ManageAlerts";
import VideoConferencing from "../pages/Account/VideoConferencing";
import UsersAdmin from "../pages/Account/Users/Admin/AdminUsers";
import AdminUsersNew from "../pages/Account/Users/Admin/AdminUsersNew";
import AdminUsersEdit from "../pages/Account/Users/Admin/AdminUsersEdit";
import LayoutAuthenticated from "../layout/LayoutAuthenticated";
import UserInvitationAccept from "../pages/UserInvitation/UserInvitationAccept";
import EmailTemplates from "../pages/Account/EmailTemplates";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_ACCOUNT: ApplicationRouteProps[] = [
  {
    path: "/account/users",
    element: (
      <LayoutAuthenticated>
        <Users />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_dashboard_users_edit"],
    type: "protected",
  },
  {
    path: "/account/users/new",
    element: (
      <LayoutAuthenticated>
        <UsersNew />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_dashboard_users_create"],
    type: "protected",
  },
  {
    path: "/account/users/:id/edit",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <UsersEdit />
      </LayoutAuthenticated>
    ),
    permissions: ["applicant_dashboard_users_edit"],
    type: "protected",
  },
  {
    path: "/account/profile",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <ProfileSettings />
      </LayoutAuthenticated>
    ),
    permissions: ["*"],
    type: "protected",
  },
  {
    path: "/account/resources",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <Resources />
      </LayoutAuthenticated>
    ),
    permissions: ["*"],
    type: "protected",
  },
  {
    path: "/account/manage-alerts",
    element: (
      <LayoutAuthenticated>
        <ManageAlerts />
      </LayoutAuthenticated>
    ),
    permissions: ["ad_alerts_manage"],
    type: "protected",
  },
  {
    path: "/account/vidconf",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <VideoConferencing />
      </LayoutAuthenticated>
    ),
    permissions: ["*"],
    type: "protected",
  },
  {
    path: "/account/admin/users",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <UsersAdmin />
      </LayoutAuthenticated>
    ),
    permissions: ["users_admin_view"],
    type: "protected",
  },
  {
    path: "/account/admin/users/new",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdminUsersNew />
      </LayoutAuthenticated>
    ),
    permissions: ["users_admin_create"],
    type: "protected",
  },
  {
    path: "/account/admin/users/:id/edit",
    element: (
      <LayoutAuthenticated isFullHeader={false}>
        <AdminUsersEdit />
      </LayoutAuthenticated>
    ),
    permissions: ["users_admin_edit"],
    type: "protected",
  },
  {
    path: "/account/invitation/accept/:invitationID",
    element: <UserInvitationAccept />,
    permissions: ["*"],
    type: "protected",
  },
  {
    path: "/account/email-templates",
    element: (
      <LayoutAuthenticated>
        <EmailTemplates />
      </LayoutAuthenticated>
    ),
    permissions: ["*"],
    type: "protected",
  },
];
