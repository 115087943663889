// Hooks & Utilities
import { Field } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useAdminGetAllCompanyForms } from "../../../api/Company/AdminCompany";
import { useAdminGetSBCATypes } from "../../../api/SBCA/SbcaTypes";

// Components
import Card from "../../Card/Card";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormTextarea from "../../Form/FormTextarea";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { JobAdFormSettingsProps } from "./interfaces";

const JobAdFormSettings = ({
  formValues,
  form_identifier,
  setFieldValue,
}: JobAdFormSettingsProps) => {
  /*===============================
    FETCH THE LIST OF FORMS
  ================================*/
  const { data: companyForms, isLoading: companyFormsLoading } = useAdminGetAllCompanyForms();

  // Map the received company forms data into dropdown items
  const JOB_APPLICATION_TYPES: DropdownItem[] = useMemo(() => {
    if (!companyForms || !companyForms.length || companyFormsLoading) return [];

    return companyForms.map(form => {
      return { text: form.name, value: form.id };
    });
  }, [companyForms]);

  /*===============================
    FETCH THE LIST OF SBCA TYPES
  ================================*/
  const { data: sbcaTypes, isLoading: sbcaTypesLoading } = useAdminGetSBCATypes();

  // Map the received SBCA types into dropdown items
  const SBCA_TYPES: DropdownItem[] = useMemo(() => {
    if (!sbcaTypes || !sbcaTypes.length || sbcaTypesLoading) return [];

    return sbcaTypes.map(sbca => {
      return { text: `SBCA - ${sbca.name}`, value: sbca.id };
    });
  }, [sbcaTypes]);

  /*==================================
    CONSTRUCT AD FORM APPLICATION URL
  ===================================*/
  const [formApplicationURL, setFormApplicationURL] = useState<string>("");

  useEffect(() => {
    const { host, protocol } = window.location;
    const url: string = `${protocol}//${host}/online-application/initialize-oa/${form_identifier}`;
    setFormApplicationURL(url);
  }, [form_identifier]);

  return (
    <div className="col-12 col-lg-6">
      <Card modifierClass="card--padding--sm">
        <h3 className="txt--blue fw--semibold mb--20">Form Settings</h3>

        <Field
          component={FormDropdownSearchable}
          id="form_id"
          name="form_id"
          size="full"
          items={JOB_APPLICATION_TYPES}
          isLoading={companyFormsLoading}
          disabled={!JOB_APPLICATION_TYPES.length}
          placeholder="Select Job Application"
          preselectedItemValue={formValues.form_id ?? null}
          handleFieldUpdate={(form_id: DropdownItem) => setFieldValue("form_id", form_id.value)}
          modifierClass="mb--20"
        />

        <Field
          component={FormDropdownSearchable}
          id="sbca_type_id"
          name="sbca_type_id"
          size="full"
          items={SBCA_TYPES}
          isLoading={sbcaTypesLoading}
          disabled={!SBCA_TYPES.length}
          placeholder="Select SBCA Type"
          preselectedItemValue={formValues.sbca_type_id}
          handleFieldUpdate={(sbca_type_id: DropdownItem) =>
            setFieldValue("sbca_type_id", sbca_type_id.value)
          }
          modifierClass="mb--20"
        />

        <Field
          component={FormTextarea}
          name="email_alerts"
          id="email_alerts"
          title="Email Alerts"
          label="Email Alerts:"
          placeholder="Enter emails here, separated by ;"
          modifierClass="input-side-label mb--20"
          rows={3}
        />

        <div className="input input-side-label mb--20">
          <label className="input__label">URL for Application:</label>

          <div className="d-flex flex-column align-items-start w--100">
            <textarea
              className="input-textarea mb--25"
              title="Form Application URL"
              rows={3}
              disabled
              readOnly
              defaultValue={formApplicationURL}
            ></textarea>
            <a
              href={formApplicationURL}
              target="_blank"
              rel="noopener"
              className="txt--link txt--md fw--medium"
            >
              (Click here to view application)
            </a>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobAdFormSettings;
