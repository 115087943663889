// Hooks & Utilities
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fetchHandler from "../fetchHandler";

// Interfaces
import {
  PrivilegesCrudFormFields,
  PrivilegesGroupFields,
  PrivilegesResponseFields,
  PrivilegesUserCompanyPermissionsAndGroups,
  PrivilegesUserResponseFields,
  PrivilegesUserUpdateFields,
} from "./interfaces";

/**
 *
 * Get the list of all existing privileges that
 * are to be listed in the UI.
 *
 */
export const usePrivilegesGetAll = () => {
  return useQuery(["privileges-all"], async () => {
    return (await fetchHandler("GET", "admin/permissions")) as PrivilegesResponseFields;
  });
};

/**
 *
 * Get a list of all the privileges related groups
 *
 */
export const usePrivilegesGroupsGetAll = () => {
  return useQuery(["privileges-groups"], async () => {
    return (await fetchHandler("GET", "admin/groups")) as PrivilegesGroupFields[];
  });
};

/**
 *
 * Get the details for the targeted privileges group
 *
 * @param groupID ID of the targeted privileges group
 *
 */
export const usePrivilegesGroupsGetSpecific = (groupID: string | undefined) => {
  return useQuery(
    ["privileges-groups", groupID],
    async () => {
      return (await fetchHandler("GET", `admin/groups/${groupID}`)) as PrivilegesCrudFormFields;
    },
    { enabled: !!groupID },
  );
};

/**
 *
 * Create new privileges groups
 *
 */
export const usePrivilegesGroupsCreate = () => {
  return useMutation(
    async (groupDetails: PrivilegesCrudFormFields) => {
      return await fetchHandler("POST", `admin/groups`, groupDetails);
    },
    {
      onSuccess: () => toast.success("Successfully created privileges group!"),
      onError: (error: unknown) => error,
    },
  );
};

/**
 *
 * Update the details of the targeted privileges group
 *
 * @param groupID ID of the targeted privileges group
 *
 */
export const usePrivilegesGroupEdit = (groupID: string | undefined) => {
  return useMutation(
    async (updateDetails: PrivilegesCrudFormFields) => {
      return await fetchHandler("PUT", `admin/groups/${groupID}`, updateDetails);
    },
    {
      onSuccess: () => toast.success("Successfully edited privileges group!"),
      onError: (error: unknown) => error,
    },
  );
};

/**
 *
 * Delete the targeted group of privileges
 *
 */
export const usePrivilegesGroupDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (groupID: number | null) => {
      return await fetchHandler("DELETE", `admin/groups/${groupID}`);
    },
    {
      onMutate: (groupID: number | null) => {
        // Get currently cached privileges group
        const cachedPrivilegesGroups = queryClient.getQueryData([
          "privileges-groups",
        ]) as PrivilegesGroupFields[];

        // Remove the targeted group from the list
        queryClient.setQueryData(
          ["privileges-groups"],
          (oldPrivilegesGroupsData: PrivilegesGroupFields[] | undefined) => {
            if (!oldPrivilegesGroupsData) return;

            return oldPrivilegesGroupsData.filter(group => group.id !== groupID);
          },
        );

        // Show success notification
        toast.success("Privilege Group deleted successfully!", {
          toastId: "privileges-groups-delete",
        });

        return { cachedPrivilegesGroups };
      },
      onError: (error, _groupID, context) => {
        toast.dismiss("privileges-groups-delete");

        queryClient.setQueryData(["privileges-groups"], context?.cachedPrivilegesGroups);
        return error;
      },
    },
  );
};

/**
 *
 * Fetch all the details for the user's permissions. This contains:
 * - `groups` - a list of all the existing groups that can be selected for the user
 * - `companies` - the list of companies to which the user belongs to
 * - `permissions` - the list of all existing `public` and `admin` permissions
 *
 * @param userID ID of the targeted user for who we want to obtain the permission details
 *
 */
export const usePrivilegesGetUser = (userID: string | undefined) => {
  return useQuery(
    ["privileges-user-permissions", userID],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/users/${userID}/permissions`,
      )) as PrivilegesUserResponseFields;
    },
    { enabled: !!userID },
  );
};

/**
 *
 * Update the targeted user's permissions.
 *
 * The mutation hook takes in an object parameter representing
 * the update details that consist of:
 *
 * - `companies` - an array of IDs representing the companies
 *  for which the permissions will be updated
 * - `groups` - an array of IDs representing the privilege groups to be applied to the user
 * - `permissions` - an array of IDs representing the individual permissions
 *
 * @param userID The ID of the targeted user
 *
 */
export const usePrivilegesUserUpdate = (userID: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateDetails: PrivilegesUserUpdateFields) => {
      return await fetchHandler("POST", `admin/users/${userID}/permissions`, updateDetails);
    },
    {
      onSuccess: () => toast.success("Successfully updated user's permissions!"),
      onError: (error: unknown) => error,
      onSettled: () => {
        // Invalidate the logged in user's "profile" cached data
        queryClient.invalidateQueries(["user-profile"]);
      },
    },
  );
};

/**
 *
 * Fetch the list of `groups` and `permissions` that
 * the user has in the specific company that is selected at the moment
 *
 * @param userID The ID of the targeted user
 * @param companyID The ID of the specifically selected company
 *
 */
export const usePrivilegesGetCompanyPermissionsAndGroups = (
  userID: string | undefined,
  companyID: number | null,
) => {
  return useQuery(
    ["privileges-user-company-permissions", userID, companyID],
    async () => {
      return (await fetchHandler(
        "GET",
        `admin/users/${userID}/company/${companyID}/permissions`,
      )) as PrivilegesUserCompanyPermissionsAndGroups;
    },
    { enabled: !!companyID },
  );
};
