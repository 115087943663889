import { TextEditorButtonsFields } from "../interfaces";

/**
 *
 * Extract the names of the buttons and join them as a single string
 * to be used to generate the toolbar of the text editor.
 *
 * The button names are extracted from a pre-defined array of `TextEditorButtons`
 * that we're going to register in the text editor as needed.
 *
 *
 * @param buttons A list of buttons that will be reigstered in the text editor.
 *
 */
export function extractTextEditorToolbarButtonNames(buttons: TextEditorButtonsFields[]): string {
  return buttons.map(btn => btn.name).join(" ");
}
