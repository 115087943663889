// Assets
import { MdPhoneInTalk as PhoneIcon } from "react-icons/md";
import { FaMapMarkerAlt as LocationIcon } from "react-icons/fa";

// Interfaces
import { ApplicationContactProps } from "./interfaces";

const ApplicationContact = ({ location, phone }: ApplicationContactProps) => {
  return (
    <div className="applications__contact">
      <div className="applications__contact__location" title={location}>
        <LocationIcon className="mr--5" />
        <p className="m--0">{location || "N/A"}</p>
      </div>

      <div className="applications__contact__phone" title={phone}>
        <PhoneIcon className="mr--5" />
        {phone ? <a href={`tel:${phone}`}>{phone}</a> : <p className="m--0">N/A</p>}
      </div>
    </div>
  );
};

export default ApplicationContact;
