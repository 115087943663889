// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";

// Components
import Card from "../../Card/Card";
import Table from "../../Table/Table";

// Interfaces
import { JobAdDetailsHistoryProps, JobAdDetailsHistoryTableFields } from "./interfaces";

const JobAdHistory = ({ histories }: JobAdDetailsHistoryProps) => {
  /*===========================
    TABLE DATA
  ==============================*/
  const COLUMNS_HELPER = createColumnHelper<JobAdDetailsHistoryTableFields>();

  const JOB_AD_HISTORY_TABLE_COLUMNS = [
    COLUMNS_HELPER.accessor("order", {
      header: () => <span>Update #</span>,
      cell: data => <span>{data.getValue()}</span>,
      size: 100,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("timestamp", {
      header: () => <span>Time</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("author", {
      header: () => <span>Author</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
    }),
    COLUMNS_HELPER.accessor("reason", {
      header: () => <span>Reason</span>,
      cell: data => <span>{data.getValue()}</span>,
      enableSorting: false,
    }),
  ];

  return (
    <div className="col-12 col-lg-6 mb--20">
      <Card modifierClass="card--padding--xl">
        <h3 className="fw--semibold txt--blue">Ad History</h3>

        <Table
          data={histories}
          columns={JOB_AD_HISTORY_TABLE_COLUMNS}
          isRefetching={false}
          modifierClass="table-wrapper--no-shadow mb--0i px--0i"
          paginationPageSize={20}
        />
      </Card>
    </div>
  );
};

export default JobAdHistory;
