import { useMutation, useQuery } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import fetchHandlerUpload from "../fetchHandlerUpload";
import {
  OnlineApplicationAssessmentFormQuestions,
  OnlineApplicationInfoFields,
  OnlineApplicationSpecificFormResponseFields,
  OnlineApplicationUploadPhotoPayload,
  OnlineApplicationUploadResumePayload,
} from "./interfaces";

/**
 *
 * Get the form fields for the targeted form based on its ID.
 *
 * @param formID The ID of the form for which we want to obtain
 * the fields that will construct the form.
 *
 */
export function useOnlineApplicationGetSpecificForm(formID: string | undefined) {
  const { search } = window.location;

  // Merge the form ID received as a slug and any potential search parameters appended to it
  const endpointURL: string = `${formID}${search}`;

  return useQuery(
    ["online-application-form", formID],
    async () => {
      return (await fetchHandler(
        "GET",
        `online-application/${endpointURL}`,
      )) as OnlineApplicationSpecificFormResponseFields;
    },
    {
      enabled: !!formID,
      retry: 0, // Disable any consecutive retries on error
    },
  );
}

/**
 *
 * Get the dynamic questions for the Online Application's Assessment (SBCA) form
 *
 * @param identifier The unique identifier for the Assessment (SBCA) form
 *
 */
export function useOnlineApplicationGetAssessmentFormQuestions(identifier: string | undefined) {
  return useQuery(
    ["online-application-assessment-form-questions", identifier],
    async () => {
      return (await fetchHandler(
        "GET",
        `online-assessment/${identifier}`,
      )) as OnlineApplicationAssessmentFormQuestions;
    },
    {
      enabled: !!identifier,
      retry: 0, // Disable any consecutive retries on error
    },
  );
}

/**
 *
 * Get the info for the application (e.g. company name)
 *
 * @param id The application identifier
 *
 */
export function useOnlineApplicationGetInfo(id: string | undefined) {
  return useQuery(
    ["online-application-info", id],
    async () => {
      return (await fetchHandler(
        "GET",
        `online-application/${id}/info`,
      )) as OnlineApplicationInfoFields;
    },
    { enabled: !!id },
  );
}

/**
 *
 * Upload a resume for the application
 *
 */
export function useOnlineApplicationUploadResume() {
  return useMutation(async (resumeUpload: OnlineApplicationUploadResumePayload) => {
    const formData = new FormData();
    formData.append("resume", resumeUpload.resume);

    await fetchHandlerUpload("POST", `online-application/${resumeUpload.id}/resume`, formData);
  });
}

/**
 *
 * Upload a photo for the application
 *
 */
export function useOnlineApplicationUploadPhoto() {
  return useMutation(async (photoUpload: OnlineApplicationUploadPhotoPayload) => {
    const formData = new FormData();
    formData.append("photo", photoUpload.photo);

    await fetchHandlerUpload("POST", `online-application/${photoUpload.id}/photo`, formData);
  });
}
