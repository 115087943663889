import React, { Fragment } from "react";

// Interfaces
import { HeaderDropdownGroupProps } from "./interfaces";

// Components
import { Link } from "react-router-dom";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

const HeaderDropdownGroup: React.FC<HeaderDropdownGroupProps> = ({ data }) => {
  return (
    <li className="header-dropdown__group">
      <h6 className="header-dropdown__group__header">{data.title}</h6>
      <div className="header-dropdown__group__items">
        {data.items.map((link, index) => (
          <PermissionCheckComponentWrapper
            key={`header-dropdown-group-permission-${index}`}
            permissions={link.permissions}
          >
            <Fragment key={`header-dropdown-group-${index}`}>
              {link.externalPath ? (
                <a
                  className="header-dropdown__link"
                  href={link.path}
                  target="_blank"
                  rel="noopener"
                >
                  {link.text}
                </a>
              ) : (
                <Link className="header-dropdown__link" to={link.path}>
                  {link.text}
                </Link>
              )}
            </Fragment>
          </PermissionCheckComponentWrapper>
        ))}
      </div>
    </li>
  );
};
export default HeaderDropdownGroup;
