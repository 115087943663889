// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";
import { BiSearchAlt as SearchIcon } from "react-icons/bi";

// Components
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import DropdownMultiselect from "../../components/Dropdown/DropdownMultiselect";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Dropdown from "../../components/Dropdown/Dropdown";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import Datepicker from "../../components/Datepicker/Datepicker";
import Loader from "../../components/Loader/Loader";
import PieChart from "../../components/Charts/PieChart";

// Hooks & Utilities
import { matchSorter } from "match-sorter";
import { createColumnHelper } from "@tanstack/react-table";
import useAdminUsersCompanies from "../Account/Users/Admin/hooks/useAdminUsersCompanies";
import { useEffect, useMemo, useState } from "react";
import { format, subDays } from "date-fns";
import { useReportGetAdPerformance } from "../../api/Reports/Reports";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";
import handleInitiatePDFWorker from "../../utilities/data/handleInitiatePDFWorker";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";

// Interfaces & Constants
import { ReportAdPerformanceTableResponseFields } from "../../api/Reports/interfaces";
import {
  REPORTS_AD_PERFORMANCE_DOCUMENT_COLUMNS,
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
} from "./constants";
import { ReportAdPerformanceTableData, ReportBreakdownTableColumns } from "./interfaces";
import { PIE_CHART_COLORS } from "../../components/Charts/constants";

const AdPerformance = () => {
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*==========================================
    DATE RANGE SELECTION

    The first date state is used for display
    & selection purposes, while the second one
    is used for the hook, search params,
    & generated document data. The initial
    values are the search parameters values
    or custom values depending on report page.
  ===========================================*/
  const startDateQuery = searchParametersObject["start_date"];
  const endDateQuery = searchParametersObject["end_date"];

  // Call designated Report Date Range Handling hook with desired initial values
  const { pickerDates, handleStartDate, handleEndDate } = useReportDateRangeHandling({
    initial_start_date: startDateQuery ?? format(subDays(Date.now(), 7), "MM/dd/yyyy"),
    initial_end_date: endDateQuery ?? format(Date.now(), "MM/dd/yyyy"),
  });

  // Update query params on local state change
  useEffect(() => {
    const { start_date, end_date } = pickerDates;

    setSearchParameters({
      ...searchParametersObject,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    });
  }, [pickerDates]);

  /*==========================================
    CLIENTS COMPANY DROPDOWN FETCH & SELECTION
    ===========================================*/
  const [companies, companiesLoading] = useAdminUsersCompanies();
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);

  // On initial load, fill 'selectedCompanies' with data if present in search params
  useEffect(() => {
    const searchQueryClients = searchParametersObject["clients"];
    if (!searchQueryClients) return;

    setSelectedCompanies(searchQueryClients.split(",").map(Number));
  }, []);

  // Update queryparams with selected company clients
  useEffect(() => {
    if (!selectedCompanies.length) {
      delete searchParametersObject["clients"];
      setSearchParameters({ ...searchParametersObject });
    } else {
      setSearchParameters({ ...searchParametersObject, clients: selectedCompanies.join(",") });
    }
  }, [selectedCompanies]);

  /*==========================================
    JOB TITLE INPUT
    ===========================================*/
  const [jobTitle, setJobTitle] = useState<string>(searchParametersObject["job_title"] ?? "");

  useEffect(() => {
    if (!jobTitle) {
      delete searchParametersObject["job_title"];
      setSearchParameters({ ...searchParametersObject });
    } else {
      setSearchParameters({ ...searchParametersObject, job_title: jobTitle });
    }
  }, [jobTitle]);

  /*=================================
    API FILTERS & REPORT DATA FETCH

    The fetch request is trigered by
    updating the below 'apiFilters'
    state, this happens upon clicking
    the 'Run Report' button.
  ==================================*/
  const [apiFilters, setApiFilters] = useState({
    dates: pickerDates,
    clients: searchParametersObject["clients"]
      ? searchParametersObject["clients"].split(",").map(Number)
      : selectedCompanies,
    job_title: searchParametersObject["job_title"] ? searchParametersObject["job_title"] : jobTitle,
  });

  const {
    isLoading: adPerformanceLoading,
    isRefetching: adPerformanceRefetching,
    isFetching: adPerformanceFetching,
    data: adPerformanceData,
  } = useReportGetAdPerformance(apiFilters.dates, apiFilters.clients, apiFilters.job_title);

  /*=================================
    TABLES COLUMNS
  ==================================*/
  const REPORT_COLUMN_HELPER = createColumnHelper<ReportAdPerformanceTableData>();

  const REPORT_TABLE_COLUMNS = [
    REPORT_COLUMN_HELPER.accessor("company", {
      header: () => <span>Company</span>,
      size: 150,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    REPORT_COLUMN_HELPER.accessor("title", {
      header: () => <span>Title</span>,
      size: 150,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/job-ads/ads/${data.row.original.ad_id}/`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    REPORT_COLUMN_HELPER.accessor("start_date", {
      header: () => <span>Start Date</span>,
      size: 110,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    REPORT_COLUMN_HELPER.accessor("end_date", {
      header: () => <span>End Date</span>,
      size: 110,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    REPORT_COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job Code</span>,
      size: 120,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    REPORT_COLUMN_HELPER.accessor("total", {
      header: () => <span>Total</span>,
      id: "Totals",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("firstchoice", {
      header: () => <span>FirstChoice</span>,
      id: "FirstChoice",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("career_pages", {
      header: () => <span>Career Pages</span>,
      id: "CareerPages",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("indeed", {
      header: () => <span>Indeed</span>,
      id: "Indeed",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("craigslist", {
      header: () => <span>Craigslist</span>,
      id: "Craigslist",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("zip_recruiter", {
      header: () => <span>Zip Recruiter</span>,
      id: "ZipRecruiter",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("snag_a_job", {
      header: () => <span>SnagAJob</span>,
      id: "SnagAJob",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),

    REPORT_COLUMN_HELPER.accessor("career_builder", {
      header: () => <span>Career Builder</span>,
      id: "CareerBuilder",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("geo_solutions", {
      header: () => <span>Geo Solutions</span>,
      id: "GeoSolutions",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("linked_in", {
      header: () => <span>LinkedIn</span>,
      id: "LinkedIn",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("appcast", {
      header: () => <span>Appcast</span>,
      id: "Appcast",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("talent", {
      header: () => <span>Talent</span>,
      id: "Talent",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
    REPORT_COLUMN_HELPER.accessor("other", {
      header: () => <span>Other</span>,
      id: "Other",
      size: 70,
      enableSorting: true,
      cell: data => {
        return <span>{data.getValue()}</span>;
      },
    }),
  ];

  // Remap the breakdown data into two parts
  // one for usage in the table, one for the chart
  const BREAKDOWN_DATA = useMemo(() => {
    if (!adPerformanceData || !adPerformanceData.chart || !adPerformanceData.chart.length) {
      return {
        table: [],
        chart: {
          colors: [],
          options: {},
          data: {},
        },
      };
    }

    const data = adPerformanceData.chart;

    // Map data for table usage
    const mappedTableData = data.map(entity => {
      return {
        name: entity.name,
        count: `${entity.count} (${entity.percent}%)`,
      };
    });

    // Map data for chart usage and do not include the "Grand Total" field from the API response
    const mappedChartLabels = data.slice(0, data.length - 1).map(entity => entity.name);
    const mappedChartData = data.slice(0, data.length - 1).map(entity => entity.count);

    const chartObject = {
      colors: PIE_CHART_COLORS,
      options: { maintainAspectRatio: false },
      data: {
        dataset_label: "Number of Applicants",
        legend_labels: mappedChartLabels,
        datasets: mappedChartData,
      },
    };

    return { table: mappedTableData, chart: chartObject };
  }, [adPerformanceData]);

  const BREAKDOWN_COLUMN_HELPER = createColumnHelper<ReportBreakdownTableColumns>();

  const BREAKDOWN_TABLE_COLUMNS = [
    BREAKDOWN_COLUMN_HELPER.accessor("name", {
      header: () => <span>Job Board</span>,
      size: 200,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    BREAKDOWN_COLUMN_HELPER.accessor("count", {
      header: () => <span>Applicants</span>,
      size: 200,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
  ];

  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<
    ReportAdPerformanceTableResponseFields[]
  >([]);

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "job_code", "company" & "title" data fields.
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    let data = adPerformanceData?.table ?? [];

    if (searchedValue) {
      data = matchSorter(data, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "company" },
          { threshold: matchSorter.rankings.CONTAINS, key: "title" },
        ],
      });
    }
    const mappedData = data.map(entity => {
      return {
        ad_id: entity.ad_id,
        company: entity.company,
        title: entity.title,
        start_date: format(new Date(entity.start_date), "MM/dd/yyyy"),
        end_date: format(new Date(entity.end_date), "MM/dd/yyyy"),
        job_code: entity.job_code,
        total: entity.performance.find(board => board.name === "Totals")?.total || 0,
        firstchoice: entity.performance.find(board => board.name === "FirstChoice")?.total || 0,
        career_pages: entity.performance.find(board => board.name === "CareerPages")?.total || 0,
        indeed: entity.performance.find(board => board.name === "Indeed")?.total || 0,
        craigslist: entity.performance.find(board => board.name === "Craigslist")?.total || 0,
        zip_recruiter: entity.performance.find(board => board.name === "ZipRecruiter")?.total || 0,
        snag_a_job: entity.performance.find(board => board.name === "SnagAJob")?.total || 0,
        career_builder:
          entity.performance.find(board => board.name === "CareerBuilder")?.total || 0,
        geo_solutions:
          entity.performance.find(board => board.name === "Geographic Solutions")?.total || 0,
        linked_in: entity.performance.find(board => board.name === "LinkedIn")?.total || 0,
        appcast: entity.performance.find(board => board.name === "Appcast")?.total || 0,
        talent: entity.performance.find(board => board.name === "Talent")?.total || 0,
        other: entity.performance.find(board => board.name === "Other")?.total || 0,
      };
    });

    return mappedData;
  }, [searchedValue, adPerformanceData]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /*====================================================
    PDF LOADING STATE
  =====================================================*/
  const [PDFLoading, setPDFLoading] = useState<boolean>(false);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex justify-content-between">
          <div>
            <h2 className="fw--semibold mb--5">Ad Performance Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>
        </div>
      </Card>

      <div className="px--30">
        <Card modifierClass="card--padding--xl">
          <div className="d-flex flex-column">
            {/* CLIENTS ROW */}
            <div className="row justify-content-center align-items-center my--10">
              <div className="col-12 col-lg-1 txt--right txt--left--lg">
                <p className="input__label">Clients:</p>
              </div>
              <div className="col-12 col-lg-6">
                <DropdownMultiselect
                  placeholder={
                    companiesLoading
                      ? "Fetching companies..."
                      : "Select one or more clients, if none, all clients will be used"
                  }
                  size="full"
                  items={companies}
                  preselectedItems={selectedCompanies}
                  isLoading={companiesLoading}
                  disabled={companiesLoading || !companies.length}
                  searchable
                  clearable
                  handleSelectedItems={data => setSelectedCompanies(data)}
                  modifierClass="dropdown--full-body-text"
                />
              </div>
            </div>

            {/* JOB TITLE ROW */}
            <div className="row justify-content-center align-items-center my--10">
              <div className="col-12 col-lg-1 txt--right txt--left--lg">
                <p className="input__label">Job Title:</p>
              </div>
              <div className="col-12 col-lg-6 d-flex">
                <div className="input input-with-elements input--full">
                  <div className="input__wrapper">
                    <SearchIcon className="input-with-elements__element input-with-elements__element--left" />
                    <input
                      placeholder="Optionally enter a job title"
                      className="input__field"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setJobTitle(event.target.value)
                      }
                      value={jobTitle}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* DATE PICKERS */}
            <div className="row justify-content-center my--10">
              <div className="col-12 col-lg-1 txt--right txt--left--lg"></div>
              <div className="col-12 col-lg-6 d-flex justify-content-center">
                <div className="row w--100">
                  <div className="col-12 col-md-6 pl--0 pr--md--0">
                    <div className="d-flex flex-column">
                      <div className="input__label">Start Date:</div>
                      <Datepicker
                        preselectedDate={
                          pickerDates.start_date ? new Date(pickerDates.start_date) : null
                        }
                        handleSelectedDate={date => handleStartDate(date)}
                        minDate="none"
                        maxDate="none"
                        enableTime={false}
                        modifierClass="datepicker--full mb--0i"
                        placeholder={pickerDates.start_date || undefined}
                        dateFormat="m/d/Y"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 pr--0 pl--md--0">
                    <div className="d-flex flex-column">
                      <div className="input__label">End Date:</div>
                      <Datepicker
                        preselectedDate={
                          pickerDates.end_date ? new Date(pickerDates.end_date) : null
                        }
                        handleSelectedDate={date => handleEndDate(date)}
                        minDate="none"
                        maxDate="none"
                        enableTime={false}
                        modifierClass="datepicker--full mb--0i"
                        placeholder={pickerDates.end_date || undefined}
                        dateFormat="m/d/Y"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* RUN REPORT BUTTON */}
            <div className="row justify-content-center align-items-center my--10">
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <Button
                  modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                  onClick={() => {
                    setApiFilters({
                      dates: pickerDates,
                      clients: selectedCompanies,
                      job_title: jobTitle,
                    });
                  }}
                  isLoading={adPerformanceRefetching || adPerformanceLoading}
                  isDisabled={adPerformanceFetching || adPerformanceLoading}
                >
                  Run Report
                </Button>
              </div>
            </div>
          </div>
        </Card>

        {/* AD PERFORMANCE TABLE */}
        {!adPerformanceLoading ? (
          <Card>
            <h3 className="fw--semibold mb--5 px--20 pt--10 mb--20">Export Data</h3>
            <div className="d-flex flex-column flex-md-row  align-items-md-start justify-content-center justify-content-md-between px--20">
              <div className="d-flex flex-column flex-md-row">
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold mb--md--15"
                  onClick={() =>
                    handleInitiateSpreadsheetGenerator(
                      exportedTableData,
                      "ad_performance_report",
                      REPORTS_AD_PERFORMANCE_DOCUMENT_COLUMNS,
                      "default-csv",
                      `${apiFilters.dates.start_date} to ${apiFilters.dates.end_date}`,
                    )
                  }
                  isDisabled={!exportedTableData.length || adPerformanceFetching}
                >
                  CSV
                </Button>
                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--md--0 mb--md--15 txt--uppercase"
                  onClick={() =>
                    handleInitiatePDFWorker({
                      columns: REPORTS_AD_PERFORMANCE_DOCUMENT_COLUMNS,
                      data: exportedTableData,
                      documentTitle: "FirstChoice Hiring - Ad Performance Report",
                      documentSubTitle: `Data Date: ${apiFilters.dates.start_date} to ${apiFilters.dates.end_date}`,
                      template: "default-report",
                      fileName: "ad_performance_report",
                      setPDFLoading: setPDFLoading,
                    })
                  }
                  isDisabled={!exportedTableData.length || adPerformanceFetching || PDFLoading}
                  isLoading={PDFLoading}
                >
                  PDF
                </Button>
              </div>
              <div
                className="d-flex flex--wrap--md justify-content-start justify-content-md-end mb--10"
                style={{ gridGap: "10px" }}
              >
                <InputFieldSearch
                  handleOnSearch={search => setSearchedValue(search)}
                  placeholder="Search Reports"
                  size="md"
                />
                <Dropdown
                  title="Select number of rows"
                  preselectedItemValue={rowsPerPage}
                  size="md"
                  items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                  handleItemSelected={item => setRowsPerPage(item.value as number)}
                />
              </div>
            </div>
            <Table
              data={REPORT_TABLE_DATA}
              columns={REPORT_TABLE_COLUMNS}
              isRefetching={adPerformanceRefetching}
              paginationPageSize={rowsPerPage}
              handleExportData={data => setExportedTableData(data)}
              modifierClass="table-wrapper--no-shadow mb--0i table-wrapper--max-content"
            />
          </Card>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>

      {/* CHART & BREAKDOWN TABLE */}
      <div className="px--30">
        {/* Check if the chart object contains at least 1 applicant in any of the job boards before rendering the chart */}
        {!adPerformanceLoading ? (
          adPerformanceData ? (
            !adPerformanceData.chart.every(item => item.count === 0) ? (
              <Card>
                <div className="py--20">
                  <h3 className="fw--semibold mb--5 px--20 pt--10 txt--center mb--30 mb--md--0">
                    Breakdown by Job Board
                  </h3>

                  <div className="row px--50 px--md--0">
                    <div className="col-12 col-lg-6 d-flex justify-content-center px--50 px--md--20 pt--30 chart-canvas">
                      <PieChart
                        data={BREAKDOWN_DATA.chart.data}
                        options={BREAKDOWN_DATA.chart.options}
                        colors={BREAKDOWN_DATA.chart.colors}
                      />
                    </div>

                    <div className="col-12 col-lg-6 d-flex justify-content-center px--50 px--md--20">
                      <Table
                        data={BREAKDOWN_DATA.table}
                        columns={BREAKDOWN_TABLE_COLUMNS}
                        isRefetching={adPerformanceRefetching}
                        paginationPageSize={adPerformanceData?.chart.length}
                        shouldShowSummarizedData
                        modifierClass="table-wrapper--no-shadow mb--0i"
                        shouldHandlePageRouteParameter={false}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              <h3 className="fw--semibold mb--5 px--20 pt--10 txt--center mb--30">
                No applicants found to generate a breakdown by job board chart
              </h3>
            )
          ) : (
            <h3 className="fw--semibold mb--5 px--20 pt--10 txt--center mb--30">
              No breakdown by job board data available
            </h3>
          )
        ) : null}
      </div>
    </>
  );
};

export default AdPerformance;
