// Hooks & Utilities
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import FormInput from "../../../Form/FormInput";
import Button from "../../../Button/Button";

// Interfaces
import { JobBoardsFormsProps } from "../interfaces";
import { DropdownItem } from "../../../Dropdown/interfaces";

// Assets
import { FaDollarSign as DollarIcon } from "react-icons/fa";

// Dropdown datasets
import {
  CAREER_BUILDER_DROPDOWN_BASE_PAY_RATE,
  CAREER_BUILDER_DROPDOWN_EDUCATION_LEVEL,
  CAREER_BUILDER_DROPDOWN_EMPLOYMENT_TYPE,
  CAREER_BUILDER_DROPDOWN_JOB_CATEGORIES,
  CAREER_BUILDER_DROPDOWN_JOB_INDUSTRY,
  CAREER_BUILDER_DROPDOWN_JOB_SKIN,
  CAREER_BUILDER_DROPDOWN_MANAGING_OTHERS,
  CAREER_BUILDER_DROPDOWN_RELOCATION_COVERED,
  CAREER_BUILDER_DROPDOWN_TRAVEL_REQUIRED,
} from "./dropdown-data/CareerBuilderDropdowns";
import FormInputWithElements from "../../../Form/FormInputWithElements";

const CareerBuilderForm = ({
  values,
  isSubmitDisabled = false,
  isSubmitLoading = false,
  job_board_data_career_builder,
  validateField,
  setFieldValue,
}: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.careerbuilder.enabled) validateField("careerbuilder");
  }, [values.careerbuilder]);

  return (
    <>
      <>
        <div className="d-flex justify-content-between align-items-center">
          <Field
            component={FormCheckbox}
            name="careerbuilder.enabled"
            id="careerbuilder.enabled"
            label="Enable CareerBuilder"
            checked={values.careerbuilder.enabled}
            labelModifierClass="input__label--sm"
          />

          {values.careerbuilder.enabled ? (
            <div className="d-flex justify-content-end w--100">
              <Button
                modifierClass="btn--fluid btn--primary"
                isDisabled={isSubmitDisabled || isSubmitLoading}
                isLoading={isSubmitLoading}
              >
                Save Changes
              </Button>
            </div>
          ) : null}
        </div>

        {values.careerbuilder.enabled ? (
          <>
            <div className="input--default-label mb--10">
              <label className="input__label">Job Slot</label>
              <p className="txt--black mb--0">
                {job_board_data_career_builder?.job_slots} - Expires{" "}
                {job_board_data_career_builder?.job_slots_expire
                  ? job_board_data_career_builder.job_slots_expire
                  : ""}
              </p>
            </div>

            <Field
              component={FormInput}
              name="careerbuilder.ad_title"
              id="careerbuilder.ad_title"
              label="Ad Title (required)"
              placeholder="Ad title for the job board"
              modifierClass="input--default-label mb--20"
              isRequired
            />

            <Field
              component={FormInput}
              name="careerbuilder.display_city"
              id="careerbuilder.display_city"
              label="Display City"
              placeholder="Optionally display city text (Like 'North Atlanta') instead of actual city"
              modifierClass="input--default-label mb--20"
            />

            <Field
              component={FormInput}
              name="careerbuilder.display_company"
              id="careerbuilder.display_company"
              label="Display Company Name"
              placeholder="Optionally set the display company name"
              modifierClass="input--default-label mb--20"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.job_skin"
              id="careerbuilder.job_skin"
              label="Job Skin"
              items={CAREER_BUILDER_DROPDOWN_JOB_SKIN}
              handleFieldUpdate={(jobSkin: DropdownItem) => {
                setFieldValue("careerbuilder.job_skin", jobSkin.value);
              }}
              size="full"
              placeholder="Select a Job Skin - Optional"
              preselectedItemValue={values.careerbuilder.job_skin}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.category1"
              id="careerbuilder.category1"
              label="Category 1"
              items={CAREER_BUILDER_DROPDOWN_JOB_CATEGORIES}
              handleFieldUpdate={(category: DropdownItem) => {
                setFieldValue("careerbuilder.category1", category.value);
              }}
              size="full"
              placeholder="None - Optional"
              preselectedItemValue={values.careerbuilder.category1}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.category2"
              id="careerbuilder.category2"
              label="Category 2"
              items={[
                { text: "None - optional", value: "" },
                ...CAREER_BUILDER_DROPDOWN_JOB_CATEGORIES,
              ]}
              handleFieldUpdate={(category: DropdownItem) => {
                setFieldValue("careerbuilder.category2", category.value);
              }}
              size="full"
              placeholder="None - Optional"
              preselectedItemValue={values.careerbuilder.category2}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.category3"
              id="careerbuilder.category3"
              label="Category 3"
              items={[
                { text: "None - optional", value: "" },
                ...CAREER_BUILDER_DROPDOWN_JOB_CATEGORIES,
              ]}
              handleFieldUpdate={(category: DropdownItem) => {
                setFieldValue("careerbuilder.category3", category.value);
              }}
              size="full"
              placeholder="None - Optional"
              preselectedItemValue={values.careerbuilder.category3}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.industry1"
              id="careerbuilder.industry1"
              label="Job Industry 1"
              items={CAREER_BUILDER_DROPDOWN_JOB_INDUSTRY}
              handleFieldUpdate={(jobIndustry: DropdownItem) => {
                setFieldValue("careerbuilder.industry1", jobIndustry.value);
              }}
              size="full"
              placeholder="Select an industry"
              preselectedItemValue={values.careerbuilder.industry1}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.industry2"
              id="careerbuilder.industry2"
              label="Job Industry 2"
              items={[
                { text: "None - Optional", value: "" },
                ...CAREER_BUILDER_DROPDOWN_JOB_INDUSTRY,
              ]}
              handleFieldUpdate={(jobIndustry: DropdownItem) => {
                setFieldValue("careerbuilder.industry2", jobIndustry.value);
              }}
              size="full"
              placeholder="None - Optional"
              preselectedItemValue={values.careerbuilder.industry2}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.industry3"
              id="careerbuilder.industry3"
              label="Job Industry 3"
              items={[
                { text: "None - Optional", value: "" },
                ...CAREER_BUILDER_DROPDOWN_JOB_INDUSTRY,
              ]}
              handleFieldUpdate={(jobIndustry: DropdownItem) => {
                setFieldValue("careerbuilder.industry3", jobIndustry.value);
              }}
              size="full"
              placeholder="None - Optional"
              preselectedItemValue={values.careerbuilder.industry3}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.education_level"
              id="careerbuilder.education_level"
              label="Education Level"
              items={CAREER_BUILDER_DROPDOWN_EDUCATION_LEVEL}
              handleFieldUpdate={(educationLevel: DropdownItem) => {
                setFieldValue("careerbuilder.education_level", educationLevel.value);
              }}
              size="full"
              placeholder="Select an education level"
              preselectedItemValue={values.careerbuilder.education_level}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.employment_type"
              id="careerbuilder.employment_type"
              label="Employment Type"
              items={CAREER_BUILDER_DROPDOWN_EMPLOYMENT_TYPE}
              handleFieldUpdate={(employmentType: DropdownItem) => {
                setFieldValue("careerbuilder.employment_type", employmentType.value);
              }}
              size="full"
              placeholder="Select an employment type"
              preselectedItemValue={values.careerbuilder.employment_type}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormInputWithElements}
              name="careerbuilder.base_pay_low"
              id="careerbuilder.base_pay_low"
              label="Base Pay Low"
              placeholder="Amount (example 32000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
              title="Amount (example 32000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
              modifierClass="input--default-label mb--20"
              leftElement={<DollarIcon />}
              type="number"
              step="0.05"
              min={0}
            />

            <Field
              component={FormInputWithElements}
              name="careerbuilder.base_pay_high"
              id="careerbuilder.base_pay_high"
              label="Base Pay High"
              placeholder="Amount (example 52000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
              title="Amount (example 52000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
              modifierClass="input--default-label mb--20"
              leftElement={<DollarIcon />}
              type="number"
              step="0.05"
              min={0}
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.base_pay_rate"
              id="careerbuilder.base_pay_rate"
              label="Base Pay Rate"
              items={CAREER_BUILDER_DROPDOWN_BASE_PAY_RATE}
              handleFieldUpdate={(basePayRate: DropdownItem) => {
                setFieldValue("careerbuilder.base_pay_rate", basePayRate.value);
              }}
              size="full"
              placeholder="Select Base Pay Rate"
              preselectedItemValue={values.careerbuilder.base_pay_rate}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormInputWithElements}
              name="careerbuilder.bonus_pay"
              id="careerbuilder.bonus_pay"
              label="Bonus Pay (per  year)"
              placeholder="Amount (example 6500) - Do not enter commas, decimals or other symbols"
              title="Amount (example 6500) - Do not enter commas, decimals or other symbols"
              modifierClass="input--default-label mb--20"
              leftElement={<DollarIcon />}
              type="number"
              min={0}
            />

            <Field
              component={FormInputWithElements}
              name="careerbuilder.commission_pay"
              id="careerbuilder.commission_pay"
              label="Commission Pay (per  year)"
              placeholder="Amount (example 12500) - Do not enter commas, decimals or other symbols"
              title="Amount (example 12500) - Do not enter commas, decimals or other symbols"
              modifierClass="input--default-label mb--20"
              leftElement={<DollarIcon />}
              type="number"
              min={0}
            />

            <Field
              component={FormInput}
              name="careerbuilder.other_pay"
              id="careerbuilder.other_pay"
              label="Other Pay (text)"
              placeholder="Enter text describing any other pay.  Stock options, paid vacation, etc."
              title="Enter text describing any other pay.  Stock options, paid vacation, etc."
              modifierClass="input--default-label mb--20"
            />

            <Field
              component={FormInput}
              name="careerbuilder.min_experience"
              id="careerbuilder.min_experience"
              label="Min Experience (Number of years)"
              placeholder="Number of years"
              title="Number of years"
              modifierClass="input--default-label mb--20"
              type="number"
              min={0}
            />

            <Field
              component={FormInput}
              name="careerbuilder.max_experience"
              id="careerbuilder.max_experience"
              label="Max Experience (Number of years)"
              placeholder="Number of years"
              title="Number of years"
              modifierClass="input--default-label mb--20"
              type="number"
              min={0}
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.managing_others"
              id="careerbuilder.managing_others"
              label="Managing Others"
              items={CAREER_BUILDER_DROPDOWN_MANAGING_OTHERS}
              handleFieldUpdate={(managingOthers: DropdownItem) => {
                setFieldValue("careerbuilder.managing_others", managingOthers.value);
              }}
              size="full"
              placeholder="Not Specified"
              preselectedItemValue={values.careerbuilder.managing_others}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.travel_required"
              id="careerbuilder.travel_required"
              label="Travel Required"
              items={CAREER_BUILDER_DROPDOWN_TRAVEL_REQUIRED}
              handleFieldUpdate={(travelRequired: DropdownItem) => {
                setFieldValue("careerbuilder.travel_required", travelRequired.value);
              }}
              size="full"
              placeholder="Not Specified"
              preselectedItemValue={values.careerbuilder.travel_required}
              modifierClass="dropdown--default-label mb--30"
            />

            <Field
              component={FormDropdownSearchable}
              name="careerbuilder.relocation_covered"
              id="careerbuilder.relocation_covered"
              label="Relocation Covered"
              items={CAREER_BUILDER_DROPDOWN_RELOCATION_COVERED}
              handleFieldUpdate={(relocationCovered: DropdownItem) => {
                setFieldValue("careerbuilder.relocation_covered", relocationCovered.value);
              }}
              size="full"
              placeholder="Not Specified"
              preselectedItemValue={values.careerbuilder.relocation_covered}
              modifierClass="dropdown--default-label mb--30"
            />
          </>
        ) : null}
      </>
    </>
  );
};

export default CareerBuilderForm;
