import { DropdownItem } from "../../../../Dropdown/interfaces";

/*===========================
  JOB INDUSTRY
============================*/
export const LINKED_IN_DROPDOWN_JOB_INDUSTRY: DropdownItem[] = [
  { text: "None - Optional", value: "" },
  { value: 47, text: "Accounting" },
  { value: 94, text: "Airlines/Aviation" },
  { value: 120, text: "Alternative Dispute Resolution" },
  { value: 125, text: "Alternative Medicine" },
  { value: 127, text: "Animation" },
  { value: 19, text: "Apparel & Fashion" },
  { value: 50, text: "Architecture & Planning" },
  { value: 111, text: "Arts and Crafts" },
  { value: 53, text: "Automotive" },
  { value: 52, text: "Aviation & Aerospace" },
  { value: 41, text: "Banking" },
  { value: 12, text: "Biotechnology" },
  { value: 36, text: "Broadcast Media" },
  { value: 49, text: "Building Materials" },
  { value: 138, text: "Business Supplies and Equipment" },
  { value: 129, text: "Capital Markets" },
  { value: 54, text: "Chemicals" },
  { value: 90, text: "Civic & Social Organization" },
  { value: 51, text: "Civil Engineering" },
  { value: 128, text: "Commercial Real Estate" },
  { value: 118, text: "Computer & Network Security" },
  { value: 109, text: "Computer Games" },
  { value: 3, text: "Computer Hardware" },
  { value: 5, text: "Computer Networking" },
  { value: 4, text: "Computer Software" },
  { value: 48, text: "Construction" },
  { value: 24, text: "Consumer Electronics" },
  { value: 25, text: "Consumer Goods" },
  { value: 91, text: "Consumer Services" },
  { value: 18, text: "Cosmetics" },
  { value: 65, text: "Dairy" },
  { value: 1, text: "Defense & Space" },
  { value: 99, text: "Design" },
  { value: 132, text: "E-Learning" },
  { value: 69, text: "Education Management" },
  { value: 112, text: "Electrical/Electronic Manufacturing" },
  { value: 28, text: "Entertainment" },
  { value: 86, text: "Environmental Services" },
  { value: 110, text: "Events Services" },
  { value: 76, text: "Executive Office" },
  { value: 122, text: "Facilities Services" },
  { value: 63, text: "Farming" },
  { value: 43, text: "Financial Services" },
  { value: 38, text: "Fine Art" },
  { value: 66, text: "Fishery" },
  { value: 34, text: "Food & Beverages" },
  { value: 23, text: "Food Production" },
  { value: 101, text: "Fund-Raising" },
  { value: 26, text: "Furniture" },
  { value: 29, text: "Gambling & Casinos" },
  { value: 145, text: "Glass, Ceramics & Concrete" },
  { value: 75, text: "Government Administration" },
  { value: 148, text: "Government Relations" },
  { value: 140, text: "Graphic Design" },
  { value: 124, text: "Health, Wellness and Fitness" },
  { value: 68, text: "Higher Education" },
  { value: 14, text: "Hospital & Health Care" },
  { value: 31, text: "Hospitality" },
  { value: 137, text: "Human Resources" },
  { value: 134, text: "Import and Export" },
  { value: 88, text: "Individual & Family Services" },
  { value: 147, text: "Industrial Automation" },
  { value: 84, text: "Information Services" },
  { value: 96, text: "Information Technology and Services" },
  { value: 42, text: "Insurance" },
  { value: 74, text: "International Affairs" },
  { value: 141, text: "International Trade and Development" },
  { value: 6, text: "Internet" },
  { value: 45, text: "Investment Banking" },
  { value: 46, text: "Investment Management" },
  { value: 73, text: "Judiciary" },
  { value: 77, text: "Law Enforcement" },
  { value: 9, text: "Law Practice" },
  { value: 10, text: "Legal Services" },
  { value: 72, text: "Legislative Office" },
  { value: 30, text: "Leisure, Travel & Tourism" },
  { value: 85, text: "Libraries" },
  { value: 116, text: "Logistics and Supply Chain" },
  { value: 143, text: "Luxury Goods & Jewelry" },
  { value: 55, text: "Machinery" },
  { value: 11, text: "Management Consulting" },
  { value: 95, text: "Maritime" },
  { value: 97, text: "Market Research" },
  { value: 80, text: "Marketing and Advertising" },
  { value: 135, text: "Mechanical or Industrial Engineering" },
  { value: 113, text: "Media" },
  { value: 126, text: "Media Production" },
  { value: 17, text: "Medical Devices" },
  { value: 13, text: "Medical Practice" },
  { value: 139, text: "Mental Health Care" },
  { value: 71, text: "Military" },
  { value: 56, text: "Mining & Metals" },
  { value: 35, text: "Motion Pictures and Film" },
  { value: 37, text: "Museums and Institutions" },
  { value: 115, text: "Music" },
  { value: 114, text: "Nanotechnology" },
  { value: 81, text: "Newspapers" },
  { value: 100, text: "Non-Profit Organization Management" },
  { value: 57, text: "Oil & Energy" },
  { value: 123, text: "Outsourcing/Offshoring" },
  { value: 87, text: "Package/Freight Delivery" },
  { value: 146, text: "Packaging and Containers" },
  { value: 61, text: "Paper & Forest Products" },
  { value: 39, text: "Performing Arts" },
  { value: 15, text: "Pharmaceuticals" },
  { value: 131, text: "Philanthropy" },
  { value: 136, text: "Photography" },
  { value: 117, text: "Plastics" },
  { value: 107, text: "Political Organization" },
  { value: 67, text: "Primary/Secondary Education" },
  { value: 83, text: "Printing" },
  { value: 105, text: "Professional Training & Coaching" },
  { value: 102, text: "Program Development" },
  { value: 79, text: "Public Policy" },
  { value: 98, text: "Public Relations and Communications" },
  { value: 78, text: "Public Safety" },
  { value: 82, text: "Publishing" },
  { value: 62, text: "Railroad Manufacture" },
  { value: 64, text: "Ranching" },
  { value: 44, text: "Real Estate" },
  { value: 40, text: "Recreational Facilities and Services" },
  { value: 89, text: "Religious Institutions" },
  { value: 144, text: "Renewables & Environment" },
  { value: 70, text: "Research" },
  { value: 32, text: "Restaurants" },
  { value: 27, text: "Retail" },
  { value: 121, text: "Security and Investigations" },
  { value: 7, text: "Semiconductors" },
  { value: 58, text: "Shipbuilding" },
  { value: 20, text: "Sporting Goods" },
  { value: 33, text: "Sports" },
  { value: 104, text: "Staffing and Recruiting" },
  { value: 22, text: "Supermarkets" },
  { value: 8, text: "Telecommunications" },
  { value: 60, text: "Textiles" },
  { value: 130, text: "Think Tanks" },
  { value: 21, text: "Tobacco" },
  { value: 108, text: "Translation and Localization" },
  { value: 92, text: "Transportation/Trucking/Railroad" },
  { value: 59, text: "Utilities" },
  { value: 106, text: "Venture Capital & Private Equity" },
  { value: 16, text: "Veterinary" },
  { value: 93, text: "Warehousing" },
  { value: 133, text: "Wholesale" },
  { value: 142, text: "Wine and Spirits" },
  { value: 119, text: "Wireless" },
  { value: 103, text: "Writing and Editing" },
];

export const LINKED_IN_DROPDOWN_EXPERIENCE_LEVEL: DropdownItem[] = [
  { value: "NOT_APPLICABLE", text: "Not Applicable" },
  { value: "INTERNSHIP", text: "Internship" },
  { value: "ENTRY_LEVEL", text: "Entry Level" },
  { value: "DIRECTOR", text: "Director Level" },
  { value: "MID_SENIOR_LEVEL", text: "Middle to Senior Level" },
  { value: "ASSOCIATE", text: "Associate" },
  { value: "EXECUTIVE", text: "Executive" },
];
