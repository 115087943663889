// Components
import AssessmentProgressBox from "../AssessmentProgressBox";

// Interfaces
import { AssessmentProgressBarSummaryCardProps } from "./interfaces";

const AssessmentProgressBarSummaryCard = ({ data }: AssessmentProgressBarSummaryCardProps) => {
  return (
    <>
      <div className="line-under line-under--absolute mb--20">
        <div className="row">
          <div className="col md-4">
            {/*SBCA DATA*/}
            <div className="sbca-data sbca-data--lg">
              <div className="sbca-data__row">
                <span className="sbca-data__title">Target range:</span>
                <span className="sbca-data__content">1-4.49</span>
              </div>
              <div className="sbca-data__row">
                <span className="sbca-data__title">Marginal:</span>
                <span className="span-data__content">4.50-6.49</span>
              </div>
              <div className="sbca-data__row">
                <span className="sbca-data__title">Concerned:</span>
                <span className="span-data__content">6.50+</span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <AssessmentProgressBox data={data.progress_bar} />
          </div>
        </div>
      </div>
      <p className="assessment-txt m--0">
        This index reflects the overall assessment including the baseline personality,
        organizational ability, behavioral patterns and all its measured components. It also
        measures the propensity for negativity and emotional patterns and their effect on his/her
        environment over a period of time.
      </p>
    </>
  );
};
export default AssessmentProgressBarSummaryCard;
