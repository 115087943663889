import { FormikValues } from "formik";

type DateControlProp = "minDate" | "maxDate";

export const handleAllowedDates = (
  formValues: FormikValues,
  sectionName: string,
  sectionIndex: number,
  targetedField: string,
) => {
  // Check if the currently tageted field ends with "start_date" or "end_date"
  // and based on that proceed onto defining the counterpart of the current field
  const dateFieldName: string | undefined = ["start_date", "end_date"].find((dateField: string) => {
    return targetedField.toLowerCase().endsWith(dateField.toLowerCase()) ? dateField : undefined;
  });

  // If the current field does not represent a "start" or "end" date, exit the function
  if (!dateFieldName) return;

  // Figure out the counterpart of the currently targeted date field.
  // For example, if the currently targeted field is "employer_start_date" then
  // its counterpart will be the ending date, or "employer_end_date" field
  const dateFieldCounterpart: string = dateFieldName === "start_date" ? "end_date" : "start_date";
  const targetedFieldCounterpart: string = targetedField
    .split(dateFieldName)
    .join(dateFieldCounterpart);

  // Get the section's current form field values
  const currentFormSectionValues = formValues[sectionName][sectionIndex];

  // Extract the form field value for the counterpart of the currently targeted date field
  const extractedDateValue = currentFormSectionValues?.[targetedFieldCounterpart];

  // If the field itself does not exist, or if it doesn't have any value, exit function
  if (!extractedDateValue) return;

  // Return a specific prop to be used by the date form field
  // based on the counterpart of the currently targeted date field.
  const prop: DateControlProp = dateFieldCounterpart === "start_date" ? "minDate" : "maxDate";

  return { [prop]: new Date(extractedDateValue) };
};
