import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import fetchHandler from "../fetchHandler";
import {
  InvitationCompleteRegistrationPayloadFields,
  InvitationInfoResponseFields,
  InvitationStatusInfoResponseFields,
} from "./interfaces";
import { UsersListResponseFields } from "../Users/interfaces";

/**
 *
 * Get the invitation's info detail
 *
 * @param invitationID The encrypted string representing the invitation's ID
 *
 */
export function useInvitationGetInfo(invitationID: string | undefined) {
  return useQuery(
    ["invitation-info", invitationID],
    async () => {
      return (await fetchHandler(
        "GET",
        `auth/invitation/${invitationID}`,
      )) as InvitationInfoResponseFields;
    },
    {
      enabled: !!invitationID,
      retryDelay: 0,
      retry: 1,
      onError: error => error,
    },
  );
}

/**
 *
 * Get the status of the received invitation, whether the
 * user already exists in the system or not, and redirect based on
 * that response
 *
 * @param invitationID The encrypted string representing the invitation's ID
 *
 */
export function useInvitationGetStatus(invitationID: string | undefined) {
  return useQuery(
    ["invitation-status-info", invitationID],
    async () => {
      return (await fetchHandler(
        "GET",
        `auth/invitation/${invitationID}/status`,
      )) as InvitationStatusInfoResponseFields;
    },
    {
      enabled: !!invitationID,
      retryDelay: 0,
      retry: 1,
      onError: error => error,
      cacheTime: 0,
    },
  );
}

/**
 *
 * Send a request accepting the received invitation
 *
 */
export function useInvitationAccept() {
  const queryClient = useQueryClient();

  return useMutation(
    async (invitationID: string) => {
      return await fetchHandler("POST", `auth/invitation/${invitationID}/join-company`);
    },
    {
      onError: error => error,
      onSettled: () => {
        // Refetch the list of available companies
        queryClient.invalidateQueries(["companies"]);
        queryClient.invalidateQueries(["user-profile"]);
      },
    },
  );
}

/**
 *
 * Complete the registration process after the user has
 * been invited to join a specific company
 *
 */
export function useInvitationCompleteRegistration() {
  return useMutation(
    async (registrationDetails: InvitationCompleteRegistrationPayloadFields) => {
      return await fetchHandler(
        "POST",
        `auth/invitation/${registrationDetails.invitationID}/join-company/new-user`,
        {
          first_name: registrationDetails.first_name,
          last_name: registrationDetails.last_name,
          password: registrationDetails.password,
          password_confirmation: registrationDetails.password_confirmation,
        },
      );
    },
    {
      onError: error => error,
    },
  );
}

/**
 *
 * Resend an invitation to a user who is in a "pending" state
 *
 */
export function useInvitationResend() {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (userID: number) => {
      return await fetchHandler("POST", `company/${companySlug}/invitation/${userID}/resend`);
    },
    {
      onSuccess: () => toast.success("Invitation email has been resent!"),
      onError: error => error,
    },
  );
}

/**
 *
 * Delete an invitation that was previously sent to a user
 *
 */
export function useInvitationDelete() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (userID: number) => {
      return await fetchHandler("DELETE", `company/${companySlug}/invitation/${userID}`);
    },
    {
      onMutate: userID => {
        // Get the cached data for listed users in the active company
        const previousUsersState = queryClient.getQueryData([
          "users-active-company",
          companySlug,
        ]) as UsersListResponseFields;

        // Filter out the cached data, removing the targeted user
        queryClient.setQueryData<UsersListResponseFields>(
          ["users-active-company", companySlug],
          users => {
            // Do not update the cached data if the "users" data is undefined
            if (!users) return;

            return users.filter(user => user.id !== userID);
          },
        );

        // Show notification in the UI
        toast.success("Invitation has been deleted successfully!", {
          toastId: "invitation-delete",
        });

        return previousUsersState;
      },
      onError: (error, _variables, previousUsersState) => {
        // Dismiss success notification from the UI first
        toast.dismiss("invitation-delete");

        // Fallback to state before triggering the API call, if the request was erroneous
        queryClient.setQueryData(["users-active-company", companySlug], previousUsersState);
        return error;
      },
    },
  );
}
