import { BsHouseDoor as HouseIcon } from "react-icons/bs";
import { CiGlobe as GlobeIcon } from "react-icons/ci";
import Tooltip from "../Tooltip/Tooltip";

interface ApplicationSourceUser {
  source: string;
  modifierClass?: string;
}

const ApplicationSourceUser = ({ source, modifierClass = "" }: ApplicationSourceUser) => {
  const isInternal = ["CareerPages", "Other"].includes(source);

  return (
    <div className={`applications__source__user ${modifierClass}`}>
      <Tooltip size="md" text={isInternal ? "Career Pages" : "FirstChoice"}>
        {isInternal ? <HouseIcon /> : <GlobeIcon />}
      </Tooltip>
    </div>
  );
};

export default ApplicationSourceUser;
