// Utilities & Hooks
import { toast } from "react-toastify";
import fetchHandlerUpload from "../../../api/fetchHandlerUpload";

// Interfaces
import { ArticlesContentUploadedImage } from "../../../api/Articles/interfaces";
import { TextEditorCustomImagePluginMetaFields } from "../interfaces";
import handleErrorMessage from "../../../utilities/handleErrorMessage";

/**
 * Utility function for handling the TinyMCE's
 * custom image plugin upload functionality that sends
 * an API request that stores the image on an S3 bucket on selection
 *
 * NOTE: There's usage of "any" type troughout this utility function
 * because trying to type out the image upload / file reader functions and
 * the TinyMCE's editor type throws errors and/or the typing is incomplete.
 *
 * @param callback TinyMCE's file picker callback function
 * @param editor TinyMCE's editor reference
 */
export default function handleCustomImageUpload(
  callback: Function,
  editor: any,
  image_upload_url: string,
) {
  // Create input element in the DOM
  const imageUploader = document.createElement("input");
  imageUploader.setAttribute("type", "file");
  imageUploader.setAttribute("accept", "image/*");

  // Listen for file changes
  imageUploader.addEventListener("change", async (event: any) => {
    // Exit function if there's no selected image
    if (!event.target) return;

    // If File Reader is not available to the user's browser, prevent trying to
    // initialize it and show an error in the UI
    if (!window.FileReader) {
      alert(
        "This browser does not have support for file uploads. Please try again with a modern browser.",
      );
      return;
    }

    // Read the selected file
    const selectedImage: File = event.target.files[0];
    const reader = new FileReader();

    // Read the image when it has been selected
    reader.onload = function (readerEvent) {
      // Exit function if there's no valid target (file) to read
      if (!readerEvent.target) return;

      // Initialize an image object that will be used for getting the dimensions
      const image = new Image();
      image.src = readerEvent.target.result as string;

      // Once the image was initialized and loaded succesfully,
      // read it's dimensions and send a POST request to the API
      // to store the image on an S3 bucket
      image.onload = async function (this: any) {
        const height: number = this.height;
        const width: number = this.width;

        // Create the form data that will be sent in the POST request
        const formData = new FormData();
        formData.append("image", selectedImage);

        // Disable the TinyMCE UI while the image upload request is ongoing
        editor.ui.setEnabled(false);

        // Send the request to the API to store the image on S3
        try {
          const response: ArticlesContentUploadedImage = await fetchHandlerUpload(
            "POST",
            image_upload_url,
            formData,
          );

          // Values to be used for the source meta data
          const sourceDataMetaFields: TextEditorCustomImagePluginMetaFields = {
            title: selectedImage.name,
            width,
            height,
            image_url: response.image_url,
            delete_url: response.delete_url,
          };

          // Update the TinyMCE custom image dialog source data
          callback(response.image_url, sourceDataMetaFields);
        } catch (error) {
          toast.error(handleErrorMessage(error) ?? "Could not upload image.");
        } finally {
          editor.ui.setEnabled(true);
        }
      };
    };

    reader.readAsDataURL(selectedImage);
  });

  // Mimic a click on the file upload input
  imageUploader.click();
}
