import Skeleton from "react-loading-skeleton";

const JobAdsOverviewSkeleton = () => {
  return (
    <div className="job-ads" data-testid="component:job-ads-overview-skeleton">
      <div className="container py--25">
        <div>
          <Skeleton width="200px" height="26px" className="mb--20" />
          <Skeleton width="100%" height="80px" className="mb--30" />
        </div>
        <div>
          <Skeleton width="200px" height="26px" className="mb--20" />
          <Skeleton width="100%" height="80px" className="mb--30" />
        </div>
        <div>
          <Skeleton width="200px" height="26px" className="mb--20" />
          <Skeleton width="100%" height="200px" className="mb--30" />
        </div>
        <div>
          <Skeleton width="200px" height="26px" className="mb--20" />
          <Skeleton width="100%" height="80px" className="mb--30" />
        </div>
      </div>
    </div>
  );
};

export default JobAdsOverviewSkeleton;
