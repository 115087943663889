// Interfaces
import { AssessmentSectionProps } from "./interfaces";

const AssessmentSection = ({
  title,
  children,
  modifierClass = "",
  sectionId,
}: AssessmentSectionProps) => {
  return (
    <div className={`assessment__block ${modifierClass}`} id={sectionId}>
      <h1 className="assessment-title">{title}</h1>

      {children}
    </div>
  );
};

export default AssessmentSection;
