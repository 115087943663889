import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchHandler from "../fetchHandler";
import { ProductTourResponseFields } from "./interfaces";

/**
 *
 * Mark the provided tour as finished in the user object
 *
 */
export const useMarkFinishTour = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (tourID: number) => {
      return await fetchHandler("POST", `product-tours/${tourID}/users`);
    },
    {
      onError: error => error,
      onSettled: () => queryClient.invalidateQueries(["user-profile"]),
    },
  );
};

/**
 *
 * Get all availbale tours for the current user
 *
 */
export const useGetAvailableTours = () => {
  return useQuery(["product-tours"], async () => {
    return (await fetchHandler("GET", "product-tours")) as ProductTourResponseFields[];
  });
};
