// Formik
import { Field, FieldArray } from "formik";

// Hooks
import { useState } from "react";

// Statics
import { CAREER_PAGES_FORM_FEATURED_HIGHLIGHTS_EXAMPLES } from "../../../pages/Career/statics";

// Components
import Card from "../../Card/Card";
import FormInput from "../../Form/FormInput";
import FormTextarea from "../../Form/FormTextarea";
import FormUpload from "../../Form/FormUpload";
import Tabs from "../../Tabs/Tabs";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

// Assets
import { AiOutlineClear as ClearIcon } from "react-icons/ai";

// Interfaces
import { TabItemProps } from "../../Tabs/interfaces";
import { FeaturedHighlightsSectionProps } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const FeaturedHighlightsSection: React.FC<FeaturedHighlightsSectionProps> = ({
  apiData,
  errors,
  handleFormFieldsClearing,
}) => {
  /*=====================
    TABS HANDLING
  ======================*/
  const [activeTab, setActiveTab] = useState<string>("0");
  const handleTabSelection = (tab: TabItemProps) => setActiveTab(tab.id);

  /*===========================
    CLEAR THE FIELD 
    VALUES FOR THE CURRENT TAB
  =============================*/
  const [clearable, setClearable] = useState<boolean>(false);
  const handleClearField = () => {
    handleFormFieldsClearing(activeTab);

    // Trigger clearance of the uploaded file
    // and reset the state after it's done
    setClearable(true);
    setTimeout(() => setClearable(false), 50);
  };

  /*===========================
    DOUBLE CONFIRMATION MODAL

    Display a double confirmation modal if
    the user wants to clear the targeted 
    featured highlight field values
  ============================*/
  const [showClearConfirmModal, setShowClearConfirmModal] = useState<boolean>(false);

  return (
    <div className="my--30">
      <Tabs
        tabs={[
          {
            id: "0",
            text: "Featured Highlight 1",
            modifierClass: errors?.featured && errors.featured[0] ? "tabs__item--error" : "",
          },
          {
            id: "1",
            text: "Featured Highlight 2",
            modifierClass: errors?.featured && errors.featured[1] ? "tabs__item--error" : "",
          },
          {
            id: "2",
            text: "Featured Highlight 3",
            modifierClass: errors?.featured && errors.featured[2] ? "tabs__item--error" : "",
          },
        ]}
        activeTabId={activeTab}
        handleTabSelection={handleTabSelection}
        modifierClass="px--20 px--md--0"
      />

      <Card modifierClass="card--padding--xl card--rounded">
        <h6 className="d-flex flex-column flex-md-row justify-content-between align-items-center mb--20">
          <span>
            <strong>Featured Highlight </strong>
            <em>{CAREER_PAGES_FORM_FEATURED_HIGHLIGHTS_EXAMPLES[+activeTab]}</em>
          </span>

          <Button
            type="button"
            modifierClass="btn--text career-pages__clear"
            onClick={() => setShowClearConfirmModal(true)}
            data-testid="component:career-pages-featured-section-clear-btn"
          >
            Clear
            <ClearIcon className="career-pages__clear__icon" />
          </Button>
        </h6>

        <p className="fw--medium">
          <em>
            Note: If one of the fields has value, then all fields in the currently active tab become
            required.
          </em>
        </p>

        <div style={{ display: activeTab === "0" ? "block" : "none" }}>
          <Field
            component={FormUpload}
            id={`featured.${activeTab}.image`}
            name={`featured.${activeTab}.image`}
            label="Featured Image"
            buttonText="Choose Image"
            placeholder="Select a featured image"
            modifierClass="input-upload--label--black mb--20"
            accept=".png, .jpg, .jpeg, .bmp"
            thumbnailPreview={apiData?.featured[0]?.image ?? ""}
            clearableFromParent={activeTab === "0" ? clearable : false}
            hasConfirmationForClearAction
            info={
              <>
                <span className="input-upload__info--info">
                  Accepted file types: gif, jpg, jpeg, png.
                </span>
                <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
              </>
            }
          />
        </div>

        <div style={{ display: activeTab === "1" ? "block" : "none" }}>
          <Field
            component={FormUpload}
            id={`featured.${activeTab}.image`}
            name={`featured.${activeTab}.image`}
            buttonText="Choose Image"
            placeholder="Select a featured image"
            modifierClass="mb--20"
            accept=".png, .jpg, .jpeg, .bmp"
            thumbnailPreview={apiData?.featured[1]?.image ?? ""}
            clearableFromParent={activeTab === "1" ? clearable : false}
            hasConfirmationForClearAction
            info={
              <>
                <span className="input-upload__info--info">
                  Accepted file types: gif, jpg, jpeg, png.
                </span>
                <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
              </>
            }
          />
        </div>

        <div style={{ display: activeTab === "2" ? "block" : "none" }}>
          <Field
            component={FormUpload}
            id={`featured.${activeTab}.image`}
            name={`featured.${activeTab}.image`}
            buttonText="Choose Image"
            placeholder="Select a featured image"
            modifierClass="mb--20"
            accept=".png, .jpg, .jpeg, .bmp"
            thumbnailPreview={apiData?.featured[2]?.image ?? ""}
            clearableFromParent={activeTab === "2" ? clearable : false}
            hasConfirmationForClearAction
            info={
              <>
                <span className="input-upload__info--info">
                  Accepted file types: gif, jpg, jpeg, png.
                </span>
                <span className="input-upload__info--danger"> Max File Size: 2mb.</span>
              </>
            }
          />
        </div>

        <FieldArray
          name="featured"
          render={() => (
            <div className="row">
              <div className="col-12 col-lg-6">
                <h5 className="fw--semibold mb--5">Headline 1</h5>
                <em className="txt--sm fw--medium txt--light-gray">
                  (Examples: Featured employee name, title of community or employee event)
                </em>
                <Field
                  component={FormInput}
                  id={`featured.${activeTab}.headline_1`}
                  name={`featured.${activeTab}.headline_1`}
                  modifierClass="mb--30"
                />

                <h5 className="fw--semibold mb--5">Headline 2</h5>
                <em className="txt--sm fw--medium txt--light-gray">
                  (Examples: Position title of featured employee or location of community or
                  employee event)
                </em>
                <Field
                  component={FormInput}
                  id={`featured.${activeTab}.headline_2`}
                  name={`featured.${activeTab}.headline_2`}
                  modifierClass="mb--30"
                />
              </div>

              <div className="col-12 col-lg-6">
                <h5 className="fw--semibold mb--5">Body</h5>
                <em className="txt--sm fw--medium txt--light-gray">
                  (Examples: Brief summary of image context, like quote from employee or summary of
                  event.)
                </em>
                <Field
                  component={FormTextarea}
                  id={`featured.${activeTab}.body`}
                  name={`featured.${activeTab}.body`}
                  modifierClass="mb--15"
                  rows="6"
                />
              </div>
            </div>
          )}
        />
      </Card>

      <AnimatePresence>
        {showClearConfirmModal && (
          <Modal
            title="Clear Featured Highlight values?"
            text={
              <>
                This action will clear all the fields for the currently selected{" "}
                <strong>Featured Highlight {parseInt(activeTab) + 1}</strong> tab. Are you sure you
                want to proceed?
              </>
            }
            modifierClass="modal--md modal--fixated"
            handleCloseModal={() => setShowClearConfirmModal(false)}
          >
            <div className="modal__actions">
              <Button
                modifierClass="btn--fluid btn--primary"
                onClick={() => setShowClearConfirmModal(false)}
                type="button"
              >
                Cancel
              </Button>

              <Button
                modifierClass="txt--capitalize btn--fluid btn--secondary"
                type="button"
                onClick={() => {
                  handleClearField();
                  setShowClearConfirmModal(false);
                }}
              >
                Yes, Clear Fields
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FeaturedHighlightsSection;
