import Skeleton from "react-loading-skeleton";
import Card from "../../../components/Card/Card";

const PrivilegesSkeleton = () => {
  return (
    <div data-testid="component:privileges-skeleton">
      <div className="tabs">
        <Skeleton width="175px" height="48px" />
        <Skeleton width="175px" height="48px" className="ml--10" />
      </div>

      <Card modifierClass="card--padding--xl">
        <Skeleton width="250px" height="18px" className="mb--10" />
        <Skeleton width="100%" height="40px" className="mb--20" />
        <hr className="mb--15" />

        {/* COMPANY PRIVILEGES */}
        <Skeleton width="200px" height="23px" className="mb--5" />
        <Skeleton width="300px" height="18px" className="mb--20" />

        <div className="row mb--30">
          {Array.from({ length: 4 }).map((_, index: number) => {
            return (
              <div key={`company-row-${index}`} className="col-12 col-md-6 col-lg-3 mb--25">
                <Skeleton width="150px" height="15px" className="mb--10" />

                <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                {Array.from({ length: 4 }).map((_, index: number) => {
                  return (
                    <Skeleton
                      key={`company-permission-${index}`}
                      width={(index + 1) * 50 + "px"}
                      height="12px"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* ADMIN PRIVILEGES */}
        <Skeleton width="200px" height="23px" className="mb--5" />
        <Skeleton width="300px" height="18px" className="mb--20" />

        <div className="row">
          {Array.from({ length: 4 }).map((_, index: number) => {
            return (
              <div key={`admin-row-${index}`} className="col-12 col-md-6 col-lg-3 mb--25">
                <Skeleton width="150px" height="15px" className="mb--10" />

                <hr className="mb--15" style={{ backgroundColor: "#0166a7" }} />

                {Array.from({ length: 4 }).map((_, index: number) => {
                  return (
                    <Skeleton
                      key={`admin-permission-${index}`}
                      width={(index + 1) * 50 + "px"}
                      height="12px"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default PrivilegesSkeleton;
