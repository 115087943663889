import { BucketItemProps } from "./interfaces";

const BucketItem = ({ id, name, modifierClass = "", handleBucketSelection }: BucketItemProps) => {
  return (
    <div
      className={`buckets__item js-bucket-${name
        .replaceAll(" ", "_")
        .toLowerCase()} ${modifierClass}`}
      key={id}
      title={name}
      onClick={() => handleBucketSelection(name)}
    >
      {name}
    </div>
  );
};

export default BucketItem;
