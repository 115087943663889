// Components
import { Field } from "formik";
import Card from "../../Card/Card";
import FormDropdownSearchable from "../../Form/FormDropdownSearchable";
import FormInput from "../../Form/FormInput";

// Constants
import {
  COUNTRY_BASED_ADDRESS_LABELS,
  COUNTRY_DROPDOWN,
  STATES_DROPDOWN,
} from "../../../constants/countries";

// Interfaces
import { DropdownItem } from "../../Dropdown/interfaces";
import { JobAdAddressProps } from "./interfaces";
import { CountryOption } from "../../../interfaces/global";

const JobAdAddress = ({ values, setFieldValue, setFieldTouched }: JobAdAddressProps) => {
  return (
    <Card modifierClass="card--padding--sm">
      <h3 className="txt--blue fw--semibold mb--20">Job Address</h3>

      <div className="row">
        <div className="col-12">
          <Field
            component={FormInput}
            label="Street"
            id="address_street"
            name="address_street"
            placeholder="Enter Street"
            modifierClass="input--default-label mb--15"
            isRequired
          />
        </div>

        <div className="col-12 col-lg-6">
          <Field
            component={FormDropdownSearchable}
            label="Country"
            id="address_country"
            name="address_country"
            placeholder="Select Country"
            modifierClass="dropdown--default-label mb--15"
            size="full"
            items={COUNTRY_DROPDOWN}
            preselectedItemValue={values.address_country}
            handleFieldUpdate={async (country: DropdownItem) => {
              setFieldValue("address_country", country.value);
              await setFieldValue("address_state", "");
              await setFieldTouched("address_state");
            }}
            isRequired
          />
        </div>

        <div className="col-12 col-lg-6">
          <Field
            component={FormInput}
            label={
              COUNTRY_BASED_ADDRESS_LABELS[values.address_country as CountryOption]?.city ?? "City"
            }
            id="address_city"
            name="address_city"
            placeholder="Enter City"
            modifierClass="input--default-label mb--15"
            isRequired
          />
        </div>

        <div className="col-12 col-lg-6">
          <Field
            key={values.address_country}
            component={FormDropdownSearchable}
            label={
              COUNTRY_BASED_ADDRESS_LABELS[values.address_country as CountryOption]?.state ??
              "State"
            }
            id="address_state"
            name="address_state"
            placeholder={`Select ${
              COUNTRY_BASED_ADDRESS_LABELS[values.address_country as CountryOption]?.state ??
              "State"
            }`}
            modifierClass="dropdown--default-label mb--15"
            size="full"
            items={STATES_DROPDOWN[(values.address_country as CountryOption) || "US"]}
            preselectedItemValue={values.address_state}
            handleFieldUpdate={(country: DropdownItem) => {
              setFieldValue("address_state", country.value);
            }}
            isRequired
          />
        </div>
        <div className="col-12 col-lg-2">
          <Field
            component={FormInput}
            label={
              COUNTRY_BASED_ADDRESS_LABELS[values.address_country as CountryOption]?.code ??
              "Zip Code"
            }
            id="address_zip"
            name="address_zip"
            placeholder="Enter ZIP"
            modifierClass="input--default-label mb--15"
            isRequired
          />
        </div>
      </div>
    </Card>
  );
};

export default JobAdAddress;
