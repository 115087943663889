// Hooks & Utilities
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useChatGetAllUserChats } from "../../../api/Chat/Chat";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../../providers/auth-context";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { LocalStorageActions } from "../../../utilities/handleLocalStorage";
import handleMoveArrayItem from "../../../utilities/data/handleMoveArrayItem";
import fetchHandler from "../../../api/fetchHandler";
import handlePermissionCheck from "../../../utilities/handlePermissionCheck";
import useWindowResize from "../../../hooks/useWindowResize";

// Components
import ChatPanel from "../Panel/ChatPanel";
import ChatBubble from "../ChatBubble";
import ChatBubbleExtras from "../ChatBubbleExtras";

// Interfaces
import { ChatDetails, ChatsContextProps } from "./interfaces";
import {
  ChatIsLastMessageRead,
  ChatResponseFields,
  ChatsForSpecificUserResponseFields,
} from "../../../api/Chat/interfaces";

// Socket Client
import socket from "../../../config/chat-socket";

export const ChatContext = createContext<ChatsContextProps>({
  existingUserChats: undefined,
  existingUserChatsLoading: false,
  unreadChatsCount: 0,
  activeChatID: null,
  handleOpenChat: () => undefined,
  handleCloseChat: () => undefined,
  handleMinimizeChat: () => undefined,
  handleChatSetActive: () => undefined,
  handleOpenBubbleChat: () => undefined,
});

const ChatContextWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  useEffect(() => {
    // Manually connect to the socket once the ChatContextWrapper
    // component is mounted which happens only on Authenticated Layout pages.
    socket.connect();

    // Manually disconnect from the socket once the ChatContextWrapper
    // component has been unmounted (e.g. user moved to page that's not part of the Authenticated Layout)
    return () => {
      socket.disconnect();
    };
  }, []);

  /*============================
    USER'S CHATS
  =============================*/
  const { data: existingUserChats, isLoading: existingUserChatsLoading } = useChatGetAllUserChats();
  const [unreadChatsCount, setUnreadChatsCount] = useState<number>(0);

  // Count all the chats whose last message is marked as unread
  useEffect(() => {
    if (!existingUserChats) return;

    const unreadChats = existingUserChats.filter(chat => !chat.last_message.is_read);
    setUnreadChatsCount(unreadChats.length);
  }, [existingUserChats]);

  /*============================
    ACTIVE (SELECTED) CHAT
  =============================*/
  const [activeChatID, setActiveChatID] = useState<number | null>(null);

  const handleChatSetActive = (chatID: number | null) => setActiveChatID(chatID);

  /*============================
    OPENED CHATS
  =============================*/
  const [openedChats, setOpenedChats] = useState<ChatDetails[]>(() => {
    const chatsInStorage = LocalStorageActions.getItem("fch-chats") || [];
    return chatsInStorage as ChatDetails[];
  });

  const handleOpenChat = (chat: ChatDetails) => {
    let openedChatsCopy = [...openedChats];

    // Find the index of the chat that we opened
    const chatIndex: number = openedChatsCopy.findIndex(openedChat => {
      return openedChat.id === chat.id;
    });

    // If the chat doesn't already exist, add it to the start of the list
    // Otherwise expand it, and if it was displayed as a bubble, rearrange it to first position
    if (chatIndex < 0) {
      openedChatsCopy.unshift(chat);
    } else {
      openedChatsCopy[chatIndex].is_minimized = false;

      // Only rearrange the chats if the chat that we tried reopening
      // was being displayed as a chat bubble / bubble extras item
      if (chatIndex > 1) {
        openedChatsCopy = handleMoveArrayItem<ChatDetails>(openedChatsCopy, chatIndex, 0);
      }
    }

    // Update the state of currently opened chats
    setOpenedChats(openedChatsCopy);

    // Mark the latest opened chat as active
    setActiveChatID(chat.id);
  };

  /*========================
    CLOSE CHAT
  =========================*/
  const handleCloseChat = (id: number) => {
    const filteredChats: ChatDetails[] = [...openedChats].filter(chat => {
      return chat.id !== id;
    });

    setOpenedChats(filteredChats);

    // If we closed the chat that was marked as active (focused)
    // then reset the corresponding state value to its defaults
    if (id === activeChatID) handleChatSetActive(null);
  };

  /*========================
    MINIMIZE CHAT
  =========================*/
  const handleMinimizeChat = (id: number) => {
    const openedChatsCopy = cloneDeep(openedChats);

    // Find the targeted chat from the list of opened chats
    const matchingChatIndex: number = openedChatsCopy.findIndex(chat => {
      return chat.id === id;
    });

    // If chat cannot be found, exit function
    if (matchingChatIndex < 0) return;

    // Update the minimized state of the chat
    openedChatsCopy.splice(matchingChatIndex, 1, {
      ...openedChatsCopy[matchingChatIndex],
      is_minimized: !openedChatsCopy[matchingChatIndex].is_minimized,
    });

    setOpenedChats(openedChatsCopy);
  };

  // Find the matching opened chats, with the chats returned from the API,
  // and update the state of the opened chats with the latest received changes from the API
  useEffect(() => {
    // If there are no opened chats, or no user chats fetched from the API, exit function
    if (!openedChats.length || !existingUserChats || !existingUserChats.length) return;

    const updatedChats: ChatDetails[] = [...openedChats];

    // From the list of fetched chats extract the chats whose IDs match
    // the IDs of the currently opened chats, so their values can be updated
    const matchingChats: ChatsForSpecificUserResponseFields[] = existingUserChats.filter(
      existingChat => {
        return openedChats.some(openedChat => {
          return existingChat.applicant.id === openedChat.applicant.id;
        });
      },
    );

    // Exit function if none of the chats received from the API
    // are part of the currently opened chats, as there'll be nothing to update
    if (!matchingChats.length) return;

    matchingChats.forEach(matchingChat => {
      // Find the index of the individual opened and matching chats
      // Using the "applicant.id" value as matching condition because this
      // will allow us to also target the chats that were brand new before sending a message
      // and update their ID and "is_new_chat" properties
      const openedChatIndex = updatedChats.findIndex(chat => {
        return chat.applicant.id === matchingChat.applicant.id;
      });

      // Exit function if chat cannot be found
      if (openedChatIndex < 0) return;

      // Update the targeted opened chat with the new details
      // received from the API response
      updatedChats.splice(openedChatIndex, 1, {
        ...updatedChats[openedChatIndex],
        id: matchingChat.id,
        is_blocked: matchingChat.is_blocked,
        is_new_chat: false,
        chat_id: matchingChat.id,
      });
    });

    // Update the state of the opened chats
    setOpenedChats(updatedChats);
  }, [existingUserChats]);

  /*============================
    OPEN CHAT BUBBLES

    Moves the clicked chat displayed as a bubble
    to the front of the list of opened chats, so it
    can be opened as a chat panel instead
  =============================*/
  const handleOpenBubbleChat = (chat: ChatDetails) => {
    // Find the chat that was previously displayed as a bubble
    const bubbleChatIndex: number = openedChats.findIndex(bubbleChat => {
      return bubbleChat.id === chat.id;
    });

    // Exit function if bubble cannot be found
    if (bubbleChatIndex < 0) return;

    // Move the chat from it's bubble position, to the front of the list
    const rearrangedChats = handleMoveArrayItem<ChatDetails>(openedChats, bubbleChatIndex, 0);

    // Always set the first item in the newly rearranged array to not be minimized
    const rearrangedChatsDeepCopy = cloneDeep(rearrangedChats);
    rearrangedChatsDeepCopy[0].is_minimized = false;

    setOpenedChats(rearrangedChatsDeepCopy);

    // Mark the bubble chat that was opened as active
    setActiveChatID(chat.id);
  };

  /*=============================
    LISTEN TO INCOMING MESSAGES

    If there's an incoming message, update the list
    of existing user chats that are displayed in the chats dropdown
  ==============================*/
  socket.on("inbound message", (incomingMessage: ChatResponseFields) => {
    if (!existingUserChats || !existingUserChats.length) return;

    // Find the matching chat ID to which the incoming message is belonging
    const matchingChatIndex: number = existingUserChats.findIndex(existingChat => {
      return existingChat.id === incomingMessage.chat_id;
    });

    // Exit function if chat does not exist
    if (matchingChatIndex < 0) return;

    // Received message should be marked as "read" only if the chat
    // that received the message is `opened` and `focused`
    const isIncomingMessageRead: ChatIsLastMessageRead =
      activeChatID != null ? (activeChatID === incomingMessage.chat_id ? 1 : 0) : 0;

    // Update the last message value
    const updatedExistingUserChats = [...existingUserChats];
    updatedExistingUserChats.splice(matchingChatIndex, 1, {
      ...updatedExistingUserChats[matchingChatIndex],
      last_message: {
        ...updatedExistingUserChats[matchingChatIndex].last_message,
        content: incomingMessage.content,
        timestamp: incomingMessage.timestamp,
        is_read: isIncomingMessageRead,
      },
    });

    // Update the cached query data
    queryClient.setQueryData(["sms-chats", user.id], updatedExistingUserChats);
  });

  /*=============================
    HIDE CHAT PANELS & BUBBLES 
    IF VISTING CHATS PAGE
  ===============================*/
  const [shouldDisplayChats, setShouldDisplayChats] = useState<boolean>(true);
  const location = useLocation();
  const hasChatsReadPermission = handlePermissionCheck(["sms_read"]);

  useEffect(() => {
    // If the user values are received, and the user
    // does not have the needed permission to see the chats,
    // then remove them from local storage (if there were any)
    if (user.id && !hasChatsReadPermission) {
      LocalStorageActions.removeItem("fch-chats");
      setShouldDisplayChats(false);

      return;
    }

    if (location.pathname.startsWith("/chats/messages")) {
      setShouldDisplayChats(false);
    } else {
      setShouldDisplayChats(true);
    }
  }, [user.id, hasChatsReadPermission, location.pathname]);

  /*=============================
    RESAVE THE OPENED CHATS IN 
    LOCAL STORAGE ON ANY CHANGE
  ===============================*/
  useEffect(() => {
    // Prevent re-saving the chats to local storage if the user
    // does not have the necessary "sms_read" permission
    if (user.id && !hasChatsReadPermission) return;

    const filteredOpenChats: ChatDetails[] = [...openedChats].filter(chat => !chat.is_new_chat);
    LocalStorageActions.saveItem("fch-chats", filteredOpenChats);
  }, [openedChats, user.id, hasChatsReadPermission]);

  /*=================================
    MARK MESSAGES AS READ ON FOCUS
  ==================================*/

  useEffect(() => {
    // If there are no existing user chats yet, exit function
    if (!existingUserChats || !existingUserChats.length) return;

    // If there's no valid active chat value selected, exit function
    if (!activeChatID) return;

    // Find the focused chat from the list of existing user chats
    const matchingChatIndex: number = existingUserChats.findIndex(chat => {
      return chat.id === activeChatID;
    });

    // If the chat that is selected as active cannot be
    // found within the list of existing chats, exit function
    if (matchingChatIndex < 0) return;

    // If all the messages for the targeted chat
    // are already marked as read do not send emit the event
    if (existingUserChats[matchingChatIndex].last_message.is_read) return;

    socket.emit("mark_chat_as_read", { chat_id: activeChatID });
  }, [activeChatID]);

  // Listen to receiving events from the socket to
  // trigger UI updates marking the messages for the matching chat as read
  socket.on("chat_marked_as_read", chatData => {
    // If there are no existing user chats yet, exit function
    if (!existingUserChats || !existingUserChats.length) return;

    // Find the chat for who we want to mark all messages as read
    const matchingChatIndex: number = existingUserChats.findIndex(chat => {
      return chat.id === chatData.chat_id;
    });

    // If the chat for which we received the event cannot be
    // found within the list of existing chats, exit function
    if (matchingChatIndex < 0) return;

    // If all of the messages for the targeted chat are already
    // marked as "read", exit the function, to prevent unnecessary state updates
    if (existingUserChats[matchingChatIndex].last_message.is_read) return;

    // Update all the messages for this chat as "read"
    const updatedChats = [...existingUserChats];
    updatedChats[matchingChatIndex] = {
      ...updatedChats[matchingChatIndex],
      last_message: {
        ...updatedChats[matchingChatIndex].last_message,
        is_read: 1,
      },
    };

    // Update the query data for the existing user chats
    queryClient.setQueryData(["sms-chats", user.id], updatedChats);
  });

  /*=================================
    FETCH MESSAGES FOR OPENED CHATS
    ON PAGE LOAD
  ==================================*/
  useEffect(() => {
    handleFetchMessagesForOpenedChats();
  }, []);

  const handleFetchMessagesForOpenedChats = async () => {
    const savedChats = LocalStorageActions.getItem("fch-chats") || [];

    // Exit function if there are no chats to work with
    if (!savedChats || !savedChats.length) return;

    savedChats.forEach(async (savedChat: ChatResponseFields) => {
      const data = await fetchHandler("GET", `sms/chats/${savedChat.chat_id}/messages`);

      queryClient.setQueryData(["sms-chat-messages", savedChat.chat_id], data);
    });
  };

  /*=====================================
    CONTROL HOW THE CHATS WRAPPER
    CONTENT IS BEING RENDERED

    - For >=768px default behavior with
    up to 2 chat panels, 6 bubbles, and rest
    is considered as "extras"
    - For <768px only 1 chat panel and all
    other chats that were opened are handled
    trough the "extras" menu
  ======================================= */
  const [windowWidth] = useWindowResize(300);

  return (
    <ChatContext.Provider
      value={{
        existingUserChats,
        existingUserChatsLoading,
        unreadChatsCount,
        activeChatID,

        handleOpenChat,
        handleCloseChat,
        handleMinimizeChat,
        handleChatSetActive,
        handleOpenBubbleChat,
      }}
    >
      {children}

      {shouldDisplayChats ? (
        windowWidth >= 768 ? (
          <div className="chats-wrapper">
            <div className="chats-wrapper__panels">
              {openedChats.slice(0, 2).map(chat => (
                <ChatPanel key={chat.applicant.id} details={chat} />
              ))}
            </div>

            {openedChats.length > 2 ? (
              <div className="chats-wrapper__bubbles">
                {openedChats.slice(2, 8).map(chat => (
                  <ChatBubble key={chat.applicant.id} details={chat} />
                ))}

                {openedChats.length > 8 ? <ChatBubbleExtras chats={openedChats.slice(8)} /> : null}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="chats-wrapper">
            <div className="chats-wrapper__panels">
              {openedChats.slice(0, 1).map(chat => (
                <ChatPanel key={chat.applicant.id} details={chat} />
              ))}
              {openedChats.length > 1 ? (
                <div className="chats-wrapper__bubbles">
                  {openedChats.length > 1 ? (
                    <ChatBubbleExtras chats={openedChats.slice(1)} />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        )
      ) : null}
    </ChatContext.Provider>
  );
};

// Wrapper hook around the context provider
// ensuring that the provider is available to be consumed
const useChatsContext = () => {
  const chatsContext = useContext(ChatContext);

  // Handle potential scenario in which the provider is not available yet to be used
  if (!chatsContext) throw new Error("Chats Context Provider does not exist.");

  return chatsContext;
};

export { ChatContextWrapper, useChatsContext };
