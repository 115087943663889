import { useEffect, useState } from "react";
import { ReportsDateRange } from "../interfaces";
import { compareAsc, format } from "date-fns";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";

interface InitialHookDates {
  initial_start_date: string | null;
  initial_end_date: string | null;
}
/**
 * Custom hook for handling the report date range change
 * In case the 'start_date' is greater than the 'end_date', both dates are set to the greater value
 * In case the 'end_date' is lesser than the 'start_date', both dates are set to the lesser value
 *
 * @param initial_start_date - The preselected value for the start_date field
 * @param initial_end_date - The preselected value for the end_date field
 * @returns { pickerDates, filterDates,setPickerDates, setFilterDates, handleStartDate, handleEndDate, handleRemoveDates } - pickerDates contain the preview Dates, filterDates
 * contain the 'final' dates that will be used, the reflection handler 'setFilterDates' and both preview dates change handlers
 */
export const useReportDateRangeHandling = ({
  initial_start_date,
  initial_end_date,
}: InitialHookDates) => {
  // SEARCH PARAMS
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  const startDateQuery = searchParametersObject["start_date"];
  const endDateQuery = searchParametersObject["end_date"];

  //PREVIEW DATES
  const [pickerDates, setPickerDates] = useState<ReportsDateRange>({
    start_date: startDateQuery ?? initial_start_date,
    end_date: endDateQuery ?? initial_end_date,
  });

  //FINAL DATES
  const [filterDates, setFilterDates] = useState<ReportsDateRange>({
    start_date: startDateQuery ?? initial_start_date,
    end_date: endDateQuery ?? initial_end_date,
  });

  //REFLECT FINAL DATES IN SEARCH PARAMS
  useEffect(() => {
    const { start_date, end_date } = filterDates;

    setSearchParameters({
      ...searchParametersObject,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    });
  }, [filterDates]);

  // PICKER DATE CHANGE HANDLERS
  const handleStartDate = (date: Date[]) => {
    setPickerDates({
      start_date: format(new Date(date[0]), "MM/dd/yyyy"),
      end_date:
        pickerDates.end_date && compareAsc(date[0], new Date(pickerDates.end_date)) > 0
          ? format(new Date(date[0]), "MM/dd/yyyy")
          : pickerDates.end_date,
    });
  };

  const handleEndDate = (date: Date[]) => {
    setPickerDates({
      start_date:
        pickerDates.start_date && compareAsc(new Date(pickerDates.start_date), date[0]) > 0
          ? format(new Date(date[0]), "MM/dd/yyyy")
          : pickerDates.start_date,
      end_date: format(new Date(date[0]), "MM/dd/yyyy"),
    });
  };

  const handleRemoveDates = () => {
    delete searchParametersObject.start_date;
    delete searchParametersObject.end_date;
    setSearchParameters({ ...searchParametersObject });
    setPickerDates({ start_date: null, end_date: null });
  };

  return {
    pickerDates,
    filterDates,
    setPickerDates,
    setFilterDates,
    handleStartDate,
    handleEndDate,
    handleRemoveDates,
  };
};
