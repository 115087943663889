// Hooks & Utilities
import { format } from "date-fns";
import { useAppointmentsDelete } from "../../../api/Appointments/Appointments";
import useErrorReporting from "../../../hooks/useErrorReporting";
import { useExtractSearchParameters } from "../../../hooks/useExtractSearchParameters";

// Components
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

// Interfaces
import { DeleteAppointmentModalProps } from "../interfaces";
import { handleStringCapitalization } from "../../../utilities/strings/handleStringCapitalization";

const DeleteAppointment = ({ details, handleCloseModal }: DeleteAppointmentModalProps) => {
  const errorReporting = useErrorReporting();

  /*==========================
    DELETE APPOINTMENT
  ===========================*/
  const deleteAppointment = useAppointmentsDelete();
  const [searchParameterObject] = useExtractSearchParameters();

  const handleDeleteAppointment = async () => {
    const month = searchParameterObject.month || format(new Date(), "MMMM").toLowerCase();
    const year = searchParameterObject.year || format(new Date(), "yyyy").toLowerCase();
    const capitalizedMonthString: string = handleStringCapitalization(month, [" "]);

    try {
      handleCloseModal();
      await deleteAppointment.mutateAsync({
        application_id: details.applicationID,
        appointment_id: details.id,
        month: capitalizedMonthString,
        year,
      });
    } catch (error) {
      errorReporting("Failed deleting appointment", error, {
        application_id: details.applicationID,
        appointment_id: details.id,
      });
    }
  };

  return (
    <Modal
      title="Delete appointment?"
      text={
        <>
          This action will delete the selected appointment. <br /> Are you sure you want to delete
          it?
        </>
      }
      modifierClass="modal--md modal--fixated"
      handleCloseModal={handleCloseModal}
    >
      <div className="modal__actions">
        <Button modifierClass="btn--fluid btn--primary" onClick={handleCloseModal}>
          Cancel
        </Button>

        <Button
          modifierClass="txt--capitalize btn--fluid btn--secondary"
          onClick={handleDeleteAppointment}
          isLoading={deleteAppointment.isLoading}
          isDisabled={deleteAppointment.isLoading}
        >
          Yes, Delete it
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAppointment;
