// Hooks
import { useEffect } from "react";

// Components
import { Field } from "formik";
import FormCheckbox from "../../../Form/FormCheckbox";

// Interfaces
import { JobBoardsFormsProps } from "../interfaces";
import FormInput from "../../../Form/FormInput";
import FormDropdownSearchable from "../../../Form/FormDropdownSearchable";
import { DropdownItem } from "../../../Dropdown/interfaces";
import FormInputWithElements from "../../../Form/FormInputWithElements";

// Assets
import { FaDollarSign as DollarIcon } from "react-icons/fa";
import FormDropdownMultiselect from "../../../Form/FormDropdownMultiselect";
import {
  FIRSTCHOICE_DROPDOWNS_JOB_TYPES,
  FIRSTCHOICE_DROPDOWNS_CATEGORIES,
  FIRSTCHOICE_DROPDOWNS_SALARY_PERIOD,
} from "./dropdown-data/FirstChoiceDropdowns";

export const FirstChoiceForm = ({ values, validateField, setFieldValue }: JobBoardsFormsProps) => {
  // When job board's "Enabled" button gets unchecked,
  // revalidate the whole field representing the job board, to
  // clear out any remaining errors for this group
  useEffect(() => {
    if (!values.firstchoice.enabled) validateField("firstchoice");
  }, [values.firstchoice]);

  return (
    <>
      <Field
        component={FormCheckbox}
        name="firstchoice.enabled"
        id="firstchoice.enabled"
        label="Enable FirstChoice"
        checked={values.firstchoice.enabled}
        labelModifierClass="input__label--sm"
      />

      {values.firstchoice.enabled ? (
        <>
          <Field
            component={FormInput}
            name="firstchoice.ad_title"
            id="firstchoice.ad_title"
            label="Ad Title (required)"
            placeholder="Ad title for the job board"
            modifierClass="input--default-label mb--20"
            isRequired
          />

          <Field
            component={FormDropdownSearchable}
            name="firstchoice.job_type"
            id="firstchoice.job_type"
            label="Job Type (required)"
            items={FIRSTCHOICE_DROPDOWNS_JOB_TYPES}
            handleFieldUpdate={(job_type: DropdownItem) => {
              setFieldValue("firstchoice.job_type", job_type.value);
            }}
            size="full"
            placeholder="Select Job Type"
            preselectedItemValue={values.firstchoice.job_type}
            modifierClass="dropdown--default-label mb--20"
            isRequired
          />

          <Field
            component={FormDropdownMultiselect}
            name="firstchoice.categories"
            id="firstchoice.categories"
            label="Categories (required, max 5)"
            items={FIRSTCHOICE_DROPDOWNS_CATEGORIES}
            handleFieldUpdate={(categories: DropdownItem[]) => {
              setFieldValue("firstchoice.categories", categories);
            }}
            size="full"
            placeholder="Select Categories"
            preselectedItems={values.firstchoice.categories}
            modifierClass="dropdown--default-label mb--20"
            isRequired
          />

          <Field
            component={FormInputWithElements}
            name="firstchoice.salary_from"
            id="firstchoice.salary_from"
            label="Salary From"
            placeholder="Amount (example 50000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
            title="Amount (example 50000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
            modifierClass="input--default-label mb--20"
            leftElement={<DollarIcon />}
            type="number"
            step="0.05"
            min={0}
          />

          <Field
            component={FormInputWithElements}
            name="firstchoice.salary_to"
            id="firstchoice.salary_to"
            label="Salary To"
            placeholder="Amount (example 60000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
            title="Amount (example 60000) - Do not enter other symbols besides numbers or a dot as decimal delimiter"
            modifierClass="input--default-label mb--20"
            leftElement={<DollarIcon />}
            type="number"
            step="0.05"
            min={0}
          />

          <Field
            component={FormDropdownSearchable}
            name="firstchoice.salary_period"
            id="firstchoice.salary_period"
            label="Salary Period"
            items={FIRSTCHOICE_DROPDOWNS_SALARY_PERIOD}
            handleFieldUpdate={(salary_period: DropdownItem) => {
              setFieldValue("firstchoice.salary_period", salary_period.value);
            }}
            size="full"
            placeholder="Select Salary Period"
            preselectedItemValue={values.firstchoice.salary_period}
            modifierClass="dropdown--default-label mb--20"
          />

          <Field
            component={FormCheckbox}
            name="firstchoice.remote"
            id="firstchoice.remote"
            label="Remote"
            checked={values.firstchoice.remote}
            labelModifierClass="input__label--sm mb--0i"
          />

          <Field
            component={FormCheckbox}
            name="firstchoice.featured"
            id="firstchoice.featured"
            label="Featured"
            checked={values.firstchoice.featured}
            labelModifierClass="input__label--sm mb--0i"
          />
        </>
      ) : null}
    </>
  );
};

export default FirstChoiceForm;
