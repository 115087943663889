// Interfaces
import { Component, ErrorInfo } from "react";

// Components
import { Context, getRollbarFromContext } from "@rollbar/react";

interface Props {
  fallbackUI: React.ReactNode;
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ComponentErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: "",
  };

  // Used for Rollbar instance
  static contextType = Context;

  public static getDerivedStateFromError(err: Error): State {
    console.log("error", err);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: err.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    // Report the uncaught errors from the Error Boundary directly to Rollbar's servers
    const rollbarInstance = getRollbarFromContext(this.context as any);
    rollbarInstance.error(`Uncaught error: ${error.message}`, error, errorInfo);
  }

  public render() {
    if (this.state.hasError && this.props.fallbackUI) {
      return this.props.fallbackUI;
    }

    return this.props.children;
  }
}

export default ComponentErrorBoundary;
