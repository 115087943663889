import { getIn } from "formik";
import { FormPasswordInputProps } from "./interfaces";
import { useState } from "react";

// Assets
import { BiHide as HidePasswordIcon, BiShow as ShowPasswordIcon } from "react-icons/bi";

const FormPasswordInput = ({
  form,
  field,
  label = "",
  modifierClass = "",
  description = "",
  isRequired = false,
  size = "full",
  ...props
}: FormPasswordInputProps) => {
  // Handle Formik errors
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  /*===============================
    TRIM FORM FIELD VALUE
  ================================*/
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue: string = event.target.value.trim();

    // Update the form value to which this field corresponds to
    form.setFieldValue(field.name, trimmedValue);

    // Trigger internal Formik 'onBlur' events for the field
    field.onBlur(event);
  };

  /*==============================
    TOGGLE PASSWORD VISIBILITY
  ===============================*/
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const handlePasswordVisibility = () => setPasswordVisibility(!passwordVisibility);

  return (
    <div
      className={`input input-with-elements input--${size} ${modifierClass} ${
        errors && touched ? "input-with-elements--error" : ""
      }`}
    >
      {label && (
        <label
          className={`input__label ${isRequired ? "input__label--required" : ""}`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div className="input__wrapper">
        <input
          {...field}
          {...props}
          onBlur={handleOnBlur}
          className="input__field"
          type={passwordVisibility ? "text" : "password"}
        />

        <label
          htmlFor={field.name}
          className="input-with-elements__element input-with-elements__element--right"
        >
          {passwordVisibility ? (
            <ShowPasswordIcon
              data-testid="component:profile-settings-password-visibility-toggle"
              onClick={handlePasswordVisibility}
            />
          ) : (
            <HidePasswordIcon
              data-testid="component:profile-settings-password-visibility-toggle"
              onClick={handlePasswordVisibility}
            />
          )}
        </label>
      </div>

      {/* DESCRIPTION OF THE FIELD */}
      {description && <p className="input__description">{description}</p>}

      {/* DISPLAY ERROR MESSAGES */}
      {errors && touched && <p className="input__error">{errors}</p>}
    </div>
  );
};

export default FormPasswordInput;
